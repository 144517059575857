// import "../../../userList/userList.css";
import { Business, Verified, WorkspacePremium } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";

import { CartContext } from "../../../../contexts/cartContext";
import api from "../../../../../utils/apiBusiness";
import { CircularProgress } from "@mui/material";
import ToastNote from "../../../../golbal/notifications/toastNote";
import tools from "../../../../../utils/tools";

export default function BusWaiting() {
  const { user } = useContext(CartContext);
  const effectRan = useRef(false);
  const messageRef = useRef(null);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState({
    rejected: false,
    approved: false,
    revise: false,
  });

  console.log(data);

  useEffect(() => {
    if (effectRan.current === false) {
      (async () => {
        const response = await api.waitingLiBus(user.token);
        console.log(response);
        if (response.error !== undefined) alert(response.error);
        setData(response.data);
      })();
      effectRan.current = true;
    }
  }, [window.location.pathname]);

  return (
    <div>
      <ToastNote messageRef={messageRef} />
      {data?.map((bus) => (
        <ExistBusRender bus={bus} status={status} />
      ))}
    </div>
  );

  function ExistBusRender({ bus, status }) {
    return (
      <div key={bus?._id} className="m-1 p-2 bs-1 ">
        <div
          className=" row bg-pure-white d-flex justify-content-around g-4 m-3 p-3"
          to={`/${window.lang}/business/page/${bus?._id}`}
        >
          <div className="col-lg-2 d-flex justify-content-center align-items-center">
            <img
              height="50px"
              width="70px"
              src={bus?.moreInfo.photos.businessCard}
              alt={bus?.busName?.[window.lang]}
              loading="lazy"
            />
            <span className="p-2">{bus?.busName?.[window.lang]}</span>
          </div>
          <div className="col-lg-1   mx-auto text-center align-self-center">
            <span className="p-2">{bus?.moreInfo?.status}</span>
          </div>
          {bus.moreInfo?.verifiedDoc?.length > 0 && (
            <div className="col-lg-4 text-center">
              <div className="d-flex flex-column">
                <div className="text-center primary-btn checkout-btn">
                  Verifying Business <Verified />
                </div>

                <div>
                  <div
                    className="small-gray-font my-3"
                    title={tools.toSolar(
                      bus.moreInfo?.verifiedDoc[0]?.startDate
                    )}
                  >
                    start date:
                    {tools.toGreg(bus.moreInfo?.verifiedDoc[0]?.startDate)}
                  </div>
                  <div
                    className="small-gray-font my-3"
                    title={tools.toSolar(bus.moreInfo?.verifiedDoc[0]?.endDate)}
                  >
                    End Date :
                    {tools.toGreg(bus.moreInfo?.verifiedDoc[0]?.endDate)}
                  </div>
                </div>
                <div>
                  <button className="btn primary-btn close-btn my-2">
                    <span>کارت شناسایی مسئول کسب و کار</span>
                    <i className="bi bi-download mx-2"> </i>
                  </button>
                </div>

                <ActionBtns bus={bus} section="verifiedDoc" />
              </div>
            </div>
          )}
          {bus.moreInfo?.trustedDoc.length > 0 && (
            <div className="col-lg-5 text-center">
              <div className="d-flex flex-column ">
                <div className="text-center primary-btn view-cart ">
                  Trusting Business <WorkspacePremium />
                </div>

                <div
                  className="small-gray-font my-3"
                  title={tools.toSolar(bus.moreInfo?.trustedDoc[0]?.startDate)}
                >
                  start date:
                  {tools.toGreg(bus.moreInfo?.trustedDoc[0]?.startDate)}
                </div>
                <div
                  className="small-gray-font mb-3"
                  title={tools.toSolar(bus.moreInfo?.trustedDoc[0]?.endDate)}
                >
                  End Date :{tools.toGreg(bus.moreInfo?.trustedDoc[0]?.endDate)}
                </div>
                <div>
                  <button className="btn primary-btn close-btn  my-2">
                    <span>کارت شناسایی مسئول کسب و کار</span>
                    <i className="bi bi-download mx-2"> </i>
                  </button>
                </div>
                <div>
                  <button className="btn primary-btn close-btn my-2 ">
                    <span>مدرک هویتی کسب و کار</span>
                    <i className="bi bi-download"> </i>
                  </button>
                </div>
                <ActionBtns bus={bus} section="trustedDoc" />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  function ActionBtns({ bus, section }) {
    return (
      <div className="d-flex justify-content-center">
        <button
          disabled={
            bus?.moreInfo?.[section][0]?.endDate > new Date().toISOString()
          }
          className="btn primary-btn edit-btn m-2"
          id={[bus._id, "approved", section]}
          onClick={actionHandle}
        >
          {loading.approved ? (
            <CircularProgress color="inherit" size="20px" />
          ) : (
            "Approve"
          )}
        </button>

        {/* <!-- Button trigger modal --> */}

        {/* <button
          disabled={bus.moreInfo[section][0].endDate > new Date().toISOString()}
          className="login-btn"
          id={[bus._id, "rejected", section]}
          onClick={actionHandle}
        >
          {loading.rejected ? (
            <CircularProgress color="inherit" size="20px" />
          ) : (
            "Reject"
          )}
        </button> */}

        <div>
          <button
            type="button"
            className="btn primary-btn delete-btn m-2"
            data-bs-toggle="modal"
            data-bs-target="#revisedModal"
            dir="ltr"
          >
            Revise!
          </button>

          {/* <!-- Modal --> */}
          <div
            className="modal fade"
            id="revisedModal"
            tabIndex="-1"
            aria-labelledby="ModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    به دلایل زیر مدرک باید دوباره فرستاده شود:
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body small-gray-font">
                  <div className="form-check text-start">
                    <input
                      className="form-check-input text-start"
                      type="checkbox"
                      id="waitingBus1"
                      value="cash"
                    />
                    <label className="form-check-label ms-1" for="waitingBus1">
                      خوانا نبودن مدرک
                    </label>
                  </div>
                  <div className="form-check text-start">
                    <input
                      className="form-check-input text-start"
                      type="checkbox"
                      id="waitingBus2"
                      value="cash"
                    />
                    <label className="form-check-label ms-1" for="waitingBus2">
                      باز نشدن فایل
                    </label>
                  </div>
                  <div className="form-check text-start">
                    <input
                      className="form-check-input text-start"
                      type="checkbox"
                      id="waitingBus3"
                      value="cash"
                    />
                    <label className="form-check-label ms-1" for="waitingBus3">
                      هم‌نام نبودن مدرک با مسئول کسب و کار
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">توضیحات بیشتر</label>
                    <textarea
                      className="form-control"
                      rows="7"
                      name="description"
                    ></textarea>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn primary-btn close-btn m-2 "
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    disabled={
                      bus?.moreInfo[section][0]?.endDate >
                      new Date().toISOString()
                    }
                    className="btn primary-btn delete-btn m-2 "
                    id={[bus._id, "revise", section]}
                    onClick={actionHandle}
                  >
                    {loading.revise ? (
                      <CircularProgress color="inherit" size="20px" />
                    ) : (
                      "Send to business"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async function actionHandle(e) {
    e.preventDefault();
    const id = e.target.id.split(",")[0];
    const action = e.target.id.split(",")[1];
    const section = e.target.id.split(",")[2];
    console.log(id, action, section);
    if (!id) return messageRef.current.showError({ message: "Invalid Id" });
    const el = e.target;
    console.log("el", el);
    el.setAttribute("disabled", true);
    setLoading((pre) => ({ ...pre, [action]: true }));
    console.log("acceptHandle", e.target.id, action);
    const response = await api.verifyDoc({
      token: user.token,
      action,
      section,
      id,
    });
    setLoading((pre) => ({ ...pre, [action]: false }));
    setTimeout(() => el.removeAttribute("disabled"), 5000);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({ message: response.message });
    setData(response.data);
  }
}
