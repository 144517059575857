import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CartContext } from "../../../../../../contexts/cartContext";
import api from "../../../../../../../utils/apiUsers";
import renderData from "../../../../../../../utils/renderData";
import MyIcons from "../../../../../../golbal/icons/myIcons";
import tools from "../../../../../../../utils/tools";
import ColoredSpinner from "../../../../../../alret/spiners/coloredSpiner/coloredSpinner";
import MetaDecorator from "../../../../../../../utils/metaDecorator";

export default function PointsDetails() {
  const { user } = useContext(CartContext);
  const navigate = useNavigate();
  const { pointsGroups } = renderData;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  console.log(data);
  const { lang, state:{state,stateShort,lat,lng}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await api.getPointsDetails({
        token: user.token,
        id: user._id,
      });
      setLoading(false);
      if (response.error) return alert(response.error);
      setData(response.data);
    })();
  }, []);

  function showPageHandle({ props }) {
    console.log(props);
    let text = "شما در حال انتقال به صفحه دیگر هستید.";
    //  if(lang.toLocaleLowerCase()!==props.businessLng.toLocaleLowerCase()) text+= `شما در حال انتقال به زبان ${window.capitalize(props.businessLng)} میباشد . \n`
    //  if(countryShort.toLocaleLowerCase()!==props.countryShort.toLocaleLowerCase()) text+= `شما در حال انتقال به کشور ${window.capitalize(props.country)} میباشد . \n`
    //  if(stateShort.toLocaleLowerCase()!==props.stateShort.toLocaleLowerCase()) text+= `شما در حال انتقال به استان ${window.capitalize(props.state)} میباشد . \n`
    if (window.confirm(text) !== true) return;
    if (props.targetIdentifier.model === "Business")
      return navigate(
        `/${window.lang}/business/page/${props.targetIdentifier.id}`
      );
    if (props.targetIdentifier.model === "Post")
      return navigate(`/${window.lang}/blog/post/${props.targetIdentifier.id}`);
  }
  const columns = [
    //   { field: '_id', headerName: 'ID', width: 70 },
    // {
    //   field: "action",
    //   headerName: "عملیات",
    //   width: 100,
    //   renderCell: (params) => {
    //     return (
    //       <div onClick={e=>showPageHandle({props:params.row})}>
    //        <MyIcons icon='display' />
    //       </div>
    //     );
    //   },
    // },

    {
      field: "type",
      headerName: "گروه",
      width: 150,
      renderCell: (params) => {
        const group = pointsGroups.find(
          (group) => group.name === params.row?.type
        );
        return <div title={group?.[window.lang]}> {group?.[window.lang]}</div>;
      },
    },

    {
      field: "targetIdentifier.description",
      headerName: "توضیحات",
      width: 200,
      renderCell: (params) => {
        return (
          <strong title={params.row?.targetIdentifier?.description}>
            {" "}
            {params.row?.targetIdentifier?.description}
          </strong>
        );
      },
    },
    {
      field: "point",
      headerName: "امتیاز",
      width: 80,
      renderCell: (params) => (
        <div>
          {tools.dynamicNumber({
            number: params.row?.point,
            lang: window.lang,
          })}
        </div>
      ),
    },

    {
      field: "createdAt",
      headerName: "تاریخ",
      width: 150,
      renderCell: (params) => (
        <div>
          {tools.dynamicDate({
            date: params.row?.createdAt,
            lang: window.lang,
            countryShort,
            showYear: true,
          })}
        </div>
      ),
    },
  ];
  const metaTagsData = {
    title: "  متامارس | داشبورد | جزئیات امتیاز ها ",
    description: "مشاهده جزئیات امتیاز کسب شده کاربری",
    section: "points list",
    url: `${window.location.origin}/${window.lang}/dashboard/user/trans/points`,
    img: "https://www.metamarce.com/img/illustration/buy-photography-gear-online.svg",
    type: "webpage",
    index: false,
  };

  return (
    <div className="userList" style={{ height: "100vh", width: "100%" }}>
      <MetaDecorator data={{ ...metaTagsData }} />

      {loading && <ColoredSpinner />}
      <DataGrid
        getRowId={(row) => row._id}
        rows={data}
        components={{
          Toolbar: GridToolbar,
        }}
        disableSelectionOnClick
        columns={columns}
        pageSize={[12]}
        rowsPerPageOptions={[1]}
        checkboxSelection
      />
    </div>
  );
}
