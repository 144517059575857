import { Link, useOutletContext } from "react-router-dom";
import PlansX5 from "../../golbal/sliders/plansX5";
import BusCardSliderS1 from "../../golbal/sliders/busCardSliderS1";
// import tools from "../../../utils/tools";
import NewBusSlider from "../../golbal/sliders/newBusSlider";
// import HorizontalSlider from "../../golbal/sliders/horizontalSlider";
import NewPosts2x2 from "../../golbal/sliders/components/newPosts2x2";
import PlansX4 from "../../golbal/sliders/plansX4";
// import renderData from "../../../utils/renderData";
import MetaDecorator from "../../../utils/metaDecorator";
// import tools from "../../../utils/tools";

export default function Home() {
  const [pubData] = useOutletContext();  
  // const { lang, state:{state,stateShort,lat,lng}, country, countryShort } =
  //   tools.getItemFromLocalStorage("initSetup", {});
  const page = "home";
  // const { translations } =
  //   JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = ` متامارس | خانه`;
  const description = `متامارس | خانه ای برای زبان فارسی | بانک مشاغل | نیازمندی ها| کاریابی | بانک شبکه های اجتماعی | وبلاک | آکادمی متامارس | متادمی `;
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "خانه ",
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": window.location.href,
              name: "خانه",
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
        ],
      },
      // about: {
      //   "@type": "Place",
      //   name: state?.toUpperCase(),
      //   address: {
      //     "@type": "PostalAddress",
      //     // "addressLocality": "City",
      //     addressRegion: state?.toUpperCase(),
      //     // "postalCode": "Postal Code",
      //     addressCountry: country?.toUpperCase(),
      //   },
      //   geo: {
      //     "@type": "GeoCoordinates",
      //     latitude: lat, // Replace with the actual latitude
      //     longitude: lng, // Replace with the actual longitude
      //   },
      // },
    },
  ];
  const metaTagsData = {
    title,
    description,
    section: "خانه",
    url: `https://www.metamarce.com/${window.lang}`,
    canonical: `https://www.metamarce.com/${window.lang}`,
    img: "https://www.metamarce.com/img/plans/x5-2.jpg",
    type: "webpage",
  };
  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <div className="bg-home">
        <div className="row mx-auto mb-5">
          <div className="col-lg-7 my-lg-5">
            <div className=" text-center   my-5 ">
              <p className="mx-auto mt-5 header-firstpage titr2">Metamarce</p>
              <h3 className="titr4 mx-auto ">
                {/* {window.capitalize(country)} */}
              </h3>
              <h1 className="mx-auto titr1 mt-5 ">متامارس</h1>             
              <h3 className="mx-auto titr3 ">
                پلی بین پارسی‌زبانان سراسر دنیا!
              </h3>
            </div>
          </div>
          <div className="col-lg-5  px-5  d-flex flex-wrap align-items-center">
            <img
              src="/img/homePage/business-discussion.png"
              className=" px-md-5 mb-5 w-100 "
              alt="homePage"
              loading="lazy"
            />
          </div>
        </div>
        <img
          src="/img/homePage/wave-haikei-3.svg"
          className=" p-0 py-0 w-100 overlay-b2"
          alt="homePage"
        />
      </div>
      {/* upward curve  */}
      {/* <section>
        <div
          style={{
            background: "#a99282",
            height: "120px",
            overflow: "hidden;",
          }}
        >
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{ height: "100%", width: "100%" }}
          >
            <path
              d="M-42.61,57.72 C173.53,156.41 310.10,-10.36 508.17,69.56 L500.00,0.00 L0.00,0.00 Z"
              style={{ stroke: "none", fill: "rgb(247, 244, 237)" }}
            ></path>
          </svg>
        </div>
      </section> */}
      {/* end of upward curve ************************** */}

      {/* <div className="text-center max-w-1000 mx-auto px-3">
<img
                      src="/img/homePage/metamarceHome.png"
                      className="mx-auto"
                      style={{width:'100%'}}
                      alt="metamarceHome"
                    />
</div>
     */}

      <div className="mt-5 mx-md-3 display-none-md">
        <div className="d-flex flex-wrap  max-w-1000 mx-auto my-3 justify-content-between ">
          <Link
            onClick={(e) => window.scroll(0, 0)}
            to="demy"
            className="card card3 card3-5 mx-auto my-3 img-banner-text-6 "
          >
            <img
              src="/img/illustration/online-learning-concept-3.svg"
              className="card-img-top card-img-pre w-100"
              alt="content creator"
            />
            <div className="card-body p-0">
              <p className="card-text text-center  titr7 titr7-5"> متادمی</p>
            </div>
          </Link>
          <Link
            onClick={(e) => window.scroll(0, 0)}
            to="business"
            className="card card3 card3-5 mx-auto my-3  "
          >
            <img
              src="/img/illustration/buy-photography-gear-online.svg"
              className="card-img-top card-img-pre w-100"
              alt="free business directory"
            />
            <div className="card-body p-0">
              <p className="card-text text-center  titr7 titr7-5">کسب‌وکارها</p>
            </div>
          </Link>
          <Link
            onClick={(e) => window.scroll(0, 0)}
            to="mp"
            className="card card3 card3-5 mx-auto my-3  "
          >
            <img
              src="/img/illustration/announcement.svg"
              className="card-img-top card-img-pre w-100"
              alt="free business directory"
            />
            <div className="card-body p-0">
              <p className="card-text text-center  titr7 titr7-5">
                نیازمندی‌ها
              </p>
            </div>
          </Link>
          {/* keep
          <Link
          onClick={e=> window.scroll(0,0)}
           to='jobs' className="card card3 card3-5 mx-auto my-3 img-banner-text-6 "
          >
            <img
              src="/img/illustration/man-with-join-us-sign-for-open-recruitment.svg"
              className="card-img-top card-img-pre w-100"
              alt="plans"
            />
            <div className="card-body p-0">
              <p className="card-text text-center  titr7 titr7-5"> کاریابی</p>
            </div>
          </Link> */}
          {/* <Link 
          onClick={e=> window.scroll(0,0)}
          to='media' className="card card3 card3-5 mx-auto my-3 img-banner-text-7 "
          >
            <img
              src="/img/illustration/about-us-2.svg"
              className="card-img-top card-img-pre w-100"
              alt="content creator"
            />
            <div className="card-body p-0">
              <p className="card-text text-center titr7 titr7-5">                
                شبکه اجتماعی
              </p>
            </div>
          </Link> */}
          <Link
            onClick={(e) => window.scroll(0, 0)}
            to="blog/filters"
            className="card card3 card3-5 mx-auto my-3 img-banner-text-8 "
          >
            <img
              src="/img/illustration/blogs-and-article.svg"
              className="card-img-top card-img-pre w-100"
              alt="content creator"
            />
            <div className="card-body p-0">
              <p className="card-text text-center titr7 titr7-5">مجله</p>
            </div>
          </Link>
        </div>
      </div>

      {/* <!-- PlanX5--> */}
      <div className="display-none-md">
        {" "}
        <PlansX5 plansX5={pubData.ads?.plansX5} page={page} />
      </div>

      <div className="pb-5 pt-0">
        <div className="similar-blog mx-auto pb-3 pt-0 ">
          <div className="divider2 mb-3  mt-0 ">
             
            <svg
              className="text-dark-550 dark:text-white ml-2"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
              <circle
                cx="35"
                cy="16"
                r="8"
                fill="currentColor"
                fillOpacity="0.4"
              ></circle>
              <circle
                cx="20"
                cy="8"
                r="5"
                fill="currentColor"
                fillOpacity="0.7"
              ></circle>
              {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
            </svg>
            <span className="p-0 mb-0 ">برگزیدگان</span>
          </div>

          <BusCardSliderS1 plansS1={pubData.ads?.plansS1} page={page} />
        </div>

        {/* Start the most visited posts */}
        <div className="dark-background mt-0">
          <div className="similar-blog  mx-auto py-3 ">
            <div className="divider-light ">
              &nbsp;
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
              </svg>
              <span className="p-0 mb-0 ">تازه های کسب و کار</span>
            </div>
            <NewBusSlider data={pubData.newBus} />
          </div>
        </div>
        {/* End the most visited posts */}
        {/* start the most recent posts 2x2*/}
        <div className=" mx-md-5 ">
          <div className="divider2 ">
             
            <svg
              className="text-dark-550 dark:text-white ml-2"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
              <circle
                cx="35"
                cy="16"
                r="8"
                fill="currentColor"
                fillOpacity="0.4"
              ></circle>
              <circle
                cx="20"
                cy="8"
                r="5"
                fill="currentColor"
                fillOpacity="0.7"
              ></circle>
              {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
            </svg>
            <span className="p-0 mb-0 ">تازه‌های بلاگ</span>
            {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> --> */}
          </div>
          <div className="row row-cols-1 row-cols-lg-2 g-4 my-3 ads-all mx-auto ">
            <NewPosts2x2 newPosts={pubData?.newPosts} />
          </div>
        </div>
        {/* end the most recent posts 2x2*/}
      </div>
      {/* <!-- banner FAQ--> */}
      <div className="banner-listing-2 w-100  mt-md-5 img-banner-text">
        <div className="row  ads-all mx-auto">
          <div className="col-md-6  d-flex align-items-center justify-content-center px-5 pt-3 img-banner-2  ">
            <img
              src="/img/Images/faq-animate2.svg"
              alt="FAQ"
              className="faq-img"
            />
          </div>
          <div className="col-md-6  d-flex flex-column align-items-center p-md-5 p-3 pb-1  ">
            <h4 className="my-3">ممکنه پرسش شما هم باشه!</h4>

            <div
              className="accordion w-100"
              id="accordionPanelsStayOpenExample"
            >
              <div className="accordion-item2">
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    className="accordion-button accordion-button2 collapsed "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    چگونه می‌توانم یک حساب در متامارس ایجاد کنم؟
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse "
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div className="accordion-body">
                    <strong>
                      برای ایجاد یک حساب، بر روی دکمه
                      <span
                        onClick={(e) =>
                          document.getElementById("loginTrigger").click()
                        }
                        className="btn-cart  px-2  "
                      >
                        "ورود/ثبت نام"
                      </span>
                      کلیک کنید و مراحل را دنبال و اطلاعات ورود خود را تنظیم
                      کنید.
                    </strong>
                  </div>
                </div>
              </div>
              <div className="accordion-item2">
                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                  <button
                    className="accordion-button accordion-button2  accordion-button2b collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseTwo"
                  >
                    کلمه عبور خود را فراموش کرده‌ام، چکار کنم؟
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingTwo"
                >
                  <div className="accordion-body">
                    <strong>
                      دکمه ورود/ثبت نام در گوشه سمت راست و بالا را بزنید، سپس
                      دکمه فعالسازی/کلمه عبور را فراموش کردم و سپس ایمیل خود را
                      وارد و ادامه دستور العمل را جهت بازیابی دنبال کنید
                    </strong>
                  </div>
                </div>
              </div>
              <div className="accordion-item2">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingThree"
                >
                  <button
                    className="accordion-button accordion-button2 accordion-button2c collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseThree"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree"
                  >
                    چگونه می‌توانم نویسنده در وبلاگ شما شوم؟
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingThree"
                >
                  <div className="accordion-body">
                    <strong>
                      برای تبدیل شدن به نویسنده در مجله متامارس، ابتدا باید حساب
                      کاربری خود را ایجاد کنید. سپس، وارد حساب کاربری شوید وارد
                      بخش پروفایل نویسنده، جزئیات نویسنده‌ای خود را وارد کنید.
                      پس از این مراحل، می‌توانید محتوای خود را به اشتراک
                      بگذارید.
                    </strong>
                  </div>
                </div>
              </div>
              <div className="accordion-item2">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingfour"
                >
                  <button
                    className="accordion-button accordion-button2 accordion-button2d collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapsefour"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapsefour"
                  >
                    آیا هزینه‌ای برای لیست کردن کسب‌وکار من در دایرکتوری وجود
                    دارد؟
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapsefour"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingfour"
                >
                  <div className="accordion-body">
                    <strong>
                      ثبت و ادعای مالکیت و همچنین ایجاد صفحه شخصی برای هر کسب و
                      کار در متامارس کاملا رایگان است. با این حال، ممکن است
                      فرصت‌های تبلیغاتی دیگری را با هزینه‌ ارائه دهیم.
                    </strong>
                  </div>
                </div>
              </div>
              <div className="accordion-item2">
                <h2
                  className="accordion-header"
                  id="panelsStayOpen-headingfive"
                >
                  <button
                    className="accordion-button accordion-button2 accordion-button2e collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapsefive"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapsefive"
                  >
                    چگونه در متامدیا صفحه خودم در شبکه های اجتماعی را معرفی کنم
                    و آیا ثبت نام رایگان است ؟
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapsefive"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingfive"
                >
                  <div className="accordion-body">
                    <strong>
                      <Link onClick={(e) => window.scroll(0, 0)} to="media">
                        "متامدیا "
                      </Link>
                      رایگان و خودکار است و در دسترس تمامی کاربران و صاحبان
                      صفحات شبکه اجتماعی است،
                    </strong>
                  </div>
                </div>
              </div>
            </div>
            <Link
              onClick={(e) => window.scroll(0, 0)}
              to={`prompt/faq`}
              style={{ fontSize: "50px" }}
              title="پرسش و پاسخ بیشتر"
              //  className="btn primary-btn checkout-btn cart-footer mx-auto"
            >
              <i className="bi bi-three-dots"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="divider2 ">
         
        <svg
          className="text-dark-550 dark:text-white ml-2"
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
          <circle
            cx="35"
            cy="16"
            r="8"
            fill="currentColor"
            fillOpacity="0.4"
          ></circle>
          <circle
            cx="20"
            cy="8"
            r="5"
            fill="currentColor"
            fillOpacity="0.7"
          ></circle>
        </svg>
        <span className="p-0 mb-0 ">معرفی ویژه</span>
      </div>
      <PlansX4 plansX4={pubData.ads?.plansX4} page={page} />

      {/* KEEP ***** <section>
        <div className="fixed-bg2 "></div>
      </section> */}
      {/* youtube */}
      {/* <section className="px-5">   <div className="ratio ratio-16x9 mb-5 ">
          <iframe
            className="video-frame-sidebar"
            src="https://www.youtube.com/embed/zqQGkco4VkM?mute=1&autoplay=1"
            allowFullScreen="allowFullScreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
          ></iframe>
        </div></section>
   
      KEEP ***** 
      <div className="ratio ratio-16x9 text-center px-5">
        <iframe
          className=" "
          // width="560"
          // height="315"
          src="https://www.youtube.com/embed/Qm6s8r-mh0w"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div> */}

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
