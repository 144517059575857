import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import apiMedia from "../../../utils/apiMedia";
// import { CartContext } from "../../contexts/cartContext";
// import PostsSlider from "../../golbal/sliders/postsSlider";
import BusCardSliderS1 from "../../golbal/sliders/busCardSliderS1";
import PlansX4 from "../../golbal/sliders/plansX4";
import countryStates from "../../../utils/countryStates";
import PopCatSlider from "../../golbal/sliders/popCatSlider";
import renderData from "../../../utils/renderData";
// import api from "../../../utils/api";
import NewPosts2x2 from "../../golbal/sliders/components/newPosts2x2";
import tools from "../../../utils/tools";
import MetaDecorator from "../../../utils/metaDecorator";
// import ColoredSpinner from "../../alret/spiners/coloredSpiner/coloredSpinner";

export default function MediaHome() {
  const [pubData] = useOutletContext();
  const [popCat, setPopCat] = useState([]);
  // console.log("popCat", popCat);
  const navigate = useNavigate();
  const effectRan = useRef(false);
  const [cities, setCities] = useState([]);
  const [media, setMedia] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const page = "mediaHome";
  const { lang, state:{state,stateShort,lat,lng}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
  // const [countryShort, stateShort] = window.location.pathname
  //   .toLowerCase()
  //   .split("/")
  //   .slice(2, 4);

  // const state = window.convert(
  //   countryStates
  //     .find((count) => count.countryShort.toLowerCase() === countryShort)
  //     .states.find((st) => st.stateShort.toLowerCase() === stateShort)
  //     .state.toLowerCase()
  // );

  const searchHandle = (e) => {
    e.preventDefault();
    window.scroll(0, 0);
    navigate(`filters?socialMedias=${media}&searchKey=${searchKey}`);
    const storedFilters = JSON.parse(localStorage.getItem("storedFilters"));
    if (storedFilters?.media) {
      delete storedFilters.media;
      localStorage.setItem("storedFilters", JSON.stringify(storedFilters));
    }
  };

  const mediaEnterHandle = (med) => {
    navigate(`/${window.lang}/media/filters?socialMedias=${med.name}`);
    const storedFilters = JSON.parse(localStorage.getItem("storedFilters"));
    if (storedFilters?.media) {
      delete storedFilters.media;
      localStorage.setItem("storedFilters", JSON.stringify(storedFilters));
    }
  };

  useEffect(() => {
    fetchBusPopCat();
  }, []);
  async function fetchBusPopCat() {
    const filters = {
      lang: window.lang,
      countryShort,
      stateShort,
      state,
      pageNumber: 1,
      itemsToShow: 12,
      sort: "dateB",
    };

    const response = await apiMedia.getPopCat({ filters });
    if (response.error) return console.log(response.error);
    setPopCat(response.data);
  }
  const { translations } =
    JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = `بانک شبکه های اجتماعی متامارس(متامدیا )}`;
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name:
        "متامدیا" ,
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/media`,
              name: `شبکه های اجتماعی`,
              description: `بستری برای معرفی و دسترسی گروه‌های تخصصی، شبکه‌های اجتماعی در هر گوشه از دنیا`,
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/media/filters`,
              name: `جتسجوی شبکه های اجتماعی ${translations?.[window.lang]}`,
              description: `فیلترهای پیشرفته و پر سرعت جهت نمایش دقیق سریع پیچ ها و گروه ها در شبکه های اجتماعی ${
                translations?.[window.lang]
              } در استان ${window.capitalize(state)}`,
            },
          },
        ],
      },
      about: {
        "@type": "Place",
        name: state?.toUpperCase(),
        address: {
          "@type": "PostalAddress",
          // "addressLocality": "City",
          addressRegion: state?.toUpperCase(),
          // "postalCode": "Postal Code",
          addressCountry: country?.toUpperCase(),
        },
        geo: {
          "@type": "GeoCoordinates",
          latitude: lat, // Replace with the actual latitude
          longitude: lng, // Replace with the actual longitude
        },
      },
    },
  ];
  const metaTagsData = {
    title,
    description:
      "بانک شبکه های اجتماعی | متامارس مدیا دایرکتوری | راه حلی برای یافتن کانال ها، گروه ها و صفحات اجتماعی مفید | سراسر دنیا.",
    section: "کسب و کارها",
    url: `https://www.metamarce.com/${window.lang}/media`,
    img: "https://www.metamarce.com/img/jobs/connected-world-animate.svg",
    type: "website",
    index:false
  };

  return (
    <div className="media">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <div className="my-header py-5 m-0">
        <div className="bg-image fixed-bg-media "></div>
        <div className="row">
          <div className="col-lg-8">
            <div className="row py-5">
              <div className="col-lg-6 ">
                <h1 className="mx-auto header-firstpage titr1 display-none-md">
                  متامدیا{" "}
                </h1>
                <h3 className="mx-auto header-firstpage titr3">
                  {/* راهنمای سوشیال مدیای متامارس */}
                  پلتفرم اختصاصی شبکه‌های اجتماعی
                </h3>
              </div>
              <div className="col-lg-6 text-center ">
                {/* {/* <!-- <a href="#" className="   mx-auto  ">Metamarce</a> --> */}
                {/* <!-- <h1 className="titr2 mx-auto ">"Metamarce"</h1>  --> */}
                <p className="mx-auto mt-5 header-firstpage titr2">MetaMedia</p>

                {/* <h3 className="titr4 mx-auto ">Quebec</h3> */}
              </div>
            </div>
            <div className="search-sec align-items-end align-end mb-md-4 my-5 mx-auto">
              <div name="selectSocialForm">
                <div className="row ">
                  <div className="col-lg-12">
                    <div className="row  justify-content-center">
                      <div className="col-md-4  p-0">
                        <select
                          name="mediaSel"
                          className="form-control search-slt search-slt2"
                          onChange={(e) =>
                            setMedia(e.target.value.toLowerCase())
                          }
                        >
                          <option>
                            کدوم شبکه اجتماعی
                            <span className="px-5 mx-5">🜄</span>
                          </option>
                          {renderData.socialMedias?.map((med, i) => (
                            <option key={i} value={med.name}>
                              {med[window.lang]}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-4  p-0">
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control search-slt search-slt1"
                          placeholder="دنبال چه چیزی می‌گردی؟ "
                          onKeyDown={(e) => setSearchKey(e.target.value)}
                          name="searchInputMediaHome"
                        />
                      </div>

                      <div className="col-md-4 p-0">
                        <button
                          type="button"
                          className="btn btn-danger wrn-btn"
                          onClick={searchHandle}
                        >
                          <i className="bi bi-search p-1"></i>
                          جستجوی سریع
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4   px-5  d-flex flex-wrap align-items-center display-none-lg">
            <img
              src="/img/jobs/connected-world-animate.svg"
              className=" mb-3 px-3 w-100 "
              alt="homePage"
              loading="lazy"
            />
          </div>
        </div>
        <img
          src="/img/homePage/wave-haikei-3.svg"
          className=" p-0 py-0 w-100 overlay-b"
          alt="homePage01"
          loading="lazy"
        />
      </div>

      {/* {/* <!-- ******************************* --> */}
      <div className="mx-0 py-0">
        <div className=" pb-0 pt-3">
          {/* {/* <!-- شبکه‌های اجتماعی پرطرفدار --> */}
          <div className="sections-gap special-carousel">
            <p className="divider2 mt-2">
               
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
                {/* {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
              </svg>
              <span className="p-0 m-0 ">شبکه‌های اجتماعی پرطرفدار</span>
            </p>

            <div className="row row-cols-4 max-w-1000 mx-auto my-md-5 my-3">
              {renderData.socialMedias.slice(0, 4).map((med) => (
                <div
                  className="col text-center"
                  onClick={(e) => mediaEnterHandle(med)}
                >
                  <img
                    className="med-img-categories "
                    src={med.img}
                    alt={med.name}
                    title={med?.[window.lang]}
                    loading="lazy"
                  />
                </div>
              ))}
            </div>
          </div>
          {/* Why Metamarce for media */}
          <div
            className="px-md-5 mx-0 my-5 img-banner-text-9 "
            style={{ color: "var(--darkbrown)" }}
          >
            <div className="row  banner-listing-2 w-100  ads-all mx-auto">
              <div className="col-md-5 d-flex align-items-center justify-content-center p-lg-5 img-banner-2 display-none-md">
                <img
                  className="w-100 px-md-5"
                  src="/img/illustration/online-learning-concept-2.svg"
                  alt="online-learning-concept"
                  loading="lazy"
                />
              </div>
              <div className="col-md-7  d-flex flex-column align-items-md-start align-items-center">
                <div style={{ position: "relative" }}>
                  <p className="titr1 mb-0 mt-3 ">چرا متامدیا؟</p>
                </div>
                <ul className="my-3 flex-column  align-items-start text-start">
                  {/* <li className="my-2" >
                <i className="bi bi-circle-fill fs-6 mx-2" style={{color:'var(--brown)'}}></i> 
                ثبت رایگان کسب و کار
                </li> */}
                  <li className="my-2 titr5" style={{ color: "var(--beige)" }}>
                    {/* <i className="bi bi-circle-fill fs-6 mx-2" style={{color:'var(--brown)'}}></i>  */}
                    برای کاربران
                  </li>

                  <li className="my-2">
                    <i
                      className="bi bi-circle-fill fs-6 mx-2"
                      style={{ color: "var(--brown)" }}
                    ></i>
                    یافتن گروه‌های تخصصی
                  </li>
                  <li className="my-2">
                    <i
                      className="bi bi-circle-fill fs-6 mx-2"
                      style={{ color: "var(--brown)" }}
                    ></i>
                    یافتن گروه‌های محلی | دوستانه
                  </li>
                  <li className="my-2">
                    <i
                      className="bi bi-circle-fill fs-6 mx-2"
                      style={{ color: "var(--brown)" }}
                    ></i>
                    دسته‌بندی بر اساس محتوا، کشور، شهر و ...
                  </li>

                  <li
                    className="my-2 mt-3 titr5"
                    style={{ color: "var(--beige)" }}
                  >
                    {/* <i className="bi bi-circle-fill fs-6 mx-2" style={{color:'var(--brown)'}}></i>  */}
                    برای تولیدکنندگان محتوا
                  </li>
                  <li className="my-2">
                    <i
                      className="bi bi-circle-fill fs-6 mx-2"
                      style={{ color: "var(--brown)" }}
                    ></i>
                    ثبت نام آسان، سریع، امن و رایگان
                  </li>
                  <li className="my-2">
                    <i
                      className="bi bi-circle-fill fs-6 mx-2"
                      style={{ color: "var(--brown)" }}
                    ></i>
                    دسترسی به مخاطبان بیشتر
                  </li>
                  <li className="my-2">
                    <i
                      className="bi bi-circle-fill fs-6 mx-2"
                      style={{ color: "var(--brown)" }}
                    ></i>
                    فرصت‌ همکاری با برندها و کسب‌وکارها
                  </li>

                  <li className="my-2">
                    <i
                      className="bi bi-circle-fill fs-6 mx-2"
                      style={{ color: "var(--brown)" }}
                    ></i>
                    فرصت‌ شبکه‌سازی
                  </li>
                </ul>
                <div
                  className="btn primary-btn view-cart cart-footer w-75 mx-md-5 mx-0 mb-5"
                  onClick={(e) => {
                    if (!window.logined(9999, "media")) {
                      return window.navigateHandle(
                        `/${window.lang}/media/register`
                      );
                    }
                    window.scroll({ top: 0, behavior: "smooth" });
                    navigate(`/${window.lang}/dashboard/user/media/register`);
                  }}
                >
                  <i className="bi bi-pencil-square p-1"></i>
                  افزودن شبکه اجتماعی
                </div>
                {/* <!-- </button> --> */}
              </div>
            </div>
          </div>

          {/* <!-- معرفی ویژه --> */}

          <div className="mx-auto px-md-5 px-0">
            <div className="divider2 ">
               
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
              </svg>
              <span className="p-0 mb-0 ">معرفی ویژه</span>
            </div>
            <PlansX4 plansX4={pubData.ads?.plansX4} page={page} />
            <div className="divider2 ">
               
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
                {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
              </svg>
              <span className="p-0 mb-0 ">برگزیدگان</span>
            </div>

            <BusCardSliderS1 plansS1={pubData?.ads?.plansS1} page={page} />
          </div>

          {/* <!-- معرفی ویژه end of --> */}

          <div>
            {/* <div className=" mt-0 py-5 banner-listing-2 img-banner-text-3 "> */}
            {/* <!-- تازه‌های بلاگ --> */}
            <PopCatSlider data={popCat} section="media" />
          </div>

          {/* <!-- تازه‌ترین‌ها --> */}
          {/* <div className="mx-auto px-md-5 px-0">
            <div className="row row-cols-1  banner-listing-2 w-100 mx-0 ">
              <div className="divider2 ">
                 
                <svg
                  className="text-dark-550 dark:text-white ml-2"
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                  <circle
                    cx="35"
                    cy="16"
                    r="8"
                    fill="currentColor"
                    fillOpacity="0.4"
                  ></circle>
                  <circle
                    cx="20"
                    cy="8"
                    r="5"
                    fill="currentColor"
                    fillOpacity="0.7"
                  ></circle>
                </svg>
                <span className="p-0 mb-0 ">تازه‌ترین‌ها</span>
              </div>
            </div>
          </div> */}
        </div>

        <div className="mx-md-5  ">
          {/* <!-- تازه‌های بلاگ --> */}
          <div className="mx-auto px-md-5 px-0">
            <div className="divider2 ">
               
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
                {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
              </svg>
              <span className="p-0 mb-0 ">تازه‌های بلاگ</span>
            </div>

            <div className="row row-cols-1 row-cols-lg-2 g-4 my-3 ads-all mx-auto ">
              <NewPosts2x2 newPosts={pubData?.newPosts} />
            </div>
          </div>
          {/* <!-- تازه‌های بلاگ end of --> */}
        </div>

        {/* <!-- ********** back to top************************* --> */}
        <div className="row backtotop">
          <div className="col-12">
            <a
              href="#top"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="بازگشت به آغاز صفحه"
              className="tooltip-color"
            >
              <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
            </a>
          </div>
        </div>
      </div>
      {/* <!-- *********************************** end of main ******************************************************************** --> */}

      {/* <!-- ***JavaScript*** --> */}

      {/* <div>
       
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
          crossOrigin="anonymous"
        ></script>

      
      </div> */}
    </div>
  );
}
