import { useEffect, useRef, useState } from "react";
import api from "../../../utils/api";
import tools from "../../../utils/tools";

export default function SearchMultiSelectFilters({ data, updateParent }) {
  const [orgOpt, setOrgOpt] = useState([]);
  const [options, setOptions] = useState([]);
  const ulRef = useRef();
  const inputRef = useRef();
  const { lang, state:{state,stateShort}, country, countryShort } =
    tools.getItemFromLocalStorage("initSetup", {});
  // const [countryShort, stateShort] = window.location.pathname
  //   .toLowerCase()
  //   .split("/")
  //   .slice(2, 4);
  const onInputChange = (e) => {
    const newOptions = orgOpt.filter((options) =>
      window
        .convert(options.name)
        .toLowerCase()
        .includes(e?.target?.value.toLowerCase())
    );
    setOptions(newOptions);
  };
  useEffect(() => {
    ulRef.current.style.display = "flex";
    getCitiesHandle();
  }, [data?.stateShort]);

  async function getCitiesHandle() {
    //console.log(e.target);
    const country =
      (data?.countryShort?.length && data.countryShort) || countryShort;
    const state = (data?.stateShort?.length && data.stateShort) || stateShort;
    const response = await api.getCities(country, state);
    if (response.error) return window.alert("f" + response.error);
    // console.log('useEffect',response);
    setOrgOpt(response.data);
    setOptions(response.data);
  }
  return (
    <div>
      <div className="  btn-search d-flex justify-content-between align-items-center w-100 ">
        <span className="w-100 text-center ">
          <input
            ref={inputRef}
            onChange={onInputChange}
            className="form-input3 shadow-none pe-2 w-100"
            placeholder={
              !data.cities?.length
                ? "نام شهر یا محله را بنویسید"
                : data.cities?.length > 1
                ? `${data.cities.length}Items Selected`
                : data.cities[0]
            }
            name="cityFormInput"
            autoComplete="off"
          />
        </span>
      </div>
      <ul
        ref={ulRef}
        id="result"
        className="list-group list-group-multiselect mt-3"
      >
        <div className="reset-btn" onClick={(e) => updateParent([])}>
          پاک کردن !
        </div>
        {options?.map((option, i) => {
          return (
            <div
              key={i}
              className="form-check pe-3 ps-5 my-1 d-flex justify-content-between align-items-center"
            >
              <input
                className="form-check-input"
                type="checkbox"
                value={option.name}
                checked={data.cities?.includes(option.name)}
                id={`flexCheckOptions${i}`}
                onChange={(e) => {
                  const value = e.target.value;
                  const cities = data?.cities ? [...data.cities] : [];
                  const index = cities.indexOf(value);
                  if (index !== -1) {
                    cities.splice(index, 1);
                  } else {
                    cities.push(option.name);
                  }
                  updateParent(cities);
                }}
              />
              <label
                className="form-check-label"
                htmlFor={`flexCheckOptions${i}`}
              >
                {option.name}
              </label>
            </div>
          );
        })}
      </ul>
    </div>
  );
}
