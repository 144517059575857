import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useRef, useState } from "react";
import { Check, CheckCircle, Error, Publish } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import MyIcons from "../../../golbal/icons/myIcons";
import { Link, useParams } from "react-router-dom";
import renderData from "../../../../utils/renderData";
import FormikErrRender from "../../../persian/common/alerts/formikErrRender";
import addErrClass from "../../../persian/common/alerts/addErrClass";
import api from "../../../../utils/api";
import MetaDecorator from "../../../../utils/metaDecorator";
// import demyData from "../../../../utils/demyData";
import tools from "../../../../utils/tools";
import CatsSelector from "../../../golbal/catsSelector";
// import DropdownSearch from "../../../golbal/dropdown/DropdownSearch";
// import SearchMultiSelectFilters from "../../../golbal/dropdown/searchMultiSelectFilters";
// import countryStates from "../../../../utils/countryStates";
import CountryStateCitySelector from "../../../golbal/dropdown/countryStateCitySelector";
import apiMP from "../../../../utils/apiMP";
import FormikListErr from "../../../persian/common/alerts/formikListErr";
import { isValidPhoneNumber } from "react-phone-number-input";
import DatePickerRender from "../../../golbal/datePicker";

export default function UsedRegister({ action }) {
  const { lang, state:{state,stateShort}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
  const { translations } =
  JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  // const [states, setStates] = useState([]);
  // const [resisedPhotoAuth, setResisedPhotoAuth] = useState(null);
  // const [originalPhoto, setOriginalPhoto] = useState(null);
  // const [resisedLogoAuth, setResisedLogoAuth] = useState(null);
  // const [originalLogo, setOriginalLogo] = useState(null);
  const [photos, setPhotos] = useState({
    blob: [],
    resized: [],
    urls: [],
  });
  
  const sendBodyPhotosFirebase = async () => {
    if (photos.blob == []) return;
    console.log("sendBusinessCardToFirebase04");
    const uplaodedURLS2 = await Promise.all(
      photos.blob?.map(async (img) => {
        const formData = new FormData();
        formData.append("file", img);
        const response = await api.uploadSingleFile({
          token: user.token,
          formData,
          path: "images/mp/used",
        });
        if (response.error)
          return messageRef.current.showError({
            message: (
              <div>
                <div>مشکل در ذخیره فایل.</div>
                <div dir="ltr">{response.error}</div>
              </div>
            ),
          });
        return response.data;
      })
    );
    console.log("sendBusinessCardToFirebase04", uplaodedURLS2);
    return uplaodedURLS2;
  };
  function deleteHandle(e) {
    const newArray = photos.resized.filter((x, i) => i != e.target.id);
    const newArrayBlob = photos.blob.filter((x, i) => i != e.target.id);
    setPhotos((prevPhotos) => ({
      ...prevPhotos,
      resized: newArray,
      blob: newArrayBlob,
    }));
  }
  const messageRef = useRef(null);
  // const navigate = useNavigate();
  const { user, signedUser } = useContext(CartContext);
  const [loading, setLoading] = useState({ spinner: false, addSub: false });
  const countryDetails = JSON.parse(localStorage.getItem("countryDetails"));
  //  console.log('countryDetails',countryDetails);
  const [currencies, setCurrencies] = useState([
    { currency: "CAD", currency_name: "CAD", exRate: 1 },
    { currency: "USD", currency_name: "USD", exRate: 0.8 },
    { currency: "EUR", currency_name: "EUR", exRate: 0.7 },
    { currency: "AUD", currency_name: "AUD", exRate: 1.2 },
    { currency: "GBP", currency_name: "GBP", exRate: 0.6 },
    { currency: "IRR", currency_name: "ریال", exRate: 500000 },
  ]);
  const { id } = useParams();

  const urlRegex =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;



  const formik = useFormik({
    initialValues: {
      action,
      terms: false,
      // logo: "",
      title: "",
      description: "",
      _id: "",
      lang,
      lcs: window.lcs,
      tags: `نیازمندیهای ${translations[window.lang]||country}+خرید و فروش و رهن و اجاره در${translations[window.lang]||country}+بزرگترین سایت نیازمندیهای ${translations[window.lang]||country}+ کی چی کجا ${translations[window.lang]||country}+لوازم دست دوم ${translations[window.lang]||country}`,
      email: user?.email,
      category: {
        mainCat: {},
        // subCat: {},
      },
      photos: [],
      contact: "",
      link: "",
      phone: "",
      carInfo: {
        year: "",
        make: "",
        model: "",
        transmission: "",
        fuel: "",
        color: "",
        odometer: "",
        class: "",
      },
      homeInfo: {
        year: "",
        transType: "",
        proType: "",
        lot: "",
        build: "",
        sqf: "",
        beds: "",
        bath: "",
        // laundry: "",
        parking: "",
        parkingQty: "",
        airCondition: "",
        heating: "",
        hFacilities: [],
      },
      tripInfo: {
        from:{
          date:"", 
          location: {           
            country,
            countryShort,
            state,
            stateShort,
            city: "",
          },
        },
        to:{
          date: "",
          location: {           
            country:"",
            countryShort:"",
            state:"",
            stateShort:"",
            city: "",
          },
        },
        activityType: "",
        capacity: 0,
      },
      condition: "usedGood",
      delivery: "doorPick",
      isFree: false,
      isActive: true,
      price: {
        rate: 0,
        currency: countryDetails?.currency || "CAD",
      },
      location: {
        displayAddress: false,
        preciseAddress: "",
        googleAddress: "",
        country,
        countryShort,
        state,
        stateShort,
        city: "",
        // route: "",
        // municipality: "",
        // streetNumber: "",
        // number: "",
        // postalCode: "",
        // lat: "",
        // lng: "",
      },
    },
    onSubmit: async (values) => {
      if (values?.action === "edit")
        return editHandle({ messageRef, setLoading, user, values });
      const photos = await sendBodyPhotosFirebase();
      values.photos = photos;
      console.log("passed Formik", values);

      setLoading((pre) => ({ ...pre, spinner: true }));
      const response = await apiMP.registerUsed({
        token: user.token,
        data: values,
      });
      setLoading((pre) => ({ ...pre, spinner: false }));
      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      messageRef.current.showSuccess({
        message: "تبریک، نیازمندی با موفقیت ثبت شد.",
      });

      const updateduser = await api.getUser();
      if (updateduser.error) return;
      signedUser(updateduser.data);
      window.navigateHandle(`/${window.lang}/mp/used/filters`, response.data);
    },
    validationSchema: Yup.object({
      terms: Yup.boolean().oneOf(
        [true],
        " قوانین افزودن آگهی را مطالعه و سپس دکمه قبول را فشار دهید."
      ),
      link: Yup.string().matches(
        urlRegex,
        " لطفا لینک تماس را درست وارد نمائید."
      ),
      // name: Yup.string()
      //   .notRequired("لطفاً نام شرکت را وارد کنید.")
      //   .max(200, "نام شرکت حداکثر باید 200 حرف باشد .")
      //   .min(3, "نام شرکت حداقل باید 3 حرف باشد."),
      title: Yup.string()
        .required("لطفاً عنوان آگهی را وارد کنید.")
        .max(200, "عنوان آگهی حداکثر باید 200 حرف باشد .")
        .min(3, "عنوان آگهی حداقل باید 3 حرف باشد."),

      description: Yup.string()
        .required("لطفاً توضیحات را وارد کنید.")
        .max(4000, "توضیحات حداکثر باید 4000 حرف باشد .")
        .min(10, "توضیحات حداقل باید 10 حرف باشد."),
      category: Yup.object().shape({
        mainCat: Yup.object().shape({
          _id: Yup.string().required(" دسته‌بندی را انتخاب کنید."),
        }),
        subCat: Yup.object().shape({
          _id: Yup.string().required(" دسته بندی کوچکتر را انتخاب کنید."),
        }),
      }),
      phone: Yup.string()
        .trim()
        .when("suggestMode", (suggestMode, schema, value) => {
          return (
            value.parent.phone?.length &&
            schema.test(
              "is-valid-phone",
              "شماره تلفن نامعتبر میباشد.",
              (value) => {
                return isValidPhoneNumber(value || "");
              }
            )
          );
        }),
      // ... your other validation rules for phone

      email: Yup.string()
        .required("لطفا ایمیل خود را وارد نمائید.")
        .trim()
        .lowercase()
        .email("ایمیل را به درستی وارد کنید."),
      // languages: Yup.array()
      //   .required(" دست کم یک زبان را انتخاب کنید.")
      //   .test(
      //     "array-length",
      //     " دست کم یک زبان را انتخاب کنید.",
      //     (value) => value.length > 0
      //   ),

      location:  Yup.object().when("$category", {
        is: (category) =>!(isTrip&&!isStudy&&!isMeet&&!isFun) ,
        then: Yup.object().shape({
        country: Yup.string().required("کشور نمیتواند خالی باشد."),
        state: Yup.string().required("استان نمیتواند خالی باشد."),
        city: Yup.string().required("شهر نمیتواند خالی باشد."),
        municipality: Yup.string(),
      }),
      }),
      // website: Yup.string().matches(
      //   urlRegex,
      //   " آدرس وبسایت را به درستی وارد کنید."
      // ),
      // socialMedia: Yup.array().of(
      //   Yup.object().shape({
      //     platform: Yup.string(),

      //     url: Yup.string()
      //       .when("platform", (platform, schema) => {
      //         return platform
      //           ? schema.required("لطفاً آدرس URL را وارد نمایید.")
      //           : schema;
      //       })
      //       .test("validUrl", " آدرس را به شکل درست وارد کنید.", (value) => {
      //         if (!value) return true; // Allow empty value
      //         // Regular expression to validate URL
      //         return urlRegex.test(value);
      //       }),
      //   })
      // ),
      // availability: Yup.array().of(
      //   Yup.object().shape({
      //     availableDay: Yup.string().required("لطفا روزهای کاری را انتخاب نمائید."),
      //     availableTime: Yup.string().required("لطفا ساعات کاری را انتخاب نمائید."),
      //   })
      // ),
      // distances: Yup.array().of(
      //   Yup.object().shape({
      //     distance: Yup.string().required("Please enter the distance."),
      //     cost: Yup.string().required("Please enter the cost."),
      //   })
      // ),
      price: Yup.object().shape({
        currency: Yup.string()
          .required("واحد پول را وارد کنید.")
          .min(1, "واحد پول نامعتبر است."),
        rate: Yup.number().when("$isFree", {
          is: (isFree) => !isFree,
          then: Yup.number()
            .required("نرخ نمیتواند خالی باشد.")
            .min(1, "قیمت باید بزرگتر از صفر باشد و یا دکمه رایگان را بزنید"),
          otherwise: Yup.number().notRequired(),
        }),
      }),
      carInfo: Yup.object().when("$category", {
        is: (category) =>
          (category?.subCat?._id === "66c1285fc79e6e1af3154801" ||
            category?.subCat?._id === "66c128a0c79e6e1af3154807") &&
          true,
        then: Yup.object().shape({
          year: Yup.number()
            .required("لطفا سال ساخت وسیله راانتخاب کنید.")
            .min(1900, "لطفا سال ساخت را درست انتخاب کنید."),
          make: Yup.string().required("سازنده نمیتواند خالی باشد."),
          model: Yup.string().required("مدل/تیپ وسیله نمیتواند خالی باشد."),
          transmission: Yup.string().required(
            "لطفا نوع گیربکس را انتخاب کنید."
          ),
          fuel: Yup.string().required("لطفا نوع سوخت را انتخاب کنید."),
          color: Yup.string().required("رنگ وسیله نمیتواند خالی باشد."),
          odometer: Yup.string().required("لطفا کارکرد وسیله را وارد نمائید."),
          class: Yup.string().required("لطفا کلاس وسیله را انتخاب کنید."),
        }),
        otherwise: Yup.object(),

        // .shape({
        //   // year: Yup.string(),
        //     // .required("سال نمی تواند خالی باشد."),
        //   make: Yup.string().when("$category", {
        //     is: (category) =>
        //       (category?.subCat?._id === "66c1285fc79e6e1af3154801" ||
        //         category?.subCat?._id === "66c128a0c79e6e1af3154807") &&
        //       true,
        //     then: Yup.string()
        //       .required("سازنده نمیتواند خالی باشد.")
        //       .min(1, "قیمت باید بزرگتر از صفر باشد و یا دکمه رایگان را بزنید"),
        //     otherwise: Yup.string().notRequired(),
        //   }),
      }),
      homeInfo: Yup.object().when(["$category", "$homeInfo"], {
        is: (category, homeInfo) =>
          category?.mainCat?._id === "66c126fec79e6e1af31547d1" &&
          homeInfo?.proType !== "lot",
        then: Yup.object().shape({
          year: Yup.number().notRequired("لطفا سال ساخت را انتخاب کنید."),
          proType: Yup.string().required("لطفا نوع دارایی را انتخاب کنید."),
          build: Yup.number().notRequired("لطفا مساحت خانه را وارد کنید."),
          beds: Yup.number().required("لطفا تعداد اتاق خواب را انتخاب کنید."),
          bath: Yup.number().required(
            "لطفا تعداد سرویس بهداشتی را انتخاب کنید."
          ),
          // laundry: Yup.string().required("لطفا وضعیت لاندری را انتخاب کنید."),
          parking: Yup.string().required("لطفا نوع پارکینگ را انتخاب کنید."),
          airCondition: Yup.string().required(
            "لطفا نوع سیستم سرمایشی را انتخاب کنید."
          ),
          heating: Yup.string().required(
            "لطفا نوع سیستم گرمایشی را انتخاب کنید."
          ),
        }),
        otherwise: Yup.object().when("$homeInfo.proType", {
          is: "lot",
          then: Yup.object().shape({
            lot: Yup.number().required("لطفا مساحت زمین را وارد کنید."),
          }),
          otherwise: Yup.object(),
        }),
      }),
      tripInfo: Yup.object().when(["$category", "$tripInfo"], {
        is: (category, tripInfo) =>
          isTrip&&!isStudy&&!isMeet&&!isFun,
        then: Yup.object().shape({
          from : Yup.object().shape({
            date:Yup.date()
            .required(" تاریخ سفر/شروع را وارد کنید.")
            .typeError("تاریخ سفر/شروع نامعتبر می باشد.")
            .min(new Date(new Date().setHours(0, 0, 0, 0)), "تاریخ سفر/شروع نمی تواند در گذشته باشد."),
            location: Yup.object().shape({
              country: Yup.string().required("لطفا کشور مبدا را انتخاب نمائید."),
              state: Yup.string().required("لطفا استان مبدا را انتخاب نمائید."),
              city: Yup.string().required("لطفا شهر مبدا را انتخاب نمائید."),
              municipality: Yup.string(),
            }),
          
          }),
          to : Yup.object().shape({            
            location: Yup.object().shape({
              country: Yup.string().required("لطفا کشور مقصد را انتخاب نمائید."),
              state: Yup.string().required("لطفا استان مقصد را انتخاب نمائید."),
              city: Yup.string().required("لطفا شهر مقصد را انتخاب نمائید."),
              municipality: Yup.string(),
            }),
          }),
          activityType:Yup.string().when("$category", {
            is: (category) =>
              isPackage||isAccomp||isRide,
          then:Yup.string().required("لطفا نقش خود را در سفر انتخاب کنید ."),}),
          capacity:Yup.number().when("$category", {
            is: (category) =>
              isPackage,
          then:Yup.number().min(.1,"لطفا ظرفیت اضافی خود را برای پذیرش محموله مشخص نمائید .").required("لطفا ظرفیت حمل بار خود را مشحص نمائید ."),}),
          // capacity: Yup.number().min(.1,"لطفا ظرفیت اضافی خود را برای پذیرش محموله مشخص نمائید .").required("لطفا ظرفیت حمل بار خود را مشحص نمائید ."),
        }),
        otherwise: Yup.object()
      }),
      // languages: Yup.array().of(
      //   Yup.object().shape({
      //     level: Yup.string().notRequiredequired("لطفا مقطع آموزشی را انتخاب نمائید."),
      //     subjects: Yup.array()
      //       .of(Yup.string()) // Validate individual subject items if needed
      //       .min(1, "لطفا دست کم یک سرفصل آموزشی را انتخاب نمائید.") // Ensure at least one subject is entered
      //   })
      // )
    }),
  });


  const isTrip = formik.values?.category?.mainCat?._id ==="66c0d7e8c79e6e1af314f511"
  const isPackage = formik.values?.category?.subCat?._id==="66f9e687a5945e5e21d40e57"
  const isRide = formik.values?.category?.subCat?._id==="66c0fc4fc79e6e1af314f52a"
  const isStudy = formik.values?.category?.subCat?._id==="66c0deb7c79e6e1af314f51a"
  const isRecre = formik.values?.category?.subCat?._id==="66c5eb2dd01eec4f688d1b68"
  const isAccomp = formik.values?.category?.subCat?._id==="66c0fc1dc79e6e1af314f523"
  const isMeet = formik.values?.category?.subCat?._id==="670eea450e4cc9045a43d5b2"
  const isFun = formik.values?.category?.subCat?._id==="670ee9b60e4cc9045a43d3b3"

  useEffect(() => {
    fetchDetails();
    // fetchSubjects()
  }, [id]);

  async function fetchDetails() {
    console.log(id);
    if (!id) return;
    const response = await apiMP.usedDetails({ token: user.token, id });
    if (response.error) return alert(response.error);
    // console.log(response.data);
    editInitialData(formik, response.data, action);
  }
  const bodyPhotosHandle = async (e) => {
    if (!tools.photoCheck(e))
      return messageRef.current.showError({
        message: " فرمت فایل را از میان  jpeg , png , gif انتخاب کنید.",
      });

    setLoading((pre) => ({ ...pre, loading14: true }));
    const resizedPhotos = await Promise.all(
      [...Array.from(e.target.files)]
        .slice(0, 6)
        .map(
          async (x) =>
            await tools.resizeFile({ file: x, width: 1000, height: 1000 })
        )
    );
    const resizedPhotosBlob = await Promise.all(
      [...Array.from(e.target.files)].slice(0, 6).map(
        async (x) =>
          await tools.resizeFile({
            file: x,
            width: 1000,
            height: 1000,
            blob: true,
          })
      )
    );
    setLoading((pre) => ({ ...pre, loading14: false }));
    if (resizedPhotos.length < 6) {
      setPhotos((prevPhotos) => ({
        ...prevPhotos,
        resized: photos.resized
          .slice(resizedPhotos.length - 6)
          .concat(resizedPhotos),
        blob: photos.blob
          .slice(resizedPhotos.length - 6)
          .concat(resizedPhotosBlob),
        urls: [],
      }));
    } else {
      setPhotos((prevPhotos) => ({
        ...prevPhotos,
        resized: resizedPhotos,
        blob: resizedPhotosBlob,
        urls: [],
      }));
    }
  };

  console.log("formik", formik.values);
  console.log("formik.errors", formik.errors);
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "University Register",
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/dashboard/mp/registerused`,
              name: "ثبت رایگان کی چی کجا",
              description: "ثبت آسان، انتشار سریع و خودکار آگهی نیازمندیها",
            },
          },
        ],
      },
    },
  ];

  const metaTagsData = {
    title: "کی چی کجا",
    description: "ثبت آگهی نیازمندی در کی چی کجا",
    section: "used register",
    url: `https://www.metamarce.com/${window.lang}/mp/registerused`,
    img: "https://www.metamarce.com/img/demy/Hiring-rafiki.svg",
    type: "register",
  };
 
  return (
    <div className="user  mt-3  px-0">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      {loading.spinner && <ColoredSpiner />}
      <ToastNote messageRef={messageRef} />
      <p className="divider2 border-0 mt-3 ">
           <MyIcons icon="dotsTitr" />
        <span className="p-0 m-z ">{action === "edit"?"ویرایش":"ثبت"} آگهی کی چی کجا </span>
      </p>
      {user?.roles?.includes(9999) ? (
        <form
          onSubmit={formik.handleSubmit}
          className="userShow mx-3"
          name="jobRegForm00"
          autoComplete="true"
        >
          {/* Right Section */}
          <div className="row">
            <div className="col-md-6 px-md-3">
              <div className="mb-5 ">
                <CatsSelector formik={formik} section={"used"} />
                <div className="my-5">
                  <div className="form-label titr6 required">مبلغ</div>
                  <div className="form-switch mb-2 ">
                    <input
                      className="form-check-input form-check-input-filter1  me-2 p-0 cursor"
                      type="checkbox"
                      role="switch"
                      id="activeSwitchFree"
                      checked={formik.values?.isFree}
                      onChange={(e) =>
                        formik.setValues((prev) => ({
                          ...prev,
                          isFree: !formik.values.isFree,
                          price: { rate: 0, currency: prev.price.currency },
                        }))
                      }
                    />
                    <label htmlFor="activeSwitchFree">رایگان</label>
                  </div>

                  {!formik.values.isFree && (
                    <div className="d-flex">
                      <div className=" mx-2 d-flex">
                        <input
                          id="price-rate"
                          type="number"
                          autoComplete="off"
                          aria-label="Amount"
                          className={`form-control ${addErrClass({
                            formik,
                            field: "price.rate",
                          })}`}
                          placeholder="با حروف لاتین تایپ کنید"
                          // {...formik.getFieldProps("price.rate")}
                          value={formik.values.price.rate}
                          onChange={(e) =>
                            formik.setValues((pre) => ({
                              ...pre,
                              price: {
                                ...pre.price,
                                rate: e.target.value,
                              },
                            }))
                          }
                        />
                        <select
                          className={`login-btn my-0 ms-1 ${addErrClass({
                            formik,
                            field: "price.currency",
                          })}`}
                          name="salaryCurrency"
                          value={formik.values.price.currency}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "price.currency",
                              e.target.value
                            )
                          }
                        >
                          <option value="">انتخاب کنید :</option>
                          {currencies.map((curr) => (
                            <option
                              key={curr.currency} // Add a unique key
                              value={curr.currency} // Set the currency value
                            >
                              {curr.currency_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {/* ({formik.values.price.currency}) */}
                    </div>
                  )}
                  <FormikErrRender formik={formik} field="price.rate" />
                  <FormikErrRender formik={formik} field="price.currency" />
                </div>

                <div>
                  <label
                    className="form-label titr6 mb-0 required"
                    htmlFor="title"
                  >
                    عنوان آگهی
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className={`form-control py-1 box2 ${addErrClass({
                      formik,
                      field: "title",
                    })} `}
                    id="title"
                    placeholder=""
                    name="title"
                    defaultValue={formik.values.title}                    
                    onBlur={(e) =>
                      formik.setValues((pre) => ({
                        ...pre,
                        title: e.target.value,
                      }))
                    }
                    // {...formik.getFieldProps("title")}
                  />
                  <FormikErrRender formik={formik} field="title" />
                </div>
                {(formik.values.category?.subCat?._id ===
                  "66c1285fc79e6e1af3154801" ||
                  formik.values.category?.subCat?._id ===
                    "66c128a0c79e6e1af3154807") && (
                  <CarInfoRender formik={formik} />
                )}
                {formik.values?.category?.mainCat?._id ===
                  "66c126fec79e6e1af31547d1" && (
                  <HomeInfoRender formik={formik} />
                )}
                {isTrip&&!isStudy&&!isMeet&&!isFun && (
                  <TripInfoRender formik={formik} isPackage={isPackage} isRide={isRide} isAccomp={isAccomp} />
                )}
                <div className="mt-2">
                  <p className="form-label mt-5 titr6 mb-0">وضعیت آگهی</p>
                  <select
                    className={`form-select ${addErrClass({
                      formik,
                      field: "status",
                    })}`}
                    name="status"
                    // value={formik.values.status}
                    // onChange={(e) =>
                    //   formik.setFieldValue("status", e.target.value)
                    // }
                    {...formik.getFieldProps("status")}
                  >
                    {renderData.usedStatus.map((st, i) => (
                      <option key={i} value={st.name}>
                        {" "}
                        {st[window.lang]}
                      </option>
                    ))}
                  </select>
                </div>
               
                {!isTrip&&<div>
                <div className="">
                  <p className="form-label mt-3 titr6 mb-0"> وضعیت سلامت</p>
                  <select
                    className={`form-select ${addErrClass({
                      formik,
                      field: "condition",
                    })}`}
                    name="condition"
                    // value={formik.values.condition}
                    // onChange={(e) =>
                    //   formik.setFieldValue("condition", e.target.value)
                    // }
                    {...formik.getFieldProps("condition")}
                  >
                    {renderData.condition.map((st, i) => (
                      <option key={i} value={st.name}>
                        {" "}
                        {st[window.lang]}
                      </option>
                    ))}
                  </select>
                  {/* <div className="d-flex flex-wrap">
                        {renderData?.condition?.map((cond) => (
                          <div className="form-check form-check-px1  d-flex m-2">
                            <input
                              checked={formik.values.condition === cond.name}
                              className="form-check-input"
                              type="radio"
                              name="condition"
                              id={"condition" + cond.name}
                              value={cond.name}
                              onChange={(e) =>
                                formik.setValues((pre) => ({
                                  ...pre,
                                  condition: e.target.value,
                                }))
                              }
                            />
                            <label
                              className="form-check-label d-flex ps-1"
                              htmlFor={"condition" + cond.name}
                            >
                              {cond[lang]}
                            </label>
                          </div>
                        ))}
                      </div> */}
                </div>
                <div className="">
                  <p className="form-label mt-3 titr6 mb-0">محل تحویل</p>
                  <select
                    className={`form-select ${addErrClass({
                      formik,
                      field: "delivery",
                    })}`}
                    name="delivery"
                    // value={formik.values.delivery}
                    // onChange={(e) =>
                    //   formik.setFieldValue("delivery", e.target.value)
                    // }
                    {...formik.getFieldProps("delivery")}
                  >
                    {renderData.delivery.map((st, i) => (
                      <option key={i} value={st.name}>
                        {" "}
                        {st[window.lang]}
                      </option>
                    ))}
                  </select>
                  {/* <div className="d-flex flex-wrap">
                        {renderData?.delivery?.map((deliver) => (
                          <div className="form-check form-check-px1 m-2 d-flex">
                            <input
                              checked={formik.values.delivery === deliver.name}
                              className="form-check-input"
                              type="radio"
                              name="delivery"
                              id={"delivery" + deliver.name}
                              value={deliver.name}
                              onChange={(e) =>
                                formik.setValues((pre) => ({
                                  ...pre,
                                  delivery: e.target.value,
                                }))
                              }
                            />
                            <label
                              className="form-check-label d-flex ps-1"
                              htmlFor={"delivery" + deliver.name}
                            >
                              {deliver[lang]}
                            </label>
                          </div>
                        ))}
                      </div> */}
                </div>
                </div>}

                <div className="my-5">
                  <label htmlFor="description" className="form-label titr6">
                    توضیحات
                    <Tooltip1 message="اگر نیاز دارید در مورد آگهی اطلاعات بیشتری را منتشر نمائید می توانیداز این باکس استفاده نمائید." />
                  </label>
                  <textarea
                    id="description"
                    className={`form-control ${addErrClass({
                      formik,
                      field: "description",
                    })}`}
                    rows="3"
                    name="description"
                    defaultValue={formik.values.description}
                    onChange={(e) => {
                      e.target.style.height = "auto";
                      e.target.style.height = e.target.scrollHeight + "px";
                     }}
                    onBlur={(e) =>
                      formik.setValues((pre) => ({
                        ...pre,
                        description: e.target.value,
                      }))
                    }
                  ></textarea>
                  <FormikErrRender formik={formik} field="description" />
                </div>
                {!(isTrip&&!isStudy&&!isMeet&&!isFun)&&<div>
                <div>
                  <p className="form-label mt-5 titr6 required">                  
                    آدرس آگهی
                  </p>
                  <CountryStateCitySelector formik={formik} />
                </div>
                <div className="mb-5">
                  <label className="form-label titr6">
                    آدرس دقیق‌تر
                    <Tooltip1 message="در صورت تمایل میتوانید جزئیات بیشتری از محل خود منتشر نمائید." />
                  </label>
                  <textarea
                    className={`form-control ${addErrClass({
                      formik,
                      field: "preciseAddress",
                    })}`}
                    rows="3"
                    name="preciseAddress"
                    defaultValue={formik.values.location.preciseAddress}
                    onChange={(e) => {
                      e.target.style.height = "auto";
                      e.target.style.height = e.target.scrollHeight + "px";
                     }}
                    onBlur={
                      (e) =>
                        formik.setFieldValue(
                          "location.preciseAddress",
                          e.target.value
                        )
                      // formik.setValues((pre) => ({
                      //   ...pre,
                      //   preciseAddress: e.target.value,
                      // }))
                    }
                  ></textarea>
                  <FormikErrRender
                    formik={formik}
                    field="location.preciseAddress"
                  />
                </div>
                </div>}
                <div className="mb-5">
                  <label className="form-label titr6">
                    اطلاعات تماس
                    <Tooltip1 message="کاربران میتوانند از طریق متامارس برای شما درخواست ارسال نمایند با اینحال در صورت تمایل میتوانید اطلاعات تماس خود را منتشر نمائید." />
                  </label>
                  <textarea
                    className={`form-control ${addErrClass({
                      formik,
                      field: "contact",
                    })}`}
                    rows="3"
                    name="contact"
                    defaultValue={formik.values.contact}
                    onChange={(e) => {
                      e.target.style.height = "auto";
                      e.target.style.height = e.target.scrollHeight + "px";
                     }}
                    onBlur={(e) =>
                      formik.setValues((pre) => ({
                        ...pre,
                        contact: e.target.value,
                      }))
                    }
                  ></textarea>
                  <FormikErrRender formik={formik} field="contact" />
                </div>

                {/* <div className="mb-5">
                    <label className="form-label titr6">Hobbies</label>
                    <textarea
                      className={`form-control ${addErrClass({
                        formik,
                        field: "hobbies",
                      })}`}
                      rows="3"
                      name="hobbies"
                      defaultValue={formik.values.hobbies}
                       onChange={(e) => {
                      e.target.style.height = "auto";
                      e.target.style.height = e.target.scrollHeight + "px";
                     }}
                      onBlur={(e) =>
                        formik.setValues((pre) => ({
                          ...pre,
                          hobbies: e.target.value,
                        }))
                      }
                    ></textarea>
                    <FormikErrRender formik={formik} field="hobbies" />
                  </div> */}

                <div>
                  <label
                    className="form-label  titr6 mb-0 required"
                    htmlFor="emailApply"
                  >
                    ایمیل
                    <Tooltip1
                      message={
                        <div>
                          <div>این ایمیل محرمانه است و منتشر نخواهد شد.</div>
                          <div>
                            درخواستها توسط متامارس به آن فرستاده می‌شود.
                          </div>
                        </div>
                      }
                    />
                  </label>
                  <input
                    dir="ltr"
                    id="emailApply"
                    {...formik.getFieldProps("email")}
                    className={`userUpdateInput  form-control box2 ${addErrClass(
                      {
                        formik,
                        field: "email",
                      }
                    )}`}
                    type="text"
                    autoComplete="off"
                    placeholder={formik.values.email}
                  />
                  <FormikErrRender formik={formik} field="email" />
                </div>
               {tools.compareCheck(user?.roles , [1000,1900])&&<div>
                  <label
                    className="form-label  titr6 mb-0 required"
                    htmlFor="linkApply"
                  >
                    افزودن لینک                  
                  </label>
                  <input
                    dir="ltr"
                    id="linkApply"
                    {...formik.getFieldProps("link")}
                    className={`userUpdateInput  form-control box2 ${addErrClass(
                      {
                        formik,
                        field: "link",
                      }
                    )}`}
                    type="text"
                    autoComplete="off"
                    placeholder={formik.values?.link}
                  />
                  <FormikErrRender formik={formik} field="link" />
                </div>}
                <div>
                  <label
                    className="form-label mt-5 titr6 mb-0"
                    htmlFor="PhoneNumber"
                  >
                    تلفن تماس
                  </label>
                  <input
                    dir="ltr"
                    id="PhoneNumber"
                    {...formik.getFieldProps("phone")}
                    className={`userUpdateInput  form-control box2 ${addErrClass(
                      {
                        formik,
                        field: "phone",
                      }
                    )}`}
                    type="text"
                    autoComplete="off"
                    placeholder={formik.values.phone}
                  />
                  <FormikErrRender formik={formik} field="phone" />
                </div>

                {/* <div>
                    <label
                      className="form-label mt-5 titr6 mb-0 required"
                      htmlFor="website"
                    >
                      Website
                    </label>
                  </div> */}
                {/* <div>
                    <input
                      dir="ltr"
                      id="website"
                      {...formik.getFieldProps("website")}
                      className={`userUpdateInput  form-control box2 ${addErrClass(
                        {
                          formik,
                          field: "website",
                        }
                      )}`}
                      type="text"
                      autoComplete="off"
                      placeholder={formik.values.website}
                    />
                    <FormikErrRender formik={formik} field="website" />
                  </div> */}
                {/* <div className="form-label mt-5 titr6 mb-0  border-0 ">
                    شبکه‌های اجتماعی
                  </div> */}

                {/* <div className=" border-0 mb-0 px-5">
                    <AddSocialMedia formik={formik} />
                  </div> */}
              </div>
            </div>
            {/* Left Section */}
            <div className="userUpdateItem col-md-6 px-md-3">
              <div>
                <div>
                  <label
                    htmlFor="formFileMultiple"
                    className="form-label   mb-2"
                  >
                    <span>
                      <i className="bi bi-circle-fill me-1"></i>
                    </span>
                    عکس اضافه کنید تا توجه‌ها رو به آگهی جذب کنید
                    {/* <Tooltip1 message="این عکس ها در گالری نمایش داده خواهد شد. (حداکثر 6 عکس)" /> */}
                  </label>
                  <div className="small-gray-font">
                    (عکس‌ها رو از زوایای مختلف و حداکثر تا ۶ عدداضافه کنید.)
                  </div>
                  <div className="mb-0 form-group files">
                    <div className="d-flex flex-column align-items-center ">
                      {!formik?.values?._id
                        ? ""
                        : photos?.resized?.length > 0 &&
                          (photos?.urls?.length > 0 ? (
                            <Check />
                          ) : (
                            <div
                              className="btn primary-btn checkout-btn cart-footer  py-1 mb-2"
                              onClick={async (e) => {
                                setLoading((pre) => ({
                                  ...pre,
                                  loading4: true,
                                }));
                                const urls = await sendBodyPhotosFirebase();
                                setLoading((pre) => ({
                                  ...pre,
                                  loading4: false,
                                }));

                                setPhotos((pre) => {
                                  const photos = { ...pre };
                                  photos.urls = urls;

                                  const formikValues = { ...formik.values };
                                  formikValues.photos = urls;
                                  formik.setValues(formikValues);

                                  return photos;
                                });
                              }}
                            >
                              افزودن عکس به آگهی
                              {loading.loading4 && (
                                <CircularProgress color="inherit" size="20px" />
                              )}
                            </div>
                          ))}
                      <input
                        disabled={loading.loading14 || loading.loading4}
                        className="form-control  p-3"
                        type="file"
                        accept="image/jpeg, image/png, image/gif"
                        data-mdb-max-file-quantity="6"
                        id="formFileMultiple"
                        dir="ltr"
                        multiple
                        max={5}
                        onChange={bodyPhotosHandle}
                      />
                      {loading.loading14 && (
                        <CircularProgress color="inherit" size="20px" />
                      )}

                      {photos?.resized?.length > 0
                        ? photos?.resized?.map((img, i) => (
                            <>
                              <img
                                key={i}
                                className="userShowImg userShowImg-busphotos"
                                src={img}
                                alt="userShowImg"
                                loading="lazy"
                              />
                              <i
                                id={i}
                                className="bi bi-trash"
                                style={{ color: "red" }}
                                onClick={deleteHandle}
                              ></i>
                            </>
                          ))
                        : formik?.values?.photos?.length > 0
                        ? formik?.values?.photos.map((img, i) => (
                            <>
                              <img
                                key={i}
                                className="userShowImg userShowImg-busphotos"
                                src={img}
                                alt={formik?.values.busName?.[window.lang] + i}
                                loading="lazy"
                              />
                              {/* <i
                id={i}
                className="bi bi-trash"
                style={{ color: "red" }}
                onClick={deleteHandle}
              ></i> */}
                            </>
                          ))
                        : ""}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p id="tagsInput" className="mt-5 form-titr titr-6">
                  برچسب‌ها (با "+" جدا شوند )
                  <Tooltip1
                    message={
                      <div>
                        <div>
                          برچسب‌ها - تگ - را توسط '+' (بدون فاصله)از یکدیگر
                          جداکنید.
                        </div>
                        <div>حداکثر ۱۰ برچسب.</div>
                      </div>
                    }
                  />
                </p>
                <div className="small-gray-font">
                  (بیشتر دیده شدن آگهی. ۱۰ کلمه کلیدی که ممکنه کسی اون رو جستجو
                  کنه را وارد کنید)
                </div>
                <input
                  type="text"
                  autoComplete="off"
                  name="other"
                  placeholder={
                    formik?.values?.tags ||
                    `برای مثال : بازسازی+طراحی داخلی+ طراحی فضای سبز`
                  }
                  className="form-input1 mt-3 w-100"
                  {...formik.getFieldProps("tags")}
                />
              </div>

              {/* <div>
      <span className="p-0 m-z "> مهارتهای زبانی</span>
          <LangsLevel formik={formik}/> 
      </div> */}
            </div>
          </div>
          <div className="mt-5 mb-3 required">
            <span>
              <a
                className=" pe-1 fw-bold fst-italic icon-btn font-12"
                data-bs-toggle="modal"
                data-bs-target="#usedRulesModal"
              >
                (قوانین افزودن آگهی)
              </a>
            </span>
            <span>
              <label htmlFor="termsBtn" className={`  med-gray-font  `}>
                <span>را مطالعه کردم و می‌پذیرم</span>

                <input
                  id="termsBtn"
                  type="checkbox"
                  className="form-check-input mx-2"
                  onChange={(e) => {
                    if (formik.values.terms) return;
                    formik.setValues((pre) => ({ ...pre, terms: true }));
                  }}
                  checked={formik.values.terms}
                />
              </label>
            </span>

            <FormikErrRender formik={formik} field="terms" />
          </div>
          <UsedRulesModal />

          <FormikListErr formik={formik} />
          <SubmitBtn formik={formik} text="" />
        </form>
      ) : (
        <div className="container-404 text-center d-flex flex-column align-items-center px-4 ">
          <div className="my-3 ">
            <div> در گام نخست شما نیاز دارید تا وارد حساب کاربری شوید.</div>

            <Link
              id="loginTrigger"
              className="btn primary-btn btn-404 checkout-btn my-5 mt-3"
              data-bs-toggle="modal"
              to="#exampleModalToggle"
              role="button"
            >
              ورود به حساب کاربری
            </Link>
            <img
              className=" img-404 w-75 "
              src="/img/illustration/access-denied.svg"
              alt="404"
            />
          </div>
        </div>
      )}
      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );

  function SubmitBtn({ text, formik }) {
    let textBtn = "بفرست";
    if (formik.values.action === "edit") textBtn = "ویرایش";
    return (
      <button
        disabled={formik.isSubmitting}
        className="my-1 login-btn login-btn2"
        style={{
          backgroundColor: Object.keys(formik.errors)?.length > 0 && "grey",
        }}
        type="submit"
        id="createBusBtn"
      >
        {textBtn}
        {loading.spinner ? (
          <CircularProgress color="inherit" size="20px" />
        ) : (
          Object.keys(formik.errors)?.length === 0 && <CheckCircle />
        )}
        <span>
          {Object.keys(formik.errors)?.length > 0 && (
            <Tooltip1
              message=" موارد ستاره دار را کامل کنید."
              icon={<Error sx={{ fontSize: 18, color: "red" }} />}
              position="left"
            />
          )}
        </span>
      </button>
    );
  }
  // function countrySet(countryShort) {
  //   const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  //   console.log(countryShort);
  //   const states = countryStates.find(
  //     (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
  //   ).states;
  //   console.log(states);
  //   // const state = countryStates.find(
  //   //   (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
  //   // ).states[0];
  //   setStates(states);

  //   formik.setValues((prev) => {
  //     const location = { ...prev.location };
  //     location.country = regionNames.of(countryShort).toLowerCase();
  //     location.countryShort = countryShort.toLowerCase();
  //     location.state = "";
  //     location.stateShort = "";
  //     location.cities = [];
  //     return { ...prev, location };
  //   });
  // }
  // function setCitiesHandle(value) {
  //   formik.setValues((prev) => {
  //     const location = { ...prev.location };
  //     location.cities = value;
  //     return { ...prev, location };
  //   });
  // }
}

function editInitialData(formik, data, action) {
  const initialValues = {
    action,
    _id: action === "edit" ? data._id : "",
    lcs: data?.lcs ?? window.lcs,
    lang: data?.lang ?? window.lang,
    title: data.title,
    terms: data?.moreInfo?.terms,
    category: data.category,
    email: data.email,
    // logo: data.moreInfo.logo,
    description: data.moreInfo.description,
    status: data.moreInfo.status,
    tags: data.moreInfo.tags.join("+") || "",
    photos: data.moreInfo.photos,
    contact: data.moreInfo.contact,
    link: data.moreInfo.link,
    phone: data.moreInfo.phone,
    carInfo: data.carInfo,
    homeInfo: data.homeInfo,
    tripInfo: data.tripInfo,
    condition: data.moreInfo.condition,
    delivery: data.moreInfo.delivery,
    isFree: data.moreInfo.isFree,
    isActive: data.moreInfo.isActive,
    price: data.moreInfo.price,
    // salary: data.moreInfo.salary,
    description: data.moreInfo.description,
    location: data.moreInfo.address,
  };
  formik.setValues(initialValues);
}

async function editHandle({ messageRef, setLoading, user, values }) {
  console.log("edit handle triggred");
  setLoading((pre) => ({ ...pre, spinner: true }));
  const response = await apiMP.editUsed({
    token: user.token,
    data: values,
  });
  setLoading((pre) => ({ ...pre, spinner: false }));
  if (response.error)
    return messageRef.current.showError({
      message: (
        <div>
          <div> مشکل در انجام عملیات !</div>
          <div dir="ltr">{response.error}</div>
        </div>
      ),
    });

  messageRef.current.showSuccess({
    message: " نیازمندی با موفقیت ویرایش شد.",
  });

  window.navigateHandle(`/${window.lang}/mp/used/page/${tools.slugMaker({title:response.data?.title,city:response.data?.moreInfo?.address?.city,state:response.data?.moreInfo?.address?.state,serial:response.data?.serial})}`, response.data)
}
function CarInfoRender({ formik }) {
  return (
    <div>
      <p className="form-label mt-3 titr6 mb-0 mt-5">
        اطلاعات خودرو | موتورسیکلت
      </p>
      <div className="mt-2">
        <label htmlFor="carInfoYear">سال</label>
        <select
          className={`form-select ${addErrClass({
            formik,
            field: "carInfo.year",
          })}`}
          name=""
          id="carInfoYear"
          // onChange={(e) => formik.setFieldValue("carInfo.year", e.target.value)}
          {...formik.getFieldProps("carInfo.year")}
        >
          <option value="">انتخاب کنید:</option>

          {[...Array(50).keys()].map((index) => {
            const year = 2024 - index;
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
          <option value="1974">پیش از 1975</option>
        </select>
        <FormikErrRender formik={formik} field="carInfo.year" />
      </div>
      <div className="mt-2">
        <label htmlFor="carInfoMake">سازنده</label>
        <select
          className={`form-select ${addErrClass({
            formik,
            field: "carInfo.make",
          })}`}
          name="car-manufacturer"
          id="carInfoMake"
          {...formik.getFieldProps("carInfo.make")}
        >
          {/* Japanese Manufacturers  */}
          <option value="">انتخاب کنید :</option>
          {renderData.carCompanies.map((car,i)=><option key={i} value={car.name}>{car.en}</option>)}
        </select>
        <FormikErrRender formik={formik} field="carInfo.make" />
      </div>
      <div className="mt-2">
        <label className="" htmlFor="carInfoModel">
          {" "}
          مدل / تیپ
        </label>
        <input
          type="text"
          id="carInfoModel"
          className={`form-control ${addErrClass({
            formik,
            field: "carInfo.model",
          })}`}
          name="carInfoModel"
          placeholder="برای مثال : Prado"
          // defaultValue={formik.values.carInfo?.model}
          // onBlur={(e) => formik.setFieldValue("carInfo.model", e.target.value)}
          {...formik.getFieldProps("carInfo.model")}
        ></input>
        <FormikErrRender formik={formik} field="carInfo.model" />
      </div>
      <div className="mt-2">
        <label for="class-type">کلاس </label>
        <select
          className={`form-select ${addErrClass({
            formik,
            field: "carInfo.class",
          })}`}
          name="class-type"
          id="class-type"
          // onChange={(e) =>
          //   formik.setFieldValue("carInfo.class", e.target.value)
          // }
          {...formik.getFieldProps("carInfo.class")}
        >
          <option value="">انتخاب کنید :</option>
          <option value="sedan">Sedan</option>
          <option value="suv">SUV</option>
          <option value="pickup-truck">Pickup Truck</option>
          <option value="crossover">Crossover</option>
          <option value="coupe">Coupe</option>
          <option value="convertible">Convertible</option>
          <option value="minivan">Minivan</option>
          <option value="hatchback">Hatchback</option>
        </select>
        <FormikErrRender formik={formik} field="carInfo.class" />
      </div>

      <div className="mt-2">
        <label htmlFor="carInfoGearBox">گیریبکس</label>
        <select
          className={`form-select ${addErrClass({
            formik,
            field: "carInfo.transmission",
          })}`}
          name=""
          id="carInfoGearBox"
          {...formik.getFieldProps("carInfo.transmission")}
        >
          <option value="">انتخاب کنید : </option>
          {renderData.transmission.map((da, i) => (
            <option key={i} value={da.name}>
              {da?.[window.lang]}
            </option>
          ))}
        </select>
        <FormikErrRender formik={formik} field="carInfo.transmission" />
      </div>

      <div className="mt-2">
        <label for="fuel-type">نوع سوخت </label>
        <select
          className={`form-select ${addErrClass({
            formik,
            field: "carInfo.fuel",
          })}`}
          name="fuel-type"
          id="fuel-type"
          {...formik.getFieldProps("carInfo.fuel")}
        >
          <option value="">انتخاب کنید:</option>
          {renderData.fuelTypes.map((da, i) => (
            <option key={i} value={da.name}>
              {da?.[window.lang]}
            </option>
          ))}
        </select>
        <FormikErrRender formik={formik} field="carInfo.fuel" />
      </div>
      <div className="mt-2">
        <label className="" htmlFor="carInfoColor">
          {" "}
          رنگ
        </label>
        <input
          id="carInfoColor"
          className={`form-control ${addErrClass({
            formik,
            field: "carInfo.color",
          })}`}
          name="carInfoColor"
          // defaultValue={formik.values.carInfo.color}
          // onBlur={(e) => formik.setFieldValue("carInfo.color", e.target.value)}
          {...formik.getFieldProps("carInfo.color")}
        ></input>
        <FormikErrRender formik={formik} field="carInfo.color" />
      </div>
      <div className="mt-2">
        <label className="" htmlFor="carInfoColor">
          کیلومتر
          <Tooltip1 message=".کارکرد وسیله نقیله را به کیلومتر وارد نمائید" />
        </label>
        <input
          type="number"
          id="carInfoOdometer"
          className={`form-control ${addErrClass({
            formik,
            field: "carInfo.odometer",
          })}`}
          name="carInfoOdometer"
          defaultValue={formik.values.carInfo.odometer}
          onBlur={(e) =>
            formik.setFieldValue("carInfo.odometer", e.target.value)
          }
        ></input>
        <FormikErrRender formik={formik} field="carInfo.odometer" />
      </div>
    </div>
  );
}
function HomeInfoRender({ formik }) {
  return (
    <div>
      <p className="form-label mt-5 titr6 mb-0">اطلاعات تکمیلی خانه</p>
      <div className="mt-2">
        <label htmlFor="homeInfoproType" className="required">
          نوع دارایی
        </label>
        <select
          className={`form-select ${addErrClass({
            formik,
            field: "homeInfo.proType",
          })}`}
          name="car-manufacturer"
          id="homeInfoproType"
          // defaultValue={formik.values?.homeInfo?.proType}
          // onChange={(e) => formik.setFieldValue("homeInfo.proType", e.target.value)}
          {...formik.getFieldProps("homeInfo.proType")}
        >
          {/* Japanese Manufacturers  */}
          <option value="">انتخاب کنید :</option>
          {renderData.proType.map((da, i) => (
            <option key={i} value={da.name}>
              {da?.[window.lang]}
            </option>
          ))}
        </select>
        <FormikErrRender formik={formik} field="homeInfo.proType" />
      </div>

      <div className="mt-2">
        <label htmlFor="homeInfoTransType" className="required">
          نوع تراکنش
        </label>
        <select
          className={`form-select ${addErrClass({
            formik,
            field: "homeInfo.transType",
          })}`}
          name="home-transType"
          id="homeInfoTransType"
          // defaultValue={formik.values.homeInfo.transType}
          // onChange={(e) => formik.setFieldValue("homeInfo.transType", e.target.value)}
          {...formik.getFieldProps("homeInfo.transType")}
        >
          {/* Japanese Manufacturers  */}
          <option value="">انتخاب کنید :</option>
          {renderData.transType.map((da, i) => (
            <option key={i} value={da.name}>
              {da?.[window.lang]}
            </option>
          ))}
        </select>
        <FormikErrRender formik={formik} field="homeInfo.transType" />
      </div>

      <div className="mt-2">
        <label className="" htmlFor="homeInfoLand">
          {" "}
          مساحت زمین
          <Tooltip1 message="لطفا مساحت زمین را به مترمربع بنویسید" />
        </label>
        <input
          type="number"
          id="homeInfoLand"
          className={`form-control ${addErrClass({
            formik,
            field: "homeInfo.lot",
          })}`}
          name="homeInfoLand"
          // defaultValue={formik.values.homeInfo.lot}
          // onBlur={(e) =>
          //   formik.setFieldValue("homeInfo.lot", e.target.value)
          // }
          {...formik.getFieldProps("homeInfo.lot")}
        ></input>
        <FormikErrRender formik={formik} field="homeInfo.lot" />
      </div>
      {formik.values.homeInfo?.proType !== "lot" && (
        <div>
          <div className="mt-2">
            <label className="" htmlFor="homeInfoMeter">
              زیربنا(به متر مربع)
              <Tooltip1 message=" .لطفا مساحت کلی واحد را اینجا وارد نمائید" />
            </label>
            <input
              type="number"
              id="homeInfoMeter"
              className={`form-control ${addErrClass({
                formik,
                field: "homeInfo.build",
              })}`}
              name="homeInfoMeter"
              // defaultValue={formik.values?.homeInfo?.build}
              // onBlur={(e) =>
              //   formik.setFieldValue("homeInfo.build", e.target.value)
              // }
              {...formik.getFieldProps("homeInfo.build")}
            ></input>
            <FormikErrRender formik={formik} field="homeInfo.build" />
          </div>
          <div className="mt-2">
            <label htmlFor="homeInfoYear">سال</label>
            <select
              className={`form-select ${addErrClass({
                formik,
                field: "homeInfo.year",
              })}`}
              name=""
              id="homeInfoYear"
              // onChange={(e) => formik.setFieldValue("homeInfo.year", e.target.value)}
              {...formik.getFieldProps("homeInfo.year")}
            >
              <option value="">انتخاب کنید:</option>

              {[...Array(60).keys()].map((index) => {
                const year = 2024 - index;
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
              <option value="1974">پیش از 1975</option>
            </select>
          </div>
          <div className="mt-2">
            <label htmlFor="homeInfoBeds">اتاق خواب</label>
            <select
              className={`form-select ${addErrClass({
                formik,
                field: "homeInfo.beds",
              })}`}
              name=""
              id="homeInfoBeds"
              // onChange={(e) => formik.setFieldValue("homeInfo.beds", e.target.value)}
              {...formik.getFieldProps("homeInfo.beds")}
            >
              <option value="">انتخاب کنید:</option>

              {[...Array(15).keys()].map((index) => {
                const beds = index;
                return (
                  <option key={beds} value={beds}>
                    {beds}
                  </option>
                );
              })}
              <option value="16"> 16 بیشتر از </option>
            </select>
          </div>

          <div className="mt-2">
            <label htmlFor="homeInfoBeds">سرویس بهداشتی</label>
            <select
              className={`form-select ${addErrClass({
                formik,
                field: "homeInfo.bath",
              })}`}
              name=""
              id="homeInfobath"
              // onChange={(e) => formik.setFieldValue("homeInfo.bath", e.target.value)}
              {...formik.getFieldProps("homeInfo.bath")}
            >
              <option value="">انتخاب کنید:</option>

              {[...Array(15).keys()].map((index) => {
                const bath = index + 1;
                return (
                  <option key={bath} value={bath}>
                    {bath}
                  </option>
                );
              })}
            </select>
            <FormikErrRender formik={formik} field="homeInfo.bath" />
          </div>

          {/* <div className="mt-2">
        <label htmlFor="homeInfoLaundry">لاندری</label>
        <select
          className={`form-control ${addErrClass({
            formik,
            field: "homeInfo.laundry",
          })}`}
          name="car-manufacturer"
          id="homeInfoLaundry"
          // defaultValue={formik.values?.homeInfo?.laundry}
          // onChange={(e) => formik.setFieldValue("homeInfo.laundry", e.target.value)}
          {...formik.getFieldProps("homeInfo.laundry")}
       
       >   
          <option value="">انتخاب کنید :</option>
          {renderData.laundry.map((pa,i)=> <option key={i} value={pa.name}>{pa['fa']}</option>)}
       
        </select>
        <FormikErrRender formik={formik} field="homeInfo.laundry" />
      </div> */}

          <div className="mt-2">
            <label htmlFor="homeInfoAirCondition">سیستم خنک کننده</label>
            <select
              className={`form-select ${addErrClass({
                formik,
                field: "homeInfo.airCondition",
              })}`}
              name="car-manufacturer"
              id="homeInfoAirCondition"
              // defaultValue={formik.values?.homeInfo?.airCondition}
              // onChange={(e) => formik.setFieldValue("homeInfo.airCondition", e.target.value)}
              {...formik.getFieldProps("homeInfo.airCondition")}
            >
              <option value="">انتخاب کنید :</option>
              {renderData.airCondition.map((pa, i) => (
                <option key={i} value={pa.name}>
                  {pa["fa"]}
                </option>
              ))}
            </select>
            <FormikErrRender formik={formik} field="homeInfo.airCondition" />
          </div>
          <div className="mt-2">
            <label htmlFor="homeInfoHeating"> سیستم گرمایشی اصلی </label>
            <select
              className={`form-select ${addErrClass({
                formik,
                field: "homeInfo.heating",
              })}`}
              name="car-manufacturer"
              id="homeInfoHeating"
              // defaultValue={formik.values?.homeInfo?.heating}
              // onChange={(e) => formik.setFieldValue("homeInfo.heating", e.target.value)}
              {...formik.getFieldProps("homeInfo.heating")}
            >
              <option value="">انتخاب کنید :</option>
              {renderData.heating.map((pa, i) => (
                <option key={i} value={pa.name}>
                  {pa[window.lang]}
                </option>
              ))}
            </select>
            <FormikErrRender formik={formik} field="homeInfo.heating" />
          </div>

          <div className="mt-2">
            <label htmlFor="homeInfoParking">پارکینگ</label>
            <select
              className={`form-select ${addErrClass({
                formik,
                field: "homeInfo.parking",
              })}`}
              name="car-manufacturer"
              id="homeInfoParking"
              // defaultValue={formik.values?.homeInfo?.parking}
              // onChange={(e) => formik.setFieldValue("homeInfo.parking", e.target.value)}
              {...formik.getFieldProps("homeInfo.parking")}
            >
              <option value="">انتخاب کنید :</option>
              {renderData.parking.map((pa, i) => (
                <option key={i} value={pa.name}>
                  {pa["fa"]}
                </option>
              ))}
            </select>
            <FormikErrRender formik={formik} field="homeInfo.parking" />
          </div>
          {formik.values?.homeInfo?.parking === "garage" && (
            <div className="mt-2">
              <label htmlFor="homeInfoParkingQty">تعداد پارکینک</label>
              <select
                className={`form-select ${addErrClass({
                  formik,
                  field: "homeInfo.parkingQty",
                })}`}
                name="car-manufacturer"
                id="homeInfoParkingQty"
                // defaultValue={formik.values?.homeInfo?.parkingQty}
                // onChange={(e) => formik.setFieldValue("homeInfo.parkingQty", e.target.value)}
                {...formik.getFieldProps("homeInfo.parkingQty")}
              >
                <option value="">انتخاب کنید :</option>
                {[...Array(15).keys()].map((index) => {
                  const beds = index + 1;
                  return (
                    <option key={beds} value={beds}>
                      {beds}
                    </option>
                  );
                })}
                <option value="+">15+</option>
              </select>
              <FormikErrRender formik={formik} field="homeInfo.parkingQty" />
            </div>
          )}
        </div>
      )}

      <div className=" mt-5">
        <p className="form-label titr6"> امکانات </p>
        <div
          className={`d-flex flex-wrap ${addErrClass({
            formik,
            field: "hFacilities",
          })} `}
        >
          {renderData.hFacilities.map((type, i) => (
            <div
              key={i}
              className={`form-check mx-3 px-2 py-1 my-2 ${
                formik.values?.homeInfo?.hFacilities?.includes(type.name) &&
                "selected-session"
              }`}
              onClick={(e) =>
                formik.setValues((pre) => {
                  const hFacilities = [...pre.homeInfo.hFacilities];
                  const valueIndex = hFacilities.indexOf(type.name);
                  if (valueIndex !== -1) {
                    hFacilities.splice(valueIndex, 1);
                  } else {
                    hFacilities.push(type.name);
                  }
                  return {
                    ...pre,
                    homeInfo: { ...pre.homeInfo, hFacilities },
                  };
                })
              }
            >
              {/* <input
                className="form-check-input px-1"
                type="checkbox"
                id={"hFacilities" + type.name}
                checked={formik.values?.homeInfo?.hFacilities?.includes(
                  type.name
                )}
                value={type.name}
                onChange={(e) =>
                  formik.setValues((pre) => {
                    const hFacilities = [...pre.homeInfo.hFacilities];
                    const valueIndex = hFacilities.indexOf(type.name);
                    if (valueIndex !== -1) {
                      hFacilities.splice(valueIndex, 1);
                    } else {
                      hFacilities.push(type.name);
                    }
                    return {
                      ...pre,
                      homeInfo: { ...pre.homeInfo, hFacilities },
                    };
                  })
                }
              /> */}
              <label
                className="form-check-label "
                htmlFor={"hFacilities" + type.name}
              >
                {/* <i className={` ${type.img}`}></i> */}
                {type[window.lang]}
              </label>
            </div>
          ))}
        </div>
        <FormikErrRender formik={formik} field="hFacilities" />
      </div>
    </div>
  );
}


function UsedRulesModal() {
  return (
    <div
      className="modal fade"
      id="usedRulesModal"
      tabIndex="-1"
      aria-labelledby="UsedModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div>
            <div className="bg-light p-4 rounded">
              <h4 className="mb-4 text-center">
                لیست کالاها و فعالیت‌های غیر‌مجاز
              </h4>
              <div>
                <p>
                  <strong>آگهی با محتوای مربوط به رایانه:</strong>
                </p>
                <ul>
                  <li>خرید و فروش غیرمجاز نرم فزارهای دارای لایسنس</li>
                  <li>هک نرم‌افزارهای ارتباطی به علت نقض حریم خصوصی</li>
                  <li>
                    خرید و فروش و خدمات مربوط به دسترسی به بانک‌های اطلاعاتی
                    حاوی اطلاعات شخصی
                  </li>
                </ul>

                {/* <p ><strong>آگهی مربوط به استخدام:</strong></p>
          <ul>
            <li >ثبت آگهی استخدام نوجوان (افراد زیرِ ۱۵ سال)</li>
            <li >ثبت آگهی استخدام یا کاریابی در خارج از کشور</li>
            <li >آگهی‌ استخدام برای امور منزل یا محل کار، در صورتی که غیر قانونی و غیر اخلاقی یا نامتناسب با عرف باشد</li>
          </ul>
          <p >&nbsp;</p> */}
                {/* <p ><strong>آگهی مربوط به ارتباطات/مخابرات:</strong></p>
          <ul>
            <li >خرید و فروش دستگاه گیرندهٔ ماهواره و تجهیزات مربوط به آن یا هرگونه خدمات مرتبط با ماهواره و اینترنت ماهواره‌ای</li>
            <li >خرید و فروش و خدمات جمر یا مسدودکنندهٔ سیگنال</li>
            <li >خرید و فروش لوازم و خدمات جاسوسی</li>
          </ul> */}

                <p>
                  <strong>آگهی مربوط به خودرو:</strong>
                </p>
                <ul>
                  <li>ارائه خدمات مربوط به دستکاری و تغییر کیلومتر ماشین</li>
                  {/* <li >خرید و فروش خودروهایی که موضوع حقوق ثالث به شمار می‌آیند یا توقیف باشند.</li>
            <li >خرید و فروش خودروهای شوتی و خدمات مربوط به آن</li> */}
                  <li>خرید و فروش و خدمات دستکاری غیر مجاز پلاک خودرو</li>
                </ul>

                <p>
                  <strong>آگهی مربوط به سلاح و کالاهای خطرناک:</strong>
                </p>
                <ul>
                  <li>
                    خرید و فروش و خدمات اسلحه‌های سرد و لوازم دفاع شخصی غیر
                    ورزشی
                  </li>
                  <li>خرید و فروش و خدمات انواع جنگ‌افزار و سلاح گرم</li>
                  <li>خرید و فروش انواع مواد محترقهٔ خطرآفرین</li>
                  {/* <li >خرید و فروش نفت و بنزین و گازوئیل و سایر مشتقات نفتی</li> */}
                </ul>

                <p>
                  <strong>آگهی مربوط به سلامت و داروهای های فاقد نسخه:</strong>
                </p>
                <ul>
                  {/* <li >هرگونه داروی خوراکی و گیاهی و شیمیایی</li>
            <li >داروهای تزریقی در صورتی که مورد استعمال خارجی نباشد </li>
            <li >داروهای ضدبارداری یا برای مصارف جنسی</li>
            <li >خرید و فروش شیر خشک و قوطی شیر خشک کودک</li> */}
                  <li>فروش هرگونه دارو‌ی ترک اعتیاد و ترک سیگار</li>
                  {/* <li >آگهی‌های خرید و فروش &nbsp;پلاتین پزشکی و پلاتین‌های اعضای بدن و خدمات مربوط به آن</li> */}
                  {/* <li >تمام مکمل‌های بدنسازی (تقویتی و گیاهی و شیمیایی)، مکمل‌های غذایی، مکمل‌های دارویی، مکمل‌های کاهش یا افزایش وزن و قوطی‌های مکمل</li> */}
                  <li>خرید و فروش مواد مخدر و ادوات استعمال آن‌ها</li>
                  {/* <li >خرید و فروش مشروبات الکلی و ابزار تولید آن‌ها</li> */}
                </ul>

                {/* <p ><strong>آگهی با مضامین مالی:</strong></p>
          <ul>
            <li >خرید و فروش ارز رایج کشورها (اسکناس ایرانی و خارجی) و حوالهٔ ارزی</li>
            <li >هرگونه آگهی خرید و فروش وام</li>
            <li >هرگونه درخواست پول نزول، ربا یا پرداخت آن‌ها</li>
            <li >خدمات تغییر هر نوع قبض مانند برق، آب، گاز، تلفن و…</li>
          </ul> */}

                <p>
                  <strong>آگهی‌ ناقض قوانین حیات وحش:</strong>
                </p>
                <ul>
                  <li>پرندگانی مثل عقاب یا شاهین و یا سایر پرندگان شکاری</li>
                  <li>
                    پستانداران، حیوانات وحشی، گونه‌های رو به انقراض و هر حیوانی
                    که خرید و فروش آن قانونی نیست
                  </li>
                  {/* <li >خزندگان و بندپایانی مانند عقرب، رتیل، آفتاب‌پرست، ایگوانا، سوسمار، مار، مار ماهی، لاک پشت</li> */}
                  {/* <li >گوشت حیواناتی که کشتار و عرضهٔ قانونی ندارند</li> */}
                  {/* <li >تمام حیوانات داخل الکل و تاکسیدرمی (خشک‌شده)</li> */}
                  <li>جنگ یا مسابقه بین حیوانات و ترغیب و تشویق به این کار</li>
                  <li>هرگونه ابزار آزار و ترویج خشونت علیه حیوانات</li>
                  <li>
                    خرید و فروش، معاوضه و واگذاری توله‌ی زیر ۲ ماهِ سگ و گربه
                  </li>
                  <li>
                    خرید و فروش اعضای بدن حیوانات وحشی و خدمات مربوط به آن
                  </li>
                  {/* <li >خرید و فروش ذغال جنگلی و ذغال بلوط</li> */}
                </ul>

                {/* <p ><strong>آگهی ناقض امنیت اخلاقی:</strong></p> */}
                {/* <ul> */}
                {/* <li >آگهی با مضمون ماساژ</li> */}
                {/* <li >آگهی‌های ارائهٔ خدمات فقط به جنس مخالف</li> */}
                {/* <li >آگهی‌های همسفری</li> */}
                {/* <li >آگهی‌های مدل به‌ جز مدل‌های رایج در سالن‌های آرایشی، کلینیک‌های زیبایی و پزشکی</li> */}
                {/* <li >آگهی ارائهٔ خدمات همسریابی یا دوست‌یابی</li> */}
                {/* <li >آگهی خدمات سقط جنین</li> */}
                {/* <li >فیلم سینمایی و موسیقی حاوی محتوای مستهجن و مبتذل</li> */}
                {/* </ul> */}
                <p>
                  <strong>آگهی مرتبط با خیریه، کمک مالی، اهدای عضو:</strong>
                </p>
                <ul>
                  <li>آگهی با محتوای کمک مالی</li>
                  <li>
                    واگذاری، حضانت و سرپرستی کودک (مانند: نیازمند فرزند یا
                    واگذاری فرزند)
                  </li>
                  <li>خرید و فروش و اهدای جنین انسان</li>
                  {/* <li >خرید و فروش و اهدای اعضای بدن به استثنای موی سر</li> */}
                  {/* <li >آگهی‌ رشوه و محتوای مشابه</li> */}
                  {/* <li >خرید و فروش و اهدای تجهیزات هلال احمر</li> */}
                  {/* <li >خدمات پناهندگی</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function TripInfoRender({ formik,isPackage,isRide,isAccomp }) {
  return (
    <div>
      <p className="form-label mt-5 titr6 mb-0">اطلاعات سفر</p>
      {(isPackage||isAccomp||isRide)&&<div className="mt-2">
        <label htmlFor="tripInfoactivityType" className="required">
         نقش شما در سفر
        </label>   
        <select
          className={`form-select ${addErrClass({
            formik,
            field: "tripInfo.activityType",
          })}`}
          name="tripInfo-activityType"
          id="tripInfoactivityType"
          {...formik.getFieldProps("tripInfo.activityType")}
        >
      
          <option value="">انتخاب کنید :</option>
          {renderData[isRide?"rideType":"activityType"].map((da, i) => (
            <option key={i} value={da.name}>
              {da?.[window.lang]}
            </option>
          ))}
        </select>
        <FormikErrRender formik={formik} field="tripInfo.activityType" />
   
      </div>}
      <div>
      <div className="col-md-6">
                      <p>تاریخ سفر/ شروع</p>
                      <DatePickerRender formik={formik} customPath={"tripInfo.from.date"} />
                      <FormikErrRender formik={formik} field="tripInfo.from.date" />
      </div>
                  <p className="form-label mt-5 titr6 required">                  
                    مبدا
                  </p>
                  <CountryStateCitySelector formik={formik} customPath={'tripInfo.from.location'} />
      </div>
      <div>
                  <p className="form-label mt-5 titr6 required">                  
                    مقصد
                  </p>
                  <CountryStateCitySelector formik={formik} customPath={'tripInfo.to.location'} />
      </div>

      {isPackage&&<div className="mt-2">
            <label htmlFor="tripInfocapacity"> ظرفیت به کیلوگرم</label>
            <select 
              className={`form-select ${addErrClass({
                formik,
                field: "tripInfo.capacity",
              })}`}
              name=""
              id="tripInfocapacity"
              // onChange={(e) => formik.setFieldValue("tripInfo.capacity", e.target.value)}
              {...formik.getFieldProps("tripInfo.capacity")}
            >
              <option value="">انتخاب کنید:</option>

              {[...Array(30).keys()].map((index) => {
                const capacity = index+1;
                return (
                  <option key={capacity} value={capacity}>
                    {capacity}
                  </option>
                );
              })}
              <option value="31"> +30</option>
            </select>
            <FormikErrRender formik={formik} field="tripInfo.capacity" />
          </div>}

     
    </div>
  );
}
