import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom';

export default function FiltersCatsSelector({filters,setFilters ,section}) {
  const [pubData] = useOutletContext();
  const [categories, setCategories] = useState({
    categories: pubData.categories?.[section] || [],
    selected: { mainCategory: "", subCategory: "" },
    subCategories: [],
  });
  
  useEffect(()=>{
    if(!filters.mainCatId)return
      mainCatHandle([filters.mainCatId,filters.mainCat])    
  },[filters.mainCatId])


  return (
    <>
   
              {/* <!-- Button filter 3 *******************************--> */}
              <div className="dropdown">
                <button
                  type="button"
                  className="btn  button-filter dropdown-toggle"
                  data-bs-toggle="dropdown"             
                  aria-expanded="false"                
                >
                  <span>
                    <i className="bi bi-tags me-1 "></i>
                  </span>
                  دسته‌بندی اصلی
                  <span className="ps-3">
                    <i className="bi bi-chevron-down"></i>
                  </span>
                </button>

                {/* <!-- Modal --> */}

                <div className="dropdown-menu checkbox-form px-0">
                  <div
                    className="form-check px-0 d-flex justify-content-between"
                    onClick={(e) => {
                      // effectRan.current = false;
                      setFilters((prev) => ({
                        ...prev,
                        mainCat: "",
                        mainCatId: "",
                        subCat: [],
                        subCatId: [],
                        pageNumber: 1,
                      }));
                      setCategories((prev) => ({
                        ...prev,
                        subCategories: [],
                      }));
                    }}
                  >
                    <div className="reset-btn w-100 ">پاک کردن</div>
                  </div>
                  {/* <div className="">
                    {pubData?.categories?.[section]?.map((cat, i) => (
                      <div
                        key={i}
                        className="form-check px-3 d-flex justify-content-between flex-row-reverse"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="mainCategory"
                          checked={cat._id === filters.mainCatId}
                          id={[cat._id, cat.mainCat?.[window.lang]]}
                          onChange={e=>mainCatHandle(e.currentTarget.id.split(","))}
                          // id={`flexCheckChecked${i}`}
                        />

                        <label
                          className="form-check-label"
                          htmlFor={[cat._id, cat.mainCat?.[window.lang]]}
                        >
                          {cat.mainCat?.[window.lang]}
                        </label>
                      </div>
                    ))}
                  </div> */}
                  <div className="">
  {pubData?.categories?.[section]?.map((cat, i) => (
    <div
      key={i}
      className="form-check px-3 d-flex justify-content-between flex-row-reverse"
      onClick={() => mainCatHandle([cat._id, cat.mainCat?.[window.lang]])} // Trigger radio selection on row click
      style={{ cursor: "pointer" }} // Add pointer cursor for better UX
    >
      <input
        className="form-check-input"
        type="radio"
        name="mainCategory"
        checked={cat._id === filters.mainCatId}
        id={[cat._id, cat.mainCat?.[window.lang]]}
        onChange={e => mainCatHandle(e.currentTarget.id.split(","))}
        style={{ pointerEvents: "none" }} // Disable pointer events to prevent click interference
      />

      <label
        className="form-check-label"
        htmlFor={[cat._id, cat.mainCat?.[window.lang]]}
      >
        {cat.mainCat?.[window.lang]}
      </label>
    </div>
  ))}
</div>

                </div>
              </div>

              {/* <!-- Button filter 4 *******************************--> */}
              {section!=="media"&&categories?.subCategories?.length>0&&filters.mainCatId?.length>0 && (
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-primary button-filter dropdown-toggle"
                    data-bs-toggle="dropdown"
                    // id="#filter5"
                    aria-expanded="false"
                    // aria-controls="filter5"
                  >
                    <span>
                      <i className="bi bi-tags-fill me-1"></i>
                    </span>
                    دسته‌بندی کوچک‌تر
                    <span className="ps-3">
                      <i className="bi bi-chevron-down"></i>
                    </span>
                  </button>

                  {/* <!-- Modal --> */}

                  <div className="dropdown-menu checkbox-form px-0 ">
                    <span
                      onClick={(e) => {
                        // effectRan.current = false;
                        setFilters((prev) => ({
                          ...prev,
                          subCat: [],
                          subCatId: [],
                          pageNumber: 1,
                        }));
                      }}
                    >
                      <div className="reset-btn w-100 ">پاک کردن</div>
                    </span>
                    {categories.subCategories.map((subCat, i) => (
                      <div
                        key={i}
                        className="form-check d-flex justify-content-between flex-row-reverse px-3"
                        onChange={e=>subCatHandle(e,i)}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          //  name="subCategory"
                          value={i}
                          // onChange={e=>subCatHandle(e,i)}
                          checked={filters?.subCatId?.includes(subCat?._id)}
                          id={`flexCheckSub${i}`}
                        />

                        <label
                          className="form-check-label"
                          htmlFor={`flexCheckSub${i}`}
                        >
                          {subCat?.[window.lang]}
                        </label>
                      </div>
                    ))}


                  </div>
                </div>
              )}

      


    </>
  )

  function mainCatHandle([id,mainCat]) {
    // const [id,mainCat] = e.currentTarget.id.split(",");

    console.log("mainCatHandle",id,mainCat);
    const category = pubData?.categories?.[section]?.find(
      (category) => category._id === id
    );
    setCategories((prevState) => ({
      ...prevState,
      subCategories: category?.subCat||[],
    }));

    setFilters((prev) => ({
      ...prev,
      mainCat,
      mainCatId:id,
      subCat: [],
      subCatId: [],
      pageNumber: 1,
    }));
  }
  function subCatHandle(e,subCatIndex) {
    // console.log("subCatIndex");
    e.stopPropagation();
    // const subCatIndex = e.target.value
    // console.log('subCatIndex',subCatIndex);

    const category = pubData?.categories?.[section]?.find(
      (category) => category._id === filters.mainCatId
    );

    const newSubCat = category.subCat[subCatIndex]
   
    let existSubCatArrayId = [...filters.subCatId]
    let existSubCatArray = [...filters.subCat]
    const index =  existSubCatArrayId.indexOf(newSubCat._id)
    if(index==-1){
      console.log('index==-1',index);
      existSubCatArray.push(newSubCat[window.lang])
      existSubCatArrayId.push(newSubCat._id)

    }else{
      console.log('index!==-1',index);
      existSubCatArray.splice(index,1)
      existSubCatArrayId.splice(index,1)
    }

    console.log(existSubCatArray);
    setFilters((prev) => ({ ...prev,
       subCat: existSubCatArray,
       subCatId: existSubCatArrayId,
        pageNumber: 1 }));
  }
}
