import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import api from "../../../../../../utils/apiBlog";
import { CartContext } from "../../../../../contexts/cartContext";
import ToastNote from "../../../../../golbal/notifications/toastNote";
import MyIcons from "../../../../../golbal/icons/myIcons";
import SearchBarUser from "../../profile/transactions/myTrans/searchBarUser";
import ColoredSpinner from "../../../../../alret/spiners/coloredSpiner/coloredSpinner";

export default function PostList({ access }) {
  console.log(access);
  const { user } = useContext(CartContext);
  const messageRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [user2, setUser2] = useState({ _id: user._id });

  const deleteHandle = async (id) => {
    if (
      window.confirm(
        "با حذف همیشگی و کم کردن 10 متاپوینت مربوط به این پست موافقم."
      ) !== true
    )
      return;
    const response = await api.deletePost({ token: user.token, id });
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({ message: "پست با موفقیت حذف شد." });
    setData(response.data);
  };

  useEffect(() => {
    fetchData();
  }, [user2._id, window.location.pathname]);

  async function fetchData() {
    setLoading(true);
    const response = await api.getPostList({
      token: user.token,
      id: user2._id,
      access,
    });
    setLoading(false);
    console.log(response);
    if (response.error) return alert(response.error);
    setData(response.data);
  }

  const columns = [
    //   { field: '_id', headerName: 'ID', width: 70 },
    {
      field: "action",
      headerName: "عملیات",
      width: 170,
      renderCell: (params) => {
        return (
          <div className="d-flex">
            <Link to={`/${window.lang}/blog/post/${params.row._id}`}>
              <MyIcons icon="display" />
            </Link>
            <Link
              to={`/${window.lang}/dashboard/user/blog/edit/${params.row._id}`}
              state={{ post: params.row }}
            >
              <MyIcons icon="edit" />
            </Link>
            <div title="پاک کردن" onClick={() => deleteHandle(params.row._id)}>
              <MyIcons icon="delete2" />
            </div>
            {/* <DeleteOutline className='userListDelete' onClick={()=>deleteHandle(params.row._id)}/>          */}
          </div>
        );
      },
    },
    {
      field: "img",
      headerName: "عکس",
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            <img
              src={params.row.img}
              alt="title"
              className="userListImg"
              loading="lazy"
            />
            {}
          </div>
        );
      },
    },
    // { field: 'َauthor', headerName: 'نویسنده', width: 130 , renderCell:(params)=>{ return (
    //   <div className='userListUser'>{params.row?.authorName}</div>
    //  )} },
    {
      field: "status",
      headerName: "وضعیت",
      width: 100,
      renderCell: (params) => {
        // const status = renderData.validation.find(va=> va.name ===params.row.status.substring(3))?.[window.lang]
        return <div>{params.row.status.substring(3)}</div>;
      },
    },

    {
      field: "title",
      headerName: "عنوان پست",
      width: 350,
      renderCell: (params) => (
        <div title={params.row.title} className="userListUser">
          {params.row.title}
        </div>
      ),
    },

    access === "admin" && {
      field: "userName",
      headerName: "User",
      width: 200,
      renderCell: (params) => {
        return <div className="userListUser">{params.row.userName}</div>;
      },
    },

    access === "admin" && {
      field: "email",
      headerName: "Email",
      width: 230,
      renderCell: (params) => (
        <div dir="ltr" title={params.row.email} className="userListUser">
          {params.row.email}
        </div>
      ),
    },
  ];
  return (
    <div className="userList" style={{ height: 600, width: "100%" }}>
      <ToastNote messageRef={messageRef} />
      {loading && <ColoredSpinner />}
      {!data.length ? (
        <div>
          داده ای پیدا نشد. پس از اطمینان از وجود داده، دوباره تلاش کنید.
        </div>
      ) : (
        <DataGrid
          getRowId={(row) => row._id}
          rows={data}
          components={{
            Toolbar: GridToolbar,
          }}
          disableSelectionOnClick
          columns={columns}
          pageSize={[15]}
          rowsPerPageOptions={[1]}
          checkboxSelection
        />
      )}
      {access === "admin" && (
        <SearchBarUser setUser2={setUser2} user2={user2} />
      )}
    </div>
  );
}
