import { useState, useEffect, useContext, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import apiDemy from "../../../utils/apiDemy";
import { CircularProgress } from "@mui/material";
import CommentsRating from "../../comment/commentsRating";
import { CartContext } from "../../contexts/cartContext";
// import renderData from "../../../utils/renderData";
import ToastNote from "../../golbal/notifications/toastNote";
import SocialMedias from "../business/components/socialMedias";
import ShareSocialMedias from "../business/components/shareSocialMedias";
import api from "../../../utils/api";
import tools from "../../../utils/tools";
import MetaDecorator from "../../../utils/metaDecorator";
import ColoredSpinner from "../../alret/spiners/coloredSpiner/coloredSpinner";

export default function ProgramPage(props) {
  const messageRef = useRef(null);
  const effectRan = useRef(false);
  const { user, signedUser } = useContext(CartContext);
  const section = "program";
  const page = "programFilters";

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState({ save: false });
  const [request, setRequest] = useState({
    email: "",
    message: "",
    links: [""],
  });
  const repliesCounter = () => {
    const comments = data?.feedbackInfo?.comment?.comments;
    let count = 0;
    comments?.forEach((com) => {
      count += 1;
      com.reply.forEach((rep) => {
        count += 1;
        rep.replyToReply.forEach((rep2) => (count += 1));
      });
    });
    return count;
  };
  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    setLoading((pre) => ({ ...pre, save: true }));
    e.target.setAttribute("disabled", true);
    console.log("saveHandler", data);
    const response = await api.saveHandler({
      id: data?._id,
      section,
      token: user.token,
      title: data.moreInfo?.title,
    });

    setLoading((pre) => ({ ...pre, save: false }));
    setTimeout(() => e.target.removeAttribute("disabled"), 1000 * 5);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    console.log("data", user?.saved?.data);
    signedUser(response.data);

    !user?.saved?.[section]?.includes(data?._id)
      ? messageRef.current.showSuccess({
          message: "Program saved.",
        })
      : messageRef.current.showWarning({
          message: "Program unsaved",
        });
  };

  useEffect(() => {
    if (!id) return;
    (async () => {
      // if (id?.length !== 24)
      //   return messageRef.current.showError({
      //     message: "شناسه صحیح نمی باشد، لطفا دوباره وارد این صفحه شوید.",
      //   });
      setLoading((pre) => ({ ...pre, spinner: true }));
      const response = await apiDemy.fetchProgramDetails(id);
      setLoading((pre) => ({ ...pre, spinner: false }));
      if (response.error) return alert(response.error);
      setData(response.data);
      if (effectRan.current) return;
      effectRan.current = true;
    })();
  }, [id]);
  // const { translations } =
  //   JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
 const url = `https://www.metamarce.com/${window.lang}/demy/program/page/${tools.slugMaker({title:data.center,city:data?.moreInfo?.centerId?.moreInfo?.address?.city,state:data?.moreInfo?.centerId?.moreInfo?.address?.state,serial:data?.serial})}`
  
  const title = ` ${data?.moreInfo?.program} | ${(
    data?.moreInfo?.centerId?.moreInfo?.address?.country +
    ", " +
    data?.moreInfo?.centerId?.moreInfo?.address?.state +
    ", " +
    data?.moreInfo?.centerId?.moreInfo?.address?.city
  )?.toUpperCase()}`;
  const description =
    data?.moreInfo?.centerId?.moreInfo?.description?.substring(0, 100) +
    " | " +
    data?.moreInfo?.centerId?.moreInfo?.address?.municipality +
    " | " +
    data?.moreInfo?.centerId?.moreInfo?.address?.city;

  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "EducationalOrganization",
      name: data?.moreInfo?.program,    
      url,
      logo: data?.moreInfo?.centerId?.moreInfo?.logo,
      image: data?.moreInfo?.centerId?.moreInfo?.img,
      address: {
        "@type": "PostalAddress",
        streetAddress: `${data?.moreInfo?.centerId?.moreInfo?.address?.streetNumber} ${data?.moreInfo?.centerId?.moreInfo?.address?.route}`,
        addressLocality: data?.moreInfo?.centerId?.moreInfo?.address?.city?.toUpperCase() ?? "",
        addressRegion: data?.moreInfo?.centerId?.moreInfo?.address?.state?.toUpperCase(),
        postalCode: data?.moreInfo?.centerId?.moreInfo?.address?.postalCode ?? "",
        addressCountry: data?.moreInfo?.centerId?.moreInfo?.address?.countryShort?.toUpperCase(),
      },
      contactPoint: {
        "@type": "ContactPoint",
        telephone: data?.moreInfo?.centerId?.moreInfo?.phone,
        contactType: "ADMISSIONS",
      },
      sameAs: data?.moreInfo?.centerId?.moreInfo?.socialMedia?.map((so) => so.url),
      email: data?.email,
      website: data?.moreInfo?.centerId?.moreInfo?.website,

      // "duns": "DUNS_number",
      // "paymentAccepted": "Cash, Credit Card",
      image: data?.moreInfo?.centerId?.moreInfo?.img,
      description: data?.moreInfo?.centerId?.moreInfo?.description,
      geo: {
        "@type": "GeoCoordinates",
        latitude: data?.moreInfo?.centerId?.moreInfo?.address?.coordinates?.lat || "",
        longitude: data?.moreInfo?.centerId?.moreInfo?.address?.coordinates?.lng || "",
      },
      feesAndCommissionsSpecification: {
        "@type": "TuitionSpecification",
        tuitions: data?.moreInfo?.centerId?.moreInfo?.tuitions?.map((tu) => {
          return {
            "@type": "Tuition",
            resideStatus: tu.resideStatus,
            tuitionLevel: tu.details.map((stl) => {
              return {
                "@type": "StudyLevel",
                name: stl.studyLevel,
                price: `${stl.currency} ${stl.max}`,
                priceCurrency: stl.currency,
                min: stl.min,
                max: stl.max,
                validFrom: "2024-01-01",
                validThrough: "2025-12-31",
                valueAddedTaxIncluded: false,
              };
            }),
          };
        }),
      },
      applicationFees: {
        type: "applicationFees",
        amount: data?.moreInfo?.centerId?.moreInfo?.appFee?.fee,
        currency: data?.moreInfo?.centerId?.moreInfo?.appFee?.currency,
      },
      studentsEnrollment: data?.moreInfo?.centerId?.moreInfo?.enrolment.map((enrol) => {
        return {
          studyLevel: enrol?.studyLevel,
          type: enrol?.type,
          quantity: enrol?.qty,
        };
      }),

      // "founder": "Founder Name",
      // "alumni": "Alumni Name",
      areaServed: [
        {
          "@type": "City",
          name: data?.moreInfo?.centerId?.moreInfo?.address?.city?.toUpperCase() ?? "",
        },
      ],
      dliNumber: data?.moreInfo?.centerId?.moreInfo?.dli,
      pgwpEligibility: data?.moreInfo?.centerId?.moreInfo?.pgwp,
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: data?.moreInfo?.program,
      description: data?.description,
      url,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "landing",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: "home",
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/demy`,
              name: "metademy",
              description: "MetaMarce Academy",
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id":url,
              name: data?.moreInfo?.program,
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title,
    description,
    section: "metademy",
    url,
    // canonical: `https://www.metamarce.com/${window.lang}/demy/program/page/${tools.slugMaker({title:data.center,city:data?.moreInfo?.centerId?.moreInfo?.address?.city,state:data?.moreInfo?.centerId?.moreInfo?.address?.state,serial:data?.serial})}`,
    img: data?.moreInfo?.centerId?.moreInfo?.img,
    type: "webpage",
  };
  function openGoogleMaps(destination) {
    if (!destination)
      return messageRef.current.showError({
        message: "آدرس در دسترس نمی باشد",
      });
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      destination
    )}`;
    window.open(googleMapsUrl, "_blank");
  }

  return (
    <div className="page-en">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <ToastNote messageRef={messageRef} />
      {loading.spinner && <ColoredSpinner />}
      {/* ****** cover ******** */}
      <nav className="p-md-3 p-2" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={`/${window.lang}`} className="breadcrumb-item">
              Home
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/${window.lang}/demy`} className="breadcrumb-item">
              Metademy
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              to={`/${window.lang}/demy/program/filters`}
              className="breadcrumb-item"
            >
              Programs
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="breadcrumb-item active">
              <strong>{data?.moreInfo?.program}</strong>
            </span>
          </li>
        </ol>
      </nav>

      <div className="bg-deepGreen py-0" dir="ltr">
        <div className=" container2 p-0 ">
          <div className="row py-3 px-lg-5 g-3">
            <div className="col-lg-2 d-flex justify-content-lg-start justify-content-center">
              <div className="picture-container">
                <div className=" picture picture2">
                  <img
                    // src="/img/Images/icons8-add-image-64.png"
                    src={data?.moreInfo?.centerId?.moreInfo?.logo}
                    className="picture-src"
                    id="wizardPicturePreview"
                    title=""
                  />
                  {/* <input type="file" id="wizard-picture" className="" /> */}
                </div>
              </div>
            </div>

            <div className="col-lg-10 text-center px-0 d-flex flex-column align-items-center justify-content-start  ">
              <h1 className="uni-name-cover text-beigeYellow m-2 my-3">
                {/* className="p-0 mb-0   "> */}
                {data?.moreInfo?.center}
              </h1>

              <div className=" d-flex ">
                {user?.roles?.includes(1000) && (
                  <Link
                    className="btn btn-primary share-cover-btn bg-pinkOrange  mx-2 font-12 py-1 "
                    to={`/${window.lang}/dashboard/demy/editprogram/${tools.slugMaker({title:data?.moreInfo?.center,program:data?.moreInfo?.program,state:data?.moreInfo?.address?.state,serial:data?.serial})}`}
                  >
                    edit
                  </Link>
                )}

                <button
                  className="btn btn-primary share-cover-btn bg-pinkOrange  mx-2 font-12 py-1"
                  data-bs-toggle="modal"
                  data-bs-target={`#myModel${data?._id}`}
                  id="shareBtn"
                  data-bs-placement="top"
                  title="Share!"
                >
                  share
                  <i className="bi bi-share-fill mx-2"></i>
                </button>

                {/* <!-- Modal --> */}
                <div>
                  {" "}
                  <ShareSocialMedias
                    url={`${window.location.origin}/${window.lang}/demy/program/page/${tools.slugMaker({title:data?.moreInfo?.program,city:data?.moreInfo?.centerId?.moreInfo?.address?.city,state:data?.moreInfo?.centerId?.moreInfo?.address?.state,serial:data?.serial})}`}
                    title={data?.moreInfo?.program}
                    img={data?.moreInfo?.centerId?.moreInfo?.logo}
                    _id={data?._id}
                  />
                </div>

                <button
                  className="btn btn-outline-secondary save-cover-btn img-banner-text-23 border-0 mx-2 font-12 py-1"
                  onClick={saveHandler}
                >
                  save
                  {loading.save ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    <i
                      className={
                        user?.saved?.[section]?.includes(data?._id)
                          ? "bi bi-bookmark-fill mx-1"
                          : "bi bi-bookmark mx-1"
                      }
                    ></i>
                  )}
                </button>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary share-cover-btn mx-2 bg-redDarker border-0 font-12"
                    data-bs-toggle="modal"
                    data-bs-target={
                      user.roles?.includes(9999)
                        ? "#messageModal"
                        : "#exampleModalToggle"
                    }
                  >
                    update
                    <i className="bi bi-arrow-repeat me-1"></i>
                  </button>

                  {/* <!-- Modal --> */}
                  <div
                    // dir="rtl"
                    className="modal fade"
                    id="messageModal"
                    tabIndex="-1"
                    aria-labelledby="sendMessageModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between">
                          <h1
                            className="modal-title fs-5"
                            id="sendMessageModalLabel"
                          >
                           Need an update
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-md-7">
                              {user?.email?.length <= 0 && (
                                <div dir="ltr">
                                  <label htmlFor="emailCheck" className=" mx-2">
                                    E-mail:
                                  </label>
                                  <input
                                    type="email"
                                    placeholder="Please enter your email address"
                                    id="emailCheck"
                                    onChange={(e) =>
                                      setRequest((pre) => ({
                                        ...pre,
                                        email: e.target.value,
                                      }))
                                    }
                                  />
                                </div>
                              )}

                              <div>
                                <label
                                  htmlFor="sessionRequest"
                                  className="med-gray-font my-3"
                                >If the information related to this program needs to be changed or updated, we request you to send us the items you want with details and confirmation link(s). Thank you very much.

                                </label>
                                <textarea
                                  name=""
                                  id="sessionRequest"
                                  cols="50"
                                  rows="7"
                                  onChange={(e) =>{
                                    e.target.style.height = "auto";
                                    e.target.style.height = e.target.scrollHeight + "px";
                                    setRequest((pre) => ({
                                      ...pre,
                                      message: e.target.value,
                                    }))}
                                  }
                                ></textarea>
                              </div>
                              {request.links.map((link, i) => (
                                <div className="my-2">
                                  <label htmlFor={"link" + i} className="mx-2">
                                    Link : {i + 1}
                                  </label>
                                  <input
                                    type="text"
                                    id={"link" + i}
                                    defaultValue={link[i]}
                                    onChange={(e) =>
                                      setRequest((pre) => ({
                                        ...pre,
                                        links: [
                                          ...pre.links.slice(0, i),
                                          e.target.value,
                                          ...pre.links.slice(i + 1),
                                        ],
                                      }))
                                    }
                                  />
                                  {i !== 0 && (
                                    <button
                                      className="btn btn-primary share-cover-btn bg-redDarker mx-2 font-12 py-1 border-0"
                                      onClick={() =>
                                        setRequest((pre) => ({
                                          ...pre,
                                          links: pre.links.filter(
                                            (_, index) => index !== i
                                          ),
                                        }))
                                      }
                                    >
                                      Delete
                                    </button>
                                  )}
                                </div>
                              ))}
                              <button
                                className="btn btn-primary share-cover-btn img-banner-text-23 mx-2 font-12 py-1 border-0"
                                onClick={(e) =>
                                  setRequest((pre) => ({
                                    ...pre,
                                    links: [...pre.links, ""],
                                  }))
                                }
                              >
                               Add Link
                              </button>
                            </div>
                            <div className="col-md-5 align-items-center my-5 display-none-md">
                              <img
                                src="/img/illustration/task-animate.svg"
                                alt="claim"
                                loading="lazy"
                                // style={{ verticalAlign: "center" }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-primary share-cover-btn bg-pinkOrange  mx-2 font-12 py-1 border-0"
                            // data-bs-dismiss="modal"
                            onClick={sendRequestHandle}
                          >
                            Send
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary share-cover-btn bg-redDarker mx-2 font-12 py-1 border-0"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ****** end of cover ******** */}
      <div className="mx-md-3">
        <div className="row p-0 m-0">
          <div className=" col-lg-6  d-flex flex-wrap  justify-content-lg-start justify-content-center mt-3 px-lg-5">
            {/* <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lang}/mdcenter/filters?mainCat=${
                data?.category?.mainCat?.[window.lang]
              }&mainCatId=${data?.category?.mainCat?._id}`}
              className="btn btn-primary button-filter button-filter-tag  "
            >
              <span>
                <i className="bi bi-list-ul me-1"></i>
              </span>
              {data?.category?.mainCat?.[window.lang]}
            </Link>
            <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lang}/mdcenter/filters?subCat=${
                data?.category?.subCat?.[window.lang]
              }&subCatId=${data?.category?.subCat?._id}`}
              className="btn btn-primary button-filter button-filter-tag  "
            >
              <span>
                <i className="bi bi-list-nested me-1 "></i>
              </span>
              {data?.category?.subCat?.[window.lang]}
            </Link> */}
          </div>
          <div className=" col-lg-6   d-flex flex-wrap  justify-content-lg-end justify-content-center  mt-3 px-lg-5">
            <a href="#comments2" className="btn btn-primary button-filter ">
              <span>
                <i className="bi bi-chat-dots me-1"></i>
              </span>
              ( {repliesCounter()} ) Comments
            </a>
          </div>
        </div>
        {/* <!-- Listing Details Section Begin -->*/}
        <div className="row m-md-2 p-2 gx-5" dir="ltr">
          <div className="col-lg-8  order-2 order-lg-1">
          
              <div>
                <div className="w-100 image-container image-container-gd rounded-top">
                  <img src={data?.moreInfo?.centerId?.moreInfo?.img} alt={data?.moreInfo?.program} />
                </div>
                <div className="listing-details-information px-md-5  p-3 mb-5">
                  <div className=" divider-small divider-small-en border-0 mt-4"></div>

                  <div className="text-justify mt-3">
                  <div className="fw-bold">Course language</div>
                  
                  { tools.renderTrans({key:data?.moreInfo?.courseLang,section:"languages",lang:"en"}) }
                  </div>
                  <div className="text-justify mt-3">
                  <div className="fw-bold">Program Name</div>
                  <div>{ data?.moreInfo?.program}</div>
                  <div className="med-gray-font text-end cursor entry-meta" onClick={(e) =>
                  window.navigateHandle(
                    `/${window.lang}/demy/center/page/${tools.slugMaker({title:data?.moreInfo?.centerId?.moreInfo?.center,program:data?.moreInfo?.centerId?.moreInfo?.program,state:data?.moreInfo?.centerId?.moreInfo?.address?.state,serial:data?.moreInfo?.centerId?.serial})}`
                  )
                }>                 
                  {data?.moreInfo?.centerId?.center}
                </div>
                  <a className="fst-italic" href={data?.moreInfo?.facultyUrl} target="_blank">            
                  { data?.moreInfo?.faculty}</a>
                  
                  
                  {/* <div>{ data?.moreInfo?.center+", "+data?.moreInfo?.address?.state}</div> */}
                  </div>               
                  { data?.moreInfo?.comments?<div className="text-justify mt-3">
                  <div className="fw-bold" >Comments</div>
                  { data?.moreInfo?.comments}
                  </div>:""}
                  <div className="text-justify mt-3">
                  <div className="fw-bold" >Level</div>
                  { data?.moreInfo?.programLevel}
                  </div>
                  <div className="text-justify mt-3">
                    <div  className="fw-bold">Co-op available</div>
                  { data?.moreInfo?.coop}
                  </div>
                  <div className="text-justify mt-3">
                    <div  className="fw-bold">Distance Education</div>
                  { data?.moreInfo?.distanceEdu}
                  </div>
                  <div className="text-justify mt-3">
                    <div  className="fw-bold">Area of Study</div>
                    <ul className="list-group px-1">
                {data?.moreInfo?.studyArea?.map((st, i) => (
                  <li className="cursor" key={i} onClick={e=>window.navigateHandle(`/${window.lang}/demy/program/filters?studyArea=${st}`)}>                    
                      {st}                    
                  </li>
                ))}
              </ul>
                    
                  {/* {tools.linesSpliter(data?.moreInfo?.studyArea)} */}
                  </div>
                </div>
              </div>
            
            {/* {data?.moreInfo?.centerId?.moreInfo?.tuitions[0]?.resideStatus?.length > 0 && (
              <div className="listing-details-information px-md-5  p-3 mb-5">
                <div className=" divider-small divider-small-en border-0 my-2">
                  <span>
                    <img
                      src="/img/bussiness-page/pay.png"
                      className="arrow4 pe-2"
                      alt="info"
                    />
                  </span>
                  <span className="p-0 mb-0 "> TUITION FEES </span>
                </div>
                <div className="row row-cols-md-2 row-cols-1 g-3 text-justify mb-5  ">
                  {data.moreInfo.tuitions?.map((tuition, i) => (
                    <div className="col">
                      <div className="">
                        <div className="btn text-center primary-btn edit-btn justify w-100 my-3 bg-redDarker">
                          {tuition?.resideStatus}{" "}
                        </div>
                        <div className="fw-bold"></div>
                        {tuition.details.map((detail, i) => (
                          <div className="ps-3 pb-3">
                            <div className="fw-bold ">{detail?.studyLevel}</div>
                            <div>
                              {tools.showPrice(
                                detail?.min,
                                detail.currency,
                                "en"
                              )}{" "}
                              -{" "}
                              {tools.showPrice(
                                detail?.max,
                                detail.currency,
                                "en"
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div></div>
                    </div>
                  ))}
                </div>
                <div className="med-gray-font mt-3">
                  {" "}
                  {tools.linesSpliter(data?.moreInfo?.centerId?.moreInfo?.tuitionDesc)}
                </div>
              </div>
            )}
            {data?.moreInfo?.centerId?.moreInfo?.enrolment[0]?.studyLevel?.length > 0 && (
              <div className="listing-details-information px-md-5 p-3 mb-5">
                <div className=" divider-small divider-small-en border-0 mt-4">
                  <span>
                    <img
                      src="/img/bussiness-page/graduated.png"
                      className="arrow4 pe-2"
                      alt="info"
                    />
                  </span>
                  <span className="px-1 mb-0 ">STUDENT ENROLMENT</span>
                </div>
                <div className="row row-cols-md-2 row-cols-1 g-3">
                  {data?.moreInfo?.centerId?.moreInfo?.enrolment?.map((enrol, i) => (
                    <div className="col ">
                      <div className="fw-bold fs-5">
                        {enrol?.type + " - " + enrol?.studyLevel}
                      </div>
                      <div>{enrol?.qty}</div>
                    </div>
                  ))}
                </div>

                <div className="med-gray-font mt-3">
                  {data?.moreInfo?.centerId?.moreInfo.tuitionDesc}
                </div>
              </div>
            )} */}

            {/* {data?.moreInfo?.centerId?.moreInfo?.resideDesc?.length > 0 && ( */}
            {/* <div className="listing-details-information px-md-5  p-3 mb-5">
              <div className=" divider-small divider-small-en border-0 mt-4">
                <span>
                  <img
                    src="/img/bussiness-page/home.png"
                    className="arrow2 pe-2"
                    alt="info"
                  />
                </span>

                <span className="px-1 mb-0 ">RESIDENCE</span>
              </div>
              <p className=".text-justify mt-3">{data?.moreInfo?.centerId?.moreInfo?.resideDesc}</p>
              <ul className="list-group px-1">
                {data?.moreInfo?.centerId?.moreInfo?.resideLinks?.map((link, i) => (
                  <li key={i}>
                    <a href={link.url} target="_blank">
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div> */}
            {/* )} */}
          </div>
          <div className=" col-lg-4 order-1 order-lg-2">
            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small divider-small-en  border-0">
                <span>
                  <img
                    src="/img/bussiness-page/home-address.png"
                    className="arrow2 ms-2 py-1"
                    alt="more info"
                  />
                </span>
                <span className="mb-0 ">LOCATION</span>
              </div>
              <ul className="list-group px-1" dir="ltr">
                <li className="group-list-item flex-column" dir="ltr">
                  <div dir="ltr">
                    {/* <span>
                      <i className="bi bi bi-geo-alt mx-3"></i>
                    </span> */}

                    <span className="addressBusPage">
                      {`
                    ${
                      data?.moreInfo?.centerId?.moreInfo?.address?.number?.length
                        ? "#" +
                          window.capitalize(data?.moreInfo?.centerId?.moreInfo?.address?.number) +
                          ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.centerId?.moreInfo?.address?.streetNumber?.length
                        ? window.capitalize(
                            data?.moreInfo?.centerId?.moreInfo?.address?.streetNumber
                          ) + ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.centerId?.moreInfo?.address?.route?.length
                        ? window.capitalize(data?.moreInfo?.centerId?.moreInfo?.address?.route) +
                          ","
                        : ""
                    }
                    
                    ${
                      data?.moreInfo?.centerId?.moreInfo?.address?.postalCode
                        ? data?.moreInfo?.centerId?.moreInfo?.address?.postalCode
                        : ""
                    }
                    
                   
                   `}
                    </span>
                  </div>

                  <div className="me-5">
                    {`
                    ${
                      data?.moreInfo?.centerId?.moreInfo?.address?.municipality?.length
                        ? window.capitalize(
                            data?.moreInfo?.centerId?.moreInfo?.address?.municipality
                          ) + ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.centerId?.moreInfo?.address?.city?.length
                        ? window.capitalize(data?.moreInfo?.centerId?.moreInfo?.address?.city) + ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.centerId?.moreInfo?.address?.state?.length
                        ? window.capitalize(data?.moreInfo?.centerId?.moreInfo?.address?.state) +
                          ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.centerId?.moreInfo?.address?.countryShort?.length
                        ? window.capitalize(
                            data?.moreInfo?.centerId?.moreInfo?.address?.countryShort
                          )
                        : ""
                    }
                    `}
                  </div>
                </li>
                <li
                  className="group-list-item pe-auto cursor"
                  onClick={() =>
                    openGoogleMaps(data?.moreInfo?.centerId?.moreInfo?.address?.googleAddress)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="32"
                    fill="currentColor"
                    className="bi bi-sign-turn-right mx-2 my-1"
                    viewBox="0 0 16 32"
                  >
                    <path d="M5 8.5A2.5 2.5 0 0 1 7.5 6H9V4.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L9.41 8.658A.25.25 0 0 1 9 8.466V7H7.5A1.5 1.5 0 0 0 6 8.5V11H5V8.5Z" />
                    <path
                      fillRule="evenodd"
                      d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435Zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134Z"
                    />
                  </svg>
                  <div className="map-dir">Get direction</div>
                </li>
              </ul>

              <div className="listing-details-map mt-3">
                {/* <GoogleMap data={data?.moreInfo?.centerId?.moreInfo?.address.coordinates} /> */}
              </div>
            </div>

            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small divider-small-en border-0">
                <span>
                  <img
                    src="/img/bussiness-page/email.png"
                    className="arrow2 pe-2 py-1"
                    alt="more info"
                  />
                </span>
                <span className="p-0 mb-0 "> UNIVERSITY RESOURCES </span>
              </div>
              <ul className="list-group px-1" dir="ltr">
                <li className="group-list-item">
                  <i className="bi bi-telephone mx-3"></i>
                  {tools.formatPhoneNumber(data?.moreInfo?.centerId?.moreInfo?.phone ?? "")}
                </li>
                {/* <!-- <li className="group-list-item"><i className="bi bi-clock"></i><span>Sat - Mon:</span> 09.00am
                                            - 10.00pm</li> -->*/}
                <li className="group-list-item">
                  <i className="bi bi-globe mx-3"></i>
                  <a href={data?.moreInfo?.centerId?.moreInfo?.website} target="_blank">
                    {data?.moreInfo?.centerId?.moreInfo?.website}
                  </a>
                </li>
                <li className="group-list-item">
                  <i className="bi bi-envelope mx-3"></i>
                  {data?.email}
                </li>
              </ul>
              {/* </div> */}
              <SocialMedias socialMedias={data?.moreInfo?.centerId?.moreInfo?.socialMedia} />

              <div className="listing-details-map mt-3">
                {/* <GoogleMap data={data?.moreInfo?.centerId?.moreInfo?.address.coordinates} /> */}
              </div>
            </div>
            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small divider-small-en  border-0">
                <span>
                  <img
                    src="/img/bussiness-page/maple-leaf.png"
                    className="arrow2 pe-2 py-1"
                    alt="more info"
                  />
                </span>
                <span>Institution Details </span>
              </div>

              <p>
                {" "}
                <strong>DLI# </strong>
                <span>{data?.moreInfo?.centerId?.moreInfo?.dli}</span>
              </p>

              <p className="d-flex justify-content">
                {" "}
                <strong>PGWP-eligible </strong>
                <span className="mx-2">
                  {data?.moreInfo?.centerId?.moreInfo?.pgwp ? "Yes" : "No"}
                </span>
              </p>
          
              <p>
              <strong> More About</strong>
              <div className="med-gray-font text-end cursor" onClick={(e) =>
                  window.navigateHandle(
                    `/${window.lang}/demy/center/page/${tools.slugMaker({title:data?.moreInfo?.centerId?.moreInfo?.center,program:data?.moreInfo?.centerId?.moreInfo?.program,state:data?.moreInfo?.centerId?.moreInfo?.address?.state,serial:data?.moreInfo?.centerId?.serial})}`
                  )
                }>                 
                  {data?.moreInfo?.centerId?.center}
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <SliderPosts data={busSlider}/> */}
      {/* <BusCardSlider data={busSlider} /> */}
      {/* *****************comments************** */}
      <div className="row m-0 p-0 gx-5 justify-content-center ">
        <div className="col-md-9 col-lg-9 col-xl-6" id="comments2">
          <CommentsRating
            id={data?._id}
            value={data}
            setData={setData}
            collection="Program"
          />
        </div>
      </div>
      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );
  async function sendRequestHandle(e) {
    if (!window.logined(9999)) return;    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (request.message.length < 10)
      return messageRef.current.showError({
        message: "Please add more details.",
      });
    if (request.message.length > 2000)
      return messageRef.current.showError({
        message: "Your message is too long.",
      });
    if (request.email.length && !emailRegex.test(request.email))
      return messageRef.current.showError({
        message: "Please verify you email format.",
      });
      if(window.confirm("Send this update ?")!==true)return
    e.target.setAttribute("disabled", true);

    setLoading((pre) => ({ ...pre, spinner: true }));
    const email = request.email.length ? request.email : user.email;
    const response = await apiDemy.programNeedUpdate({
      token: user.token,
      data: {
        message: request.message,
        email,
        center:data?.moreInfo.center,
        program:data?.moreInfo.program,
        links: request.links,
        _id: data._id,
        lang: window.lang,
      },
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    setTimeout(() => e.target.removeAttribute("disabled"), 10000);
    if (response.error)
      return messageRef.current.showError({
        message: response.error,
      });
    messageRef.current.showSuccess({
      message: (
        <div>
          <div>Thanks for your update.</div>
          <div>Your informations sent to the support team.</div>
        </div>
      ),
    });
    // setData(response.data);
    var myModal = document.getElementById("messageModal"); // Get the modal element
    var modal = window.bootstrap.Modal.getInstance(myModal);
    modal.hide();
    document.querySelector("textarea").value = "";
    setRequest({ message: "", email: "", links: [""] });
  }
}
