import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useRef, useState } from "react";
import {
  CheckCircle,
  Clear,
  Error,
  Publish,
  WarningAmber,
} from "@mui/icons-material";
import { isValidPhoneNumber } from "react-phone-number-input";
import { CircularProgress } from "@mui/material";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { CartContext } from "../../contexts/cartContext";
import ToastNote from "../../golbal/notifications/toastNote";
import ColoredSpinner from "../../alret/spiners/coloredSpiner/coloredSpinner";
import AddSocialMedia from "../../persian/addSocialMedia";
import RegisterAddressPlaces from "../business/register/registerAddressPlaces";
import MyIcons from "../../golbal/icons/myIcons";
import FormikErrRender from "../../persian/common/alerts/formikErrRender";
import CatsSelector from "../../golbal/catsSelector";
import addErrClass from "../../persian/common/alerts/addErrClass";
import Tooltip1 from "../../golbal/tooltip/tooltip";
import DatePickerRender from "../../golbal/datePicker";
import tools from "../../../utils/tools";
import apiBusiness from "../../../utils/apiBusiness";
import apiEvent from "../../../utils/apiEvent";
import api from "../../../utils/api";
import MetaDecorator from "../../../utils/metaDecorator";
import FormikListErr from "../../persian/common/alerts/formikListErr";

export default function EventRegister({ action }) {
  const { state:{state,stateShort,lat,lng}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {}); 

  const messageRef = useRef(null);
  const navigate = useNavigate();
  const { user, signedUser } = useContext(CartContext);
  const [loading, setLoading] = useState({ spinner: false });
  const fetchedRef = useRef(null);
  const [existBus, setExistBus] = useState([]);
  const [existArtist, setExistArtist] = useState([]);
  const [resisedPhotoAuth, setResisedPhotoAuth] = useState(null);
  const [originalPhoto, setOriginalPhoto] = useState(null);
  const countryDetails = JSON.parse(localStorage.getItem("countryDetails"));

  const { id } = useParams();

  const urlRegex =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
  const englishRegex = /^[a-zA-Z0-9\s!@#$%^&*()_+{}\[\]:;<>,.?~\\/`'"\-]+$/;
  const formik = useFormik({
    initialValues: {
      action,
      lang: window.lang,
      lcs: window.lcs,
      busId: "",
      logo: "",
      businessCard: "",
      busName: "",
      name: { fa: "", en: "", fr: "" },
      img: "",
      artistId: "",
      artistImg: "",
      _id: "",
      title: { fa: "", en: "", fr: "" },
      desc: { fa: "", en: "", fr: "" },
      complex: { fa: "", en: "", fr: "" },
      email: "",
      phone: "",
      socialMedia: [{ platform: "", url: "" }],
      moreContact: "",
      terms: false,
      tags: "",
      location: {
        displayAddress: "on",
        country,
        countryShort,
        state,
        stateShort,
        city: "",
        route: "",
        municipality: "",
        streetNumber: "",
        number: "",
        postalCode: "",
        googleAddress: "",
        lat: "",
        lng: "",
      },
      dates: { start: "", end: "", eventDate: "", eventTime: "" },
      category: {
        mainCat: {},
        subCat: {},
      },
      capacity: 0,
      isFree: false,
      seeding: [
        { name: "A", rate: 100, qty: 100, currency: countryDetails.currency },
      ],
    },
    onSubmit: async (values) => {
      if (action === "edit")
        return editHandle({ messageRef, setLoading, user, values, navigate });
      if (!values.busId.length)
        return messageRef.current.showError({
          message: " کسب و کار خود را انتخاب کنید.",
        });
      console.log("passed Formik", values);
      delete values.cities;
      setLoading((pre) => ({ ...pre, spinner: true }));
      const response = await apiEvent.registerEvent({
        token: user.token,
        data: values,
      });
      setLoading((pre) => ({ ...pre, spinner: false }));
      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      messageRef.current.showSuccess({
        message: "تبریک، رویداد با موفقیت ثبت شد.",
      });

      formikInitializer(response.data);

      const updateduser = await api.getUser();
      if (updateduser.error) return;
      signedUser(updateduser.data);
      // window.scrollTo({ top: "0", behavior: "smooth" });
      // navigate(`/${window.lang}/event/page/${response.data._id}`, {
      //   state: response.data,
      // });
    },
    validationSchema: Yup.object({
      terms: Yup.boolean().oneOf(
        [true],
        " قوانین را مطالعه و دکمه قبول را فشار دهید."
      ),
      name: Yup.object().shape({
        fa: Yup.string()
          .required(" نام هنری هنرمند را وارد نمائید.")
          .min(3, "نام هنرمند حداقل باید ۳ حرف باشد.")
          .max(50, "نام هنرمند حداکثر باید 50 حرف باشد.")
          .trim(),
        en: Yup.string()
          .matches(englishRegex, "please use english letters.")
          .min(3, "Artist name must be 3 letters and more.")
          .max(50, "Artist name must be 50 letters and less.")
          .trim(),
        fr: Yup.string()
          .matches(englishRegex, "please use english letters.")
          .min(3, "Artist name must be 3 letters and more.")
          .max(50, "Artist name must be 50 letters and less.")
          .trim(),
      }),
      title: Yup.object().shape({
        fa: Yup.string()
          .required(" نام هنری هنرمند را وارد نمائید.")
          .min(3, "نام هنرمند حداقل باید ۳ حرف باشد.")
          .max(50, "نام هنرمند حداکثر باید 50 حرف باشد.")
          .trim(),
        en: Yup.string()
          .matches(englishRegex, "please use english letters.")
          .min(3, "Artist name must be 3 letters and more.")
          .max(50, "Artist name must be 50 letters and less.")
          .trim(),
        fr: Yup.string()
          .matches(englishRegex, "please use english letters.")
          .min(3, "Artist name must be 3 letters and more.")
          .max(50, "Artist name must be 50 letters and less.")
          .trim(),
      }),
      desc: Yup.object().shape({
        fa: Yup.string()
          .required(" نام هنری هنرمند را وارد نمائید.")
          .min(3, "نام هنرمند حداقل باید ۳ حرف باشد.")
          .max(50, "نام هنرمند حداکثر باید 50 حرف باشد.")
          .trim(),
        en: Yup.string()
          .matches(englishRegex, "please use english letters.")
          .min(3, "Artist name must be 3 letters and more.")
          .max(50, "Artist name must be 50 letters and less.")
          .trim(),
        fr: Yup.string()
          .matches(englishRegex, "please use english letters.")
          .min(3, "Artist name must be 3 letters and more.")
          .max(50, "Artist name must be 50 letters and less.")
          .trim(),
      }),
      complex: Yup.object().shape({
        fa: Yup.string()
          .required(" نام مجموعه را وارد نمائید.")
          .min(3, "نام مجموعه حداقل باید ۳ حرف باشد.")
          .max(100, "نام مجموعه حداکثر باید 100 حرف باشد.")
          .trim(),
        en: Yup.string()
          .matches(englishRegex, "please use english letters.")
          .min(3, "complex name must be 3 letters and more.")
          .max(100, "complex name must be 100 letters and less.")
          .trim(),
        fr: Yup.string()
          .matches(englishRegex, "please use english letters.")
          .min(3, "complex name must be 3 letters and more.")
          .max(100, "complex name must be 100 letters and less.")
          .trim(),
      }),

      phone: Yup.string()
        .trim()
        .when("suggestMode", (suggestMode, schema, value) => {
          return (
            value.parent.phone?.length &&
            schema.test(
              "is-valid-phone",
              "شماره تلفن نامعتبر میباشد.",
              (value) => {
                return isValidPhoneNumber(value || "");
              }
            )
          );
        }),
      // ... your other validation rules for phone

      email: Yup.string()
        .trim()
        .lowercase()
        .required("ایمیل را وارد کنید.")
        .email("ایمیل را به درستی وارد کنید."),
      // languages: Yup.array()
      //   .required(" دست کم یک زبان را انتخاب کنید.")
      //   .test(
      //     "array-length",
      //     " دست کم یک زبان را انتخاب کنید.",
      //     (value) => value.length > 0
      //   ),
      // location: Yup.object().shape({
      //   country: Yup.string().required("کشور نمیتواند خالی باشد."),
      //   state: Yup.string().required("استان نمیتواند خالی باشد."),
      //   city: Yup.string().required("شهر نمیتواند خالی باشد."),
      //   municipality: Yup.string(),
      // }),
      socialMedia: Yup.array().of(
        Yup.object().shape({
          platform: Yup.string(),

          url: Yup.string()
            .when("platform", (platform, schema) => {
              return platform
                ? schema.required("لطفاً آدرس URL را وارد نمایید.")
                : schema;
            })
            .test("validUrl", " آدرس را به شکل درست وارد کنید.", (value) => {
              if (!value) return true; // Allow empty value
              // Regular expression to validate URL
              return urlRegex.test(value);
            }),
        })
      ),
      category: Yup.object().shape({
        mainCat: Yup.object().shape({
          _id: Yup.string().required(" دسته‌بندی را انتخاب کنید."),
        }),
        subCat: Yup.object().shape({
          _id: Yup.string().required(" دسته بندی کوچکتر را انتخاب کنید."),
        }),
      }),
      dates: Yup.object().shape({
        start: Yup.date()
          .required(" تاریخ شروع را وارد کنید.")
          .typeError("تاریخ شروع نامعتبر می باشد.")
          .min(new Date(), "تاریخ شروع نمی تواند در گذشته باشد."),
        end: Yup.date()
          .required(" تاریخ پایان را وارد کنید.")
          .typeError("تاریخ پایان نامعتبر می باشد.")
          .min(
            Yup.ref("start"),
            " تاریخ پایان را بعد از تاریخ شروع انتخاب کنید !"
          )
          .test(
            "within-two-months",
            "تاریخ پایان نمی تواند بیشتر از دوماه از تاریخ شروع باشد.",
            function (value) {
              const today = new Date();
              const twoMonthsLater = new Date();
              twoMonthsLater.setMonth(today.getMonth() + 2);
              return value <= twoMonthsLater;
            }
          ),
      }),
    }),
  });

  useEffect(() => {
    fetchExistBus();
    fetchEventDetails();
  }, [id]);

  // useEffect(() => {
  //   fetchExistBus();
  // }, []);

  async function fetchExistBus() {
    if (fetchedRef.current) return;
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await apiBusiness.checkExistBus({
      userId: user._id,
      values: [countryShort,stateShort],
      token: user.token,
      lang:window.lang
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    fetchedRef.current = true;
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    if (response.data.length === 0)
      messageRef.current.showError({
        message: `مالکیت هیچ کسب و کاری در ${countryDetails.name} و در حساب کاربری شما پیدا نشد. `,
      });
    setExistBus(response.data);

    if (!id) {
      formik.setValues((pre) => {
        const bus = response.data[0];
        const pre2 = { ...pre };
        pre2.busName = bus?.busName || {};
        pre2.logo = bus?.logo;
        pre2.businessCard = bus?.businessCard;
        pre2.busId = bus?._id;
        return pre2;
      });
    }
  }

  async function fetchEventDetails() {
    console.log(id);
    if (!id) return;
    const response = await apiEvent.fetchEventDetails({
      token: user.token,
      id,
    });
    if (response.error) return alert(response.error);
    // console.log(response.data);
    formikInitializer(response.data);
  }

  console.log("formik", formik.values);
  // console.log("formik", formik.errors);
  const [lang, setLang] = useState(window.lang);
  const langName =
    lang === "fa"
      ? " فارسی "
      : lang === "en"
      ? " انگلیسی "
      : lang === "fr"
      ? " فرانسه "
      : "";
  const metaTagsData = {
    title: "ثبت رویداد",
    description: " ثبت رویداد و مدیریت آن در متامارس ",
    section: "event register",
    url: `${window.location.origin}/${window.lang}/dashboard/user/event/register`,
    img: "https://www.metamarce.com/img/illustration/buy-photography-gear-online.svg",
    type: "webpage",
    index: false,
  };
  return (
    <div className="user  mt-3  px-0">
      <MetaDecorator data={{ ...metaTagsData }} />
      {loading.spinner && <ColoredSpinner />}
      <ToastNote messageRef={messageRef} />
      {/* changing language start */}

      {/* changing language End */}
      <div className=" mx-0 row align-items-center" style={{ width: "100%" }}>
        <div
          className="col "
          style={{
            backgroundColor: "var(--brownOrange)",
            color: "rgb(48, 23, 23)",
            width: "100%",
            height: "140px",
          }}
        >
          <div
            className="h2 text-center"
            style={{ marginTop: "6rem", color: "var(--beige)" }}
          >
            افزودن ایونت
          </div>
        </div>
      </div>

      {/* <!-- This is htmlFor curve! --> */}

      <div className="business-top-curve">
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: "100%", width: "100%" }}
        >
          <path
            d="M-2.82,59.70 C211.62,278.78 237.58,-55.75 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
            style={{ stroke: "none", fill: "var(--brownOrange)" }}
          ></path>
        </svg>
      </div>
      <div className="col-6 d-flex justify-content-center ">
        <span
          className={`me-1 map-card my-2 d-flex justify-content-center ${
            lang === "fa" && "map-card-active"
          }`}
          onClick={(e) => setLang("fa")}
        >
          فارسی <i className="bi bi-map mx-1"></i>
        </span>
        <span
          className={`me-1 map-card my-2 d-flex justify-content-center ${
            lang === "en" && "map-card-active"
          }`}
          onClick={(e) => setLang("en")}
        >
          English <i className="bi bi-map mx-1"></i>
        </span>
        <span
          className={`me-1 map-card my-2 d-flex justify-content-center ${
            lang === "fr" && "map-card-active"
          }`}
          onClick={(e) => setLang("fr")}
        >
          French <i className="bi bi-map mx-1"></i>
        </span>
      </div>
      {tools.compareCheck(user.roles, [1000, 1299]) && existBus.length > 0 ? (
        <form
          onSubmit={formik.handleSubmit}
          className="row ads-all mx-auto my-5 px-mx-5"
          name="jobRegForm00"
          autoComplete="true"
        >
          <div>
            <div className="userShow py-5 px-md-5">
              <div className="  mx-md-5">
                <div id="existingBus">
                  <div className=""></div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={(e) =>
                      existBusinesses({
                        e,
                        setExistBus,
                        user,
                        fetchedRef,
                        setLoading,
                      })
                    }
                  >
                    <div className="d-flex">
                      <p className="text-center m-0 p-0">
                        برگذار کننده رویداد
                        {/* {formik?.values?.busName?.[window.lang]?.length
                          ? "انتخاب برگذار کننده رویداد"
                          : "این آگهی در ارتباط با "} */}
                      </p>
                      <div className="mx-md-5 titr5">
                        {formik.values.busName?.[window.lang] ?? ""}
                      </div>
                      <div className="ms-auto">
                        <img
                          className="jobCrad-img"
                          src={formik.values.businessCard}
                          alt={formik.values.busName?.[window.lang]}
                        />
                      </div>
                    </div>

                    {loading.existBus && (
                      <CircularProgress color="inherit" size="20px" />
                    )}
                  </div>
                </div>
                {existBus.length > 0 && (
                  <div
                    id="existingBusShowHide"
                    className="BusRegList p-1 mx-auto w-100 ads-all"
                    style={{ display: "none" }}
                  >
                    <span
                      title="بستن"
                      onClick={(e) => {
                        document.querySelector(
                          "#existingBusShowHide"
                        ).style.display = "none";
                      }}
                    >
                      <Clear sx={{ color: "red" }} />
                    </span>
                    {existBus.map((bus) => (
                      <ExistBusRender bus={bus} formik={formik} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <p className="divider2 border-0 mt-3 ">
                 <MyIcons icon="dotsTitr" />
              <span className="p-0 m-z ">جزییات رویداد</span>
            </p>

            <div className="row userShow">
              {/* Right Section */}
              <div className="row col-md-8">
                <div className="col px-3">
                  <div className="userUpdateItem mb-5">
                    <label
                      className="form-label mt-3 titr6 mb-0 required"
                      htmlFor={`title[${lang}]`}
                    >
                      Event Title ({langName})
                      <Tooltip1
                        message="نام رویداد خود را وارد نمائید"
                        position="top-end"
                      />
                    </label>
                    <input
                      id={`title[${lang}]`}
                      {...formik.getFieldProps(`title[${lang}]`)}
                      className={`userUpdateInput form-control box2 ${addErrClass(
                        {
                          formik,
                          field: `title.${lang}`,
                        }
                      )}`}
                      type="text"
                      autoComplete="off"
                      placeholder={formik.values.title?.[lang]}
                    />
                    <FormikErrRender formik={formik} field={`title.${lang}`} />
                  </div>
                  <div className="userUpdateItem mb-5">
                    <label
                      className="form-label mt-3 titr6 mb-0 required"
                      htmlFor={`desc[${lang}]`}
                      htmlFor={`desc[${lang}]`}
                    >
                      Event description ({langName})
                      <Tooltip1
                        message="Extera descriptions if needed"
                        position="top-end"
                      />
                    </label>
                    <input
                      id={`desc[${lang}]`}
                      id={`desc[${lang}]`}
                      {...formik.getFieldProps(`desc[${lang}]`)}
                      {...formik.getFieldProps(`desc[${lang}]`)}
                      className={`userUpdateInput form-control box2 ${addErrClass(
                        {
                          formik,
                          field: `desc.${lang}`,
                          field: `desc.${lang}`,
                        }
                      )}`}
                      type="text"
                      autoComplete="off"
                      placeholder={formik.values.desc?.[lang]}
                      placeholder={formik.values.desc?.[lang]}
                    />
                    <FormikErrRender formik={formik} field={`desc.${lang}`} />
                    <FormikErrRender formik={formik} field={`desc.${lang}`} />
                  </div>

                  <div className="userUpdateItem mb-5">
                    <label
                      className="form-label mt-3 titr6 mb-0 required"
                      htmlFor={`name[${lang}]`}
                    >
                      Artist Name ({langName})
                    </label>
                    <input
                      id={`name[${lang}]`}
                      {...formik.getFieldProps(`name[${lang}]`)}
                      className={`userUpdateInput form-control box2 ${addErrClass(
                        {
                          formik,
                          field: `name.${lang}`,
                        }
                      )}`}
                      type="text"
                      autoComplete="off"
                      placeholder={formik.values.name?.[lang]}
                    />
                    {/* show Artists list starts */}
                    {formik.values.name?.[window.lang]?.length >= 3 && (
                      <div className="d-flex">
                        <span
                          onClick={existArtistHandle}
                          className="btn btn-primary button-filter button-filter-tag w-50  "
                        >
                          لیست هنرمندان
                          {loading.existArtist && (
                            <CircularProgress color="inherit" size="20px" />
                          )}
                        </span>
                        <Tooltip1
                          message="لطفا نام هنرمند مورد نظر را از بین گزینه های پیشنهادی انتخاب نمائید"
                          position="top-end"
                        />
                      </div>
                    )}
                    {/* show Artists list end */}
                  </div>
                  {existArtist.length > 0 && (
                    <div id="existingArtistsShowHide">
                      <span
                        title="close"
                        onClick={(e) => {
                          document.querySelector(
                            "#existingArtistsShowHide"
                          ).style.display = "none";
                          setExistArtist([]);
                        }}
                      >
                        <Clear sx={{ color: "red" }} />
                      </span>
                      <div className="BusRegList p-1">
                        {existArtist?.map((data) => (
                          <ExistArtistRender data={data} formik={formik} />
                        ))}
                      </div>
                    </div>
                  )}

                  <FormikErrRender formik={formik} field={`name.${lang}`} />
                  <CatsSelector formik={formik} section={"event"} />
                </div>
              </div>

              {/* Left Section */}
              <div className="userUpdateItem col-md-4 px-3">
                <div className="col-md-6 userUpdateUpload d-flex flex-column justify-content-center">
                  <img
                    src={resisedPhotoAuth || formik.values?.img}
                    alt={formik.values.name[window.lang]}
                    className="userShowImg-profile"
                    loading="lazy"
                  />

                  <label className="my-2" htmlFor="fileAuth">
                    <Publish className="userUpdateIcon" />
                    تغییر عکس
                  </label>
                  <input
                    type="file"
                    id="fileAuth"
                    onChange={photoHandle}
                    style={{ display: "none" }}
                  />
                  <span
                    className="userUpdateButton edit-btn"
                    style={{ display: resisedPhotoAuth ? "block" : "none" }}
                    onClick={(e) => uploadFileDatabase(e, originalPhoto)}
                  >
                    بارگذاری
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="divider2 border-0 mt-3">
                 <MyIcons icon="dotsTitr" />
              <span className="p-0 m-z "> راههای تماس با مدیریت رویداد</span>
            </p>

            <div className="userShow py-5 px-md-5">
              {/* <p> اطلاعات تماسی که مایلید در آگهی نمایش داده شوند را مشخص کنید.</p> */}

              <label
                className="form-label mt-5 titr6 mb-0 required"
                htmlFor="emailApply"
              >
                ایمیل
              </label>
              <input
                dir="ltr"
                id="emailApply"
                {...formik.getFieldProps("email")}
                className={`userUpdateInput  form-control box2 ${addErrClass({
                  formik,
                  field: "email",
                })}`}
                type="text"
                autoComplete="off"
                placeholder={formik.values.email}
              />
              <FormikErrRender formik={formik} field="email" />
              <label
                className="form-label mt-5 titr6 mb-0 "
                htmlFor="phoneApply"
              >
                شماره تماس
              </label>
              <input
                dir="ltr"
                id="phoneApply"
                {...formik.getFieldProps("phone")}
                className={`userUpdateInput  form-control box2 ${addErrClass({
                  formik,
                  field: "phone",
                })} `}
                type="text"
                autoComplete="off"
                placeholder={formik.values.phone}
              />
              <FormikErrRender formik={formik} field="phone" />
              <div className="form-label mt-5 titr6 mb-0  border-0 ">
                شبکه‌های اجتماعی
              </div>

              <div className=" border-0 mb-0 px-5">
                <AddSocialMedia formik={formik} />
              </div>
              <label
                className="form-label my-0 titr6  "
                htmlFor="moreContactApply"
              >
                دیگر راه‌ها
              </label>
              <input
                id="moreContactApply"
                {...formik.getFieldProps("moreContact")}
                className="userUpdateInput  form-control box2"
                type="text"
                autoComplete="off"
                placeholder={formik.values.email}
              />
            </div>
          </div>
          <div>
            <div className="userShow py-5 px-md-5 ">
              <p className="divider2 border-0 mt-3 ">
                <MyIcons icon="dotsTitr" />
                <span className="p-0 m-z">Event address</span>
              </p>
              <div className="userUpdateItem mb-5">
                <label
                  className="form-label mt-3 titr6 mb-0 required"
                  htmlFor={`complex[${lang}]`}
                >
                  complex name ({langName})
                  <Tooltip1
                    message="Extera descriptions if needed"
                    position="top-end"
                  />
                </label>
                <input
                  id={`complex[${lang}]`}
                  {...formik.getFieldProps(`complex[${lang}]`)}
                  className={`userUpdateInput form-control box2 ${addErrClass({
                    formik,
                    field: `complex.${lang}`,
                  })}`}
                  type="text"
                  autoComplete="off"
                  placeholder={formik.values.complex?.[lang]}
                />
                <FormikErrRender formik={formik} field={`complex.${lang}`} />
              </div>

              <RegisterAddressPlaces formik={formik} section="event" />
            </div>
          </div>

          <div>
            <p className="divider2 border-0 mt-3 ">
               <MyIcons icon="dotsTitr" />
              <span className="p-0 m-z "> امکانات تکمیلی رویداد</span>
            </p>

            <div className="userShow py-5 px-md-5">
              <div className="row g-5 mb-3">
                <div className=" col-md-8 ">
                  {/* <input
                    className="form-check-input px-1"
                    type="checkbox"
                    id="assignedSeat"
                    {...formik.getFieldProps("assignedSeat")}
                  />
                  <label className="form-label titr6 " htmlFor="assignedSeat">
                    {" "}
                    بلیط فروشی براساس محل صندلی
                  </label> */}

                  <p className="form-label titr6">
                    <div>
                      ظرفیت کل رویداد :
                      {formik.values.seeding.reduce((a, c) => c.qty + a, 0) ||
                        formik.values?.capacity}
                      نفر
                    </div>
                    <div>
                      جمع ارزش بلیط ها :
                      {tools.showPrice(
                        formik.values.seeding.reduce(
                          (a, c) => c.qty * c.rate + a,
                          0
                        ),
                        countryDetails.currency
                      )}
                    </div>
                  </p>

                  <div className="d-flex">
                    {/* {!formik.values.assignedSeat&&<div className=" mx-2">
                      <input
                        type="number"
                        autoComplete="off"
                        aria-label="Amount (to the nearest dollar)"
                        value={formik.values.seeding.reduce(
                          (a, c) => c.qty + a,
                          0
                        )}
                        className={`form-control ${addErrClass({
                          formik,
                          field: "capacity",
                        })}`}
                        placeholder="Min"
                        {...formik.getFieldProps("capacity")}
                      />
                      <FormikErrRender formik={formik} field="capacity" />
                    </div>} */}
                  </div>
                  <div className="table" id="maintable2">
                    {formik.values?.seeding.map((seed, i) => (
                      <div key={i} className="mb-2">
                        <div id={i} className="input-group mb-1">
                          <label htmlFor={"seedName" + i}>نام طبقه(سید)</label>
                          <input
                            className={`form-control`}
                            id={"seedName" + i}
                            type="text"
                            {...formik.getFieldProps(`seeding[${i}].name`)}
                          />
                          <label htmlFor={"seedQty" + i}>تعداد بلیط</label>
                          <input
                            className={`form-control`}
                            id={"seedQty" + i}
                            type="number"
                            {...formik.getFieldProps(`seeding[${i}].qty`)}
                          />
                          <label htmlFor={"seedRate" + i}>مبلغ بلیط </label>
                          <input
                            className={`form-control`}
                            id={"seedRate" + i}
                            type="number"
                            {...formik.getFieldProps(`seeding[${i}].rate`)}
                          />

                          <FormikErrRender
                            formik={formik}
                            field={`seeding.${i}.name`}
                          />
                          <FormikErrRender
                            formik={formik}
                            field={`seeding.${i}.qty`}
                          />
                          <FormikErrRender
                            formik={formik}
                            field={`seeding.${i}.rate`}
                          />
                        </div>
                        {i > 0 && (
                          <button
                            type="button"
                            className="deleteContact btn btn login-trigger3 btn-xs mx-2"
                            title="پاک کردن"
                            onClick={() => removeHandle(i)}
                          >
                            <i
                              className="bi bi-x-circle-fill p-0 "
                              style={{ fontSize: "1.5em" }}
                            ></i>
                          </button>
                        )}
                      </div>
                    ))}
                    {!formik.errors.seeding && (
                      <div
                      // className='border-top border-secondary'
                      >
                        <div
                          className="btn btn-xs shadow-none login-trigger2  "
                          title="افزودن گروه طبقه"
                        >
                          <i
                            className="bi bi-plus-circle-fill "
                            style={{ fontSize: "1.5em" }}
                            onClick={addSeedHandle}
                          ></i>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 ">
                  <div className="row g-3 titr6">
                    <div className="">
                      <p>تاریخ شروع بلیط فروشی</p>
                      <DatePickerRender formik={formik} customPath={"dates.start"} />

                   
                      <FormikErrRender formik={formik} field="dates.start" />
                    </div>
                    <div className="">
                      <p>تاریخ پایان بلیط فروشی</p>

                      <DatePickerRender formik={formik} customPath={"dates.end"} />

                      <FormikErrRender formik={formik} field="dates.end" />
                    </div>
                    <div className="">
                      <p>تاریخ رویداد</p>
                      <DatePickerRender formik={formik} customPath={"dates.eventDate"} />

                      {/* <DatePickerRender formik={formik} field={"eventDate"} /> */}
                      <FormikErrRender
                        formik={formik}
                        field="dates.eventDate"
                      />
                    </div>
                    <div className="">
                      <label htmlFor="startTime">ساعت شروع رویداد</label>
                      <div className="cs-form">
                        <input
                          type="time"
                          className="form-control"
                          value="10:05 AM"
                          {...formik.getFieldProps("dates.eventTime")}
                        />
                        <FormikErrRender
                          formik={formik}
                          field="dates.eventTime"
                        />
                      </div>

                      <FormikErrRender
                        formik={formik}
                        field="dates.eventDate"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* افزودن دکمه فرستادن رزومه */}
              <p id="tagsInput" className="mt-5 form-titr ">
                <span>
                  <i className="bi bi-circle-fill me-1"></i>
                </span>
                برچسب‌ها (با "+" جدا شوند )
                <Tooltip1
                  message={
                    <div>
                      <div>
                        برچسب‌ها - تگ - را توسط '+' (بدون فاصله)از یکدیگر
                        جداکنید.
                      </div>
                      <div>حداکثر ۱۰ برچسب.</div>
                    </div>
                  }
                />
                <input
                  type="text"
                  autoComplete="off"
                  name="other"
                  placeholder={
                    formik?.values?.tags ||
                    `برای مثال : بازسازی+طراحی داخلی+ طراحی فضای سبز`
                  }
                  className="form-input1 mt-3"
                  {...formik.getFieldProps("tags")}
                />
              </p>
            </div>

            {formik.values.assignedSeat && (
              <div className="userShow py-5 px-md-5">
                <div className="row g-5 mb-3">
                  <div className="d-flex flex-column">
                    {formik.values.seeding.map((seed, index) => {
                      let row = [];
                      for (let i = 0; i < seed.qty; i++) {
                        row.push(
                          <li
                            className={`card-background${index + 2}`}
                            title={seed.name + (i + 1)}
                            style={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              // display: 'inline-block',
                              margin: "5px",
                              // backgroundColor: seed.color || 'gray',
                            }}
                          ></li>
                        );
                      }
                      return (
                        <div>
                          {seed.name} <ul> {row} </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            <div className="mt-5">
              <input
                style={{ display: "none" }}
                className="form-check-input"
                id="termsConditions"
                type="checkbox"
                onChange={(e) =>
                  (document.querySelector("#termsContent").style.display = e
                    .target.checked
                    ? "block"
                    : "none")
                }
              />
              <label className=" ps-3" htmlFor="termsConditions">
                مشاهده قوانین
              </label>
              <div
                id="termsContent"
                className="userUpdateInput form-control userUpdateInputTextarea"
                style={{ display: "none" }}
              >
                English Version in following
                <br />
                <br />
                <div>
                  شرایط و مقررات انتشار مشاغل متاجابز این شرایط و مقررات استفاده
                  شما به عنوان کارفرما از خدمات انتشار فرصت های شغلی را تنظیم
                  می‌کند. با دسترسی یا استفاده از خدمات ما به عنوان یک کارفرما،
                  شما موافقت خود را با این شرایط اعلام و تایید می‌کنید. اگر با
                  این شرایط مخالفت دارید، لطفاً از استفاده از خدمات ما به عنوان
                  کارفرما خودداری کنید.
                  <br />
                  1. اهلیت: کارفرماها باید حداقل 18 سال سن داشته باشند تا
                  بتوانند آگهی‌های شغلی را در پلتفرم ما انتشار دهند. کارفرماها
                  مسئول رعایت تمامی قوانین و مقررات مربوط به انتشار مشاغل در
                  پلتفرم ما در سطح محلی، ملی و بین‌المللی هستند.
                  <br />
                  2. آگهی‌های شغلی: کارفرماها می‌بایست اطمینان حاصل کنند که
                  آگهی‌های شغلی با تمامی قوانین و مقررات محلی، ملی و بین‌المللی
                  سازگاری داشته باشند. آگهی‌های شغلی نباید حاوی محتویات مرتبط با
                  فعالیت‌های کیفری، مواد مخدر غیرقانونی، محتوای جنسی و عریانی و
                  فعالیت های هرمی و شبکه ای و یا هر محتوای دیگری که ممکن است با
                  دستورالعمل‌های محتوای ما در تضاد باشد، باشند.
                  <br />
                  3. دستورالعمل‌های انتشار: کارفرماها مسئول ارائه اطلاعات دقیق و
                  به‌روز در آگهی‌های شغلی خود هستند. آگهی‌های شغلی باید به وضوح
                  شرایط و مقررات استخدام را، از جمله ساعات کاری، مزایا و دستمزد،
                  اعلام کنند. کارفرماها باید تمام متقاضیان شغلی را با انصاف و
                  بدون تبعیض بر اساس نژاد، جنسیت، مذهب، معلولیت، سن یا هر مشخصه
                  محافظت‌شده دیگری دریافت کنند.
                  <br />
                  4. مدیریت و حذف: ما حق داریم آگهی‌های شغلی را مدیریت کرده و هر
                  آگهی‌ای که مقررات این شرایط یا دستورالعمل‌های محتوای ما را نقض
                  کند، حذف کنیم. کارفرماهایی که به تکرار این شرایط را نقض
                  می‌کنند، ممکن است حساب کاربری‌شان معلق یا بسته شود.
                  <br />
                  5. حریم خصوصی: ما اطلاعات شخصی شما را به عنوان توضیحات شده در
                  سیاست حریم خصوصی ما جمع‌آوری و پردازش می‌کنیم. با استفاده از
                  خدمات ما، شما به جمع‌آوری و استفاده از اطلاعات شخصی خود به
                  موجب آن موافقت می‌کنید.
                  <br />
                  6. سلب مسئولیت: ما تضمینی در مورد دقت، کمال یا دسترسی به
                  آگهی‌های شغلی در پلتفرم ما ارائه نمی‌دهیم. ما مسئولیتی در مورد
                  هرگونه اختلاف یا مشکلی که ممکن است بین کارفرماها و متقاضیان
                  شغلی به وجود آید، نداریم.
                  <br />
                  7. تغییرات در شرایط: ما ممکن است این شرایط را از زمان به زمان
                  به‌روز کنیم. هر تغییری را که در متامارس انجام شود، اعلام
                  خواهیم کرد، <br />
                </div>
                <br />
                <div dir="ltr">
                  Persian version in the above
                  <br />
                  <br />
                  Metamarce Employer Job Posting Terms and Conditions These
                  Terms and Conditions ("Terms") govern your use of Metamarce
                  ("we," "our," or "us") job posting services and website
                  (collectively, the "Service") as an employer. By accessing or
                  using our Service as an employer, you agree to comply with and
                  be bound by these Terms. If you do not agree to these Terms,
                  please do not use our Service.
                  <br />
                  1. Eligibility: Employers must be at least 18 years old to
                  post job listings on our platform. Employers are responsible
                  for adhering to all applicable local, national, and
                  international laws and regulations related to job postings on
                  our platform.
                  <br />
                  2. Job Listings: Employers must ensure that job listings
                  comply with all local, national, and international laws and
                  regulations. Job listings must not contain content related to
                  criminal activities, illegal drugs, sexual content, nudity, or
                  any other content that may violate our content guidelines.
                  <br />
                  3. Posting Guidelines: Employers are responsible for providing
                  accurate and up-to-date information in their job listings. Job
                  listings must clearly state the terms and conditions of
                  employment, including working hours, compensation, and
                  benefits. Employers must treat all job applicants fairly and
                  without discrimination based on race, gender, religion,
                  disability, age, or any other protected characteristic.
                  <br />
                  4. Moderation and Removal: We reserve the right to moderate
                  job listings and remove any listings that violate these Terms
                  or our content guidelines. Employers who repeatedly violate
                  our terms may have their accounts suspended or terminated.
                  <br />
                  5. Privacy: We collect and process personal information as
                  described in our Privacy Policy. By using our Service, you
                  consent to the collection and use of your personal information
                  as outlined therein.
                  <br />
                  6. Disclaimers: We do not guarantee the accuracy,
                  completeness, or availability of job listings on our platform.
                  We are not responsible for any disputes or issues that may
                  arise between employers and job applicants.
                  <br />
                  7. Changes to Terms: We may update these Terms from time to
                  time. Any changes will be posted on our website, and your
                  continued use of our Service as an employer after such changes
                  constitute your acceptance of the revised Terms.
                  <br />
                  8. Contact Us: If you have any questions or concerns about
                  these Employer Job Posting Terms and Conditions, please
                  contact us at metamarce.com@gmail.com .
                </div>
              </div>
              <label
                htmlFor="termsBtn"
                className={`required my-3 mb-5 ${addErrClass({
                  formik,
                  field: "terms",
                })} `}
              >
                قوانین را مطالعه کردم و می‌پذیرم
                <input
                  id="termsBtn"
                  type="checkbox"
                  className="form-check-input mx-2"
                  onChange={(e) => {
                    if (formik.values.terms) return;
                    formik.setValues((pre) => ({ ...pre, terms: true }));
                  }}
                  checked={formik.values.terms}
                />
                <FormikErrRender formik={formik} field="terms" />
              </label>
            </div>
          </div>
          <FormikListErr formik={formik} />
          <SubmitBtn formik={formik} />
        </form>
      ) : (
        <div>
          <div>
            <div>
              <WarningAmber sx={{ color: "red" }} />
              برای ثبت فرصت شغلی دست کم باید مالکیت یک کسب و کار را در حساب خود
              داشته باشید.
            </div>
            <div>
              <WarningAmber sx={{ color: "red" }} />
              اگر در {countryDetails.name} کسب و کار ثبت شده ای ندارید می توانید
              از گوشه سمت چپ و بالا کشور یا استان خود را تغییر دهید.
              <span
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasScrolling2"
                aria-controls="offcanvasScrolling2"
                className="btn btn-primary share-cover-btn mx-2"
              >
                یا روی من کلیک کنید
              </span>
            </div>
          </div>
          <Link
            to={`/${window.lang}/dashboard/user/business/register`}
            className="btn btn-primary share-cover-btn mx-2"
          >
            افزودن کسب و کار
          </Link>
          <Link
            to={`/${window.lang}/business/filters?validation=draft`}
            className="btn btn-primary share-cover-btn mx-2"
          >
            دیدن کسب و کارهای فاقد مالکیت
          </Link>
        </div>
      )}

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );

  function SubmitBtn({ text, formik }) {
    let textBtn = "بفرست";
    console.log("action", action);
    if (action === "edit") textBtn = "ویرایش";
    return (
      <button
        disabled={formik.isSubmitting}
        className="my-1 login-btn login-btn2"
        style={{
          backgroundColor: Object.keys(formik.errors)?.length > 0 && "grey",
        }}
        type="submit"
        id="createBusBtn"
      >
        {textBtn}
        {loading.spinner ? (
          <CircularProgress color="inherit" size="20px" />
        ) : (
          Object.keys(formik.errors)?.length === 0 && <CheckCircle />
        )}
        <span>
          {Object.keys(formik.errors)?.length > 0 && (
            <Tooltip1
              message=" موارد ستاره دار را کامل کنید."
              icon={<Error sx={{ fontSize: 18, color: "red" }} />}
              position="left"
            />
          )}
        </span>
      </button>
    );
  }
  function addSeedHandle() {
    console.log("addSeedHandle");

    formik.setValues((prev) => {
      const newF = { ...prev };
      const index = newF.seeding.length;
      // console.log("addSeedHandle", newF, "index", index);
      newF.seeding.push({ name: "", rate: "", qty: "" });
      return newF;
    });
  }

  function removeHandle(index) {
    console.log("removeHandle");
    formik.setValues((prev) => {
      const newFormik = { ...prev };
      newFormik.seeding.splice(index, 1);
      return newFormik;
    });
  }
  async function existArtistHandle(e) {
    e.preventDefault();
    if (!user.roles?.includes(9999))
      return document.querySelector("#loginTrigger").click();
    setLoading((pre) => ({ ...pre, Artist: true }));
    const response = await apiEvent.fetchArtList({
      data: { lcs: window.lcs, searchKey: formik.values.name[window.lang] },
      token: user.token,
    });
    setLoading((pre) => ({ ...pre, Artist: false }));
    if (response.error) return alert(response.error);
    setExistArtist(response.data);
    const el = document.querySelector("#existingArtistsShowHide");
    document.querySelector("#existItemNotAvail")?.remove();
    if (el !== null) {
      el.style.display = el.style.display === "none" ? "block" : "none";
    }
    if (response.data.length == 0)
      return el.insertAdjacentHTML(
        "beforeEnd",
        `<div id="existItemNotAvail">موردی پیدا نشد.</div>`
      );
    document.querySelector("#existItemNotAvail")?.remove();
  }
  function formikInitializer(data) {
    formik.setValues({
      action,
      lcs: data.lcs,
      lang: data.lang,
      terms: data.moreInfo.terms,
      category: data.category,
      email: data.email,
      address: data.moreInfo.address,
      phone: data.moreInfo.phone,
      busId: data.busInfo.busId,
      logo: data.busInfo.logo,
      businessCard: data.busInfo.businessCard,
      busName: data.busInfo.busName,
      name: data.artistInfo.name,
      img: data.img,
      artistId: data.artistInfo.artistId,
      artistImg: data.artistInfo.img,
      title: data.moreInfo.title,
      desc: data.moreInfo.desc,
      complex: data.moreInfo.complex,
      socialMedia: data.moreInfo.socialMedia,
      moreContact: data.moreInfo.moreContact,
      terms: true,
      tags: data.moreInfo.tags?.join("+"),
      location: data.moreInfo.address,
      dates: data.moreInfo.dates,
      capacity: data.moreInfo.capacity,
      seeding: data.moreInfo.seeding,
      _id: data._id,
    });
  }
  async function photoHandle(e) {
    if (!tools.photoCheck(e))
      return messageRef.current.showError({
        message: " از فایل با فرمت عکس استفاده کنید.",
      });
    console.log("photoHandle auth", originalPhoto);
    setResisedPhotoAuth(
      await tools.resizeFile({
        file: e.target.files[0],
        width: 500,
        height: 500,
      })
    );
    setOriginalPhoto(e.target.files[0]);
  }

  async function uploadFileDatabase(e, file) {
    e.preventDefault();
    if (!file) return;
    const resizedPhotoBlob = await tools.resizeFile({
      file,
      blob: true,
      width: 500,
      height: 500,
    });
    const formData = new FormData();
    formData.append("file", resizedPhotoBlob);
    console.log("formData", formData);
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: "images/profile/artist",
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div> <div dir="ltr">{response.error}</div>
          </div>
        ),
      });

    console.log(response.data);
    formik.setValues((pre) => ({ ...pre, img: response.data }));
    setResisedPhotoAuth(null);
    messageRef.current.showSuccess({
      message: "عکس با موفقیت بارگذاری شد، جهت ثبت کلید ویرایش را فشار دهید. ",
    });
  }
}

async function editHandle({ messageRef, setLoading, user, values, navigate }) {
  console.log("edit handle triggred");
  setLoading((pre) => ({ ...pre, spinner: true }));
  const response = await apiEvent.edit({ token: user.token, data: values });
  setLoading((pre) => ({ ...pre, spinner: false }));
  if (response.error)
    return messageRef.current.showError({
      message: (
        <div>
          <div> مشکل در انجام عملیات !</div>
          <div dir="ltr">{response.error}</div>
        </div>
      ),
    });

  messageRef.current.showSuccess({
    message: " رویداد با موفقیت ویرایش شد.",
  });
  window.scroll(0, 0);
  navigate(`/${window.lang}/event/page/${response.data._id}`, {
    state: response.data,
  });
}
async function existBusinesses({ e, user }) {
  e.preventDefault();
  if (!user.roles?.includes(9999))
    return document.querySelector("#loginTrigger").click();
  const showHide = document.querySelector("#existingBusShowHide");

  if (showHide !== null) {
    showHide.style.display =
      showHide.style.display === "none" ? "block" : "none";
  }

  document.querySelector("#existItemNotAvail")?.remove();
}
function ExistBusRender({ bus, formik }) {
  return (
    <div
      key={bus?._id}
      className="m-1 p-3 bs-1 d-flex justify-content-between w-100"
    >
      <div className="d-flex ad-select">
        <input
          type="radio"
          id={`ad-select-bus${bus?._id}`}
          name="busRadio"
          style={{ display: "none" }}
          defaultChecked={bus?.busId === formik?.values?.busId}
          onChange={(e) => {
            const showHide = (document.querySelector(
              "#existingBusShowHide"
            ).style.display = "none");

            formik.setValues((pre) => {
              const pre2 = { ...pre };
              // pre2.facilities = bus?.facilities || pre2.facilities;
              pre2.busName = bus?.busName || "";
              // pre2.img = "";
              pre2.businessCard = bus?.businessCard;
              pre2.logo = bus?.logo;
              // pre2.isPaid = bus?.isPaid;
              // pre2.targetIdentifier = bus?.targetIdentifier;
              pre2.busId = bus?._id;
              return pre2;
            });
          }}
        />
        <label
          htmlFor={`ad-select-bus${bus?._id}`}
          className="d-flex justify-content-between"
        >
          <img
            height="40px"
            width="60px"
            src={bus?.businessCard}
            alt={bus?.busName?.[window.lang]}
          />
          <MyIcons icon={bus.status} />
          <span className="m-1 p-2">{bus?.busName?.[window.lang]}</span>
          <span className="m-1 p-2">
            دسته بندی : {bus?.category?.subCat?.[window.lang]}
          </span>

          <span className="m-1 p-2">ایمیل : {bus?.email}</span>
          <div className="m-1 p-2" dir="ltr">
            <i className="bi bi bi-geo-alt mx-2"></i>
            {`${window.capitalize(bus?.address?.city)},
              ${window.capitalize(bus?.address?.state)},
              ${window.capitalize(bus?.address?.countryShort)}
              `}
          </div>
        </label>
      </div>

      <Link
        className="d-flex justify-content-around"
        to={`/${window.lang}/event/page/${bus?._id}`}
      >
        <button className="btn primary-btn delete-btn"> دیدن صفحه </button>
      </Link>
    </div>
  );
}
function ExistArtistRender({ data, formik }) {
  return (
    <div
      key={data?._id}
      className="m-1 p-3 bs-1 d-flex justify-content-between w-100"
    >
      <div className="d-flex ad-select">
        <input
          type="radio"
          id={`ad-select-artist${data?._id}`}
          name="busRadio"
          style={{ display: "none" }}
          defaultChecked={data?._id === formik?.values?.artist?._id}
          onChange={(e) => {
            const showHide = document.querySelector("#existingArtistsShowHide");
            console.log("showHide", showHide);
            showHide.style.display = "none";
            formik.setValues((pre) => ({
              ...pre,
              name: data.name,
              artistImg: data.img,
              img: formik.values.img || data.img,
              artistId: data._id,
            }));
          }}
        />
        <label
          htmlFor={`ad-select-artist${data?._id}`}
          className="d-flex justify-content-between"
        >
          <img
            height="40px"
            width="60px"
            src={data?.img}
            alt={data?.name?.[window.lang]}
          />
          <MyIcons icon={data.status} />
          <span className="m-1 p-2">{data?.name?.[window.lang]}</span>

          <span className="m-1 p-2">
            نام : {data?.moreInfo.firstName?.[window.lang]}
          </span>
          <span className="m-1 p-2">
            نام فامیلی : {data?.moreInfo.lastName?.[window.lang]}
          </span>
        </label>
      </div>

      <Link
        className="d-flex justify-content-around"
        to={`/${window.lang}/artist/page/${data?._id}`}
      >
        <button className="btn primary-btn delete-btn"> دیدن صفحه </button>
      </Link>
    </div>
  );
}
