import { NavLink } from "react-router-dom";
import tools from "../../../../../utils/tools";
import { useContext } from "react";
import { CartContext } from "../../../../contexts/cartContext";


export default function UserDashJobsNav() {

  const {user}= useContext(CartContext)
  const showEdit = window.location.pathname.split("/").slice(7, 8).toString();
  const trans={   
    register:{fa:"افزودن ",en:"Register"},
    myJobs:{fa:"فرصت های شغلی",en:"My Jobs"},
    addHide:{fa:"افزودن گوشه و کنار",en:"Add Hide"},
    reportHide:{fa:"گزارش گوشه و کنار",en:"Hide List"},   
    editReady:{fa:"ویرایش آماده کار",en:"Edit Ready"},
    addReady:{fa:"آماده کار",en:"Add Ready"},
  }
  return (
    <div className="dashboardNavigator d-flex">
      {tools.compareCheck (user?.roles,[1000,1299,1200])&&<>
      <NavLink
        to={`/${window.lang}/dashboard/user/jobs/register`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >{trans.register[window.lang]}       
      </NavLink>
      <NavLink
        to={`/${window.lang}/dashboard/user/jobs/list`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {trans.myJobs[window.lang]}
       
      </NavLink>
      </>}
      <NavLink
        to={`/${window.lang}/dashboard/user/jobs/registerhide`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {trans.addHide[window.lang]}
        
      </NavLink>
      <NavLink
        to={`/${window.lang}/dashboard/user/jobs/listHide`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {trans.reportHide[window.lang]}
        
      </NavLink>
      {/* <NavLink
        to={`/${window.lang}/dashboard/user/jobs/registerReady`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        
        آماده به کار
      </NavLink> */}
      {/* <NavLink
        to={`/coming`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        لیست درخواست‌دهندگان
      </NavLink> */}
      {showEdit === "editReady" ? (
        <NavLink
          to={`/${window.lang}/dashboard/user/jobs/editReady`}
          className={`sidebarListItem m-1 link  ${({ isActive }) => {
            return isActive && "active";
          }}`}
        >
        {trans.editReady[window.lang]}
        </NavLink>
      ):
      <NavLink
      to={`/${window.lang}/dashboard/user/jobs/registerReady`}
      className={`sidebarListItem m-1 link  ${({ isActive }) => {
        return isActive && "active";
      }}`}
    >{trans.addReady[window.lang]}
     
    </NavLink>
    
    }
    </div>
  );
}
