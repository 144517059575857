import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Rating } from "@mui/material";
import api from "../../../../utils/api";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
import ShareSocialMedias from "../../business/components/shareSocialMedias";

export default function EventCard2(props) {
  console.log("props.data", props.data);
  const messageRef = useRef(null);
  const [spiner, setSpiner] = useState(false);
  const navigate = useNavigate();
  function navigateHandle(url) {
    window.scroll(0, 0);
    setTimeout(() => navigate(url, { state: { event: props.business } }), 200);
  }
  const { signedUser, user } = useContext(CartContext);
  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const el = e.currentTarget;
    el.setAttribute("disabled", true);
    setSpiner(true);
    const response = await api.saveHandler({
      id: props.data._id,
      token: user.token,
      // title: props.data.busName,
      section: "event",
    });
    setSpiner(false);
    setTimeout(() => el.removeAttribute("disabled"), 1000 * 6);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>عملیات ذخیره ناموفق!</div>
            <div> دوباره وارد شوید.</div>
          </div>
        ),
      });
    // user?.saved.business.find((x) => x._id == props.business?._id);
    signedUser(response.data);
    !user?.saved.event?.includes(props.data?._id)
      ? messageRef.current.showSuccess({
          message: "رویداد با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "رویداد از لیست ذخیره حذف شد.",
        });
  };
  return (
    <div className="col pb-3 px-0 h-100 ">
      <ToastNote messageRef={messageRef} />
      <div className="card mx-2  border-0 shadow h-100">
        <div className="card-img-pack ">
          <div className="business-card-img-wrap">
            <div
              className="image-container image-container-bus"
              onClick={(e) =>
                navigateHandle(
                  props.data._id
                    ? `/${window.lang}/event/page/${props.data._id}`
                    : props.data.url
                )
              }
            >
              <img
                src={props.data?.img}
                className={`card-img-top  image-card business-card-img ${
                  props.data?.moreInfo?.status === "01-draft" && "unclaimed-img"
                }`}
                alt={(
                  props.data?.busInfo?.busName?.[window.lang] ||
                  props.data.moreInfo?.title[window.lang]
                )?.substring(0, 15)}
                loading="lazy"
              />
            </div>
          </div>
          {props.data.category?.mainLogo && (
            <div
              onClick={(e) =>
                navigateHandle(
                  `?mainCat=${
                    props.data.category?.mainCat?.[window.lang]
                  }&mainCatId=${props.data.category?.mainCat?._id}`
                )
              }
              to={`?mainCat=${
                props.data.category?.mainCat?.[window.lang]
              }&mainCatId=${props.data.category?.mainCat?._id}`}
              title={props.data.category?.mainCat?.[window.lang]}
            >
              <img
                src={props.data.category?.mainLogo}
                className="card-logo "
                alt={props.data.category?.mainCat?.[window.lang]}
                loading="lazy"
              />
            </div>
          )}

          {/* <div className="listing-item-pic-tag"> تخفیف ویژه</div> */}
          {/* <!-- <span className="icon-stack2">
                            <i className="bi bi-check icon-stack-2x"></i>
                        </span> --> */}
          {/* <!-- <i className="bi bi-shield-fill-check icon-logo "></i> --> */}
        </div>
        <div className="card-body mt-4" dir="ltr">
          <h5
            dir="rtl"
            className="card-title card-titel2 m-1 cursor"
            onClick={(e) =>
              navigateHandle(
                props.data._id
                  ? `/${window.lang}/event/page/${props.data._id}`
                  : props.data.url
              )
            }
          >
            {props.data.artistInfo?.name?.[window.lang] ||
              props.data?.moreImfo?.title[window.lang]}
          </h5>

          {/* <!-- *******rating stars shold show up here --> */}
          <p dir="ltr" className="text-start">
            <span
              title={
                Number(props.data?.rate) > 0 &&
                Number(props.data?.rate.toFixed(2))
              }
            >
              <Rating
                name="read-only"
                value={Number(props.data?.rate)}
                readOnly
                precision={0.5}
              />
            </span>
          </p>

          <div className="card-text card-text1">
            <div className="list-group">
              {props.data?.address?.city && (
                <span className="group-list-item2 my-1">
                  <i className="bi bi bi-geo-alt mx-2"></i>
                  {`${window.capitalize(props.data?.address?.city)},
                    ${window.capitalize(props.data?.address?.state)},
                    ${window.capitalize(props.data?.address?.countryShort)}
                `}
                </span>
              )}
            </div>
          </div>
        </div>
        {/* <!-- end of card bady --> */}
        {props.data?.category && (
          <div className="card-footer py-4  ">
            {/* <!-- <a href="#" className="btn btn-primary btn-card stretched-link">  بیشتر</a> --> */}

            <div
              onClick={(e) =>
                navigateHandle(`/${window.lang}/event/page/${props.data._id}`)
              }
              className="btn btn-primary btn-card mx-1"
            >
              جزییات بیشتر
            </div>

            <span
              className="icon-stack"
              onClick={(e) => window.open(`tel:${props.data?.phone}`)}
            >
              <i
                className="bi bi-telephone icon-stack-1x px-1"
                title=" تماس"
              ></i>
            </span>

            <span className="icon-stack ">
              <button className="icon-btn" onClick={saveHandler}>
                {spiner ? (
                  <CircularProgress color="inherit" size="20px" />
                ) : (
                  <i
                    className={
                      user?.saved !== undefined &&
                      user?.saved?.event?.includes(props.data._id)
                        ? "bi bi-bookmark-fill icon-stack-1x"
                        : "bi bi-bookmark icon-stack-1x"
                    }
                    title="ذخیره"
                    style={{ color: "rgba(123, 99, 90, 0.894)" }}
                  ></i>
                )}
              </button>
            </span>
            <span>
              <button
                className=" icon-stack mx-1 "
                data-bs-toggle="modal"
                data-bs-target={`#myModel${props.data._id}`}
                id={`shareBtn${props.data._id}`}
                data-bs-placement="top"
                title="Share!"
              >
                <i className="bi bi-share icon-stack-1x" title="همرسانی"></i>
              </button>
            </span>
            <ShareSocialMedias
              url={`${window.location.origin}/${window.lang}/event/page/${props.data._id}`}
              title={props.data.moreInfo?.busName?.[window.lang]}
              img={props.data.img}
              _id={props.data._id}
            />
          </div>
        )}
        {/* <!-- end of card footer --> */}
      </div>
    </div>
  );
}
