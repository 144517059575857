import React from "react";
import { Link } from "react-router-dom";
import MyIcons from "../../icons/myIcons";
import tools from "../../../../utils/tools";

export default function BusSmallCard({ data, section }) {
  let url = "";
  if (section === "business")
    url = `/${window.lang}/${section}/page/${tools.slugMaker({title:data.busName?.[window.lang],city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`;
  if (section === "jobs") url = `/${window.lang}/${section}/page/${tools.slugMaker({title:data?.moreInfo?.title,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`;
  if (section === "media")
    url = `/${window.lang}/${section}/filters?mediaId=${data?._id}&title=${
      data?.title[window.lang]
    }`;

  return (
    <div className="card bg-dark text-white card1 ">
      <Link
        onClick={(e) => window.scroll(0, 0)}
        className="image-container "
        to={url}
        state={data}
      > 
        <img
          src={data?.moreInfo?.photos?.businessCard || data?.img}
          className="card-img card-img1 w-100 "
          alt={data?.busName?.[window.lang] || data?.title[window.lang]}
          loading="lazy"
        />
        <div className="card-img-overlay ">
          <div>
            <h5 className="card-title card-titel1 card-titel3 mt-auto">
              {data?.busName?.[window.lang] || data?.title[window.lang]}{" "}
              <MyIcons icon={data.status} />
            </h5>
            <div
              className="group-list-item small-gray-font my-1"
              style={{ color: "beige" }}
            >
              <i className="bi bi bi-geo-alt mx-2"></i>
              {`${window.capitalize(data?.moreInfo?.address?.city||data?.address?.city)},
                    ${window.capitalize(data?.moreInfo?.address?.state||data?.address?.state)},
                    ${window.capitalize(data?.moreInfo?.address?.countryShort||data?.address?.countryShort)}
                `}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
