import "../demy.css";
import React, { useContext, useRef, useState } from "react";
// import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import api from "../../../../utils/api";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
// import Tooltip1 from "../../../golbal/tooltip/tooltip";
// import MyIcons from "../../../golbal/icons/myIcons";
import ShareSocialMedias from "../../business/components/shareSocialMedias";
// import renderData from "../../../../utils/renderData";
import tools from "../../../../utils/tools";
import RatingManuel from "../../../comment/rating";
import demyData from "../../../../utils/demyData";
import TutorGrades from "./tutorGrades";
// import ShowAddress from "../../../golbal/showAddress";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import ProfTutor from "./profTutor";

export default function TutorCard({
  data,
  currencies,
  setVideo,
  setActiveTutor,
}) {
  // console.log("data", data);
  const messageRef = useRef(null);
  const [spiner, setSpiner] = useState(false);

  const { signedUser, user } = useContext(CartContext);
  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const el = e.currentTarget;
    el.setAttribute("disabled", true);
    setSpiner(true);
    const response = await api.saveHandler({
      id: data._id,
      section: "tutor",
      token: user.token,
    });
    setSpiner(false);
    setTimeout(() => el.removeAttribute("disabled"), 1000 * 2);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            {/* <div>عملیات ذخیره ناموفق!</div> */}
            <div> Please sign in again.</div>
          </div>
        ),
      });
    signedUser(response.data);

    !user?.saved?.tutor?.includes(data?._id)
      ? messageRef.current.showSuccess({
          message: trans.save[window.lang],
        })
      : messageRef.current.showWarning({
          message:  trans.unSave[window.lang],
        });
  };

  const {
    currency,
    currency_symbol,
    exRate,
    convertRate,
    accepted,
    translations,
  } = JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const matchedCurr = currencies.find(
    (curr) => curr.currency === data?.moreInfo?.salary?.currency
  );

  const isOwner = data?.moreInfo?.history?.createdBy?._id === user?._id;
  const trans={
    freeSession:{fa:"جلسه آزمایشی",en:"Free session"},
    sendMessage:{fa:"فرستادن پیام",en:"Send message"},
    repeatedMessage:{fa:"درگذشته پیام شما فرستاده شده است، \n لطفا در پیام رسان متامارس ادامه دهید.",en:"Your message has been sent in the past,\n Please continue in Meta chat."},
    review:{fa:"دیدگاه",en:"review(s)"},
    views:{fa:"بازدید",en:"view(s)"},
    noRev:{fa:"بدون",en:"whitout"},
    save:{fa:" صفحه استاد با موفقیت ذخیره شد.",en:"Tutor saved successfully."},
    unSave:{fa:"صفحه استاد از لیست ذخیره حذف شد.",en:"Tutor unsaved."},
    }
  return (
    <div key={data._id} className="col px-0 tutorCard">
      <ToastNote messageRef={messageRef} />
      <div className="card entry p-md-4 p-3 pt-4">
        <div className=" row g-3">
          <div className="col-md-2 px-0">
            <div className="row display-tablet">
              <div className="col-4 p-0">
                <div>
                  <div
                    className="image-container image-container-media text-center cursor"
                    onClick={(e) =>
                      window.navigateHandle(                  
                        `/${window.lang}/demy/tutor/page/${tools.slugMaker({title:data.name?.[window.lang],city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`,
                        { currencies, data }
                      )
                    }
                  >
                    <img
                      src={
                        data?.moreInfo?.img ||
                        "/img/Metademy/Green-Red-Stickers-5.png"
                      }
                      // className="picture-src"
                      id={"tutor" + data?._id}
                      title={data.name?.[window.lang]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div>
                  <div className="d-flex align-items-center">
                    <div
                      className="titr5 text-start text-gray-green cursor"
                      onClick={(e) =>
                        window.navigateHandle(
                          `/${window.lang}/demy/tutor/page/${tools.slugMaker({title:data.name?.[window.lang],city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`,
                          { currencies, data }
                        )
                      }
                    >
                      {data.name?.[window.lang]}
                    </div>
                    <Tooltip1
                      key={data?._id}
                      icon={
                        <img
                          // width="25px"
                          style={{ width: "25px" }}
                          className=""
                          src={`/img/flags/${data?.moreInfo?.address?.countryShort?.toLowerCase()}.svg`}
                          alt={data?.moreInfo?.address?.countryShort}
                          title={window.capitalize(
                            data?.moreInfo?.address?.country
                          )}
                        />
                      }
                      message={
                        <div>
                          {`${data?.moreInfo?.address?.country}, ${data?.moreInfo?.address?.state}, ${data?.moreInfo?.address?.city}`.toUpperCase()}
                        </div>
                      }
                    />
                  </div>
                  <div>
                    <span className="text-pink-orange me-1">
                      (
                      {tools.dynamicNumber({
                        number:
                          Math.floor(data?.feedbackInfo?.rate * 100) / 100,
                        lang: window.lang,
                      })}
                      )
                      {/* {Math.floor(data?.feedbackInfo?.rate * 100) / 100} */}
                    </span>
                    <RatingManuel value={data?.feedbackInfo?.rate} />

                    <span className="review-text font-10 mx-1">
                      (
                      {data?.feedbackInfo?.rates
                        ? tools.dynamicNumber({
                            number: data?.feedbackInfo?.rates,
                            lang: window.lang,
                          }) + " "
                        : trans.review[window.lang]}
                      {trans.noRev[window.lang]} )
                    </span>
                  </div>
                  <div>
                    <ProfTutor data={data} />
                    <TutorGrades data={data} />
                    {user?.roles?.includes(1000) && (
                      <span className="review-text text-center">
                        (
                        <i
                          className="bi bi-eye Business-cover-icon px-1  "
                          title={trans.views[window.lang]}
                        ></i>
                        {data?.feedbackInfo?.views} )
                      </span>
                    )}
                  </div>
                </div>

                {/* <ShowAddress address={data?.moreInfo?.address}/> */}
              </div>
            </div>
            <div className="display-none-md">
              <div
                className="image-container image-container-media text-center cursor"
                onClick={(e) =>
                  window.navigateHandle(
                    `/${window.lang}/demy/tutor/page/${tools.slugMaker({title:data.name?.[window.lang],city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`,
                    { currencies, data }
                  )
                }
              >
                <img
                  src={
                    data?.moreInfo?.img ||
                    "/img/Metademy/Green-Red-Stickers-5.png"
                  }
                  // className="picture-src"
                  id={"tutor" + data?._id}
                  title={data.name?.[window.lang]}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex flex-column display-none-md ps-3">
            <div>
              <div className="d-flex align-items-center">
                <div
                  className="titr5 text-start text-gray-green cursor"
                  onClick={(e) =>
                    window.navigateHandle(
                      `/${window.lang}/demy/tutor/page/${tools.slugMaker({title:data.name?.[window.lang],city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`,
                      { currencies, data }
                    )
                  }
                >
                  {data.name?.[window.lang]}
                </div>
                <Tooltip1
                  icon={
                    <img
                      // width="25px"
                      style={{ width: "25px" }}
                      className=""
                      src={`/img/flags/${data?.moreInfo?.address?.countryShort?.toLowerCase()}.svg`}
                      alt={data?.moreInfo?.address?.countryShort}
                      title={window.capitalize(
                        data?.moreInfo?.address?.country
                      )}
                    />
                  }
                  message={
                    <div>
                      {`${data?.moreInfo?.address?.country}, ${data?.moreInfo?.address?.state}, ${data?.moreInfo?.address?.city}`.toUpperCase()}
                    </div>
                  }
                />
              </div>
              <div>
                <span className="text-pink-orange me-1">
                  (
                  {tools.dynamicNumber({
                    number: Math.floor(data?.feedbackInfo?.rate * 100) / 100,
                    lang: window.lang,
                  })}
                  ){/* {Math.floor(data?.feedbackInfo?.rate * 100) / 100} */}
                </span>
                <RatingManuel value={data?.feedbackInfo?.rate} />

                <span className="review-text font-10 mx-1">
                  (
                  {data?.feedbackInfo?.rates
                    ? tools.dynamicNumber({
                        number: data?.feedbackInfo?.rates,
                        lang: window.lang,
                      }) + " "
                    : trans.noRev[window.lang]}
                  {trans.review[window.lang]} )
                </span>
              </div>
              <div>
                <ProfTutor data={data} />

                <TutorGrades data={data} />
                {user?.roles?.includes(1000) && (
                  <span className="review-text text-center">
                    (
                    <i
                      className="bi bi-eye Business-cover-icon px-1  "
                      title={trans.views[window.lang]}
                    ></i>
                    {data?.feedbackInfo?.views} )
                  </span>
                )}
              </div>
            </div>

            <div className="mt-auto px-3">
              {/* {data?.moreInfo?.stList?.length > 0 && (
                <div className="small-gray-font">
                  <i className="bi bi-pencil me-2"></i>
                  {tools.dynamicNumber({
                    number: data?.moreInfo?.stList?.length,
                    lang: window.lang,
                  })}{" "}
                  دانشجوی فعال
                </div>
              )}
              {data?.moreInfo?.transactions?.sessionCounter > 0 && (
                <div className="small-gray-font">
                  <i className="bi bi-book me-2"></i>
                  {tools.dynamicNumber({
                    number: data?.moreInfo?.transactions?.sessionCounter,
                    lang: window.lang,
                  })}{" "}
                  کلاس برگزار شده
                </div>
              )} */}

              <div className="  divider-small w-100 border-0 mt-3 d-flex">
                <span>
                  <img
                    src="/img/icons/about.png"
                    className="arrow2 pe-2"
                    alt="info"
                  />
                </span>
                <div className="p-0 mb-0 med-gray-font text-justify-metamarce ">
                  {tools.linesSpliter(data?.moreInfo.intro?.[window.lang])}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 px-3 d-flex flex-column">
            <div className="card-footer  d-flex flex-wrap  justify-content-between ">
              <div className="">
                <strong>
                  {tools.showPrice(
                    Math.ceil(
                      Math.ceil(
                        data?.moreInfo?.salary?.rate /
                          (matchedCurr?.exRate ??
                            data?.moreInfo?.salary?.exRate)
                      ) * exRate
                    ),
                    currency
                  )}
                </strong>
              </div>
              <div>
                <button
                  id={`saveBtn${data._id}`}
                  className="icon-btn icon-stack "
                  onClick={saveHandler}
                >
                  {spiner ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    <i
                      className={
                        user.saved?.tutor?.includes(data._id)
                          ? "bi bi-bookmark-fill icon-stack-1x "
                          : "bi bi-bookmark icon-stack-1x "
                      }
                      title="ذخیره"
                    ></i>
                  )}
                </button>
                <button
                  className=" icon-stack mx-1 text-green-dark "
                  data-bs-toggle="modal"
                  data-bs-target={`#myModel${data._id}`}
                  id="shareBtn"
                  data-bs-placement="top"
                  title="Share!"
                >
                  <i className="bi bi-share" title="همرسانی"></i>
                </button>

                {data?.moreInfo?.video?.[window.lang]?.youtube?.length > 0 && (
                  <button
                    className=" icon-stack mx-1 bg-redDark text-beigeYellow text-center "
                    data-bs-toggle="modal"
                    // data-bs-target={`#showVideoModal${data._id}`}
                    data-bs-target={`#showVideoModal`}
                    // id="shareBtn"
                    data-bs-placement="top"
                    title="Youtube!"
                    onClick={(e) =>
                      setVideo(data?.moreInfo?.video?.[window.lang]?.youtube)
                    }
                  >
                    <i
                      className="bi bi-caret-right-fill font-18 "
                      title="ویدیو"
                    ></i>
                  </button>
                )}
              </div>
            </div>
            <div>
              <ShareSocialMedias
                url={`${window.location.origin}/${window.lang}/demy/tutor/page/${tools.slugMaker({title:data.name?.[window.lang],city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`}
                title={data?.name?.[window.lang]}
                img={data?.moreInfo.img}
                _id={data?._id}
              />
            </div>
            <div>
              <div className="m-3 ">
                {data?.moreInfo?.levelSub?.map((sub, i) => (
                  <div key={i} className="d-flex flex-wrap">
                    <ul className="d-flex flex-wrap mb-1 ps-1">
                      <div className=" form-check-label small-gray-font bold-dark-font ">
                        <span>
                          {
                            demyData.tutorLevel.find(
                              (le) => le.name === sub.level
                            )?.[window.lang]
                          }
                        </span>
                        :
                      </div>
                      {sub.subjects?.slice(0, 3).map((sub, i, arr) => (
                        <li
                          key={i}
                          className="ms-1  small-gray-font"
                          // onClick={(e) =>
                          //   window.navigateHandle(`?searchKey=${sub}`)
                          // }
                        >
                          {sub}
                          {i < arr.length - 1 && "،"}
                        </li>
                      ))}
                      <div style={{ position: "relative" }}>
                        {sub.subjects.length > 3 && (
                          <span
                            className="cursor cart-count bg-pinkOrangeDark text-beigeYellow"
                            onClick={(e) =>
                              window.navigateHandle(
                                `/${window.lang}/demy/tutor/page/${tools.slugMaker({title:data.name?.[window.lang],city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`,
                                { currencies, data }
                              )
                            }
                          >
                            +
                            {tools.dynamicNumber({
                              number: sub.subjects.length - 3,
                              lang: window.lang,
                            })}
                          </span>
                        )}
                      </div>
                    </ul>
                  </div>
                ))}
              </div>
              <ul className="d-flex flex-wrap my-3 p-0">
                <i className="bi bi-translate font-20"></i>
                {data?.moreInfo?.languages.slice(0, 2)?.map((lang, i, arr) => (
                  <li key={i} className="small-gray-font pt-1">
                    <span
                      // htmlFor={lang + i}
                      className="form-check-label px-1 "
                    >
                      {tools.renderTrans({
                        section: "languages",
                        key: lang.name,
                      })}
                    </span>
                    (
                    {tools.renderTrans({
                      section: "langsLevel",
                      key: lang.level,
                    })}
                    ){i < arr.length - 1 && "،"}
                  </li>
                ))}
                <div style={{ position: "relative" }}>
                  {data?.moreInfo?.languages.length > 2 && (
                    <span
                      className="cursor cart-count bg-pinkOrangeDark text-beigeYellow"
                      onClick={(e) =>
                        window.navigateHandle(
                          `/${window.lang}/demy/tutor/page/${tools.slugMaker({title:data.name?.[window.lang],city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`,
                          { currencies, data }
                        )
                      }
                    >
                      +
                      {tools.dynamicNumber({
                        number: data?.moreInfo?.languages.length - 2,
                        lang: window.lang,
                      })}
                      {/* {`+${data?.moreInfo?.languages.length - 2}`} */}
                    </span>
                  )}
                </div>
              </ul>
            </div>
            <div className="display-tablet">
              {/* {data?.moreInfo?.stList?.length > 0 && (
                <div className="small-gray-font">
                  {" "}
                  <i className="bi bi-pencil me-2"></i>
                  {data?.moreInfo?.stList?.length} دانشجوی فعال
                </div>
              )}
              {data?.moreInfo?.transactions?.sessionCounter > 0 && (
                <div className="small-gray-font">
                  <i className="bi bi-book me-2"></i>
                  {data?.moreInfo?.transactions?.sessionCounter} کلاس برگزار شده
                </div>
              )} */}

              <div className="  divider-small w-100 border-0 my-2 d-flex">
                <span>
                  <img
                    src="/img/icons/about.png"
                    className="arrow2 pe-2"
                    alt="info"
                  />
                </span>
                <div className="p-0 mb-0 med-gray-font text-justify-metamarce ">
                  {tools.linesSpliter(data?.moreInfo.intro?.[window.lang])}
                </div>
              </div>
            </div>
            <div className="mt-auto">
              <button
                type="button"
                disabled={!data.moreInfo.freeSession}
                className={`btn primary-btn checkout-btn cart-footer bg-${
                  data.moreInfo.freeSession ? "pinkOrange" : "grayGreen"
                }  mt-3  border-0 shadow-none py-1  w-100`}
                onClick={(e) =>
                  window.navigateHandle(
                    `/${window.lang}/demy/tutor/page/${tools.slugMaker({title:data.name?.[window.lang],city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}#tutor-schedule`,
                    { currencies, data }
                  )
                }
              >
                <span>
                  <i className="bi bi-clipboard-check me-2 font-20"></i>
                </span>
                {trans.freeSession[window.lang]}
              </button>
              <button
                type="button"
                // className="btn primary-btn checkout-btn cart-footer bg-pinkOrangeDark mt-3  border-0 shadow-none py-1 w-100 "
                disabled={isOwner}
                className={`btn primary-btn checkout-btn cart-footer bg-${
                  isOwner ? "grayGreen" : "pinkOrangeDark"
                } mt-3  border-0 shadow-none py-1 w-100  `}
                // data-bs-toggle="modal"
                // data-bs-target="messageModal"
                onClick={(e) => {
                  if (!window.logined(9999)) return;
                  if (isOwner) return;
                  setActiveTutor(data);
                  if (data.moreInfo.requests.some((t) => t._id === user?._id)) {
                    document.getElementById("chatBoxBtn").click();
                    messageRef.current.showWarning({ message:trans.repeatedMessage[window.lang] });
                  } else {
                    const myModal = document.getElementById("messageModal"); // Get the modal element
                    const modal = new window.bootstrap.Modal(myModal, {
                      // backdrop: "static",
                      keyboard: true,
                    });
                    modal.show();
                  }
                }}
              >
                <span>
                  <i
                    className="bi bi-send-plus mx-2"
                    style={{ fontSize: 20 }}
                  ></i>
                </span>
               {trans.sendMessage[window.lang]}
              </button>
            </div>
          </div>
        </div>

        {/* <!-- end of card footer --> */}
      </div>
    </div>
  );
}
