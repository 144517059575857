import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import 'slick-carousel/slick/slick.css'; 
import "slick-carousel/slick/slick-theme.css";

import MyIcons from "../icons/myIcons";
import tools from "../../../utils/tools";

export default class HorizontalSlider extends Component {
  
  constructor(props) {
    super(props);   
  }
    scrollToTop = () => {
      window.scroll(0,0);
    };
    redirectHandle = (data) => {  
       const url= ()=>{
        switch (this.props.section) {
        case 'media': return `/${window.lang}/media/filters?mediaId=${data._id}& title=${data.title}`;
        case 'jobs': return `/${window.lang}/jobs/page/${tools.slugMaker({title:data?.moreInfo?.title,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`;
        case 'blog': return `/${window.lang}/blog/post/${tools.slugMaker({title:data?.title,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`;
        case 'tutor': return `/${window.lang}/demy/tutor/page/${tools.slugMaker({title:data.name?.[window.lang],city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`;
        case 'blog': return `/${window.lang}/blog/post/${tools.slugMaker({title:data?.title,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`;

        default: return ""         
      }}  
  window.open(url());
    };

  render() {
    const PrevButton = ({ onClick }) => (
      <button className="slick-prev" onClick={onClick}>Previous</button>
    );
    
    const NextButton = ({ onClick }) => (
      <button className="slick-next" onClick={onClick}>Next</button>
    );

    var settings = {
      dots: true,
      infinite: true,
      autoplay:true,
      // fade:true,
      rtl:true,
      swipe:true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      vertical: false,
      prevArrow: <PrevButton />,
      nextArrow: <NextButton />,
      responsive: [
        {
          breakpoint: 1224,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 790,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    // console.log('this.props.data',this.props.data);
    return (
      <div className=" m-md-5 mx-4 my-5"  >       
        <Slider {...settings}>
        {this.props.data.map((da, i) => (
        <div
      
          key={i}
          // ref={(el) => (cardElementsRef.current[i] = el)}
        >
          <div className="card bg-dark text-white card1 mx-3" >
            <div className="image-container image-container-bus"
            //  onClick={this.scrollToTop} to={`/${window.lang}/blog/da/${da._id}`} state={{ data:da , das:this.props.data}}
            onClick={e=>this.redirectHandle(da)} 
            >
              <img
                src={da?.img||da?.moreInfo?.img||'/img/Metademy/Green-Red-Stickers-5.png'}
                className="card-img card-img1 w-100 "
                alt={da.title}
                loading="lazy"
              />
              <div className="card-img-overlay ">
                <div>
                  <h5 className="card-title card-titel1 card-titel3 mt-3">
                    
                   { da.status==="03-verified"&& <MyIcons icon="Verified" />}
                   <span className="mt-auto ">
                     {da?.title||da?.name?.[window.lang]}
                    </span>
                  </h5>
               
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
        </Slider>
      </div>
    );
  }
}