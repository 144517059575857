import React, {useEffect} from "react";
import countryStates from "../../../../utils/countryStates";
import SearchMultiSelectBusRegister from "../../../golbal/dropdown/searchMultiSelectBusRegister";
import MyIcons from "../../../golbal/icons/myIcons";

export default function TargetAddress01({ formik, section }) {
  console.log('section',section);
  
  const targetAddressKey = (section) ? `${section}.targetAddress` : "targetAddress";


  const stateSelectHandle = (index, value) => {
    const parts = value.split(",");
    const stateShort = parts[0];
    const state = parts[1];

    const updatedAddresses = [...formik.values?.[targetAddressKey]];
    updatedAddresses[index].states.push({ state, stateShort });
    formik.setValues((prev) => ({
      ...prev,
      [targetAddressKey]: updatedAddresses,
    }));
  };

  const addAddressHandle = () => {
    const newTA = [...getNestedValue(formik.values, targetAddressKey)];
    const index = newTA.length;
    newTA[index] = {
      country: "",
      countryShort: "",
      states: [],
      statesOptions: [],
    };
    formik.setValues((prev) => {
      const updatedValues = { ...prev };
      setNestedValue(updatedValues, targetAddressKey, newTA); // Using setNestedValue to update deep object
      return updatedValues;
    });
    
    

    // formik.setValues((prev) => ({
    //   ...prev,
    //   [...getNestedValue(prev, targetAddressKey)]: newTA,
    // }));
  };

  const removeAddressHandle = (e,index) => {   
    const targetAddress = [...getNestedValue(formik.values, targetAddressKey)];
    console.log("t21",targetAddress)
     const t2 = targetAddress.filter((da,i)=> i!==index )
   console.log("t2",t2)
    
    formik.setValues((prev) => {
      const newTA = { ...prev };
      setNestedValue(newTA,targetAddressKey,t2)
      return newTA;
    });
  };
  function getNestedValue(obj, key) {
    return key.split('.').reduce((acc, part) => acc && acc[part], obj);
  }
  
  function setNestedValue(obj, key, value) {
    const keys = key.split('.');
    return keys.reduce((acc, part, index) => {
      if (index === keys.length - 1) {
        acc[part] = value; // Set the value at the deepest key
      } else {
        acc[part] = acc[part] || {}; // Ensure the intermediate objects exist
      }
      return acc[part];
    }, obj);
  }
  
  function updatedTarget(targetAddress) {
    // Update target address by adding states options
    return (
      targetAddress[0]?.countryShort?.length > 0 ?
      targetAddress?.map((value, index) => {
        const countryInfo = countryStates.find(
          (count) =>
            count.countryShort.toLowerCase() === value.countryShort.toLowerCase()
        );
        const updatedAddresses = [...targetAddress];
        updatedAddresses[index] = {
          ...updatedAddresses[index],
          country: value.country,
          countryShort: value.countryShort.toLowerCase(),
          states: value.states,
          statesOptions: countryInfo ? countryInfo.states : [], // Safely handle undefined countryInfo
        };
        return updatedAddresses[index];
      }): [
        {
          country: 'canada',
          countryShort: 'qc',
          states: ['quebec'],
        },
      ]
    );
  }
  
  useEffect(() => {
    const targetAddress = getNestedValue(formik.values, targetAddressKey);
    console.log(targetAddressKey,targetAddress)
    const newTarget = updatedTarget(targetAddress);
  // console.log(targetAddressKey,newTarget)
    if (newTarget) {
      formik.setValues((prev) => {
        const updatedValues = { ...prev };
        setNestedValue(updatedValues, targetAddressKey, newTarget);
        return updatedValues;
      });
    }
  }, [formik.values._id]); // Ensure formik is included in the dependency array
  
  const countrySelectHandle = (index, value) => {
    const countryInfo = countryStates.find(
      (count) => count.countryShort === value
    );
  
    const updatedAddresses = getNestedValue(formik.values, targetAddressKey)||[] // Handle potential undefined formik.values
    updatedAddresses[index] = {
      ...updatedAddresses[index],
      country: countryInfo ? countryInfo.country : '',
      countryShort: value,
      states: [],
      statesOptions: countryInfo ? countryInfo.states : [], // Safely handle undefined countryInfo
    };
  
    formik.setValues((prev) => {
      const updatedValues = { ...prev };
      setNestedValue(updatedValues, targetAddressKey, updatedAddresses);
      return updatedValues;
    });
  };
  

  return (
    <div>
      <div id="parentAddressReapeater">
        {getNestedValue(formik.values, targetAddressKey)?.map((address, index) => (
          <div className="d-flex targetAddressReapeater" key={index}>
            <div className="mb-2 mx-3">
              {/* <label className="form-label m-0">کشور</label> */}
              <select
                name={`targetAddressReapeater${address}${index}`}
                className="login-btn mt-0 selectpicker shadow-none w-100"
                value={address.country}
                onChange={(e) => countrySelectHandle(index, e.target.value)}
              >
                <option value={address.country}>
                  {address?.country?.toUpperCase() || "Select from list"}
                </option>
                {countryStates.map((coun, i) => (
                  <option key={i} value={coun.countryShort}>
                    {coun.country?.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>

            <button
              type="button"
              className="deleteContact btn btn login-trigger3 btn-xs"
              onClick={(e) => removeAddressHandle(e,index)}
            >
              <div>
                <MyIcons icon="delete2" />
              </div>
            </button>
          </div>
        ))}
      </div>
      <img src="/img/premium/add-button-2.png" className="arrow3 ms-5 " alt="add" onClick={addAddressHandle} />
    </div>
  );
}


