import { Outlet } from "react-router-dom"
import UserDashMpNav from "../../../components/dashNavbar/userDashMpNav"

export default function UserMpOut() {
    
  return (
    <div className="savedItems">        
        <UserDashMpNav/> 
        <Outlet/>
    </div>
  )
}
