import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import "slick-carousel/slick/slick-theme.css";
import renderData from "../../../utils/renderData";
import BusinessCard from "../../screens/business/components/businessCard";

export default class BusCardSliderS1 extends Component {
  constructor(props) {
    super(props);   
    this.state={data:[]}
  }
  capitalize = (s) =>
    s?.charAt(0).toUpperCase() + s?.slice(1).toLowerCase();   
    


    componentDidMount() {
      // Run once on mount
      this.updateData();
    }
  
    componentDidUpdate(prevProps) {
      // Run whenever props.plansS1 changes
      if (prevProps?.plansS1 !== this.props?.plansS1) {
        this.updateData();
      }
    }

    updateData() {
      const plansLg = this.plansS1ReadyToSh({ size: "plansLg" }) || [];
      const combinedArray = this.combineArrays(plansLg);
      this.setState({ data: combinedArray });
    }
  
    plansS1ReadyToSh({size}){
      // console.log("plansS1ReadyToSh",this.props.page);
      // console.log('plan',this.props.page,this.props?.plansS1);
      const plan = this.props?.plansS1?.[size]
      ?.filter(plan=> 
        plan.pages?.includes(this.props.page)
        &&
        plan.isPaid    
        )
        // console.log("plansS1ReadyToSh plan",plan);
        return plan
          }       

    combineArrays(array1) {
      let combinedArray = [...array1]
      if (array1.length<6){        
        combinedArray = [...array1,...renderData.plansDef.plansS1.slice(array1.length-6)];        
        return combinedArray}

      return combinedArray;
    }



  render() {
    const PrevButton = ({ onClick }) => (
      <button className="slick-prev" onClick={onClick}>Previous</button>
    );
    
    const NextButton = ({ onClick }) => (
      <button className="slick-next" onClick={onClick}>Next</button>
    );
    const settings = {
      dots: true,
      infinite: true,
      autoplay:true,
      // fade:true,
      rtl:true,
      swipe:true,
      speed: 600,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      vertical: false,
      prevArrow: <PrevButton />,
      nextArrow: <NextButton />,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };    

    
    // console.log('this.state.data',this.state.data)

    return (
      <div className="m-md-5 mx-4 my-5 h-100 "  >       
        <Slider {...settings}>
        {this.state?.data?.map((bus, i) => (         
            <BusinessCard
          key={i}
          data={bus}  
        />
        
      ))}
        </Slider>
      </div>
    );
  }
}

