import MetaDecorator from "../../../../utils/metaDecorator";

export default function AboutUs() {
  const structuredData = [    
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "درباره متامارس",
      description:
        "یک پلتفرم آنلاین پویا که برای جامعه پارسی‌زبان در سراسر جهان طراحی شده ...",

      url: "https://www.metamarce.com/fa/prompt/guides",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/prompt/about`,
              name: `درباره متامارس`,
              description:
                "متامارس چیست و چگونه عمل می کند",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: "درباره متامارس | About Metamarce",
    description: "درمورد متامارس | اهداف متامارس | چشم انداز متامارس",
    section: "درباره",
    url: `https://www.metamarce.com/${window.lang}/prompt/about`,
    // canonical: `https://www.metamarce.com/${window.lang}/prompt/about`,
    img: "https://www.metamarce.com/img/illustration/pair-programming-animate.svg",
    type: "webpage",
  };
  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <div className="my-header m-0">
        <div className="bg-image fixed-bg-media bg-contact-us"></div>

        <div className=" p-3 pb-0 px-5">
          <h1 className="mx-auto header-firstpage titr1 font-40 text-start">
            درباره متامارس
          </h1>
          <h3
            className="mx-auto header-firstpage titr3 text-start"
            style={{ color: "#60412b" }}
          >
            {/* راهنمای سوشیال مدیای متامارس */}
            {/* منتظر شنیدن دیدگاه‌های شما هستیم! */}
            پلی بین پارسی‌زبانان سراسر دنیا
          </h3>
        </div>

        <img
          src="/img/homePage/wave-haikei-3.svg"
          className=" p-0 pt-2 w-100 overlay-b"
          alt="homePage01"
        />
      </div>

      <div className="px-md-5 px-3">
        <div className="row">
          <div className="col-md-8 px-4 d-flex flex-column align-items-center justify-content-center">
            <p className="titr7">به متامارس خوش آمدید!</p>
            <p className="titr7 titr7-5" style={{ color: "#c3b091" }}>
              {" "}
              یک پلتفرم آنلاین پویا که برای جامعه پارسی‌زبان در سراسر جهان طراحی
              شده است.{" "}
            </p>
            <p className="pt-5 text-justify-metamarce">
              در متامارس قراره همه با هم و در کنار هم مانند افراد یک خانواده
              باعث رشد و پیشرفت هم بشیم. هنر و مهارت‌‌هامون رو به همدیگه یاد
              بدیم، با کسب و کارهای هموطنانمون آشنا بشیم و با حمایت از اونها در
              رشد جامعه‌ی پارسی‌زبانان سراسر دنیا بکوشیم.
              <br />
              دسترسی آسون به کسب و کارهای گوناگون در سراسر دنیا، ساخت پل ارتباطی
              بین اساتید و دانشجویان و همینطور جویندگان کار و کارفرماها، فراهم
              آوردن بستری برای معرفی گروه‌های تخصصی، شبکه‌های اجتماعی و ... در
              شهرها و محله‌های گوناگون تنها گوشه‌ای از خدمات ما در متامارس
              هستند.
              <br />
              ما یک شرکت ثبت شده در شهر مونترال کانادا هستیم و تمامی قوانین و
              مقررات کانادا را رعایت می‌کنیم. در متامارس ما به حفظ حقوق کاربران
              و کسب و کارها متعهد هستیم و با ارائه خدمات مطابق با استانداردهای
              بین‌المللی، تلاش می‌کنیم تا تجربه‌ای امن و مطمئن را برای
              کاربرانمون فراهم کنیم. هدف ما ساخت یک شبکه جهانیه که از مرزها
              فراتر بره برای رسیدن به این هدف ما همچنان در حال توسعه وبسایت و
              طراحی بخش‌‌های جدید هستیم. تا نیازهای بیشتری رو بتونیم رفع کنیم.
              در این راه پشتیبانی و پیشنهادهای شما می‌تونه بسیار انگیزه بخش و
              کمک کننده باشه.
              <br />
            </p>
          </div>
          <div className="col-md-4 text-center ">
            <div style={{ position: "relative" }}>
              {/* <div className="titr9 text-center dir="ltr"">Welcome to Metamarce!</div> */}
              <img
                src="/img/illustration/pair-programming-animate.svg"
                className=" mb-3 px-0 w-100"
                alt="homePage"
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div>
       <div>
        --English translation in following--
        </div>
        <h1>به متامارس خوش آمدید </h1>
    <section>
        <h2>چشم‌انداز ما</h2>
        <p>متامارس فقط یک وبسایت نیست؛ این یک جنبش به سوی ایجاد یک دهکده جهانی است. ما به قدرت جامعه اعتقاد داریم و تلاش می‌کنیم که از مرزهای زبانی فراتر رویم تا اتصالاتی را برقرار کنیم که مهم هستند. سفر ما با تمرکز بر محتوای فارسی شروع می‌شود و به اتحاد ذهن‌ها در زبان‌ها و فرهنگ‌های مختلف می‌پیوندد.</p>
    </section>

    <section>
        <h2>محتوا به آزادی</h2>
        <p>در وبلاگ ما غوطه ور شوید، جایی که دانش با خلاقیت هم‌راه می‌شود. برای دریافت دانش‌های خاص صنعت یا راهنمای مسایل عمومی ، متامارس منبع شماست. ما بر سیاست آزادی در اشتراک‌گذاری عمل می‌کنیم و هرکسی می‌تواند مشارکت کند،  بستری از ایده‌ها و تجربیات گوناگون  .</p>
    </section>

    <section>
        <h2>ساخت پل‌ها</h2>
        <p>در متامارس، ما فقط خالقان محتوا نیستیم. ما داشتنیم، کسب‌وکارها و افراد را با یکدیگر به ارتباط می‌اندازیم تا اتصالات قدرتمندی را برای نیازهای مشترک بسازیم. هدف ما این است که تا حد ممکن فاصله ها رو کاهش دهیم.</p>
    </section>
    <section>
        <h2>پیوستن به جنبش</h2>
        <p>متامارس فقط یک پلتفرم نیست؛ این یک دعوت به پیوستن به یک جامعه پویااست. آیا یک کسب و کار موفق هستید، یا یک بازاریاب با تجربه، یا یک اینفلوئنسر ، یا یک خالق محتوا در حال رشد، در دهکده جهانی ما جایی برای شما وجود دارد. با هم، بیایید آینده بازاریابی دیجیتال را شکل دهیم و از مرزها گذر کرده و جهانی ایجاد کنیم که هر کسی صدا داشته باشد.</p>
    </section>

    <section>
        <h2>پیشنهاد ما</h2>
        <p>متامارس بر پایه شفافیت، همکاری و یک دیدگاه مشترک برای یک دنیای بهتر و متصل ساخته شده است. با ما در این سفر هیجان‌انگیز به عنوان یک نیروی نوآورانه در منظر دیجیتال، یک ایده نوین در هر زمان را تعریف کنید.</p>
    <h4> <p>خوش آمدید به متامارس – جایی که ایده‌ها مرز نمی‌شناسند!</p></h4>
    </section>

<div>Persian translation in the above</div>
    <header>
        <h1>Welcome to Metamarce</h1>
    </header>

    <section>
        <h2>Our Vision</h2>
        <p>Metamarce is not just a company; it's a movement towards creating a global village. We believe in the strength of community, transcending language barriers to foster connections that matter. Our journey begins with a focus on Persian content, branching out to unite minds across various languages and cultures.</p>
    </section>

    <section>
        <h2>Content Unleashed</h2>
        <p>Dive into our blog, where knowledge meets creativity. Whether you're seeking industry-specific insights or a general guide to spark your interest, Metamarce is your go-to source. We operate on an open sharing policy, inviting anyone and everyone to contribute, creating a diverse tapestry of ideas and experiences.</p>
    </section>

    <section>
        <h2>Building Bridges</h2>
        <p>At Metamarce, we're more than just content creators. We're matchmakers, bringing businesses and individuals together to forge powerful connections. Our goal is to serve as the catalyst for collaborative marketing solutions. From crafting compelling advertising videos to managing social media platforms, we're here to elevate your brand presence.</p>
    </section>

    <section>
        <h2>Join the Movement</h2>
        <p>Metamarce isn't just a platform; it's an invitation to join a thriving community. Whether you're a seasoned marketer or a budding content creator, there's a place for you in our global village. Together, let's shape the future of digital marketing, transcending boundaries and creating a world where everyone has a voice.</p>
    </section>

    <section>
        <h2>Our Promise</h2>
        <p>Metamarce is built on transparency, collaboration, and a shared vision for a better-connected world. Join us on this exciting journey as we redefine the landscape of digital marketing, one innovative idea at a time.</p>
    </section>

    <footer>
        <p>Welcome to Metamarce – Where Ideas Know No Borders!</p>
    </footer>
    </div> */}
    </div>
  );
}
