import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import apiStore from "../../../../../../../utils/apiStore";
import MyIcons from "../../../../../../golbal/icons/myIcons";
import ToastNote from "../../../../../../golbal/notifications/toastNote";
import renderData from "../../../../../../../utils/renderData";
import SearchBarUser from "./searchBarUser";
import ColoredSpinner from "../../../../../../alret/spiners/coloredSpiner/coloredSpinner";
import tools from "../../../../../../../utils/tools";
import { CartContext } from "../../../../../../contexts/cartContext";
import MetaDecorator from "../../../../../../../utils/metaDecorator";

export default function MyOrderList({ access, section }) {
  const { user } = useContext(CartContext);
  const [countryShort, stateShort] = window.location.pathname
    .toLowerCase()
    .split("/")
    .slice(2, 4);

  const navigate = useNavigate();

  const messageRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [user2, setUser2] = useState({ _id: user._id });

  const deleteHandle = async (id) => {
    console.log(id);
    if (!id)
      return messageRef.current.showError({ message: "آیدی فراهم نشده است." });
    const response = await apiStore.deleteRow({
      token: user.token,
      id,
      userId: user._id,
    });
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({ message: "سفارش با موفقیت حذف شد." });
    setData((pre) => {
      return pre.filter((row) => row._id !== id);
    });
  };
  function showPageHandle({ props }) {
    let text = "";
    if (window.lang !== props.lang.toLowerCase())
      text += `شما در حال انتقال به متامارس با زبان ${window.capitalize(
        props.lang
      )} میباشد . \n`;
    if (countryShort.toLowerCase() !== props.address.countryShort.toLowerCase())
      text += `شما در حال انتقال به کشور ${window.capitalize(
        props.address.country
      )} میباشد . \n`;
    if (stateShort.toLowerCase() !== props.address.stateShort.toLowerCase())
      text += `شما در حال انتقال به استان ${window.capitalize(
        props.address.state
      )} میباشد . \n`;

    if (!text.length)
      return navigate(`/${window.lang}/store/order/${props._id}`);

    if (window.confirm(text) != true) return;
    console.log("Passed");

    navigate(`/${window.lang}/store/order/${props._id}`);
  }

  useEffect(() => {
    fetchData();
  }, [user2._id, window.location.pathname]);
  async function fetchData() {
    setLoading(true);
    const response = await apiStore.getOrdersList({
      token: user.token,
      id: user2._id,
      access,
      section,
    });
    setLoading(false);
    console.log(response);
    if (response.error) return alert(response.error);
    setData(response.data);
  }

  function showPrice(amount, currency) {
    const cur = currency || "USD";
    const final = (amount || 0).toLocaleString(window.lang, {
      style: "currency",
      currency: cur,
      minimumFractionDigits: cur === "IRR" ? 0 : 2,
      maximumFractionDigits: cur === "IRR" ? 0 : 2,
    });
    return final;
  }

  const columns = [
    //   { field: '_id', headerName: 'ID', width: 70 },
    {
      field: "action",
      headerName: "عملیات",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="d-flex">
            <div
              to={""}
              title="نمایش"
              className="widgetSmButton"
              onClick={(e) => showPageHandle({ props: params.row })}
            >
              <MyIcons icon="display" />
            </div>
            {/* <Link to={""} title="ویرایش" className="widgetSmButton"><Edit className='widgetSmIcon ' onClick={e => editOrderHandle({props:params.row})}/></Link> */}
            {!params.row.isPaid && (
              <Link
                to={""}
                title="پاک کردن"
                onClick={() => deleteHandle(params.row._id)}
              >
                <MyIcons icon="delete2" />
              </Link>
            )}
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "تاریخ ثبت",
      width: 100,
      renderCell: (params) => (
        <div title={tools.toSolar(params.row?.updatedAt)}>
          {tools.toGreg(params.row?.updatedAt)}
        </div>
      ),
    },
    {
      field: "isPaid",
      headerName: "تکمیل سفارش",
      width: 100,
      renderCell: (params) => <div>{params.row.isPaid ? "بله" : "خیر"}</div>,
    },
    {
      field: "address.country",
      headerName: "کشور",
      width: 150,
      renderCell: (params) => (
        <div dir="ltr">{`${window.capitalize(
          params.row?.address?.state
        )}, ${window.capitalize(params.row?.address?.country)}`}</div>
      ),
    },
    {
      field: "serviceItems",
      headerName: "نام امکانات",
      width: 200,
      renderCell: (params) => {
        return params.row.serviceItems.map((serv, i) => (
          <span key={i}>
            <span title={serv.title}>
              {
                renderData.plansName.find((plan) => plan.name === serv?.type)?.[
                  window.lang
                ]
              }
            </span>
            -
          </span>
        ));
      },
    },
    {
      field: "totalPrice",
      headerName: "مبلغ صورتحساب",
      width: 150,
      renderCell: (params) => (
        <div
          title={`مبلغ پرداختی  ${showPrice(
            Number(params.row?.paymentResult[0]?.amount?.value),
            params.row?.paymentResult[0]?.amount?.currency_code
          )}`}
        >
          {showPrice(params.row?.totalPrice, params.row?.currency)}
        </div>
      ),
    },
    {
      field: "invNumber",
      headerName: "شماره صورتحساب",
      width: 150,
      // renderCell:params=>  <div title={params.row?.paymentResult[0]?.amount?.value}>{params.row?.paymentResult[0]?.amount?.currency_code+params.row?.paymentResult[0]?.amount?.value}</div>
    },
  ];
  const metaTagsData = {
    title: "متامارس | داشبورد | لیست سفارشهای ناتمام ",
    description: "مشاهده صورتحساب ها و سفارشهای ناتمام ",
    section: "oreders list",
    url: `${window.location.origin}/${window.lang}/dashboard/user/trans/points`,
    // canonical: `${window.location.origin}/${window.lang}/dashboard/user/trans/points`,
    img: "https://www.metamarce.com/img/illustration/buy-photography-gear-online.svg",
    type: "webpage",
    index: false,
  };
  // console.log('data',data);
  return (
    <div className="userList" style={{ height: "80vh", width: "100%" }}>
      <MetaDecorator data={{ ...metaTagsData }} />
      <ToastNote messageRef={messageRef} />

      {loading && <ColoredSpinner />}
      {!data.length ? (
        <div>
          داده ای پیدا نشد. پس از اطمینان از وجود داده، دوباره تلاش کنید.
        </div>
      ) : (
        <DataGrid
          getRowId={(row) => row._id}
          rows={data}
          components={{
            Toolbar: GridToolbar,
          }}
          disableSelectionOnClick
          columns={columns}
          pageSize={[15]}
          rowsPerPageOptions={[1]}
          checkboxSelection
        />
      )}
      {access === "admin" && (
        <SearchBarUser setUser2={setUser2} user2={user2} />
      )}
    </div>
  );
}
