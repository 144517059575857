import { Link } from "react-router-dom";
import MetaDecorator from "../../../../utils/metaDecorator";

export default function Privacy() {
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "حفظ حریم خصوصی",
      description:
      "چگونه متامارس از اطلاعات کاربران در هنگام ورود محافظت و استفاده می نماید.",

      url: `https://www.metamarce.com/${window.lang}/prompt/privacy`,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/prompt/privacy`,
              name: "متامارس | حفظ حریم شخصی",
              description:
                "سیاست حفظ حریم خصوصی توضیح می‌دهد که چگونه متامارس از اطلاعات کاربران در هنگام ورود محافظت و استفاده می نماید.",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: "متامارس | حفظ حریم شخصی",
    description:
      "سیاست حفظ حریم خصوصی توضیح می‌دهد که چگونه متامارس از اطلاعات کاربران در هنگام ورود محافظت و استفاده می نماید.",
    section: "Privacy",
    url: `https://www.metamarce.com/${window.lang}/prompt/privacy`,
    canonical: `https://www.metamarce.com/${window.lang}/prompt/privacy`,

    img: "https://www.metamarce.com/img/illustration/pair-programming-animate.svg",
    type: "webpage",
  };

  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <div className="d-flex flex-column justify-content-center align-items-center mx-md-5 mx-2">
        <div>
          <h1 className="titr1 font-40 my-5">
            سیاست حفظ حریم خصوصی کاربران متامارس
          </h1>
          <p>
            سیاست حفظ حریم خصوصی توضیح می‌دهد که چگونه متامارس اطلاعاتی را که
            کاربران در هنگام ورود ارائه می‌دهند، جمع‌آوری، استفاده و حفاظت
            می‌کند.
          </p>

          <h2 className="titr7 text-start mt-3 mt-md-4">
            ۱. اطلاعات جمع‌آوری‌شده
          </h2>
          <ul className="flex-column">
            <li>شناسه کاربری </li>
            <li>آدرس ایمیل در زمان ورود </li>
            <li>و اطلاعات پروفایل شما (مانند نام و تصویر پروفایل)</li>
            <li>
              کد احراز هویت شما که برای احراز هویت و دسترسی به پروفایل استفاده
              می‌شود
            </li>
          </ul>

          <h2 className="titr7 text-start mt-3 mt-md-4">
            ۲. استفاده از اطلاعات
          </h2>
          <ul className="flex-column">
            <li>ایجاد و مدیریت حساب کاربری شما</li>
            <li>بهبود و شخصی‌سازی تجربه کاربری شما</li>
            <li>
              ارتباط با شما از جمله فرستادن به‌روزرسانی‌ها و اطلاعیه‌های مهم
            </li>
            <li>حفظ امنیت و اصالت برنامه وب ما</li>
            <li>رعایت الزامات قانونی و نظارتی</li>
          </ul>

          <h2 className="titr7 text-start mt-3 mt-md-4">
            ۳. دسترسی به اطلاعات توسط شخص ثالث
          </h2>
          <p>
            ما اطلاعات ورود شما را به اشخاص ثالث به اشتراک نمی‌گذاریم، مگر در
            موارد زیر:
          </p>
          <ul className="flex-column">
            <li>برای رعایت تعهدات قانونی </li>
            <li>
              یا ارائه‌دهندگان خدمات قابل اعتماد (شخص ثالث) که در عملکرد برنامه
              به ما کمک می‌کنند. مانند google.
            </li>
            <li>
              در صورت ادغام، یا فروش بخشی از دارایی‌های ما، اطلاعات شما ممکن است
              به شرکت دریافت‌کننده منتقل شود
            </li>
          </ul>

          <h2 className="titr7 text-start mt-3 mt-md-4">۴. امنیت داده</h2>
          <p>
            ما از تدابیر امنیتی استاندارد برای حفاظت از اطلاعات شما استفاده
            می‌کنیم. با این حال، لطفاً به یاد داشته باشید که هیچ انتقال داده ای
            از طریق اینترنت یا ذخیره‌سازی الکترونیک به‌طور کامل ایمن نیست.
          </p>

          <h2 className="titr7 text-start mt-3 mt-md-4">۵. گزینه‌های شما</h2>
          <p>
            شما حق دارید هر زمان اطلاعات حساب کاربری خود را بررسی، به‌روزرسانی
            یا حذف کنید. این کار را با ورود به حساب کاربری خود یا تماس مستقیم با
            ما می‌توانید انجام دهید.
          </p>
          <h2 className="titr7 text-start mt-3 mt-md-4">
            ۶. تغییرات در سیاست حفظ حریم خصوصی
          </h2>
          <p>
            در هر زمان ممکن است ما سیاست حفظ حریم خصوصی را به‌روز کنیم تا بتواند
            منعکس کنده تغییرات در متامارس یا الزامات قانونی باشد. مطمئن باشید که
            هرگونه تغییرات بر روی این صفحه قرار داده خواهد شد و ما توصیه می‌کنیم
            که هر چند وقت یک بار این سیاست‌ها را مرور کنید.
          </p>
          <h2 className="titr7 text-start mt-3 mt-md-4">۷. تماس با ما</h2>
          <p>
            اگر سوال یا نگرانی‌ای در مورد روش‌های حفظ حریم خصوصی ما یا گزینه‌های
            سیاست حفظ حریم خصوصی دارید، لطفاً با ما به آدرس metamarce@gmail.com
            تماس بگیرید. با ورود و ثبت‌نام در وبسایت ما، شما با شرایط سیاست حفظ
            حریم خصوصی موافقت می‌کنید.
          </p>
        </div>

        {/* <div className="btn primary-btn btn-404 checkout-btn cart-footer mb-5 mt-2"> */}
        <Link
          className="btn primary-btn btn-404 checkout-btn my-5"
          onClick={(e) => window.scroll(0, 0)}
          to={`/${window.lang}/prompt/disclaimer`}
        >
          شرایط استفاده از متامارس (بیانیه رفع مسئولیت)
        </Link>
        {/* </div> */}      
      </div>
    </div>
  );
}
