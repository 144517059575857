import MetaDecorator from "../../../../utils/metaDecorator";

export default function AboutUsEn() {
  const structuredData = [
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "About Metamarce",
      description:
        "A dynamic online Academy designed for the teachers and students around the world.",

      url: "https://www.metamarce.com/fa/prompt/guides",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "landing",
              description:
                "A bridge between teachers and students around the world",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/prompt/about`,
              name: "About Metamarce",
              description: "Who is Metamarce",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: "About us",
    description: "About Metamarce | Visions",
    section: "About",
    url: `https://www.metamarce.com/${window.lang}/prompt/about`,
    // canonical: `https://www.metamarce.com/${window.lang}/prompt/about`,
    img: "https://www.metamarce.com/img/illustration/pair-programming-animate.svg",
    type: "webpage",
  };
  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <div className="my-header m-0">
        <div className="bg-image fixed-bg-media bg-contact-us"></div>

        <div className=" p-3 pb-0 px-5">
          <h1 className="mx-auto header-firstpage titr1 font-40 text-start">
            About Metamarce
          </h1>
          <h3
            className="mx-auto header-firstpage titr3 text-start"
            style={{ color: "#60412b" }}
          >
            A bridge between teachers
            and students around the world
          </h3>
        </div>

        <img
          src="/img/homePage/wave-haikei-3.svg"
          className=" p-0 pt-2 w-100 overlay-b"
          alt="homePage01"
        />
      </div>

      <div className="px-md-5 px-3">
        <div className="row">
          <div className="col-md-8 px-4 d-flex flex-column align-items-center justify-content-center">
            <p className="titr7">Wellcome to Metamarce!</p>
            <p className="titr7 titr7-5" style={{ color: "#c3b091" }}>
              A dynamic online Academy designed for the teachers and students
              around the world.
            </p>
            <p className="pt-5 text-justify-metamarce">
              Easy
              access to various teachers around the world, building a
              communication bridge between professors and students as well as
              job seekers and employers, providing a platform for introducing
              specialized groups, social networks, etc. in various cities and
              neighborhoods are only a part of our services in They are
              metamares.
              <br />
               We are a registered company in the city of Montreal,
              Canada, and we comply with all Canadian laws and regulations. At
              Metamars, we are committed to protecting the rights of users and
              businesses, and by providing services in accordance with
              international standards, we strive to provide a safe and secure
              experience for our users. Our goal is to build a global network
              that transcends borders. To achieve this goal, we are still
              developing the website and designing new sections. So that we can
              meet more needs. In this way, your support and suggestions can be
              very motivating and helpful
            
            
              <br />
            </p>
          </div>
          <div className="col-md-4 text-center ">
            <div style={{ position: "relative" }}>
              {/* <div className="titr9 text-center dir="ltr"">Welcome to Metamarce!</div> */}
              <img
                src="/img/illustration/pair-programming-animate.svg"
                className=" mb-3 px-0 w-100"
                alt="homePage"
              />
            </div>
          </div>
        </div>
      </div>    
    </div>
  );
}
