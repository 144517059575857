import { useState, useRef, useEffect, useContext } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import apiDemy from "../../../../utils/apiDemy";
import Pagination from "../../../golbal/pagination/pagination";
// import Cluster from "../../../googleMap/googleCluster";
// import Search from "../../../persian/common/pagination/searchPagination";
import renderData from "../../../../utils/renderData";
import SearchMultiSelect from "../../../golbal/dropdown/searchMultiSelectFilters";
import { CartContext } from "../../../contexts/cartContext";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import PlansX4 from "../../../golbal/sliders/plansX4";
// import BusSmallCardSlider from "../../../golbal/sliders/busCardSliderS1";
// import MainSidebar from "../../media/components/mainSidebar";
import tools from "../../../../utils/tools";
// import FiltersCatsSelector from "../../../golbal/filtersCatsSelector";
import MetaDecorator from "../../../../utils/metaDecorator";
import ProgramCard from "../components/programCard";
// import DropdownSearch from "../../../golbal/dropdown/DropdownSearch";
import countryStates from "../../../../utils/countryStates";
import demyData from "../../../../utils/demyData";
import ToastNote from "../../../golbal/notifications/toastNote";
// import api from "../../../../utils/api";
import HorizontalSlider from "../../../golbal/sliders/horizontalSlider";
import BlogSearch from "../../../persian/common/pagination/searchPagination";
// import CountryStateCitySelector from "../../../golbal/dropdown/countryStateCitySelector";
import FiltersCountryStateCity from "../../../golbal/dropdown/filtersCountryStateCity1";
// import NewPosts2x2 from "../../../golbal/sliders/components/newPosts2x2";

export default function ProgramFiltersEn() {
  const navigate = useNavigate();

  const section = "program";
  const page = "programFilters";
  const { lang, state:{state,stateShort}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
  const { user } = useContext(CartContext);
  const [pubData] = useOutletContext();
  const messageRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [video, setVideo] = useState("");

  // const [view, setView] = useState({
  //   thumbnail: true,
  //   cluster: false,
  // });

  const [resultsTotal, setResultsTotal] = useState(0);
  const [loading, setLoading] = useState({ fetchBus: false });
  // const [states, setStates] = useState([]);
  // const [showCities, setShowCities] = useState(false);
  const [fSelector, setFSelector] = useState({});
  // console.log('fSelector',fSelector);
  
  const [data, setData] = useState([]);
  // const [currencies, setCurrencies] = useState([]);



  const initialFilters = {
    lang,
    country: "",
    countryShort: "",
    states: [],
    state: "",
    stateShort: "",
    cities: [],
    languages:[],
     mainCatId: "",
     studyArea: [],
     distanceEdu: "",   
    center: [],
    searchKey: "", 
    pageNumber: 1,
    itemsToShow: 20,
    sort: "default",
  };
  const storedFilters = JSON.parse(localStorage.getItem("storedFilters"))?.[
    section
  ];
  const [filters, setFilters] = useState(initialFilters||storedFilters);
  
  const [backFilters, setBackFilters] = useState({ ...filters });
  // console.log('backFilters',backFilters);

  const effectRan = useRef(true);
  useEffect(() => {
    // console.log('useEffectRun00',); 
    if (effectRan.current) return;
    filtersFunction()
  }, [filters]);
  function storeFilters({ section, filters }) {
    const storedFilters =
      JSON.parse(localStorage.getItem("storedFilters")) ?? {};
    storedFilters[section] = filters;
    localStorage.setItem("storedFilters", JSON.stringify(storedFilters));
    return;
  }
  useEffect(()=>{ fetchFData()},[])

  useEffect(() => {
    // const keys = Array.from(searchParams.keys())
    // console.log('useEffectRun02',); 
    const params = {};
    for (let [key, value] of searchParams) {
      params[key] = value;
    }
    if (Object.keys(params).length > 0) {
      setFilters((pre) => ({
        ...pre,
        lang,
        pageNumber: 1,
        // country: "",
        // state: "",
        // stateShort: "",
        studyArea: params?.studyArea ? [params?.studyArea] : pre.studyArea,
        // studyArea: params?.studyArea ?? pre.studyArea,
        // mainCat: params?.mainCat ?? pre.mainCat,
        // mainCatId: params?.mainCatId ?? pre.mainCatId,
        // subCat: params?.subCat ? [params?.subCat] : pre.subCat,
        // subCatId: params?.subCatId ? [params?.subCatId] : pre.subCatId,
        // cities: params?.cities ? [params?.cities] : pre.cities,
        searchKey: params?.searchKey ?? pre.searchKey,
        // tag: params?.tag ?? pre.tag,
      }));
    }
    // console.log('useEffectRun03', filters); 
    // filtersFunction()
    effectRan.current = false;
  }, [searchParams]);

  function filtersFunction(){
    fetchData();
    setBackFilters({ ...filters });
    storeFilters({ section, filters });

  }

  function queryRemover(field) {
    console.log(field);
    searchParams?.delete(field);
    // searchParams.set(field,"")
    const searchString = searchParams?.toString();
    setFilters((pre) => {
      const newFilters = { ...pre };
      delete newFilters[field];
      return newFilters;
    });
    navigate(`?${searchString}`);
  }

  async function fetchData() {
    setLoading((pre) => ({
      ...pre,
      fetchBus: true,
    }));
    const response = await apiDemy.filtersProgram({ filters });
    setLoading((pre) => ({
      ...pre,
      fetchBus: false,
    }));
    if (response?.error) return alert(response?.error);
    setResultsTotal(response?.data?.resultsTotal);
    setData(response?.data?.data);
  }
  async function fetchFData() {
    const response = await apiDemy.fetchProgramFData({ filters });
    if (response?.error) return alert(response?.error);
    setFSelector(response.data)    
  }

  async function searchHandle(e) {
    e.preventDefault();
    const value = e.target.parentElement.querySelector("input").value;

    filters.searchKey = value;
    if (e.key === "Enter" || e.type === "click") {
      setFilters((prev) => ({
        ...prev,
        searchKey: value,
        pageNumber: 1,
      }));
    }
  }
  const itemsToShowHandle = async (itemsToS) => {
    setFilters((prev) => ({
      ...prev,
      itemsToShow: itemsToS,
      pageNumber: 1,
    }));
  };
  const pageNumberHandle = (PageN) => {
    setFilters((prev) => ({
      ...prev,
      pageNumber: PageN,
    }));
  };

  function deleteFilter(e) {
    const field = e.currentTarget.id;
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key] = "";
              if (key === "mainCat") {
                newFilters.mainCatId = "";
                newFilters.subCat = [];
                newFilters.subCatId = [];
                queryRemover("mainCatId");
              }
              if (key === "country") {
                newFilters.countryShort = "";
              }
              newFilters.pageNumber = 1;
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }

    // effectRan.current = false;
    setFilters(update());
  }

  function deleteArrayFilter(e) {
    const field = e.currentTarget.id.split(",")[0];
    const index = e.currentTarget.id.split(",")[1];
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key].splice(index, 1);
              newFilters.pageNumber = 1;
              if (key === "subCat") {
                newFilters.subCatId.splice(index, 1);
                queryRemover("subCatId");
              }
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }
    setFilters(update());
  }

  const selectedFiltersRender = (() => {
    let rows = [];
    for (let key in filters) {
      if (Array.isArray(filters[key]))
        filters[key].map((option, i) => {
          // console.log(key, renderData[key], option);
          if (
            key !== "states" &&
            key !== "cities" &&
            key !== "subCat" &&
            key !== "center" &&
            key !== "programLevel" &&
            key !== "studyArea" &&
            key !== "subCatId"
          ) {
            const translate = (renderData[key] || demyData[key]).find(
              (option1) => option1.name == option
            );
            rows.push(
              <div
                key={[option, i]}
                className="btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                <span>{translate?.[window.lang]} </span>
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }

          if (key === "states" || key === "cities" || key === "subCat"|| key === "center"|| key === "programLevel"|| key === "studyArea") {
            rows.push(
              <div
                key={[option, i]}
                className="btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                <span>{option} </span>
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }
        });

      !Array.isArray(filters[key]) &&
        key !== "lang" &&
        key !== "countryShort" &&
        key !== "stateShort" &&
        key !== "state" &&
        key !== "section" &&
        key !== "sort" &&
        key !== "pageNumber:" &&
        key !== "itemsToShow" &&
        key !== "mainCatId" &&
        filters[key]?.length &&
        rows.push(
          <div className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center">
            <span>
              {key === "tag" && "# "}
              {filters[key]}
            </span>
            <span className="ms-3 me-2" id={key} onClick={deleteFilter}>
              <i className="bi bi-x-lg"></i>
            </span>
          </div>
        );
    }
    return rows;
  })();

  // function setCitiesHandle(value) {
  //   setFilters((prev) => ({ ...prev, cities: value, pageNumber: 1 }));
  //   // effectRan.current = false;
  // }
  // const { translations } =
  //   JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = `University Programs`;
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: title,
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "landing",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، درآمد دلاری از تدریس، آموزش آنلاین و مجازی بدون مرز، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/demy`,
              name: `Metademy`,
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/demy/filters`,
              name: `لیست اساتید `,
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title,
    description: `جستجوی بهترین اساتید تدریس خصوص`,
    section: "metademy filters",
    url: `https://www.metamarce.com/${window.lang}/demy/tutor/filters`,
    canonical: `https://www.metamarce.com/${window.lang}/demy/tutor/filters`,
    img: "https://www.metamarce.com/img/Metademy/Metademy-green-red.png",
    type: "website",
  };

  return (
    <div dir="ltr">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <ToastNote messageRef={messageRef} />
      {loading.fetchBus && <ColoredSpiner />}
      {/* <!-- *********************************** main ******************************************************************** --> */}

      <section className="mx-auto ads-all py-0">
        <nav className="p-md-3 p-2" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={`/${window.lang}`} className="breadcrumb-item">
              Home
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/${window.lang}/demy`} className="breadcrumb-item">
                Metademy
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/${window.lang}/demy/program`} className="breadcrumb-item">
                Programs
              </Link>
            </li>
            <li className="breadcrumb-item">
              <span className="breadcrumb-item active">
                <strong> Filters</strong>
              </span>
            </li>
          </ol>
        </nav>

        <div className="row blog px-md-4 p-0 py-1">
          {/* <!-- **************Right side ************** --> */}
          <div className="col-lg-12 entries  ">
            {/* <!-- ************** MAP ************** --> */}
            <a id="top"> </a>

            {/* <!-- **************Filters  ************** --> */}
            <div className=" d-flex flex-wrap x-3 justify-content-between my-2 border-0 ">
              <div className="d-flex">
                <span className="pt-2" title="نمایش ذخیره ها">
                  <i
                    className={`bi ${
                      filters.saved ? "bi-bookmark-fill" : "bi-bookmark"
                    } icon-stack-1x cursor`}
                    onClick={(e) => {
                      if (!window.logined(9999)) return;
                      if (user?.saved?.[section]?.length <= 0)
                        return alert("شما تاکنون آیتم ذخیره شده ای ندارین.");
                      setFilters((pre) => ({
                        ...initialFilters,
                        saved: !pre.saved,
                        section,
                      }));
                    }}
                  ></i>
                </span>
                {resultsTotal !== 0 && (
                  <span className="box4 py-1 px-2 mx-md-2 mx-0">
                    {resultsTotal} Results
                  </span>
                )}
                <div className="blog-search ">
                  <BlogSearch searchHandle={searchHandle} />
                </div>
              </div>
              <div className="d-flex">
                {user?.roles?.includes(1000)?<div
                  className="btn btn-primary addbusiness-btn my-2 me-2 pt-2"
                  onClick={(e) => {
                    // if (!window.logined(9999, section)) {
                    //   return window.navigateHandle(
                    //     `/${window.lang}/demy/program/register`
                    //   );
                    // }
                    window.navigateHandle(
                      `/${window.lang}/dashboard/demy/registerprogram`
                    );
                  }}
                >
                  <i className="bi bi-geo me-1"></i>
                  Program register
                </div>:""}
              
              </div>
          
               
                  
                

                    <div
                      className="offcanvas offcanvas-end offcanvas-countryLanguage "
                      data-bs-scroll="true"
                      tabIndex="-1"
                      id="offcanvasWithBothOptionsFilters"
                      aria-labelledby="offcanvasWithBothOptionsLabelFilters"
                    >
                      <div className="offcanvas-header offcanvas-countryLanguageTitle ">
                        <h5
                          className="offcanvas-title "
                          id="offcanvasWithBothOptionsLabelFilters"
                        >
                          More Filters
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        ></button>
                      </div>

                      <div className="offcanvas-footer  text-center px-5 py-4">
                        <button
                          className="btn  checkout-btn  text-center w-100 mb-2 py-2"
                          type="button"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                         onClick={(e) => {
                    // removing all url queries
                    navigate('')
                    setFilters(initialFilters)
                  }}
                        >
                          Delete Filters
                        </button>
                        <button
                          className="btn  view-cart text-center w-100 py-2"
                          type="button"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                          onClick={(e) => {
                            // effectRan.current = false;
                            backFilters.pageNumber = 1;
                            setFilters(backFilters);
                          }}
                        >
                          Show Result
                        </button>
                      </div>

                      <div className="offcanvas-body px-0 pb-3 pt-0">
                        <div
                          className="accordion w-100 p-0 m-0"
                          id="accordionPanelsStayOpenBusinessFilters"
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="panelsStayOpenBusinessFilters-headingZero"
                            >
                              <button
                                className="accordion-button collapsed accordion-button-business-filter"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpenBusinessFilters-collapseZero"
                                aria-expanded="false"
                                aria-controls="panelsStayOpenBusinessFilters-collapseZero"
                              >
                                <p className="form-titr2">Course Language </p>
                              </button>
                            </h2>
                            <div
                              id="panelsStayOpenBusinessFilters-collapseZero"
                              className="accordion-collapse collapse"
                              aria-labelledby="panelsStayOpenBusinessFilters-headingZero"
                            >
                             
                              <div className="accordion-body form-font px-5  row row-cols-2">
                               
                                {fSelector?.courseLang?.map((ad, i) => (
                                  <div
                                    key={i}
                                    className="form-check m-0 py-0 px-1"
                                  >
                                    <div
                                      className={`cursor  ${backFilters?.languages?.includes(ad._id)?"selected-session":""}`}                                             
                                      id={`languages,${ad._id}`}                                     
                                      onClick={(e) => {                                      
                                        const languages = backFilters?.languages
                                          ? [...backFilters.languages]
                                          : [];
                                        const index = languages.indexOf(ad._id);
                                        if (index !== -1) {
                                          languages.splice(index, 1);
                                        } else {
                                          languages.push(ad._id);
                                        }
                                        setBackFilters((prev) => ({
                                          ...prev,
                                          languages,
                                        }));
                                      }}
                                    >
                                      {tools.renderTrans({key: ad._id, section:"languages",lang:"en"})+ " ("+( ad?.count)+")"}
                                   
                                  </div>
                                  </div>
                                ))}
                             
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="panelsStayOpenBusinessFilters-headcountryStatesCities"
                            >
                              <button
                                className="accordion-button collapsed accordion-button-business-filter"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpenBusinessFilters-countryStatesCities"
                                aria-expanded="false"
                                aria-controls="panelsStayOpenBusinessFilters-countryStatesCities"
                              >
                                <p className="form-titr2">Location</p>
                              </button>
                            </h2>
                            <div
                              id="panelsStayOpenBusinessFilters-countryStatesCities"
                              className="accordion-collapse collapse"
                              aria-labelledby="panelsStayOpenBusinessFilters-headcountryStatesCities"
                            >
                             
                              <div className="accordion-body form-font px-5  row row-cols-2">
                               
                              <FiltersCountryStateCity
                              mode="multi"
                              setBackFilters={setBackFilters}
                              backFilters={backFilters}
                              filters={filters}
                              />



                             
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="panelsStayOpenBusinessFilters-headingZero"
                            >
                              <button
                                className="accordion-button collapsed accordion-button-business-filter"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#stayOpenWeedkDays"
                                aria-expanded="false"
                                aria-controls="stayOpenWeedkDays"
                              >
                                <p className="form-titr2">Centers</p>
                              </button>
                            </h2>
                            <div
                              id="stayOpenWeedkDays"
                              className="accordion-collapse collapse"
                              aria-labelledby="panelsStayOpenBusinessFilters-headingZero"
                            >
                              <div className="accordion-body form-font px-5  row row-cols-1">
                              {fSelector?.center?.map((ad, i) => (
                                  <div
                                    key={i}
                                    className="form-check m-0 py-0 px-1"
                                  >
                                    <div
                                      className={`cursor  ${backFilters?.center?.includes(ad._id)?"selected-session":""}`}                                             
                                      id={`center,${ad._id}`}                                     
                                      onClick={(e) => {                                      
                                        const center = backFilters?.center
                                          ? [...backFilters.center]
                                          : [];
                                        const index = center.indexOf(ad._id);
                                        if (index !== -1) {
                                          center.splice(index, 1);
                                        } else {
                                          center.push(ad._id);
                                        }
                                        setBackFilters((prev) => ({
                                          ...prev,
                                          center,
                                        }));
                                      }}
                                    >
                                      {ad._id+ " ("+( ad?.count)+")"}
                                   
                                  </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="panelsStayOpenBusinessFilters-Province"
                            >
                              <button
                                className="accordion-button collapsed accordion-button-business-filter"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#stayOpenProvince"
                                aria-expanded="false"
                                aria-controls="stayOpenProvince"
                              >
                                <p className="form-titr2">Province</p>
                              </button>
                            </h2>
                            <div
                              id="stayOpenProvince"
                              className="accordion-collapse collapse"
                              aria-labelledby="panelsStayOpenBusinessFilters-Province"
                            >
                              <div className="accordion-body form-font px-5  row row-cols-1">
                              {fSelector?.state?.sort((a, b) => a._id - b._id)?.map((ad, i) => (
                                  <div
                                    key={i}
                                    className="form-check m-0 py-0 px-1"
                                  >
                                    <div
                                      className={`cursor  ${backFilters?.states?.includes(ad._id)?"selected-session":""}`}                                             
                                      id={`states,${ad._id}`}                                     
                                      onClick={(e) => {                                      
                                        const states = backFilters?.states
                                          ? [...backFilters.states]
                                          : [];
                                        const index = states.indexOf(ad._id);
                                        if (index !== -1) {
                                          states.splice(index, 1);
                                        } else {
                                          states.push(ad._id);
                                        }
                                        setBackFilters((prev) => ({
                                          ...prev,
                                          states,
                                        }));
                                      }}
                                    >
                                      {ad._id+ " ("+( ad?.count)+")"}
                                   
                                  </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="panelsStayOpenBusinessFilters-headingTwo"
                            >
                              <button
                                className="accordion-button collapsed accordion-button-business-filter"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpenBusinessFilters-collapseTwo"
                                aria-expanded="false"
                                aria-controls="panelsStayOpenBusinessFilters-collapseTwo"
                              >
                                <p className="form-titr2">Program Level</p>
                              </button>
                            </h2>
                            <div
                              id="panelsStayOpenBusinessFilters-collapseTwo"
                              className="accordion-collapse collapse"
                              aria-labelledby="panelsStayOpenBusinessFilters-headingTwo"
                            >
                              <div className="accordion-body form-font px-5">
                              {fSelector?.programLevel?.map((ad, i) => (
                                  <div
                                    key={i}
                                    className="form-check m-0 py-0 px-1"
                                  >
                                    <div
                                      className={`cursor  ${backFilters?.programLevel?.includes(ad._id)?"selected-session":""}`}                                             
                                      id={`programLevel,${ad._id}`}                                     
                                      onClick={(e) => {                                      
                                        const programLevel = backFilters?.programLevel
                                          ? [...backFilters.programLevel]
                                          : [];
                                        const index = programLevel.indexOf(ad._id);
                                        if (index !== -1) {
                                          programLevel.splice(index, 1);
                                        } else {
                                          programLevel.push(ad._id);
                                        }
                                        setBackFilters((prev) => ({
                                          ...prev,
                                          programLevel,
                                        }));
                                      }}
                                    >
                                      {ad._id+ " ("+( ad?.count)+")"}
                                   
                                  </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                      
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="panelsStayOpenBusinessFilters-heading-six"
                            >
                              <button
                                className="accordion-button collapsed accordion-button-business-filter"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpenBusinessFilters-collapse-six"
                                aria-expanded="false"
                                aria-controls="panelsStayOpenBusinessFilters-collapse-six"
                              >
                                <p className="form-titr2"> Others</p>
                              </button>
                            </h2>
                            <div
                              id="panelsStayOpenBusinessFilters-collapse-six"
                              className="accordion-collapse collapse"
                              aria-labelledby="panelsStayOpenBusinessFilters-heading-six"
                            >
                              <div className="accordion-body form-font px-5">
                                <div className="form-check form-switch m-0 py-0 px-1">
                                  <input
                                    className="form-check-input form-check-input-filter1 m-0 p-0"
                                    type="checkbox"
                                    role="switch"
                                    name="coop"
                                    // value={'tr.name'}
                                    id={`coopBackF`}
                                    checked={backFilters?.coop}
                                    onChange={(e) => {
                                      setBackFilters((prev) => ({
                                        ...prev,
                                        coop: prev?.coop?.length
                                          ? ""
                                          : "Co-op Available",
                                      }));
                                    }}
                                  />
                                  <label
                                    className="form-check-label px-2"
                                    htmlFor={`coopBackF`}
                                  >
                                    Co-op Available ({fSelector?.coop?.find(co=>co._id==="Yes").count})
                                    {/* Co-op Available ({backFilters?.coop?fSelector?.coop.find(co=>co._id==="Yes").count:fSelector?.coop?.reduce((a,c)=>a+c.count,0)}) */}
                                 
                                  </label>
                                </div>
                                <div className="form-check form-switch m-0 py-2 px-1">
                                  <input
                                    className="form-check-input form-check-input-filter1 m-0 p-0"
                                    type="checkbox"
                                    role="switch"
                                    name="distanceEdu"
                                    // value={'tr.name'}
                                    id={`distanceEdu`}
                                    checked={backFilters?.distanceEdu}
                                    onChange={(e) => {
                                      setBackFilters((prev) => ({
                                        ...prev,
                                        distanceEdu: prev?.distanceEdu?.length
                                          ? ""
                                          : "Distance Education",
                                      }));
                                    }}
                                  />
                                  <label
                                    className="form-check-label px-2"
                                    htmlFor={`distanceEdu`}
                                  >
                                    Distance Education ({fSelector?.distanceEdu?.find(co=>co._id==="Yes").count})
                                    {/* Distance Education {backFilters?.distanceEdu?"":`(${fSelector?.distanceEdu?.reduce((a,c)=>a+c.count,0)})`} */}
                                  </label>
                                </div>
                             
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* ***************** */}
                    </div>
                  
             
              
            </div>

            <div className=" d-flex flex-wrap my-2 show-filters ">
              {selectedFiltersRender}
              {selectedFiltersRender.length > 0 && (
                <div
                  className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center img-banner-text-7 px-3 filter-del-btn"
                 onClick={(e) => {
                    // removing all url queries
                    navigate('')
                    setFilters(initialFilters)
                  }}
                >
                  <span>Remove Filters</span>
                </div>
              )}
            </div>

            {/* <!-- Pagination *******************************--> */}
            <div className="mx-1 p-0 ">
              <div className="g-2 mb-3 d-flex" dir="rtl">
                <Pagination
                  data={{ resultsTotal, itemsToShow: filters.itemsToShow }}
                  setPageNumber={pageNumberHandle}
                  pageNumber={filters.pageNumber}
                  setItemsToShow={itemsToShowHandle}
                  position="top"
                />
                  <button
                  className="btn btn-primary button-filter me-md-3 me-0 ms-auto my-2"
                  type="button"
                  // data-bs-toggle="collapse"
                  // data-bs-target="#navbarTogglerDemo02"
                  // aria-controls="navbarTogglerDemo02"
                  // aria-expanded="false"
                  // aria-label="Toggle navigation"
                  // data-bs-toggle="offcanvas"
                  // data-bs-target="#offcanvasWithBothOptionsFilters"
                  // aria-controls="offcanvasWithBothOptionsFilters"
                 
                    
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasWithBothOptionsFilters"
                      aria-controls="offcanvasWithBothOptionsFilters"
                >
                  <i className="bi bi-filter me-1"> Filters</i>
                </button>
              </div>

              <div>
                {loading.fetchBus ? (
                  <img src="/img/searching-error.svg" alt="Searching..." />
                ) : (
                  data?.length &&
                  !loading.fetchBus <= 0 && (
                    <div className="row m-5 px-md-5 px-0">
                      <div className="col-md-6 d-flex align-items-center justify-content-center px-0">
                        No Data where found
                        <div
                          className="btn-search shadow-none m-2 d-flex  align-items-center filter-del-btn img-banner-text-7"
                          onClick={(e) =>
                            document.querySelector("#lcsChangeBtn").click()
                          }
                        >
                          Other countries
                        </div>
                      </div>
                      <div className="col-md-6 d-flex align-items-center justify-content-center">
                        <img
                          src="/img/cart-is-empty.svg"
                          alt="cart-is-empty"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  )
                )}
                {/* <!-- ************** Cards************** --> */}

                <div
                  className="row row-cols-1 
              g-4"
                >
                  {data?.map((da, i) => (
                    <div key={i}>
                      <ProgramCard
                        data={da}
                        setFilters={setFilters}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-5 ">
                <Pagination
                  data={{ resultsTotal, itemsToShow: filters.itemsToShow }}
                  setPageNumber={pageNumberHandle}
                  pageNumber={filters.pageNumber}
                  position="down"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-1  sidebar-all align-items-center text-center  p-md-4 p-1">
            {/* <!-- ********left side *************************************************************** --> */}

            {/* <MainSidebar section={section} /> */}
          </div>
        </div>
        {/* <!-- *********** end of listing ******* --> */}
      </section>

      <section className="mx-md-5 pt-0 mx-0 p-0">
        {/* <!-- تخفیف‌های ویژه --> */}

        {/* <div className="divider2 mb-0">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>          
          </svg>
          <span className="p-0 mb-0 ">معرفی ویژه</span>
        </div> */}

        <PlansX4 plansX4={pubData.ads?.plansX4} page={page} />
      </section>

      {/* <!-- تازه‌ترین اساتید --> */}
      <div className="mx-md-5">
        <div className="divider2 ">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 "> New Tutors</span>
        </div>
        <HorizontalSlider data={pubData?.newTutors} section={section} />
      </div>
      {/* <!-- تازه‌ترین اساتید end of --> */}

      {/* <!-- تازه‌های بلاگ  --> */}

      {/* <div className=" mx-5 ">
        <div className="divider2 ">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
          </svg>
          <span className="p-0 mb-0 ">New Posts </span>
        </div>

        <div className="row row-cols-1 row-cols-md-2 g-4 my-5 ads-all mx-auto ">
          {pubData?.newPosts?.length > 0 && (
            <NewPosts2x2 newPosts={pubData.newPosts} user={user} />
          )}
        </div>
      </div> */}

      {/* <!-- *********************************** end of main ******************************************************************** --> */}

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>

      {/* <!-- **********end of back to top************************* --> */}
      {/* <!-- Modal Video --> */}
      <div
        className="modal fade"
        id="showVideoModal"
        tabIndex="-1"
        aria-labelledby="showYoutubeIframe"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                // id="showYoutubeIframe"
              >
                Display Video
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setVideo("");
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="ratio ratio-16x9 video-frame">
                {tools.generateYoutubeIframe(video)}
              </div>
            </div>
          </div>
        </div>
      </div>
    
   
    </div>
  );
  // function countrySet(countryShort) {
  //   const coun = countryStates.find(
  //     (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
  //   );
  //   setStates(coun.states);
  //   setFilters((prev) => ({
  //     ...prev,
  //     country: coun.country,
  //     countryShort: countryShort.toLowerCase(),
  //     state: "",
  //     stateShort: "",
  //     states: [],
  //     cities: [],
  //   }));
  // }
}
