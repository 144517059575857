// import "./saved.css"

import { Outlet } from "react-router-dom"

// import { useContext } from "react"
// import { CartContext } from "../../../../../contexts/cartContext"

// import UserDashTransNav from '../../../components/dashNavbar/userDashTransNav'
import UserDashTransAffNav from "../../../../components/dashNavbar/userDashTransAffNav"

export default function UserTransAffOut() {
    
  return (
    <div className="savedItems">
        {/* <UserDashNavbar/>*/}
        <UserDashTransAffNav/> 
   
        <Outlet/>
    </div>
  )
}
