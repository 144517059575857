import { Route } from "react-router-dom";

import MarketPlaceHome from "../components/screens/marketPlace/home/marketPlaceHome";
import UsedFilters from "../components/screens/marketPlace/filters/usedFilters";
import BusinessPage from "../components/screens/business/page/businessPage";
import UsedPage from "../components/screens/marketPlace/page/usedPage";
import MatchFilters from "../components/screens/marketPlace/filters/matchFilters";
import UsedRegister from "../components/screens/marketPlace/register/usedRegister";
// import BusinessFilters from "../components/screens/business/filters/businessFilters";
// import BusinessHome from "../components/screens/business/home/businessHome";
// import BusinessRegister from "../components/screens/business/register/businessRegister";
// import BusinessPage from "../components/screens/business/page/businessPage";
// import BusTerms from "../components/screens/business/busTerms";
// import NeedsHome from "../components/screens/marketplace/home/marketPlaceHome";
export default function MarketPlaceRoutes() { 
  return (
    <>
      <Route path="" element={<MarketPlaceHome />} />
      <Route path="used/filters" element={<UsedFilters />} />
      <Route path="used/register" element={<UsedRegister />} />
      <Route path="used/page/:id/:title?" element={<UsedPage />} />
      <Route path="match/filters" element={<MatchFilters />} />
      <Route path="match/page/:id/:title?" element={<UsedPage />} />

      {/* <Route path="terms" element={<BusTerms />} /> 
      <Route path="register" element={<BusinessRegister action="register"/>} />
       <Route path="filters" element={<BusinessFilters />}/> */}
       </>
  );
}
