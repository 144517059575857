import { useFormik } from "formik";
import * as Yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import TargetAddress01 from "./targetAddress01";
import { CartContext } from "../../../contexts/cartContext";
import { useEffect, useRef, useContext, useState } from "react";
import BusinessPhotos from "./businessPhotos";
import api from "../../../../utils/apiBusiness";
import RegisterAddressPlaces from "./registerAddressPlaces";
import renderData from "../../../../utils/renderData";
import { Link, useNavigate, useParams } from "react-router-dom";
// import BusinessPage from "../page/businessPage";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import { CircularProgress } from "@mui/material";
import { CheckCircle, Clear, Error } from "@mui/icons-material";
import ToastNote from "../../../golbal/notifications/toastNote";
import AddSocialMedia from "../../../persian/addSocialMedia";
import WeekdaysSelector from "./weekdaysSelector";
import apiCat from "../../../../utils/api";
import FormikErrRender from "../../../persian/common/alerts/formikErrRender";
import tools from "../../../../utils/tools";
import countryStates from "../../../../utils/countryStates";
import ColoredSpinner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import CatsSelector from "../../../golbal/catsSelector";
import MetaDecorator from "../../../../utils/metaDecorator";
import FormikListErr from "../../../persian/common/alerts/formikListErr";
// import AddEmployee from "./addEmployes";

export default function BusinessRegisterSch({ action }) {
  // console.log('action',action)
  const { lang, state:{state,stateShort}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
  const { translations } =
  JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const navigate = useNavigate();
  const { id } = useParams();
  const [view, setView] = useState({
    register: true,
    preview: false,
  });
  const [loading, setLoading] = useState({
    register: false, //register,editBusiness,editAndActivate
    existBus: false, //existBusHandle
    existEmail: false, //existEmailAddress
    existPhone: false, //existPhoneCheckHandle
    activate: false, //activationHandle
    sendCode: false, //sendCodeToContactPhone
    btn: false,
  });
  // const effectRan = useRef(false);
  const [passwordCounter, setPaswordCounter] = useState(1);
  const messageRef = useRef(null);
  const photoRef = useRef(null);
  const [existBusEmail, setExistBusEmail] = useState([]);
  const [existBusPhone, setExistBusPhone] = useState([]);
  const [existBus, setExistBus] = useState([]);
  const { user, serviceIncrement, signedUser } = useContext(CartContext);
  const isOwner = user?.roles?.includes(1000);
  // const existingBusiness = useLocation().state;

  // console.log('categories',categories);

  useEffect(() => {
    // if(!user?.roles?.includes(9999))actModal()
    (async () => {
      // if(!user?.roles?.includes(9999))actModal()
      // if(!window.logined(9999))return
      if (action === "edit") return fetchDetails();
      const storedBusInfo = JSON.parse(localStorage.getItem("businessInfo"));
      if (storedBusInfo !== null) return formik.setValues(storedBusInfo);
    })();
  }, []);

  async function fetchDetails() {
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await api.businessDetails(id);
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    primaryValues(response.data);
  }

  async function register() {
    console.log("register");
    setLoading((pre) => ({ ...pre, btn: true }));
    const response1 = await Promise.all([
      await photoRef.current.sendBusinessCardToFirebase(),
      await photoRef.current.sendLogoToFirebase(),
      await photoRef.current.sendCoverToFirebase(),
      await photoRef.current.sendBodyPhotosFirebase(),
    ]);

    const newVal = { ...formik.values };
    newVal.photos = {
      businessCard: response1[0],
      logo: response1[1],
      coverPhoto: response1[2],
      bodyPhotos: response1[3],
    };
    delete newVal.resizedPhotos;
    newVal.businessPhone = tools.prepareForSMS(newVal.businessPhone);
    newVal.contactPhone = tools.prepareForSMS(newVal.contactPhone);
    const response = await api.register(user.token, newVal);
    setLoading((pre) => ({ ...pre, btn: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div dir="ltr">{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({
      message: formik.values.suggestMode
        ? `سپاس ${user.userName} گرامی کسب و کار با موفقیت ثبت شد.`
        : `تبریک! کسب و کار با موفقبت ثبت شد، برای فعالسازی و مالکیت از روش زیر اقدام کنید.`,
    });
    if (formik.values.suggestMode === true)
      return setTimeout(() => {
        window.scroll(0, 0);     
        navigate(`/${window.lang}/business/page/${tools.slugMaker({title:response.data.busName[window.lang],city:response.data?.moreInfo?.address?.city,state:response.data?.moreInfo?.address?.state,serial:response.data?.serial})}`, {
          replace: true,
        });
      }, 100);

    navigate(
      `/${window.lang}/dashboard/user/business/edit/${tools.slugMaker({title:response.data.busName[window.lang],city:response.data?.moreInfo?.address?.city,state:response.data?.moreInfo?.address?.state,serial:response.data?.serial})}`
    );

    setTimeout(() => {
      var myModal = document.getElementById("claimModal"); // Get the modal element
      var modal = new window.bootstrap.Modal(myModal, {
        backdrop: "static",
        keyboard: true,
      });
      document.querySelector("#claimModal button").style.visibility = "hidden";
      modal.show();
      // document.getElementById('finishedBusBtn').style.display="block"
      // document.getElementById('finishedBusBtn').click()
      document.getElementById("createBusBtn").style.display = "none";
    }, 1000);

    localStorage.removeItem("businessInfo");
    // actions.resetForm();

    primaryValues(response.data);
  }
  function actModal() {
    console.log("actModa");
    var myModal = document.getElementById("claimModal"); // Get the modal element
    var modal = new window.bootstrap.Modal(myModal, {
      backdrop: "static",
      keyboard: true,
    });
    // document.querySelector("#claimModal button").style.visibility = "hidden";
    modal.show();
    // document.getElementById('finishedBusBtn').style.display="block"
    // document.getElementById('finishedBusBtn').click()
    // document.getElementById("createBusBtn").style.display = "none";
  }

  async function editAndActivate() {
    setLoading((pre) => ({ ...pre, btn: true }));
    const response1 = await Promise.all([
      await photoRef.current.sendLogoToFirebase(),
      await photoRef.current.sendCoverToFirebase(),
      await photoRef.current.sendBodyPhotosFirebase(),
      await photoRef.current.sendBusinessCardToFirebase(),
    ]);

    const newVal = { ...formik.values };
    newVal.photos = {
      logo: response1[0],
      coverPhoto: response1[1],
      bodyPhotos: response1[2],
      businessCard: response1[3],
    };
    delete newVal.resizedPhotos;
    newVal.businessPhone = tools.prepareForSMS(newVal.businessPhone);
    newVal.contactPhone = tools.prepareForSMS(newVal.contactPhone);
    const response = await api.editAndActivate(user.token, newVal);
    setLoading((pre) => ({ ...pre, btn: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>خطا در انجام عملیات!</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({
      message: "کسب و کار با موفقیت به روز رسانی و فعال شد.",
    });
    localStorage.removeItem("businessInfo");

    const updateduser = await apiCat.getUser();
    if (updateduser.error) return;
    signedUser(updateduser.data);

    setTimeout(() => {
      window.scroll(0, 0);
      navigate(`/${window.lang}/business/page/${tools.slugMaker({title:response.data.busName[window.lang],city:response.data?.moreInfo?.address?.city,state:response.data?.moreInfo?.address?.state,serial:response.data?.serial})}`, {
        replace: true,
      });
    }, 100);
  }
  async function editBusiness() {
    console.log("editBusiness", formik.values);

    const newVal = { ...formik.values };
    delete newVal.resizedPhotos;

    const statesCount = newVal.targetAddress.reduce((count, address) => {
      return count + address.states.length;
    }, 0);
    // console.log(
    //   newVal.isPaid.targetAddress,
    //   statesCount > newVal.isPaid.targetCount,
    //   statesCount,
    //   newVal.isPaid.targetCount
    // );
    if (
      newVal.isPaid.targetAddress &&
      statesCount > newVal.isPaid.targetCount &&
      window.confirm(
        `اعتبار شما برای نمایش این کسب و کار در ${
          newVal.isPaid.targetCount
        } استان می باشد، با افزایش اعتبار در ${
          statesCount - newVal.isPaid.targetCount
        } استان دیگر موافقم. `
      ) === true
    )
      return addTargetBasket({
        formik,
        serviceIncrement,
        messageRef,
        navigate,
      });
    newVal.businessPhone = tools.prepareForSMS(newVal.businessPhone);
    newVal.contactPhone = tools.prepareForSMS(newVal.contactPhone);
    setLoading((pre) => ({ ...pre, btn: true }));
    const response = await api.editBusiness({
      token: user.token,
      values: newVal,
      id,
    });
    console.log(response.error);
    setLoading((pre) => ({ ...pre, btn: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>خطا در انجام عملیات!</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({
      message: "کسب و کار با موفقیت به روز رسانی شد.",
    });
    primaryValues(response.data);
    localStorage.removeItem("businessInfo");
    setTimeout(() => {
      navigate(`/${window.lang}/business/page/${tools.slugMaker({title:response.data.busName[window.lang],city:response.data?.moreInfo?.address?.city,state:response.data?.moreInfo?.address?.state,serial:response.data?.serial})}`, {
        replace: true,
      });
    }, 100);
  }
  const countryInfo = countryStates.find(
    (count) => count.countryShort === countryShort.toUpperCase()
  );

  const urlRegex =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
  const englishRegex = /^[a-zA-Z0-9 ]+$/;
  const formik = useFormik({
    initialValues: {
      action,
      terms: false,
      listingLangs: [lang],
      suggestMode: false,
      busName: { fa: "", en: "", fr: "" },
      businessLng: lang,
      email: "",
      tags: `لیست بیزینس های ${translations[window.lang]||country}+کسب و کارهای ایرانی${translations[window.lang]||country}+شرکت های ایرانی ${translations[window.lang]||country}+معرفی بهترین بیزینس های ایرانی ${translations[window.lang]||country}`,
      category: {
        mainCat: {},
        subCat: {},
      },
      serial: '',
      desc: { fa: "", en: "", fr: "" },
      contactPhone: "",
      businessPhone: "",
      verifiedPhone: [],
      workingDays: [
        {
          from: "",
          to: "",
          weekDay: "",
        },
      ],
      website: "",
      socialMedia: [{ platform: "", url: "" }],
      location: {
        displayAddress: true,
        country,
        countryShort,
        state,
        stateShort,
        city: "",
        route: "",
        municipality: "",
        streetNumber: "",
        number: "",
        postalCode: "",
        googleAddress: "",
        lat: "",
        lng: "",
      },
       states:countryInfo.states,
      targetAddress: [
        {
          country: "",
          countryShort: "",
          states: [],
        },
      ],
      photos: {},
      resizedPhotos: [],
      video: [
        {
          description: "",
          youtube: "",
        },
      ],
      languages: [],
      facilities: [],
      serviceTypes: [],
      paymentMethods: [],
      isPaid: {},
      employes: [{ name: "", email: "", phone: "", _id: "" }],
    },
    onSubmit: async (values, actions) => {
      console.log(values);
      console.log("act", act);
      return act.getAction();
    },
    validationSchema: Yup.object({
      terms: Yup.boolean().oneOf(
        [true],
        " قوانین افزودن کسب و کار را مطالعه و سپس دکمه قبول را فشار دهید."
      ),
      busName: Yup.object().shape({
        fa: Yup.string()
          .required(" نام کسب و کار را انتخاب کنید.")
          .min(3, "نام کسب و کار حداقل باید ۳ حرف باشد.")
          .max(50, "نام کسب و کار حداکثر باید 50 حرف باشد.")
          .trim(),
        en: Yup.string()
          .matches(englishRegex, "please use english letters.")
          .min(3, "Business name must be 3 letters and more.")
          .max(50, "Business name must be 50 letters and less.")
          .trim(),
        fr: Yup.string()
          .matches(englishRegex, "please use english letters.")
          .min(3, "Business name must be 3 letters and more.")
          .max(50, "Business name must be 50 letters and less.")
          .trim(),
      }),
      desc: Yup.object().shape({
        fa: Yup.string()
          .max(1000, "توضیحات حداکثر باید 1000 حرف .")
          .min(0, "توضحیات حداقل باید 5 حرف باشد.")
          .trim(),
        en: Yup.string()
          .matches(englishRegex, "please use english letters.")
          .max(1000, "Business name must be 1000 letters and less."),
        fr: Yup.string()
          .matches(englishRegex, "please use english letters.")
          .max(1000, "Business name must be 1000 letters and less."),
      }),
      category: Yup.object().shape({
        mainCat: Yup.object().shape({
          _id: Yup.string().required(" دسته‌بندی را انتخاب کنید."),
        }),
        subCat: Yup.object().shape({
          _id: Yup.string().required(" دسته بندی کوچکتر را انتخاب کنید."),
        }),
      }),

      email: Yup.string()
        .trim()
        .lowercase()
        .when("suggestMode", (suggestMode, schema, value) => {
          // console.log("suggestMode",suggestMode)
          return suggestMode && value.parent.businessPhone?.length
            ? schema.notRequired().email(" فرمت ایمیل را درست وارد کنید.")
            : schema
                .required("ایمیل را وارد کنید.")
                .email("ایمیل را به درستی وارد کنید.");
        }),

      contactPhone: Yup.string()
        .when("suggestMode", (suggestMode, schema, value) => {
          return suggestMode
            ? schema.notRequired()
            : schema
                .required("شماره تلفن الزامی است")
                .test(
                  "is-valid-phone",
                  "شماره تلفن نامعتبر میباشد.",
                  (value) => {
                    return isValidPhoneNumber(value || "");
                  }
                );
        })
        .trim(),

      businessPhone: Yup.string()
        .when("suggestMode", (suggestMode, schema, value) => {
          return value?.value?.length
            ? schema
                .required("شماره تلفن یا ایمیل الزامی است.")
                .test(
                  "is-valid-phone",
                  "شماره تلفن نامعتبر میباشد.",
                  (value) => {
                    return isValidPhoneNumber(value || "");
                  }
                )
            : (suggestMode && value.parent.email?.length) || !suggestMode
            ? schema.notRequired()
            : schema
                .required("شماره تلفن یا ایمیل الزامی است.")
                .test(
                  "is-valid-phone",
                  "شماره تلفن نامعتبر میباشد.",
                  (value) => {
                    return isValidPhoneNumber(value || "");
                  }
                );
        })
        .trim(),
      website: Yup.string().matches(
        urlRegex,
        " آدرس وبسایت را به درستی وارد کنید."
      ),
      location: Yup.object().shape({
        country: Yup.string().required("کشور نمیتواند خالی باشد."),
        state: Yup.string().required("استان نمیتواند خالی باشد."),
        city: Yup.string().required("شهر نمیتواند خالی باشد."),
        municipality: Yup.string(),
        route: Yup.string().when("suggestMode", (suggestMode, schema) => {
          return formik.values.suggestMode
            ? schema.notRequired("نام خیابان ضروری نیست.")
            : schema.required(" نام خیابان را وارد کنید.");
        }),

        streetNumber: Yup.string().when(
          "suggestMode",
          (suggestMode, schema) => {
            return formik.values.suggestMode
              ? schema.notRequired()
              : schema.required(" پلاک را وارد کنید.");
          }
        ),

        number: Yup.string(),
        postalCode: Yup.string().when("suggestMode", (suggestMode, schema) => {
          return formik.values.suggestMode
            ? schema.notRequired()
            : schema.required("کد پستی نمیتواند خالی باشد.");
        }),

        // lat: Yup.number().when("suggestMode", (suggestMode, schema) => {
        //   return formik.values.suggestMode
        //     ? schema.notRequired()
        //     : schema.required(" عرض جغرافیایی را انتخاب کنید.");
        // }),

        // lng: Yup.number().when("suggestMode", (suggestMode, schema) => {
        //   return formik.values.suggestMode
        //     ? schema.notRequired()
        //     : schema.required(" طول جغرافیایی را انتخاب کنید.");
        // }),
      }),
      socialMedia: Yup.array().of(
        Yup.object().shape({
          platform: Yup.string("پلتفرم نمی تواند خالی باشد."),
          url: Yup.string()
            .when("platform", (platform, schema) => {
              return platform
                ? schema.required("لطفاً آدرس URL را وارد نمایید.")
                : schema;
            })
            .test("validUrl", " آدرس را به شکل درست وارد کنید.", (value) => {
              if (!value) return true; // Allow empty value
              // Regular expression to validate URL
              return urlRegex.test(value);
            }),
        })
      ),
      workingDays: Yup.array().of(
        Yup.object().shape({
          weekDay: Yup.string(),
          from: Yup.string().when("weekDay", (weekDay, schema) => {
            return weekDay
              ? schema.required(" زمان شروع را انتخاب کنید.")
              : schema;
          }),
          to: Yup.string()
            .when("weekDay", (weekDay, schema) => {
              return weekDay
                ? schema.required(" زمان پایان را انتخاب کنید.")
                : schema;
            })
            .when("from", (from, schema) => {
              return from
                ? schema.test({
                    name: "timeValidation",
                    exclusive: true,
                    test: function (to) {
                      const fromTime = getTimeInMinutes(from);
                      const toTime = getTimeInMinutes(to);
                      return fromTime < toTime;
                    },
                    message: "زمان پایان باید بعد از زمان شروع باشد.",
                  })
                : schema;
            }),
        })
      ),
      // resizedPhotos :Yup.object().shape({
      //   bodyPhotos:Yup.array().min(0, "عکس های گالری"),
      //   businessCard: Yup.string(),
      //   coverPhoto:Yup.string(),
      //   logo:Yup.string()

      // })
    }),
  });
  // console.log('formik.errors',formik.errors)
  // console.log('formik.touched',formik.touched)
  const act = checkAction();
  function checkAction() {
    // console.log("action", action);
    let actToDo = { textBtn: "کنترل پارامتر ها", getAction: "register" };
    const verifiedPhone =
      formik.values?.verifiedPhone[0]?.phoneNumber ===
      formik.values.contactPhone;
    // console.log('verifiedPhone',verifiedPhone);
    if (action === "register") {
      actToDo.textBtn = "ثبت اولیه";
      actToDo.getAction = register;
    }
    if (
      action === "edit" &&
      verifiedPhone &&
      formik.values.status === "01-draft"
    ) {
      actToDo.textBtn = "فعال سازی";
      actToDo.getAction = editAndActivate;
    }
    if (
      action === "edit" &&
      !verifiedPhone &&
      tools.compareCheck([formik.values.status], ["01-draft"])
    ) {
      actToDo.textBtn = "تائید هویت و فعال سازی";
      actToDo.getAction = actModal;
    }
    if (
      action === "edit" &&
      !verifiedPhone &&
      tools.compareCheck([formik.values.status], ["01-draft"]) &&
      formik.values.history.createdBy._id === user._id
    ) {
      actToDo.textBtn = "ویرایش پیشنهاد";
      actToDo.getAction = editBusiness;
    }
    if (
      action === "edit" &&
      tools.compareCheck(
        [formik.values.status],
        ["02-claimed", "03-verified", "04-trusted"]
      ) &&
      verifiedPhone
    ) {
      actToDo.textBtn = "ویرایش";
      actToDo.getAction = editBusiness;
    }
    if (
      action === "edit" &&
      !verifiedPhone &&
      tools.compareCheck(
        [formik.values.status],
        ["02-claimed", "03-verified", "04-trusted"]
      )
    ) {
      actToDo.textBtn = "تائید هویت و ویرایش";
      actToDo.getAction = actModal;
    }
    return actToDo;
  }
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "افزودن کسب و کار ",
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/business`,
              name: ` بیزنس ها`,
              description: `نیازمندیها و کسب و کارهای ایرانی `,
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/business/register`,
              name: `ثبت بیزینس`,
              description:
                "از اینجا میتون به رایگان و اتوماتیک کسب و کارتون ثبت و به روز رسانی کنین...",
            },
          },
        ],
      },
    },
  ];

  // console.log("formik.errors", formik.errors);
  console.log("formik.values", formik.values);

  const metaTagsData = {
    title: `متامارس | ${
      action === "edit?" ? "ویرایش کسب و کار" : "افزودن کسب و کار"
    }`,
    description:
      " ثبت رایگان کسب و کار | معرفی رایگان | صفحه اختصاصی رایگان| افزایش شانس در گوگل",
    section: "bussines register",
    url: `https://www.metamarce.com/${window.lang}/business/register`,
    img: "https://www.metamarce.com/img/Images/Profile-Interface-bro.svg",
    type: "webpage",
  };
  return (
    <>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <ToastNote messageRef={messageRef} />
      {loading.spinner && <ColoredSpinner />}
      <div className=" mx-0 align-items-center w-100">
        <div
          className="display-none-md"
          style={{
            backgroundColor: "var(--brownGray)",
          }}
        >
          <div className="text-center pt-5 titr3 font-40">
            {action === "register" ? "افزودن" : "ویرایش"} کسب و کار
            <div>{formik.values.busName["fa"]}</div>
          </div>
        </div>
        <div className="text-center m-4 titr7  display-tablet">
          {action === "register" ? "افزودن" : "ویرایش"} کسب و کار
        </div>
      </div>

      {/* <!-- This is htmlFor curve! --> */}

      <div className="business-top-curve display-none-md">
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: "100%", width: "100%" }}
        >
          <path
            d="M-2.82,59.70 C211.62,278.78 237.58,-55.75 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
            style={{ stroke: "none", fill: "var(--brownGray)" }}
          ></path>
        </svg>
      </div>

      {/* <!-- *************************************************************************************************************************************************** --> */}
      {user?.roles?.includes(9999) ? (
        <div>
          {/* <div className=" d-flex justify-content-start mb-4 mx-5 display-none-md display">
            <span
              className={`me-2 map-card ${view.register && "map-card-active"}`}
              onClick={(e) => setView({ register: true, preview: false })}
            >
              فرم ثبت نام <i className="bi bi-pencil mx-2"></i>
            </span>
            <span
              className={`me-2 map-card  ${view.preview && "map-card-active"}`}
              onClick={(e) => setView({ register: false, preview: true })}
            >
              پیش‌نمایش <i className="bi bi-binoculars mx-2"></i>
            </span>
          </div> */}

          {/* {view.register && ( */}
          <div style={{ display: view.register ? "block" : "none" }}>
            {!formik?.values?._id && isOwner && (
              <div className="switch-button my-4 mx-auto">
                <input
                  className="switch-button-checkbox"
                  type="checkbox"
                  // onClick={e=> console.log( e.target.checked)}
                  onClick={(e) =>
                    formik.setValues((prev) => ({
                      ...prev,
                      suggestMode: e.target.checked,
                    }))
                  }
                ></input>
                <div className="switch-button-label">
                  <span className="switch-button-label-span">
                    صاحب کسب و کار
                  </span>
                </div>
              </div>
            )}
            <form
              name="busRegForm"
              id="busRegForm"
              className="form-style mt-0"
              onSubmit={formik.handleSubmit}
              autoComplete="false"
            >
              {/* <!-- Righ side --> */}
              <div className="px-md-5 px-2">
                <div className="my-3">
                  <div className="accordion w-100" id="accordionExample">
                    <div className="accordion-item2 m-md-3 my-3">
                      <div
                        className="accordion-button accordion-button4 collapsed "
                        type="button"
                        data-bs-toggle="collapse"
                        aria-expanded="true"
                        data-bs-target={`#open-col-location`}
                        aria-controls={`open-col-location`}
                        aria-labelledby="open-col-location-tab"
                      >
                        <div>
                          موقعیت جغرافیایی
                          <span> {checkFields(formik, ["location"])} </span>
                        </div>
                      </div>
                      <div
                        className="accordion-collapse collapse"
                        id="open-col-location"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="row ">
                          <div className="col-md-6 ">
                            {/* <RegisterAddress formik={formik} /> */}
                            <p className="form-titr mb-3  large bold">
                              <span>
                                <i className="bi bi-circle-fill mx-1"></i>
                              </span>
                              نشانی کسب و کار :
                              <Tooltip1
                                message={
                                  <div>
                                    اگر آدرس دقیق پیشنهاد نشد، نزدیک ترین منطقه
                                    به خود را پیدا و در ادامه اطلاعات کامل را
                                    وارد کنید.
                                  </div>
                                }
                              />
                            </p>
                            <RegisterAddressPlaces
                              formik={formik}
                              section="business"
                              setLoading={setLoading}
                            />
                            {/* <TargetAddress formik={formik} /> */}

                            {(formik.values.status === "02-claimed" ||
                              formik.values.status === "03-verified") && (
                              <div
                                className="d-flex justify-content-between "
                                onClick={(e) =>
                                  redirectGoPro({ formik, navigate })
                                }
                              >
                                <p
                                  className="form-titr mt-0 py-0 mb-3 "
                                  data-bs-toggle="modal"
                                  data-bs-target="#premiumModal"
                                >
                                  <span>
                                    <img
                                      src="/img/premium/global.png"
                                      className="arrow4 px-1"
                                      alt="targetAddress"
                                      loading="lazy"
                                    />
                                  </span>
                                  نمایش آگهی در متامارس دیگر کشور/ استانها :
                                </p>
                                <span className="mt-0 py-0 mb-3 ">
                                  <img
                                    // src="/img/premium/premium.png"
                                    src="/img/premium/premium-quality.png"
                                    className="arrow2"
                                    alt="premium"
                                  />
                                </span>
                              </div>
                            )}
                            {formik.values.isPaid.targetAddress && (
                              <div>
                                <TargetAddress01 formik={formik} />
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 ">
                            {/* <MapBusRegister data={formik.values?.location} /> */}
                            {formik.values.location.city?.length > 0 && (
                              <div className="lat-lng m-3  ">
                                <p className="form-titr mb-3 mt-4 large bold required">
                                  {/* <span >
                          <i className="bi bi-circle-fill mx-1"></i>
                        </span> */}
                                  مختصات جغرافیایی کسب و کار:
                                </p>
                                <div className="row">
                                  <div className="col mb-2">
                                    <label
                                      className="form-label mt-0 mb-2"
                                      htmlFor="f201"
                                    >
                                      عرض
                                    </label>
                                    <input
                                      id="f201"
                                      type="number"
                                      value={formik.values?.location.lat}
                                      {...formik.getFieldProps("location.lat")}
                                      // onChange={(event) => setLat(parseFloat(event.target.value))}
                                      // step={0.01}
                                    />
                                    <FormikErrRender
                                      formik={formik}
                                      field="location.lat"
                                    />
                                  </div>

                                  <div className="col mb-2">
                                    <label
                                      className="form-label mt-0 mb-2"
                                      htmlFor="f200"
                                    >
                                      طول
                                    </label>

                                    <input
                                      id="f200"
                                      type="number"
                                      value={formik.values?.location.lng}
                                      {...formik.getFieldProps("location.lng")}
                                      // onChange={(event) => setLng(parseFloat(event.target.value))}
                                      // step={0.01}
                                    />
                                    <FormikErrRender
                                      formik={formik}
                                      field="location.lng"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            <img
                              src="/img/Images/Location-review-rafiki.svg"
                              className="img-fluid display-none-md "
                              alt="map"
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item2 m-md-3 my-3">
                      <div
                        className="accordion-button accordion-button4 collapsed "
                        type="button"
                        data-bs-toggle="collapse"
                        aria-expanded="true"
                        data-bs-target={`#open-col-busInfo`}
                        aria-controls={`open-col-busInfo`}
                        aria-labelledby="open-col-busInfo-tab"
                      >
                        <div>
                          اطلاعات کسب و کار
                          {checkFields(formik, ["busName", "category", "desc"])}
                        </div>
                      </div>
                      <div
                        className="accordion-collapse collapse"
                        id="open-col-busInfo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="row d-flex">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="form-label mt-3 mb-0 required"
                                htmlFor="f1"
                              >
                                عنوان کسب و کار
                                <Tooltip1
                                  message=" نام کسب و کار خود را اینجا وارد کنید!  (مثال: آموزشکاه دانا)"
                                  // position="top-end"
                                />
                              </label>
                              <input
                                type="text"
                                autoComplete="off"
                                className="form-control py-1 box2 "
                                id={"f1"}
                                placeholder=" نام کسب و کار خود را اینجا وارد کنید!  (مثال: آموزشکاه دانا)"
                                {...formik.getFieldProps(`busName.fa`)}
                              />

                              {formik.values.busName?.[window.lang]?.length >=
                                3 && (
                                <div
                                  id="existingBusinesses"
                                  className="d-flex flex-row align-items-center"
                                >
                                  <span
                                    onClick={existBusHandle}
                                    className="btn btn-primary button-filter button-filter-tag w-50  "
                                  >
                                    لیست کسب و کارهای هم‌نام
                                    {loading.existBus && (
                                      <CircularProgress
                                        color="inherit"
                                        size="20px"
                                      />
                                    )}
                                  </span>

                                  <Tooltip1
                                    message=" ممکن است کسب و کار شما قبلا توسط کاربران پیشنهاد شده باشد، کسب و کارهای هم نام را بوسیله دریافت لیست کسب و کارهای همنام پیدا و در صورت تمایل نسبت به مالکیت رایگان آن اقدام کنید. "
                                    // position="top-end"
                                  />
                                </div>
                              )}
                            </div>
                            {existBus.length > 0 && (
                              <div>
                                <span
                                  title="close"
                                  onClick={(e) => {
                                    document.querySelector(
                                      "#existingBusinesses"
                                    ).style.display = "none";
                                    setExistBus([]);
                                  }}
                                >
                                  <Clear sx={{ color: "red" }} />
                                </span>
                                <div className="BusRegList p-1">
                                  {existBus.map((bus) => (
                                    <ExistBusRender bus={bus} />
                                  ))}
                                </div>
                              </div>
                            )}
                            <FormikErrRender
                              formik={formik}
                              field="busName.fa"
                            />
                            {/* keep This */}
                            {/* <div>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="en"
                        checked={formik.values.listingLangs?.includes("en")}
                        id={"listingLangs" + "en"}
                        onChange={(e) => {
                          let listingLangs = [...formik.values.listingLangs];
                          const value = e.target.value;
                          const index = listingLangs.indexOf(value);
                          if (index !== -1) {
                            listingLangs.splice(index, 1);
                          } else {
                            listingLangs.push(value);
                          }
                          formik.setValues((prev) => ({
                            ...prev,
                            listingLangs,
                          }));
                        }}
                      />

                      <label
                        className="form-check-label mb-3 med-gray-font ms-2"
                        htmlFor={"listingLangs" + "en"}
                      >
                        نمایش آگهی در صفحه متامارس انگلیسی
                      </label>

                      {formik.values.listingLangs?.includes("en") && (
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control py-1 box2 "
                          id={"f1"}
                          placeholder="Enter you business name here (ex: Metamarce)"
                          {...formik.getFieldProps(`busName.en`)}
                        />
                      )}
                      <FormikErrRender formik={formik} field="busName.en" />
                    </div> */}

                            <CatsSelector
                              formik={formik}
                              section={"business"}
                            />

                            <div className="mb-3">
                              <label className="form-label">
                                توضیحات
                                <Tooltip1
                                  message=" کسب و کار خود و خدماتی که ارایه می‌دهید را با جزییات بیشتر معرفی کنید."
                                  // position="top-end"
                                />
                              </label>

                              <textarea
                                className="form-control"
                                rows="1"
                                defaultValue={formik.values.desc[window.lang]}
                                onChange={(e) => {
                                  e.target.style.height = "auto";
                                  e.target.style.height = e.target.scrollHeight + "px";}}
                                  onBlur={e=>formik.setValues(pre=>({...pre,
                                    desc:{...pre.desc,[window.lang]:e.target.value}
                                  }))}
                                  // {...formik.getFieldProps("desc.fa")}
                              ></textarea>
                              <FormikErrRender
                                formik={formik}
                                field="desc.fa"
                              />
                            </div>

                            {/* {formik.values.listingLangs?.includes("en") && (
                              <div className="mb-3">
                                <label className="form-label">
                                  Description
                                  <Tooltip1
                                    message=" Describe your business in more details."
                                    position="top-end"
                                  />
                                </label>

                                <textarea
                                  className="form-control"
                                  rows="7"
                                  {...formik.getFieldProps("desc.en")}
                                ></textarea>
                                <FormikErrRender
                                  formik={formik}
                                  field="desc.en"
                                />
                              </div>
                            )} */}
                          </div>

                          <div className="col-md-6 display-none-md">
                            <img
                              src="/img/Images/Profile-Interface-bro.svg"
                              className="img-fluid   "
                              alt="Profile"
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item2 m-md-3 my-3">
                      <div
                        className="accordion-button accordion-button4 collapsed "
                        type="button"
                        data-bs-toggle="collapse"
                        aria-expanded="true"
                        data-bs-target={`#open-col-contactInfo`}
                        aria-controls={`open-col-contactInfo`}
                        aria-labelledby="open-col-contactInfo-tab"
                      >
                        <div>
                          اطلاعات تماس
                          {checkFields(formik, [
                            "email",
                            "contactPhone",
                            "businessPhone",
                            "workingDays",
                          ])}
                        </div>
                      </div>
                      <div
                        className="accordion-collapse collapse"
                        id="open-col-contactInfo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="col-md-7 col-lg-6">
                          <div className="mb-3">
                            <label
                              //  className="form-label required mb-0"

                              className={`form-label mt-3 mb-0 ${
                                !formik.values.suggestMode ||
                                !formik.values.businessPhone.length
                                  ? "required"
                                  : ""
                              } `}
                              htmlFor="f3"
                            >
                              ایمیل کسب و کار
                              <Tooltip1
                                message=" 
                          ایمیل کسب  و کار را جهت نمایش و تماس کاربران اینجا وارد نمائید."
                                // position="top-end"
                              />
                            </label>

                            <input
                              type="email"
                              className="form-control py-1 box2"
                              id="f3"
                              placeholder="abc@metamarce.com"
                              name="f3"
                              {...formik.getFieldProps("email")}
                            />

                            {formik.touched.email &&
                              !formik.errors.email &&
                              formik.values.email?.length > 0 && (
                                <div
                                  id="existingEmail"
                                  className="d-flex flex-row align-items-center"
                                >
                                  <span
                                    onClick={existEmailAddress}
                                    className="btn btn-primary button-filter button-filter-tag w-50  "
                                  >
                                    دریافت مشاغل این ایمیل
                                    {loading.existEmail && (
                                      <CircularProgress
                                        color="inherit"
                                        size="20px"
                                      />
                                    )}
                                  </span>
                                  <Tooltip1
                                    message=" ممکن است کسب و کار شما قبلا توسط کاربران پیشنهاد شده باشد، کسب و کارهای هم نام را بوسیله دریافت لیست کسب و کارهای هم نام پیدا و در صورت تمایل نسبت به مالکیت رایگان آن اقدام کنید. "
                                    // position="top-end"
                                  />
                                </div>
                              )}

                            {existBusEmail.length > 0 && (
                              <div>
                                <span
                                  title="close"
                                  onClick={(e) => {
                                    document.querySelector(
                                      "#existingEmail"
                                    ).style.display = "none";
                                    setExistBusEmail([]);
                                  }}
                                >
                                  <Clear sx={{ color: "red" }} />
                                </span>
                                <div className="BusRegList p-1">
                                  {existBusEmail.map((bus) => (
                                    <ExistBusRender bus={bus} />
                                  ))}
                                </div>
                              </div>
                            )}
                            <FormikErrRender formik={formik} field="email" />
                          </div>
                          <div className="mb-3">
                            <label
                              className={`form-label mt-3 mb-0 ${
                                formik.values.suggestMode &&
                                !formik.values.email.length
                                  ? "required"
                                  : ""
                              } `}
                              htmlFor="f7"
                            >
                              شماره تلفن (کسب و کار)
                              <Tooltip1
                                message="شماره تلفن خود را با اعداد لاتین و با کد کشور وارد کنید. (مثال: 6543 987 514 1+) این شماره در وبسایت نمایش داده خواهد شد."
                                // position="top-end"
                              />
                            </label>
                            <a
                              tabIndex="0"
                              className="btn info-btn shadow-none py-0 "
                              role="button"
                              data-bs-toggle="popover"
                              data-bs-trigger="focus"
                              data-bs-content="این شماره در وبسایت نمایش داده خواهد شد. "
                            >
                              {/* <i className="bi bi-question-circle-fill"></i> */}
                            </a>
                            <input
                              type="tel"
                              className="form-control py-1  box2"
                              id="f7"
                              placeholder="+1 514 333 4444"
                              // pattern="{[0-9]{3}-[0-9]{3}-[0-9]{4}"
                              // {...formik.getFieldProps("businessPhone")}
                              value={formik.values.businessPhone}
                              onChange={(e) =>
                                formik.setValues((pre) => ({
                                  ...pre,
                                  businessPhone: tools.convertToEnglish(
                                    e.target.value
                                  ),
                                }))
                              }
                            />
                            {formik.values.verifiedPhone?.find(
                              (verify) =>
                                verify.phoneNumber ===
                                formik.values.businessPhone
                            ) && (
                              <Tooltip1
                                message="این شماره تائید شده است."
                                icon={
                                  <CheckCircle
                                    sx={{ fontSize: 20, color: "green" }}
                                  />
                                }
                                // position="left"
                              />
                            )}

                            {formik.touched.businessPhone &&
                              !formik.errors.businessPhone &&
                              formik.values.businessPhone?.length > 0 && (
                                <div id="existingBusPhone">
                                  <span
                                    onClick={existPhoneCheckHandle}
                                    className="btn btn-primary button-filter button-filter-tag w-50  "
                                  >
                                    دریافت مشاغل این تلفن
                                    {loading.existPhone && (
                                      <CircularProgress
                                        color="inherit"
                                        size="20px"
                                      />
                                    )}
                                  </span>
                                  <Tooltip1
                                    message=" ممکن است کسب و کار شما قبلا توسط کاربران پیشنهاد شده باشد، کسب و کارهای هم نام را بوسیله دریافت لیست کسب و کارهای هم نام پیدا و در صورت تمایل نسبت به مالکیت رایگان آن اقدام کنید. "
                                    // position="top-end"
                                  />
                                </div>
                              )}

                            {existBusPhone.length > 0 && (
                              <div>
                                <span
                                  title="close"
                                  onClick={(e) => {
                                    document.querySelector(
                                      "#existingBusPhone"
                                    ).style.display = "none";
                                    setExistBusPhone([]);
                                  }}
                                >
                                  <Clear sx={{ color: "red" }} />
                                </span>
                                <div className="BusRegList p-1">
                                  {existBusPhone.map((bus) => (
                                    <ExistBusRender bus={bus} />
                                  ))}
                                </div>
                              </div>
                            )}

                            <FormikErrRender
                              formik={formik}
                              field="businessPhone"
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              className={`form-label mt-3 mb-0 ${
                                !formik.values.suggestMode && "required"
                              } `}
                              htmlFor="f4"
                            >
                              شماره تلفن همراه (مسئول)
                              <Tooltip1
                                message="شماره تلفن مالک یا شخص هماهنگ کننده کسب و کار. (مثال: 6543 987 514 1+) این شماره در وبسایت نمایش داده نخواهد شد و برای تایید مالکیت به آن نیاز خواهید داشت."
                                // position="top-end"
                              />
                            </label>
                            {/* <a
                        tabIndex="0"
                        className="btn info-btn shadow-none py-0 "
                        role="button"
                        data-bs-toggle="popover"
                        data-bs-trigger="focus"
                        data-bs-content=" شماره تلفن خود را با اعداد لاتین و با کد کشور وارد کنید. (مثال: +1 514 987 6543) این شماره در وبسایت نمایش داده نخواهد شد."
                      >
                        <i className="bi bi-question-circle-fill"></i>
                      </a> */}
                            <input
                              type="tel"
                              className="form-control py-1  box2"
                              id="f4"
                              placeholder="+1 514 333 4444"
                              disabled={
                                formik.values.suggestMode ||
                                formik.values?.verifiedPhone[0]?.phoneNumber ===
                                  formik.values.contactPhone
                              }
                              // {...formik.getFieldProps("contactPhone")}
                              value={formik.values.contactPhone}
                              onChange={(e) =>
                                formik.setValues((pre) => ({
                                  ...pre,
                                  contactPhone: tools.convertToEnglish(
                                    e.target.value
                                  ),
                                }))
                              }
                            />
                            {formik.values?.verifiedPhone[0]?.phoneNumber ===
                              formik.values.contactPhone && (
                              <Tooltip1
                                message="این شماره تائید شده است."
                                icon={
                                  <CheckCircle
                                    sx={{ fontSize: 20, color: "green" }}
                                  />
                                }
                              />
                            )}
                            <FormikErrRender
                              formik={formik}
                              field="contactPhone"
                            />
                          </div>
                          <WeekdaysSelector formik={formik} />
                        </div>

                        {/* <div className="col-md-5 display-none-md">
                          <img
                            src="/img/Images/Time-management-pana.png"
                            className="img-fluid  "
                            alt="Time-management-pana"
                            loading="lazy"
                          />
                        </div> */}
                      </div>
                    </div>
                    <div className="accordion-item2 m-md-3 my-3">
                      <div
                        className="accordion-button accordion-button4 collapsed "
                        type="button"
                        data-bs-toggle="collapse"
                        aria-expanded="true"
                        data-bs-target={`#open-col-socialMedias`}
                        aria-controls={`open-col-socialMedias`}
                        aria-labelledby="open-col-socialMedias-tab"
                      >
                        <div>
                          شبکه های اجتماعی
                          {checkFields(formik, ["socialMedia", "website"])}
                        </div>
                      </div>
                      <div
                        className="accordion-collapse collapse"
                        id="open-col-socialMedias"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="row d-flex">
                          <div className="col-md-6 ps-3">
                            <div className="mb-3 mt-3">
                              <label
                                className="form-label mt-3  ps-2"
                                htmlFor="f5"
                              >
                                وبسایت
                                <Tooltip1
                                  message="برای مثال: https://www.metamarce.com،در صورت تمایل لینک وبسایت خود را به صورت کامل وارد کنید."
                                  // position="top-end"
                                />
                              </label>
                              <a
                                tabIndex="0"
                                className="btn info-btn shadow-none "
                                role="button"
                                data-bs-toggle="popover"
                                data-bs-trigger="focus"
                                data-bs-content="در صورت تمایل لینک وبسایت خود را به صورت کامل وارد کنید."
                              >
                                {/* <i className="bi bi-question-circle-fill"></i> */}
                              </a>

                              <input
                                type="text"
                                autoComplete="off"
                                className=" form-control mb-5 py-1 box2"
                                dir="ltr"
                                id="f5"
                                placeholder=" https://www.instagram.com/metamarce?igsh=azJoejNtdjdydXY4&utm_source=qr "
                                {...formik.getFieldProps("website")}
                              />
                              <FormikErrRender
                                formik={formik}
                                field="website"
                              />
                            </div>
                            لینک شبکه‌های اجتماعی خودتون را وارد کنید.
                            <AddSocialMedia formik={formik} />
                          </div>

                          <div className="col-md-6 display-none-md ">
                            <img
                              src="/img/Images/Mobile-Marketing-pana-(2).png"
                              className="img-fluid   "
                              alt="social Media"
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item2 m-md-3 my-3">
                      <div
                        className="accordion-button accordion-button4 collapsed "
                        type="button"
                        data-bs-toggle="collapse"
                        aria-expanded="true"
                        data-bs-target={`#open-col-photos`}
                        aria-controls={`open-col-photos`}
                        aria-labelledby="open-col-photos-tab"
                      >
                        <div>
                          بارگذاری عکس
                          {checkFields(formik, ["photos"])}
                        </div>
                      </div>
                      <div
                        className="accordion-collapse collapse"
                        id="open-col-photos"
                        data-bs-parent="#accordionExample"
                      >
                        <BusinessPhotos photoRef={photoRef} formik={formik} />
                      </div>
                    </div>
                    {/* <div className="accordion-item2 m-md-3 my-3">
                      <div
                        className="accordion-button accordion-button4 collapsed "
                        type="button"
                        data-bs-toggle="collapse"
                        aria-expanded="true"
                        data-bs-target={`#open-col-upload-video`}
                        aria-controls={`open-col-upload-video`}
                        aria-labelledby="open-col-upload-video-tab"
                      >
                        <div>
                          بارگذاری ویدئو
                          {checkFields(formik, ["video"])}
                        </div>
                      </div>
                      <div
                        className="accordion-collapse collapse"
                        id="open-col-upload-video"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="row mt-5">
                          <div className="col-md-6 pe-0">
                            {formik.values.isPaid.video ? (
                              <div className="mb-3 mt-3">
                                <label
                                  className="form-label form-titr mt-3  ps-2"
                                  htmlFor="f55"
                                >
                                  <span>
                                    <i className="bi bi-circle-fill me-1"></i>
                                  </span>
                                  لینک ویدیوی یوتوب
                                  <Tooltip1
                                    message={
                                      <div>
                                        <div>
                                          .لینک ویدنو رو را مطابق عکس از یوتیوب
                                          کپی کنید.
                                        </div>
                                        <img
                                          src="/img/videoLink.jpg"
                                          height="300"
                                          width="500"
                                          loading="lazy"
                                        />
                                      </div>
                                    }
                                    position="top-end"
                                  />
                                </label>
                                <a
                                  tabIndex="0"
                                  className="btn info-btn shadow-none "
                                  role="button"
                                  data-bs-toggle="popover"
                                  data-bs-trigger="focus"
                                  data-bs-content="در صورت تمایل لینک ویدئو معرفی کسب و کار خود را به صورت کامل وارد کنید."
                                >
                                 
                                </a>

                                <input
                                  type="text"
                                  autoComplete="off"
                                  className=" form-control my-5 mt-3 py-1 box2"
                                  id="f55"
                                  placeholder=" مثال:https://youtu.be/yfY9zRZxqtY."
                                  onBlur={(e) => {
                                    const videoIdMatch = e.target.value?.match(
                                      /(?:v=|v\/|embed\/|youtu\.be\/)(.{11})/
                                    );
                                    if (!videoIdMatch)
                                      return messageRef.current?.showError({
                                        message: "لینک ویدئو درست نمی باشد .",
                                      });
                                    formik.setValues((pre) => {
                                      const val = { ...pre };
                                      val.video[0].youtube = e.target.value;
                                      return val;
                                    });
                                  }}
                                />
                                <div>
                                  <div className="ratio ratio-16x9 mb-5 ">
                                    {generateYoutubeIframe(
                                      formik.values?.video[0]?.youtube ?? ""
                                    )}
                                  </div>
                                
                                  <div></div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div>
                                  {action === "edit"
                                    ? " پرداخت شما تاکنون برای نمایش ویدئو در صفحه این کسب و کار  کامل نشده است."
                                    : "این گزینه در این مرحله فعال نیست.  پس از پایان ثبت‌‌نام شما می‌تونید در صفحه تبلیغات (افزودن ویدیو) را خریداری و فعال کنید."}
                                </div>
                                <div
                                  className="btn btn-primary login-btn login-btn2 mt-3"
                                  onClick={(e) =>
                                    window.open(`/${window.lang}/store/plans`)
                                  }
                                >
                                  تبلیغات
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 display-none-md">
                            <img
                              src="/img/Images/Press-pause-bro.svg"
                              className="img-fluid m-5  "
                              alt="Press-pause-bro"
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="accordion-item2 m-md-3 my-3">
                      <div
                        className="accordion-button accordion-button4 collapsed "
                        type="button"
                        data-bs-toggle="collapse"
                        aria-expanded="true"
                        data-bs-target={`#open-col-moreInfo`}
                        aria-controls={`open-col-moreInfo`}
                        aria-labelledby="open-col-moreInfo-tab"
                      >
                        <div>اطلاعات تکمیلی</div>
                      </div>
                      <div
                        className="accordion-collapse collapse"
                        id="open-col-moreInfo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="row d-flex justify-content-center align-items-center">
                          <div className="col-md-7  my-3  form-font">
                            <div>
                              <p id="tagsInput" className="form-titr ">
                                <span>
                                  <i className="bi bi-circle-fill me-1"></i>
                                </span>
                                برچسب‌ها (با "+" جدا شوند )
                                <Tooltip1
                                  message={
                                    <div>
                                      <div>
                                        برچسب‌ها - تگ - را توسط '+' (بدون
                                        فاصله)از یکدیگر جداکنید.
                                      </div>
                                      <div>حداکثر ۱۰ برچسب.</div>
                                    </div>
                                  }
                                />
                              </p>
                              <div className="small-gray-font">
                                (بیشتر دیده شدن آگهی. 10 کلمه کلیدی که ممکنه کسی
                                اون رو جستجو کنه را وارد کنید)
                              </div>
                              <input
                                type="text"
                                autoComplete="off"
                                name="other"
                                placeholder={
                                  formik?.values?.tags ||
                                  `برای مثال : بازسازی+طراحی داخلی+ طراحی فضای سبز`
                                }
                                className="form-input1 mt-3"
                                {...formik.getFieldProps("tags")}
                              />
                            </div>

                            <div className="row">
                              <div className="col-md-4">
                                <div className=" p-0">
                                  <p className="form-titr">
                                    <span>
                                      <i className="bi bi-circle-fill me-1"></i>
                                    </span>
                                    چگونگی ارايه خدمات:
                                  </p>

                                  {renderData.serviceTypes?.map((servT, i) => (
                                    <div key={i} className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={servT.name}
                                        checked={formik.values.serviceTypes?.includes(
                                          servT.name
                                        )}
                                        id={`checkST${i}`}
                                        onChange={(e) => {
                                          let serviceTypes = [
                                            ...formik.values.serviceTypes,
                                          ];
                                          const value = e.target.value;
                                          const index =
                                            serviceTypes.indexOf(value);
                                          if (index !== -1) {
                                            serviceTypes.splice(index, 1);
                                          } else {
                                            serviceTypes.push(value);
                                          }
                                          formik.setValues((prev) => ({
                                            ...prev,
                                            serviceTypes,
                                          }));
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`checkST${i}`}
                                      >
                                        {servT[window.lang]}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className=" p-0">
                                  <p className="form-titr">
                                    <span>
                                      <i className="bi bi-circle-fill me-1"></i>
                                    </span>
                                    چگونگی پرداخت:
                                  </p>

                                  {renderData.paymentMethods?.map((payM, i) => (
                                    <div key={i} className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={payM.name}
                                        checked={formik.values.paymentMethods?.includes(
                                          payM.name
                                        )}
                                        id={`checkPM${i}`}
                                        onChange={(e) => {
                                          let paymentMethods = [
                                            ...formik.values.paymentMethods,
                                          ];
                                          const value = e.target.value;
                                          const index = paymentMethods.indexOf(
                                            payM.name
                                          );
                                          if (index !== -1) {
                                            paymentMethods.splice(index, 1);
                                          } else {
                                            paymentMethods.push(value);
                                          }
                                          formik.setValues((prev) => ({
                                            ...prev,
                                            paymentMethods,
                                          }));
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`checkPM${i}`}
                                      >
                                        {payM?.[window.lang]}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className=" p-0">
                                  <p className="form-titr">
                                    <span>
                                      <i className="bi bi-circle-fill me-1 "></i>
                                    </span>
                                    زبان خدمات:
                                  </p>
                                  <div className="row row-cols-1">
                                    {renderData.languages.map((lang, i) =>
                                      !lang[window.lang].length ? (
                                        ""
                                      ) : (
                                        <div
                                          key={i}
                                          className="morelang-line my-0 "
                                        >
                                          <input
                                            className="form-check-input  ms-2 me-1 red moreLang"
                                            type="checkbox"
                                            value={lang.name}
                                            checked={formik.values.languages?.includes(
                                              lang.name
                                            )}
                                            id={`flexLanguages${i}`}
                                            onChange={(e) => {
                                              let languages =
                                                formik.values.languages || [];
                                              const valueIndex =
                                                formik.values.languages.indexOf(
                                                  lang.name
                                                );
                                              if (valueIndex !== -1) {
                                                languages.splice(valueIndex, 1);
                                              } else {
                                                languages.push(e.target.value);
                                              }

                                              formik.setValues((pre) => ({
                                                ...pre,
                                                languages,
                                              }));
                                            }}
                                          />
                                          <label
                                            className="form-check-label ps-2 red moreLang"
                                            htmlFor={`flexLanguages${i}`}
                                          >
                                            {lang[window.lang]}
                                          </label>
                                        </div>
                                      )
                                    )}
                                  </div>

                                  <button
                                    className="btn btn-primary edit-btn fw-bold py-0 mt-3"
                                    type="button"
                                  >
                                    <label htmlFor="moreLangCheckbox">
                                      {" "}
                                      بیشتر ...
                                    </label>
                                    <input
                                      id="moreLangCheckbox"
                                      type="checkbox"
                                      onClick={(e) => {
                                        const elemet =
                                          document.querySelectorAll(
                                            ".moreLang"
                                          );
                                        if (e.target.checked) {
                                          document.querySelector(
                                            "label[for=moreLangCheckbox]"
                                          ).innerHTML = "کمتر ...";
                                          Array.from(elemet)?.map(
                                            (el) =>
                                              (el.style.display =
                                                "inline-block")
                                          );
                                        } else {
                                          document.querySelector(
                                            "label[for=moreLangCheckbox]"
                                          ).innerHTML = " بیشتر ...";
                                          Array.from(elemet)?.map(
                                            (el) => (el.style.display = "none")
                                          );
                                        }
                                      }}
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>

                            {formik.values.isPaid.facilities && (
                              <div>
                                <div
                                  className="d-flex justify-content-between "
                                  // onClick={(e) => redirectGoPro({ formik, navigate })}
                                >
                                  <p className="form-titr my-4 py-1 ">
                                    <span>
                                      <img
                                        src="/img/premium/three-dots.png"
                                        className="arrow2 px-1"
                                        alt="targetAddress"
                                        loading="lazy"
                                      />
                                    </span>
                                    کدام یک از امکانات زیر را در اختیار مشتریان
                                    خود قرار می‌دهید؟
                                  </p>
                                  <span className=" my-4 pb-3 ">
                                    <img
                                      // src="/img/premium/premium.png"
                                      src="/img/premium/premium-quality.png"
                                      className="arrow2"
                                      alt="premium"
                                      loading="lazy"
                                    />
                                  </span>
                                </div>
                                <Facilities formik={formik} />
                              </div>
                            )}
                          </div>

                          <div className="col-md-5 display-none-md p-0 d-flex justify-content-center align-items-center ">
                            <img
                              src="/img/Images/Customer-Survey-pana.svg"
                              className="img-fluid   "
                              alt="Customer-Survey-pana"
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* {isOwner && (
                      <div>
                        <div className="accordion-item2 m-md-3 my-3">
                          <div
                            className="accordion-button accordion-button4 collapsed "
                            type="button"
                            data-bs-toggle="collapse"
                            aria-expanded="true"
                            data-bs-target={`#open-col-schedule`}
                            aria-controls={`open-col-schedule`}
                            aria-labelledby="open-col-schedule-tab"
                          >
                            <div> ثبت کارمند</div>
                          </div>
                          <div
                            className="accordion-collapse collapse"
                            id="open-col-schedule"
                            data-bs-parent="#accordionExample"
                          >
                            <AddEmployee formik={formik} />
                          </div>
                        </div>
                        <div className="accordion-item2 m-md-3 my-3">
                          <div
                            className="accordion-button accordion-button4 collapsed "
                            type="button"
                            data-bs-toggle="collapse"
                            aria-expanded="true"
                            data-bs-target={`#open-col-location2`}
                            aria-controls={`open-col-location2`}
                            aria-labelledby="open-col-location2-tab"
                          >
                            <div>+ سایر فیلدهای مورد نیاز</div>
                          </div>
                          <div
                            className="accordion-collapse collapse"
                            id="open-col-location2"
                            data-bs-parent="#accordionExample"
                          >
                            <div>
                              در متامارس این امکان فراهم شده که اگر یک استاد در
                              دو زمینه متفاوت توانایی تدریس داشته باشد، برای هر
                              کدام به شکل جداگانه پروفایل استاد تشکیل بدهند
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                  {/* <!-- Modal --> */}
                  <div
                    className="modal fade"
                    id="claimModal"
                    tabIndex="-1"
                    aria-labelledby="claimModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1 className="modal-title fs-5" id="claimModalLabel">
                            ثبت نهایی و اثبات مالکیت کسب و کار
                          </h1>
                          <button
                            type="button"
                            id="closeClaimModalBtn"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-md-7">
                              <p>
                                برای ثبت نهایی و فعالسازی یک کد به شماره شما
                                فرستاده خواهد شد.
                              </p>

                              <div className="claim-box">
                                <div className="d-flex justify-content-start align-items-center">
                                  <input
                                    type="checkbox"
                                    id="showTextmessageBtn"
                                    value={formik.values.contactPhone}
                                    name="calimOption"
                                    onChange={(e) =>
                                      (document.querySelector(
                                        ".hideShowControl"
                                      ).style.display = e.target.checked
                                        ? "block"
                                        : "none")
                                    }
                                    style={{ display: "none" }}
                                    dir="ltr"
                                  />

                                  <label
                                    className="form-label mb-0 mx-3 btn-cart "
                                    htmlFor="showTextmessageBtn"
                                  >
                                    دریافت پیامک
                                    <Tooltip1
                                      message="این شماره شخص هماهنگ کننده میباشد و در وبسایت نمایش داده نخواهد شد."
                                      // position="left"
                                    />
                                  </label>
                                </div>
                                <div
                                  style={{ display: "none" }}
                                  className="hideShowControl"
                                >
                                  <div className="d-flex justify-content-between">
                                    <span>
                                      فرستادن پیامک به :
                                      {formik.values?.contactPhone.length ? (
                                        <div dir="ltr">
                                          {tools.formatPhoneNumber(
                                            formik.values?.contactPhone
                                          )}
                                        </div>
                                      ) : (
                                        <div> تلفن مسئول وارد نشده است.</div>
                                      )}
                                    </span>
                                    <div className="d-flex">
                                      <button
                                        type="button"
                                        className="btn btn-primary button-filter button-filter-tag m-3"
                                        onClick={sendCodeToContactPhone}
                                      >
                                        بفرست
                                        {loading.sendCode && (
                                          <CircularProgress
                                            color="inherit"
                                            size="20px"
                                          />
                                        )}
                                      </button>

                                      <Tooltip1
                                        message={
                                          <div>
                                            <div>
                                              دریافت کد ممکنه دو دقیقه زمان
                                              ببره.
                                            </div>
                                            {countryShort === "ir" && (
                                              <div>
                                                با توجه به محدودیت های بین
                                                المللی، ارسال پیام تنها به
                                                شماره‌های ایرانسل و همراه اول
                                                امکانپذیر می باشد.
                                              </div>
                                            )}
                                          </div>
                                        }
                                        // position="left"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                id="getVerficationCode"
                                style={{ display: "none" }}
                              >
                                <p>کد دریافتی رو این جا وارد کنید:</p>
                                <div className="claim-box d-flex flex-column align-items-center text-center ">
                                  <input
                                    type="text"
                                    autoComplete="off"
                                    className="form-control box3 w-50 text-center "
                                    {...formik.getFieldProps("codeA")}
                                  />
                                  <div className="d-flex justify-content-center">
                                    <button
                                      type="button"
                                      className="btn btn-primary button-filter button-filter-tag m-3 "
                                      disabled={loading.activate}
                                    >
                                      <div
                                        className="d-flex align-items-center"
                                        onClick={activationHandle}
                                      >
                                        تکمیل ثبت نام
                                        {loading.activate && (
                                          <CircularProgress
                                            color="inherit"
                                            size="20px"
                                          />
                                        )}
                                        <i className="bi bi-caret-left ms-1"></i>
                                      </div>
                                    </button>
                                  </div>

                                  <div className="megamenu">
                                    {/* <a href="#" onClick={sendCodeToContactPhone}>
                                    درخواست کد جدید
                                    {loading.sendCode && (
                                        <CircularProgress
                                          color="inherit"
                                          size="20px"
                                        />
                                      )}
                                  </a> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5 align-items-center my-5 display-none-md">
                              <img
                                src="/img/illustration/task-animate.svg"
                                alt="claim"
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer d-flex">
                          <a
                            href={`/${window.lang}/prompt/claimBen`}
                            target="_blank"
                            className="btn btn-outline-secondary save-cover-btn ms-3"
                          >
                            چرا باید ادعا کنم؟
                          </a>
                          <div
                            onClick={(e) => {
                              document
                                .querySelector("#closeClaimModalBtn")
                                .click();
                              window.scroll(0, 0);
                              navigate(
                                `/${window.lang}/business/page/${tools.slugMaker({title:formik.values.busName[window.lang],city:formik.values?.moreInfo?.address?.city,state:formik.values?.moreInfo?.address?.state,serial:formik.values?.serial})}`,{ replace: true }
                              );
                            }}
                            className="btn btn-outline-secondary save-cover-btn ms-3"
                          >
                            برو به صفحه کسب و کار
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 
                  <div
                    href="#previewBusiness"
                    className="my-1  login-btn login-btn0 text-center login-btn4 display-none-lg"
                    onClick={(e) => setView({ register: false, preview: true })}
                  >
                    پیش‌نمایش
                  </div> */}
                  <label
                    htmlFor="termsBtn"
                    className={`required my-3 mb-5 med-gray-font  `}
                  >
                    <a
                      className=" py-0 pe-1 fw-bold fst-italic"
                      href={`/${window.lang}/business/terms`}
                      target="_blank"
                    >
                      ( قوانین افزودن کسب و کار )
                    </a>
                    <span>را مطالعه کردم و می‌پذیرم</span>

                    <input
                      id="termsBtn"
                      type="checkbox"
                      className="form-check-input mx-2"
                      onChange={(e) => {
                        if (formik.values.terms) return;
                        formik.setValues((pre) => ({ ...pre, terms: true }));
                      }}
                      checked={formik.values.terms}
                    />
                    <FormikErrRender formik={formik} field="terms" />
                  </label>
                  <FormikListErr formik={formik} />
                  <SubmitBtn formik={formik} loading={loading} act={act} />
                </div>
              </div>
            </form>
          </div>
          {/* )} */}
          {/* {view.preview && (
            <div className="mt-3">            
              <BusinessPage data={reversePrimaryValues(formik.values)} />
            </div>
          )} */}
          {/* <!-- ********** back to top************************* --> */}
          <div className="row backtotop">
            <div className="col-12">
              <a
                href="#top"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="بازگشت به آغاز صفحه"
                className="tooltip-color"
              >
                <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-404 text-center d-flex flex-column align-items-center px-4 ">
          <div className="my-3 ">
            <div> در گام نخست شما نیاز دارید تا وارد حساب کاربری شوید.</div>

            <Link
              id="loginTrigger"
              className="btn primary-btn btn-404 checkout-btn my-5 mt-3"
              data-bs-toggle="modal"
              to="#exampleModalToggle"
              role="button"
            >
              ورود به حساب کاربری
            </Link>
            <img
              className=" img-404 w-75 "
              src="/img/illustration/access-denied.svg"
              alt="404"
            />
          </div>
        </div>
      )}
    </>
  );

  async function existBusHandle(e) {
    e.preventDefault();
    setLoading((pre) => ({ ...pre, existBus: true }));
    const response = await api.checkExistBus({
      busName: formik.values.busName,
      lang: window.lang,
      values: window.location.pathname.toLowerCase().split("/").slice(2, 4),
      token: user.token,
    });
    setLoading((pre) => ({ ...pre, existBus: false }));
    if (response.error) return alert(response.error);
    setExistBus(response.data);
    const el = document.querySelector("#existingBusinesses");
    document.querySelector("#existItemNotAvail")?.remove();
    if (response.data.length == 0)
      return el.insertAdjacentHTML(
        "beforeEnd",
        `<div class="error-message" id="existItemNotAvail">موردی با این نام پیدا نشد.</div>`
      );
    document.querySelector("#existItemNotAvail")?.remove();
  }
  async function existEmailAddress(e) {
    e.preventDefault();
    setLoading((pre) => ({ ...pre, existEmail: true }));
    const response = await api.checkExistBus({
      email: formik.values.email,
      values: [countryShort,stateShort],
      token: user.token,
      lang:window.lang
    });
    setLoading((pre) => ({ ...pre, existEmail: false }));
    if (response.error) return alert(response.error);
    setExistBusEmail(response.data);
    const el = document.querySelector("#existingEmail");
    document.querySelector("#existItemNotAvail")?.remove();
    if (response.data.length == 0)
      return el.insertAdjacentHTML(
        "beforeEnd",
        `<span class="error-message" id="existItemNotAvail">موردی با این ایمیل پیدا نشد.</span>`
      );
    document.querySelector("#existItemNotAvail")?.remove();
  }
  async function existPhoneCheckHandle(e) {
    e.preventDefault();
    setLoading((pre) => ({ ...pre, existPhone: true }));
    const response = await api.checkExistBus({
      businessPhone: formik.values.businessPhone,
      values: window.location.pathname.toLowerCase().split("/").slice(2, 4),
      token: user.token,
    });
    setLoading((pre) => ({ ...pre, existPhone: false }));
    if (response.error) return alert(response.error);
    setExistBusPhone(response.data);
    const el = document.querySelector("#existingBusPhone");
    document.querySelector("#existItemNotAvail")?.remove();
    if (response.data.length == 0)
      return el.insertAdjacentHTML(
        "beforeEnd",
        `<span id="existItemNotAvail">کسب و کاری با این تلفن پیدا نشد.</span>`
      );
    document.querySelector("#existItemNotAvail")?.remove();
  }
  async function saveHandle() {
    messageRef.current.showSuccess({
      message:
        "اطلاعات کسب و کار به صورت موقت ذخیره شد، توجه اینکه اطلاعات مربوط به تصاویر قابل ذخیره سازی نمی باشد.",
    });
    localStorage.setItem("businessInfo", JSON.stringify(formik.values));
  }
  function primaryValues(existingBusiness) {
    formik.setValues({
      suggestMode: existingBusiness?.suggestMode || false,
      serial: existingBusiness?.serial,
      terms: existingBusiness?.moreInfo?.terms || false,
      listingLangs: existingBusiness?.listingLangs || [],
      verifiedPhone: existingBusiness?.moreInfo?.verifiedPhone || [],
      busName: existingBusiness?.busName || {},
      businessLng: existingBusiness?.moreInfo?.businessLng || lang,
      status: existingBusiness?.moreInfo?.status || "01-draft",
      isPaid: existingBusiness?.moreInfo?.isPaid || {},
      employes: existingBusiness?.employesInfo || {},
      _id: existingBusiness?._id,
      email: existingBusiness?.email || "",
      feedbackInfo: existBus.feedbackInfo,
      category: {
        mainCat: existingBusiness?.category?.mainCat || "",
        subCat: existingBusiness?.category?.subCat || "",
      },
      desc: existingBusiness?.moreInfo?.desc || "",

      contactPhone: existingBusiness?.moreInfo?.contactPhone || "",
      businessPhone: existingBusiness?.moreInfo?.businessPhone || "",
      workingDays: existingBusiness?.moreInfo?.workingDays || [
        {
          from: " ",
          to: " ",
          weekDay: "",
        },
      ],
      website: existingBusiness?.moreInfo?.website || "",
      socialMedia: existingBusiness?.moreInfo?.socialMedia || [],
      location: {
        displayAddress: existingBusiness?.moreInfo?.address.displayAddress,
        country: existingBusiness?.moreInfo?.address?.country || "",
        countryShort: existingBusiness?.moreInfo?.address?.countryShort || "",
        state: existingBusiness?.moreInfo?.address?.state || "",
        stateShort: existingBusiness?.moreInfo?.address?.stateShort || "",
        city: existingBusiness?.moreInfo?.address?.city || "",
        route: existingBusiness?.moreInfo?.address?.route || "",

        municipality: existingBusiness?.moreInfo?.address?.municipality || "",
        streetNumber: existingBusiness?.moreInfo?.address?.streetNumber || "",
        number: existingBusiness?.moreInfo?.address?.number || "",
        postalCode: existingBusiness?.moreInfo?.address?.postalCode || "",
        googleAddress: existingBusiness?.moreInfo?.address?.googleAddress || "",
        lat: existingBusiness?.moreInfo?.address?.coordinates?.lat || "",
        lng: existingBusiness?.moreInfo?.address?.coordinates?.lng || "",
      },
      targetAddress: existingBusiness?.moreInfo?.targetAddress || [],
      resizedPhotos: existingBusiness.moreInfo?.photos || {},
      photos: existingBusiness.moreInfo?.photos || {},
      languages: existingBusiness?.moreInfo?.languages || [],
      facilities: existingBusiness?.moreInfo?.facilities || [],
      serviceTypes: existingBusiness?.moreInfo?.serviceTypes || [],
      paymentMethods: existingBusiness?.moreInfo?.paymentMethods || [],
      employes: existingBusiness?.moreInfo?.employes || [],
      video: existingBusiness?.moreInfo?.video || [],
      tags: existingBusiness.moreInfo?.tags.join("+"),
      history: existingBusiness.moreInfo?.history,
    });
  }

  function reversePrimaryValues(formikValues) {
    const initialFetchedData = {
      preview: true,
      suggestMode: formikValues.suggestMode,
      moreInfo: {
        terms: formikValues?.terms,
        verifiedPhone: formikValues.verifiedPhone,
        businessLng: formikValues.businessLng,
        status: formikValues.status,
        isPaid: formikValues.isPaid,

        // _id: formikValues._id,
        desc: formikValues.desc,
        contactPhone: formikValues.contactPhone,
        businessPhone: formikValues.businessPhone,
        workingDays: formikValues.workingDays,
        website: formikValues.website,
        socialMedia: formikValues.socialMedia,
        address: {
          displayAddress: formikValues.location.displayAddress,
          country: formikValues.location.country,
          countryShort: formikValues.location.countryShort,
          state: formikValues.location.state,
          stateShort: formikValues.location.stateShort,
          city: formikValues.location.city,
          route: formikValues.location.route,
          municipality: formikValues.location.municipality,
          streetNumber: formikValues.location.streetNumber,
          number: formikValues.location.number,
          postalCode: formikValues.location.postalCode,
          googleAddress: formikValues.location.googleAddress,
          coordinates: {
            lat: formikValues.location.lat,
            lng: formikValues.location.lng,
          },
        },
        targetAddress: formikValues.targetAddress,
        photos: {
          bodyPhotos: photoRef?.current?.photos.bodyPhotos.resized,
          businessCard: photoRef?.current?.photos.businessCard.resized,
          coverPhoto: photoRef?.current?.photos.coverPhoto.resized,
          logo: photoRef?.current?.photos.logo.resized,
        },
        languages: formikValues.languages,
        employes: formikValues.employes || [],

        facilities: formikValues.facilities,
        serviceTypes: formikValues.serviceTypes,
        paymentMethods: formikValues.paymentMethods,
        video: formikValues.video,
        tags: formikValues?.tags?.split("+"),
      },
      listingLangs: formikValues.listingLangs,
      busName: formikValues.busName,
      email: formikValues.email,
      feedbackInfo: formikValues.feedbackInfo,
      category: {
        mainCat: formikValues.category.mainCat,
        subCat: formikValues.category.subCat,
      },
    };

    return initialFetchedData;
  }

  async function activationHandle(e) {
    e.preventDefault();
    setPaswordCounter(passwordCounter + 1);
    console.log(passwordCounter);
    setTimeout(() => setPaswordCounter(1), 1000 * 60 * 15);
    if (passwordCounter > 5)
      return messageRef.current.showError({
        message:
          "تلاش های نادرست شما بیش از 5 می باشد،  پس از 15 دقیقه برای دریافت کد جدید اقدام کنید",
      });

    if (!formik.values.codeA || formik.values.codeA.length < 6)
      return messageRef.current.showError({
        message: <div>خطا در انجام عملیات!</div>,
      });

    e.target.setAttribute("disabled", true);

    setTimeout(() => {
      e.target.removeAttribute("disabled");
    }, 1000 * 10 * 1);
    setLoading((pre) => ({ ...pre, activate: true }));
    const response = await api.busActivation({
      value: formik.values.codeA,
      token: user.token,
      id,
    });
    setLoading((pre) => ({ ...pre, activate: false }));
    console.log(response);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>خطا در انجام عملیات!</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({
      message: (
        <div>
          <span> : تبریک </span>
          <span>! {user.userName}</span>
          <div>با موفقیت فعال شد. {formik.values.busName?.[window.lang]}</div>
        </div>
      ),
    });

    const updateduser = await apiCat.getUser();
    if (updateduser.error) return;
    signedUser(updateduser.data);

    setTimeout(() => {
      window.scroll(0, 0);
      document.querySelector("#claimModal button").click();
      navigate(`/${window.lang}/business/page/${tools.slugMaker({title:formik.values.busName[window.lang],city:formik.values?.moreInfo?.address?.city,state:formik.values?.moreInfo?.address?.state,serial:formik.values?.serial})}`, {
        replace: true,
      });
    }, 2000);
  }

  async function sendCodeToContactPhone(e) {
    e.preventDefault();
    console.log(id, !formik.values.contactPhone?.length);
    if (!id || !formik.values.contactPhone?.length)
      return messageRef.current.showWarning({
        message: " کسب و کار یا شماره تلفن را کنترل کنید!",
      });
    e.target.setAttribute("disabled", true);
    setLoading((pre) => ({ ...pre, sendCode: true }));
    const response = await api.sendCodeToContactPhone({
      token: user.token,
      id,
      contactPhone: formik.values.suggestMode
        ? formik.values.businessPhone
        : formik.values.contactPhone,
    });
    setLoading((pre) => ({ ...pre, sendCode: false }));
    console.log(response);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>خطا در انجام عملیات!</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({
      message: "کد فعالسازی از طریق پیامک فرستاده شد.",
    });

    document.querySelector("#getVerficationCode").style.display = "block";
    document.querySelector(".hideShowControl").checked = false;
    setTimeout(() => {
      e.target.removeAttribute("disabled");
    }, 1000 * 60 * 1);
  }

  function getTimeInMinutes(time) {
    const [hours, minutes] = time?.split(":");
    return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  }
}

function ExistBusRender({ bus }) {
  return (
    <div key={bus?._id} className="m-1 p-2 bs-1 ">
      <Link
        className="d-flex justify-content-around"
        to={`/${window.lang}/business/page/${tools.slugMaker({title:bus.busName[window.lang],city:bus?.moreInfo?.address?.city,state:bus?.moreInfo?.address?.state,serial:bus?.serial})}`}
      >
        <img
          height="40px"
          width="60px"
          src={bus?.businessCard}
          alt={bus?.busName?.[window.lang]}
          loading="lazy"
        />
        <span className="m-1 p-2">{bus?.busName?.[window.lang]}</span>
        <span className="m-1 p-2">
          دسته بندی : {bus?.category?.subCat?.[window.lang]}
        </span>
        {/* <span className="m-1 p-2">
      تلفن : {bus?.moreInfo?.businessPhone}
    </span> */}
        <span className="m-1 p-2">ایمیل : {bus?.email}</span>
        <div className="group-list-item2 my-1">
          <i className="bi bi bi-geo-alt mx-2"></i>
          {`${window.capitalize(bus?.address?.city)},
${window.capitalize(bus?.address?.state)},
${window.capitalize(bus?.address?.countryShort)}
`}
        </div>
      </Link>
    </div>
  );
}

function redirectGoPro({ formik, navigate }) {
  navigate(
    `/${window.lang}/dashboard/user/ads/plans?_id=${formik.values._id}&targetIdentifier=Business`,
    {
      state: {
        title: formik.values.busName?.[window.lang],
        img: formik.values.photos.businessCard,
        targetIdentifier: "Business",
        _id: formik.values._id,
      },
    },
    {
      // replace: true,
    }
  );
}

function Facilities({ formik }) {
  // const { formik, serviceIncrement } = data;
  return (
    <div id="facilitiesRender">
      <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4  justify-content-evenly g-4 mt-3">
        {renderData.facilities?.map((fac, i) => (
          <div key={i} id="facilitiesRender" className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={fac.name}
              checked={formik.values.facilities?.includes(fac.name)}
              id={`facilCheck${i}`}
              onChange={(e) => {
                let facilities = [...formik.values.facilities];
                const index = facilities.indexOf(fac.name);
                if (index !== -1) {
                  facilities.splice(index, 1);
                } else {
                  facilities.push(e.target.value);
                }

                formik.setValues((pre) => ({
                  ...pre,
                  facilities,
                }));
              }}
            />
            <label className="form-check-label" htmlFor={`facilCheck${i}`}>
              {fac?.[window.lang]}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
function addTargetBasket({ formik, serviceIncrement, messageRef, navigate }) {
  console.log("addTargetBasket");
  // e.preventDefault()
  if (!formik.values._id)
    return messageRef.current.showWarning({
      message: " کسب و کار را انتخاب کنید.",
    });

  let countTotal = 0;
  const price = formik.values.targetAddress.reduce((a, item) => {
    countTotal += item.states.length;
    if (countTotal <= 20) return countTotal * 10;
    return (countTotal - 20) * 2.5 + 200;
  }, 0);

  const target = formik.values.targetAddress.map((neewTar) => {
    const newD = {
      country: neewTar.country,
      countryShort: neewTar.countryShort,
      states: neewTar.states,
    };
    return newD;
  });

  if (countTotal > formik.values.isPaid.targetCount) {
    let count = formik.values.isPaid.targetCount * -1;
    const price = formik.values.targetAddress.reduce((a, item) => {
      count += item.states.length;
      if (count <= 20) return count * 10;
      return (count - 20) * 2.5 + 200;
    }, 0);

    serviceIncrement({
      type: "targetAddress",
      items: target,
      price,
      count,
      _id: formik.values._id,
      title: formik.values.busName?.[window.lang],
      img: "/img/premium/map-ad-banner.png",
      mainImg: formik.values.photos.businessCard,
      targetIdentifier: "Business",
    });

    document.querySelector("#sidebarRightTrigger").click();
    messageRef.current.showSuccess({
      message: "نمایش در استانهای دیگر با موفقیت به سبد خریداضافه شد.",
    });
    // navigate(`/${window.location.pathname.split('/').slice(1,4).join('/')}/store/cart`)
    document.querySelector("#sidebarRightTrigger").click();
  }
}

function SubmitBtn({ formik, loading, act }) {
  return (
    <button
      disabled={formik.isSubmitting}
      className="my-1 mb-3 login-btn login-btn2 w-100"
      style={{
        backgroundColor: Object.keys(formik.errors)?.length > 0 && "grey",
      }}
      type="submit"
      id="createBusBtn"
    >
      {act.textBtn}
      {loading.btn ? (
        <CircularProgress color="inherit" size="20px" />
      ) : (
        Object.keys(formik.errors)?.length === 0 && <CheckCircle />
      )}
      <span>
        {Object.keys(formik.errors)?.length > 0 && (
          <Tooltip1
            message=" موارد ستاره دار را کامل کنید."
            icon={<Error sx={{ fontSize: 18, color: "red" }} />}
            // position="left"
          />
        )}
      </span>
    </button>
  );
}

function generateYoutubeIframe(videoLink) {
  const videoIdMatch = videoLink?.match(
    /(?:v=|v\/|embed\/|youtu\.be\/)(.{11})/
  );
  if (!videoIdMatch) return;
  const videoId = videoIdMatch[1];
  const iframeSrc = `https://www.youtube.com/embed/${videoId}`;

  return (
    <iframe
      width="560"
      height="315"
      src={iframeSrc}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen="allowFullScreen"
      mozallowfullscreen="mozallowfullscreen"
      msallowfullscreen="msallowfullscreen"
      oallowfullscreen="oallowfullscreen"
      webkitallowfullscreen="webkitallowfullscreen"
    ></iframe>
  );

  return null; // Return null if video ID couldn't be extracted
}

function checkFields(formik, fields) {
  const hasError = fields.some(
    (field) => field in formik.touched && field in formik.errors
  );

  if (hasError) {
    return (
      <span>
        <Error sx={{ color: "red" }} />
      </span>
    );
  }
  //  else {
  //   return <CheckCircle />;
  // }
}

// function FormikListErr({ formik }) {
//   let rows = [];
//   function extractErrors(errors, touched, path = '') {
//     for (const key in errors) {
//       const currentPath = path ? `${path}.${key}` : key;
//       if (typeof errors[key] === "string") {
//         if (touched?.[key]) {
//           rows.push(
//             <div className="error-message" key={currentPath}>
//               <i className="bi bi-exclamation-triangle-fill mx-2 error-icon"></i>
//               {errors[key]}
//             </div>
//           );
//         }
//       } else {
//         extractErrors(errors[key], touched?.[key], currentPath);
//       }
//     }
//   }
//   extractErrors(formik.errors, formik.touched);

//   return rows;
// }
