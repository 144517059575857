import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import BusCardSliderS1 from "../../golbal/sliders/busCardSliderS1";
import PlansX4 from "../../golbal/sliders/plansX4";
import api from "../../../utils/api";
import HorizontalSlider from "../../golbal/sliders/horizontalSlider";
import ColoredSpinner from "../../alret/spiners/coloredSpiner/coloredSpinner";
import tools from "../../../utils/tools";
import MetaDecorator from "../../../utils/metaDecorator";

export default function EventHome() {
  const [pubData] = useOutletContext();
  // const [popCat, setPopCat] = useState([]);
  // console.log("pubData", pubData);
  const navigate = useNavigate();
  const page = "eventHome";
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const { lang, state:{state,stateShort}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});

  const searchHandle = () => {
    window.scroll(0, 0);
    navigateHandle(`filters?cities=${city}&searchKey=${searchKey}`);
    const storedFilters = JSON.parse(localStorage.getItem("storedFilters"));
    if (storedFilters?.event) {
      delete storedFilters.event;
      localStorage.setItem("storedFilters", JSON.stringify(storedFilters));
    }
  };

  const [loading, setLoading] = useState(false);
  async function fetchCities() {
    if (cities.length > 0) return;
    setLoading(true);
    const response = await api.getCities(countryShort, stateShort);
    setLoading(false);
    if (response.error)
      return alert("مشکل در دریافت نام شهرها، کد خطا :" + response.error);
    setCities(response.data);
  }

  function navigateHandle(url, state) {
    window.scroll(0, 0);
    setTimeout(() => navigate(url, { state }), 200);
  }
  const { translations } =
    JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = ` متامارس
     | ایونت در ${
       translations?.[window.lang] + "-" + state
     } | رویداد های کشور ${translations?.[window.lang] + "-" + state}`;
  const description = `متاایونت | بانک رایگان رویداد های متامارس در ${
    translations?.[window.lang] + "-" + state
  } و سراسر دنیا`;

  const metaTagsData = {
    title,
    description,
    section: "event",
    url: `${window.location.origin}/${window.lang}/event`,
    img: "https://www.metamarce.com/img/jobs/Hiring-rafiki.svg",
    type: "webpage",
    index: false,
  };
  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData }} />
      {loading && <ColoredSpinner />}
      <div className="my-header py-5 m-0">
        <div className="bg-image fixed-bg-jobs "></div>
        <div className="row">
          <div className="col-lg-7">
            <div className="row py-5">
              <div className="col-lg-6 ">
                <h1 className="mx-auto header-firstpage titr1">متاایونت</h1>
                <h3 className="mx-auto header-firstpage titr3">
                  راهنمای خرید بلیط و سرگرمی
                </h3>
              </div>
              <div className="col-lg-6 text-center">
                {/* {/* <!-- <a href="#" className="   mx-auto  ">Metamarce</a> --> */}
                {/* <!-- <h1 className="titr2 mx-auto ">"Metamarce"</h1>  --> */}
                <p className="mx-auto mt-5 header-firstpage titr2 ">
                  MetaEvent
                </p>

                <h3 className="titr4 mx-auto ">{window.capitalize(state)}</h3>
              </div>
            </div>
            <div className="search-sec align-items-end align-end ">
              <div>
                <div className="row  justify-content-center">
                  <div className="col-md-4  p-0" onClick={fetchCities}>
                    <select
                      className="form-control search-slt search-slt2"
                      name="citiesOpt"
                      onChange={(e) => setCity(e.target.value.toLowerCase())}
                    >
                      <option>شهر خود را انتخاب کنید 🜄</option>
                      {cities?.map((city, i) => (
                        <option key={i}>{city.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4  p-0">
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control search-slt search-slt1"
                      placeholder="دنبال چه چیزی می‌گردی؟ "
                      onChange={(e) => setSearchKey(e.target.value)}
                      name="searchFor..."
                    />
                  </div>

                  <div className="col-md-4 p-0">
                    <button
                      type="button"
                      className="btn btn-danger wrn-btn"
                      onClick={searchHandle}
                    >
                      <i className="bi bi-search p-1"></i>
                      جستجوی سریع
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5  px-5  d-flex flex-wrap align-items-center display-none-lg">
            <img
              src="/img/jobs/Hiring-rafiki.svg"
              className="  mb-3  w-100  flip-y-180"
              alt="homePage"
              loading="lazy"
            />
          </div>
        </div>
        <img
          src="/img/homePage/wave-haikei-3.svg"
          className=" p-0 py-0 w-100 overlay-b "
          alt="homePage"
          loading="lazy"
        />
      </div>

      {/* {/* <!-- ******************************* --> */}
      <div className="mx-0 py-0">
        {/* <div className="row shift-down"></div> */}

        <div className=" pb-2 pt-3">
          {/* {/* <!-- گروه‌های اصلی کسب و کار --> */}
          <div className="sections-gap special-carousel">
            <p className="divider2 mt-2">
               
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
                {/* {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
              </svg>
              <span className="p-0 m-z ">گروه‌های اصلی فرصت‌های شغلی</span>
            </p>

            <div className="d-flex justify-content-around flex-wrap card-categories">
              {pubData.categories?.event?.map((cat, i) => (
                <div
                  key={i}
                  //    className="col
                  //  "
                >
                  <div
                    className="card card4 mx-3"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div
                      className="text-center cursor"
                      onClick={(e) =>
                        navigateHandle(
                          `filters?mainCat=${
                            cat.mainCat?.[window.lang]
                          }&mainCatId=${cat._id}&city=${city}`
                        )
                      }
                      aria-label={cat.mainCat?.[window.lang]}
                    >
                      {/* <div className="image-container m-3 image-container-bus">  */}
                      <img
                        src={cat.categoryPhoto}
                        className={`card4-5 `}
                        alt={"event main category" + i}
                        loading="lazy"
                      />
                      {/* </div> */}
                      <div className="card-body card-title-categories">
                        <h5 className="card-title card-title-categories ">
                          {cat.mainCat?.[window.lang]}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <!-- گروه‌های اصلی کسب و کار end of --> */}

          {/* Why Metamarce for media */}
          <div
            className="px-md-5 mx-0 my-5 img-banner-text-10 "
            style={{ color: "var(--darkbrown)" }}
          >
            <div className="row  banner-listing-2 w-100  ads-all mx-auto">
              <div className="col-md-5 d-flex align-items-center justify-content-center p-lg-5 img-banner-2 display-none-md">
                <img
                  className="w-100 px-md-5"
                  src="/img/illustration/man-with-join-us-sign-for-open-recruitment.svg"
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="col-md-7  d-flex flex-column align-items-md-start align-items-center">
                <div style={{ position: "relative" }}>
                  <p className="titr1 mb-0 mt-3 ">چرا متاایونت؟</p>
                </div>
                <ul className="my-3 flex-column  align-items-start text-start">
                  {/* <li className="my-2" >
                <i className="bi bi-circle-fill fs-6 mx-2" style={{color:'var(--brown)'}}></i> 
                ثبت رایگان کسب و کار
                </li> */}
                  <li className="my-2 titr5" style={{ color: "var(--beige)" }}>
                    {/* <i className="bi bi-circle-fill fs-6 mx-2" style={{color:'var(--brown)'}}></i>  */}
                    برای کارفرماها
                  </li>

                  <li className="my-2">
                    <i
                      className="bi bi-circle-fill fs-6 mx-2"
                      style={{ color: "var(--brown)" }}
                    ></i>
                    جذب نیروی کار
                  </li>
                  <li className="my-2">
                    <i
                      className="bi bi-circle-fill fs-6 mx-2"
                      style={{ color: "var(--brown)" }}
                    ></i>
                    ثبت نام آسان، سریع و رایگان
                  </li>
                  <li className="my-2">
                    <i
                      className="bi bi-circle-fill fs-6 mx-2"
                      style={{ color: "var(--brown)" }}
                    ></i>
                    فیلترهای پیشرفته و جستجوی قدرتمند
                  </li>

                  <li
                    className="my-2 mt-3 titr5"
                    style={{ color: "var(--beige)" }}
                  >
                    {/* <i className="bi bi-circle-fill fs-6 mx-2" style={{color:'var(--brown)'}}></i>  */}
                    برای جویندگان کار
                  </li>
                  <li className="my-2">
                    <i
                      className="bi bi-circle-fill fs-6 mx-2"
                      style={{ color: "var(--brown)" }}
                    ></i>
                    ثبت نام آسان، سریع، امن و رایگان
                  </li>
                  <li className="my-2">
                    <i
                      className="bi bi-circle-fill fs-6 mx-2"
                      style={{ color: "var(--brown)" }}
                    ></i>
                    دسترسی مخاطبان بیشتر
                  </li>
                  <li className="my-2">
                    <i
                      className="bi bi-circle-fill fs-6 mx-2"
                      style={{ color: "var(--brown)" }}
                    ></i>
                    فرصت‌ همکاری با برندها و کسب‌وکارها
                  </li>

                  <li className="my-2">
                    <i
                      className="bi bi-circle-fill fs-6 mx-2"
                      style={{ color: "var(--brown)" }}
                    ></i>
                    فرصت‌ شبکه‌سازی
                  </li>
                </ul>
                <div
                  className="btn primary-btn view-cart cart-footer w-75 mx-md-5 mx-0 mb-5"
                  onClick={(e) => {
                    if (!window.logined(9999, "event")) {
                      return window.navigateHandle(
                        `/${window.lang}/event/register`
                      );
                    }
                    window.scroll({ top: 0, behavior: "smooth" });
                    navigateHandle(
                      `/${window.lang}/dashboard/user/event/register`
                    );
                  }}
                >
                  <i className="bi bi-pencil-square p-1"></i>
                  افزودن فرصت شغلی
                </div>
                {/* <!-- </button> --> */}
              </div>
            </div>
          </div>
          <div className="mx-md-5">
            <div className="divider2 ">
               
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
                {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
              </svg>
              <span className="p-0 mb-0 ">برگزیدگان</span>
            </div>
            <BusCardSliderS1 plansS1={pubData?.ads?.plansS1} page={page} />
            <div className="divider2 ">
               
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
              </svg>
              <span className="p-0 mb-0 ">معرفی ویژه</span>
            </div>

            <PlansX4 plansX4={pubData.ads?.plansX4} page={page} />
          </div>
        </div>

        <div className="mx-md-5  ">
          {/* <!-- تازه‌های جابز --> */}
          <div className="mx-md-5">
            <div className="divider2 ">
               
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
                {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
              </svg>
              <span className="p-0 mb-0 "> تازه‌های کاریابی</span>
            </div>
            <HorizontalSlider data={pubData?.newJobs} section="event" />
          </div>
          {/* <!-- تازه‌های جابز end of --> */}

          {/* <!-- تازه‌های بلاگ --> */}
          <div className="mx-md-5">
            <div className="divider2 ">
               
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
                {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
              </svg>
              <span className="p-0 mb-0 ">تازه‌های بلاگ</span>
            </div>

            <HorizontalSlider data={pubData?.newPosts} section="blog" />
          </div>
          {/* <!-- تازه‌های بلاگ end of --> */}
        </div>

        {/* <!-- ********** back to top************************* --> */}
        <div className="row backtotop">
          <div className="col-12">
            <a
              href="#top"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="بازگشت به آغاز صفحه"
              className="tooltip-color"
            >
              <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
            </a>
          </div>
        </div>
      </div>
      {/* <!-- *********************************** end of main ******************************************************************** --> */}

      {/* <!-- ***JavaScript*** --> */}

      {/* <div>
       
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
          crossOrigin="anonymous"
        ></script>

      
      </div> */}
    </div>
  );
}
