import { useEffect, useState } from "react";
import renderData from "../../../utils/renderData";
import tools from "../../../utils/tools";

export default function PlansX4({ plansX4, page }) {
  // console.log(plansX4);
  const [data, setData] = useState([]);
  const { lang, state:{state,stateShort}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
 
  function plansX4ReadyToSh({ size }) {
    const plan = plansX4?.[size]?.filter(
      (plan) =>
        plan.pages?.includes(page) &&
        plan.isPaid    &&
        plan.targetAddress.some(
          (target) =>
            target.countryShort === countryShort?.toLowerCase() 
          // && target.states.some((state0) => state0 === state.toLowerCase())
        )
    );
    return plan;
  }

  useEffect(() => {
    const plansLg = plansX4ReadyToSh({ size: "plansLg" })||[];
    const plansMd = plansX4ReadyToSh({ size: "plansMd" })||[];
    const plansSm = plansX4ReadyToSh({ size: "plansSm" })||[];
    const combinedArray = combineArrays(plansLg, plansMd, plansSm);
    setData(combinedArray);
    // console.log(combinedArray);
  }, [plansX4]);

  function combineArrays(array1, array2, array3) {
    const maxLength = Math.max(
      array1?.length,
      array2?.length,
      Math.ceil(array3?.length / 2)
    )||1;

    const combinedArray = [];
    let counterLg = 0;
    let counterMd = 0;
    for (let i = 0; i < maxLength; i++) {
      const item1 = array1[i] || getNextPlanLg();
      const item2 = array2[i] || getNextPlanMd();
      const item3 = array3[i * 2] || getNextPlanLg();
      const item4 = array3[i * 2 + 1] || getNextPlanLg();
      combinedArray.push([item1, item2, item3, item4]);
    }
    function getNextPlanLg() {
      const nextPlan = renderData.plansDef.plansLg[counterLg];
      counterLg = (counterLg + 1) % renderData.plansDef.plansLg.length;
      return nextPlan;
    }
    function getNextPlanMd() {
      const nextPlan = renderData.plansDef.plansMd[counterMd];
      counterMd = (counterMd + 1) % renderData.plansDef.plansMd.length;
      return nextPlan;
    }
    return combinedArray;
  }

  return (
    <div className="mx-auto px-md-5 px-0 special-carousel pb-0 ads-all  ">
      <div
        id="carouselExampleInterval-special"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {data.map((da, i) => (
            <div  key={'plansX4Slideerab'+i} > 
            <PlansX4Slide data={da} index={i} />
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleInterval-special"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleInterval-special"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      {/* <!-- معرفی ویژه end of --> */}
    </div>
  );
}

function PlansX4Slide({ data, index }) {
  // console.log('PlansX4Slide',index); 
  return (
    <div
      className={`carousel-item ${index === 0 && "active"}`}
      data-bs-interval="7000"
    >
      <div className=" d-block w-100">
        <div className="row m-3 g-3">
          {/* Lg1 */}
          <PlansLgRender data={data[0]} />
          {/* Lg2 */}
          <div className="col-lg-6 ">
            <div className="row g-3">
              {/* Sm00 */}
              <PlansSmRender data={data[2]} />
              {/* Sm01 */}
              <PlansSmRender data={data[3]} />
              {/* Md */}
              <PlansMdRender data={data[1]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  function PlansLgRender({ data }) {
    return (
      <div key={'PlansLgRender'+data.title} className="col-lg-6  ">
        <div className="card bg-dark text-white card1 ad-image container-ad-sq-img-slide ">
          <a href={data?.url}>
            <img
              src={data?.img}
              className="card-img card-img1 w-100 ad-image ad-image1 planx4-1 "
              alt={data?.title}
              loading="lazy"
            />
            <div className="card-img-overlay  card-img-overlay-unclaimed ">
              <div>
                {/* <h5 className="card-title card-titel1 mt-3">
                    نمونه کارت
                  </h5> */}

                <p className="card-text overlay-text">{data?.description}</p>

                <p className="card-text">
                  {data?.title}
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    );
  }
  function PlansMdRender({ data }) {
    return (
      <div key={'PlansMdRender'+data.title} className="col-12 mt-2">
        <div className="card bg-dark text-white card1 container-ad-2to1-img-slide ">
          <a href={data?.url}>
            <img
              src={data?.img}
              className="card-img card-img1 w-100 ad-image ad-image2"
              alt={data?.title}
              loading="lazy"
            />
            <div className="card-img-overlay  card-img-overlay-unclaimed ">
              <div>
                {/* <h5 className="card-title card-titel1 mt-3">
                      نمونه کارت
                    </h5> */}

                <p className="card-text overlay-text">{data?.description}</p>

                <p className="card-text">{data?.title}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    );
  }
  function PlansSmRender({ data }) {
    return (
      <div key={'PlansSmRender'+data.title} className="col-6">
        <div className="card bg-dark text-white card1 container-ad-sq-img-slide">
          <a href={data?.url}>
            <img
              src={data?.img}
              className="card-img card-img1 w-100 ad-image ad-image2"
              alt={data?.title}
              loading="lazy"
            />
            <div className="card-img-overlay  card-img-overlay-unclaimed ">
              <div>
                {/* <h5 className="card-title card-titel1 mt-3">
                          نمونه کارت
                        </h5> */}

                <p className="card-text overlay-text">{data?.description}</p>

                <p className="card-text">{data?.title}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    );
  }
}
