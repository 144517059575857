import React, { useEffect, useState } from 'react'
import api from '../../utils/api';
import FormikErrRender from '../persian/common/alerts/formikErrRender';
import { CircularProgress } from '@mui/material';

export default function CatsSelector({formik,section}) {
const[loading,setLoading]=useState({cats:false})
const [categories, setCategories] = useState({
  categories: [],
  subCategories: [],
});
// console.log('categories',categories)
    function mainCatHandle(e) {
        if(!e.target.value.length)return;
            const category = categories.categories.find(
          (category) => category._id === e.target.value
        );
        setCategories((prevState) => ({
          ...prevState,
          subCategories: category?.subCat,
        }));
      
        formik.setValues((prev) => ({
          ...prev,
          category: {     
            mainCat:{ ...category?.mainCat,_id: category?._id,mainLogo:category?.mainLogo},
            subCat: {_id: ''},        
          },
        }));
      }
      function subCatHandle(e) {    
        if(!e.target.value.length)return;
            const category = categories.categories.find(
          (category) => category._id === formik.values.category.mainCat._id
        );  
        const selectedSubCat = category.subCat.find(sCat=> sCat._id ===e.target.value )
        formik.setValues((prev) => ({
          ...prev,
          category: {    
            mainCat:prev.category.mainCat,  
            subCat: selectedSubCat,        
          },
        }));
      }

      useEffect(()=>{
        fetchCategories()

      },[])

      async function fetchCategories() {
        if (categories.categories.length) return;
        setLoading((pre) => ({ ...pre, cats: true }));
        const response = await api.getCategories({ section });
        setLoading((pre) => ({ ...pre, cats: false }));
        if (response.error) return;
        setCategories((pre) => ({ ...pre, categories: response.data }));
      }

      const trans={
        select:{fa:"انتخاب کنید:",en:"Select:"},
        mainCat:{fa:"دسته بندی اصلی",en:"Main Category"},
        subCat:{fa:"دسته بندی فرعی",en:"Sub Category"},
      }


  return (
    <div>
        <div className='mt-3' >
                        <span className="titr6 required m-0">
                      {trans.mainCat[window.lang]}
                          {loading.cats && (
                            <CircularProgress color="inherit" size="20px" />
                          )}
                        </span>
                        <br />
                        <select                        
                          className="login-btn  selectpicker shadow-none w-100"
                          aria-label="Default select example"
                          name='mainCatSel001'
                          value={formik.values?.category?.mainCat?._id}                          
                          onChange={mainCatHandle}
                        >
                          <option>{trans.select[window.lang]}</option>

                          {categories?.categories?.map((category) => (                         
                            <option
                              key={category._id}
                              value={category?._id}
                            >
                              {category?.mainCat?.[window.lang]}
                            </option>
                          ))}
                        </select>
                        <FormikErrRender
                          formik={formik}
                          field="category.mainCat._id"
                        />
        </div>
        <br />
        {!(section==="media"||section==="tutor")&&formik.values?.category?.mainCat?._id?.length > 0 &&
        <div className="mb-3">
          <span className="form-label required m-0">
          {trans.subCat[window.lang]}
          </span>
          <br />
          <select
          name="subCatSel002"
            className="login-btn  selectpicker shadow-none w-100"
            aria-label="Default select example"
            value={formik.values?.category?.subCat?._id} 
            onChange={subCatHandle}                          
          >
            <option selected> {formik.values?.category?.subCat?.[window.lang] || trans.select[window.lang]}</option>
            {categories?.subCategories?.map(
              (subCat, i) =>                              
                  <option key={i} value={subCat._id}>
                    {subCat[window.lang]}                                   
                  </option>
                )
            }
          </select>
          <FormikErrRender
            formik={formik}
            field="category.subCat._id"
          />
        </div>}
    </div>
  )
}
