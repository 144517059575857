import React, { useContext, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import api from "../../../../utils/api";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
import ShareSocialMedias from "../../business/components/shareSocialMedias";
import tools from "../../../../utils/tools";

export default function CenterCard({ data }) {
  // console.log(props);
  const messageRef = useRef(null);
  const [spiner, setSpiner] = useState(false);
  const { signedUser, user } = useContext(CartContext);
  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const el = e.currentTarget;
    el.setAttribute("disabled", true);
    setSpiner(true);
    const response = await api.saveHandler({
      id: data._id,
      section: "center",
      token: user.token,
    });
    setSpiner(false);
    setTimeout(() => el.removeAttribute("disabled"), 1000 * 2);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>عملیات ذخیره ناموفق!</div>
            <div> دوباره وارد شوید.</div>
          </div>
        ),
      });
    signedUser(response.data);

    !user?.saved?.center?.includes(data?._id)
      ? messageRef.current.showSuccess({
          message: "مرکز آموزشی با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "مرکز آموزشی از لیست ذخیره حذف شد.",
        });
  };

  return (
    <div key={data._id} className="col px-3 ">
      <ToastNote messageRef={messageRef} />
      <div className="card entry p-md-2 p-1 mb-0 ">
        <div className=" d-flex justify-content-between ">
          <div className="picture-container3">
            <div className=" picture picture3">
              <img
                src={data?.moreInfo?.logo}
                className="picture-src"
                id="wizardPicturePreview"
                title={data?.center}
                onClick={(e) =>
                  window.navigateHandle(     
                    `/${window.lang}/demy/center/page/${tools.slugMaker({title:data?.center,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`
                  )
                }
              />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-end flex-grow-1 px-1">
            <div className="fw-bold font-16 text-end cursor">
              <div
                onClick={(e) =>
                  window.navigateHandle(
                    `/${window.lang}/demy/center/page/${tools.slugMaker({title:data?.center,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`
                  )
                }
              >
                {data?.center}
              </div>
            </div>
            <div className="row">
              <div className="col-8 med-gray-font">
                <div>
                  {window.capitalize(data?.moreInfo?.address?.state)}{" "}
                  {user?.roles?.includes(1000) && (
                    <span className="review-text text-center">
                      (
                      <i
                        className="bi bi-eye Business-cover-icon px-1  "
                        title="بازدید"
                      ></i>
                      {data?.feedbackInfo?.views} )
                    </span>
                  )}
                </div>
                <div> {window.capitalize(data?.moreInfo?.address?.city)}</div>
              </div>
              <div className="col-4 card-footer  d-flex flex-wrap  justify-content-end">
                <div className="d-flex  justify-content-between">
                  <div>
                    <button
                      id={`saveBtn${data._id}`}
                      className="icon-btn icon-stack"
                      onClick={saveHandler}
                    >
                      {spiner ? (
                        <CircularProgress color="inherit" size="20px" />
                      ) : (
                        <i
                          className={
                            user.saved?.center?.includes(data._id)
                              ? "bi bi-bookmark-fill icon-stack-1x"
                              : "bi bi-bookmark icon-stack-1x"
                          }
                          title="ذخیره"
                          style={{ color: "rgba(123, 99, 90, 0.894)" }}
                        ></i>
                      )}
                    </button>
                  </div>

                  <div>
                    <button
                      className=" icon-stack mx-1 "
                      data-bs-toggle="modal"
                      data-bs-target={`#myModel${data._id}`}
                      id="shareBtn"
                      data-bs-placement="top"
                      title="Share!"
                    >
                      <i className="bi bi-share" title="همرسانی"></i>
                    </button>
                  </div>

                  <ShareSocialMedias
                    url={`${window.location.origin}/${window.lang}/demy/center/page/${tools.slugMaker({title:data?.center,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`}
                    title={data.center}
                    img={data?.moreInfo.logo}
                    _id={data?._id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- end of card footer --> */}
      </div>
    </div>
  );
}
