// import { useState } from "react";
import {useNavigate } from "react-router-dom";
// import BusCardSliderS1 from "../../golbal/sliders/busCardSliderS1";
// import PlansX4 from "../../golbal/sliders/plansX4";
// import api from "../../../utils/api";
// import HorizontalSlider from "../../golbal/sliders/horizontalSlider";
// import ColoredSpinner from "../../alret/spiners/coloredSpiner/coloredSpinner";
// import tools from "../../../utils/tools";
// import renderData from "../../../utils/renderData";
import MetaDecorator from "../../../utils/metaDecorator";
// import renderData from "../../../utils/renderData";
import { useContext } from "react";
import { CartContext } from "../../contexts/cartContext";
// import demyData from "../../../utils/demyData";

export default function DemyHome() {
  // const [pubData] = useOutletContext();
  const {  user } = useContext(CartContext);

  // console.log("pubData", pubData);
  // const [data, setData] = useState({ center: "", program: "", level: "" });
  // console.log('demy home data' ,data);
  // const [popCat, setPopCat] = useState([]);
  // console.log("pubData", pubData);
  const navigate = useNavigate();
  const page = "demyHome";
  // const { lang, state:{state,stateShort,lat,lng}, country, countryShort } =
  // tools.getItemFromLocalStorage("initSetup", {});

  function navigateHandle(url, section) {
    window.scroll(0, 0);
    setTimeout(() => navigate(url), 200);
    const storedFilters = JSON.parse(localStorage.getItem("storedFilters"));
    if (storedFilters && storedFilters[section]) {
      delete storedFilters[section];
      localStorage.setItem("storedFilters", JSON.stringify(storedFilters));
    }
  }

  // const { translations } =
  //   JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = `متادمی | آکادمی متامارس `;
  const description = `دسترسی به اساتید متخصص و متعهد در سراسر دنیا، صد در صد تضمین کیفیت و قیمت، پرداخت با کردیت کارت و پی پال`;
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name:"متادمی" ,
      image: "https://www.metamarce.com/img/Metademy/Metademy-green-red2.jpg",
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/demy`,
              name: `متادمی `,
              description: `بهترین مدرسین خصوصی`,
            },
          },
        ],
      },
      // about: {
      //   "@type": "Place",
      //   name: state?.toUpperCase(),
      //   address: {
      //     "@type": "PostalAddress",
      //     // "addressLocality": "City",
      //     addressRegion: state?.toUpperCase(),
      //     // "postalCode": "Postal Code",
      //     addressCountry: country?.toUpperCase(),
      //   },
      //   geo: {
      //     "@type": "GeoCoordinates",
      //     latitude: lat, // Replace with the actual latitude
      //     longitude: lng, // Replace with the actual longitude
      //   },
      // },
    },
  ];

  const metaTagsData = {
    title,
    description,
    section: "metademy",
    url: `https://www.metamarce.com/${window.lang}/demy`,
    canonical: `https://www.metamarce.com/${window.lang}/demy`,
    img: "https://www.metamarce.com/img/Metademy/Metademy-green-red2.jpg",
    type: "webpage",
  };

  return (
    <div className="metademy-page">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      {/* <nav className="p-md-3 p-2 bg-beigeYellow" aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item">
      <Link to={`/${window.lang}`}>
        خانه
      </Link>
    </li>
    <li className="breadcrumb-item">
      <strong> متادمی</strong>
    </li>
  </ol>
</nav> */}

      <div className="demy-fp mt-0 text-center w-100 display-none-md">
        <img
          className="w-100 max-w-m mx-auto px-5 pb-5 "
          src="/img/Metademy/Metademy-green-red2.jpg"
          alt="متادمی دسکتاپ"
        />
      </div>
      <div className="demy-fp text-center w-100 display-tablet ">
        <img
          className="w-100 max-w-1000 mx-auto px-1 pb-5 "
          src="/img/Metademy/Metademy-mobile-green-red2.jpg"
          alt="متادمی موبایل"
        />
      </div>
      {/* sections */}
      <div className="text-center max-w-1000 mx-auto my-5">
        <div className="row px-3 py-md-5">
          <div className="col px-0 ">
            {" "}
            <img
              className="w-100 demy-cat "
              src="/img/Metademy/1.jpg"
              onClick={(e) => navigateHandle("tutor/filters", "tutor")}
              alt="tutor/filters"
            />
          </div>
          <div className="col px-1 ">
            {" "}
            <img
              className="w-100 demy-cat "
              src="/img/Metademy/2.jpg"
              onClick={(e) => navigateHandle(`tutor/home`, "tutorHome")}
              alt="tutor/Home"
            />
          </div>
          <div className="col px-0">
            {" "}
            {/* <a href='/en/demy/center/filters' target="_blank"> */}
            <img
              className="w-100 demy-cat "
              src="/img/Metademy/3.jpg"
              onClick={(e) => navigateHandle("/fa/demy/center/filters", "center")}
              alt="center/filters"
            />
             {/* </a> */}
          </div>
          
          {user?.roles?.includes(1000)?<div className="col px-0"
          
          >
            {" "}
            
            {/* <a href='/fa/demy/program/filters' target="_blank"> */}
            <img
              className="w-100 demy-cat "
              src="https://img.freepik.com/free-photo/city-committed-education-collage-concept_23-2150062207.jpg?ga=GA1.1.1592690541.1727963591&semt=ais_hybrid"
              onClick={(e) => navigateHandle('/en/demy/program/filters','program')}
              alt="program/filters"
            />
            {/* </a> */}
          </div>:""}
        
          {/* <div className="col "> <img className="w-100 demy-cat " src="/img/Metademy/4.png"  onClick={e=>navigateHandle('program/filters',"program")} alt="program/filters" />
        </div> */}
        </div>

        {/* <img className="demy-cat mx-3" src="/img/Metademy/2.png" alt="" />
          <img className="demy-cat mx-3" src="/img/Metademy/3.png" alt="" />
          <img className="demy-cat mx-3" src="/img/Metademy/4.png" alt="" /> */}
      </div>

      <div className="tutor-frame max-w-m  mx-auto my-7">
        <ul className="max-w-1000 mx-auto">
          <li className="row   banner-listing-2 w-100  ads-all mx-auto">
            <div className="col-md-4  d-flex align-items-center justify-content-center px-5  img-banner-2 ">
              <img
                className="w-100  rounded-2"
                src="/img/Metademy/67.jpg"
                // src="/img/Metademy/Metademy-students.png"
                alt="دانشجو یا دانش‌آموز هستی"
              />
            </div>
            <div className="col-md-8 px-md-5  pb-5 titr7 titr7-25 d-flex  align-items-center justify-content-center text-gray-green ">
              دانشجو یا دانش‌آموز هستی و
              <br /> تو درس‌هات به مشکل برخورد کردی؟
            </div>
          </li>
          <li className="row   banner-listing-2 w-100  ads-all mx-auto">
            <div className="col-md-4  order-md-2 d-flex align-items-center justify-content-center px-5  img-banner-2 ">
              <img
                className="w-100  rounded-2"
                src="/img/Metademy/156.jpg"
                // src="/img/Metademy/Metademy-students.png"
                alt="پدر یا مادر هستی"
              />
            </div>
            <div className="col-md-8 px-md-5 pb-5 titr7 titr7-25 d-flex align-items-center justify-content-center text-gray-green">
              پدر یا مادر هستی و به شخصی نیاز داری
              <br />
              که بتونه تو درس‌های فرزندت بهش کمک کنه؟
            </div>
          </li>
          <li className="row   banner-listing-2 w-100  ads-all mx-auto">
            <div className="col-md-4  d-flex align-items-center justify-content-center px-5  img-banner-2 ">
              <img
                className="w-100  rounded-2"
                src="/img/Metademy/152.jpg"
                // src="/img/Metademy/Metademy-students.png"
                alt="تصمیم داری یک هنر یا مهارت"
              />
            </div>
            <div className="col-md-8 px-md-5  pb-5 titr7 titr7-25 d-flex  align-items-center justify-content-center text-gray-green">
              تصمیم داری یک هنر یا مهارت جدید یاد بگیری؟
            </div>
          </li>
        </ul>

        <div className="px-md-5 py-md-5 p-3 max-w-m   rounded-5 bg-greenGray mx-3 my-5 ">
          <div className="text-center  w-100 titr7 titr7-25 text-beigeYellow  ">
            متادمی این فرصت رو براتون فراهم کرده تا از بین صدها استاد در سراسر
            دنیا، بهترین‌ها رو انتخاب کنید و به دانش و مهارتتون اضافه کنید.
          </div>
          {/* <div
              className="btn primary-btn  tutor-btn font-20 bg-greenGray"
              onClick={(e) =>
                navigateHandle(`/${window.lang}/demy/registertutor`)
              }
            >
              <i className="bi bi-file-text p-1 font-20">   راهنما و قوانین متادمی</i>
          
           

          
        </div> */}
        </div>
      </div>

      <div className="tutor-frame  max-w-m mx-auto my-7">
        <div className="row   banner-listing-2 w-100  ads-all mx-auto">
          <div className="col-md-7 img-banner-text-21 d-flex flex-column align-items-md-start align-items-center justify-content-end ">
            <ul className="mx-md-5 my-3 px-1 flex-column text-start text-green-dark align-items-start">
              <li className="titr1 text-pink-orange mb-4">چرا متادمی؟</li>
              <li className="my-2">
                <i className="bi bi-circle-fill fs-6 mx-2"></i>
                دسترسی به بهترین اساتید از سراسر دنیا
              </li>
              <li className="my-2">
                <i className="bi bi-circle-fill fs-6 mx-2"></i>
                انتخاب استاد مناسب با کمک فیلتر
              </li>

              <li className="my-2">
                <i className="bi bi-circle-fill fs-6 mx-2"></i>
                بازگشت تمام وجه کلاس در صورت برگزار نشدن
              </li>

              <li className="my-2">
                <i className="bi bi-circle-fill fs-6 mx-2"></i>
                جلسه آزمایشی رایگان
              </li>
              <li className="my-2">
                <i className="bi bi-circle-fill fs-6 mx-2"></i>
                ‌برگزاری کلاس در هر ساعتی از شبانه‌روز
              </li>
              <li className="my-2">
                <i className="bi bi-circle-fill fs-6 mx-2"></i>
                پرداخت شهریه امن
                <span className="font-12 ps-2 ">( کردیت‌کارت یا PayPal)</span>
              </li>
            </ul>
            <div
              className="btn primary-btn  tutor-btn2 w-75 mx-md-5 mx-0 my-5"
              onClick={(e) => navigateHandle(`tutor/filters`)}
            >
              <i className="bi bi-pencil-square p-1"></i>
              جستجوی استاد
            </div>
            {/* <!-- </button> --> */}
          </div>
          <div className="col-md-5 img-banner-text-21 d-flex align-items-center justify-content-center p-5  img-banner-2 ">
            <img
              className="w-100  rounded-2"
              src="/img/Metademy/81.jpg"
              // src="/img/Metademy/Metademy-students.png"
              alt="جستجوی استاد"
            />
          </div>
        </div>
      </div>

      {/* why metademy for tutors */}
      <div className="text-center max-w-1000 mx-auto my-5">
        <div className="titr1 text-pink-orange mb-4">
          متادمی چطور کار می‌کنه؟
        </div>

        <div className="row px-3 py-md-5 g-3 ">
          <div className="col-md-3">
            <div className="card w-100">
              <img
                src="/img/Metademy/teacher.png"
                className="card-img-top w-25 mx-auto"
                alt="متادمی چطور کار می‌کنه"
              />
              <div className="card-body">
                <h5 className="card-title titr5">۱- انتخاب استاد</h5>
                <p className="card-text">
                  با کمک فیلترهای متادمی استادانی که درس موردنظرتون رو تدریس
                  می‌کنند، پیدا و بهترین رو انتخاب کنید.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 ">
            <div className="card w-100">
              <img
                src="/img/Metademy/rescheduling.png"
                className="card-img-top w-25 mx-auto"
                alt="انتخاب روز و ساعت"
              />
              <div className="card-body">
                <h5 className="card-title titr5">۲- انتخاب روز و ساعت</h5>
                <p className="card-text">
                  از بین زمان‌های پیشنهادی استاد در «برنامه کلاسی»، کلاس آزمایشی
                  رایگان یا آموزشی را انتخاب کنید.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card w-100">
              <img
                src="/img/Metademy/payment.png"
                className="card-img-top w-25 mx-auto"
                alt="۳- پرداخت"
              />
              <div className="card-body">
                <h5 className="card-title titr5">۳- پرداخت</h5>
                <p className="card-text">
                  بدون نگرانی از روش پرداخت و تبدیل ارز، هزینه کلاسهای آموزشی رو
                  با کردیت کارت یا پی‌پل بپردازید.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card w-100">
              <img
                src="/img/Metademy/classroom.png"
                className="card-img-top w-25 mx-auto"
                alt=" شرکت در کلاس"
              />
              <div className="card-body">
                <h5 className="card-title titr5">۴- شرکت در کلاس</h5>
                <p className="card-text">
                  در روز و ساعت مشخص شده و با استفاده از لینکی که استاد براتون
                  می‌فرسته در کلاس شرکت کنید.
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          className="btn primary-btn tutor-btn bg-pinkOrange  shadow-none mt-4"
          onClick={(e) => navigateHandle(`tutor/filters`)}
        >
          {" "}
          <i className="bi bi-pencil-square p-1"></i> جستجوی استاد
        </button>
      </div>
      <div className="my-5  mx-auto ">
        <div className="row  tutor-frame banner-listing-2 w-100  max-w-m  mx-auto display-none-md">
          <img
            className="w-100 p-0 "
            src="/img/Metademy/Metademy-green-red-4.jpg"
            alt="استاد"
          />
          {/* </div> */}
        </div>
        <div className="demy-fp text-center w-100 display-tablet ">
          <img
            className="w-100 max-w-1000 mx-auto p-0 "
            src="/img/Metademy/Metademy-mobile-green-red-4.jpg"
            alt="Metademy-mobile-green-red"
          />
        </div>
        <div className="row   banner-listing-2 w-100  max-w-m mx-auto ">
          <div className="col-md-5 img-banner-text-21-5 d-flex align-items-center justify-content-center p-0  img-banner-2 "></div>
          <div className="col-md-7 img-banner-text-21-5 d-flex flex-column align-items-md-start align-items-center p-0 justify-content-end ">
            <div
              className="btn primary-btn  tutor-btn2 w-75 mx-md-5 mx-0 mb-5 mt-0"
              onClick={(e) => navigateHandle(`tutor/filters`)}
            >
              <i className="bi bi-pencil-square p-1"></i>
              جستجوی استاد
            </div>
            {/* <!-- </button> --> */}
          </div>
        </div>
      </div>
      <div className="my-5  mx-auto text-center">
        <div className="row  tutor-frame banner-listing-2 w-100  max-w-m  mx-auto display-none-md">
          {/* <div className="col img-banner-text-21 d-flex align-items-center justify-content-center p-lg-5 img-banner-2 "> */}
          <img
            className="w-100 p-0 "
            src="/img/Metademy/Metademy-green-red-2.jpg"
            alt="Metademy-green-red-2"
          />
          {/* </div> */}
        </div>
        <div className="demy-fp text-center w-100 display-tablet ">
          <img
            className="w-100 max-w-1000 mx-auto p-0 "
            src="/img/Metademy/Metademy-mobile-green-red-2.jpg"
            alt="Metademy-mobile-green-red-2"
          />
        </div>
        <button
          className="btn primary-btn tutor-btn bg-pinkOrange  shadow-none mt-4"
          onClick={(e) => navigateHandle(`tutor/home`)}
        >
          {" "}
          <i className="bi bi-pencil-square p-1"></i> تدریس در متادمی
        </button>
      </div>

      {/* <!-- *********************************** end of main ******************************************************************** --> */}

      {/* <!-- ***JavaScript*** --> */}

      {/* <div>
       
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
          crossOrigin="anonymous"
        ></script>

      
      </div> */}
    </div>
  );
}
