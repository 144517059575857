// import "../../../userList/userList.css";
import { useContext, useEffect, useRef, useState } from "react";
import { CartContext } from "../../../../contexts/cartContext";
import apiDemy from "../../../../../utils/apiDemy";
import ToastNote from "../../../../golbal/notifications/toastNote";
import Tooltip1 from "../../../../golbal/tooltip/tooltip";
import ColoredSpinner from "../../../../alret/spiners/coloredSpiner/coloredSpinner";
// import SearchBarUser from "../profile/transactions/myTrans/searchBarUser";
// import MyIcons from "../../../../golbal/icons/myIcons";
import tools from "../../../../../utils/tools";
import api from "../../../../../utils/api";
import renderData from "../../../../../utils/renderData";


export default function TutorTransList({ access }) {
  const { user,signedUser } = useContext(CartContext);
  const [loading, setLoading] = useState({spinner:false});
  const [user2, setUser2] = useState({ _id: user._id });
  const [selected, setSelected] = useState([]);
  const { countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});

  const [data, setData] = useState([]);
  const [history,setHistory]=useState(false)
  // console.log('user',user);
  console.log('data',data);
  console.log('selected',selected);
  const currentDate =  new Date();   
  currentDate.setDate(1); // Set the day of the month to 1 to get the first day of the current month
  currentDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero
  const messageRef = useRef(null);


  useEffect(() => {
    fetchData();
  }, [history,access]);

  

  async function fetchData() {
    setLoading(pre=>({...pre,spinner:true}))
    const response = await apiDemy.fetchTutorTransList({
      token: user.token,
      data: {
        access: access,
        userId: user?._id,
        history,
        currentDate,
        trans:true
      },
    });
    setLoading(pre=>({...pre,spinner:false}))
    if (response.error)
      return messageRef.current.showError({ message: response.error });     
      setData(response.data);

  }


   const subtotal = (()=>{
    return data.reduce((a,b)=> a+Number(b.sessionInfo.rate*b.sessionInfo.duration*.8),0)
   })()
  return (
    <div className="userList" style={{ height: "100vh", width: "100%" }}>
      <ToastNote messageRef={messageRef} />
      {loading.spinner && <ColoredSpinner />}
      <div  className="panel-tab0 d-flex">
      <div className={` panel-tab2 mx-1 ${history === false ? "active" : ""}`}>
        <label htmlFor="currentRequests">ماه جاری</label>
        <input className="form-check-input ms-1 d-none" name="historicalData" id="currentRequests" type="radio" checked={!history} onChange={e=> setHistory(false)} />
      </div>
      <div className={` panel-tab2 mx-1 ${history ===true? "active" : ""}`}>
        <label htmlFor="historicalRequests">سوابق</label>
        <input className="form-check-input ms-1 d-none" name="historicalData" id="historicalRequests" type="radio" checked={history} onChange={e=> setHistory(true)} />
      </div>
      </div>
      <div>
        <ul className="nav nav-pills mb-3">
      
        </ul>         
        <table className="table" >
    <thead>
      <tr>     
        <th scope="col">دانشجو</th>                        
        <th scope="col">توضیحات</th>       
        <th scope="col"> مبلغ خالص</th>
        {/* <th scope="col">پرداخت</th> */}
      </tr>
    </thead>
    <tbody>
    {data?.map((session , i) =>{
const currentStatus=   session.history?.[0]?.status       
return(

<tr  key={i} >
  <td className="col-md-3">
    <div className="row text-center">
      <div className="">
      
          <div className="row">
            <div className="col-3 px-0">
              <div className="px-0 d-flex justify-content-center align-items-center">
                <img
                  className="img-fluid jobCrad-img2 "
                  src={session?.guest?.img}
                  alt={session?.guest?.userName}
                />
              </div>
            </div>
            <div className="col-9">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <strong>{session?.guest?.userName}</strong>
                <div className=" small-gray-font">
                  <div>
                    {tools.dynamicDate({date:session.sessionInfo?.start,countryShort,lang:window.lang})}
                    
                  </div>
                  <div>
                    {tools.dynamicTime({date:session.sessionInfo?.end,countryShort,lang:window.lang})} - 
                    {tools.dynamicTime({date:session.sessionInfo?.start,countryShort,lang:window.lang})}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
   
      </div>
      
    </div>
  </td>
  <td className="col-md-3">
      <div className=" small-gray-font ">
          {tools.renderTrans({section:'status',key:currentStatus})}
          <div>
            توسط {session.history[0].userName}
          </div>
        </div>
  </td>
  <td className="col-md-3">{tools.showPrice( session.sessionInfo.duration*session.sessionInfo.rate*.8,session?.sessionInfo?.currency)}</td>
  {/* <td className="col-md-3">0</td> */}
</tr>
)
            }
              )}
              <tr>
                    <td colSpan="2" style={{ textAlign: "left" }}>
                      مبلغ کل
                    </td>
                    <td>
                      {/* <strong> {showNumber(calculateTotalPrice())}</strong> */}
                      <strong> {tools.showPrice(subtotal,data[0]?.sessionInfo?.currency)}</strong>
                    </td>
                  </tr>
    </tbody>
  </table>
    
      </div>
      {/* <div>
      <button
      type="button"
      className="btn primary-btn checkout-btn cart-footer border-0 shadow-none img-banner-text-15 py-1 mt-3 "
     onClick={e=>paymentHandler}
     >
      <span>
        <i
          className="bi bi-credit-card mx-2"
          style={{ fontSize: 20 }}
        ></i>
      </span>
      پرداخت 
      <span
        style={{ background: "black", borderRadius: "50%" }}
      >                 
      </span>
      </button>
      </div> */}
    
    </div>
  );
  async function paymentHandler({e}) {
    console.log('selected', selected);
   
    if(window.confirm(`شما در حال پرداخت می باشید. \nاین عملیات غیر قابل بازگشت می باشد.`)!== true) return
    setLoading(pre=>({...pre,spinner:true}))
    const response = await apiDemy.changeSessionStatus({
        token: user.token,
       data:{         
        lang:window.lang, 
        lcs:window.lcs, 
         sessionIds:selected,
         access
      },
    });
    setLoading(pre=>({...pre,spinner:false}))
    if (response.error) return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({ message: `عملیات  موفق` });

    
    
}
}




