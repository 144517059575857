import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import apiStore from "../../../../../../../utils/apiStore";
import MyIcons from "../../../../../../golbal/icons/myIcons";
import ToastNote from "../../../../../../golbal/notifications/toastNote";
import renderData from "../../../../../../../utils/renderData";
import SearchBarUser from "./searchBarUser";
import ColoredSpinner from "../../../../../../alret/spiners/coloredSpiner/coloredSpinner";
import tools from "../../../../../../../utils/tools";
import { CartContext } from "../../../../../../contexts/cartContext";
import MetaDecorator from "../../../../../../../utils/metaDecorator";

export default function AffiliateAnalytics({ access, section }) {
  const { user } = useContext(CartContext);
  const [countryShort, stateShort] = window.location.pathname
    .toLowerCase()
    .split("/")
    .slice(2, 4);

  const navigate = useNavigate();
  const messageRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [user2, setUser2] = useState({ _id: user._id }); 

  const deleteHandle = async (id) => {
    console.log(id);
    if (!id)
      return messageRef.current.showError({ message: "آیدی فراهم نشده است." });
    if (window.confirm("Are you sure to delete this transa") !== true) return;
    const response = await apiStore.deleteRow({
      token: user.token,
      id,
      userId: user._id,
    });
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({ message: "سفارش با موفقیت حذف شد." });
    setData((pre) => {
      return pre.filter((row) => row._id !== id);
    });
  };
  function showPageHandle({ props }) {
    let text = "";
    if (window.lang !== props.lang.toLowerCase())
      text += `شما در حال انتقال به متامارس با زبان ${window.capitalize(
        props.lang
      )} میباشد . \n`;
    if (countryShort.toLowerCase() !== props.address.countryShort.toLowerCase())
      text += `شما در حال انتقال به کشور ${window.capitalize(
        props.address.country
      )} میباشد . \n`;
    // if (stateShort.toLowerCase() !== props.address.stateShort.toLowerCase())
    //   text += `شما در حال انتقال به استان ${window.capitalize(
    //     props.address.state
    //   )} میباشد . \n`;

    if (!text.length)
      return navigate(`/${window.lang}/store/order/${props._id}`);

    if (window.confirm(text) != true) return;
    console.log("Passed");

    navigate(`/${window.lang}/store/order/${props._id}`);
  }

  useEffect(() => {
    fetchData("yearMont");
  }, [user2._id, window.location.pathname]);
  async function fetchData(reportType) {
    setLoading(true);
    const response = await apiStore.fetchAffiliateAnalytics({
      token: user.token,
      id: user._id,
      access,
      section,
      affiliateCode: user?.affiliateInfo?.code,
      reportType,
    });
    setLoading(false);
    console.log(response);
    if (response.error) return alert(response.error);

    setData(response.data);
   
  }

  function showPrice(amount, currency) {
    const cur = currency || "USD";
    const final = (amount || 0).toLocaleString(window.lang, {
      style: "currency",
      currency: cur,
      minimumFractionDigits: cur === "IRR" ? 0 : 2,
      maximumFractionDigits: cur === "IRR" ? 0 : 2,
    });
    return final;
  }

  const columns = [
    //   { field: '_id', headerName: 'ID', width: 70 },

    {
      field: "createdAt",
      headerName: " affiliateAnalytics تاریخ ثبت",
      width: 100,
      renderCell: (params) => (
        <div title={tools.toSolar(params.row?.updatedAt)}>
          {tools.toGreg(params.row?.updatedAt)}
        </div>
      ),
    },
    {
      field: "affiliateInfo.affiliatedby",
      headerName: "کد معرفی",
      width: 150,
      renderCell: (params) => (
        <strong>{params.row.affiliateInfo.affiliatedby}</strong>
      ),
    },
    // {
    //   field: "marketerInfo.refPercent",
    //   headerName: "مارجین بازاریابی",
    //   width: 110,
    //   renderCell: (params) => (
    //     <strong title="">{`% ${
    //       params.row.marketerInfo.refPercent * 100
    //     }`}</strong>
    //   ),
    // },
    // {
    //   field: "marketerInfo.discPercent",
    //   headerName: "تخفیف کاربر",
    //   width: 110,
    //   renderCell: (params) => (
    //     <strong title="">{`% ${
    //       params.row.marketerInfo.discPercent * 100
    //     }`}</strong>
    //   ),
    // },
    {
      field: "isPaid",
      headerName: "تکمیل سفارش",
      width: 100,
      renderCell: (params) => <div>{params.row.isPaid ? "بله" : "خیر"}</div>,
    },
    // {
    //   field: "totalPrice1",
    //   headerName: "مبلغ پرداختی",
    //   width: 100,
    //   renderCell: (params) => <div>{showPrice(
    //     Number(params.row?.paymentResult[0]?.amount?.value),
    //     params.row?.paymentResult?.[0]?.amount?.currency_code
    //   )}</div>,
    // },
    {
      field: "netSales",
      headerName: "فروش پس از تخفیفات",
      width: 100,
      renderCell: (params) => (
        <div>
          {/* {showPrice(
            Number(params.row?.paymentResult?.[0]?.amount?.value) > 0
              ? params.row.itemsPrice - params.row.discAmount
              : 0,
            params?.row?.currency
          )} */}
          {/* {tools.showPrice(params.row?.paymentResult?.[0]?.amount?.value,params.row?.paymentResult?.[0]?.amount.currency_code)} */}
          {tools.showPrice(
            (params.row.itemsPrice - params.row.discAmount) / params.row.exRate,
            "CAD"
          )}
        </div>
      ),
    },
    {
      field: "marketerMargin",
      headerName: "کارمز بازاریابی",
      width: 100,
      renderCell: (params) => (
        <div>
          {/* {showPrice(
            Number(params.row?.paymentResult?.[0]?.amount?.value) > 0
              ? (params.row.itemsPrice - params.row.discAmount) *
                  .05
              : 0,
              params.row?.paymentResult?.[0]?.amount.currency_code
          )} */}
          {/* {tools.showPrice(params.row?.paymentResult?.[0]?.amount?.value*.05,params.row?.paymentResult?.[0]?.amount.currency_code)} */}
          {tools.showPrice(
            ((params.row.itemsPrice - params.row.discAmount) /
              params.row.exRate) *
              0.05,
            "CAD"
          )}
        </div>
      ),
    },
    {
      field: "address.country",
      headerName: "کشور",
      width: 150,
      renderCell: (params) => (
        <div dir="ltr">{`${window.capitalize(
          params.row?.address?.state
        )}, ${window.capitalize(params.row?.address?.country)}`}</div>
      ),
    },
    {
      field: "serviceItems",
      headerName: "نام امکانات",
      width: 200,
      renderCell: (params) => {
        return params.row.serviceItems.map((serv, i) => (
          <span key={i}>
            <span title={serv.title}>
              {
                renderData.plansName.find((plan) => plan.name === serv?.type)?.[
                  window.lang
                ]
              }
            </span>
            -
          </span>
        ));
      },
    },
    // {
    //   field: "totalPrice",
    //   headerName: "مبلغ صورتحساب",
    //   width: 150,
    //   renderCell: (params) => (
    //     <div
    //       title={`مبلغ پرداختی  ${showPrice(
    //         Number(params.row?.paymentResult[0]?.amount?.value),
    //         params.row?.paymentResult[0]?.amount?.currency_code
    //       )}`}
    //     >
    //       {showPrice(params.row?.totalPrice, params.row?.currency)}
    //     </div>
    //   ),
    // },
    access === "admin" && {
      field: "invNumber",
      headerName: "شماره صورتحساب",
      width: 150,
      // renderCell:params=>  <div title={params.row?.paymentResult[0]?.amount?.value}>{params.row?.paymentResult[0]?.amount?.currency_code+params.row?.paymentResult[0]?.amount?.value}</div>
    },
    access === "admin" && {
      field: "action",
      headerName: "عملیات",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="d-flex">
            <div
              title="نمایش"
              className="widgetSmButton"
              onClick={(e) => showPageHandle({ props: params.row })}
            >
              <MyIcons icon="display" />
            </div>
            {!params.row.isPaid && (
              <div
                title="پاک کردن"
                onClick={() => deleteHandle(params.row._id)}
              >
                <MyIcons icon="delete2" />
              </div>
            )}
          </div>
        );
      },
    },
  ];
  // console.log('data',data);
  const metaTagsData = {
    title: "گزارش افیلیشن ",
    description: "مشاهده گزارش عملکرد افیلیشن مارکتینگ ",
    section: "Affiliation",
    url: `https://www.metamarce.com/${window.lang}/dashboard/user/trans/affilate/ana`,   
    img: "https://www.metamarce.com/img/illustration/buy-photography-gear-online.svg",
    type: "webpage",
    index: false,
  };
  return (
    <div className="userList" style={{ height: "80vh", width: "100%" }}>     
      <MetaDecorator data={{ ...metaTagsData }} />
      <ToastNote messageRef={messageRef} />
      {loading && <ColoredSpinner />}
      {/* <ResponsiveContainer width="100%" height={400}>
      <BarChart data={chartData.yearMont} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="yearMonth" />
        <YAxis />
        <Tooltip formatter={(value, name, props) => {
          // console.log('props.payload',props.payload);          
          return[`${props.payload._id.yearMonth} - ${value}`, 'Count']}} />
        <Legend />
        <Bar dataKey="count" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer> */}
    {/* <ResponsiveContainer width="100%" height={400}>
      <LineChart data={chartData.yearMont} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={"_id.yearMonth"} />
        <YAxis />
        <Tooltip formatter={(value, name, props) => [`${props.payload._id.yearMonth}`,`${value}`]} />
        <Legend />
        <Line type="monotone" dataKey="count" stroke="#8884d8" strokeWidth={2} dot={{ r: 5 }} activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer> */}
    <div className="bold-dark-font font-18 mt-3 ">نمودار کاربران با کد معرفی {user?.userName} به ترتیب ماه</div>
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={yearMonthCounts (data)} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={"yearMonth"} />
        <YAxis />
        <Tooltip formatter={(value, name, props) => [`user(s) : ${value} `]} />
        <Legend />
        <Line type="monotone" dataKey="count" stroke="#8884d8" strokeWidth={2} dot={{ r: 5 }} activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
    {/* <ResponsiveContainer width="100%" height={400}>
      <LineChart data={countryCounts (data)} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={"country"} />
        <YAxis />
        <Tooltip formatter={(value, name, props) => [`user(s) : ${value} `]} />
        <Legend />
        <Line type="monotone" dataKey="count" stroke="#8884d8" strokeWidth={2} dot={{ r: 5 }} activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer> */}
    <div className="bold-dark-font font-18 mt-3 ">نمودار کاربران با کد معرفی {user?.userName} بر اساس کشور</div>

    <ResponsiveContainer width="100%" height={300}>
    <BarChart
      data={countryCounts (data)}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="country" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="count" stackId="a" fill="#8884d8" /> 
    </BarChart>
  </ResponsiveContainer>
      {/* {!data.length ? (
        <div>
          <div>
            داده ای پیدا نشد. پس از اطمینان از وجود داده، دوباره تلاش کنید.
          </div>
    
        </div>
      ) : (
        <DataGrid
          getRowId={(row) => row._id}
          rows={data}
          components={{
            Toolbar: GridToolbar,
          }}
          disableSelectionOnClick
          columns={columns}
          pageSize={[15]}
          rowsPerPageOptions={[1]}
          checkboxSelection
        />
      )} */}
      
      {(access === "admin"||user?.roles?.includes(1000)) && (
        <SearchBarUser setUser2={setUser2} user2={user2} />
      )}
    </div>
  );
}

function countryCounts (data){

 console.log('data',data);
  
  const countryCounts = {};
// Process the raw data
data.forEach((item) => {
  const lcs = item._id.lcs; // Assuming 'lcs' contains the country code
  const count = item.count;

  // Extract the country code from 'lcs' (e.g., 'ca' from 'fa/ca/on')
  const country = lcs.split('/')[1];

  // Update or initialize the count for the country
  if (countryCounts[country]) {
    countryCounts[country] += count;
  } else {
    countryCounts[country] = count;
  }
});

// Convert the country counts object to an array of objects
const result = Object.keys(countryCounts).map((country) => ({
  country,
  count: countryCounts[country],
}));
return result
}
function yearMonthCounts (data){

 console.log('data',data);
  
  const yearMonthCount = {};
// Process the raw data
data.forEach((item) => {
  const yearMonth = item._id.yearMonth; // Assuming 'lcs' contains the country code
  const count = item.count;

  // Extract the country code from 'lcs' (e.g., 'ca' from 'fa/ca/on')
  // const country = lcs.split('/')[1];

  // Update or initialize the count for the country
  if (yearMonthCount[yearMonth]) {
    yearMonthCount[yearMonth] += count;
  } else {
    yearMonthCount[yearMonth] = count;
  }
});

// Convert the yearMonth counts object to an array of objects
const result = Object.keys(yearMonthCount).map((yearMonth) => ({
  yearMonth,
  count: yearMonthCount[yearMonth],
}));
console.log('result',result);

return result
}
