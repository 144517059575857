import { NavLink } from "react-router-dom";

export default function UserDashTransAffNav() {
  const trans={   
    analytics:{fa:"آنالیز",en:"Analytics"},    
    refferal:{fa:"گزارش لینک معرفی",en:"Refferal list"},
  }
  return (
    <div className="dashboardNavigator d-flex flex-wrap justify-content-md-start justify-content-around p-3">
           <NavLink
        to={`/${window.lang}/dashboard/user/trans/affilate/ana`}
        className={`sidebarListItem m-md-3 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
 {trans.analytics[window.lang]}      
 </NavLink>
      <NavLink
        to={`/${window.lang}/dashboard/user/trans/affilate/list`}
        className={`sidebarListItem m-md-3 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
 {trans.refferal[window.lang]}      
 </NavLink>

    </div>
  );
}
