import renderData from "../../../utils/renderData";
import MetaDecorator from "../../../utils/metaDecorator";

export default function BusTerms() {
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "درباره متامارس",
      description:
        "یک پلتفرم آنلاین پویا که برای جامعه پارسی‌زبان در سراسر جهان طراحی شده ...",

      url: "https://www.metamarce.com/fa/business/terms",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/fa/business/terms`,
              name: "متامارس | متامارس | قوانین افزودن کسب و کار در بانک مشاغل متامارس",
              description:
                " قوانین متامارس جهت ثبت رایگان کسب و کار |قوانین متامارس جهت معرفی رایگان مشاغل ",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: "متامارس | متامارس | قوانین افزودن کسب و کار در بانک مشاغل متامارس",
    description:
      " قوانین متامارس جهت ثبت رایگان کسب و کار |قوانین متامارس جهت معرفی رایگان مشاغل ",
    section: "قوانین کسب و کار",
    url: `https://www.metamarce.com/fa/business/terms`,
    img: "https://www.metamarce.com/img/illustration/pair-programming-animate.svg",
    type: "webpage",
  };

  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <div className="d-flex flex-column justify-content-center align-items-center mx-md-5 mx-2">
        <h1 className="titr1 font-40 my-5">
          قوانین متامارس برای افزودن کسب و کار
        </h1>

        <ul className="flex-column">
          <li>
            <h2 className="titr7 text-start mt-3 mt-md-4">
              ۱.واجدین شرایط و پایبندی به قوانین
            </h2>
            برای ثبت و اداره کسب و کار در متامارس، شما باید تمامی الزامات قانونی
            مرتبط را برآورده و به آنها پایبند باشید. ما احتمال انجام بررسی و
            احراز صلاحیت شما را داریم و ممکن است از شما درخواست احراز هویت کنیم.
          </li>

          <li>
            <h2 className="titr7 text-start mt-3 mt-md-4">
              ۲. فعالیت‌های ممنوعه
            </h2>
            کسب و کارهای موجود در متامارس ممنوع از شرکت در هر نوع فعالیت جنایی،
            غیرقانونی، مضر یا غیراخلاقی هستند، از جمله ولی محدود نیست به موارد
            زیر:
            <ul className="flex-column">
              <li>فروش یا تبلیغ مواد مخدر غیرقانونی یا مواد افیونی.</li>
              <li>
                ترویج یا شرکت در سوءاستفاده از کودکان، استفاده از کودکان یا
                صدمه‌رسانی یا هر نوع آسیب به کودکان.
              </li>
              <li>فروش یا تبلیغ محتوای بزرگ‌سالان و خدمات جنسی .</li>
              <li>هر نوع سخنان تعریضی، تبعیض، آزار یا محتوای مضر.</li>
              <li>شرکت در تقلب یا رفتارهای گمراه کننده.</li>
              <li> تبلیغ و ترویج فعالیت شرکت های هرمی.</li>
            </ul>
          </li>

          <li>
            <h2 className="titr7 text-start mt-3 mt-md-4">۳. تدابیر ایمنی</h2>
            هرچند ما تلاش می‌کنیم تا امنیت پلتفرم خود را تامین کنیم، اما توصیه
            می‌شود که کسب‌وکارها هنگام تعامل با دیگر کاربران، احتیاط لازم را
            انجام دهند، بررسی درستی ادعاهای سایر کاربران بر عهده کسب و کار می
            باشد
          </li>

          <li>
            <h2 className="titr7 text-start mt-3 mt-md-4">
              ۴. گزارش‌دهی و پایبندی
            </h2>
            کاربران ما همواره تشویق می‌شوند که هر نوع تخلف یا فعالیت مشکوکی را
            گزارش دهند. ما گزارش‌ها را بررسی و در صورت نیاز اقدامات لازم را
            انجام می‌دهیم که ممکن است شامل تعلیق یا حذف کسب‌وکارهای متخلف شود.
          </li>
          <li>
            <h2 className="titr7 text-start mt-3 mt-md-4">۵. صحت اطلاعات</h2>
            کسب و کارها در هنگام تکمیل فرم ثبت نام، بایستی اطلاعات خواسته شده را
            بصورت درست و واقعی ثبت نمایند و در صورت بروز تغییرات پروفایل خود را
            به روز نگه دارند.
          </li>
        </ul>

        <div
          className="btn primary-btn btn-404 checkout-btn cart-footer mb-5 mt-2"
          onClick={(e) => window.close()}
        >
          بستن
        </div>
      </div>
    </div>
  );
}
