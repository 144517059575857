import renderData from "../../../../utils/renderData";
import MetaDecorator from "../../../../utils/metaDecorator";
import { CartContext } from "../../../contexts/cartContext";
import { useContext } from "react";

export default function Affiliation() {
  const { user } = useContext(CartContext);
  const structuredData = [
     {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "لینک معرفی اختصاصی | affiliation",
      description:
        "یک پلتفرم آنلاین پویا که برای جامعه پارسی‌زبان در سراسر جهان طراحی شده ...",

      url: "https://www.metamarce.com/fa/prompt/affiliation",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/prompt/affiliation`,
              name: `همکاری در معرفی`,
              description: "افیلیشن متامارس، کسب و درآمد در متامارس از طریق لینک معرفی اختصاصی ",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: "لینک معرفی اختصاصی | affiliation ",
    description: "کسب و درآمد در متامارس از طریق لینک معرفی اختصاصی ",
    section: "affiliation",
    url: `https://www.metamarce.com/${window.lang}/prompt/affiliation`,
    // canonical: `https://www.metamarce.com/${window.lang}/prompt/affiliation`,
    img: "https://www.metamarce.com/img/illustration/pair-programming-animate.svg",
    type: "webpage",
  };
  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <div className="my-header m-0">
        <div className="bg-image fixed-bg-media bg-contact-us"></div>
        <div className="p-3 pb-0 px-5">
          <h1 className="mx-auto header-firstpage text-justify-metamarce titr1 font-40 text-start mt-md-5">
            راهنمای کسب درآمد از طریق لینک معرفی متامارس (Affiliation)
          </h1>
        </div>
        <img
          src="/img/homePage/wave-haikei-3.svg"
          className="p-0 pt-2 w-100 overlay-b"
          alt="homePage01"
        />
      </div>

      <div className="px-md-5 px-3 ads-all mx-auto">
        {/* <div className="row"> */}
        <div className="px-4 d-flex flex-column align-items-center justify-content-center">
          <p className="titr7"> با معرفی متامارس، درآمد داشته باش!</p>
          <p className="titr7 titr7-5" style={{ color: "#c3b091" }}>
            متامارس و بخش‌های مختلفش رو معرفی کن و در ٪۵۰ از درآمدش شریک شو!
          </p>
          <div className="container">
            <section>
              <div className="text-justify-metamarce">
                اگر شما هم قصد شروع یک کار ساده، بدون نیاز به سرمایه اولیه با
                ریسک پایین و بازده بالا را دارید، متامارس این مسیر را از طریق
                همکاری مشارکتی در فروش برای شما هموار نموده است.
              </div>

              <div>
                همکاری مشارکتی در فروش (Affiliate Marketing) متارمارس بر اساس
                سهولت، شفافیت و بدون نیاز به سرمایه و ریسک طراحی شده است.{" "}
              </div>
              {/* <div>برای این کار تنها کافیست است لینک افیلیت خود را در سایت یا پلتفرم های اجتماعی خود قرار دهید تا به ازای هر خرید پورسانت آن به شما تعلق گیرد.  </div> */}
              {/* <p> برنامه افیلیت مارکتینگ متامارس خوش آمدید! با معرفی و هر خرید، 30٪ کمیسیون از سهم متامارس کسب کنید. از همین امروز شروع به کسب درآمد کنید.</p> */}
            </section>

            <section>
              <h3 className=" titr6 font-20 mt-3 mt-md-4">
                Affiliate Marketing یا همکاری در فروش چیست؟
              </h3>
              <div className="text-justify-metamarce">
                افیلیت یا همکاری در فروش راهی ساده برای کسب درآمد بدون داشتن
                سرمایه اولیه است. شما با معرفی متامارس به دوستان و یا در شبکه
                های اجتماعی مانند اینستاگرام، تلگرام و… ،به آنها کمک می کنید که
                با خدمات و امکاناتی که متامارس در اختیار آنها قرار می دهد آشنا
                شوند و در مقابل 50% کمیسیون فروش از سهم متامارس به شما تعلق می
                گیرد. سیستم افیلیت متامارس با ریسک پایینی که دارد یکی از بهترین
                و پر بازده ترین روش ها برای کسب درآمد به شمار می رود.
              </div>
            </section>

            <section>
              <div className="row">
                <div className="col-md-7">
                  <h2 className=" titr6 font-20 mt-3 mt-md-4">
                    فرآیند ثبت‌نام
                  </h2>
                  <ul className="text-justify-metamarce px-0">
                    <li>
                      <i class="bi bi-dot "></i> در متامارس ثبت‌نام کنید.
                      {!user?.roles?.includes(9999) && (
                        <strong
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalToggle"
                          role="button"
                        >
                          (اینجا)
                        </strong>
                      )}
                    </li>
                    <li>
                      {" "}
                      <i class="bi bi-dot"></i>
                      پس از ثبت نام یک لینک اختصاصی برای شما صادر می گردد.
                      {/* <span className="fw-bold fst-italic cursor" onClick={(e) => {
                    if (!window.logined(9999)) return;
                    window.navigateHandle(`/${window.lang}/dashboard/user/profile/me`);
                  }}>
                    (اینجا)
                  </span> */}
                    </li>
                    <li>
                      {" "}
                      <i class="bi bi-dot"></i>
                      شما میتوانید لینک اختصاصی خود را کپی و آنرا منتشر کنید و
                      سپس کمیسون شما از کاربرانی که توسط لینک شما وارد وبسایت
                      شده باشند شروع و تا دوسال آینده از تمام خریدهای کاربران
                      مشمول دریافت پورسانت میباشید.
                    </li>
                  </ul>

                  <h2 className=" titr6 font-20 mt-3 mt-md-4">
                    تهیه لینک معرفی
                  </h2>
                  <ul className="text-justify-metamarce px-0">
                    <li>
                      <i class="bi bi-dot"></i>
                      برای تهیه لینک معرفی ابتدا بایستی وارد حساب کاربری خود
                      شوید.
                    </li>
                    <li>
                      {" "}
                      <i class="bi bi-dot"></i>
                      سپس به صفحه یا مطلبی که میخواهید آنرا معرفی کنید بروید.{" "}
                    </li>
                    <li>
                      {" "}
                      <i class="bi bi-dot"></i>
                      با کلیک بر روی نام کاربری خود از منوی بازشده گزینه
                      <span
                        className="fw-bold fst-italic cursor"
                        onClick={(e) => {
                          if (!window.logined(9999)) return;
                          document
                            .querySelector("#offcanvasLoginTrigger")
                            .click();
                        }}
                      >
                        "لینک اختصاصی شما به این صفحه"
                      </span>
                      را انتخاب و لینک صفحه کنونی را کپی کنید.
                    </li>
                    <li>
                      {" "}
                      <i class="bi bi-dot"></i>
                      پس از کپی کردن میتوانید از آن برای معرفی متامارس استفاده
                      نمائید.
                    </li>
                    <li>
                      {" "}
                      <i class="bi bi-dot"></i>
                      عملکرد خود را در بخش
                      <span
                        className="fw-bold fst-italic cursor"
                        onClick={(e) => {
                          if (!window.logined(9999)) return;
                          window.navigateHandle(
                            `/${window.lang}/dashboard/user/trans/affilatelist`
                          );
                        }}
                      >
                        "تراکنش ها"
                      </span>
                      پیگیری کنید.
                    </li>
                  </ul>
                </div>
                <div className="col-md-5">
                  <div className="w-100">
                    <img
                      className="w-100 px-md-5 px-3 mx-auto"
                      src="/img/guide/affiliationLink2.png"
                      alt="Affiliation Link"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section>
              <h2 className=" titr6 font-20 mt-3 mt-md-4">کسب کمیسیون</h2>
              <ul className="text-justify-metamarce px-0">
                <li>
                  {" "}
                  <i class="bi bi-dot"></i>
                  شما به مدت دوسال بابت تمامی خریدهایی که کاربر آن توسط شما وارد
                  وبسایت شده باشد، ٪۵۰ کمیسیون از سهم متامارس کسب می‌کنید.
                </li>
                <li>
                  {" "}
                  <i class="bi bi-dot"></i>
                  این کمیسیون ٪۵۰ مربوط به کاربرانی است که تا پایان سال ۲۰۲۴
                  میلادی ثبت نام کرده باشند و برای کاربران جدید در سال ۲۰۲۵ ممکن
                  است نرخ متفاوتی درنظر گرفته شود.
                </li>
                <li>
                  {" "}
                  <i class="bi bi-dot"></i>
                  کمیسیون‌ها به صورت ماهیانه و از طریق شبکه PayPal پرداخت
                  می‌شوند.
                </li>
                <li>
                  {" "}
                  <i class="bi bi-dot"></i>حداقل آستانه برای باز پرداخت کمیسیون
                  ۵۰ دلار می باشد.
                </li>
              </ul>
            </section>

            {/* <section>
              <h2>قوانین و راهنماها</h2>
              <ul>
                <li><strong>روش‌های تبلیغاتی مجاز:</strong> پست‌های شبکه‌های اجتماعی، مقالات بلاگ، خبرنامه‌های ایمیلی.</li>
                <li><strong>فعالیت‌های ممنوع:</strong> اسپم، ادعاهای گمراه‌کننده، یا استفاده از لینک همکاری در فروش خود در سایت‌های فروش کوپن.</li>
                <li><strong>رعایت قوانین:</strong> اطمینان حاصل کنید که تمامی فعالیت‌های تبلیغاتی با قوانین محلی سازگار هستند.</li>
              </ul>
            </section> */}

            <section>
              <h2 className=" titr6 font-20 mt-3 mt-md-4">نظارت و گزارش‌دهی</h2>
              <p className="text-justify-metamarce">
                ما از ابزارهای پیشرفته ردیابی برای نظارت بر ارجاع‌های شما و
                تشخیص هرگونه فعالیت تقلبی استفاده می‌کنیم. داشبورد شما داده‌های
                لحظه‌ای درباره کلیک‌ها و فروش‌ها را نشان می‌دهد.
              </p>
              <span
                className="fw-bold fst-italic cursor"
                onClick={(e) => {
                  if (!window.logined(9999)) return;
                  window.navigateHandle(
                    `/${window.lang}/dashboard/user/profile/me`
                  );
                }}
              >
                (اینجا)
              </span>
            </section>

            <section>
              <h2 className=" titr6 font-20 mt-3 mt-md-4">پشتیبانی</h2>
              <p className="text-justify-metamarce">
                نیاز به کمک دارید؟ با تیم پشتیبانی به آدرس
                metamarce.com@gmail.com ایمیل بفرستید و یا در
                <span
                  className="fw-bold fst-italic cursor"
                  onClick={(e) => {
                    if (!window.logined(9999)) return;
                    document.querySelector("#chatBoxBtn").click();
                  }}
                >
                  "متاچت"
                </span>
                با ما در ارتباط باشید.
              </p>
            </section>
          </div>
        </div>

        {/* <div className="col-md-4 text-center">
            <div style={{ position: "relative" }}>
              <img
                src="/img/illustration/pair-programming-animate.svg"
                className="mb-3 px-0 w-100"
                alt="homePage"
              />
            </div>
          </div> */}
        {/* </div> */}
      </div>
    </div>
  );
}
