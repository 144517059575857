import "./saved.css";
import { DeleteOutline } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { CartContext } from "../../../../../contexts/cartContext";
import apiMedia from "../../../../../../utils/apiMedia";
import ToastNote from "../../../../../golbal/notifications/toastNote";
import { CircularProgress } from "@mui/material";
import api from "../../../../../../utils/api";
import ColoredSpiner from "../../../../../alret/spiners/coloredSpiner/coloredSpinner";
import renderData from "../../../../../../utils/renderData";
import MyIcons from "../../../../../golbal/icons/myIcons";

export default function SavedMedia() {
  const messageRef = useRef(null);
  const [loading, setLoading] = useState({
    spinner: false,
    id: "",
    loading2: false,
    loading3: false,
  });
  // console.log("loading main",loading);
  const effectRan = useRef(false);
  const { user, signedUser } = useContext(CartContext);
  const [countryShort, stateShort] = window.location.pathname
    .toLowerCase()
    .split("/")
    .slice(2, 4);

  const navigate = useNavigate();
  console.log(user);
  const url = useParams();
  // console.log(url);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchSavedList();
  }, []);

  async function fetchSavedList() {
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await apiMedia.userSavedList({
      token: user.token,
      savedItems: user?.saved?.media,
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    console.log(response.data);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    setData(response.data);
  }

  const deleteHandler = async (e, media) => {
    e.preventDefault();
    const text = "شما در حال حذف این اینفلوئنسر از ذخیره میباشید.";
    if (window.confirm(text) != true) return;
    setLoading((pre) => ({
      ...pre,
      id: media._id,
    }));
    const response = await api.saveHandler({
      id: media._id,
      token: user.token,
      section: "media",
    });
    setLoading((pre) => ({ ...pre, id: "" }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showWarning({
      message: "کسب و کار از لیست ذخیره حذف شد.",
    });
    signedUser(response.data);
    const newData = data.filter((da) => da._id !== media._id);
    setData(newData);
  };

  function showPageHandle(props) {
    let text = "";
    if (window.lang !== props.lang.toLowerCase())
      text += `شما در حال انتقال به زبان ${window.capitalize(
        props.lang
      )} میباشد . \n`;
    if (countryShort.toLowerCase() !== props.countryShort.toLowerCase())
      text += `شما در حال انتقال به کشور ${window.capitalize(
        props.country
      )} میباشد . \n`;
    if (stateShort.toLowerCase() !== props.stateShort.toLowerCase())
      text += `شما در حال انتقال به استان ${window.capitalize(
        props.state
      )} میباشد . \n`;
    if (!text.length)
      return navigate(
        `/${window.lang}/media/filters?mediaId=${props._id}&title=${props.title}`
      );
    if (window.confirm(text) != true) return;
    console.log("Passed");
    navigate(
      `/${
        props.lang + "/" + props.countryShort + "/" + props.stateShort
      }/media/filters?mediaId=${props._id}&title=${props.title}`
    );
  }

  const columns = [
    {
      field: "action",
      headerName: "عملیات",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="d-flex">
            <div title="نمایش" onClick={(e) => showPageHandle(params.row)}>
              <MyIcons icon="display" />{" "}
            </div>
            {loading.id === params.row._id ? (
              <CircularProgress color="inherit" size="20px" />
            ) : (
              <div
                title="پاک کردن"
                onClick={(e) => deleteHandler(e, params.row)}
              >
                <MyIcons icon="delete2" />
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "photo",
      headerName: "عکس",
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            <img
              src={params.row.photo}
              alt={params.row.title}
              className="userListImg"
              loading="lazy"
            />
          </div>
        );
      },
    },
    {
      field: "platform",
      headerName: "نام شبکه ",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            // className="userListEdit"
            title={window.capitalize(params.row?.mainMedia.platform)}
          >
            <img
              src={
                renderData.socialMedias.find(
                  (med) => med.name === params.row?.mainMedia.platform
                ).img
              }
              alt={params.row.title}
              className="userListImg"
            />
          </div>
        );
      },
    },
    {
      field: "title",
      headerName: "عنوان صفحه",
      width: 150,
      renderCell: (params) => {
        return (
          <storng>
            <div title={params.row?.title}>{params.row?.title}</div>
            {/* <div title={params.row?.email}> {params.row?.email}</div>        */}
          </storng>
        );
      },
    },
    {
      field: "subCategory",
      headerName: "دسته بندی",
      width: 100,
    },
    {
      field: "city",
      headerName: "شهر",
      width: 100,
      renderCell: (params) => (
        <div dir="ltr" title={params.row?.city}>
          {window.capitalize(params.row?.city)}
        </div>
      ),
    },
    {
      field: "state",
      headerName: "استان",
      width: 100,
      renderCell: (params) => (
        <div dir="ltr" title={params.row?.state}>
          {window.capitalize(params.row?.state)}
        </div>
      ),
    },
    {
      field: "country",
      headerName: "کشور",
      width: 100,
      renderCell: (params) => (
        <div dir="ltr" title={params.row?.country}>
          {window.capitalize(params.row?.country)}
        </div>
      ),
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 170,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {loading.id === params.row._id ? (
    //           <CircularProgress color="inherit" size="20px" />
    //         ) : (
    //           <DeleteOutline
    //             className="userListDelete"
    //             onClick={(e) => deleteHandler(e, params.row)}
    //           />
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <div className="userList" style={{ height: "100vh", width: "100%" }}>
      Saved Jobs
      {loading.spinner && <ColoredSpiner />}
      <ToastNote messageRef={messageRef} />
      <DataGrid
        getRowId={(row) => row._id}
        rows={data}
        components={{
          Toolbar: GridToolbar,
        }}
        disableSelectionOnClick
        columns={columns}
        pageSize={[15]}
        rowsPerPageOptions={[1]}
        checkboxSelection
      />
    </div>
  );
}
