import React, { useRef, useState } from "react";
import { Rating } from "@mui/material";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import { TaskAlt, Verified } from "@mui/icons-material";
import ToastNot from "../../../golbal/notifications/toastNote";
import MyIcons from "../../../golbal/icons/myIcons";

export default function MapBusinessCard({data}) {
  console.log('data',data);
  
  const messageRef = useRef(null);
  return (
    <div className="map-bus-card">
      <ToastNot messageRef={messageRef} />
      <div className="card mx-2 border-0 shadow h-100">
        <div className="card-img-pack ">
          <div className="business-card-img-wrap">
            <a
              className="image-container image-container-bus"
              href={`/${window.lang}/business/page/${data?._id}`}
              target="_blank"
            >
              <img
                src={data?.moreInfo?.photos?.businessCard}
                // src={"https://www.metamarce.com/uploads/images/business/17228710693702023-12-25---Copy.jpg"}
                className="card-img-top  image-card business-card-img"
                alt={data.busName[window.lang]}
                loading="lazy"
              />
            </a>
          </div>
          <a
            href={`/${window.lang}/business/filters??mainCat=${
              data.category?.mainCat?.[window.lang]
            }&mainCatId=${data.category?.mainCat?._id}`}
            title={data.category?.mainCat[window.lang]}
            target="_blank"
          >
            <img
              src={data.category?.mainCat?.mainLogo}
              className="card-logo "
              alt={data.category?.mainCat[window.lang]}
              loading="lazy"
            />
          </a>

          {/* {data.facilities?.find(
            (facility) => facility == "specialOffer"
          ) && <div className="listing-item-pic-tag"> تخفیف ویژه</div>} */}
          {/* <!-- <span className="icon-stack2">
                          <i className="bi bi-check icon-stack-2x"></i>
                      </span> --> */}
          {/* <!-- <i className="bi bi-shield-fill-check icon-logo "></i> --> */}
        </div>

        <div className="card-body mt-2">
          <h5 className="card-title card-titel1 m-1">
            {data.busName?.[window.lang]}
            <span>
              {data?.moreInfo?.status === "01-draft" ? (
                <Tooltip1 message="این کسب و کار توسط کاربرها پیشنهاد و ثبت شده است." />
              ) : // <i className="bi bi-patch-question-fill"></i>
              data?.moreInfo?.status === "03-verified" ? (
                <Tooltip1
                  icon={<MyIcons icon={data?.moreInfo?.status} />}
                  message=" هویت مالک کسب و کار تائید شده است."
                />
              ) : //  : data?.moreInfo?.status === "02-claimed" ? (
              //   <Tooltip1
              //   icon={<MyIcons icon={data?.moreInfo?.status} />}
              //     message="کسب و کار توسط مالک ثبت شده"
              //   />
              // )
              data?.moreInfo?.status === "04-trusted" ? (
                <Tooltip1
                  icon={<MyIcons icon={data?.moreInfo?.status} />}
                  message=" هویت کسب و کار و مالک آن تائید شده است."
                />
              ) : (
                ""
              )}
            </span>
          </h5>

          {/* <!-- *******rating stars shold show up here --> */}

          <p dir="ltr" className="text-start">
            <span className="map-card-rating">
              <Rating
                name="read-only"
                value={Number(data?.feedbackInfo?.rate)}
                readOnly
                precision={0.5}
              />
            </span>
          </p>

          <div className="card-text card-text1">
            <ul className="list-group">
              <li className="group-list-item2 my-1">
                <i className="bi bi bi-geo-alt mx-2"></i>
                {`${window.capitalize(data?.moreInfo?.address?.city)},
                  ${window.capitalize(data?.moreInfo?.address?.state)},
                  ${window.capitalize(data?.moreInfo?.address?.countryShort)}
              `}
              </li>
            </ul>
          </div>
        </div>

        {/* <!-- end of card footer --> */}
      </div>
    </div>
  );
}
