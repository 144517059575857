import React from "react";
import renderData from "../../../../../../utils/renderData";

export default function CalendarMy({ formik, section }) {
  // const formik =props.formik
  // console.log('CalendarMy',formik.values,section);
  const currentYear = new Date().getUTCFullYear();
  const currentYearMonth = Number(
    `${currentYear}${(new Date().getUTCMonth() )
      .toString()
      .padStart(2, "0")}`
  );
  const nextYear = new Date().getUTCFullYear() + 1;
  return (
    <div key={section} id={"calendarMy-" + section} className="calendar-my row">
      <div className="col-6">
        <div className="ad-calendar  mx-auto">
          <h5 className="text-center">{currentYear}</h5>
          <MonthsRender formik={formik} year={currentYear} section={section} />
        </div>
      </div>
      <div className="col-6">
        <div className="ad-calendar  mx-auto">
          <h5 className="text-center">{nextYear}</h5>
          <MonthsRender formik={formik} year={nextYear} section={section} />
        </div>
      </div>
    </div>
  );

  function MonthsRender({ formik, year, section }) {
    return (
      <div key={section + year + formik.values[section]?.price} className="row">
        {renderData.months.map((mont, i) => {
          const yearMonth = Number(
            `${year}${(i + 1).toString().padStart(2, "0")}`
          );
          return (
            <div key={i} className="col-4">
              <div
                className={`form-check mx-auto ${classSelector(yearMonth)} `}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={yearMonth}
                  // value={`${year}-${(i + 1).toString().padStart(2, '0')}`}
                  // value={`${year}-${i+1}`}
                  id={`${section + year}-month-${i + 1}`}
                  // checked={formik.values.plansX4.currentYear.some(mo=>mo==i+1)}
                  onChange={monthSelectorHandle}
                />
                <label
                  className="form-check-label"
                  htmlFor={`${section + year}-month-${i + 1}`}
                >
                  {mont?.[window.lang]}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    );

    function classSelector(yearMonth) {
      if (yearMonth <= currentYearMonth) return "not-available";
      if (formik.values[section]?.yearMonth?.includes(yearMonth)) return "selected";
    }

    function monthSelectorHandle(e) {
      e.preventDefault();

      if (e.target.value < currentYearMonth + 1)
        return alert("انتخاب نمی تواند در گذشته باشد.");
      function removePushArray(arr, value) {
        const index = arr.indexOf(value);
        if (index !== -1) {
          arr.splice(index, 1);
        } else {
          arr.push(value);
        }
      }

      formik.setValues((pre) => {
        const pre2 = { ...pre };
        const newArray = pre2[section].yearMonth;
        removePushArray(newArray, Number(e.target.value));
        pre2[section].yearMonth = newArray;
        return pre2;
      });
    }
  }
}
