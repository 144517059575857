import React, { useState } from 'react'
import tools from '../../../../../../../utils/tools'
import apiChain from '../../../../../../../utils/apiChain';

export default function CreateTransaction() {
    const [transaction,setTransaction] =useState({amount:"",recipient:""}) 
    async function transferHandle(){
        if (!transaction.amount||!transaction.recipient.length) return alert("لطفا آدرس یا مبلغ را کنترل نمائید.")
        const response = await apiChain.createTransaction({transaction});
        if (response.error)return window.alert(response.error)
            window.alert(response.message)
        console.log("response", response);
        setTransaction({amount:0,recipient:""});          
        

    }

  return (
    <div>
        <h1>ایجاد تراکنش</h1>
        <div><input type="text" placeholder='Please enter the recipient address' value={transaction.recipient} onChange={e=>{
           setTransaction(pre=>({...pre,recipient:e.target.value}))
        }}/></div>
        <div><input type="text" placeholder='Please enter the amount' value={transaction.amount} onChange={e=>{
               const value = e.target.value;
             const numericValue = value.replace(/[^0-9]/g, '');
           setTransaction(pre=>({...pre,amount:tools.convertToEnglish(numericValue)}))
        }}/></div>
        <div><div className='btn btn-primary m-2' type="button" onClick={e=>transferHandle()}>ارسال</div> </div>
    </div>
  )
}
