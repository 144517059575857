import {Link, NavLink, useNavigate } from "react-router-dom";
import api from "../../utils/api";
import LoginEn from "../screens/login/loginEn";
import SidebarRight from "./sidebarRight/cartSidebar";
import "./navBar.css";
import "./topbar.css";
import { CartContext } from "../contexts/cartContext";
import React, { useContext, useState } from "react";
import SidebarLeft from "./sidbarLeft/sidebarLeft";
// import Tooltip1 from "../golbal/tooltip/tooltip";
import tools from "../../utils/tools";
import ChatBox from "../golbal/chatBox/chatBox";
import Tooltip1 from "../golbal/tooltip/tooltip";

export default function NavbarEn({ categories, countryDetails }) {

  const { countItems, user, userEmptier } = useContext(CartContext);
  const { lang, state:{state,stateShort}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
 
  const [unreadCount, setUnreadCount] = useState(0);
  const navigate = useNavigate();
  const logoutHandle = async () => {
    const response = await api.logout();
    if (response.error) return alert(response.error);
    console.log(response);
    userEmptier();
  };

  // useEffect(() => autologout(), [user]);

  // function autologout() {
  //   if (!user.roles) return;
  //   let timer = setTimeout(() => {
  //     logoutHandle();
  //     eventsRemover();
  //   }, 24 * 60 * 60 * 1000);
  //   window.addEventListener("mousemove", resetTimer);
  //   window.addEventListener("keydown", resetTimer);
  //   function resetTimer() {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       eventsRemover();
  //       logoutHandle();
  //     }, 24 * 60 * 60 * 1000);
  //   }
  //   return () => {
  //     eventsRemover();
  //     clearTimeout(timer);
  //   };
  //   function eventsRemover() {
  //     window.removeEventListener("mousemove", resetTimer);
  //     window.removeEventListener("keydown", resetTimer);
  //   }
  // }
  function navigateHandle(url) {
    window.scroll(0, 0);
    setTimeout((e) => navigate(url), 200);
    localStorageRemover("storedFilters")
  }
  function localStorageRemover(filed){
    localStorage.removeItem([filed])
  }
  const currentPage = window.location.pathname.toLowerCase().split("/")[2];
  // console.log(currentPage, window.location.pathname.split('/')[4] === "business")
  return (
    <>
      <nav
        className="navbar  navbar-dark bg-nav-soft-dark p-2 px-1 m-0"
        //  style={{zIndex:"1021"}}
      >
        <div className="container-fluid px-0 m-0" style={{ width: "100%" }}>
          <div
            className="row g-2 px-0 mx-0 d-flex justify-content-center align-items-center  "
            style={{ width: "100%" }}
          >
            {/*<!-- login Trigger Sign in ******************************************-->*/}

            <div className="nav-item px-0 mx-0 d-flex justify-content-start align-items-start  ">
              {user?.roles?.includes(9999) ? (
                <>
                  <div
                    className="btn p-0"
                    type="button"
                    id="offcanvasLoginTrigger"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasLogin"
                    aria-controls="offcanvasLogin"
                  >
                    <img
                      className="topAvatar  mx-md-3"
                      src={user?.thumbnail}
                      alt={user.userName}
                      loading="lazy"
                    />
                  </div>
                  <div className="dropdown">
                    <div
                      type="button"
                      className="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="bi bi-wallet2 cart-icon px-2 "></i>
                      {user?.wallet > 0 && (
                        <span className="cart-count">
                          <i className="bi bi-currency-dollar"></i>
                        </span>
                      )}
                      {/* {<span className="cart-count"> {tools.dynamicNumber({number: user?.points, lang:window.lang})}</span>} */}

                      {/* <img
                        style={{ width: "35px" }}
                        src="https://img.icons8.com/?size=160&id=c9JXKuUWBLIt&format=png"
                      /> */}
                    </div>

                    {/* <!-- Modal --> */}

                    <div className="dropdown-menu checkbox-form px-3 bg-beigeYellow ">
                      <div className="d-flex">
                        <i className="bi bi-cash font-16 pe-1 "></i>
                        <span> موجودی کیف پول : </span>
                        <span>
                          <strong>
                            {tools.showPrice(
                              user?.wallet * countryDetails?.exRate,
                              countryDetails?.currency
                            )}
                          </strong>
                        </span>
                      </div>
                      <div className="d-flex">
                        <i className="bi bi-coin font-16 pe-1 "></i>
                        <span>امتیاز :</span>
                        <strong>
                          {" "}
                          {tools.dynamicNumber({
                            number: user?.points,
                            lang: window.lang,
                          })}
                        </strong>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <LoginEn logoutHandle={logoutHandle} />
              )}
              <UserSidebar
                user={user}
                logoutHandle={logoutHandle}
              />
              <div
                className="nav-link cart-nav py-0 px-2 "
                id="sidebarRightTrigger"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBothOptions"
                aria-controls="offcanvasWithBothOptions"
              >
                <i className="bi bi-cart3 cart-icon cursor"> </i>
                <span className="cart-count">
                  {" "}
                  {tools.dynamicNumber({
                    number: countItems(),
                    lang: window.lang,
                  })}
                </span>
              </div>
              <div
                type="button btn-icon"
                id="chatBoxBtn"
                className="nav-link cart-nav py-0 px-2 "
                //  type="button"
                // data-bs-backdrop="true"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasChatboxContainer"
                aria-controls="offcanvasChatboxContainer"
              >
                <div>
                  <i
                    className="bi bi-chat-text cart-icon cursor"
                    title="متاچت"
                  ></i>
                  {unreadCount > 0 && (
                    <span className="cart-count">
                      {" "}
                      {tools.dynamicNumber({
                        number: unreadCount,
                        lang: window.lang,
                      })}
                    </span>
                  )}
                </div>
              </div>
              <div className="ms-auto d-flex me-1">
                {/* <div
                  className="btn btn-primary addbusiness-btn  display-none-md mt-1"
                  onClick={(e) => {
                    if (!window.logined(9999,"business")) {
                      return window.navigateHandle(
                         `/${window.lang}/business/register`
                       );
                     };
                 
                    navigateHandle(
                      `/${window.lang}/dashboard/user/business/register`
                    );
                  }}
                >
                  <i
                    className="bi bi-geo me-1"
                    style={{ fontSize: "large" }}
                  ></i>
                  افزودن کسب و کار
                </div> */}

                {/* <div
                  className="btn btn-primary addbusiness-btn mx-2 mt-1 display-none-md"
                  onClick={(e) => navigate(`/${window.lang}/store/plans`)}
                >
                  <i
                    className="bi bi-mic me-1"
                    style={{ fontSize: "large" }}
                  ></i>
                  <i className="bi bi-rocket" style={{ fontSize: "large" }}></i>
                  تبلیغات
                </div> */}
                {/* <div
                  className="btn btn-primary addbusiness-btn mx-2 mt-1 display-none-md"
                  onClick={(e) =>  navigate(`/${window.lang}/dashboard/demy/registercenter`)}                
                >
                  <i
                    className="bi bi-mic me-1"
                    style={{ fontSize: "large" }}
                  ></i>
                  <i className="bi bi-rocket" style={{ fontSize: "large" }}></i>
                  افزودن دانشگاه
                </div> */}
                <SidebarRight countryDetails={countryDetails} />
                <div className="  mt-2 cursor">
                  <SidebarLeft />
                </div>
              </div>
              {/* <!-- <button className="btn btn-primary bag" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">یا این</button> --> */}
              {/* <i className="bi bi-geo-alt login-trigger-user" >{this.user.moreInfo.city}</i> */}
            </div>

            {/*<!-- login Trigger Register ******************************************-->*/}
          </div>
        </div>
      </nav>
      {/*<!-- end of top navbar  -->*/}
      {/* {section !== "demy" && ( */}
      <nav className="navbar navbar-light  sticky-bot sticky-top-nav display-tablet p-0 w-100">
        <div className="w-100 p-3 py-2 pb-1 nav-bg">
          <div className="d-flex w-100">
            <div className="row w-100 ">
              <div className="col px-0 ">
                <div
                  className="nav-item dropdown dropdown2 has-megamenu "
                  tabIndex="1"
                  // data-bs-dismiss="offcanvas"
                >
                  <div
                    onClick={(e) => navigateHandle(`/${window.lang}`)}
                    // onClick={e=>window.scroll(0,0)}
                    className={`nav-link dropdown-toggle nav-title ${
                      window.location.pathname.split("/").length === 2 &&
                      "active"
                    } `}
                    aria-current="page"
                    // to={`/${window.lang}`}
                  >
                    <div
                      className={`nav-app-btn pt-1 mx-auto ${
                        !currentPage && "nav-active"
                      }`}
                    >
                      <i className="bi bi-house "> </i>
                    </div>

                    {/* <div className="m-1 mb-0 small-beige-font font-10 text-center">
                      Home
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col px-0 ">
                <div
                  className="nav-item   dropdown dropdown2 has-megamenu "
                  tabIndex="1"
                  // data-bs-dismiss="offcanvas"
                >
                  <div
                    onClick={(e) => navigateHandle(`/${window.lang}/demy`)}
                    className="nav-link dropdown-toggle nav-title"
                  >
                    <div
                      className={`nav-app-btn pt-1 mx-auto ${
                        currentPage === "demy" && "nav-active"
                      }`}
                    >
                      <i className="bi bi-laptop "></i>
                    </div>
                    <div className="m-1 small-beige-font font-10 text-center">
                      Metademy
                    </div>
                  </div>
                </div>
              </div>
              <div className="col px-0 ">
                <div
                  className="nav-item  dropdown dropdown2 has-megamenu "
                  tabIndex="1"
                  // data-bs-dismiss="offcanvas"
                >
                  <NavLink
                    onClick={(e) => {
                      window.scroll(0, 0)
                    localStorageRemover("storedFilters")
                    }}
                    className="nav-link dropdown-toggle nav-title"
                    to={`/${window.lang}/business`}
                  >
                    <div
                      className={`nav-app-btn pt-1 mx-auto ${
                        currentPage === "business" && "nav-active"
                      }`}
                    >
                      <i className="bi bi-briefcase"></i>
                    </div>

                    <div className="m-1 small-beige-font font-10 text-center">
                      Businesses
                    </div>
                  </NavLink>
                </div>
              </div>

              {/* <div className="col px-0 ">
                  <div
                    className="nav-item   dropdown dropdown2 has-megamenu "
                    tabIndex="1"
                    // data-bs-dismiss="offcanvas"
                  >
                    <div
                    onClick={(e) => navigateHandle(`/${window.lang}/jobs`)}
                      className="nav-link dropdown-toggle nav-title"
                      
                    >
                      <div className={`nav-app-btn pt-1 mx-auto ${currentPage==="jobs"&&'nav-active'}`}>
                        <i className="bi bi-laptop "></i>
                      </div>
                      <div className="m-1 small-beige-font font-10 text-center">
                        
                        کاریابی
                      </div>
                    </div>
                  </div>
                </div> */}
              <div className="col px-0 ">
                <div
                  className="nav-item dropdown dropdown2 has-megamenu "
                  tabIndex="1"
                  // data-bs-dismiss="offcanvas"
                >
                  <div
                    onClick={(e) => navigateHandle(`/${window.lang}/mp`)}
                    className="nav-link dropdown-toggle nav-title"
                    // to={`/${window.lang}/mp`}
                  >
                    <div
                      className={`nav-app-btn pt-1 mx-auto ${
                        (currentPage === "mp"||currentPage==="jobs") && "nav-active"
                      }`}
                    >
                      <i className="bi bi-megaphone"></i>
                    </div>

                    <div className="m-1 small-beige-font font-10 text-center">
                     Marketplace
                    </div>
                  </div>
                </div>
              </div>
              <div className="col px-0 ">
                <div
                  className="nav-item dropdown dropdown2 has-megamenu "
                  tabIndex="1"
                  // data-bs-dismiss="offcanvas"
                >
                  <div
                    className="nav-link dropdown-toggle nav-title"
                    onClick={(e) => {
                      navigateHandle(`/${window.lang}/blog/filters`);
                      const storedFilters = JSON.parse(
                        localStorage.getItem("storedFilters")
                      );
                      if (storedFilters?.blog) {
                        delete storedFilters.blog;
                        localStorage.setItem(
                          "storedFilters",
                          JSON.stringify(storedFilters)
                        );
                      }
                    }}
                  >
                    <div
                      className={`nav-app-btn pt-1 mx-auto ${
                        currentPage === "blog" && "nav-active"
                      }`}
                    >
                      <i className="bi bi-book"></i>
                    </div>

                    <div className="m-1 small-beige-font font-10 text-center">
                      Blog
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className=" navbar-toggler border-0 px-1 "
              style={{ width: "30px", fontSize: "35px", color: "white" }}
              type="button"
              aria-label="Toggle navigation"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              {/* <span className="navbar-toggler-icon"></span> */}
              <i className="bi bi-three-dots-vertical"></i>
            </div>
          </div>
          <div
            className="offcanvas offcanvas-end "
            data-bs-scroll="true"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header offcanvas-countryLanguageTitle">
              {/* <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                متامارس
              </h5> */}
              <div className="d-flex justify-content-start" dir="ltr">
                <div
                  onClick={(e) => navigateHandle("/")}
                  className="footer-brand px-1"
                  style={{ color: "gold", cursor: "pointer" }}
                >
                  {/* متامارس */}
                  Metamarce
                </div>
                {/* <Link to="" className="btn-primery province-btn p-1 mx-1 mb-2">
                  {state}
                </Link> */}
              </div>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body "></div>
            <div className=" offcanvas-footer">
              <div
                className="btn primary-btn view-cart view-cart-2  cart-footer m-3 "
                tabIndex="1"
                data-bs-dismiss="offcanvas"
                onClick={(e) => {
                  if (!window.logined(9999,"tutor")) return;
                  window.navigateHandle(
                    `/${window.lang}/dashboard/user/demy/registertutor`
                  );
                }}
              >
                <i className="bi bi-book">تدریس در Metademy</i>
              </div>
              <div
                className="btn primary-btn view-cart cart-footer m-3 "
                tabIndex="1"
                data-bs-dismiss="offcanvas"
                onClick={(e) => {
                  if (!window.logined(9999,"business")) {
                    return window.navigateHandle(
                       `/${window.lang}/business/register`
                     );
                   };
                  window.navigateHandle(
                    `/${window.lang}/dashboard/user/business/register`
                  );
                }}
              >
                <i className="bi bi-geo">افزودن کسب و کار</i>
              </div>
              {/* <div
                className="btn primary-btn checkout-btn cart-footer m-3 "
                tabIndex="1"
                data-bs-dismiss="offcanvas"
                onClick={(e) => navigateHandle(`/${window.lang}/jobs/register`)}
              >
                افزودن فرصت شغلی
              </div> */}

              <div
                className="btn primary-btn nav-mob-btn cart-footer m-3 "
                tabIndex="1"
                data-bs-dismiss="offcanvas"
                onClick={(e) => {
                  navigate(`/${window.lang}/store/plans`);
                }}
              >
                <i className="bi bi-mic ">تبلیغات</i>
              </div>
              <div
                className="btn primary-btn checkout-btn cart-footer m-3 "
                tabIndex="1"
                data-bs-dismiss="offcanvas"
                onClick={(e) => {
                  if (!window.logined(9999,"media")){
                    return window.navigateHandle(
                       `/${window.lang}/media/register`
                     );
                   };;
                  window.navigateHandle(
                    `/${window.lang}/dashboard/user/media/register`
                  );
                }}
              >
                افزودن شبکه اجتماعی
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* )} */}

      {/*<!-- second navbar******************************************* -->*/}
      {/* {section !== "demy" && ( */}
      <nav className="navbar navbar-expand-md navbar-light bg-light sticky-top sticky-top-nav display-none-md ">
        <div className="container-fluid mx-5">
          <div className="d-flex " dir="ltr">
            <div
              onClick={(e) => navigateHandle("/")}
              className="footer-brand px-1"
              // to={`/`}
              style={{ color: "gold", cursor: "pointer" }}
            >
              {/* متامارس */}
              Metamarce
            </div>
            <div
              onClick={(e) => document.querySelector("#lcsChangeBtn").click()}
              className="btn-primery province-btn p-1 mx-1 mb-2"
            >
              {country}
            </div>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ">
              {/* <li className="nav-item px-3 dropdown dropdown2 has-megamenu">
                <div
                  onClick={(e) => navigateHandle(`/${window.lang}`)}
                  className={`nav-link nav-title ${
                    window.location.pathname.split("/").length === 2 && "active"
                  }`}
                  aria-current="page"
                  // to={`/${window.lang}`}
                >
                  Home
                </div>
              </li> */}

              {/* <li className="nav-item px-3">
                <NavLink
                  className="nav-link dropdown-toggle nav-title"
                  to={`/${window.lang}/store`}
                >
                  فروشگاه
                </NavLink>
              </li> */}

              <li className="nav-item px-3">
                <NavLink
                onClick={e=>{
                  window.scroll(0,0)
                  localStorageRemover("storedFilters")
                }}
                  className="nav-link dropdown-toggle nav-title"
                  to={`/${window.lang}/demy`}
                >
                  Metademy
                </NavLink>
              </li>

              {/* <li className="nav-item px-3 dropdown dropdown2 has-megamenu">
                <NavLink
                  className="nav-link dropdown-toggle nav-title"
                  to={`/${window.lang}/business`}
                  id="dropdownMenuClickableInside"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                  onClick={(e) => {
                    window.scroll(0, 0);
                    navigate(`/${window.lang}/business`, { replace: true });
                    localStorageRemover("storedFilters")                   
                    var dropdownButton = document.getElementById(
                      "dropdownMenuClickableInside"
                    );
                    var dropdown = new window.bootstrap.Dropdown(
                      dropdownButton
                    );
                    dropdown.hide();
                  }}
                >
                 Businesses
                </NavLink>
                <ul
                  className="dropdown-menu megamenu pb-5 px-5"
                  aria-labelledby="dropdownMenuClickableInside"
                >
                  <div className="row">
                    <div className="col-lg-10">
                      <div className="row row-cols-xxl-6 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 mb-1 g-3">
                        {categories?.business?.map((busCat, i) => (
                          <div key={"bus" + busCat._id}>
                            <div className="col-megamenu ">
                              <div className="d-flex btn-size">
                                <div
                                  onClick={(e) =>
                                    navigateHandle(
                                      `/${
                                        window.lang
                                      }/business/filters?mainCat=${
                                        busCat.mainCat?.[window.lang]
                                      }&mainCatId=${busCat._id}`
                                    )
                                  }
                                  className=" p-1 "
                                >
                                  {busCat.mainCat?.[window.lang]}
                                </div>
                                {busCat.subCat.length > 0 && (
                                  <div className="mt-2 ms-auto menu-drop ">
                                 
                                    <i
                                      id={busCat._id}
                                      className="bi bi-chevron-down no-close"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        const el = e.currentTarget;
                                      
                                        el.classList.toggle("bi-chevron-down");
                                        el.classList.toggle("bi-chevron-up");
                                        const subCat = document.querySelector(
                                          `#ul${busCat._id}`
                                        );
                                       
                                        subCat.style.display =
                                          subCat.style.display === "none"
                                            ? "block"
                                            : "none";
                                      }}
                                    ></i>
                                   
                                  </div>
                                )}
                              </div>

                              <ul
                                style={{ display: "none" }}
                                className="list-unstyled mega-menu-subtitle"
                                id={`ul${busCat._id}`}
                              >
                                {busCat.subCat.map((sub, i) => (
                                  <li
                                    key={
                                      "bus-" +
                                      busCat.mainCat._id +
                                      sub?.[window.lang] +
                                      i
                                    }
                                  >
                                    <div
                                      onClick={(e) =>
                                        navigateHandle(
                                          `/${
                                            window.lang
                                          }/business/filters?mainCat=${
                                            busCat.mainCat?.[window.lang]
                                          }&mainCatId=${busCat._id}&subCat=${
                                            sub?.[window.lang]
                                          }&subCatId=${sub._id}`
                                        )
                                      }
                                      className={`mega-menu-subtitle menuMainCat${busCat._id}`}
                                    >
                                      {sub?.[window.lang]}
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="row  g-3">
                        <div
                          className="btn primary-btn edit-btn cart-footer "
                          onClick={(e) =>
                            navigateHandle(`/${window.lang}/business/register`)
                          }
                        >
                          <i className="bi bi-geo">افزودن کسب و کار</i>
                        </div>

                        <div
                          onClick={(e) =>
                            navigateHandle(`/${window.lang}/store/plans`)
                          }
                          className="btn primary-btn close-btn cart-footer "
                        >
                          <i className="bi bi-mic ">تبلیغات</i>
                        </div>

                        <div
                          onClick={(e) =>
                            navigateHandle(
                              `/${window.lang}/dashboard/user/jobs/register`
                            )
                          }
                          className="btn primary-btn delete-btn cart-footer "
                          // to={`/${window.lang}/dashboard/user/jobs/register`}
                        >
                          افزودن فرصت شغلی
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </li> */}

              {/* {user?.roles?.includes(1000)&& 
              <> */}
              {/* <li
                className="nav-item px-3 dropdown dropdown2 has-megamenu "
                tabIndex="1"
              >
                <NavLink
                  onClick={(e) => {window.scroll(0, 0)
                    localStorageRemover("storedFilters")
                  }}
                  className="nav-link dropdown-toggle nav-title"
                  to={`/${window.lang}/jobs`}
                >
                  کاریابی
                </NavLink>
              </li> */}
              {/* <li
                className="nav-item px-3 dropdown dropdown2 has-megamenu "
                tabIndex="1"
              >
                <NavLink
                  onClick={(e) => {window.scroll(0, 0)
                    localStorageRemover("storedFilters")
                  }}
                  className="nav-link dropdown-toggle nav-title"
                  to={`/${window.lang}/mp`}
                >
                  Marketplace
                </NavLink>
              </li> */}
              {/* </> } */}
              {/* <li
                className="nav-item px-3 dropdown dropdown2 has-megamenu "
                tabIndex="1"
              >
                <NavLink
                  onClick={(e) => {window.scroll(0, 0)
                    localStorageRemover("storedFilters")
                  }}
                  className="nav-link dropdown-toggle nav-title"
                  to={`/${window.lang}/media`}
                >
                  شبکه‌های اجتماعی
                </NavLink>
              </li> */}

              {/* <li
                className="nav-item px-3 dropdown dropdown2 has-megamenu "
                tabIndex="1"
              >
                <NavLink
                  className="nav-link dropdown-toggle nav-title"
                  onClick={(e) => {
                    window.scroll(0, 0);
                    localStorageRemover("storedFilters")
                  }}
                  to={`/${window.lang}/blog/filters`}
                >
                  Blog
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      {/* )} */}

      {/*<!-- ***end of navbars*** -->*/}
      <ChatBox unreadCount={unreadCount} setUnreadCount={setUnreadCount} />
    </>
  );
}
function UserSidebar({ user, logoutHandle }) {
  return (
    <div
      className="offcanvas offcanvas-start mb-5"
      data-bs-scroll="true"
      tabIndex="-1"
      id="offcanvasLogin"
      aria-labelledby="offcanvasLoginLabel"
    >
      <div className="offcanvas-header offcanvas-countryLanguageTitle ">
        <h5
          className="offcanvas-title mx-5 p-0 "
          id="offcanvasLoginLabel"
          dir="ltr"
        >
          <span>
            <img
              className="  topAvatar2 mx-2"
              src="/img/icons/wink.png"
              alt="login"
              loading="lazy"
            />
          </span>
          {user?.userName}
        </h5>

        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      {/* <div
        // style={{ color: "white", fontSize: "10px" }}
        title="Wallet"
      >
        کیف پول{" "}
        {user?.wallet?.toLocaleString("us", {
          style: "currency",
          currency: "CAD",
        })}
      </div> */}
      <div className="offcanvas-body ">
        <ul className="navbar-nav pe-3">
          <li
            className="nav-item px-3 dropdown dropdown2 "
            tabIndex="1"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <NavLink
              to={`/${window.lang}/dashboard/user/profile/me`}
              className=" shadow-none py-0 bordere-0 nav-title titr5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-person-vcard"
                viewBox="0 0 20 20"
              >
                <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5ZM9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8Zm1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Z" />
                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12V4Z" />
              </svg>
              <span className="ms-2">Go to my dashboard</span>
            </NavLink>
          </li>

          <div
            className=" titr5 text-start ms-3 border-bottom fw-bold"
            style={{ marginTop: "100px", fontSize: "18px" }}
          >
            <i className="bi bi-arrow-counterclockwise me-2"></i>
Shortcuts          </div>
          <li className="my-2" tabIndex="1" data-bs-dismiss="offcanvas">
            <NavLink to={`/${window.lang}/dashboard/user/demy/registertutor`}>
              <i className="bi bi-laptop mx-2"></i>
              <span className="small-gray-font">Add tutoring profile</span>
            </NavLink>
          </li>

          {/* <li className="my-2" tabIndex="1" data-bs-dismiss="offcanvas">
            <NavLink to={`/${window.lang}/dashboard/user/business/register`}>
              <i className="bi bi-geo mx-2"></i>
              <span className="small-gray-font"> افزودن کسب و کار</span>
            </NavLink>
          </li> */}

          {/* <li className="my-2" tabIndex="1" data-bs-dismiss="offcanvas">
            <NavLink
              // className="nav-link dropdown-toggle nav-title"
              to={`/${window.lang}/dashboard/user/jobs/register`}
            >
              <i className="bi bi-briefcase mx-2"></i>
              <span className="small-gray-font"> افزودن فرصت شغلی</span>
            </NavLink>
          </li> */}

          {/* <li className="my-2" tabIndex="1" data-bs-dismiss="offcanvas">
            <NavLink to={`/${window.lang}/dashboard/user/blog/register`}>
              <i className="bi bi-pencil-square mx-2 "></i>
              <span className="small-gray-font">افزودن پست</span>
            </NavLink>
          </li>
          <li className="my-2" tabIndex="1" data-bs-dismiss="offcanvas">
            <NavLink
              // className="nav-link dropdown-toggle nav-title"
              to={`/${window.lang}/dashboard/user/media/register`}
            >
              <i className="bi bi-people mx-2 "></i>
              <span className="small-gray-font"> افزودن شبکه اجتماعی</span>
            </NavLink>
          </li> */}
          <li className="my-2" tabIndex="1" data-bs-dismiss="offcanvas">
            <NavLink
              // className="nav-link dropdown-toggle nav-title"
              to={`/${window.lang}/store/plans`}
            >
              <i className="bi bi-mic mx-2"></i>
              <span className="small-gray-font">Ads</span>
            </NavLink>
          </li>
          <li className="my-2" tabIndex="1" data-bs-dismiss="offcanvas">
            <NavLink
              // className="nav-link dropdown-toggle nav-title"
              to={`/${window.lang}/prompt/guides`}
            >
              <i className="bi bi-info-circle pt-2 mx-2"></i>
              <span className="small-gray-font">Medademy guidlines</span>
            </NavLink>
          </li>
          {user?.affiliateInfo?.code&&
          <li className="d-flex align-items-center"> 
          <div className="mt-auto  cursor" onClick={e=>copyHandle(e)}
          >
          <i class="bi bi-link-45deg pt-2 mx-2"></i> 
          <span title="Copy" className="small-gray-font bg-beigeGray py-2">Copy affiliation link</span>
          </div> 
          <Tooltip1 message={<div className="cursor fw-bold" >Learn more about metamarce affiliation plan.</div>} /> 
          <div tabIndex="1" data-bs-dismiss="offcanvas"  className="cursor small-gray-font fs-italic mx-1" onClick={e=>{window.navigateHandle(`/${window.lang}/prompt/affiliation`);}}>(learn more ) </div>
          </li>}

        </ul>
        {/* <img
                  className="ps-5 w-100"
                  src="  /img/illustration/prompt/about-us-2.svg"
                  alt="login"
                /> */}
      </div>
      <div className=" offcanvas-footer text-center p-0 ">
        <div
          className="exit-footer d-flex align-items-center justify-content-center "
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={logoutHandle}
        >
          <div>
           Sign out
            <i className="bi bi-box-arrow-left  ps-2 "></i>
          </div>
        </div>
      </div>
    </div>
  );

  function copyHandle(e){
            
    const copyLinkElement = e.currentTarget;
  navigator.clipboard.writeText(`https://www.metamarce.com${window.location.pathname}?aff=${user?.affiliateInfo?.code}`)
.then(() => {      
const spanElement = copyLinkElement.querySelector('span');           
spanElement.textContent = "Copied!"; 
setTimeout(() => {
spanElement.textContent = "Copy affiliation link ";      
}, 2000);
})
.catch(err => console.error('Failed to copy text: ', err));
}
}


