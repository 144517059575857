import "./login.css";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../utils/api.jsx";
// import { CartContext } from "../../contexts/cartContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef, useState } from "react";
import { CircularProgress, Hidden } from "@mui/material";
import ToastNote from "../../golbal/notifications/toastNote.jsx";
import {
  CheckCircle,
  Error,
  // Visibility,
  // VisibilityOff,
} from "@mui/icons-material";
import Tooltip1 from "../../golbal/tooltip/tooltip.jsx";
import ColoredSpiner from "../../alret/spiners/coloredSpiner/coloredSpinner.jsx";
import FormikErrRender from "../../persian/common/alerts/formikErrRender.jsx";

export default function PassRecoverEn() {
  const [loading, setLoading] = useState({ spinner: false });
  const params = useParams();
  // const lowercaseRegex = /(?=.*[a-z])/;
  // const uppercaseRegex = /(?=.*[A-Z])/;
  const numericRegex = /(?=.*[0-9])/;
  // const specialcharRegex = /(?=.*[$&+,:;=?@#|'<>.^*()%!-])/;
  const combinedRegex = /^[A-Za-z0-9# $&+,:;=?@|'<>.^*()%!-]+$/;

  const messageRef = useRef(null);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState({
    password: false,
    rPass: false,
  });

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      // console.log("submit", values);
      document.querySelector("#loginTrigger")?.click();
      // var myModal = document.getElementById("exampleModalToggle"); // Get the modal element
      // var modal = window.bootstrap.Modal.getInstance(myModal);
      // modal.open();

      const el = document.querySelector("#newPassSubBtn");
      console.log("el", el);
      // el.setAttribute("visibility", 'hidden');
      el.style.visibility = "hidden";
      setLoading({ ...loading, spinner: true });
      const response = await api.passRecover2({
        id: params.id,
        token: params.token,
        newPassword: values.newPassword,
      });
      setLoading({ ...loading, spinner: false });
      // setTimeout(() => el.removeAttribute("disabled"), 1000 * 60);
      setTimeout(() => (el.style.visibility = "visible"), 1000 * 10);
      el.style.visibility = "visible";

      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              {/* <div> مشکل در انجام عملیات !</div> */}
              <div>{response.error}</div>
            </div>
          ),
        });

      messageRef.current.showSuccess({
        message: "Password successfully updated.",
      });
      setTimeout(
        () => navigate(`/${window.lang}`, { replace: true }),
        1000 * 1
      );

      var myModal = document.getElementById("exampleModalToggle"); // Get the modal element
      var modal = window.bootstrap.Modal.getInstance(myModal);
      modal.hide();
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("Please enter the password.")
        .matches(numericRegex, "At least one number")
        .matches(combinedRegex, "Only English letters")
        .min(8, "The password must contain at least 8 characters."),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "The password does not match!")
        .required("Please confirm the password."),
    }),
  });

  return (
    <div className="">
      <ToastNote messageRef={messageRef} />
      {loading.spinner && <ColoredSpiner />}
      <div className=" mx-0 row align-items-center" style={{ width: "100%" }}>
        <div
          className="col "
          style={{
            backgroundColor: "var(--brownGray)",
            color: "rgb(48, 23, 23)",
            width: "100%",
            height: "90px",
          }}
        >
          <div
            className="h2 mt-5 text-center"
            style={{ marginTop: "6rem", color: "var(--beige)" }}
          >
           Password recovery
          </div>
        </div>
      </div>

      {/* <!-- This is htmlFor curve! --> */}

      <div className="business-top-curve">
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: "100%", width: "100%" }}
        >
          <path
            d="M-2.82,59.70 C211.62,278.78 237.58,-55.75 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
            style={{ stroke: "none", fill: "var(--brownGray)" }}
          ></path>
        </svg>
      </div>
      <div className="row mx-md-5 my-5">
        <div className="col-md-6">
          <div className="wrapper mx-md-5 mx-3">
            <form
              className="w-100"
              onSubmit={formik.handleSubmit}
              name="passRecForm05"
              autoComplete="true"
            >
              <div className=" form-floating input-group box1 mt-2 py-1 ">
                <input
                  dir="ltr"
                  type={showPassword.password ? "text" : "password"}
                  autoComplete="off"
                  name="password"
                  className="form-control box6 border-0"
                  id="passwordRegister"
                  {...formik.getFieldProps("newPassword")}
                />
                <label htmlFor="passwordRegister" className="">
                  <i className="bi bi-lock-fill"></i> New password
                </label>
                <span
                  className="input-group-append pt-3 d-flex align-items-center"
                  onMouseDown={(e) =>
                    setShowPassword((pre) => ({
                      ...pre,
                      password: !pre.password,
                    }))
                  }
                  onMouseUp={(e) =>
                    setTimeout(
                      () =>
                        setShowPassword((pre) => ({
                          ...pre,
                          password: false,
                        })),
                      1000
                    )
                  }
                >
                  {showPassword.password ? (
                    <i className="bi bi-eye show-hide-icon"></i>
                  ) : (
                    <i className="bi bi-eye-slash show-hide-icon"></i>
                  )}
                </span>
              </div>
              <FormikErrRender formik={formik} field="newPassword" />

              <div className=" form-floating  input-group box1 mt-2 py-1">
                <input
                  dir="ltr"
                  type={showPassword.rPass ? "text" : "password"}
                  name="passwordRegister"
                  className="form-control box6 border-0"
                  id="confirmPassword"
                  {...formik.getFieldProps("confirmPassword")}
                />
                <label htmlFor="password2" className="">
                  <i className="bi bi-lock-fill"></i> Confirm password
                </label>
                <span
                  id="password2"
                  className="input-group-append pt-3 d-flex align-items-center"
                  onMouseDown={(e) =>
                    setShowPassword((pre) => ({
                      ...pre,
                      rPass: !pre.rPass,
                    }))
                  }
                  onMouseUp={(e) =>
                    setTimeout(
                      () =>
                        setShowPassword((pre) => ({
                          ...pre,
                          rPass: false,
                        })),
                      1000
                    )
                  }
                >
                  {showPassword.rPass ? (
                    <i className="bi bi-eye show-hide-icon"></i>
                  ) : (
                    <i className="bi bi-eye-slash show-hide-icon"></i>
                  )}
                  {/* {showPassword.rPass?<Visibility/>:<VisibilityOff/>} */}
                </span>
              </div>
              <FormikErrRender formik={formik} field="confirmPassword" />

              <SubmitBtn formik={formik} />
            </form>
          </div>
        </div>
        <div className="col-md-6">
          <img src="/img/sign-up-form.svg" alt="login" loading="lazy" />
        </div>
      </div>
    </div>
  );
  function SubmitBtn({ text, formik }) {
    return (
      <button
        disabled={formik.isSubmitting}
        className="btn btn-btn primary-btn view-cart cart-footer mt-5 w-75 mx-auto"
        style={{
          backgroundColor: Object.keys(formik.errors)?.length > 0 && "grey",
        }}
        type="submit"
        id="newPassSubBtn"
      >
        <span className="me-3">{text || "Send!"}</span>
        {loading.spinner ? (
          <CircularProgress color="inherit" size="20px" />
        ) : (
          Object.keys(formik.errors)?.length === 0 && <CheckCircle />
        )}
        <span>
          {Object.keys(formik.errors)?.length > 0 && (
            <Tooltip1
              message="Please complete the required fields."
              icon={<Error sx={{ fontSize: 18, color: "red" }} />}
              // position="left"
            />
          )}
        </span>
      </button>
    );
  }
}
