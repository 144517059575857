import { useState, useRef, useEffect, useContext } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import api from "../../../../utils/apiBusiness";
import Pagination from "../../../golbal/pagination/pagination";
import Cluster from "../../../googleMap/googleCluster";
// import Search from "../../../persian/common/pagination/searchPagination";
import renderData from "../../../../utils/renderData";
import BusinessCard from "../components/businessCard";
import SearchMultiSelect from "../../../golbal/dropdown/searchMultiSelectFilters";
import { CartContext } from "../../../contexts/cartContext";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import PlansX4 from "../../../golbal/sliders/plansX4";
import BusCardSliderS1 from "../../../golbal/sliders/busCardSliderS1";
import tools from "../../../../utils/tools";
// import MediaSidebar from "../../media/components/mediaSidebar";
import FiltersCatsSelector from "../../../golbal/filtersCatsSelector";
import MainSidebar from "../../media/components/mainSidebar";
import NewPosts2x2 from "../../../golbal/sliders/components/newPosts2x2";
import MetaDecorator from "../../../../utils/metaDecorator";
// import DropdownSearch from "../../../golbal/dropdown/DropdownSearch";
import countryStates from "../../../../utils/countryStates";
import BlogSearch from "../../../persian/common/pagination/searchPagination";

export default function BusinessFilters() {
  const navigate = useNavigate();
  const { lang, state:{state,stateShort,lat,lng}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
 

  const page = "busFilters";
  const section = "business";
  const { user } = useContext(CartContext);
  const [pubData] = useOutletContext();
  // const [showCities, setShowCities] = useState(false);
  const [states, setStates] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [view, setView] = useState({
    thumbnail: true,
    cluster: false,
  });

  const [resultsTotal, setResultsTotal] = useState(0);
  const [loading, setLoading] = useState({ fetchBus: false });

  const [data, setData] = useState([]);

  const initialFilters = {
    lang: window.lang,
    country,
    countryShort,
    states: [],
    state: "",
    stateShort: "",
    cities: [],
    mainCat: "",
    mainCatId: "",
    subCat: [],
    subCatId: [],
    searchKey: "",
    tag: "",
    saved:false,
    validation: [],
    pageNumber: 1,
    itemsToShow: 15,
    sort: "default",
  };

  const storedFilters = JSON.parse(localStorage.getItem("storedFilters"))?.[
    section
  ];


  const [filters, setFilters] = useState(tools.filtersInitiate(initialFilters,storedFilters));
  // console.log('filters',filters);
  
  const effectRan = useRef(true);
  const [backFilters, setBackFilters] = useState({ ...filters });
  useEffect(() => {
    if (effectRan.current) return;    
    fetchData();    
    setBackFilters({ ...filters });
    storeFilters({ section, filters });    
  }, [filters]);
  function storeFilters({ section, filters }) {
    const storedFilters =
      JSON.parse(localStorage.getItem("storedFilters")) ?? {};
    storedFilters[section] = filters;
    localStorage.setItem("storedFilters", JSON.stringify(storedFilters));
    return;
  }

  useEffect(() => {
    const params = {};
    for (let [key, value] of searchParams) {
      params[key] = value;
    }
     countrySet(countryShort,'first')
   
    if(Object.keys(params).length>0){
      setFilters((pre) => ({
      ...pre,
      lang: window.lang,
      pageNumber:1,
      country,
      states:params?.state ? [params?.state] : pre.states,
      mainCat: params?.mainCat ?? pre.mainCat,
      mainCatId: params?.mainCatId ?? pre.mainCatId,
      subCat: params?.subCat ? [params?.subCat] : pre.subCat,
      subCatId: params?.subCatId ? [params?.subCatId] : pre.subCatId,
      cities: params?.cities ? [params?.cities] : pre.cities,
      searchKey: params?.searchKey ?? pre.searchKey,
      tag: params?.tag ?? pre.tag,
      validation: params?.validation ? [params?.validation] : pre.validation,
    }));
    }
    effectRan.current = false;
  }, [searchParams]);

  function queryRemover(field) {
    console.log(field);
    searchParams?.delete(field);
    // searchParams.set(field,"")
    const searchString = searchParams?.toString();
    setFilters((pre) => {
      const newFilters = { ...pre };
      // newFilters[field] =""
      delete newFilters[field];
      return newFilters;
    });
    navigate(`${window.location.pathname}?${searchString}`);
  }

  async function fetchData() {
    setLoading((pre) => ({
      ...pre,
      fetchBus: true,
    }));
    const response = await api.filterBusinesses({ filters });
    setLoading((pre) => ({
      ...pre,
      fetchBus: false,
    }));
    if (response.error) return alert(response.message);
    setResultsTotal(response.data.resultsTotal);
    setData(response.data.data);
  }

  async function searchHandle(e) {
    e.preventDefault();
    const value = e.target.parentElement.querySelector("input").value;

    filters.searchKey = value;
    if (e.key === "Enter" || e.type === "click") {
      setFilters((prev) => ({
        ...prev,
        searchKey: value,
        pageNumber: 1,
      }));
    }
  }
  const itemsToShowHandle = async (itemsToS) => {
    setFilters((prev) => ({
      ...prev,
      itemsToShow: itemsToS,
      pageNumber: 1,
    }));
  };
  const pageNumberHandle = (PageN) => {
    setFilters((prev) => ({
      ...prev,
      pageNumber: PageN,
    }));
  };

  function deleteFilter(e) {
    const field = e.currentTarget.id;
    console.log("field", field);
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key] = "";
              if (key === "mainCat") {
                newFilters.mainCatId = "";
                newFilters.subCat = [];
                newFilters.subCatId = [];
                queryRemover("mainCatId");
              }
              newFilters.pageNumber = 1;
            })()
          : (newFilters[key] = newFilters?.[key]);
      }
      return newFilters;
    }

    setFilters(update());
  }

  function deleteArrayFilter(e) {
    const field = e.currentTarget.id.split(",")[0];
    const index = e.currentTarget.id.split(",")[1];
    console.log("field", field);
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key].splice(index, 1);
              newFilters.pageNumber = 1;
              if (key === "subCat") {
                newFilters.subCatId.splice(index, 1);
                queryRemover("subCatId");
              }
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }
    setFilters(update());
  }

  const  selectedFiltersRender = (()=>{
    let rows = [];

    for (let key in filters) {
      if (Array.isArray(filters[key]))
        filters[key].map((option, i) => {
          // console.log(key, renderData[key], option);
          if (key !== "states" && key !== "cities" && key !== "subCat" && key !== "subCatId") {
            const translate = renderData[key]?.find(
              (option1) => option1.name === option
            );
            // key == "languages"
            //   ? renderData[key]?.find((option1) => option1.short == option)
            //   : renderData[key]?.find((option1) => option1.name == option);
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                {/* <span>{option} </span>        */}
                <span>{translate?.[window.lang]} </span>
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }

          if (key === "states" || key === "cities" || key === "subCat") {
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                <span>{option} </span>
                {/* <span>{translate.[window.lang]} </span> */}
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }
        });

      !Array.isArray(filters[key]) &&
        key !== "lang" &&
        key !== "country" &&
        key !== "countryShort" &&
        key !== "stateShort" &&
        key !== "state" &&
        key !== "section" &&
        key !== "sort" &&
        key !== "pageNumber:" &&
        key !== "itemsToShow" &&
        key !== "mainCatId" &&
        filters[key]?.length &&
        rows.push(
          <div className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center">
            <span>
              {key === "tag" && "# "}
              {filters[key]}
            </span>
            <span className="ms-3 me-2" id={key} onClick={deleteFilter}>
              <i className="bi bi-x-lg"></i>
            </span>
          </div>
        );
    }
    return rows;
  }
)()
  function setCitiesHandle(value) {
    setFilters((prev) => ({ ...prev, cities: value, pageNumber: 1 }));
    // effectRan.current = false;
  }
  function countrySet(countryShort,first) {    
    const coun = countryStates.find(
      (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    )
    setStates(coun.states);
    setFilters((prev) => ({
      ...prev,      
      // country: coun.country,
      countryShort: countryShort.toLowerCase(),
      state:first?prev.state:"",
      stateShort: first?prev.stateShort:"",      
      states:first?prev.states:[],
      cities:first?prev.cities:[] ,
    }));
  }
  const { translations , states:states0} = JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = `لیست بیزینس های ایرانی کانادا و سراسر دنیا `;
  const structuredData=[{
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name":title,
    "url":window.location.href,
    breadcrumb: {
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,          
          item: {
            "@id": "https://www.metamarce.com/",
            name: "شروع",
            description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا"
          }
        },
        {
          "@type": "ListItem",
          "position": 2,          
          item: {
            "@id": `https://www.metamarce.com/${window.lang}`,
            name: `خانه` ,
            description: "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !"
          }
        },
        {
          "@type": "ListItem",
          "position": 3,          
          item: {
            "@id": `https://www.metamarce.com/${window.lang}/business`,
            name:` بیزنس ها`,
            description: `نیازمندیها و کسب و کارهای ایرانی کانادا و سراسر دنیا `
          }
        },
        {
          "@type": "ListItem",
          "position": 4,          
          item: {
            "@id": `https://www.metamarce.com/${window.lang}/business/filters`,
            name:title,
            description: `فیلترهای پیشرفته و پر سرعت جهت نمایش دقیق سریع کسب و کارهای کانادا و سراسر دنیا `
          }
        }
      ]       
    },
    "about": {
      "@type": "Place",
      "name": state?.toUpperCase(),
      "address": {
        "@type": "PostalAddress",
        // "addressLocality": "City",
        "addressRegion": state?.toUpperCase(),
        // "postalCode": "Postal Code",
        "addressCountry": country?.toUpperCase()
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": lat,  // Replace with the actual latitude
        "longitude": lng  // Replace with the actual longitude
      }
    },
  }]
  const metaTagsData={
    title,
    description: `معرفی و لیست کسب و های فارسی زبان کانادا و سراسر دنیا `,
    section:"فیلتر کسب و کارها",
    url:`https://www.metamarce.com/${window.lang}/business/filters`,
    // canonical:`https://www.metamarce.com/${window.lang}/business/filters`,
    img:"https://www.metamarce.com/img/illustration/buy-photography-gear-online.svg",
    type:'website',
  }
 
// console.log("filters.saved",filters.saved);
  return (
    <div>
<MetaDecorator data={{...metaTagsData,structuredData}}/>
     
      {loading.fetchBus && view.thumbnail && <ColoredSpiner />}
      {/* <!-- *********************************** main ******************************************************************** --> */}
      <nav className="px-2" aria-label="breadcrumb">
  <ol className="breadcrumb mb-0">
    <li className="breadcrumb-item">
      <Link to={`/${window.lang}`}>
        خانه
      </Link>
    </li>
    <li className="breadcrumb-item">
      <Link to={`/${window.lang}/business`}>
      کسب و کار
      </Link>
    </li>
    <li className="breadcrumb-item">  
        <strong> لیست کسب و کارها</strong>        
    </li>
  </ol>
</nav>
      <section className="mx-0 py-0">     
        <div className="row blog">
          {/* <!-- **************Right side ************** --> */}
          <div className="col-lg-9 ">
            {/* <!-- ************** MAP ************** --> */}
            <a id="top"> </a>

            {/* <!-- **************Filters  ************** --> */}

            {view.thumbnail && <div>
            <div className=" d-flex flex-wrap x-3 justify-content-between my-2 border-0 ">
                  <div className="d-flex">
                    <span className="pt-2" title="نمایش ذخیره ها">
                      <i
                        className={`bi ${
                          filters?.saved ? "bi-bookmark-fill" : "bi-bookmark"
                        } icon-stack-1x cursor`}
                        onClick={(e) => {
                          if (!window.logined(9999)) return;
                          if (user?.saved?.[section]?.length <= 0)
                            return alert(
                              "شما تاکنون آیتم ذخیره شده ای ندارین."
                            );
                          setFilters((pre) => ({
                            ...initialFilters,
                            saved: !pre.saved,
                            section,
                          }));
                        }}
                      ></i>
                    </span>
                    {resultsTotal !== 0 && (
                      <span className="box4 py-1 px-2 mx-md-2 mx-0">
                        {resultsTotal} یافته
                      </span>
                    )}
                    <div className="blog-search ">
                      <BlogSearch searchHandle={searchHandle} />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div
                      className="btn btn-primary addbusiness-btn my-2 me-2 pt-2"
                      onClick={(e) => {
                        if (!window.logined(9999, "business")) {
                          return window.navigateHandle(
                            `/${window.lang}/business/register`
                          );
                        }
                        window.navigateHandle(
                          `/${window.lang}/dashboard/user/business/register`
                        );
                      }}
                    >
                      <i className="bi bi-geo me-1"></i>
                       افزودن رایگان کسب و کار
                    </div>
                    <button
                      className="btn btn-primary button-filter me-md-3 me-0 ms-auto my-2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarTogglerDemo02"
                      aria-controls="navbarTogglerDemo02"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="bi bi-filter me-1"> فیلترها</i>
                    </button>
                  </div>
                  <div
                    className="collapse navbar-collapse "
                    id="navbarTogglerDemo02"
                  >
                    <div className="d-flex flex-wrap mt-3">
                      {/* <!-- Button filter 1 *******************************--> */}
                      <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-primary button-filter dropdown-toggle"
                      data-bs-toggle="dropdown"
                      // id="#filter5"
                      aria-expanded="false"
                      // aria-controls="filter5"
                    >
                      <span>
                        <i className="bi bi-arrow-down-up me-1"></i>
                      </span>
                      مرتب کن
                      <span className="ps-2">
                        <i className="bi bi-chevron-down"></i>
                      </span>
                    </button>

                    {/* <!-- Modal --> */}

                    <div className="dropdown-menu checkbox-form px-0 ">
                      <div className=" ">
                        <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault112"
                            checked={filters.sort === "default" && true}
                            onChange={(e) => {
                              setFilters((prev) => ({
                                ...prev,
                                sort: "default",
                                pageNumber: 1,
                              }));
                              // effectRan.current = false;
                            }}
                          />
                          <label
                            className="form-check-label "
                            htmlFor="flexRadioDefault112"
                          >
                            پیش فرض
                          </label>
                        </div>
                        <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={filters.sort === "rateB" && true}
                            onChange={(e) => {
                              setFilters((prev) => ({
                                ...prev,
                                sort: "rateB",
                                pageNumber: 1,
                              }));
                              // effectRan.current = false;
                            }}
                          />
                          <label
                            className="form-check-label "
                            htmlFor="flexRadioDefault1"
                          >
                            بالاترین امتیاز
                          </label>
                        </div>
                        <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            checked={filters.sort === "dateB" && true}
                            onChange={(e) => {
                              // effectRan.current = false;
                              setFilters((prev) => ({
                                ...prev,
                                sort: "dateB",
                                pageNumber: 1,
                              }));
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            تازه‌ترین
                          </label>
                        </div>
                        <div className="form-check px-3 d-flex justify-content-between  flex-row-reverse">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault3"
                            // value={flexRadioDefault3}
                            checked={filters.sort === "nameB" && true}
                            onChange={(e) => {
                              // effectRan.current = false;
                              setFilters((prev) => ({
                                ...prev,
                                sort: "nameB",
                                pageNumber: 1,
                              }));
                            }}
                          />
                          <span className="checkmark-radio-btn "></span>
                          <label
                            className="form-check-label "
                            htmlFor="flexRadioDefault3"
                          >
                            الفبا
                          </label>
                        </div>
                        <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault4"
                            checked={filters.sort === "viewsB" && true}
                            onChange={(e) => {
                              // effectRan.current = false;
                              setFilters((prev) => ({
                                ...prev,
                                sort: "viewsB",
                                pageNumber: 1,
                              }));
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault4"
                          >
                            بازدید
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                      {/* <!-- Button country 2 *******************************--> */}
                      {/* <div className="dropdown">
                  <button
                    type="button"
                    className="btn button-filter dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="bi bi-geo-alt me-1"></i>
                    </span>
                    کشور
                    <span className="ps-2">
                      <i className="bi bi-chevron-down"></i>
                    </span>
                  </button>

           

                  <div className="dropdown-menu checkbox-form">
                    <DropdownSearch updateParent={countrySet} />
                  </div>
                </div> */}

                      {/* <!-- Button State 2.5 *******************************--> */}
                      {filters.country?.length > 0 && (
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn button-filter dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span>
                              <i className="bi bi-geo-alt me-1"></i>
                            </span>
                            استان
                            <span className="ps-2">
                              <i className="bi bi-chevron-down"></i>
                            </span>
                          </button>

                          {/* <!-- Modal --> */}

                          <div className="dropdown-menu px-0 checkbox-form">
                            <div
                              className="reset-btn w-100"
                              onClick={(e) => {
                                setFilters((prev) => ({
                                  ...prev,
                                  states: [],
                                  state: "",
                                  stateShort: "",
                                  pageNumber: 1,
                                }));
                              }}
                            >
                              پاک کردن
                            </div>
                            {states?.map((state, i) => (
                              <div
                                key={"states" + i}
                                className="form-check d-flex justify-content-between flex-row-reverse px-3"
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  // value={state.name?.toLowerCase()}
                                  onChange={(e) => {
                                    const states = filters?.states
                                      ? [...filters.states]
                                      : [];
                                    const state1 = {
                                      stateShort: "",
                                      state: "",
                                    };
                                    const value = state.state;
                                    console.log("value01", value);
                                    console.log("states02", states);
                                    const index = states.indexOf(value);
                                    if (index !== -1) {
                                      states.splice(index, 1);
                                      const contry = countryStates.find(
                                        (count) =>
                                          count.countryShort ===
                                          filters.countryShort.toUpperCase()
                                      );
                                      console.log("contry03", contry);
                                      // const stateShort = contry.states.find(
                                      //   (state) => {
                                      //     console.log('state.state === states[0]',state.state , states[0]);
                                      //     return state.state === states[0]}
                                      // ).stateShort;
                                      const stateShort = countryStates.find(
                                        (count) =>
                                          count.countryShort ===
                                          filters.countryShort
                                      );
                                      if (states.length === 1) {
                                        state1.stateShort = stateShort;
                                        state1.state = states[0];
                                      } else {
                                        state1.stateShort = "";
                                        state1.state = "";
                                      }
                                    } else {
                                      states.push(value);
                                      if (states.length === 1) {
                                        state1.stateShort = state.stateShort;
                                        state1.state = value;
                                      } else {
                                        state1.stateShort = "";
                                        state1.state = "";
                                      }
                                    }
                                    setFilters((pre) => ({
                                      ...pre,
                                      states,
                                      state: state1.state,
                                      stateShort: state1.stateShort,
                                    }));
                                  }}
                                  checked={filters.states?.includes(
                                    state.state
                                  )}
                                  id={`statesCheckBox${i}`}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`statesCheckBox${i}`}
                                >
                                  {state?.state}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* <!-- Button City 3 *******************************--> */}
                      {filters.states.length === 1 &&
                        filters?.state?.length > 0 && (
                          <div className="dropdown">
                            <button
                              type="button"
                              className="btn button-filter dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span>
                                <i className="bi bi-geo-alt me-1"></i>
                              </span>
                              شهر/ محله
                              <span className="ps-2">
                                <i className="bi bi-chevron-down"></i>
                              </span>
                            </button>

                            {/* <!-- Modal --> */}

                            <div className="dropdown-menu checkbox-form">
                              <SearchMultiSelect
                                updateParent={setCitiesHandle}
                                data={{
                                  countryShort: filters.countryShort,
                                  stateShort: filters.stateShort,
                                  cities: filters.cities,
                                }}
                              />
                            </div>
                          </div>
                        )}

                      {/* <!-- Button MainCat 3 *******************************--> */}
                      <FiltersCatsSelector
                    section={section}
                    filters={filters}
                    setFilters={setFilters}
                  />

                      {/* <!-- Button filter 5 *******************************--> */}
                      <div className="dropdown">

<button
  className="btn btn-primary button-filter"
  type="button"
  data-bs-toggle="offcanvas"
  data-bs-target="#offcanvasWithBothOptionsFilters"
  aria-controls="offcanvasWithBothOptionsFilters"
>
  <span>
    <i className="bi bi-filter me-1"></i>
  </span>
  فیلتر بیشتر
  <span className="ps-2">
    <i className="bi bi-chevron-down"></i>
  </span>
</button>

<div
  className="offcanvas offcanvas-end offcanvas-countryLanguage "
  data-bs-scroll="true"
  tabIndex="-1"
  id="offcanvasWithBothOptionsFilters"
  aria-labelledby="offcanvasWithBothOptionsLabelFilters"
>
  <div className="offcanvas-header offcanvas-countryLanguageTitle ">
    <h5
      className="offcanvas-title "
      id="offcanvasWithBothOptionsLabelFilters"
    >
      فیلتر بیشتر
    </h5>
    <button
      type="button"
      className="btn-close"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>

  <div className="offcanvas-footer  text-center px-5 py-4">
    <button
      className="btn  checkout-btn  text-center w-100 mb-2 py-2"
      type="button"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
      onClick={(e) => {
        setFilters(initialFilters)
        // removing all url queries
        navigate('')
      }}
    >
      پاک کردن فیلترها
    </button>
    <button
      className="btn  view-cart text-center w-100 py-2"
      type="button"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
      onClick={(e) => {
        setFilters(backFilters);
      }}
    >
      نمایش موارد
    </button>
  </div>

  <div className="offcanvas-body px-0 pb-5 pt-0">
    <div
      className="accordion w-100 p-0 m-0"
      id="accordionPanelsStayOpenBusinessFilters"
    >
      <div className="accordion-item">
        <h2
          className="accordion-header"
          id="panelsStayOpenBusinessFilters-headingZero"
        >
          <button
            className="accordion-button collapsed accordion-button-business-filter"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpenBusinessFilters-collapseZero"
            aria-expanded="false"
            aria-controls="panelsStayOpenBusinessFilters-collapseZero"
          >
            <p className="form-titr2">امتیاز </p>
          </button>
        </h2>
        <div
          id="panelsStayOpenBusinessFilters-collapseZero"
          className="accordion-collapse collapse"
          aria-labelledby="panelsStayOpenBusinessFilters-headingZero"
        >
          <div className="accordion-body form-font px-5">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="4"
                checked={backFilters?.rate?.slice(0, 1) == 4}
                id={`flexCheckRates4`}
                name="rateFilter"
                onChange={(e) => {
                  setBackFilters((prev) => ({
                    ...prev,
                    rate: "4 ستاره و بیشتر",
                    pageNumber: 1,
                  }));
                }}
              />

              <label
                className="form-check-label d-flex"
                htmlFor={`flexCheckRates4`}
              >
                <div className="star-filter me-1">
                  <i className="bi bi-star-fill ps-1"></i>
                  <i className="bi bi-star-fill ps-1"></i>
                  <i className="bi bi-star-fill ps-1"></i>
                  <i className="bi bi-star-fill ps-1"></i>
                  <i className="bi bi-star ps-1"></i>
                </div>
                <span> و بیشتر</span>
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="3"
                checked={backFilters?.rate?.slice(0, 1) == 3}
                id={`flexCheckRates3`}
                name="rateFilter"
                onChange={(e) => {
                  setBackFilters((prev) => ({
                    ...prev,
                    rate: "3 ستاره و بیشتر",
                    pageNumber: 1,
                  }));
                  // effectRan.current = false;
                }}
              />
              <label
                className="form-check-label d-flex"
                htmlFor={`flexCheckRates3`}
              >
                <div className="star-filter me-1">
                  <i className="bi bi-star-fill ps-1"></i>
                  <i className="bi bi-star-fill ps-1"></i>
                  <i className="bi bi-star-fill ps-1"></i>
                  <i className="bi bi-star ps-1"></i>
                  <i className="bi bi-star ps-1"></i>
                </div>
                <span> و بیشتر</span>
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="2"
                checked={backFilters?.rate?.slice(0, 1) == 2}
                onChange={(e) => {
                  setBackFilters((prev) => ({
                    ...prev,
                    rate: "2 ستاره و بیشتر",
                    pageNumber: 1,
                  }));
                  // effectRan.current = false;
                }}
                id={`flexCheckRates2`}
                name="rateFilter"
              />
              <label
                className="form-check-label d-flex"
                htmlFor={`flexCheckRates2`}
              >
                <div className="star-filter me-1">
                  <i className="bi bi-star-fill ps-1"></i>
                  <i className="bi bi-star-fill ps-1"></i>
                  <i className="bi bi-star ps-1"></i>
                  <i className="bi bi-star ps-1"></i>
                  <i className="bi bi-star ps-1"></i>
                </div>
                <span> و بیشتر</span>
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="1"
                checked={
                  backFilters?.rate?.slice(0, 1) == 1 && true
                }
                onChange={(e) => {
                  setBackFilters((prev) => ({
                    ...prev,
                    rate: "1 ستاره و بیشتر",
                    pageNumber: 1,
                  }));
                  // effectRan.current = false;
                }}
                id={`flexCheckRates1`}
                name="rateFilter"
              />
              <label
                className="form-check-label d-flex"
                htmlFor={`flexCheckRates1`}
              >
                <div className="star-filter me-1">
                  <i className="bi bi-star-fill ps-1"></i>
                  <i className="bi bi-star ps-1"></i>
                  <i className="bi bi-star ps-1"></i>
                  <i className="bi bi-star ps-1"></i>
                  <i className="bi bi-star ps-1"></i>
                </div>
                <span> و بیشتر</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="accordion-item">
        <h2
          className="accordion-header"
          id="panelsStayOpenBusinessFilters-headingOne"
        >
          <button
            className="accordion-button collapsed accordion-button-business-filter "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpenBusinessFilters-collapseOne"
            aria-expanded="false"
            aria-controls="panelsStayOpenBusinessFilters-collapseOne"
          >
            <p className="form-titr2">
              {/* <span>
            <i className="bi bi-circle-fill "></i>
          </span> */}
              زبان پاسخگویی
            </p>
          </button>
        </h2>
        <div
          id="panelsStayOpenBusinessFilters-collapseOne"
          className="accordion-collapse collapse"
          aria-labelledby="panelsStayOpenBusinessFilters-headingOne"
        >
          <div className="accordion-body form-font px-5  row row-cols-3 ">
            {renderData.languages.map((lang, i) =>
              !lang.fa.length ? (
                ""
              ) : (
                <div key={i} className="morelang-line ">
                  <input
                    className="form-check-input  red moreLang"
                    type="checkbox"
                    value={lang.name}
                    checked={backFilters.languages?.includes(
                      lang.name
                    )}
                    id={`flexLanguages${i}`}
                    onChange={(e) => {
                      const languages = backFilters?.languages
                        ? [...backFilters.languages]
                        : [];
                      const value = e.target.value;
                      const index = languages.indexOf(value);
                      if (index !== -1) {
                        languages.splice(index, 1);
                      } else {
                        languages.push(value);
                      }
                      setBackFilters((pre) => ({
                        ...pre,
                        languages,
                      }));
                    }}
                  />
                  <label
                    className="form-check-label ps-2 red moreLang"
                    htmlFor={`flexLanguages${i}`}
                  >
                    {lang[window.lang]}
                  </label>
                </div>
              )
            )}

            <button
              className="btn btn-primary button-filter py-0 mt-3"
              type="button"
            >
              <label htmlFor="moreLangCheckbox">
                بیشتر...
              </label>
              {/* <span>
            <i className="bi bi-three-dots ps-2 py-0 my-0"></i>
          </span> */}
              <input
                id="moreLangCheckbox"
                type="checkbox"
                onClick={(e) => {
                  const elemet =
                    document.querySelectorAll(".moreLang");
                  if (e.target.checked) {
                    document.querySelector(
                      "label[for=moreLangCheckbox]"
                    ).innerHTML = "کمتر...";
                    Array.from(elemet).map(
                      (el) =>
                        (el.style.display = "inline-block")
                    );
                  } else {
                    document.querySelector(
                      "label[for=moreLangCheckbox]"
                    ).innerHTML = "بیشتر...";
                    Array.from(elemet).map(
                      (el) => (el.style.display = "none")
                    );
                  }
                }}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2
          className="accordion-header"
          id="panelsStayOpenBusinessFilters-headingTwo"
        >
          <button
            className="accordion-button collapsed accordion-button-business-filter"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpenBusinessFilters-collapseTwo"
            aria-expanded="false"
            aria-controls="panelsStayOpenBusinessFilters-collapseTwo"
          >
            <p className="form-titr2">
              {/* <span>
            <i className="bi bi-circle-fill "></i>
          </span> */}
              چگونگی ارايه خدمات
            </p>
          </button>
        </h2>
        <div
          id="panelsStayOpenBusinessFilters-collapseTwo"
          className="accordion-collapse collapse"
          aria-labelledby="panelsStayOpenBusinessFilters-headingTwo"
        >
          <div className="accordion-body form-font px-5">
            {renderData.serviceTypes.map((serT1, i) => (
              <div key={i} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={serT1.name}
                  checked={backFilters.serviceTypes?.includes(
                    serT1.name
                  )}
                  id={`checkST${i}`}
                  onChange={(e) => {
                    const serviceTypes =
                      backFilters?.serviceTypes
                        ? [...backFilters.serviceTypes]
                        : [];
                    const value = e.target.value;
                    const index = serviceTypes.indexOf(value);
                    if (index !== -1) {
                      serviceTypes.splice(index, 1);
                    } else {
                      serviceTypes.push(value);
                    }
                    setBackFilters((pre) => ({
                      ...pre,
                      serviceTypes,
                    }));
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor={`checkST${i}`}
                >
                  {serT1?.[window.lang]}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2
          className="accordion-header"
          id="panelsStayOpenBusinessFilters-headingThree"
        >
          <button
            className="accordion-button collapsed accordion-button-business-filter"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpenBusinessFilters-collapseThree"
            aria-expanded="false"
            aria-controls="panelsStayOpenBusinessFilters-collapseThree"
          >
            <p className="form-titr2">چگونگی پرداخت</p>
          </button>
        </h2>
        <div
          id="panelsStayOpenBusinessFilters-collapseThree"
          className="accordion-collapse collapse"
          aria-labelledby="panelsStayOpenBusinessFilters-headingThree"
        >
          <div className="accordion-body form-font px-5">
            {renderData.paymentMethods.map((payM1, i) => (
              <div key={i} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={payM1.name}
                  checked={backFilters.paymentMethods?.includes(
                    payM1.name
                  )}
                  id={`checkPM${i}`}
                  onChange={(e) => {
                    const paymentMethods =
                      backFilters?.paymentMethods
                        ? [...backFilters.paymentMethods]
                        : [];
                    const value = e.target.value;
                    const index =
                      paymentMethods.indexOf(value);
                    if (index !== -1) {
                      paymentMethods.splice(index, 1);
                    } else {
                      paymentMethods.push(value);
                    }
                    setBackFilters((pre) => ({
                      ...pre,
                      paymentMethods,
                    }));
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor={`checkPM${i}`}
                >
                  {payM1?.[window.lang]}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2
          className="accordion-header"
          id="panelsStayOpenBusinessFilters-headingFour"
        >
          <button
            className="accordion-button collapsed accordion-button-business-filter"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpenBusinessFilters-collapseFour"
            aria-expanded="false"
            aria-controls="panelsStayOpenBusinessFilters-collapseFour"
          >
            <p className="form-titr2 ps-2">
              <span>
                {/* <i className="bi bi-circle-fill "></i> */}
              </span>
              امکانات
            </p>
          </button>
        </h2>
        <div
          id="panelsStayOpenBusinessFilters-collapseFour"
          className="accordion-collapse collapse"
          aria-labelledby="panelsStayOpenBusinessFilters-headingFour"
        >
          <div className="accordion-body form-font px-5 mb-5">
            <div className="row row-cols-2   justify-content-evenly g-4">
              {renderData.facilities.map((facility, i) => (
                <div key={i} className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={facility.name}
                    checked={backFilters.facilities?.includes(
                      facility.name
                    )}
                    id={`flexCheckfacilities${i}`}
                    onChange={(e) => {
                      const facilities =
                        backFilters?.facilities
                          ? [...backFilters.facilities]
                          : [];
                      const value = e.target.value;
                      const index = facilities.indexOf(value);
                      if (index !== -1) {
                        facilities.splice(index, 1);
                      } else {
                        facilities.push(value);
                      }
                      setBackFilters((pre) => ({
                        ...pre,
                        facilities,
                      }));
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`flexCheckfacilities${i}`}
                  >
                    {facility?.[window.lang]}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="accordion-item">
        <h2
          className="accordion-header"
          id="panelsStayOpenBusinessFilters-heading-Five"
        >
          <button
            className="accordion-button collapsed accordion-button-business-filter"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpenBusinessFilters-collapse-Five"
            aria-expanded="false"
            aria-controls="panelsStayOpenBusinessFilters-collapse-Five"
          >
            <p className="form-titr2">اعتبارسنجی </p>
          </button>
        </h2>
        <div
          id="panelsStayOpenBusinessFilters-collapse-Five"
          className="accordion-collapse collapse"
          aria-labelledby="panelsStayOpenBusinessFilters-heading-Five"
        >
          <div className="accordion-body form-font px-md-5 px-4">
            {renderData.validation.map((valid, i) => (
              <div
                key={i}
                className="form-check form-switch m-0 p-1"
              >
                <input
                  className="form-check-input form-check-input-filter1 m-0 p-0"
                  type="checkbox"
                  role="switch"
                  id={`validation${i}`}
                  checked={backFilters?.validation?.includes(
                    valid.name
                  )}
                  value={valid.name}
                  onChange={(e) => {
                    const validation = backFilters?.validation
                      ? [...backFilters.validation]
                      : [];
                    const value = e.target.value;
                    const index = validation.indexOf(value);
                    if (index !== -1) {
                      validation.splice(index, 1);
                    } else {
                      validation.push(value);
                    }
                    setBackFilters((pre) => ({
                      ...pre,
                      validation,
                    }));
                  }}
                />
                <label
                  className="form-check-label px-2"
                  htmlFor={`validation${i}`}
                >
                  {valid?.[window.lang]}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>

  {/* ***************** */}
</div>
</div>
                    </div>
                  </div>
                  
                </div>
                <div className=" d-flex flex-wrap my-2 show-filters">
                <div
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasScrolling2"
                aria-controls="offcanvasScrolling2"
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center img-banner-text-7"
              >
                <span>{window.capitalize(filters?.country)}</span>
                <span className="ms-3 me-2">
                  <i className="bi bi-arrow-repeat"></i>
                </span>
              </div>
                 
                  {selectedFiltersRender}
                  {selectedFiltersRender.length > 0 && (
                    <div
                      className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center img-banner-text-7 px-3 filter-del-btn"
                      onClick={(e) => {
                        setFilters(initialFilters)
                        // removing all url queries
                        navigate('')
                      }}
                    >
                      <span>پاک کردن فیلترها</span>
                    </div>
                  )}
                </div>
                </div>
                }
            {/* <!-- Pagination *******************************--> */}

            <div className="mx-1 p-0 mb-2 ">
              <div className="d-flex justify-content-between">
                <div className="">
                  {view.thumbnail && (
                    <Pagination
                      data={{ resultsTotal, itemsToShow: filters.itemsToShow }}
                      setPageNumber={pageNumberHandle}
                      pageNumber={filters.pageNumber}
                      setItemsToShow={itemsToShowHandle}
                      position="top"
                    />
                  )}
                </div>
                <div className="d-flex">
                      <span
                        className={`me-1 map-card my-2 d-flex justify-content-center ${
                          view.cluster && "map-card-active"
                        }`}
                        onClick={(e) =>
                          setView({ thumbnail: false, cluster: true })
                        }
                      >
                        نقشه <i className="bi bi-map mx-1 "></i>
                      </span>
                      <span
                        className={`me-1 map-card my-2 d-flex justify-content-center ${
                          view.thumbnail && "map-card-active"
                        }`}
                        onClick={(e) =>
                          setView({ thumbnail: true, cluster: false })
                        }
                      >
                        کارت<i className="bi bi-image mx-1"></i>
                      </span>
                    </div>           
              </div>

              {/* <img style={{display:view.thumbnail &&loading.fetchBus?'block':'none' }} src="/img/searching-error.svg" alt="Searching..." /> */}
              {data?.length <= 0&& !loading.fetchBus ? (
                <div className="row m-5 px-md-5 px-0">
                  <div className="col-md-6 d-flex align-items-center justify-content-center px-0">
                    داده ای پیدا نشد، لطفا فیلتر های دیگر را امتحان کنید !
                    <div
                      className="btn-search shadow-none m-2 d-flex  align-items-center filter-del-btn img-banner-text-7"
                      onClick={(e) =>
                        document.querySelector("#lcsChangeBtn").click()
                      }
                    >
                      دیگر استانها یا کشورها
                    </div>
                  </div>
                  <div className="col-md-6 d-flex align-items-center justify-content-center">
                    <img src="/img/cart-is-empty.svg" alt="no data found" />
                  </div>
                </div>
              ) : (
                <div className="row row-cols-1 row-cols-md-3 justify-content-evenly g-4">
                  {view.thumbnail &&
                    data?.map((business, i) => (
                      <div key={i}>
                        <BusinessCard
                          data={business}
                          setFilters={setFilters}
                        />
                      </div>
                    ))}
                </div>
              )}

              {view.cluster && (
                <div className="listing-all-map px-0 mx-2">
                  <Cluster
                    data={data}
                    state={{lat,lng}}
                    filters={filters}
                    section="business"
                  />     
                </div>
              )}

              <div className="mt-5">
                {view.thumbnail && (
                  <Pagination
                    data={{ resultsTotal, itemsToShow: filters.itemsToShow }}
                    setItemsToShow={itemsToShowHandle}
                    setPageNumber={pageNumberHandle}
                    pageNumber={filters.pageNumber}
                    position="down"
                  />
                )}
              </div>
            </div>
          </div>
          {/* <!-- ********left side *************************************************************** --> */}
          <div className="col-lg-3  sidebar-all align-items-center text-center  p-md-4 p-1">
            <MainSidebar section={section} />
          </div>
        </div>

        {/* <!-- *********** end of listing ******* --> */}
      </section>

      <section className="mx-md-5 pt-0 mx-0">
        {/* <!-- تخفیف‌های ویژه --> */}
        <div className="divider2 ">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 ">برگزیدگان</span>
        </div>

        <BusCardSliderS1 plansS1={pubData?.ads?.plansS1} page={page} />

        <div className="divider2 mb-0">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 ">معرفی ویژه</span>
          {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> --> */}
        </div>

        <PlansX4 plansX4={pubData?.ads?.plansX4} page={page} />
      </section>

      {/* <!-- تازه‌های بلاگ  --> */}

       {/* start the most recent posts 2x2*/}
       <div className=" mx-md-5 ">
          <div className="divider2 ">
             
            <svg
              className="text-dark-550 dark:text-white ml-2"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
              <circle
                cx="35"
                cy="16"
                r="8"
                fill="currentColor"
                fillOpacity="0.4"
              ></circle>
              <circle
                cx="20"
                cy="8"
                r="5"
                fill="currentColor"
                fillOpacity="0.7"
              ></circle>
              {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
            </svg>
            <span className="p-0 mb-0 ">تازه‌های بلاگ</span>
            {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> --> */}
          </div>
          <div className="row row-cols-1 row-cols-lg-2 g-4 my-3 ads-all mx-auto ">
            <NewPosts2x2 newPosts={pubData?.newPosts} />
          </div>
        </div>
        {/* end the most recent posts 2x2*/}

      {/* <!-- *********************************** end of main ******************************************************************** --> */}

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
      {/* <!-- **********end of back to top************************* --> */}
    </div>
  );

}
