import React, { useContext } from "react";
import { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";
import api from "../../../../../../utils/api";
import { CartContext } from "../../../../../contexts/cartContext";
import tools from "../../../../../../utils/tools";

Quill.register("modules/imageUploader", ImageUploader);
const uploadHandle = async (file) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const resizedFile = await tools.resizeFile({ file, blob: true });
  console.log("resizedFile", resizedFile.size);

  const formData = new FormData();
  formData.append("file", resizedFile);
  const response = await api.uploadSingleFile({
    token: user.token,
    formData,
    path: "images/blog",
  });
  if (response.error) return window.alert(response.error);
  return response.data;
};

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "Inter",
  "lucida",
];
Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = (props) => ({
  toolbar: {
    container: "#" + props,
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },
  imageUploader: {
    upload: uploadHandle,
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
  clipboard: {
    matchers: [
      [Node.ELEMENT_NODE, customLinkMatcher],
      // [Node.TEXT_NODE, customMatcherB] // You can define another custom matcher for text nodes if needed
    ],
  },
});
function customLinkMatcher(node, delta) {
  // console.log("node.tagName00", node.tagName);
  // console.log("node01", node, delta);
  if (node.tagName === "A") {
    const href = node.getAttribute("href");
    console.log("href02", href);
    // console.log('href.startsWith',href.startsWith('https://www.metamarce.com'));
    if (removeLink(href)) {
      window.alert(
        ' از لینک به مطالب داخل سایت استفاده کنید. "https://www.metamarce.com"'
      );
      delta.ops = []; // Remove the link from the clipboard data
    }
  }
  return delta;
}
function removeLink(href) {
  const user = JSON.parse(localStorage.getItem("user"));
  console.log("href03", href);
  const internalDomain = "https://www.metamarce.com";
  let isExternal = true;
  if (href.startsWith(internalDomain)) isExternal = false;
  if (tools.compareCheck(user.roles, [1000, 1100])) isExternal = false;
  console.log("isExternal04", isExternal);
  return isExternal;
}

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "code-block",
  'custom-ul-class'
];

// Quill Toolbar component
export const QuillToolbar = (props) => {
  const cartContext = useContext(CartContext);
  const { user } = cartContext;
  // console.log(user);

  return (
    <>
      {props.toolbarId !== undefined && (
        <div id={props.toolbarId}>
          <span className="ql-formats">
            <button className="ql-bold" title="Bold" />
            <button className="ql-italic" title="Italic" />
            <button className="ql-underline" title="Underline" />
            <button className="ql-strike" title="Strike" />
          </span>
          <span className="ql-formats">
            <select className="ql-font" name="ql-font" title="Font">
              <option value="arial"> Arial </option>
              <option value="comic-sans">Comic Sans</option>
              <option value="courier-new">Courier New</option>
              <option value="georgia">Georgia</option>
              <option value="helvetica">Helvetica</option>
              <option value="Inter" selected>
                Inter
              </option>
              <option value="lucida">Lucida</option>
            </select>
            <select className="ql-size" name="ql-size" title="Font size">
              <option value="extra-small">Extra Small</option>
              <option value="small">Small</option>
              <option value="medium" selected>
                Medium
              </option>
              <option value="large">Large</option>
            </select>
            <select className="ql-header" name="ql-header" title="Header">
              <option value="1">Heading 1</option>
              <option value="2">Heading 2</option>
              <option value="3">Heading 3</option>
              <option value="4">Heading 4</option>
              <option value="5">Heading 5</option>
              <option value="6">Heading 6</option>
              <option value="" selected>
                Normal
              </option>
            </select>
          </span>
          <span className="ql-formats">
            <button className="ql-list" value="ordered" title="Ordered" />
            <button className="ql-list" value="bullet" title="Bullet" />
            <button className="ql-indent" value="-1" title="Indent -1" />
            <button className="ql-indent" value="+1" title="Indent +1" />
          </span>
          <span className="ql-formats">
            <button className="ql-script" value="super" title="Super" />
            <button className="ql-script" value="sub" title="Sub" />
            <button className="ql-blockquote" title="Block quote" />
            <button className="ql-direction" title="Direction" />
          </span>
          <span className="ql-formats">
            <select className="ql-align" title="Align" />
            <select className="ql-color" name="ql-color01" title="Color" />
            <select
              className="ql-background"
              name="ql-backgrounColor"
              title="Backgroun color"
            />
          </span>
          <span className="ql-formats">
            <button className="ql-link" title="Link" />
            <button className="ql-image" title="Image" />
            <button className="ql-video" title="Video" />
          </span>
          <span className="ql-formats">
            <button className="ql-formula" title="Formula" />
            <button className="ql-code-block" title="Code-block" />
            <button className="ql-clean" title="Clean" />
          </span>
          <span className="ql-formats">
            <button className="ql-undo" title="undo">
              <CustomUndo />
            </button>
            <button className="ql-redo" title="redo">
              <CustomRedo />
            </button>
          </span>
        </div>
      )}
    </>
  );
};
export default QuillToolbar;
