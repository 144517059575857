import React, { useContext, useEffect, useRef, useState } from "react";
import "./seatingMap.css"; // Create a separate CSS file for styling
import { useParams } from "react-router-dom";
import apiEvent from "../../../../utils/apiEvent";
import ToastNote from "../../../golbal/notifications/toastNote";
import tools from "../../../../utils/tools";
import { CartContext } from "../../../contexts/cartContext";
import MetaDecorator from "../../../../utils/metaDecorator";

const SeatingMap = () => {
  const messageRef = useRef(null);
  const { serviceIncrement, serviceItems, user, cartEmptier } =
    useContext(CartContext);
  const [selectedSeats, setSelectedSeats] = useState([]);
  console.log("selectedSeats", selectedSeats);
  const [mainData, setMainData] = useState([]);
  const [clickedSeat, setClickedSeat] = useState("");
  const {
    currency,
    currency_symbol,
    exRate,
    convertRate,
    accepted,
    translations,
  } = JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const curCom = {
    currency,
    currency_symbol,
    exRate,
    convertRate,
    accepted,
    translations,
    // state,
    // showNumber,
  };

  const { id } = useParams();
  console.log("clickedSeat", clickedSeat);
  const toggleSeat = (seatNumber) => {
    setClickedSeat(seatNumber);
    if (!selectedSeats?.includes(seatNumber)) {
      setSelectedSeats([...selectedSeats, seatNumber]);
    } else {
      setSelectedSeats(selectedSeats.filter((seat) => seat !== seatNumber));
    }
  };

  useEffect(() => {
    fetchSeatsDetails();
  }, [id]);

  async function fetchSeatsDetails() {
    if (id?.length !== 24)
      return messageRef.current.showError({
        message: "شناسه صحیح نمی باشد،  دوباره وارد این صفحه شوید.",
      });
    const response = await apiEvent.fetchSeatsDetails({ id });
    if (response.error) return alert(response.error);
    setMainData(response.data);
  }
  const metaTagsData = {
    title: "بوکینگ یک رویداد در متامارس",
    description: "بوکینگ یک رویداد در متامارس",
    section: "booking",
    url: `${window.location.origin}/${window.lang}/event/booking/${mainData?._id}`,
    img: "https://www.metamarce.com/img/illustration/buy-photography-gear-online.svg",
    type: "webpage",
    index: false,
  };

  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData }} />

      <ToastNote messageRef={messageRef} />
      {/* <BookingModal clickedSeat={clickedSeat}/>       */}
      <div className="seating-map">
        {/* {renderSeats()} */}
        {mainData?.moreInfo?.seats?.seats?.map((seat, i) => {
          return (
            <button
              onClick={() => {
                const text =
                  seat.status === "selected"
                    ? "این جایگاه برای ده دقیقه رزرو شده است."
                    : seat.status === "booked"
                    ? "متاسفانه این جایگاه فروخته شده است."
                    : "";
                if (seat.status !== "available")
                  return messageRef.current.showWarning({ message: text });
                setClickedSeat(seat);
                var myModal = document.getElementById("bookingModal"); // Get the modal element
                var modal = new window.bootstrap.Modal(myModal, {
                  // backdrop: "static",
                  keyboard: true,
                });
                modal.show();
              }}
              type="button"
              // data-bs-toggle="modal"
              // data-bs-target={"#bookingModal"}
              key={seat.number}
              id={seat.number}
              title={seat.status + " | " + seat.number.split(",").join(" ")}
              // className={`seat ${ isBooked ? 'booked' : selectedSeats?.includes(seat.number) ? 'selected' : 'available'}`}
              className={`seat ${seat.status}`}
            ></button>
          );
        })}
      </div>
      <div
        className="modal fade"
        id="bookingModal"
        tabIndex="-1"
        aria-labelledby="claimModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="claimModalLabel">
                مشخصات صندلی
              </h1>
              <button
                type="button"
                id="modal-colse-btn"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-7">
                  <p>
                    <h4>{mainData.moreInfo?.title[window.lang]}</h4>
                  </p>
                  <p>شماره صندلی :{clickedSeat?.number}</p>
                  <p>
                    قیمت :
                    {tools.showPrice(clickedSeat?.rate, clickedSeat?.currency)}
                  </p>
                  <div
                    type="button"
                    className="btn btn-outline-secondary save-cover-btn ms-3"
                    // data-bs-dismiss="modal"
                    onClick={addToCartHandle}
                  >
                    افزودن به سبد
                  </div>
                  <div className="claim-box"></div>
                </div>
                <div className="col-md-5 align-items-center my-5 display-none-md">
                  <img
                    src="/img/illustration/task-animate.svg"
                    alt="claim"
                    loading="lazy"
                    // style={{ verticalAlign: "center" }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <a
                href={`/${window.lang}/prompt/claimBen`}
                target="_blank"
                className="btn btn-outline-secondary save-cover-btn ms-3"
              >
                چرا باید ادعا کنم؟
              </a>
              <button
                type="button"
                className="btn btn-outline-secondary save-cover-btn ms-3"
                data-bs-dismiss="modal"
              >
                خیر و بستن صفحه
              </button>
              {/* <button
            type="button"
            className="btn btn-outline-secondary save-cover-btn ms-3"
          >
            ادعای مالکیت و تکمیل ثبت نام
          </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function addToCartHandle() {
    console.log("addToCartHandle");
    const text =
      clickedSeat.status === "selected"
        ? "این جایگاه برای ده دقیقه رزرو شده است."
        : clickedSeat.status === "booked"
        ? "متاسفانه این جایگاه فروخته شده است."
        : "لطفا سایر جایگاه ها را انتخاب نمائید.";
    if (clickedSeat.status !== "available")
      return messageRef.current.showWarning({ message: text });

    serviceIncrement({
      type: "eventTicket",
      items: [{ ...mainData, ...clickedSeat }],
      lcs: window.lcs,
      accepted: curCom?.accepted,
      currency: curCom?.currency,
      currency_symbol: curCom?.currency_symbol,
      convertRate: curCom?.convertRate,
      exRate: curCom?.exRate,
      price: Math.ceil(clickedSeat.rate * curCom?.exRate * curCom?.convertRate),
      count: 1,
      _id: clickedSeat._id,
      title: `${mainData?.artistInfo?.name[window.lang]}-${tools.toGreg(
        mainData?.moreInfo?.dates?.eventDate
      )}-${clickedSeat.number}`,
      img: mainData?.artistInfo.img,
      itemImg: mainData?.img,
      targetIdentifier: "Seat",
    });
    messageRef.current.showSuccess({
      message: "بلیط با موفقیت به سبد خریداضافه شد.",
    });
    document.querySelector("#modal-colse-btn").click();
    document.querySelector("#sidebarRightTrigger").click();
  }
};

export default SeatingMap;
