import { NavLink } from "react-router-dom";

export default function UserDashSavedNav() {
  const trans={   
    post:{fa:"پست",en:"Post"},
    business:{fa:"کسب و کار",en:"Business"},
    jobs:{fa:"کاریابی",en:"Job"},
    media:{fa:"شبکه اجتماعی",en:"Media"},
   
  }
  return (
    <div className="dashboardNavigator d-flex">
      <NavLink
        to={`/${window.lang}/dashboard/user/saved/posts`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
               {trans.post[window.lang]}

      </NavLink>
      <NavLink
        to={`/${window.lang}/dashboard/user/saved/business`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {trans.business[window.lang]}
      </NavLink>
      <NavLink
        to={`/${window.lang}/dashboard/user/saved/jobs`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
         {trans.jobs[window.lang]}
      </NavLink>
      <NavLink
        to={`/${window.lang}/dashboard/user/saved/media`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
          {trans.media[window.lang]}
      </NavLink>
      {/* <       NavLink to={`/${window.lang}/dashboard/user/saved/products`} className={`sidebarListItem m-1 link  ${({isActive})=>{return isActive && "active" }}`}>محصول </NavLink> */}
    </div>
  );
}
