import axios from "axios";

const proxy = "http://localhost:5001";
export default new (class {
  walletInfo = async () => {
    try {       
        const response = await axios.get(`${proxy}/api/chain/wallet-Info`)
        if(response.status!==200){throw new Error(response.data.message)}
      return response.data;
        
    } catch (error) {
      return  {error:  error?.response?.data?.message || error.message}          
    }
  };
  getBlocks = async () => {
    try {       
      const response = await axios.get(`${proxy}/api/chain/blocks`)
      if(response.status!==200){throw new Error(response.data.message)}
      return response.data;        
    } catch (error) {
      return  {error:  error?.response?.data?.message || error.message}          
    }
  };
  getPoolTransactions = async () => {
    try {       
      const response = await axios.get(`${proxy}/api/chain/transactionPool`)
      if(response.status!==200){throw new Error(response.data.message)}
      return response.data;        
    } catch (error) {
      return  {error:  error?.response?.data?.message || error.message}          
    }
  };
  getKnownAddresses = async () => {
    try {       
      const response = await axios.get(`${proxy}/api/chain/knownAddresses`)
      if(response.status!==200){throw new Error(response.data.message)}
      return response.data;        
    } catch (error) {
      return  {error:  error?.response?.data?.message || error.message}          
    }
  };
  createTransaction = async ({transaction}) => {
    try {       
      const response = await axios.post(`${proxy}/api/chain/transact`,transaction)
      if(response.status!==200){throw new Error(response.data.message)}
      return response.data;        
    } catch (error) {
      return  {error:  error?.response?.data?.message || error.message}          
    }
  };
})();
