import React, { useEffect, useState } from "react";
import apiChain from "../../../../../../utils/apiChain";
import CreateTransaction from "./components/createTransaction";
import KnownAddresses from "./components/knownAddresses";

export default function MyWallet() {
  const [wallet, setWallet] = useState({});
  const [blocks, setBlocks] = useState([]);
  const [pool, setPool] = useState({});
  console.log("blocks", blocks);

  //   useEffect(() => {
  //     fetchData();
  //   }, []);

  async function fetchData() {
    const response = await apiChain.walletInfo();
    if (response.error) return window.alert(response.error);
    console.log("response", response);
    setWallet(response.data);
  }
  async function getBlocks() {
    const response = await apiChain.getBlocks();
    if (response.error) return window.alert(response.error);
    console.log("response", response);
    setBlocks(response.data);
  }
  async function getPoolTransactions() {
    const response = await apiChain.getPoolTransactions();
    if (response.error) return window.alert(response.error);
    console.log("response", response);
    // return;
    setPool(response.data);
  }
  return (
    <div dir="ltr">
      <div>
        <div>
          <div className="btn btn-primary m-2" onClick={(e) => fetchData()}>
            مشاهده کیف پول
          </div>
          <div>Balance : {wallet?.balance}</div>
          <div>Address : {wallet?.address?.substring(0, 20) + "..."}</div>
        </div>
        <div>
          <div className="btn btn-primary m-2" onClick={(e) => getBlocks()}>
            مشاهده تراکنش ها
          </div>
          <div className="d-flex flex-column">
            {blocks.map((block, i) => {
              return (
                <div key={i} className="m-2 userShow bg-beigeYellow ">
                  <div className="">{block?.data.map((da,i)=><div key={i} className="">
                    <div className="d-flex">
                    
                    <div>
                    <span>sender :</span>
                    <span>{da.input?.address.substring(0, 20) + "..."}</span>
                    </div>
                    <div className=" m-2">
                    <span className="fw-bold">Recipient{i+1} :</span>
                    <span><OutputMapRender outputMap={da.outputMap}/></span>
                    {/* <span>{Array.from(da.outputMap).map(output=> console.log('output',output)
                    )}</span> */}
                    {/* <span>{da.input?.address.substring(0, 20) + "..."}</span> */}
                    </div>
                   </div>

                  </div>)}</div>
                  <div>
                    <div className="">
                      <span className="">Hash :</span>
                      <span className="">
                        {block.hash?.substring(0, 10) + "..."}
                      </span>
                    </div>              
                    <div className="">
                      <span className="">LastHash : </span>
                      <span className="">
                        {block.lastHash?.substring(0, 10) + "..."}
                      </span>
                    </div>
              
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <div
            className="btn btn-primary m-2"
            onClick={(e) => getPoolTransactions()}
          >
            استخر تراکنش ها
          </div>
          <div>
            <KnownAddresses />
          </div>
          <div>
            <PoolTransactionsRender pool={pool} />
            {/* {pool.map((pool,i)=>{
                return <div key={i} className="d-flex m-2">
                <div className="m-2">{pool?.difficulty}</div>
                <div className="m-2">{pool.hash.substring(10)}</div>
                </div>
            })} */}
          </div>
        </div>
        <div>
          <CreateTransaction />
        </div>
      </div>
    </div>
  );
}

function PoolTransactionsRender({ pool }) {
  const rows = [];
  for (let trans in pool) {
    const row = (
      <div className="d-flex">
        <div>
          <div className="m-2">{pool[trans].input.amount}</div>
          <div className="m-2">{trans}</div>
        </div>
        <div>
          <OuuputMap outputMap={pool[trans].outputMap} />
        </div>
      </div>
    );

    rows.push(row);
  }
  function OuuputMap({ outputMap }) {
    const row2 = [];
    for (let key in outputMap) {
      row2.push(
        <div className="d-flex">
          <div className="m-2">{key}</div>
          <div className="m-2">{outputMap[key]}</div>
        </div>
      );
    }
    return row2;
  }
  return rows;
}
function OutputMapRender({outputMap}){

    const rows = []
    for(let key in outputMap){
        rows.push(<div className="mx-2">
            <span>{key.substring(0, 20) + "..."}</span>
            <span>{outputMap[key]}</span>
        </div>)
    }
    return rows

}
