import React from "react";

import { Link } from "react-router-dom";
import MetaDecorator from "../../utils/metaDecorator";
import renderData from "../../utils/renderData";

export default function Thanks() {
  const structuredData = [   
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "سپاس ",
      description: "خریدتون کامل شد.",

      url: "https://www.metamarce.com/fa/prompt/guides",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/fa/store/order/thanks`,
              name: `سپاس از خریدتون`,
            },
          },
        ],
      },
    },
  ];

  const metaTagsData = {
    title: "Purchase completed",
    description: "خریدتون کامل شد",
    section: "Thank you",
    url: `https://www.metamarce.com/${window.lang}/store/order/thanks`,
    canonical: `https://www.metamarce.com/${window.lang}/store/order/thanks`,
    img: "https://www.metamarce.com/img/illustration/thanks.png",
    type: "webpage",
  };
  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <div className="container-404 text-center d-flex flex-column align-items-center ">
        <img
          className=" img-404 p-0 m-0"
          src="/img/illustration/thanks.png"
          alt="Thanks for your purchase"
          loading="lazy"
        />
        <div className="mb-3 d-flex flex-column justify-content-center align-items-center ">
          <div className="titr7 "> خریدتون کامل شد، سپاس از اعتمادتون!</div>
          <Link
            className="btn primary-btn btn-404 checkout-btn cart-footer mb-5 mt-2"
            to={`/${window.lang}`}
          >
            بازگشت به خانه
          </Link>
        </div>
      </div>
    </div>
  );
}
