import { NavLink } from "react-router-dom";

export default function StoreDashTransNav() {
  return (
    <div className="dashboardNavigator d-flex">
      <NavLink
        to={`/${window.lang}/dashboard/store/trans/overview`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        Overview{" "}
      </NavLink>
      <NavLink
        to={`/${window.lang}/dashboard/store/trans/orderlist`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {" "}
        Unfulfilled Ordres
      </NavLink>
      <NavLink
        to={`/${window.lang}/dashboard/store/trans/invlist`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {" "}
        Invoices
      </NavLink>
      <NavLink
        to={`/${window.lang}/dashboard/store/trans/refcodelist`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        Referral Codes
      </NavLink>
      {/* <NavLink to={`/${window.lang}/dashboard/store/trans/currencies`} className={`sidebarListItem m-1 link  ${({isActive})=>{return isActive && "active" }}`}>Currencies</NavLink>         */}
    </div>
  );
}
