import { Route } from "react-router-dom";
// import DemyHome from "../components/screens/demy/demyHome";
import DemyPage from "../components/screens/demy/CenterPage";
// import ProgramFiltersEn from "../components/screens/demy/filters/programFiltersEn";
import CenterFilters from "../components/screens/demy/filters/centerFilters";
import CenterPage from "../components/screens/demy/CenterPage";
// import TutorRegister from "../components/screens/demy/register/TutorRegister";
import TutorPageEn from "../components/screens/demy/tutorPageEn";
import DemyTutorHomeEn from "../components/screens/demy/demyTutorHomeEn";
import TutorFiltersEn from "../components/screens/demy/filters/tutorFiltersEn";
import DemyHomeEn from "../components/screens/demy/demyHomeEn";
import TutorRegisterEn from "../components/screens/demy/register/TutorRegisterEn";
import ProgramPageEn from "../components/screens/demy/programPageEn";
import ProgramFiltersEn from "../components/screens/demy/filters/programFiltersEn";

export default function demyRoutesEn() {
  return (
    <>
      <Route path="" element={<DemyHomeEn />} />
      <Route path="program/page/:id" element={<ProgramPageEn />} />
      <Route path="program/filters" element={<ProgramFiltersEn />} /> 
      <Route path="center/page/:id" element={<CenterPage />} />      
      <Route path="center/filters" element={<CenterFilters />} />
      <Route path="tutor/page/:id" element={<TutorPageEn />} />      
      <Route path="tutor/filters" element={<TutorFiltersEn />} />
      <Route path="tutor/register" element={<TutorRegisterEn />} />
      <Route path="tutor/home" element={<DemyTutorHomeEn />} />
    </>
  );
}
