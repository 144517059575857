import React, { useRef } from "react";
import ToastNote from "../../../golbal/notifications/toastNote";

export default function ShareSocialMedias(props) {
  // console.log("ShareSocialMedias",props)
  const url = encodeURI(props?.url);
  const title = encodeURI(props?.title);
  const img = encodeURI(props?.img);
  const _id = props._id;
  const messageRef = useRef(null);
  // https://api.whatsapp.com/send?text=[post-title] [post-url]
  // https://www.facebook.com/sharer.php?u=[post-url]
  // https://twitter.com/share?url=[post-url]&text=[post-title]&via=[via]&hashtags=[hashtags]
  // https://plus.google.com/share?url=[post-url]
  // https://pinterest.com/pin/create/bookmarklet/?media=[post-img]&url=[post-url]&is_video=[is_video]&description=[post-title]
  // https://www.linkedin.com/shareArticle?url=[post-url]&title=[post-title]
  // https://reddit.com/submit?url=[post-url]&title=[post-title]
  // $email = 'mailto:?subject=' . $[post-title] . '&body=Check out this site: '. $[post-url] .'" title="Share by Email';
  // https://telegram.me/share/url?url={url}&text={text}
  // fb-messenger://share/?link= https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fsharing%2Freference%2Fsend-dialog&app_id=123456789
  return (
    <div
      key={_id}
      className="modal fade"
      id={`myModel${_id}`}
      tabIndex="-1"
      aria-labelledby={`myModelLabel${_id}`}
      aria-hidden="true"
    >
      <ToastNote messageRef={messageRef} />
      <div className="modal-dialog modal-dialog2">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title modal-tilel-share "
              id={`myModelLabel${_id}`}
            >
              همرسانی
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {/* <p>همرسانی در شبکه‌های اجتماعی:</p> */}
            <div className="d-flex justify-content-center icons">
              <div className="listing-details-socialmedia">
                <div className="d-flex  flex-column align-items-center">
                  <ul className="d-flex flex-wrap px-0 mx-0">
                    <>
                      <li title="Pinterest">
                        <a
                          href={`https://pinterest.com/pin/create/bookmarklet/?media=${img}&url=${url}&description=${title}`}
                          target="_blank"
                        >
                          <span className="social-icon social-pinterest ">
                            <i className="bi bi-pinterest "></i>
                          </span>
                        </a>
                      </li>
                      <li title="WhatsApp">
                        {/* <a href={`whatsapp://send?text=${encodeURIComponent(window.location.href)}&title=${title}`}   */}
                        <a
                          href={`https://api.whatsapp.com/send?text=${title} ${url}`}
                          data-action="share/whatsapp/share"
                          target="_blank"
                        >
                          <span className="social-icon social-whatsapp">
                            <i className="bi bi-whatsapp"></i>
                          </span>
                        </a>
                      </li>

                      <li title="Twitter">
                        <a
                          href={`https://twitter.com/share?url=${url}&text=${title}`}
                          target="_blank"
                        >
                          <span className="social-icon social-twitter">
                            <i className="bi bi-twitter"></i>
                          </span>
                        </a>
                      </li>

                      <li title="Facebook">
                        <a
                          href={`https://www.facebook.com/sharer.php?u=${url}`}
                          target="_blank"
                        >
                          <span className="social-icon social-facebook">
                            <i className="bi bi-facebook"></i>
                          </span>
                        </a>
                      </li>

                      <li title="Messenger">
                        {/* Mobile only */}
                        <a
                          href={`fb-messenger://share/?link=${url}&app_id=123456789`}
                          target="_blank"
                        >
                          <span className="social-icon social-messenger">
                            <i className="bi bi-messenger"></i>
                          </span>
                        </a>
                      </li>
                      <li title="LinkedIn">
                        {/* <a href={`http://www.linkedin.com/shareArticle?mini=true&url=/fa/business/page/63d87e15040283c19fc7ac9d&title=How%20to%20make%20custom%20linkedin%20share%20button&summary=some%20summary%20if%20you%20want&source=http://metamarce.com`}> */}
                        {/* http://www.linkedin.com/shareArticle?mini=true&url=https://stackoverflow.com/questions/10713542/how-to-make-custom-linkedin-share-button/10737122&title=How%20to%20make%20custom%20linkedin%20share%20button&summary=some%20summary%20if%20you%20want&source=stackoverflow.com */}
                        <a
                          href={`https://www.linkedin.com/shareArticle?url=${url}&title=${title}`}
                          target="_blank"
                        >
                          <span className="social-icon social-linkedin">
                            <i className="bi bi-linkedin"></i>
                          </span>
                        </a>
                      </li>

                      <li title="Telegram">
                        <a
                          href={`https://telegram.me/share/url?url=${url}&text=${title}`}
                          target="_blank"
                        >
                          <span className="social-icon social-telegram">
                            <i className="bi bi-telegram"></i>
                          </span>
                        </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </div>
            {/* <p> copy link</p> */}
            <div className="field d-flex align-items-center justify-content-between">
              <span className="bi bi-link text-center"></span>
              <input
                type="text"
                defaultValue={url}
                // id={`shareInput${_id}-${Date.now()}`}
                autoComplete="off"
                readOnly
              />
              <button
                className="btn btn-primary button-filter button-filter-tag copy-btn w-25 m-1"
                onClick={(e) => {
                  messageRef.current.showSuccess({
                    message: "آدرس با موفقیت کپی شد.",
                  });
                  const el = e.currentTarget;
                  el.setAttribute("disabled", true);
                  setTimeout(() => el.removeAttribute("disabled"), 6000);
                  navigator.clipboard.writeText(url);
                }}
              >
                کپی کن
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
