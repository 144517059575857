import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useRef, useState } from "react";
import { CheckCircle, Error } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
// import Resizer from "react-image-file-resizer";
import AddSocialMedia from "../../../persian/addSocialMedia";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
// import RegisterAddressPlaces from "../../business/register/registerAddressPlaces";
import MyIcons from "../../../golbal/icons/myIcons";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import renderData from "../../../../utils/renderData";
import apiMedia from "../../../../utils/apiMedia";
import FormikErrRender from "../../../persian/common/alerts/formikErrRender";
import addErrClass from "../../../persian/common/alerts/addErrClass";
import api from "../../../../utils/api";
import tools from "../../../../utils/tools";
import CatsSelector from "../../../golbal/catsSelector";
// import DropdownSearch from "../../../golbal/dropdown/DropdownSearch";
// import SearchMultiSelectFilters from "../../../golbal/dropdown/searchMultiSelectFilters";
import countryStates from "../../../../utils/countryStates";
import MetaDecorator from "../../../../utils/metaDecorator";
import CountryStateCitySelector from "../../../golbal/dropdown/countryStateCitySelector";
import FormikListErr from "../../../persian/common/alerts/formikListErr";

export default function MediaRegister() {
  const messageRef = useRef(null);
  const [photo, setPhoto] = useState({
    blob: "",
    resized: "",
    url: "",
  });
  const { lang, state:{state,stateShort,lat,lng}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
  const { translations } =
  JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const { user, signedUser } = useContext(CartContext);
  const [loading, setLoading] = useState({ spinner: false });
  const [states, setStates] = useState([]);
  const navigate = useNavigate();

  const [searchParams, setSearchPatams] = useSearchParams();
  const action = searchParams.get("action") ?? "register";
  const { id } = useParams();

  const urlRegex =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
  const formik = useFormik({
    initialValues: {
      action,
      _id: "",
      lang: window.lang,
      lcs: window.lcs,
      mainMedia: { platform: "", url: "" },
      email: user.email,
      title: "",
      mediaUserName: "",
      bio: "",
      tags: `معرفی اینفلوئنسر های ${translations[window.lang]||country}+معرفی شبکه های اجتماعی ${translations[window.lang]||country}+جستجوی در شبکه های اجتماعی ${translations[window.lang]||country} +بهترین اینفلوینسرهای ${translations[window.lang]||country}`,
      collabTypes: [],
      socialMedia: [{ platform: "", url: "" }],
      photo: "",
      location: {
        country: "",
        countryShort: "",
        state: "",
        city: "",
        stateShort: "",
        cities: [],
      },
      category: {
        mainCat: {},
        subCat: {},
      },
      terms: false,
    },
    onSubmit: async (values) => {
      if (!photo.blob.size && !values.photo.length)
        return messageRef.current.showError({
          message: "لطفا عکس را انتخاب نمائید.",
        });
      if (photo.blob.size > 0) {
        setLoading((pre) => ({ ...pre, spinner: true }));
        const url = await uploadFileDatabese({ user, photo });
        setLoading((pre) => ({ ...pre, spinner: false }));
        if (url.error)
          return messageRef.current.showError({
            message: (
              <div>
                <div> مشکل در انجام عملیات !</div>
                <div>{response.error}</div>
              </div>
            ),
          });
        values.photo = url;
      }
      if (values?.action === "edit")
        return editHandle({ messageRef, setLoading, user, values, navigate });

      setLoading((pre) => ({ ...pre, spinner: true }));
      const response = await apiMedia.register({ token: user.token, values });
      setLoading((pre) => ({ ...pre, spinner: false }));
      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      messageRef.current.showSuccess({
        message: "تبریک، شبکه اجتماعی با موفقیت ثبت شد.",
      });

      const updateduser = await api.getUser();
      signedUser(updateduser.data);

      navigate(
        `/${window.lang}/media/filters?mediaId=${response.data._id}&title=${response.data.moreInfo.title}`
      );
    },
    validationSchema: Yup.object({
      terms: Yup.boolean().oneOf(
        [true],
        " شرایط را مطالعه و دکمه قبول را فشار دهید."
      ),
      title: Yup.string()
        .required("لطفاً نام شبکه اجتماعی را وارد کنید.")
        .max(100, "نام شبکه اجتماعی حداکثر باید 100 حرف باشد .")
        .min(5, "نام شبکه اجتماعی حداقل باید 5 حرف باشد."),
      bio: Yup.string()
        // .required("لطفاً وظایف شغلی را وارد کنید.")
        .max(400, "درباره من حداکثر باید 400 حرف باشد ."),
      // .min(50, "وظایف شغلی حداقل باید 50 حرف باشد.")
      email: Yup.string()
        .trim()
        .lowercase()
        .required("ایمیل را وارد کنید.")
        .email("ایمیل را به درستی وارد کنید."),
      // location: Yup.object().shape({
      //   country: Yup.string().required("کشور نمیتواند خالی باشد."),
      //   state: Yup.string().required("استان نمیتواند خالی باشد."),
      //   city: Yup.string().required("شهر نمیتواند خالی باشد."),
      //   municipality: Yup.string(),
      // }),
      mainMedia: Yup.object().shape({
        platform: Yup.string().required("لصفا نوع شبکه اجتماعی را انتخاب کنید"),
        url: Yup.string()
          .required("لطفاً لینک را وارد را وارد نمایید.")
          .test("validUrl", " لینک را به شکل درست وارد کنید.", (value) => {
            if (!value) return true; // Allow empty value
            // Regular expression to validate URL
            return urlRegex.test(value);
          }),
      }),

      socialMedia: Yup.array().of(
        Yup.object().shape({
          platform: Yup.string(),

          url: Yup.string()
            .when("platform", (platform, schema) => {
              return platform
                ? schema.required("لطفاً آدرس URL را وارد نمایید.")
                : schema;
            })
            .test("validUrl", " آدرس را به شکل درست وارد کنید.", (value) => {
              if (!value) return true; // Allow empty value
              // Regular expression to validate URL
              return urlRegex.test(value);
            }),
        })
      ),
      category: Yup.object().shape({
        mainCat: Yup.object().shape({
          _id: Yup.string().required(" دسته‌بندی را انتخاب کنید."),
        }),
        // subCat: Yup.object().shape({
        //   _id: Yup.string().required(" دسته بندی کوچکتر را انتخاب کنید."),
        // }),
      }),
    }),
  });
  console.log(formik.values);

  useEffect(() => {
    fetchDetails();
  }, [id]);

  async function fetchDetails() {
    if (!id) return;
    const response = await apiMedia.fetchDetails(id);
    if (response.error) return alert(response.error);
    editInitialData(formik, response.data, action);
  }
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "ثبت شبکه اجتماعی ",
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/media`,
              name: `شبکه های اجتماعی`,
              description:
                "بستری برای معرفی و دسترسی گروه‌های تخصصی، شبکه‌های اجتماعی در هر گوشه از دنیا... ",
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/media/register`,
              name: "ثبت رایگان سوشیال مدیا",
              description:
                "ثبت نام رایگان و معرفی گروه ها و صفحات شما در شبکه های اجتماعی به صورت اتوماتیک ...",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: "ثبت رایگان سوشیال مدیا",
    description:
      "ثبت نام رایگان و معرفی گروه ها و صفحات شما در شبکه های اجتماعی به صورت اتوماتیک ...",
    section: "media register",
    url: `https://www.metamarce.com/fa/dashboard/user/media/register`,
    img: "https://www.metamarce.com/img/plans/x4-2.jpg",
    type: "webpage",
    index: false,
  };

  return (
    <div className="user  mt-3  px-0">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      {loading.spinner && <ColoredSpiner />}
      <ToastNote messageRef={messageRef} />
      <div className=" mx-0 row align-items-center" style={{ width: "100%" }}>
        <div
          className="col "
          style={{
            backgroundColor: "var(--brownOrange)",
            color: "rgb(48, 23, 23)",
            width: "100%",
            height: "140px",
          }}
        >
          <div
            className="h2 text-center"
            style={{ marginTop: "6rem", color: "var(--beige)" }}
          >
            افزودن صفحه شبکه‌ی اجتماعی من
          </div>
        </div>
      </div>

      {/* <!-- This is htmlFor curve! --> */}

      <div className="business-top-curve">
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: "100%", width: "100%" }}
        >
          <path
            d="M-2.82,59.70 C211.62,278.78 237.58,-55.75 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
            style={{ stroke: "none", fill: "var(--brownOrange)" }}
          ></path>
        </svg>
      </div>
      {user?.roles?.includes(9999) ? (
        <div>
          <form
            onSubmit={formik.handleSubmit}
            className="row ads-all mx-auto my-5 px-md-5 mx-0"
            name="mediaRegister"
            autoComplete="true"
          >
            <div>
              <p className="divider2 border-0 mt-3 ">
                 <MyIcons icon="dotsTitr" />
                <span className="p-0 m-z "> کدوم شبکه اجتماعی</span>
              </p>

              <div className="userShow py-5 px-md-5 mx-0">
                <div key={"i"} className="mb-2">
                  <div id={"i"} className="input-group mb-1 addSocialMedia">
                    <select
                      className=" login-btn  selectpicker shadow-none w-30"
                      aria-label="Default select example"
                      name="mainMediaPlatform"
                      style={{
                        borderTopRightRadius: "20px",
                        bordeBbottomRightRadius: "20px",
                        fontSize: "small",
                      }}
                      {...formik.getFieldProps(`mainMedia.platform`)}
                    >
                      <option defaultValue={formik.values.mainMedia?.platform}>
                        {renderData.socialMedias.find(
                          (so1) =>
                            so1.name === formik.values.mainMedia?.platform
                        )?.[window.lang] || "شبکه‌ اجتماعی"}
                      </option>
                      {renderData.socialMedias?.map((social, i) => (
                        <option key={i} value={social.name}>
                          {social[window.lang]}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control login-btn w-60"
                      placeholder="  مثال:https://www.instagram.com/metamarce?igsh=azJoejNtdjdydXY4&utm_source=qr . "
                      aria-label="Text input with dropdown button"
                      defaultValue={formik.values.mainMedia.url}
                      style={{
                        borderTopLeftRadius: "20px",
                        borderBottomLeftRadius: "20px",
                      }}
                      onBlur={(e) =>
                        formik.setValues((pre) => {
                          const newF = { ...pre };
                          newF.mainMedia.url = e.target.value;
                          return newF;
                        })
                      }
                    />
                    <FormikErrRender
                      formik={formik}
                      field={`mainMedia.platform`}
                    />
                    <FormikErrRender formik={formik} field={`mainMedia.url`} />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p className="divider2 border-0 mt-3 ">
                   <MyIcons icon="dotsTitr" />
                <span className="p-0 m-z ">جزییات </span>
              </p>

              <div className="userShow px-md-5 mx-0">
                <div className="row">
                  <div className="col px-3">
                    <div className="mb-5 ">
                      <label
                        className="form-label mt-3 titr6 mb-0 required"
                        htmlFor="f1"
                      >
                        عنوان
                        <Tooltip1
                          message="  نام شبکه اجتماعی خودتون را اینجا وارد کنید! مانند: دانا دختری در جزیره یا  Second Home"
                          position="top-end"
                        />
                      </label>
                      <input
                        type="text"
                        autoComplete="off"
                        className={`form-control py-1 box2 ${addErrClass({
                          formik,
                          field: "title",
                        })} `}
                        id="f1"
                        placeholder=""
                        name="businessName"
                        value=""
                        {...formik.getFieldProps("title")}
                      />
                      <FormikErrRender formik={formik} field="title" />
                    </div>
                    <div className="mb-5 ">
                      <label
                        className="form-label mt-3 titr6 mb-0 "
                        htmlFor="f55"
                      >
                        نام کاربری
                        <Tooltip1
                          message=" نام کاربری | username (شبکه اجنماعی شما مانند: @metamarce) "
                          position="top-end"
                        />
                      </label>
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control py-1 box2 "
                        id="f55"
                        placeholder="نام کاربری | username (مانند: metamarce@) "
                        name="businessName"
                        value=""
                        {...formik.getFieldProps("mediaUserName")}
                      />
                      <FormikErrRender formik={formik} field="mediaUserName" />
                    </div>
                    <CatsSelector formik={formik} section={"media"} />

                    <div className="mb-5">
                      <label className="form-label titr6">
                        درباره من
                        <span className="med-gray-font mx-1">
                          (توضیحات بیشتری که مایل هستید به اشتراک بگذارید )
                        </span>
                        <Tooltip1
                          message=" (توضیحات بیشتری که مایل هستید به اشتراک بگذارید )"
                          position="top-end"
                        />
                      </label>
                      <textarea
                        className={`form-control ${addErrClass({
                          formik,
                          field: "bio",
                        })}`}
                        rows="3"
                        name="bio"
                        defaultValue={formik.values.bio}
                        onChange={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                         }}
                        onBlur={(e) =>
                          formik.setValues((pre) => ({
                            ...pre,
                            bio: e.target.value,
                          }))
                        }
                      ></textarea>
                      <FormikErrRender formik={formik} field="bio" />
                    </div>
                    <div className="form-label mt-5 titr6 mb-0  border-0 ">
                      شبکه‌های اجتماعی دیگر (حداکثر۵ تا)
                    </div>

                    <div className=" border-0 mb-5 px-md-5 mx-0">
                      <AddSocialMedia formik={formik} />
                    </div>

                    <div className="row g-5 mb-5">
                      <div className=" col-md-6 ">
                        <p className="form-label titr6 ">
                          پذیرش آگهی از صاحبان کسب و کار
                          <Tooltip1
                            message="کاربران عادی وبسایت به این گزینه دسترسی ندارند و تنها به صاحبان کسب و کار نمایش داده خواهد شد."
                            position="top-end"
                          />
                        </p>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={"acceptAds"}
                          checked={formik.values.collabTypes?.includes(
                            "acceptAds"
                          )}
                          // {...formik.getFieldProps("acceptAds")}
                          onChange={(e) =>
                            formik.setValues((pre) => {
                              const collabTypes = [...pre.collabTypes];
                              const valueIndex = collabTypes.indexOf(
                                e.currentTarget.id
                              );
                              if (valueIndex !== -1) {
                                collabTypes.splice(valueIndex, 1);
                              } else {
                                collabTypes.push(e.currentTarget.id);
                              }
                              return { ...pre, collabTypes };
                            })
                          }
                        />
                        <label
                          className="form-check-label mx-3"
                          htmlFor={"acceptAds"}
                        >
                          علاقه دارم
                        </label>
                      </div>
                      <div className=" col-md-6 ">
                        <p className="form-label titr6 ">
                          تبادل با صفحه‌های اجتماعی دیگر
                          <Tooltip1
                            message="کاربران عادی وبسایت به این گزینه دسترسی ندارند و تنها به اینفلوئنسر ها نمایش داده خواهد شد."
                            position="top-end"
                          />
                        </p>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={"pageExchange"}
                          checked={formik.values.collabTypes?.includes(
                            "pageExchange"
                          )}
                          // {...formik.getFieldProps("pageExchange")}
                          onChange={(e) =>
                            formik.setValues((pre) => {
                              const collabTypes = [...pre.collabTypes];
                              const valueIndex = collabTypes.indexOf(
                                e.currentTarget.id
                              );
                              if (valueIndex !== -1) {
                                collabTypes.splice(valueIndex, 1);
                              } else {
                                collabTypes.push(e.currentTarget.id);
                              }
                              return { ...pre, collabTypes };
                            })
                          }
                        />
                        <label
                          className="form-check-label mx-3"
                          htmlFor="pageExchange"
                        >
                          علاقه دارم
                        </label>
                      </div>
                    </div>

                    <div>
                      <label
                        className="form-label mt-5 titr6 mb-0 required"
                        htmlFor="emailApply"
                      >
                        ایمیل
                        <Tooltip1
                          message="این ایمیل به صورت عمومی نمایش داده نخواهد شد."
                          position="top-end"
                        />
                      </label>
                      <input
                        id="emailApply"
                        {...formik.getFieldProps("email")}
                        className={`userUpdateInput  form-control box2 ${addErrClass(
                          { formik, field: "email" }
                        )}`}
                        type="text"
                        autoComplete="off"
                        placeholder={formik.values.email}
                      />
                      <FormikErrRender formik={formik} field="email" />
                    </div>

                    <p id="tagsInput" className="mb-5 form-titr ">
                      <span>
                        <i className="bi bi-circle-fill me-1"></i>
                      </span>
                      برچسب‌ها (کلمات کلیدی) (با "+" جدا شوند )
                      <Tooltip1
                        message={
                          <div>
                            <div>
                              برچسب‌ها - تگ - را توسط '+' (بدون فاصله)از یکدیگر
                              جداکنید.
                            </div>
                            <div>حداکثر ۱۰ برچسب.</div>
                          </div>
                        }
                      />
                      <input
                        type="text"
                        autoComplete="off"
                        name="other"
                        placeholder={
                          (formik?.values?.tags.length &&
                            formik?.values?.tags) ||
                          `نمونه : مهاجرت+دانشگاه‌های کانادا+ مونترال`
                        }
                        className="form-input1 mt-3"
                        {...formik.getFieldProps("tags")}
                      />
                    </p>

                    <div>
                      <label
                        htmlFor="formFile1"
                        className="form-label form-titr mb-2 "
                      >
                        <span>
                          <i className="bi bi-circle-fill me-1"></i>
                        </span>
                        انتخاب عکس
                      </label>
                      <div className="mb-0 form-group d-flex files ">
                        <input
                          disabled={loading.loading11 || loading.loading1}
                          onChange={(e) =>
                            businessCardHandle(
                              e,
                              setLoading,
                              setPhoto,
                              // resizeFile,
                              messageRef
                            )
                          }
                          className="form-control p-3"
                          type="file"
                          id="formFile1"
                          dir="ltr"
                        />
                        <div>
                          {loading.loading11 && (
                            <CircularProgress color="inherit" size="20px" />
                          )}
                        </div>
                        {photo?.resized?.length ? (
                          <>
                            <img
                              src={photo?.resized}
                              alt={"media photo"}
                              className="userShowImg my-0 userShowImg-buscard "
                              loading="lazy"
                            />
                            <i
                              className="bi bi-trash"
                              style={{ color: "red" }}
                              onClick={(e) =>
                                setPhoto((pre) => {
                                  const photo = { ...pre };
                                  photo.url = "";
                                  photo.blob = "";
                                  photo.resized = "";
                                  const formikValues = { ...formik.values };
                                  formikValues.photo = "";
                                  formik.setValues(formikValues);

                                  return photo;
                                })
                              }
                            ></i>
                          </>
                        ) : formik?.values?.photo?.length ? (
                          <img
                            src={formik?.values?.photo}
                            alt={formik?.values.title}
                            className="userShowImg my-0 userShowImg-buscard "
                            loading="lazy"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="userShow py-5 px-md-5 mx-3">
              <p className="divider2 border-0 mt-3 ">
                 <MyIcons icon="dotsTitr" />
                <span className="p-0 m-z "> کدوم کشور یا شهر؟</span>
              </p>
              <CountryStateCitySelector formik={formik} />
            </div>

            <div className="mt-5">
              <input
                style={{ display: "none" }}
                className="form-check-input"
                id="termsConditions"
                type="checkbox"
                onChange={(e) =>
                  (document.querySelector("#termsContent").style.display = e
                    .target.checked
                    ? "block"
                    : "none")
                }
              />
              <label
                className=" terms-con ps-3 mb-2 mt-5"
                htmlFor="termsConditions"
              >
                مشاهده قوانین
              </label>
              <div
                id="termsContent"
                style={{ display: "none" }}
                className="userUpdateInput form-control userUpdateInputTextarea"
              >
                <Roles />
              </div>
              <label
                htmlFor="termsBtn"
                className={`required my-3 mb-5 ${addErrClass({
                  formik,
                  field: "terms",
                })} `}
              >
                قوانین را مطالعه کردم و می‌پذیرم
                <input
                  id="termsBtn"
                  type="checkbox"
                  className="form-check-input mx-2"
                  onChange={(e) => {
                    if (formik.values.terms) return;
                    formik.setValues((pre) => ({ ...pre, terms: true }));
                  }}
                  checked={formik.values.terms}
                />
                <FormikErrRender formik={formik} field="terms" />
              </label>
            </div>
            <FormikListErr formik={formik} />
            <SubmitBtn formik={formik} text="" />
          </form>
        </div>
      ) : (
        <div className="container-404 text-center d-flex flex-column align-items-center px-4 ">
          <div className="my-3 ">
            <div> در گام نخست شما نیاز دارید تا وارد حساب کاربری شوید.</div>

            <Link
              id="loginTrigger"
              className="btn primary-btn btn-404 checkout-btn my-5 mt-3"
              data-bs-toggle="modal"
              to="#exampleModalToggle"
              role="button"
            >
              ورود به حساب کاربری
            </Link>
            <img
              className=" img-404 w-75 "
              src="/img/illustration/access-denied.svg"
              alt="404"
            />
          </div>
        </div>
      )}
    </div>
  );

  function SubmitBtn({ text, formik }) {
    let textBtn = "بفرست";
    if (formik.values.action === "edit") textBtn = "ویرایش";
    return (
      <button
        disabled={formik.isSubmitting}
        className="my-1 login-btn login-btn2 "
        style={{
          backgroundColor: Object.keys(formik.errors)?.length > 0 && "grey",
        }}
        type="submit"
        id="createBusBtn"
      >
        {textBtn}
        {loading.spinner ? (
          <CircularProgress color="inherit" size="20px" />
        ) : (
          Object.keys(formik.errors)?.length === 0 && <CheckCircle />
        )}
        <span>
          {Object.keys(formik.errors)?.length > 0 && (
            <Tooltip1
              message=" موارد ستاره دار را کامل کنید."
              icon={<Error sx={{ fontSize: 18, color: "red" }} />}
              position="left"
            />
          )}
        </span>
      </button>
    );
  }
  function countrySet(countryShort) {
    const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
    console.log(countryShort);
    const states = countryStates.find(
      (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    ).states;
    console.log(states);
    // const state = countryStates.find(
    //   (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    // ).states[0];
    setStates(states);

    formik.setValues((prev) => {
      const location = { ...prev.location };
      location.country = regionNames.of(countryShort).toLowerCase();
      location.countryShort = countryShort.toLowerCase();
      location.state = "";
      location.stateShort = "";
      location.cities = [];
      return { ...prev, location };
    });
  }
  function setCitiesHandle(value) {
    formik.setValues((prev) => {
      const location = { ...prev.location };
      location.cities = value;
      return { ...prev, location };
    });
  }
}

const uploadFileDatabese = async ({ user, photo }) => {
  if (photo?.blob?.size <= 0)
    return window.alert(" از عکس با حجم بالاتر استفاده کنید.");
  const formData = new FormData();
  formData.append("file", photo.blob);
  const response = await api.uploadSingleFile({
    token: user.token,
    formData,
    path: "images/media",
  });
  if (response.error) return window.alert(response.error);
  return response.data;
};
const businessCardHandle = async (
  e,
  setLoading,
  setPhoto,
  // resizeFile,
  messageRef
) => {
  if (!tools.photoCheck(e))
    return messageRef.current.showError({
      message: " از فایل با فرمت عکس استفاده کنید.",
    });
  setLoading((pre) => ({ ...pre, loading11: true }));
  tools
    .resizeFile({ file: e.target.files[0], width: 500, height: 500 })
    .then((resized) =>
      setPhoto((prevPhotos) => ({
        ...prevPhotos,
        resized: resized,
        url: "",
      }))
    )
    .catch((err) => console.log(err));

  tools
    .resizeFile({
      file: e.target.files[0],
      blob: true,
      width: 500,
      height: 500,
    })
    .then((blob) =>
      setPhoto((prevPhotos) => ({
        ...prevPhotos,
        blob: blob,
      }))
    )
    .catch((err) => console.log(err));
  setLoading((pre) => ({ ...pre, loading11: false }));
};
// const resizeFile = ({ file, width, height, quality, blob, }) =>
//   new Promise((resolve) => {
//     Resizer.imageFileResizer(
//       file,
//       width || 500,
//       height || 500,
//       "JPEG",
//       quality || 100,
//       0,
//       (uri) => {
//         resolve(uri);
//       },
//       blob ? "blob" : "base64"
//     );
//   });

function editInitialData(formik, data, action) {
  const initialValues = {
    action,
    _id: action === "edit" ? data._id : "",
    lang: data?.lang ?? window.lang,
    lcs: data?.lcs ?? window.lcs,
    mainMedia: data.moreInfo.mainMedia,
    email: data.moreInfo.email,
    title: data.moreInfo.title,
    mediaUserName: data.moreInfo.mediaUserName,
    bio: data.moreInfo.bio,
    tags: data.moreInfo?.tags?.join("+"),
    collabTypes: data.moreInfo.collabTypes,
    socialMedia: data.moreInfo.socialMedia,
    photo: data.moreInfo.photo,
    location: data.moreInfo.address,
    phone: data.moreInfo.phone,
    moreContact: data.moreInfo.moreContact,
    category: data.category,
    terms: data.moreInfo.terms,
  };
  formik.setValues(initialValues);
  if (document.querySelector("#termsContent"))
    document.querySelector("#termsContent").style.display = data.moreInfo?.terms
      ? "none"
      : "block";
}

async function editHandle({ messageRef, setLoading, user, values, navigate }) {
  console.log("edit handle triggred");
  setLoading((pre) => ({ ...pre, spinner: true }));
  const response = await apiMedia.edit({ token: user.token, values });
  setLoading((pre) => ({ ...pre, spinner: false }));
  if (response.error)
    return messageRef.current.showError({
      message: (
        <div>
          <div> مشکل در انجام عملیات !</div>
          <div dir="ltr">{response.error}</div>
        </div>
      ),
    });
  messageRef.current.showSuccess({
    message: " صفحه اجتماعی شما با موفقیت ویرایش شد.",
  });
  window.scrollTo({ top: 0, behavior: "smooth" });

  navigate(
    `/${window.lang}/media/filters?mediaId=${response.data._id}&title=${response.data.moreInfo.title}`
  );
}

function Roles() {
  return (
    <div>
      متامارس خود را متعهد به ایجاد بستری امن جهت استفاده عموم کاربران میداند و
      از صفحات ، گروه ها و کانال ها جهت ثبت نام در متامدیا درخواست می نماید تا
      اطمینان حاصل نمایند که فعالیت های آنها در زمینه های زیر نمی باشد :
      <ul className="flex-column">
        <li> صفحات با فعالیت های کیفری و جنایی.</li>
        <li>صفحات با محتوای فعالیتهای هرمی.</li>
        <li>صفحات با محتوای تنفر یا تهدید.</li>
        <li> صفحات با محتوای ترویج مصرف مواد مخدر و روان گردان ها.</li>
        <li>صفحات دارای محتوای عریانی یا جنسی.</li>
      </ul>
      متامارس حق دارد صفحات را بررسی و در صورت تشخیص عدم انطباق با هریک از مفاد
      بالا، آنها را از متامدیا حذف کند. کاربرانی که به تکرار این شرایط را نقض
      می‌کنند، ممکن است حساب کاربری‌شان معلق یا بسته شود.
    </div>
  );
}
