import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import api from "../../../../utils/api";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
// import MyIcons from "../../../golbal/icons/myIcons";
import ShareSocialMedias from "../../business/components/shareSocialMedias";
import SocialMedias from "../../business/components/socialMedias";
import renderData from "../../../../utils/renderData";
import apiMedia from "../../../../utils/apiMedia";
import ShowAddress from "../../../golbal/showAddress";

export default function MediaCard({ data, udateparentData }) {
  // console.log(data);
  const messageRef = useRef(null);
  const [spiner, setSpiner] = useState(false);

  const { signedUser, user } = useContext(CartContext);
  // console.log('user',user);
  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const el = e.currentTarget;
    el.setAttribute("disabled", true);
    setSpiner(true);
    const response = await api.saveHandler({
      id: data._id,
      section: "media",
      token: user.token,
    });
    setSpiner(false);
    setTimeout(() => el.removeAttribute("disabled"), 1000 * 2);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>عملیات ذخیره ناموفق!</div>
            <div> دوباره وارد شوید.</div>
          </div>
        ),
      });
    signedUser(response.data);
    !user?.saved?.media?.includes(data?._id)
      ? messageRef.current.showSuccess({
          message: "شبکه اجتماعی با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "شبکه اجتماعی از لیست ذخیره برداشته شد.",
        });
  };

  async function likeHandler(e) {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const el = e.currentTarget;
    el.classList.toggle("bi-hand-thumbs-up");
    el.classList.toggle("bi-hand-thumbs-up-fill");
    const parentLi = el.parentElement;
    const span = parentLi.querySelector("span");
    // console.log(parentLi);
    const number = el.classList.contains("bi-hand-thumbs-up")
      ? Number(parentLi.textContent) - 1
      : Number(parentLi.textContent) + 1;
    span.textContent = number;

    el.setAttribute("disabled", true);

    const response = await apiMedia.likeHandler({
      id: e.target.id,
      token: user.token,
    });
    setTimeout(() => el.removeAttribute("disabled"), 1000);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>عملیات پسندیدن ناموفق!</div>
            <div> {response.error}</div>
          </div>
        ),
      });
    udateparentData(response.data);
  }

  async function disLikeHandler(e) {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const el = e.currentTarget;
    el.classList.toggle("bi-hand-thumbs-down");
    el.classList.toggle("bi-hand-thumbs-down-fill");
    el.setAttribute("disabled", true);
    const response = await apiMedia.disLikeHandler({
      id: e.target.id,
      token: user.token,
    });
    setTimeout(() => el.removeAttribute("disabled"), 1000);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>عملیات نپسندیدن ناموفق!</div>
            <div> {response.error}</div>
          </div>
        ),
      });
    udateparentData(response.data);
  }

  return (
    <div key={data?._id} className="col p-4 pt-0 ">
      <ToastNote messageRef={messageRef} />
      <div
        id={data?._id}
        className="image-flip2 h-100"
        // ontouchstart="this.classList.toggle('hover');"
      >
        <div
          className="
        mainflip 
        row h-100"
        >
          <div className=" frontside  col p-0 h-100">
            <div className="card h-100">
              <div className="card-img-pack ">
                <p className="image-container image-container-bus m-0 cursor">
                  <div
                    onClick={(e) => {
                      if (
                        window.confirm(
                          `شما در حال خروج از متامارس و انتقال به صفحه ${data.moreInfo.title} می باشید ؟`
                        ) !== true
                      )
                        return;
                      window.open(data?.moreInfo?.mainMedia.url);
                    }}
                  >
                    <img
                      className=" img-fluid w-100"
                      src={data?.moreInfo?.photo}
                      alt="card image"
                      loading="lazy"
                    />
                  </div>
                </p>
                <div
                  className="cursor"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        `شما در حال خروج از متامارس و انتقال به صفحه ${data.moreInfo.title} می باشید ؟`
                      ) !== true
                    )
                      return;
                    window.open(data?.moreInfo?.mainMedia.url);
                  }}
                  title={data.moreInfo?.mainMedia.platform}
                >
                  <img
                    src={
                      renderData.socialMedias.find(
                        (med) => med.name === data.moreInfo?.mainMedia.platform
                      )?.img
                    }
                    className="card-logo card-logo2"
                    alt={data.moreInfo?.mainMedia.platform}
                    loading="lazy"
                  />
                </div>
                <Link
                  dir="ltr"
                  title=" "
                  to=""
                  className="card-logo card-logo3 btn btn-primary button-filter button-filter-tag button-filter-tag7  justify-content-center  "
                >
                  {data?.moreInfo?.mediaUserName}
                </Link>
              </div>
              <div className="card-body p-0 pt-2">
                <Link
                  title={`دیدن تمام شبکه های اجتماعی در ارتباط با ${data?.moreInfo?.title}`}
                  to={`/${window.lang}/media/filters?userId=${data?.user}&title=${data.moreInfo.title}`}
                >
                  <h5 className="card-title titr5 text-center mt-2  ">
                    {data?.moreInfo?.title}
                  </h5>
                </Link>
                {/* <div className="card-text card-text1  my-2"> */}
                <ShowAddress address={data?.moreInfo?.address} />
                {/* </div> */}
                <div className="me-auto d-flex flex-wrap justify-content-between my-0 ">
                  <Link
                    title="دسته‌بندی اصلی"
                    to={`?mainCat=${
                      data?.category?.mainCat?.[window.lang]
                    }&mainCatId=${data?.category?.mainCat?._id}`}
                    className="btn btn-primary button-filter button-filter-tag button-filter-tag6 mx-2 "
                  >
                    {/* <span>
                      <i className="bi bi-list-ul "></i>
                    </span> */}

                    {data?.category?.mainCat?.[window.lang]}
                  </Link>
                  {(user._id === data.user || user?.roles?.includes(1000)) && (
                    <Link
                      title="  ویرایش"
                      className="btn btn-primary share-cover-btn mx-2"
                      to={`/${window.lang}/dashboard/user/media/edit/${data?._id}?action=edit`}
                    >
                      ‌<i className="bi bi-arrow-repeat"></i>
                    </Link>
                  )}
                  {/* <Link
                    title="دسته‌بندی کوچکتر"
                    to={`?subCat=${data?.category?.subCat?.[window.lang]}&subCatId=${data?.category?.subCat?._id}`}
                    className="btn btn-primary button-filter button-filter-tag  button-filter-tag4 mx-2 me-auto"
                  >
                    {data?.category?.subCat?.[window.lang]}
                  </Link> */}
                  {/* {data.tags.map(tag=>)} */}
                  <div>
                    {data?.moreInfo?.collabTypes.map((collab, i) => {
                      if (
                        (collab === "acceptAds" &&
                          user?.roles?.includes(1299)) ||
                        (collab === "pageExchange" &&
                          user?.roles?.includes(1899))
                      ) {
                        const row = renderData.collabTypes[i];
                        // console.log(row);
                        return (
                          <span
                            //  to={`?collabTypes=${collab}`}
                            key={i}
                          >
                            <Tooltip1
                              icon={
                                <img
                                  src={row.img}
                                  className=" colab-icons mx-1"
                                  alt={row.en}
                                  loading="lazy"
                                />
                              }
                              message={row[window.lang]}
                            />
                          </span>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>

              <div className="card-footer  d-flex flex-wrap justify-content-between border-top py-2 pb-0 mt-3 mb-2">
                <div className="entry-media m-0 icon-stack3">
                  <ul className="m-0 px-0 d-flex">
                    <li
                      className="d-flex align-items-center me-1 "
                      // value={data?.feedbackInfo?.likes?.length}
                    >
                      <span className="small-gray-font">
                        {data?.feedbackInfo?.likes?.length}
                      </span>

                      <button
                        className={
                          data?.feedbackInfo?.likes?.includes(user?._id)
                            ? "icon-btn bi bi-hand-thumbs-up-fill ms-1 media-footer-icons "
                            : "icon-btn bi bi-hand-thumbs-up ms-1 media-footer-icons "
                        }
                        title="پسندیدن"
                        id={data?._id}
                        onClick={likeHandler}
                      ></button>
                      {/* <a href="blog-single.html"></a> */}
                    </li>
                    <li className="d-flex align-items-center me-1">
                      <button
                        // disabled={data?.feedbackInfo?.likes?.includes(user._id)}
                        className={
                          data?.feedbackInfo?.disLikes?.includes(user._id)
                            ? "icon-btn bi bi-hand-thumbs-down-fill ms-1  media-footer-icons "
                            : "icon-btn bi bi-hand-thumbs-down ms-1 media-footer-icons "
                        }
                        title="نپسندیدن"
                        id={data?._id}
                        onClick={disLikeHandler}
                      ></button>
                      <a href="blog-single.html"></a>
                    </li>
                    <li
                      className="d-flex align-items-center me-1  "
                      title="ذخیره"
                    >
                      <button
                        id={`saveBtn${data._id}`}
                        className="icon-btn"
                        onClick={saveHandler}
                      >
                        {spiner ? (
                          <CircularProgress color="inherit" size="20px" />
                        ) : (
                          <i
                            className={
                              user?.saved?.media?.includes(data._id)
                                ? "bi bi-bookmark-fill ms-2  media-footer-icons"
                                : "bi bi-bookmark ms-2 media-footer-icons "
                            }
                            title="ذخیره"
                          ></i>
                        )}
                      </button>

                      {/* <a href="#">
                        <i
                          className=// : // ? "bi bi-bookmark-fill" // user.saved.post.find((x) => x._id == post?._id) // user.saved !== undefined && // {
                          "bi bi-bookmark ms-2 med-gray-font"
                          // }
                          // id={post?._id}
                          // onClick={saveHandler}
                        ></i>
                      </a> */}
                    </li>

                    <li className="d-flex align-items-center me-1">
                      <a
                        data-bs-toggle="modal"
                        data-bs-target={`#myModel${data?._id}`}
                        id="shareBtn"
                        data-bs-placement="top"
                        title="همرسانی!"
                      >
                        <i className="bi bi-share ms-2  media-footer-icons "></i>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="entry-content d-flex flex-column p-0">
                  <div
                    className="read-more3  d-flex justify-content-end  py-0"
                    id={`viewMore${data._id}`}
                    elid={data._id}
                    onClick={(e) => {
                      const el = e.currentTarget;
                      const id = el.getAttribute("elid");

                      document
                        .getElementById(id)
                        .querySelector(".frontside")
                        .classList.toggle("frontside2");
                      document
                        .getElementById(id)
                        .querySelector(".backside")
                        .classList?.toggle("backside2");
                    }}
                  >
                    بیشتر بخوانید
                    <span>
                      <i className="bi bi-chevron-double-left ms-2"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="backside col h-100">
            <div className="card h-100">
              <div className="card-body d-flex flex-column justify-content-center align-items-center mt-3">
                <h4 className="card-title titr5 ">درباره من</h4>
                <Link
                  className="my-posts-btn mb-3 mt-0"
                  onClick={(e) => window.scroll(0, 0)}
                  to={`/${window.lang}/blog/filters?authorId=${data.user}&title=${data.moreInfo.title} `}
                >
                  همه نوشته‌های من در متامارس
                </Link>
                <p className="card-text  ">{data.moreInfo?.bio}</p>
                <ul className="mt-auto d-flex flex-wrap">
                  {data.moreInfo.tags.map((tag, i) => (
                    <li>
                      <Link
                        key={i}
                        id={tag}
                        onClick={(e) =>
                          window.scrollTo({ top: 0, smooth: true })
                        }
                        to={`?tag=${tag}`}
                        className="ms-2 blog-tag"
                      >
                        {tag}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="card-footer py-0">
                <div className="d-flex flex-wrap  justify-content-between border-top py-0 mt-0">
                  <SocialMedias
                    // extraClass={"social-links"}
                    socialMedias={data?.moreInfo?.socialMedia}
                  />

                  <div
                    id={`back${data?._id}`}
                    elid={data?._id}
                    className="read-more3  d-flex justify-content-end py-1  align-items-center"
                    onClick={(e) => {
                      const el = e.currentTarget;
                      const id = el.getAttribute("elid");

                      document
                        .getElementById(id)
                        .querySelector(".frontside")
                        .classList.toggle("frontside2");
                      document
                        .getElementById(id)
                        .querySelector(".backside")
                        .classList?.toggle("backside2");
                    }}
                  >
                    بازگشت
                    <span>
                      <i className="bi bi-chevron-double-left ms-2 "></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ShareSocialMedias
          url={`${window.location.origin}/${window.lang}/media/filters?mediaId=${data?._id}&title=${data.moreInfo.title}`}
          title={data?.moreInfo?.title}
          img={data?.moreInfo?.photo}
          _id={data._id}
        />
      </div>
      {/* </div> */}
    </div>
  );
}
