import { useEffect, useState } from "react";
import renderData from "../../../utils/renderData";
import tools from "../../../utils/tools";

export default function PlansX5({ plansX5, page }) {
  // console.log(plansX5);
  const [data, setData] = useState([]);  
  const { lang, state:{state,stateShort}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
  function plansX5ReadyToSh({ size }) {
    const plan = plansX5?.[size]?.filter(
      (plan) =>
        plan.pages?.includes(page) &&
        plan.isPaid &&
        plan.targetAddress.some(
          (target) =>
            target.countryShort === countryShort 
          // &&  target.states.some((state0) => state0 === state.toLowerCase())
        )
    );
    return plan;
  }

  useEffect(() => {
    const plansLg = plansX5ReadyToSh({ size: "plansLg" })||[];
    const plansMd = plansX5ReadyToSh({ size: "plansMd" })||[];
    const combinedArray = combineArrays(plansLg, plansMd);
    setData(combinedArray);
    // console.log(combinedArray);
  }, [plansX5]);

  function combineArrays(array1, array2) {
    const maxLength =
      Math.max(Math.ceil(array1?.length / 2), Math.ceil(array2?.length / 2)) || 1;
    const combinedArray = [];
    let counter = 0;
    for (let i = 0; i < maxLength; i++) {
      const item1 = array1[i * 2] || getNextPlan();
      const item2 = array1[i * 2 + 1] || getNextPlan();
      const item3 = array2[i * 2] || getNextPlan();
      const item4 = array2[i * 2 + 1] || getNextPlan();
      const item5 = array2[i * 2 + 2] || getNextPlan();

      combinedArray.push([item1, item2, item3, item4, item5]);
    }
    function getNextPlan() {
      const nextPlan = renderData.plansDef.plansMd[counter];
      counter = (counter + 1) % renderData.plansDef.plansMd.length;
      // console.log('nextPlan',counter);
      return nextPlan;
    }

    return combinedArray;
  }
  // console.log("plansX5",data);
  return (
    <section className="content-section ads-all mx-auto " id={`${page}-planX5`}>
      <div className="container px-4 px-lg-5">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <ButtonsRender data={data} />
          </div>

          <div className="carousel-inner">
            {data.map((da, i) => (
              <div key={i+"PlansX5Slide"}>
              <PlansX5Slide data={da} index={i} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

function PlansX5Slide({ data, index }) {
  // console.log('PlansX4Slide',data);
  return (
    <div
      key={index}
      className={`carousel-item ${index === 0 && "active"}`}
      data-bs-interval="7000"
    >
      <div className="row gx-0">
        <PlansLgRender data={data[0]} />
        <PlansLgRender data={data[1]} />
        <PlansMdRender data={data[2]} />
        <PlansMdRender data={data[3]} />
        <PlansMdRender data={data[4]} />
      </div>
    </div>
  );
  function PlansLgRender({ data }) {
    return (
      <div className="col-md-6">
        <a
          className="portfolio-item container-bus-big-img-slide"
          href={data?.url}
        >
          <div className="caption">
            <div className="caption-content ">
              <p className=" card-text overlay-text">{data?.description}</p>
              <div className="card-text card-titel4 mt-auto">{data?.title}</div>
            </div>
          </div>
          <img className="img-fluid" src={data?.img} alt='large' loading="lazy"/>
        </a>
      </div>
    );
  }
  function PlansMdRender({ data }) {
    return (
      <div className="col-md-4">
        <a
          className="portfolio-item container-bus-big-img-slide "
          href={data?.url}
        >
          <div className="caption">
            <div className="caption-content ">
              <p className=" card-text overlay-text">{data?.description}</p>
              <div className="card-text card-titel4 mt-auto">{data?.title}</div>
            </div>
          </div>
          <img className="img-fluid" src={data?.img} alt='medium' loading="lazy"/>
        </a>
      </div>
    );
  }
}

function ButtonsRender({ data }) {
  // console.log(data.length);
  const buttons = [];
  for (let i = 0; i < data.length; i++) {
    // console.log(i);
    buttons.push(
      <button
        title={i + 1}
        key={i}
        type="button"
        className={i === 0 ? "active" : ""}
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to={i}
        // aria-current={i === 0 ? "true" : "false"}
        aria-label={`Slide ${i + 1}`}
      ></button>
    );
  }

  return buttons;
}
