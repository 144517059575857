import "./accountActivation.css";
import { useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../utils/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import ToastNote from "../golbal/notifications/toastNote";
import Tooltip1 from "../golbal/tooltip/tooltip";
import FormikErrRender from "../persian/common/alerts/formikErrRender";

export default function AccountActivation() {
  const [message, setMessage] = useState();
  const params = useParams();
  const [token, setToken] = useState(params.token);
  const messageRef = useRef(null);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      password: "",
    },
    onSubmit: async (values) => {
      console.log(values);
      //   console.log(para);
      const response = await api.activate(
        token,
        params.id,
        values.password.trim()
      );

      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      messageRef.current.showSuccess({
        message: "فعالسازی با موفقیت انجام شد.",
      });
      //   signedUser(response.data);
      // document.getElementById("loginTrigger").click(); // Get the modal element
      setMessage(response.message);
    },
    validationSchema: Yup.object({
      // password: Yup.string().trim().required(" کلمه عبور خود را وارد کنید"),
    }),
  });

  async function sendNewEmailCode(e) {
    e.preventDefault();

    e.target.setAttribute("disabled", true);
    const response = await api.sendNewEmailCode({ id: params.id });
    setTimeout(() => {
      e.target.removeAttribute("disabled");
    }, 1000 * 60 * 1);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({
      message: "لینک فعالساز با موفقیت به ایمیل شما فرستاده شد.",
    });
  }

  return (
    <div
      className=""
      // style={{ maxWidth: "550px" }}
    >
      <ToastNote messageRef={messageRef} />
      {/* {!message.length ? <h1>404 Page not found</h1> :
       */}

      {!message?.length ? (
        <div className="text-center w-100 ">
          <div
            className=" mx-0 row align-items-center"
            style={{ width: "100%" }}
          >
            <div
              className="col "
              style={{
                backgroundColor: "var(--brownGray)",
                color: "rgb(48, 23, 23)",
                width: "100%",
                height: "90px",
              }}
            >
              <div
                className="h2 mt-5 text-center"
                style={{ marginTop: "6rem", color: "var(--beige)" }}
              >
                <div
                  className="h2 mt-5 text-center"
                  style={{ marginTop: "6rem", color: "var(--beige)" }}
                >
                  به متامارس خوش آمدید!
                </div>
              </div>
            </div>
          </div>

          {/* <!-- This is htmlFor curve! --> */}

          <div className="business-top-curve">
            <svg
              viewBox="0 0 500 150"
              preserveAspectRatio="none"
              style={{ height: "100%", width: "100%" }}
            >
              <path
                d="M-2.82,59.70 C211.62,278.78 237.58,-55.75 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
                style={{ stroke: "none", fill: "var(--brownGray)" }}
              ></path>
            </svg>
          </div>
          <div className="row mx-md-5 mx-2 my-5">
            <div className="col-md-6 ">
              <div className="wrapper mx-md-5 mx-3 pb-5">
                <form
                  onSubmit={formik.handleSubmit}
                  name="accountActForm"
                  autoComplete="true"
                >
                  <div className="w-100 my-3">
                    <label className="mt-2" htmlFor="passCode">
                      کد فعالسازی شما :
                    </label>
                    <Tooltip1
                      message=" دریافت کد ممکن است دو دقیقه زمان ببرد و پس از ده دقیقه منقضی خواهد در این صورت لطفا درخواست کد جدید کنید."
                     
                    />
                    <div className="small-gray-font">
                      ( اگر کد را دریافت نکردید لطفا آدرس ایمیل و یا فولدر اسپم ایمیل خود را کنترل نمائید)
                      </div>
                    <input
                      className="w-100 text-center"
                      type="text"
                      placeholder={params.token}
                      onChange={(e) => setToken(e.target.value.trim())}
                      id="passCode"
                      autoComplete="off"
                    />
                  </div>
                  {/* <label className="my-2" htmlFor="passInput">
                    کلمه عبور خود را دوباره وارد نمایید.
                  </label>
                  <Tooltip1
                    message="کلمه عبور خود در زمان ثبت نام"
                    position="left"
                  />
                  <input
                  autoComplete="off" 
                    className="w-100 text-center"
                    id="passInput"
                    type="password"
                    {...formik.getFieldProps("password")}
                  /> */}
                  <button
                    type="submit"
                    disabled={token.length<6&&true}
                    className="btn btn-btn primary-btn view-cart cart-footer mt-5 w-100 mx-auto"
                  >
                    تائید
                  </button>
                  <FormikErrRender formik={formik} field="password" />
                </form>
                <button
                  className="btn primary-btn checkout-btn cart-footer w-100 mt-3  mx-auto"
                  onClick={sendNewEmailCode}
                >
                  درخواست کد جدید
                </button>
                {/* <Tooltip1
                  message=" فرستادن لینک فعالسازی ممکن است تا یک دقیقه زمان ببرد، پس از یک دقیقه دوباره تلاش کنید."
                  position="top"
                /> */}
              </div>
            </div>
            <div className="col-md-6">
              <img
                src="  /img/sign-up-form.svg"
                alt="login"
                className="text-start"
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column justify-content-center align-items-center mx-auto"
          style={{ maxWidth: 900 }}
        >
          <img
            className="my-0"
            src="/img/illustration/account-activated.svg"
            alt="success"
          />
          <h5>
            تبریک! حساب کاربریتون فعال شد.
            <i className="bi bi-emoji-smile ms-2 "></i>
          </h5>

          <div
            className="btn primary-btn checkout-btn cart-footer  mt-3 mb-5 "
            style={{ width: "300px" }}
          >
            <Link
              className="shadow-none "
              data-bs-toggle="modal"
              to="#exampleModalToggle"
              role="button"
              onClick={(e) => {
                window.scroll(0, 0);
                navigate(`/${window.lang}`);
              }}
            >
              <i className="bi bi-person-circle"></i> ورود به حساب کاربری
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
