import { useEffect, useRef, useState } from "react";
import countryStates from "../../../utils/countryStates";
import DropdownSearch from "../../golbal/dropdown/DropdownSearch";
import tools from "../../../utils/tools";

export default function SidebarLeft() {
  const currentUrlAfterLcs = window.location.pathname
    .split("/")
    .slice(2, window.location.pathname.split("/").length + 1)
    .join("/");
     const { lang, state:{state,stateShort,lat,lng}, country, countryShort,firstRender } =
  tools.getItemFromLocalStorage("initSetup", {});
  const [states, setStates] = useState([]);
  const effectRan = useRef(false);
  const [setting, setSetting] = useState({
    lang: lang || "fa",
    country: country,
    countryShort:countryShort,
    firstRender:firstRender || true,
    state: {
      state:state,
      stateShort: stateShort,
      lat: lat || "52.93991590",
      lng: lng || "-73.54913610",
    },
  });

  useEffect(() => {
    if (effectRan.current) return;
    const countryInfo = countryStates.find(coun=>coun.countryShort===countryShort.toUpperCase())
      // console.log('countryInfo',countryInfo)
    setStates(countryInfo?.states||[]);
    // if (setting?.firstRender) {
    //   document.getElementById("lcsChangeBtn").click();
    // }
    effectRan.current = true;
  }, []);

  function countrySet(countryShort) {
    console.log('countryShort',countryShort);
    if(!countryShort)return
const countryInfo = countryStates.find((count) => count.countryShort.toUpperCase() === countryShort.toUpperCase());
// const stateInfo = countryInfo?.states.find((state) => state?.stateShort.toLowerCase() === stateShort);
  
console.log('country',country);
console.log('countryInfo',countryInfo);

    if(!country)return
    setStates(countryInfo?.states||[]);
    setSetting((prev) => ({
      ...prev,
      country:countryInfo.country,
      countryShort: countryInfo.countryShort,
      state: {
        state: countryInfo?.states[0].state,
        stateShort: countryInfo?.states[0].stateShort,
        lat: countryInfo?.states[0].lat,
        lng: countryInfo?.states[0].lng,
      },
    }));
  }

  function enterHandler() {
    setSetting((pre) => {
      const set = { ...pre };
      const loc = { ...JSON.parse(localStorage.getItem("initSetup")) };
      set.firstRender = false;
      localStorage.setItem("initSetup", JSON.stringify(set));
      if (
        set.lang !== loc.lang ||
        set.country !== loc.country ||
        set.state.state !== loc.state.state
      ) {
        window.location.replace(
          `/${set.lang}/${currentUrlAfterLcs}`?.toLowerCase()
        );
      }
      return set;
    });
  }  
  const trans={
    changeLang:{fa:"از این جا می‌تونید کشور یا استان دیگری انتخاب کنید:",en:"From here you can change the country or state:"},
    wellcome:{fa:"خوش آمدید به !",en:"Wellcome to!"},
    webLang:{fa:"وبسایت به چه زبانی باشه؟",en:"Which language?"},
    enter:{fa:"ورود به وبسایت",en:"Enter to website"},
   
  }

  return (
    <div className="small-gray-font">
      <div
        className="py-0 ps-2 d-flex"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling2"
        aria-controls="offcanvasScrolling2"
        id="lcsChangeBtn"
      >
        {/* <span className="icon-country">&#127464;&#127462;</span> */}
        <span className="Nav-Province cart-icon" title={window.capitalize(country)+", "+window.capitalize(state)}>
          {state?.length > 7 ? `${state?.slice(0, 7)}...` : state},{window.lang}
        </span>
        <img
          width="25px"
        
        
          className="ms-2"
          src={`/img/flags/${countryShort?.toLowerCase()}.svg`}
          alt={countryShort}
          title={window.capitalize(country)}
          
        />
      </div>
      <div
        className="offcanvas offcanvas-end offcanvas-countryLanguage p-0"
        data-bs-scroll="true"
        data-bs-backdrop="false"
        tabIndex="-1"
        id="offcanvasScrolling2"
        aria-labelledby="offcanvasScrollingLabel2"
      >
        <div className="offcanvas-header offcanvas-countryLanguageTitle ">
          <p className="offcanvas-title  " id="offcanvasScrollingLabel2">
           {trans.wellcome[window.lang]} {window.capitalize(state)} | 
            {window.capitalize(country)}
          </p>

          <button
            type="button"
            className="btn-close text-reset shadow-none btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={enterHandler
              // setSetting((pre) => {
              //   const set = { ...pre };
              //   const loc = {
              //     ...JSON.parse(localStorage.getItem("initSetup")),
              //   };
              //   set.firstRender = false;
              //   localStorage.setItem("initSetup", JSON.stringify(set));
              //   if (
              //     set.lang !== loc.lang ||
              //     set.country !== loc.country 
              //     // || set.state.state !== loc.state
              //   ) {
              //     window.location.replace(
              //       `/${set.lang}/${currentUrlAfterLcs}`?.toLowerCase()
              //     );
              //   }
              //   return set;
              // })
            }
          ></button>
        </div>
        {/* <hr className="mt-0 mb-5 mx-3 " /> */}
        <div className="offcanvas-body text-center ">
          <span className="ps-2 pe-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              className="bi bi-globe-americas"
              viewBox="0 0 16 16"
            >
              <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484-.08.08-.162.158-.242.234-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
            </svg>
          </span>
          {trans.changeLang[window.lang]}
          {/* از این جا می‌تونید کشور یا استان دیگری انتخاب کنید: */}
          <div className="d-flex flex-column align-items-center mb-5">
            <div className="  selectpicker w-75 shadow-none my-3 mx-5 text-center ">
              <DropdownSearch
                updateParent={countrySet}
                data={countryStates}
                country={country}
              />
            </div>
            <select
              name="stateselectorselect"             
              value={`${setting?.state?.state},${setting?.state?.stateShort},${setting?.state?.lat},${setting?.state?.lng}`}
              className="btn-search  w-75 selectpicker shadow-none  "
              onChange={(e) => {
                setSetting((prev) => ({
                  ...prev,
                  state: {
                    state: e.target.value.split(",")[0],
                    stateShort: e.target.value.split(",")[1],
                    lat: e.target.value.split(",")[2],
                    lng: e.target.value.split(",")[3],
                  },
                }));
              }}
            >
              {!setting?.state?.state?.length && (
                <option value="">انتخاب کنید</option>
              )}
              {states?.map((state, i) => (
                <option
                  value={[
                    state.state.toLowerCase(),
                    state.stateShort.toLowerCase(),
                    state.lat,
                    state.lng,
                  ]}
                  key={i}
                >
                  {window.capitalize(state?.state)}
                </option>
              ))}
            </select>
          </div>
          {/* <hr className="my-5" /> */}
          <div className="my-3 mt-5  px-5">
            <p>
              <span>
                <i className="bi bi-translate p-2 landing_icon"></i>
              </span>
              {trans.webLang[window.lang]}
              {/* وبسایت به چه زبانی باشه؟ */}
            </p>
            <div className="d-flex justify-content-between my-1" dir="rtl">
              <input
                className="form-check-input"
                type="radio"
                id="englishBtn"
                value="en"
                name="paymentMethod"
                required
                readOnly
                disabled
                checked={setting.lang==="en"}
                // onChange={e => setSetting(prev=>({...prev,lang:e.target.value}))}
                // onChange={(e) =>
                //   window.open(`/${window.lang}/prompt/coming`, {
                //     target: "_blank",
                //   })
                // }
              />
              <label htmlFor="englishBtn">English</label>
            </div>
            <div className="d-flex justify-content-between" dir="rtl">
              <input
                className="form-check-input"
                type="radio"
                readOnly
                id="frenchBtn"
                value="fr"
                disabled
                name="paymentMethod"
                required
                // checked={setting.lang==="fr"}
                // onChange={e => setSetting(prev=>({...prev,lang:e.target.value}))}
                // onChange={(e) =>
                //   window.open(`/${window.lang}/prompt/coming`, {
                //     target: "_blank",
                //   })
                // }
              />
              <label htmlFor="frenchBtn">Français</label>
            </div>

            <div className="d-flex justify-content-between my-1" dir="rtl">
              <input
                className="form-check-input"
                type="radio"
                id="persianBtn"
                value="fa"
                name="paymentMethod"
                required
                checked={setting.lang === "fa"}
                onChange={(e) =>
                  setSetting((prev) => ({ ...prev, lang: e.target.value }))
                }
              />
              <label htmlFor="persianBtn">فارسی</label>
            </div>
          </div>
          <div className="nav-pills  ">
            <button
              className="mt-5 mb-0 login-btn login-btn2  "
              type="button"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={enterHandler}
            >
              {trans.enter[window.lang]}
              {/* ورود به وبسایت */}
            </button>
          </div>
          <img src="/img/world-tour.svg" alt="login" />
        </div>
      </div>
    </div>
  );
}
