import { useState, useEffect, useContext, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import apiMP from "../../../../utils/apiMP";
import { CartContext } from "../../../contexts/cartContext";
import renderData from "../../../../utils/renderData";
import ToastNote from "../../../golbal/notifications/toastNote";
import { CircularProgress, Rating } from "@mui/material";
import BusCardSlider from "../../../golbal/sliders/busCardSlider";
import api from "../../../../utils/api";
import tools from "../../../../utils/tools";
import MetaDecorator from "../../../../utils/metaDecorator";
import SlideshowGalery from "../../business/components/slideshowGalery/slideshowGalery";
import ShareSocialMedias from "../../business/components/shareSocialMedias";
import CommentsRating from "../../../comment/commentsRating";

export default function UsedPage() {
  const messageRef = useRef(null); 
  const effectRan = useRef(false);
  const { user, signedUser } = useContext(CartContext);
  const [request, setRequest] = useState({ email: "", message: "" });
  // const { translations } =
  //   JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
    const { lang, state:{state,stateShort,lat,lng}, country, countryShort } =
    tools.getItemFromLocalStorage("initSetup", {});
  const { id } = useParams();
  const [data, setData] = useState(useLocation().state);
  const [busSlider, setBusSlider] = useState([]);
  // console.log("data", data);
  // console.log("useLocation().state", useLocation());
  const [loading, setLoading] = useState({
    loading1: false,
    loading2: false,
    loading3: false,
    loading4: false,
    save: false,
  });

  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    setLoading((pre) => ({ ...pre, save: true }));
    e.target.setAttribute("disabled", true);
    // console.log("saveHandler", data);
    const response = await api.saveHandler({
      id: data._id,
      token: user.token,
      section: "used",
    });

    setLoading((pre) => ({ ...pre, save: false }));
    setTimeout(() => e.target.removeAttribute("disabled"), 1000 * 10);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    // user.saved.used.find((x) => x._id == used?._id);
    !user?.saved?.used?.includes(data?._id)
      ? messageRef.current.showSuccess({
          message: "آگهی با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "آگهی از لیست ذخیره حذف شد.",
        });

    signedUser(response.data);
  };

  useEffect(() => {
    if (!id) return;
    (async () => {
      const response = await apiMP.usedDetails({ token: user.token, id });
      if (response.error) return alert(response.error);
      setData(response.data);
      if (effectRan.current) return;
      // fetchBusSlider(response.data);
      effectRan.current = true;
    })();


  }, [id]);

  const url = `https://www.metamarce.com/${window.lang}/mp/used/page/${tools.slugMaker({title:data?.title,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`;

  const title = `${data?.title} | ${
    data?.category?.subCat?.[window.lang]
  } در ${window.capitalize(data?.moreInfo?.address?.city)}`;
  const description = `${data?.moreInfo?.description?.substring(0, 120)} | ${
    data?.moreInfo?.address.city
  }`;
  const structuredData = [   
    {
      "@context": "http://schema.org",
      "@type": "Product",
      name: data?.title,
      description: data?.moreInfo?.description,      
      offers: {
        "@type": "Offer",
        "url":url,
        price: data?.moreInfo?.price?.rate,
        priceCurrency: data?.moreInfo?.price?.currency,
        availability: "In Stock",
        "priceValidUntil": "9999-12-31",
        "hasMerchantReturnPolicy": {
          "@type": "MerchantReturnPolicy",
          "applicableCountry": [data?.moreInfo?.address?.countryShort?.toUpperCase()],
          "returnPolicyCountry": data?.moreInfo?.address?.countryShort?.toUpperCase(),
          "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
          "merchantReturnDays": 5,
          "returnMethod": "https://schema.org/ReturnByMail",
          "returnFees": "https://schema.org/FreeReturn",
          "refundType": "https://schema.org/FullRefund"
        },
        "shippingDetails": {
    "@type": "OfferShippingDetails",
    "shippingRate": {
      "@type": "MonetaryAmount",
      "value": 0,
      "currency": data?.moreInfo?.price?.currency
    },
    "shippingDestination": {
      "@type": "DefinedRegion",
      "addressCountry": data?.moreInfo?.address?.countryShort?.toUpperCase()
    },
    "deliveryTime": {
      "@type": "ShippingDeliveryTime",
      "handlingTime": {
        "@type": "QuantitativeValue",
        "minValue": 0,
        "maxValue": 1,
        "unitCode": "DAY"
      },
      "transitTime": {
        "@type": "QuantitativeValue",
        "minValue": 1,
        "maxValue": 5,
        "unitCode": "DAY"
      }
    }
  }
      },

      image: data?.moreInfo?.photos[0] || "",
      url: url,
      // address: {
      //   "@type": "PostalAddress",
      //   addressLocality: data?.moreInfo?.address?.city?.toUpperCase() ?? "",
      //   addressRegion: data?.moreInfo?.address?.state?.toUpperCase(),
      //   addressCountry: data?.moreInfo?.address?.countryShort?.toUpperCase(),
      //   // postalCode: data?.moreInfo?.address?.postalCode ?? "",
      //   // streetAddress: `${data?.moreInfo?.address?.streetNumber} ${data?.moreInfo?.address?.route}`,
      // },
      // geo: {
      //   "@type": "GeoCoordinates",
      //   latitude: data?.moreInfo?.address?.coordinates?.lat || "",
      //   longitude: data?.moreInfo?.address?.coordinates?.lng || "",
      // },

      // ...(data?.feedbackInfo?.rates > 0 && {
      //   aggregateRating: {
      //     "@type": "AggregateRating",
      //     ratingValue: data?.feedbackInfo?.rate ?? 0,
      //     bestRating: 5,
      //     ratingCount: data?.feedbackInfo?.rates ?? 0,
      //   },
      // }),
      ...(data?.feedbackInfo?.comment?.comments?.length > 0 && {
        review: data?.feedbackInfo?.comment?.comments?.map((rev) => ({
          "@type": "Review",
          reviewRating: {
            "@type": "Rating",
            ratingValue: rev?.rate || 0,
          },
          author: {
            "@type": "Person",
            name: rev?.userName,
          },
        })),
      }),
      ...(data?.moreInfo?.tags?.length > 0 && {
        keywords: data?.moreInfo?.tags,
      }),
      // openingHoursSpecification: data?.moreInfo?.workingDays?.map(
      //   (day) => ({
      //     "@type": "OpeningHoursSpecification",
      //     dayOfWeek: day.weekDay,
      //     opens: day.from,
      //     closes: day.to,
      //   })
      // ),
      additionalType: [
        data?.category?.subCat?.[window.lang],
        // Add more categories as needed
      ],
      // "acceptsReservations": "True"
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: data?.title,
      description: data?.moreInfo?.description,
      url: url,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/mp/used/filters`,
              name: "کی چی کجا",
              description: `کی چی کجا، نیازمندیهای ایرانیان سراسر دنیا`,
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": url,
              name: data?.title,
            },
          },
        ],
      },
    },
  ];

  const metaTagsData = {
    title,
    description,
    section: "market place page",
    url: url,
    img: data?.moreInfo?.img,
    type: "product",
  };
 const isTrip = data?.category?.mainCat?._id ==="66c0d7e8c79e6e1af314f511"
 const isHome = data?.category?.mainCat?._id === "66c126fec79e6e1af31547d1"
 const isStudy = data?.category?.subCat?._id==="66c0deb7c79e6e1af314f51a"
  const isMeet = data?.category?.subCat?._id==="670eea450e4cc9045a43d5b2"
  const isFun = data?.category?.subCat?._id==="670ee9b60e4cc9045a43d3b3"
  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <ToastNote messageRef={messageRef} />
      {/* <div className="bus-bio-bg"> */}
      <nav className="p-md-3 p-2" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={`/${window.lang}`} className="breadcrumb-item">
              خانه
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/${window.lang}/mp`} className="breadcrumb-item">
             نیازمندی‌ها
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              to={`/${window.lang}/mp/used/filters`}
              className="breadcrumb-item"
            >
               کی‌چی‌کجا
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="breadcrumb-item active">
              <strong> {data?.title.substring(0, 40)}</strong>
            </span>
          </li>
        </ol>
      </nav>

      <div className="ads-all mx-auto">
        <div className="row p-0 m-0">
          <div className=" col-lg-5 d-flex flex-wrap  justify-content-center pt-3 ">
            <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lang}/mp/used/filters?mainCat=${
                data?.category?.mainCat?.[window.lang]
              }&mainCatId=${data?.category?.mainCat._id}`}
              // to={'/${lcs}/data/filters?mainCat=${data?.category.mainCat?.[window.lang]}&mainCatId=${data?.category?mainCat?._id}'}
              className="btn btn-primary button-filter button-filter-tag"
            >
              <span>
                <i className="bi bi-list-ul me-1"></i>
              </span>
              {data?.category?.mainCat?.[window.lang]}
            </Link>
            <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lang}/mp/used/filters?subCat=${
                data?.category?.subCat?.[window.lang]
              }&subCatId=${data?.category?.subCat._id}`}
              className="btn btn-primary button-filter button-filter-tag  "
            >
              <span>
                <i className="bi bi-list-nested me-1 "></i>
              </span>
              {data?.category?.subCat?.[window.lang]}
            </Link>
          </div>
          <div className="col-lg-7 pt-3">
            {" "}
            <div className="  d-flex  flex-wrap justify-content-center ">
              {/* <!-- Button trigger modal --> */}

              {((user?._id ?? false) ||
                data?.moreInfo?.history?.createdBy?._id ===
                  (user?._id ?? false) ||
                user?.roles?.includes(1000)) && (
                <>
                  <Link
                    className="btn btn-primary share-cover-btn mx-1 px-1 font-10 "
                    // data-bs-toggle={!user.roles?.includes(9999) && "modal"}
                    to={
                      // user.roles?.includes(9999)?
                      `/${window.lang}/dashboard/mp/editused/${tools.slugMaker({title:data?.title,city:data?.moreInfo?.address?.city,state:data?.moreInfo?.address?.state,serial:data?.serial})}`
                      // : "#exampleModalToggle"
                    }
                    state={data}
                    type="Link"
                  >
                    ویرایش ‌<i className="bi bi-arrow-repeat"></i>
                  </Link>
                </>
              )}

              <Link className="btn btn-primary share-cover-btn mx-1 px-1 font-10 ">
                گزارش
                <i className="bi bi-flag"></i>
              </Link>

              <button
                className="btn btn-primary share-cover-btn mx-1 px-1 font-10  "
                data-bs-toggle="modal"
                // data-bs-target={`#myModel${data?._id}`}
                data-bs-target={`#myModel${data?._id}`}
                id="shareBtn"
                data-bs-placement="top"
                title="Share!"
              >
                همرسانی
                <i className="bi bi-share-fill mx-2"></i>
              </button>

              {/* <!-- Modal --> */}
              <ShareSocialMedias
                url={url}
                title={data?.title}
                img={data?.moreInfo?.photos?.[0]}
                _id={data?._id}
              />

              <button
                className="btn btn-outline-secondary save-cover-btn mx-1 px-1 font-10 "
                onClick={saveHandler}
              >
                ذخیره
                {loading.save ? (
                  <CircularProgress color="inherit" size="20px" />
                ) : (
                  <i
                    className={
                      user?.saved?.used?.includes(data?._id)
                        ? "bi bi-bookmark-fill ms-1"
                        : "bi bi-bookmark ms-1"
                    }
                  ></i>
                )}
              </button>
            </div>
          </div>
        </div>
        {/* <!-- Listing Details Section Begin -->*/}

        {/* <!-- <div className="container"> -->*/}
        <div className="row m-md-2 p-2 gx-5">
          <div className="col-lg-7 order-lg-last">
            {data?.moreInfo?.photos?.length > 0 && (
              <div className="listing-details-information py-3 mb-5">
                {/* <div className=" divider-small border-0 mt-4 px-5">
                  <span>
                    <img
                      src="/img/bussiness-page/gallery.png"
                      className="arrow2 pe-2"
                      alt="info"
                      loading="lazy"
                    />
                  </span>

                  <span className="p-0 mb-0 ">گالری </span>

                </div> */}
                <div className="px-3">
                  <SlideshowGalery
                    data={data?.moreInfo?.photos}
                    alt={data?.title}
                  />
                </div>
              </div>
            )}
            <div className="listing-details-information p-3  my-5  ">
              <h1 className=" font-20 fw-bold ">
                {tools.linesSpliter(data?.title)}
              </h1>
              <div className="d-flex ">
              {!(isTrip&&(!isStudy&&!isMeet&&!isFun))&&<div title=" location " className="m-0">
                  <i className="bi bi-geo-alt  px-2 "></i>
                  <span className="small-gray-font ">
                    <span>
                      {`${window.capitalize(
                        data?.moreInfo.address?.city || ""
                      )},
                    ${window.capitalize(data?.moreInfo.address?.state)},
                    ${window.capitalize(data?.moreInfo.address?.countryShort)}
                `}
                    </span>
                  </span>
                </div>}
                <div className="m-0">
                  <i className="bi bi-calendar2-check  px-2 "></i>
                  <span className="small-gray-font ">
                    {tools.timeAgo(data?.moreInfo.lastUpdate)}
                  </span>
                  {user?.roles?.includes(1000)?<span className="review-text text-start ">
                    (<i className="bi bi-eye  px-1  " title="بازدید"></i>
                    {data?.feedbackInfo?.views} )
                  </span>:""}
                </div>
              </div>
              <div className="d-flex  my-3 font-20 justify-content-center text-pink-orange-dark">
                <i className="bi bi-tag me-1"></i>
                {data?.moreInfo?.isFree ? (
                  <div className="prof-tag pt-1 px-2">رایگان</div>
                ) : (
                  <div>
                    <strong>
                      {tools.showPrice(
                        data?.moreInfo?.price?.rate,
                        data?.moreInfo?.price?.currency
                      )}
                    </strong>
                  </div>
                )}
                <div className="prof-tag prof-tag2 font-10 mx-3 px-2 text-center pt-1">
                  {tools.renderTrans({
                    section: "usedStatus",
                    key: data?.moreInfo?.status,
                  })}
                </div>
              </div>
              {isHome && (
                <HomeInfoRender data={data} />
              )}

              {(data?.category?.subCat?._id === "66c1285fc79e6e1af3154801" ||
                data?.category?.subCat?._id === "66c128a0c79e6e1af3154807") && (
                <CarInfoRender data={data} />
              )}
              {isTrip ? 
                <TripInfoRender data={data} countryShort={countryShort}
                isStudy={isStudy}
                isMeet={isMeet}
                isFun={isFun}
                /> :
                !isHome ?
                <div>
                <div className="d-flex justify-content-between me-md-5">
                  <div className="text-gray-green"> وضعیت سلامت :</div>
                  <div>
                    {" "}
                    {tools.renderTrans({
                      section: "condition",
                      key: data?.moreInfo?.condition,
                    })}
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between me-md-5">
                  <div className="text-gray-green"> چگونگی تحویل :</div>
                  <div>
                    {" "}
                    {tools.renderTrans({
                      section: "delivery",
                      key: data?.moreInfo?.delivery,
                    })}
                  </div>
                </div>
                </div>:""
              }
             
              {/* <div className="d-flex justify-content-between me-md-5">
                چگونگی تحویل :
                {tools.renderTrans({
                  section: "delivery",
                  key: data?.moreInfo?.delivery,
                })}
              </div> */}
              <hr />
              <div className=" divider-small border-0 my-2 fw-bold">
                <span>
                  <img
                    src="/img/bussiness-page/notes.png"
                    className="arrow2 pe-2"
                    alt="facilities"
                    loading="lazy"
                  />
                </span>
                <span className="p-0 ">توضیحات </span>
              </div>
              <div className="med-gray-font">
                {tools.linesSpliter(data?.moreInfo?.description)}
              </div>
            </div>
          </div>
          <div className=" col-lg-5 ">
            {/* <!-- <div className=""> -->*/}

            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small">
                <span>
                  <img
                    src="/img/bussiness-page/email.png"
                    className="arrow2 pe-2 py-1"
                    alt="more info"
                    loading="lazy"
                  />
                </span>
                <span className="p-0 mb-0 ">اطلاعات تماس</span>

                {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
              </div>

              {/* <div style={{direction:"ltr"}}> */}
              <ul className="list-group px-1" dir="ltr">
                {/* {data?.email && (
                  <li className="group-list-item flex-column" dir="ltr">
                    <div dir="ltr">
                      <span>
                        <i className="bi bi-envelope mx-3"></i>
                      </span>
                      {data?.email}
                    </div>
                  </li>
                )} */}

                {data?.moreInfo?.phone && (
                  <li className="group-list-item">
                    <i className="bi bi-telephone mx-3"></i>
                    {tools.formatPhoneNumber(data?.moreInfo?.phone)}
                  </li>
                )}
                {data?.moreInfo?.contact && (
                  <li className="group-list-item">
                    <i className="bi bi-info-square mx-3"></i>
                    <span>{data?.moreInfo?.contact}</span>
                  </li>
                )}
                {data?.moreInfo?.link && (
                  <li className="group-list-item">
                    <i className="bi bi-link mx-3"></i>
                    <a href={data?.moreInfo?.link} target="_new">تماس با صاحب آگهی</a>
                  </li>
                )}
                {/* <!-- <li className="group-list-item"><i className="bi bi-clock"></i><span>Sat - Mon:</span> 09.00am
                                            - 10.00pm</li> -->*/}
                {/* <li className="group-list-item">
                  <i className="bi bi-globe mx-3"></i>
                  <div className="text-break">
                    {data?.moreInfo?.website?.length > 0 ? (
                      <a
                        href={
                          (!data?.moreInfo?.website.startsWith("http")
                            ? "http://"
                            : "") + data?.moreInfo?.website
                        }
                        target="_blank"
                      >
                        {data?.moreInfo?.website}
                      </a>
                    ) : (
                      <span title="وبسایت تاکنون ثبت نشده است">
                        بدون وبسایت
                      </span>
                    )}
                  </div>
                </li> */}
                {/* <li className="group-list-item ">
                  <i className="bi bi-envelope mx-3"></i>
                  <div className="text-break">{data?.email}</div>
                </li> */}
                {/* {data?.moreInfo?.address?.displayAddress && (
                  <li
                    className="group-list-item pe-auto cursor"
                    onClick={() =>
                      openGoogleMaps(data?.moreInfo?.address?.googleAddress)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="32"
                      fill="currentColor"
                      className="bi bi-sign-turn-right mx-3"
                      viewBox="0 0 16 32"
                    >
                      <path d="M5 8.5A2.5 2.5 0 0 1 7.5 6H9V4.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L9.41 8.658A.25.25 0 0 1 9 8.466V7H7.5A1.5 1.5 0 0 0 6 8.5V11H5V8.5Z" />
                      <path
                        fillRule="evenodd"
                        d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435Zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134Z"
                      />
                    </svg>
                    <div className="map-dir">مسیریابی با نقشه گوگل</div>
                  </li>
                )} */}
              </ul>

             <div className=" d-flex flex-wrap  justify-content-center  mt-3">
                <button
                  onClick={(e) => {
                    if (!window.logined(9999)) return;
                    const myModal = document.getElementById("messageModal"); // Get the modal element
                    const modal = new window.bootstrap.Modal(myModal, {
                      // backdrop: "static",
                      keyboard: true,
                    });
                    modal.show();
                  }}
                  className="btn primary-btn checkout-btn cart-footer w-100 "
                >
                  <span>
                    <i className="bi bi-send-plus me-1"></i>
                  </span>
                  فرستادن پیام
                </button>
                {/* <!-- Modal --> */}
                <div
                  className="modal fade"
                  id="messageModal"
                  tabIndex="-1"
                  aria-labelledby="sendMessageModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1
                          className="modal-title titr5  "
                          id="sendMessageModalLabel"
                        >
                          فرستادن پیام به{" "}
                          {data?.moreInfo?.history?.createdBy?._id?.userName}
                        </h1>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-7">
                            <div>
                              {/* <label
                            className="med-gray-font m-3"
                            htmlFor="sessionRequest"
                          >
                             اگه پرسشی از {data?.busName[window.lang]} دارید میتونید مستقیما ازشون بیرسید.
                          </label> */}
                              <textarea
                                placeholder="پیامتون رو این‌جا بنویسید..."
                                className="w-100 px-3 border-0"
                                name=""
                                id="sessionRequest"
                                cols="50"
                                rows="10"
                                onChange={(e) =>{
                                  
                              e.target.style.height = "auto";
                              e.target.style.height = e.target.scrollHeight + "px";
                             
                                  setRequest((pre) => ({
                                    ...pre,
                                    message: e.target.value,
                                  }))}
                                }
                              ></textarea>
                              <button
                                type="button"
                                className="btn btn-primary border-0  save-cover-btn w-100 px-3"
                                // data-bs-dismiss="modal"
                                onClick={sendTextHandle}
                              >
                                بفرست
                              </button>
                            </div>
                          </div>
                          <div className="col-md-5 align-items-center  display-none-md">
                            <img
                              src="/img/Bussiness-page/Messaging-bus.svg"
                              alt="claim"
                              loading="lazy"
                              // style={{ verticalAlign: "center" }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="modal-footer">
                   
                      <button
                        type="button"
                        className="btn btn-outline-secondary save-cover-btn ms-3"
                        data-bs-dismiss="modal"
                      >
                        بستن
                      </button>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!(data?.moreInfo?.history?.createdBy?._id?._id==="633af9395b72c63167d2e59a"||data?.moreInfo?.history?.createdBy?._id?._id==="62d94eb31b842c02fdf82a2b")&&<div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small">
                <span>
                  <img
                    src="/img/icons/about.png"
                    className="arrow2 pe-2 py-1"
                    alt="more info"
                    loading="lazy"
                  />
                </span>
                <span className="p-0 mb-0 ">آگهی‌دهنده </span>
              </div>
              <div>
                <div className="d-flex">
                  <img
                    className="picture picture3 me-3"
                    src={data?.moreInfo?.history?.createdBy._id?.thumbnail}
                    alt="publisher photo"
                    width="60px"
                  />
                  <div>
                    <div className="mb-2">
                      {data?.moreInfo?.history?.createdBy._id?.userName}
                    </div>

                    <div>
                      {" "}
                      <Rating
                        name="read-only"
                        value={Number(4)}
                        readOnly
                        precision={0.5}
                      />
                    </div>
                    <div className="small-gray-font text-center">
                      {tools.showYMHistory(
                        data?.moreInfo?.history?.createdBy._id?.createdAt
                      )}{" "}
                      |{" "}
                      <i
                        className="bi bi-card-list cursor"
                        onClick={(e) =>
                          window.navigateHandle(
                            `/${window.lang}/mp/used/filters?userId=${data?.moreInfo?.history?.createdBy._id._id}&userName=${data?.moreInfo?.history?.createdBy._id?.userName}`
                          )
                        }
                      >
                        {" "}
                        آگهی‌های دیگر
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
            <div className="listing-sidebar-section mt-0 mb-5 bg-beigeDark">
              <div className="divider2 divider-small">
                <span>
                  <img
                    src="/img/icons/about.png"
                    className="arrow2 pe-2 py-1"
                    alt="more info"
                    loading="lazy"
                  />
                </span>
                <span className="p-0 mb-0  ">هشدار </span>
              </div>
              <div className="read-more2  d-flex mb-3 fw-bold cursor">
                <div
                  className=""
                  data-bs-toggle="modal"
                  data-bs-target="#warningModal"
                >
                  مشاهده زنگ خطر های این آگهی
                  <span>
                    <i className="bi bi-chevron-double-left ms-1"></i>
                  </span>
                </div>
                <WarningModal isTrip={isTrip} />
              </div>
            </div>
            {data?.moreInfo?.tags?.length > 0 && (
              <div className="listing-details-information p-5  pt-3 my-5 ">
                <div className=" divider-small border-0 mt-4 ">
                  <span>
                    <img
                      src="/img/bussiness-page/tag.png"
                      className="arrow2 pe-2"
                      alt="tags"
                      loading="lazy"
                    />
                  </span>

                  <span className="p-0 mb-0 ">برچسب‌ها </span>

                  {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
                </div>

                <ul className=" d-flex flex-wrap justify-content-between">
                  {data?.moreInfo?.tags?.map((tag, i) => (
                    <li key={i} className="cursor"  onClick={(e) =>
                      window.navigateHandle(`/${window.lang}/mp/used/filters?tag=${tag}`)
                    }>
                      <div
                       
                      >
                        <span key={i}>                         
                          <i className="bi bi-tag me-1"></i>
                        </span>
                        {tag}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* <!-- </div> -->*/}
        </div>

        {/* <!-- ممکنه براتون جالب باشه... -->*/}
        {busSlider?.length >= 4 && (
          <div>
            <div className="divider2 ">
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
                {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  -->*/}
              </svg>

              <span className="p-0 mb-0 ">ممکنه براتون جالب باشه...</span>
              {/* <img
                src="/img/Images/arrow-09-svgrepo-com.svg"
                className="arrow"
                alt="arrow"
              /> */}
            </div>
            <div className="mx-4">
              <BusCardSlider data={busSlider} />
            </div>

            {/* <BusCardSlider2 
            businessess={data?.businessess}
            subCat={data?.category?.subCategory}
            /> */}
          </div>
        )}
      </div>
       {/* *****************comments************** */}
       <div className="row m-0 p-0 gx-5 justify-content-center ">
        <div className="col-md-9 col-lg-9 col-xl-6" id="comments2">
          <CommentsRating
            id={data?._id}
            value={data}
            setData={setData}
            collection="Used"
          />
        </div>
      </div>

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );
  async function sendTextHandle(e) {
    if (!window.logined(9999)) return;
    // console.log(request);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (request.message.length < 10)
      return messageRef.current.showError({
        message: " لطفا توضیحات بیشتری بنویسید.",
      });
    if (request.message.length > 2000)
      return messageRef.current.showError({
        message: " لطفا توضیحات را به صورت مختصر بنویسید.",
      });
    if (request.email.length && !emailRegex.test(request.email))
      return messageRef.current.showError({
        message: "فرمت ایمیل درست نمی باشد.",
      });

    const hostUser = data?.moreInfo?.history?.createdBy?._id?._id;

    setLoading((pre) => ({ ...pre, spinner: true }));
    // e.target.setAttribute("disabled", true);
    const email = request.email.length ? request.email : user.email;
    if (!hostUser)
      return messageRef.current.showError({
        message: "شناسه کاربری مالک آگهی فراهم نشده است.",
      });
    const response = await api.sendText({
      token: user.token,
      data: {
        message: request.message,
        guestEmail: email,
        hostEmail: data.email,
        hostUser,
        hostId: data._id,
        hostName: data?.moreInfo?.history?.createdBy?._id?.userName,
        lang: window.lang,
        lcs: window.lcs,
        guestId: user._id,
        url: `https://metamarce.com/${window.lang}/mp/used/page/${data._id}`,
      },
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    // setTimeout(() => e.target.removeAttribute("disabled"), 10000);
    if (response.error)
      return messageRef.current.showError({
        message: "خطا در انجام عملیات : " + response.error,
      });
    messageRef.current.showSuccess({
      message: (
        <div>
          <div>
            درخواست شما با موفقیت برای {data.title} فرستاده شد و در اولین فرصت
            در متاچت پاسخ خواهند داد.
          </div>
        </div>
      ),
    });
    // setData(response.data);
    var myModal = document.getElementById("messageModal"); // Get the modal element
    var modal = window.bootstrap.Modal.getInstance(myModal);
    modal.hide();
    document.querySelector("textarea").value = "";
    setRequest({ message: "", email: "" });
  }
}

function HomeInfoRender({ data }) {
  return (
    <div>
      <div className="d-flex justify-content-around me-md-5">
        <div className="bg-beigeGray px-4 py-1">
          {" "}
          {tools.renderTrans({
            key: data?.homeInfo?.proType,
            section: "proType",
          })}
        </div>
        <div className="bg-beigeYellow px-4 py-1">
          {" "}
          {tools.renderTrans({
            key: data?.homeInfo?.transType,
            section: "transType",
          })}
        </div>
      </div>
      <div className="d-flex  justify-content-between font-14 my-4">
        <div className="d-flex flex-column align-items-center mx-2">
          <div className="small-gray-font"> زمین(م‌م)</div>
          <div>
            {data?.homeInfo?.lot>0?tools.dynamicNumber({
              number: data?.homeInfo?.lot,
              lang: window.lang,
            }):"--"}
          </div>
        </div>
        <div className="d-flex flex-column align-items-center mx-2">
          <div className="small-gray-font"> زیربنا(م‌م)</div>
          <div>
            {data?.homeInfo?.build>0?tools.dynamicNumber({
              number: data?.homeInfo?.build,
              lang: window.lang,
            }):"--"}
          </div>
        </div>
        <div className="d-flex flex-column align-items-center mx-2">
          <div className="small-gray-font"> ساخت</div>
          <div>
            {tools.dynamicNumber({
              number: data?.homeInfo?.year,
              lang: window.lang,
            })}
          </div>
        </div>
        <div className="d-flex flex-column align-items-center mx-2">
          <div className="small-gray-font"> سرویس</div>
          <div>
            {tools.dynamicNumber({
              number: data?.homeInfo?.bath,
              lang: window.lang,
            })}
          </div>
        </div>
        <div className="d-flex flex-column align-items-center mx-2">
          <div className="small-gray-font">خواب</div>
          <div>
            {tools.dynamicNumber({
              number: data?.homeInfo?.beds,
              lang: window.lang,
            })}
          </div>
        </div>
      </div>
      <div className="fw-bold mt-3">امکانات :</div>

      <div className="font-14">
        <div className="d-flex justify-content-between me-md-5 my-2">
          <div className="text-gray-green"> سیستم سرمایشی :</div>
          <div>
            {" "}
            {tools.renderTrans({
              key: data?.homeInfo?.airCondition,
              section: "airCondition",
            })}
          </div>
        </div>
        <div className="d-flex justify-content-between me-md-5 my-2">
          <div className="text-gray-green"> سیستم گرمایشی :</div>
          <div>
            {" "}
            {tools.renderTrans({
              key: data?.homeInfo?.heating,
              section: "heating",
            })}
          </div>
        </div>
        <div className="d-flex justify-content-between me-md-5 my-2">
          <div className="text-gray-green"> پارکینگ :</div>
          <div>
            {" "}
            {tools.renderTrans({
              key: data?.homeInfo?.parking,
              section: "parking",
            })}{" "}
            {data?.homeInfo?.parking === "garage" &&
              `(${tools.dynamicNumber({
                number: data?.homeInfo?.parkingQty,
                lang: window.lang,
              })} واحد)`}
          </div>
        </div>
      </div>

      <div className="fw-bold mt-3">امکانات بیشتر : </div>
      <ul className="font-14 d-flex flex-wrap ps-0">
        {data?.homeInfo.hFacilities?.map((da) => {
          const facil = renderData.hFacilities.find((fa) => fa.name === da);
          return (
            <li className="m-2 med-gray-font">
              {" "}
              <i className="bi font-10 me-1 bi-circle-fill text-beigeGray"></i>
              {facil[window.lang]}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
function CarInfoRender({ data }) {
  return (
    <div>
      <div className="d-flex justify-content-around me-md-5">
        <div className="bg-beigeGray px-4 py-1">
          {" "}
          {window.capitalize(data?.carInfo?.make)}
        </div>
        <div className="bg-beigeYellow px-4 py-1">
          {" "}
          {window.capitalize(data?.carInfo?.model)}
        </div>
      </div>
      <div className="d-flex justify-content-between font-14 my-4">
        {/* <div className="d-flex flex-column align-items-center mx-2">
      <div  > کلاس</div>
      <div>{window.capitalize(data?.carInfo?.class)}</div>                    
    </div> */}
        <div className="d-flex flex-column align-items-center mx-2">
          <div className="small-gray-font"> سوخت </div>
          <div>
            {tools.renderTrans({
              key: data?.carInfo?.fuel,
              section: "fuelTypes",
            })}
          </div>
        </div>
        <div className="d-flex flex-column align-items-center mx-2">
          <div className="small-gray-font"> گیربکس</div>
          <div>
            {tools.renderTrans({
              key: data?.carInfo?.transmission,
              section: "transmission",
            })}
          </div>
        </div>
        <div className="d-flex flex-column align-items-center mx-2">
          <div className="small-gray-font"> سال</div>
          <div>
            {tools.dynamicNumber({
              number: data?.carInfo?.year,
              lang: window.lang,
            })}
          </div>
        </div>
        <div className="d-flex flex-column align-items-center mx-2">
          <div className="small-gray-font"> کارکرد</div>
          <div>
            {tools.dynamicNumber({
              number: data?.carInfo?.odometer,
              lang: window.lang,
            })}
          </div>
        </div>
      </div>
      {/* <div className="fw-bold mt-2">سایر :</div> */}

      <div>
        <div className="d-flex justify-content-between me-md-5">
          <div className="text-gray-green"> کلاس خودرو :</div>
          <div> {window.capitalize(data?.carInfo?.class)}</div>
        </div>
        <hr />
        <div className="d-flex justify-content-between me-md-5">
          <div className="text-gray-green"> رنگ :</div>
          <div> {window.capitalize(data?.carInfo?.color)}</div>
        </div>
        <hr />
      </div>
    </div>
  );
}
function TripInfoRender({ data,countryShort,isStudy,isMeet,isFun }) {
  const isRide = data?.category?.subCat?._id==="66c0fc4fc79e6e1af314f52a"
  // const isStudy = data?.category?.subCat?._id==="66c0deb7c79e6e1af314f51a"

  return (
    <div>
      {/* <div className="d-flex justify-content-around me-md-5">
        <div className="bg-beigeGray px-4 py-1">
          {" "}
          {window.capitalize(data?.carInfo?.make)}
        </div>
        <div className="bg-beigeYellow px-4 py-1">
          {" "}
          {window.capitalize(data?.carInfo?.model)}
        </div>
      </div> */}
      <div className="d-flex justify-content-between font-14 my-4">
        {/* <div className="d-flex flex-column align-items-center mx-2">
      <div  > کلاس</div>
      <div>{window.capitalize(data?.carInfo?.class)}</div>                    
    </div> */}
        {/* <div className="d-flex flex-column align-items-center mx-2">
          <div className="small-gray-font"> سوخت </div>
          <div>
            {tools.renderTrans({
              key: data?.carInfo?.fuel,
              section: "fuelTypes",
            })}
          </div>
        </div>
        <div className="d-flex flex-column align-items-center mx-2">
          <div className="small-gray-font"> گیربکس</div>
          <div>
            {tools.renderTrans({
              key: data?.carInfo?.transmission,
              section: "transmission",
            })}
          </div>
        </div>
        <div className="d-flex flex-column align-items-center mx-2">
          <div className="small-gray-font"> سال</div>
          <div>
            {tools.dynamicNumber({
              number: data?.carInfo?.year,
              lang: window.lang,
            })}
          </div>
        </div> */}
        {/* <div className="d-flex flex-column align-items-center mx-2">
          <div className="small-gray-font"> کارکرد</div>
          <div>
            {tools.dynamicNumber({
              number: data?.carInfo?.odometer,
              lang: window.lang,
            })}
          </div>
        </div> */}
      </div>
      {/* <div className="fw-bold mt-2">سایر :</div> */}

      {(!isStudy&&!isMeet&&!isFun)&&<div>
        <div className="d-flex justify-content-between me-md-5">
          <div className="text-gray-green"> مبدا :</div>
          <div> {window.capitalize( data?.tripInfo?.from?.location?.city)}</div>
          
        </div>
        <hr />
        <div className="d-flex justify-content-between me-md-5">
          <div className="text-gray-green"> مقصد :</div>
          <div>  {window.capitalize( data?.tripInfo?.to?.location?.city)}</div>
        </div>
        <hr />
        
        <div className="d-flex justify-content-between me-md-5">
          <div className="text-gray-green"> تاریخ :</div>
          <div className="d-flex" dir="ltr">
          <div>  {tools.dynamicDate({
                            date: data?.tripInfo?.from?.date,
                            countryShort,
                            lang: window.lang,
                            showYear: true,                            
                          })} ~</div>
          <div dir="rtl">  {tools.dynamicDate({
                            date: data?.tripInfo?.from?.date,
                            countryShort:"ir",
                            lang: window.lang,
                            showYear: true,                            
                          })}</div>
        </div>
        </div>         
        <hr />

        {data?.tripInfo?.capacity>0&&<>        
        <div className="d-flex justify-content-between me-md-5">
          <div className="text-gray-green"> ظرفیت بار :</div>
          <div>  {tools.dynamicNumber({
                    number: data?.tripInfo?.capacity,
                    lang: window.lang,
                  })} کیلو</div>
        </div>
        <hr />
        </>}
        {data?.tripInfo?.activityType?<div className="d-flex justify-content-between me-md-5">
          <div className="text-gray-green"> نوع آگهی :</div>
          <div>   {tools.renderTrans({
                  key: data?.tripInfo?.activityType,
                  section: isRide?"rideType": "activityType",
                })}</div>
        </div>:""}
        
      </div>}
    </div>
  );
}

function WarningModal({ isTrip }) {
  return (
    <div
      className="modal fade"
      id="warningModal"
      tabIndex="-1"
      aria-labelledby="WarningModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        {isTrip?<div className="modal-content p-2">           
          <div className="modal-header">
            <h2 className="modal-title titr5  " id="WarningModalLabel">
            هشدار و سلب مسئولیت
              {/* {data?.moreInfo?.history?.createdBy?._id?.userName} */}
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
  <p>
    ما در <strong>متامارس</strong> تمام تلاش خود را می‌کنیم تا بستری امن و قابل اعتماد برای پیدا کردن همسفر و همراه برای سفر یا حمل و نقل ایجاد کنیم. با این حال، برای دستیابی به این هدف، نیازمند همکاری و دقت شما هستیم. لطفاً موارد زیر را با دقت بخوانید تا تجربه‌ای مطمئن‌تر و لذت‌بخش‌تر داشته باشید.
  </p>

  <ol>
    <li>
      <strong>اطلاع از خطرات احتمالی:</strong> سفر یا ارسال بار و کالا به کمک دیگران ممکن است با برخی چالش‌ها همراه باشد. توصیه می‌کنیم هنگام استفاده از خدمات، دقت کافی به خرج دهید و تنها با افرادی که اعتماد دارید، همکاری کنید. هرچند تلاش ما بر ایجاد محیطی امن می‌باشد، اما نمی‌توانیم تمامی تعاملات و کاربران را کنترل کنیم.
    </li>

    <li>
      <strong>سلب مسئولیت:</strong> <strong>متامارس</strong> به عنوان پلتفرمی برای ارتباط افراد فعالیت می‌کند و مسئولیتی در قبال رفتار، گفتار یا اقدامات کاربران بر عهده ندارد. ما اینجا هستیم تا به شما کمک کنیم، اما تصمیمات و انتخاب‌های نهایی به عهده خود شماست.
    </li>

    <li>
      <strong>توصیه به دقت و احتیاط:</strong> لطفاً در تنظیم و هماهنگی سفرها یا ارسال کالا، به اطلاعات مانند <strong>تاریخ‌ها</strong> دقت کنید و حتماً مدارک شناسایی و اطلاعات معتبر افراد را بررسی کنید. ما همیشه پیشنهاد می‌کنیم که با افرادی که از قبل می‌شناسید یا از اعتماد آنان اطمینان دارید، همکاری داشته باشید.
    </li>

    <li>
      <strong>احترام و رفتار مسئولانه:</strong> جامعه‌ای امن و سالم نیاز به احترام و همکاری همه کاربران دارد. از شما تقاضا داریم که در تمامی تعاملات خود محترمانه برخورد کنید و از ایجاد هرگونه مزاحمت برای دیگر کاربران خودداری کنید. هدف ما ساختن محیطی دوستانه و امن است و در این راه به همکاری شما نیاز داریم.
    </li>

    <li>
      <strong>شرایط استفاده:</strong> با استفاده از این سرویس، شما تأیید می‌کنید که با <a href="/fa/prompt/disclaimer" target="_blank">شرایط استفاده</a> و <a href="/fa/prompt/privacy" target="_blank">سیاست حریم خصوصی</a> وبسایت موافقید و مسئولیت کامل تعاملات و تصمیمات خود را بر عهده می‌گیرید.
    </li>
  </ol>
</div>
:<div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title titr5  " id="WarningModalLabel">
              زنگ خطر های معامله
              {/* {data?.moreInfo?.history?.createdBy?._id?.userName} */}
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-7">
                <div>
                  <div>
                    از پرداخت وجه به فروشندگان قبل از دریافت کالا به شدت خودداری
                    کنید و قبل از نهایی سازی معامله حتما از اصالت و کارکرد موضوع
                    آگهی مطمئین شوید.
                  </div>
                  <div className="mt-2">
                    <div className="fw-bold">
                      چگونه مشکل یک آگهی را گزارش کنم؟
                    </div>
                    <div>
                      در صورت مشاهده تخلف در ظاهر آگهی، رفتار آگهی‌گذار یا هنگام
                      معامله، می‌توانید با انتخاب گزینه «گزارش مشکل آگهی» مشکل
                      موجود در آن‌ را به متامارس انتقال دهید تا پس از بررسی و
                      اثبات اقدامات و تمهیدات لازم صورت گیرد.
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="fw-bold">نکات مهم پیش از معامله:</div>
                    <div className="mt-1">
                      <div>
                        هرگز پیش از دریافت کالا یا موضوع معاملعه مبلغی تحت عنوان
                        بیعانه، شیرینی، رزرو و... پرداخت نکنید.{" "}
                      </div>
                    </div>
                    <div className="mt-1">
                      هنگامی که قیمت و شرایط ارائه شده بیش از حد عالی به نظر می
                      رسد و فروشنده اطلاعات شفاف و کاملی درباره کالا ارائه
                      نمی‌دهد از انجام معامله و پرداخت وجه بدون انجام کارشناسی
                      مستقل خودداری کنید.
                    </div>
                    <div className="mt-1">
                      اگر می‌خواهید مبلغ قابل توجهی به کسی که نمی‌شناسید
                      بپردازید یا کالای گران قیمتی را با خود حمل کنید، پیشنهاد
                      می‌کنیم حتماً یکی آشنایان خود را همراه داشته باشد و در
                      مکان‌های شلوغ قرار بگذارید.{" "}
                    </div>
                    <div className="mt-1">
                      برای خرید وسایل الکترونیکی (مانند گوشی موبایل، تبلت،
                      لپ‌تاپ و …)، بهتر است از فروشنده بخواهید بسته‌بندی اولیه
                      کالا را نیز ارائه کند.
                    </div>
                    {/* <div  className="mt-1"> در هنگام ارتباط با سایر کابران همواره هشیار باشید و به کسی که به تازگی با او آشنا شده اید اعتماد نکنید</div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5 align-items-center  display-none-md">
                <img
                  src="/img/Bussiness-page/Messaging-bus.svg"
                  alt="claim"
                  loading="lazy"
                  // style={{ verticalAlign: "center" }}
                />
              </div>
            </div>
          </div>
          {/* <div className="modal-footer">
     
        <button
          type="button"
          className="btn btn-outline-secondary save-cover-btn ms-3"
          data-bs-dismiss="modal"
        >
          بستن
        </button>
      </div> */}
        </div>}
      </div>
    </div>
  );
}
