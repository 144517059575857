import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, StaticTimePicker ,DatePicker} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import addErrClass from '../persian/common/alerts/addErrClass';

// function DatePickerRender1({ formik ,customPath }) {  
    
//     function changeHandle (newTime){
//       console.log("changeHandle");
//       const date = dayjs(newTime).toDate()
//       const date2 = new Date(date)
//       formik.setValues(pre=>({
//         ...pre,
//         dates:{...pre.dates,[field]:date2}
//       }))
      
//     };
   

//   return (  
     
//     <LocalizationProvider dateAdapter={AdapterDayjs}>

//     <DatePicker 
//         value={dayjs(formik.values.dates?.[field])}
//     onChange={changeHandle}
//     className={addErrClass({formik,field:`dates.${field}`})}
//     />
//   </LocalizationProvider>
    
  
//   );
// }

export default function DatePickerRender({ formik, customPath }) {
  const deepGet = (obj, path) => {
    const keys = path?.split('.');
    return keys?.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
  };

  const deepSet = (obj, path, value) => {
    const keys = path?.split('.');
    const lastKey = keys.pop();
    const deepObj = keys?.reduce((acc, key) => (acc[key] = acc[key] || {}), obj);
    deepObj[lastKey] = value;
    return obj;
  };

  function changeHandle(newTime) {
    console.log("changeHandle");
    const date = dayjs(newTime).toDate();
    const date2 = new Date(date);

    formik.setValues(prevValues => {
      const updatedValues = { ...prevValues };
      deepSet(updatedValues, customPath, date2);
      return updatedValues;
    });
  }

  const value = dayjs(deepGet(formik.values, customPath));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        onChange={changeHandle}
        className={addErrClass({ formik, field: customPath })}
      />
    </LocalizationProvider>
  );
}


