import { useContext, useEffect, useRef, useState } from "react";
import { CartContext } from "../contexts/cartContext";
import { useNavigate } from "react-router-dom";
import ToastNote from "../golbal/notifications/toastNote";
import apiStore from "../../utils/apiStore";
import ColoredSpiner from "../alret/spiners/coloredSpiner/coloredSpinner";
import api from "../../utils/api";

export default function Paypal({ order, setOrder }) {
  const navigate = useNavigate();
  const { user, signedUser } = useContext(CartContext);
  const [loading, setLoading] = useState({ paypal: false });
  const messageRef = useRef(null);
  const effectRan = useRef(false);
  console.log(order);

  useEffect(() => {
    if (effectRan.current) return;
    addPaypalSdk();
    effectRan.current = true;
  }, []);

  async function addPaypalSdk() {
    try {
      setLoading((pre) => ({ ...pre, paypal: true }));
      const response = await apiStore.getPaypalClientId({ token: user.token });
      setLoading((pre) => ({ ...pre, paypal: false }));
      console.log(response);

      if (response.error) {
        console.log(response.error);
        messageRef.current.showError(
          <>
            <div>مشکل در انجام عملیات!</div>
            <div>{response.error}</div>
          </>
        );
        return;
      }
      const amount =
        Math.round((order?.newPrice?.totalPrice ?? order.totalPrice) * 100) /
        100;
      if (!window.paypal) {
        const currency = order?.newPrice?.currency ?? order?.currency;

        console.log(currency, amount);
        // console.log("!window.paypal");
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `https://www.paypal.com/sdk/js?client-id=${response.data.clientId}&currency=${currency}`;
        script.async = true;
        script.onload = async () => {
          setLoading((pre) => ({ ...pre, paypal: true }));
          await handlePayment(response.data.clientId, amount);
          setLoading((pre) => ({ ...pre, paypal: false }));
        };
        document.body.appendChild(script);
      } else {
        // console.log("window.paypal");
        handlePayment(response.data.clientId, amount);
      }
    } catch (error) {
      messageRef.current.showError({
        message: "Error while proccesing the paypal payment, please try again.",
      });
      console.error(error);
    }

    function handlePayment(clientId, totalPrice) {
      console.log(totalPrice);
      window.paypal
        .Buttons({
          // PayPal button configuration options
          locale: "en_CA",
          style: {
            size: "responsive",
            color: "gold",
            shape: "pill",
            label: "checkout",
          },
          // commit:true,
          createOrder: function (data, actions) {
            // This function sets up the details of the transaction, including the amount and line item details.
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    // currency: "CAD",
                    // currency_code:"CAD",
                    value: totalPrice,
                  },

                  description: `Order Id :${order._id} , total : ${
                    order.totalPrice
                  } , currency: ${
                    order?.newPrice?.currency ?? order?.currency
                  }  `,
                },
              ],
            });
          },
          onApprove(data, actions) {
            console.log("onApprove Data", data);
            return actions.order.capture().then(async (details) => {
              console.log("details", details);
              setLoading((pre) => ({ ...pre, paypal: true }));
              //its need to have Order Id before Finalized the oreder
              const response = await apiStore.payOrder({
                id: order._id,
                token: user.token,
                paymentResult: {
                  orderID: data.orderID,
                  payerID: data.payerID,
                  paymentID: data.paymentID,
                  paymentSource: data.paymentSource,
                  payerName: details.payer.name.given_name,
                  payerFamily: details.payer.name.surname,
                  payerEmail: details.payer.email_address,
                  status: details.status,
                  amount: details.purchase_units[0].amount,
                  shipping: details.purchase_units[0].shipping.address,
                  paidAT: details.update_time,
                  descripion: details.purchase_units[0].descripion,
                },
              });
              setLoading((pre) => ({ ...pre, paypal: false }));
              messageRef.current.showSuccess({
                message:
                  "پرداخت با موفقیت انجام شد." + details.payer.name.given_name,
              });
              setOrder(response.data);
              if (order.walletInfo?.isActive) {
                const response2 = await api.getUser();
                if (response2?.error) return;
                signedUser(response2.data);
              }
              navigate(`/${window.lang}/store/order/thanks`, { replace: true });
            });
          },
        })
        .render("#paypal-button")
        .then(() => {
          setLoading((prevLoading) => ({ ...prevLoading, paypal: false }));
        });
    }
  }
  // JSX rendering
  return (
    <div>
      {loading.paypal && <ColoredSpiner />}
      <ToastNote messageRef={messageRef} />
      {/* Your component JSX */}
      {/* <div ref={messageRef}></div> */}
      <div id="paypal-button"></div>
    </div>
  );
}
