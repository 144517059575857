import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { CartContext } from "../../../../contexts/cartContext";
import tools from "../../../../../utils/tools";

export default function UserDashMpNav() {
  const showEdit = window.location.pathname.split("/").slice(7, 8).toString();
  const {user}=useContext(CartContext)
  const trans={   
    add:{fa:"افزودن کی‌چی‌کجا",en:"Add"},
    myList:{fa:"کی‌چی‌کجای من",en:"My List"},
    edit:{fa:"ویرایش",en:"Edit"},
   
  }
 
  return (
    <div className="dashboardNavigator d-flex">
      <NavLink
        to={`/${window.lang}/dashboard/user/mp/usedregister`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >{trans.add[window.lang]}
        
      </NavLink>
      {tools.compareCheck( user?.roles,[1000,1999,1900])&&<NavLink
        to={`/${window.lang}/dashboard/user/mp/usedlist`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >{trans.myList[window.lang]}
     
      </NavLink>}
      {showEdit === "usededit" && (
        <NavLink
          to={`/${window.lang}/dashboard/user/mp/usededit`}
          className={`sidebarListItem m-1 link  ${({ isActive }) => {
            return isActive && "active";
          }}`}
        >{trans.edit[window.lang]}          
        </NavLink>
      )}
    </div>
  );
}
