import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useRef, useState } from "react";
import { CheckCircle, Clear, Error, WarningAmber } from "@mui/icons-material";
import { isValidPhoneNumber } from "react-phone-number-input";
import { CircularProgress } from "@mui/material";
import { CartContext } from "../../../contexts/cartContext";
// import api from "../../../../utils/api";
import ToastNote from "../../../golbal/notifications/toastNote";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import AddSocialMedia from "../../../persian/addSocialMedia";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import RegisterAddressPlaces from "../../business/register/registerAddressPlaces";
import MyIcons from "../../../golbal/icons/myIcons";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import apiBusiness from "../../../../utils/apiBusiness";
import renderData from "../../../../utils/renderData";
import apiJobs from "../../../../utils/apiJobs";
import FormikErrRender from "../../../persian/common/alerts/formikErrRender";
import DatePickerRender from "../../../golbal/datePicker";
import addErrClass from "../../../persian/common/alerts/addErrClass";
import api from "../../../../utils/api";
import tools from "../../../../utils/tools";
import CatsSelector from "../../../golbal/catsSelector";
import MetaDecorator from "../../../../utils/metaDecorator";
import FormikListErr from "../../../persian/common/alerts/formikListErr";
// import DatePicker from "../../../golbal/datePicker";

export default function JobsRegister() {
  const { lang, state:{state,stateShort,lat,lng}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
  const { translations,name,currency } =
    JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const messageRef = useRef(null);
  const navigate = useNavigate();
  const { user, signedUser } = useContext(CartContext);
  const [loading, setLoading] = useState({ spinner: false });
  const fetchedRef = useRef(null);
  // const copyData = useLocation().state
  // console.log("copyData",copyData);
  const [existBus, setExistBus] = useState([]);
  const [searchParams, setSearchPatams] = useSearchParams();
  const action = searchParams.get("action") ?? "register";
  const { id } = useParams();

  const urlRegex =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

  const formik = useFormik({
    initialValues: {
      action,
      busId: "",
      logo: "",
      businessCard: "",
      busName: "",
      _id: "",
      lang,
      email: "",
      emType: ["fullTime"],
      workShifts: [],
      title: "",
      description: "",
      experience: "noExperience",
      expertise: "",
      benefits: "",
      studyField: "",
      languages: ["fa"],
      phone: "",
      moreContact: "",
      terms: false,
      process: "month",
      tags: `فرصت های شغلس در ${translations[window.lang]||country}+پیدا کردن شغل در${translations[window.lang]||country}+استخدام در ${translations[window.lang]||country} +بزرگترین سایت استخدام در ${translations[window.lang]||country}+`,

      socialMedia: [{ platform: "", url: "" }],
      // files: { cv: "", cover: "", sop: "" },
      salary: {
        period: "hourly",
        min: 0,
        max: 0,
        currency: currency,
      },
      location: {
        country,
        countryShort,
        state,
        stateShort,
        city: "",
      },
      dates: { start: "", end: "" },
      category: {
        mainCat: {},
        subCat: {},
      },
    },
    onSubmit: async (values) => {
      if (values?.action === "edit")
        return editHandle({ messageRef, setLoading, user, values, navigate });
      if (!values.busId.length)
        return messageRef.current.showError({
          message: " کسب و کار خود را انتخاب کنید.",
        });
      console.log("passed Formik", values);
      setLoading((pre) => ({ ...pre, spinner: true }));
      const response = await apiJobs.register({ token: user.token, values });
      setLoading((pre) => ({ ...pre, spinner: false }));
      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      messageRef.current.showSuccess({
        message: "تبریک، فرصت شغلی با موفقیت ثبت شد.",
      });

      const updateduser = await api.getUser();
      if (updateduser.error) return;
      signedUser(updateduser.data);
      window.scrollTo({ top: "0", behavior: "smooth" });    
      navigate(`/${window.lang}/jobs/page/${tools.slugMaker({title:response?.data?.moreInfo?.title,city:response?.data?.moreInfo?.address?.city,state:response?.data?.moreInfo?.address?.state,serial:response?.data?.serial})}`, {
        state: response.data,
      });
    },
    validationSchema: Yup.object({
      terms: Yup.boolean().oneOf(
        [true],
        " قوانین را مطالعه و دکمه قبول را فشار دهید."
      ),
      title: Yup.string()
        .required("لطفاً عنوان شغل را وارد کنید.")
        .max(200, "عنوان شغل حداکثر باید 200 حرف باشد .")
        .min(5, "عنوان شغل حداقل باید 5 حرف باشد."),
      description: Yup.string()
        .required("لطفاً وظایف شغلی را وارد کنید.")
        .max(2000, "وظایف شغلی حداکثر باید 2000 حرف باشد .")
        .min(20, "وظایف شغلی حداقل باید 20 حرف باشد."),
      phone: Yup.string()
        .trim()
        .when("suggestMode", (suggestMode, schema, value) => {
          return (
            value.parent.phone?.length &&
            schema.test(
              "is-valid-phone",
              "شماره تلفن نامعتبر میباشد.",
              (value) => {
                return isValidPhoneNumber(value || "");
              }
            )
          );
        }),
      // ... your other validation rules for phone

      email: Yup.string()
        .trim()
        .lowercase()
        .required("ایمیل را وارد کنید.")
        .email("ایمیل را به درستی وارد کنید."),
      emType: Yup.array()
        .required(" دست کم یک نوع از همکاری را انتخاب کنید.")
        .test(
          "array-length",
          " دست کم یک نوع از همکاری را انتخاب کنید.",
          (value) => value.length > 0
        ),
      languages: Yup.array()
        .required(" دست کم یک زبان را انتخاب کنید.")
        .test(
          "array-length",
          " دست کم یک زبان را انتخاب کنید.",
          (value) => value.length > 0
        ),

      location: Yup.object().shape({
        country: Yup.string().required("کشور نمیتواند خالی باشد."),
        state: Yup.string().required("استان نمیتواند خالی باشد."),
        city: Yup.string().required("شهر نمیتواند خالی باشد."),
        municipality: Yup.string(),
      }),
      socialMedia: Yup.array().of(
        Yup.object().shape({
          platform: Yup.string(),

          url: Yup.string()
            .when("platform", (platform, schema) => {
              return platform
                ? schema.required("لطفاً آدرس URL را وارد نمایید.")
                : schema;
            })
            .test("validUrl", " آدرس را به شکل درست وارد کنید.", (value) => {
              if (!value) return true; // Allow empty value
              // Regular expression to validate URL
              return urlRegex.test(value);
            }),
        })
      ),
      category: Yup.object().shape({
        mainCat: Yup.object().shape({
          _id: Yup.string().required(" دسته‌بندی را انتخاب کنید."),
        }),
        subCat: Yup.object().shape({
          _id: Yup.string().required(" دسته بندی کوچکتر را انتخاب کنید."),
        }),
      }),
      dates: Yup.object().shape({
        start: Yup.date()
          .required(" تاریخ شروع را وارد کنید.")
          .typeError("تاریخ شروع نامعتبر می باشد.")
          .min(new Date(new Date().setHours(0, 0, 0, 0)), "تاریخ شروع نمی تواند در گذشته باشد."),
        end: Yup.date()
          .required(" تاریخ پایان را وارد کنید.")
          .typeError("تاریخ پایان نامعتبر می باشد.")
          .min(
            Yup.ref("start"),
            " تاریخ پایان را بعد از تاریخ شروع انتخاب کنید !"
          )
          .test(
            "within-two-months",
            "تاریخ پایان نمی تواند بیشتر از سه از تاریخ شروع باشد.",
            function (value) {
              const today = new Date();
              const twoMonthsLater = new Date();
              twoMonthsLater.setMonth(today.getMonth() + 3);
              return value <= twoMonthsLater;
            }
          ),
      }),
      salary: Yup.object().shape({
        min: Yup.number()
          .required(" حداقل حقوق را وارد کنید.")
          .min(1, "لطفا حداقل دستمزد را وارد نمائید."),
        period: Yup.string().required(" دوره را انتخاب کنید."),
      }),
    }),
  });

  useEffect(() => {
    fetchJobsDetails();
  }, [id]);

  useEffect(() => {
    fetchExistBus();
  }, []);

  async function fetchExistBus() {
    if (fetchedRef.current) return;
    if (!user.token) return;
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await apiBusiness.checkExistBus({
      userId: user._id,      
      token: user.token,
      values: [countryShort,stateShort],
      lang:window.lang
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    fetchedRef.current = true;
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    // if (response.data.length === 0)
    // messageRef.current.showError({
    //   message: `مالکیت هیچ کسب و کاری در ${countryDetails.name} و در حساب کاربری شما پیدا نشد. `,
    // });
    setExistBus(response.data);

    if (!id) {
      formik.setValues((pre) => {
        const bus = response.data[0];
        const pre2 = { ...pre };
        pre2.busName = bus?.busName || {};
        pre2.logo = bus?.logo;
        pre2.businessCard = bus?.businessCard;
        pre2.busId = bus?._id;
        return pre2;
      });
    }
  }

  async function fetchJobsDetails() {
    console.log(id);
    if (!id) return;
    const response = await apiJobs.jobDetails(id);
    if (response.error) return alert(response.error);
    // console.log(response.data);
    editInitialData(formik, response.data, action);
  }

  // console.log("formik", formik.values);
  // console.log("formik", formik.errors);
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "ثبت رایگان آگهی استخدام",
      url: `https://www.metamarce.com/${window.lang}/jobs/register`,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/mp`,
              name: ` نیازمندیها`,
              description: `نیازمندیهای ایرانیان در سراسر دنیا`,
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/jobs/register`,
              name: "ثبت رایگان آگهی استخدام",
              description: "ثبت آسان، انتشار سریع و خودکار آگهی استخدام",
            },
          },
        ],
      },
    },
  ];

  const metaTagsData = {
    title: "ثبت فرصت شغلی",
    description:
      " ثبت فرصت های شغلی و استخدام در متامارس فرصتی برای رشد کسب و کار و فردی",
    section: "job register",
    url: `https://www.metamarce.com/${window.lang}/jobs/register`,
    canonical: `https://www.metamarce.com/${window.lang}/jobs/register`,
    img: "https://www.metamarce.com/img/jobs/Hiring-rafiki.svg",
    type: "jobPosting",
  };

  return (
    <div className="user  mt-3  px-0">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      {loading.spinner && <ColoredSpiner />}
      <ToastNote messageRef={messageRef} />
      <div className=" mx-0 row align-items-center" style={{ width: "100%" }}>
        <div
          className="col "
          style={{
            backgroundColor: "var(--brownOrange)",
            color: "rgb(48, 23, 23)",
            width: "100%",
            height: "140px",
          }}
        >
          <div
            className="h2 text-center"
            style={{ marginTop: "6rem", color: "var(--beige)" }}
          >
            افزودن آگهی همکاری برای مالکین کسب وکار
          </div>
        </div>
      </div>

      {/* <!-- This is htmlFor curve! --> */}

      <div className="business-top-curve">
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: "100%", width: "100%" }}
        >
          <path
            d="M-2.82,59.70 C211.62,278.78 237.58,-55.75 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
            style={{ stroke: "none", fill: "var(--brownOrange)" }}
          ></path>
        </svg>
      </div>

      {!user?.roles?.includes(9999) ? (
        <div className="container-404 text-center d-flex flex-column align-items-center px-4 ">
          <div className="my-3 ">
            <div> در گام نخست شما نیاز دارید تا وارد حساب کاربری شوید.</div>

            <Link
              id="loginTrigger"
              className="btn primary-btn btn-404 checkout-btn my-5 mt-3"
              data-bs-toggle="modal"
              to="#exampleModalToggle"
              role="button"
            >
              ورود به حساب کاربری
            </Link>
            <img
              className=" img-404 w-75 "
              src="/img/illustration/access-denied.svg"
              alt="404"
            />
          </div>
        </div>
      ) : tools.compareCheck(user.roles, [1000, 1299]) &&
        existBus.length > 0 ? (
        <form
          onSubmit={formik.handleSubmit}
          className="row ads-all mx-auto my-5 px-mx-5"
          name="jobRegForm00"
          autoComplete="true"
        >
          <div>
            <p className="divider2 border-0 mt-3 ">
               <MyIcons icon="dotsTitr" />
              <span className="p-0 m-z "> اطلاعات کارفرما</span>
            </p>

            {formik.values.action === "copy" && (
              <div
                className="userShow py-5 px-md-5"
                style={{ borderColor: "green" }}
              >
                <strong className="  mx-me-5">
                  این یک نمونه کپی شده می باشد...
                </strong>
              </div>
            )}
            {formik.values.action === "edit" && (
              <div
                className="userShow py-5 px-md-5"
                style={{ borderColor: "green" }}
              >
                <strong className=" mx-md-5">
                  شما در حالت ویرایش یک فرصت شغلی می باشید...
                </strong>
              </div>
            )}
            <div className="userShow py-5 px-md-5">
              <div className="  mx-md-5">
                <div id="existingBus">
                  <div className=""></div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={(e) =>
                      existBusinesses({
                        e,
                        setExistBus,
                        user,
                        fetchedRef,
                        setLoading,
                      })
                    }
                  >
                    <div className="d-flex">
                      <p className="text-center m-0 p-0">
                        {formik?.values?.busName?.[window.lang]?.length
                          ? "کسب و کار انتخابی"
                          : "این آگهی در ارتباط با "}
                      </p>
                      <div className="mx-md-5 titr5">
                        {formik.values.busName?.[window.lang]?.length > 0 &&
                          `"${formik.values.busName?.[window.lang]}"`}
                      </div>
                      <div className="ms-auto">
                        <img
                          className="jobCrad-img"
                          src={formik.values.businessCard}
                          alt={formik.values.busName?.[window.lang]}
                          loading="lazy"
                        />
                      </div>
                    </div>

                    {loading.existBus && (
                      <CircularProgress color="inherit" size="20px" />
                    )}
                  </div>
                </div>
                {existBus.length > 0 && (
                  <div
                    id="existingBusShowHide"
                    className="BusRegList p-1 mx-auto w-100 ads-all"
                    style={{ display: "none" }}
                  >
                    <span
                      title="بستن"
                      onClick={(e) => {
                        document.querySelector(
                          "#existingBusShowHide"
                        ).style.display = "none";
                      }}
                    >
                      <Clear sx={{ color: "red" }} />
                    </span>
                    {existBus.map((bus) => (
                      <ExistBusRender bus={bus} formik={formik} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <p className="divider2 border-0 mt-3 ">
                 <MyIcons icon="dotsTitr" />
              <span className="p-0 m-z ">جزییات شغل پیشنهادی</span>
            </p>

            <div className="userShow px-5">
              <div className="row">
                <div className="col px-3">
                  <div className="mb-5 ">
                    <label
                      className="form-label mt-3 titr6 mb-0 required"
                      htmlFor="f1"
                    >
                      عنوان شغل
                      <Tooltip1
                        message="این عنوان در لیست مشاغل نمایش داده خواهد شد."
                        position="top-end"
                      />
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className={`form-control py-1 box2 ${addErrClass({
                        formik,
                        field: "title",
                      })} `}
                      id="f1"
                      placeholder=""
                      name="busName"
                      value=""
                      {...formik.getFieldProps("title")}
                    />
                    <FormikErrRender formik={formik} field="title" />
                  </div>
                  <CatsSelector formik={formik} section={"jobs"} />

                  <div className=" p-2">
                    <p className="form-label titr6"> نوع همکاری</p>
                    <div
                      className={`d-flex flex-wrap ${addErrClass({
                        formik,
                        field: "emType",
                      })} `}
                    >
                      {renderData.emType.map((type, i) => (
                        <div key={i} className="form-check mx-3 my-2">
                          <input
                            className="form-check-input px-1"
                            type="checkbox"
                            id={"emType" + type.name}
                            checked={formik.values?.emType?.includes(type.name)}
                            value={type.name}
                            onChange={(e) =>
                              formik.setValues((pre) => {
                                const emType = [...pre.emType];
                                const valueIndex = emType.indexOf(type.name);
                                if (valueIndex !== -1) {
                                  emType.splice(valueIndex, 1);
                                } else {
                                  emType.push(type.name);
                                }
                                return { ...pre, emType };
                              })
                            }
                          />
                          <label
                            className="form-check-label "
                            htmlFor={"emType" + type.name}
                          >
                            {type[lang]}
                          </label>
                        </div>
                      ))}
                    </div>
                    <FormikErrRender formik={formik} field="emType" />
                  </div>

                  <div className=" p-2 mt-5">
                    <p className="form-label titr6"> زبان‌(های) مورد نیاز </p>
                    <div className="form-check ">
                      {/* <div
                        id="panelsStayOpenBusinessFilters-collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpenBusinessFilters-headingOne"
                      > */}
                      <div
                        className={` form-font row row-cols-3 row-cols-md-4 row-cols-lg-5 g-2 ${addErrClass(
                          { formik, field: "languages" }
                        )} `}
                      >
                        {renderData.languages.map((lang, i) =>
                          !lang[window.lang].length ? (
                            ""
                          ) : (
                            <div key={i} className="morelang-line my-0 ">
                              <input
                                className="form-check-input  ms-5 me-1 red moreLang"
                                type="checkbox"
                                value={lang.name}
                                checked={formik.values.languages?.includes(
                                  lang.name
                                )}
                                id={`flexLanguages${i}`}
                                onChange={(e) => {
                                  const languages =
                                    [...formik.values.languages] || [];
                                  const index = languages.indexOf(lang.name);
                                  if (index !== -1) {
                                    languages.splice(index, 1);
                                  } else {
                                    languages.push(e.target.value);
                                  }

                                  formik.setValues((pre) => ({
                                    ...pre,
                                    languages,
                                  }));
                                }}
                              />
                              <label
                                className="form-check-label ps-2 red moreLang"
                                htmlFor={`flexLanguages${i}`}
                              >
                                {lang[window.lang]}
                              </label>
                            </div>
                          )
                        )}
                      </div>
                      <button
                        className="btn btn-click ms-5 mt-2 mb-5"
                        type="button"
                      >
                        <label htmlFor="moreLangCheckbox"> بیشتر... </label>
                        {/* <span>
                              <i className="bi bi-three-dots ps-2 py-0 my-0"></i>
                            </span> */}
                        <input
                          id="moreLangCheckbox"
                          type="checkbox"
                          onClick={(e) => {
                            const elemet =
                              document.querySelectorAll(".moreLang");
                            if (e.target.checked) {
                              document.querySelector(
                                "label[for=moreLangCheckbox]"
                              ).innerHTML = "کمتر...";
                              Array.from(elemet).map(
                                (el) => (el.style.display = "inline-block")
                              );
                            } else {
                              document.querySelector(
                                "label[for=moreLangCheckbox]"
                              ).innerHTML = "بیشتر...";
                              Array.from(elemet).map(
                                (el) => (el.style.display = "none")
                              );
                            }
                          }}
                        />
                      </button>
                      <FormikErrRender formik={formik} field="languages" />
                      {/* </div> */}
                    </div>
                  </div>

                  <div className=" p-2 mb-5">
                    <p className="form-label titr6">سابقه کار </p>
                    <div className="form-check  ">
                      <div className="d-flex flex-wrap">
                        {renderData.jobExperience.map((JobExp) => (
                          <div className="">
                            <input
                              className="form-check-input ms-5 me-1"
                              type="radio"
                              id={"jobExp-" + JobExp.name}
                              value={JobExp.name}
                              checked={formik.values.experience === JobExp.name}
                              name="jobExp-radio"
                              onChange={(e) =>
                                formik.setValues((pre) => ({
                                  ...pre,
                                  experience: e.target.value,
                                }))
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={"jobExp-" + JobExp.name}
                            >
                              {JobExp[lang]}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className=" mb-5 p-2">
                    <p className="form-label titr6">حقوق پیشنهادی</p>
                    <div className="d-flex">
                      <div className=" mx-2">
                        <input
                          type="text"
                          autoComplete="off"
                          aria-label="Amount (to the nearest dollar)"
                          className={`form-control ${addErrClass({
                            formik,
                            field: "salary.min",
                          })}`}
                          placeholder="Min"
                          {...formik.getFieldProps("salary.min")}
                        />
                      </div>

                      <p className="mt-2 mx-2">تا</p>
                      <div className=" mx-2">
                        <input
                          type="text"
                          autoComplete="off"
                          aria-label="Amount"
                          className="form-control"
                          placeholder="Max"
                          {...formik.getFieldProps("salary.max")}
                        />
                      </div>
                      <select
                        className="login-btn my-2"
                        name="salaryCurrency"
                        id=""
                        {...formik.getFieldProps("salary.currency")}
                      >
                        <option value={formik.values.salary.currency}>
                          {formik.values.salary.currency}
                        </option>
                        <option value="CAD">CAD</option>
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                        <option value="AUD">AUD</option>
                        <option value="GBP">GBP</option>
                        <option value="تومان">تومان</option>
                      </select>
                    </div>
                    <FormikErrRender formik={formik} field="salary.min" />
                    <div
                      className={`px-3 d-flex ${addErrClass({
                        formik,
                        field: "salary.period",
                      })}`}
                    >
                      {renderData.jobPeriod.map((period) => (
                        <div className="form-check form-check-px1 m-2">
                          <input
                            defaultChecked={formik.values.salary.period?.includes(
                              period.name
                            )}
                            className="form-check-input"
                            type="radio"
                            name="jobPeriod"
                            id={"jobPeriod" + period.name}
                            value={period.name}
                            onChange={(e) =>
                              formik.setValues((pre) => ({
                                ...pre,
                                salary: {
                                  ...pre.salary,
                                  period: e.target.value,
                                },
                              }))
                            }
                          />
                          <label
                            className="form-check-label d-flex"
                            htmlFor={"jobPeriod" + period.name}
                          >
                            {period[lang]}
                          </label>
                        </div>
                      ))}
                      <FormikErrRender formik={formik} field="salary.period" />
                    </div>
                  </div>
                  <div className=" mb-5 p-2">
                    <div className=" p-2">
                      <p className="form-label titr6"> روز و ساعت کاری</p>
                      <div
                        className={`d-flex flex-wrap ${addErrClass({
                          formik,
                          field: "workShifts",
                        })} `}
                      >
                        {renderData.workShifts.map((type, i) => (
                          <div key={i} className="form-check mx-3 my-2">
                            <input
                              className="form-check-input px-1"
                              type="checkbox"
                              id={"workShifts" + type.name}
                              checked={formik.values?.workShifts?.includes(
                                type.name
                              )}
                              value={type.name}
                              onChange={(e) =>
                                formik.setValues((pre) => {
                                  const workShifts = [...pre.workShifts];
                                  const valueIndex = workShifts.indexOf(
                                    type.name
                                  );
                                  if (valueIndex !== -1) {
                                    workShifts.splice(valueIndex, 1);
                                  } else {
                                    workShifts.push(type.name);
                                  }
                                  return { ...pre, workShifts };
                                })
                              }
                            />
                            <label
                              className="form-check-label "
                              htmlFor={"workShifts" + type.name}
                            >
                              {type[lang]}
                            </label>
                          </div>
                        ))}
                      </div>
                      <FormikErrRender formik={formik} field="workShifts" />
                    </div>
                  </div>
                  <div className="mb-5">
                    <label className="form-label titr6">
                      وظایف شغلی
                      <Tooltip1
                        message="وظایف شغلی مرتبط با این فرصت شغلی را اینجا وارد کنید."
                        position="top-end"
                      />
                    </label>
                    <textarea
                      className={`form-control ${addErrClass({
                        formik,
                        field: "description",
                      })}`}
                      rows="3"
                      name="description"
                      defaultValue={formik.values.description}
                      onChange={(e) => {
                        e.target.style.height = "auto";
                        e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      onBlur={(e) =>
                        formik.setValues((pre) => ({
                          ...pre,
                          description: e.target.value,
                        }))
                      }
                    ></textarea>
                    <FormikErrRender formik={formik} field="description" />
                  </div>

                  <div className="mb-5">
                    <label className="form-label titr6">
                      زمینه تحصیلی
                      <Tooltip1
                        message="زمینه تحصیلی مرتبط با این فرصت شغلی را اینجا وارد کنید."
                        position="top-end"
                      />
                    </label>
                    <textarea
                      className="form-control"
                      rows="3"
                      name="description"
                      defaultValue={formik.values.studyField}
                      onChange={(e) => {
                        e.target.style.height = "auto";
                        e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      onBlur={(e) =>
                        formik.setValues((pre) => ({
                          ...pre,
                          studyField: e.target.value,
                        }))
                      }
                    ></textarea>
                  </div>
                  <div className="mb-5">
                    <label
                      className="form-label titr6"
                      htmlFor="expertiesTextArea"
                    >
                      مهارت‌‌های مورد نیاز
                      <Tooltip1
                        message=" مهارت‌‌های مورد نیاز مرتبط با این فرصت شغلی را اینجا وارد کنید."
                        position="top-end"
                      />
                    </label>
                    <textarea
                      className="form-control"
                      rows="3"
                      name="expertise"
                      id="expertiesTextArea"
                      defaultValue={formik.values.expertise}
                      onChange={(e) => {
                        e.target.style.height = "auto";
                        e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      onBlur={(e) =>
                        formik.setValues((pre) => ({
                          ...pre,
                          expertise: e.target.value,
                        }))
                      }
                    ></textarea>
                  </div>
                  <div className="mb-5">
                    <label
                      className="form-label titr6"
                      htmlFor="benefitsTextArea"
                    >
                      تسهیلات و مزایا
                      <Tooltip1
                        message=" تسهیلات و مزایا مرتبط با این فرصت شغلی را اینجا وارد کنید."
                        position="top-end"
                      />
                    </label>
                    <textarea
                      className="form-control"
                      rows="3"
                      name="benefits"
                      id="benefitsTextArea"
                      defaultValue={formik.values.benefits}
                      onChange={(e) => {
                        e.target.style.height = "auto";
                        e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      onBlur={(e) =>
                        formik.setValues((pre) => ({
                          ...pre,
                          benefits: e.target.value,
                        }))
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="divider2 border-0 mt-3">
                 <MyIcons icon="dotsTitr" />
              <span className="p-0 m-z "> راههای تماس و فرستادن رزومه</span>
            </p>

            <div className="userShow py-5 px-md-5">
              {/* <p> اطلاعات تماسی که مایلید در آگهی نمایش داده شوند را مشخص کنید.</p> */}

              <label
                className="form-label mt-5 titr6 mb-0 required"
                htmlFor="emailApply"
              >
                ایمیل
              </label>
              <input
                dir="ltr"
                id="emailApply"
                {...formik.getFieldProps("email")}
                className={`userUpdateInput  form-control box2 ${addErrClass({
                  formik,
                  field: "email",
                })}`}
                type="text"
                autoComplete="off"
                placeholder={formik.values.email}
              />
              <FormikErrRender formik={formik} field="email" />
              <label
                className="form-label mt-5 titr6 mb-0 "
                htmlFor="phoneApply"
              >
                شماره تماس
              </label>
              <input
                dir="ltr"
                id="phoneApply"
                {...formik.getFieldProps("phone")}
                className={`userUpdateInput  form-control box2 ${addErrClass({
                  formik,
                  field: "phone",
                })} `}
                type="text"
                autoComplete="off"
                placeholder={formik.values.phone}
              />
              <FormikErrRender formik={formik} field="phone" />
              <div className="form-label mt-5 titr6 mb-0  border-0 ">
                شبکه‌های اجتماعی
              </div>

              <div className=" border-0 mb-0 px-5">
                <AddSocialMedia formik={formik} />
              </div>
              <label
                className="form-label my-0 titr6  "
                htmlFor="moreContactApply"
              >
                دیگر راه‌ها
              </label>
              <input
                id="moreContactApply"
                {...formik.getFieldProps("moreContact")}
                className="userUpdateInput  form-control box2"
                type="text"
                autoComplete="off"
                placeholder={formik.values.email}
              />
            </div>
          </div>
          <div>
            <div className="userShow py-5 px-md-5 ">
              <p className="divider2 border-0 mt-3 ">
                 <MyIcons icon="dotsTitr" />
                <span className="p-0 m-z "> نشانی محل کار </span>
              </p>
              <RegisterAddressPlaces formik={formik} section="jobs" />
            </div>
          </div>

          <div>
            <p className="divider2 border-0 mt-3 ">
               <MyIcons icon="dotsTitr" />
              <span className="p-0 m-z "> امکانات تکمیلی آگهی</span>
            </p>

            <div className="userShow py-5 px-md-5">
              <div className="row g-5 mb-3">
                <div className=" col-md-4 ">
                  <p className="form-label titr6 ">پروسه استخدام </p>

                  {renderData.process.map((pro, i) => (
                    <div
                      key={i}
                      className="form-check form-switch m-0 py-0 px-1"
                    >
                      <input
                        className="form-check-input form-check-input-filter1 m-0 p-0"
                        type="checkbox"
                        role="switch"
                        name="process"
                        value={pro.name}
                        id={`process,${i}`}
                        checked={formik.values.process === pro.name}
                        onChange={(e) =>
                          formik.setValues((pre) => ({
                            ...pre,
                            process: e.target.value,
                          }))
                        }
                      />
                      <label
                        className="form-check-label mx-3"
                        htmlFor={`process,${i}`}
                      >
                        {pro?.[window.lang]}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="col-md-8 ">
                  {/* <div className="form-div titr6">
                  تاریخ شروع و پایان
                  <Tooltip1
                    message=" نام کسب و کار خود را اینجا وارد کنید!  (مثال: آموزشکاه دانا)"
                    position="top-end"
                  />
                </div> */}
                  <div className="row g-3 titr6">
                    <div className="col-md-6">
                      <p>تاریخ شروع آگهی</p>
                      <DatePickerRender formik={formik} customPath={"dates.start"} />

                      <FormikErrRender formik={formik} field="dates.start" />
                    </div>
                    <div className="col-md-6">
                      <p> تاریخ پایان آگهی</p>

                      <DatePickerRender formik={formik} customPath={"dates.end"} />

                      <FormikErrRender formik={formik} field="dates.end" />
                    </div>
                  </div>
                </div>
              </div>
              {/* افزودن دکمه فرستادن رزومه */}
              <p id="tagsInput" className="mt-5 form-titr ">
                <span>
                  <i className="bi bi-circle-fill me-1"></i>
                </span>
                برچسب‌ها (با "+" جدا شوند )
                <Tooltip1
                  message={
                    <div>
                      <div>
                        برچسب‌ها - تگ - را توسط '+' (بدون فاصله)از یکدیگر
                        جداکنید.
                      </div>
                      <div>حداکثر ۱۰ برچسب.</div>
                    </div>
                  }
                />
                <input
                  type="text"
                  autoComplete="off"
                  name="other"
                  placeholder={
                    formik?.values?.tags ||
                    `برای مثال : بازسازی+طراحی داخلی+ طراحی فضای سبز`
                  }
                  className="form-input1 mt-3"
                  {...formik.getFieldProps("tags")}
                />
              </p>
            </div>

            <div className="mt-5 ">
              <span
                className="fw-bold ps-3 cursor required"
                htmlFor="termsConditions"
                onClick={(e) => {
                  const el = document.querySelector("#termsContent");
                  el.style.display =
                    el.style.display === "none" ? "block" : "none";
                }}
              >
                ( قوانین)
              </span>
              <div
                id="termsContent"
                style={{ display: "none" }}
                className="userUpdateInput form-control userUpdateInputTextarea"
              >
                English Version in following
                <br />
                <br />
                <div>
                  شرایط و مقررات انتشار مشاغل متاجابز این شرایط و مقررات استفاده
                  شما به عنوان کارفرما از خدمات انتشار فرصت های شغلی را تنظیم
                  می‌کند. با دسترسی یا استفاده از خدمات ما به عنوان یک کارفرما،
                  شما موافقت خود را با این شرایط اعلام و تایید می‌کنید. اگر با
                  این شرایط مخالفت دارید، لطفاً از استفاده از خدمات ما به عنوان
                  کارفرما خودداری کنید.
                  <br />
                  1. اهلیت: کارفرماها باید حداقل 18 سال سن داشته باشند تا
                  بتوانند آگهی‌های شغلی را در پلتفرم ما انتشار دهند. کارفرماها
                  مسئول رعایت تمامی قوانین و مقررات مربوط به انتشار مشاغل در
                  پلتفرم ما در سطح محلی، ملی و بین‌المللی هستند.
                  <br />
                  2. آگهی‌های شغلی: کارفرماها می‌بایست اطمینان حاصل کنند که
                  آگهی‌های شغلی با تمامی قوانین و مقررات محلی، ملی و بین‌المللی
                  سازگاری داشته باشند. آگهی‌های شغلی نباید حاوی محتویات مرتبط با
                  فعالیت‌های کیفری، مواد مخدر غیرقانونی، محتوای جنسی و عریانی و
                  فعالیت های هرمی و شبکه ای و یا هر محتوای دیگری که ممکن است با
                  دستورالعمل‌های محتوای ما در تضاد باشد، باشند.
                  <br />
                  3. دستورالعمل‌های انتشار: کارفرماها مسئول ارائه اطلاعات دقیق و
                  به‌روز در آگهی‌های شغلی خود هستند. آگهی‌های شغلی باید به وضوح
                  شرایط و مقررات استخدام را، از جمله ساعات کاری، مزایا و دستمزد،
                  اعلام کنند. کارفرماها باید تمام متقاضیان شغلی را با انصاف و
                  بدون تبعیض بر اساس نژاد، جنسیت، مذهب، معلولیت، سن یا هر مشخصه
                  محافظت‌شده دیگری دریافت کنند.
                  <br />
                  4. مدیریت و حذف: ما حق داریم آگهی‌های شغلی را مدیریت کرده و هر
                  آگهی‌ای که مقررات این شرایط یا دستورالعمل‌های محتوای ما را نقض
                  کند، حذف کنیم. کارفرماهایی که به تکرار این شرایط را نقض
                  می‌کنند، ممکن است حساب کاربری‌شان معلق یا بسته شود.
                  <br />
                  5. حریم خصوصی: ما اطلاعات شخصی شما را به عنوان توضیحات شده در
                  سیاست حریم خصوصی ما جمع‌آوری و پردازش می‌کنیم. با استفاده از
                  خدمات ما، شما به جمع‌آوری و استفاده از اطلاعات شخصی خود به
                  موجب آن موافقت می‌کنید.
                  <br />
                  6. سلب مسئولیت: ما تضمینی در مورد دقت، کمال یا دسترسی به
                  آگهی‌های شغلی در پلتفرم ما ارائه نمی‌دهیم. ما مسئولیتی در مورد
                  هرگونه اختلاف یا مشکلی که ممکن است بین کارفرماها و متقاضیان
                  شغلی به وجود آید، نداریم.
                  <br />
                  7. تغییرات در شرایط: ما ممکن است این شرایط را از زمان به زمان
                  به‌روز کنیم. هر تغییری را که در متامارس انجام شود، اعلام
                  خواهیم کرد، <br />
                </div>
                <br />
                <div dir="ltr">
                  Persian version in the above
                  <br />
                  <br />
                  Metamarce Employer Job Posting Terms and Conditions These
                  Terms and Conditions ("Terms") govern your use of Metamarce
                  ("we," "our," or "us") job posting services and website
                  (collectively, the "Service") as an employer. By accessing or
                  using our Service as an employer, you agree to comply with and
                  be bound by these Terms. If you do not agree to these Terms,
                  please do not use our Service.
                  <br />
                  1. Eligibility: Employers must be at least 18 years old to
                  post job listings on our platform. Employers are responsible
                  for adhering to all applicable local, national, and
                  international laws and regulations related to job postings on
                  our platform.
                  <br />
                  2. Job Listings: Employers must ensure that job listings
                  comply with all local, national, and international laws and
                  regulations. Job listings must not contain content related to
                  criminal activities, illegal drugs, sexual content, nudity, or
                  any other content that may violate our content guidelines.
                  <br />
                  3. Posting Guidelines: Employers are responsible for providing
                  accurate and up-to-date information in their job listings. Job
                  listings must clearly state the terms and conditions of
                  employment, including working hours, compensation, and
                  benefits. Employers must treat all job applicants fairly and
                  without discrimination based on race, gender, religion,
                  disability, age, or any other protected characteristic.
                  <br />
                  4. Moderation and Removal: We reserve the right to moderate
                  job listings and remove any listings that violate these Terms
                  or our content guidelines. Employers who repeatedly violate
                  our terms may have their accounts suspended or terminated.
                  <br />
                  5. Privacy: We collect and process personal information as
                  described in our Privacy Policy. By using our Service, you
                  consent to the collection and use of your personal information
                  as outlined therein.
                  <br />
                  6. Disclaimers: We do not guarantee the accuracy,
                  completeness, or availability of job listings on our platform.
                  We are not responsible for any disputes or issues that may
                  arise between employers and job applicants.
                  <br />
                  7. Changes to Terms: We may update these Terms from time to
                  time. Any changes will be posted on our website, and your
                  continued use of our Service as an employer after such changes
                  constitute your acceptance of the revised Terms.
                  <br />
                  8. Contact Us: If you have any questions or concerns about
                  these Employer Job Posting Terms and Conditions, please
                  contact us at metamarce.com@gmail.com .
                </div>
              </div>
              <label
                htmlFor="termsBtn"
                className={`my-3 mb-5 ${addErrClass({
                  formik,
                  field: "terms",
                })} `}
              >
                را مطالعه و می‌پذیرم
                <input
                  id="termsBtn"
                  type="checkbox"
                  className="form-check-input mx-2"
                  onChange={(e) => {
                    if (formik.values.terms) return;
                    formik.setValues((pre) => ({ ...pre, terms: true }));
                  }}
                  checked={formik.values.terms}
                />
                <FormikErrRender formik={formik} field="terms" />
              </label>
            </div>
          </div>
          <FormikListErr formik={formik} />
          <SubmitBtn formik={formik} text="" />
        </form>
      ) : (
        <div>
          <div>
            <div>
              <WarningAmber sx={{ color: "red" }} />
              برای ثبت فرصت شغلی دست کم باید مالکیت یک کسب و کار را در حساب خود
              داشته باشید.
            </div>
            <div>
              <WarningAmber sx={{ color: "red" }} />
              اگر در {name} کسب و کار ثبت شده ای ندارید می توانید
              از گوشه سمت چپ و بالا کشور یا استان خود را تغییر دهید.
              <span
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasScrolling2"
                aria-controls="offcanvasScrolling2"
                className="btn btn-primary share-cover-btn mx-2"
              >
                یا روی من کلیک کنید
              </span>
            </div>
          </div>
          <Link
            to={`/${window.lang}/dashboard/user/business/register`}
            className="btn btn-primary share-cover-btn mx-2"
          >
            افزودن کسب و کار
          </Link>
          <Link
            to={`/${window.lang}/business/filters?validation=draft`}
            className="btn btn-primary share-cover-btn mx-2"
          >
            دیدن کسب و کارهای فاقد مالکیت
          </Link>
        </div>
      )}

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );

  function SubmitBtn({ text, formik }) {
    let textBtn = "بفرست";
    if (formik.values.action === "edit") textBtn = "ویرایش";
    return (
      <button
        disabled={formik.isSubmitting}
        className="my-1 login-btn login-btn2"
        style={{
          backgroundColor: Object.keys(formik.errors)?.length > 0 && "grey",
        }}
        type="submit"
        id="createBusBtn"
      >
        {textBtn}
        {loading.spinner ? (
          <CircularProgress color="inherit" size="20px" />
        ) : (
          Object.keys(formik.errors)?.length === 0 && <CheckCircle />
        )}
        <span>
          {Object.keys(formik.errors)?.length > 0 && (
            <Tooltip1
              message=" موارد ستاره دار را کامل کنید."
              icon={<Error sx={{ fontSize: 18, color: "red" }} />}
              position="left"
            />
          )}
        </span>
      </button>
    );
  }
}
async function existBusinesses({ e, user }) {
  e.preventDefault();
  if (!user.roles?.includes(9999))
    return document.querySelector("#loginTrigger").click();
  const showHide = document.querySelector("#existingBusShowHide");

  if (showHide !== null) {
    showHide.style.display =
      showHide.style.display === "none" ? "block" : "none";
  }

  document.querySelector("#existItemNotAvail")?.remove();
}

function ExistBusRender({ bus, formik }) {
  return (
    <div
      key={bus?._id}
      className="m-1 p-3 bs-1 d-flex justify-content-between w-100"
    >
      <div className="d-flex ad-select">
        <input
          type="radio"
          id={`ad-select-bus${bus?._id}`}
          name="busRadio"
          style={{ display: "none" }}
          defaultChecked={bus.busId === formik.values.busId}
          onChange={(e) => {
            const showHide = (document.querySelector(
              "#existingBusShowHide"
            ).style.display = "none");

            formik.setValues((pre) => {
              const pre2 = { ...pre };
              // pre2.facilities = bus?.facilities || pre2.facilities;
              pre2.busName = bus?.busName || "";
              // pre2.img = "";
              pre2.businessCard = bus?.businessCard;
              pre2.logo = bus?.logo;
              // pre2.isPaid = bus?.isPaid;
              // pre2.targetIdentifier = bus?.targetIdentifier;
              pre2.busId = bus?._id;
              return pre2;
            });
          }}
        />
        <label
          htmlFor={`ad-select-bus${bus?._id}`}
          className="d-flex justify-content-between"
        >
          <img
            height="40px"
            width="60px"
            src={bus?.businessCard}
            alt={bus?.busName?.[window.lang]}
            loading="lazy"
          />
          <MyIcons icon={bus.status} />
          <span className="m-1 p-2">{bus?.busName?.[window.lang]}</span>
          <span className="m-1 p-2">
            دسته بندی : {bus?.category?.subCat?.[window.lang]}
          </span>

          <span className="m-1 p-2">ایمیل : {bus?.email}</span>
          <div className="m-1 p-2" dir="ltr">
            <i className="bi bi bi-geo-alt mx-2"></i>
            {`${window.capitalize(bus?.address?.city)},
              ${window.capitalize(bus?.address?.state)},
              ${window.capitalize(bus?.address?.countryShort)}
              `}
          </div>
        </label>
      </div>

      <Link
        className="d-flex justify-content-around"
        to={`/${window.lang}/jobs/page/${tools.slugMaker({title:bus?.moreInfo?.title,city:bus?.moreInfo?.address?.city,state:bus?.moreInfo?.address?.state,serial:bus?.serial})}`}
      >
        <button className="btn primary-btn delete-btn"> دیدن صفحه </button>
      </Link>
    </div>
  );
}

function editInitialData(formik, data, action) {
  const initialValues = {
    action,
    busId: data.business,
    logo: data.moreInfo.logo,
    businessCard: data.moreInfo.businessCard,
    busName: data.moreInfo.busName,
    _id: action === "edit" ? data._id : "",
    lang: data?.lang ?? window.lang,
    lcs: data?.lcs ?? window.lcs,
    email: data.email,
    emType: data.moreInfo.emType,
    workShifts: data.moreInfo.workShifts,
    title: data.moreInfo.title,
    description: data.moreInfo.description,
    experience: data.moreInfo.experience,
    expertise: data.moreInfo.expertise,
    benefits: data.moreInfo.benefits,
    studyField: data.moreInfo.studyField,
    languages: data.moreInfo.languages,
    phone: data.moreInfo.phone,
    moreContact: data.moreInfo.moreContact,
    terms: data.moreInfo.terms,
    process: data.moreInfo.process,
    tags: data.moreInfo?.tags?.join("+"),
    socialMedia: data.moreInfo.socialMedia,
    // files: data.moreInfo,
    salary: data.moreInfo.salary,
    location: {
      ...data.moreInfo.address,
      lat: data.moreInfo.address?.coordinates?.lat,
      lng: data.moreInfo.address?.coordinates?.lng,
    },

    dates: data.moreInfo.dates,
    category: data.category,
  };
  formik.setValues(initialValues);
  if (document.querySelector("#termsContent"))
    document.querySelector("#termsContent").style.display = data.moreInfo?.terms
      ? "none"
      : "block";
}

async function editHandle({ messageRef, setLoading, user, values, navigate }) {
  console.log("edit handle triggred");
  setLoading((pre) => ({ ...pre, spinner: true }));
  const response = await apiJobs.edit({ token: user.token, values });
  setLoading((pre) => ({ ...pre, spinner: false }));
  if (response.error)
    return messageRef.current.showError({
      message: (
        <div>
          <div> مشکل در انجام عملیات !</div>
          <div dir="ltr">{response.error}</div>
        </div>
      ),
    });

  messageRef.current.showSuccess({
    message: " فرصت شغلی با موفقیت ویرایش شد.",
  });

  navigate(`/${window.lang}/jobs/page/${tools.slugMaker({title:response?.data?.moreInfo?.title,city:response?.data?.moreInfo?.address?.city,state:response?.data?.moreInfo?.address?.state,serial:response?.data?.serial})}`, {
    state: response.data,
  });
}
