import { useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
// import SmallPostSummaryCard from "../../blog/blogCarts/smallPostSummaryCard";
import apiMedia from "../../../../utils/apiMedia";
import NewBusSmallCard from "../../../golbal/sliders/components/newBusSmallCard";
import NewMediasSmallCard from "../../../golbal/sliders/components/newMediasSmallCard";
// import NewJobsSmallCard from "../../../golbal/sliders/components/newJobsSmallCard";
import apiBusiness from "../../../../utils/apiBusiness";
import apiJobs from "../../../../utils/apiJobs";
import tools from "../../../../utils/tools";
import apiBlog from "../../../../utils/apiBlog";
import apiEvent from "../../../../utils/apiEvent";
import NewTutorSmallCard from "../../../golbal/sliders/components/newTutorSmallCard";

export default function MainSidebar({ section }) {
  function apiRouter() {
    switch (section) {
      case "media":
        return apiMedia;
      case "business":
        return apiBusiness;
      case "jobs":
        return apiJobs;
      case "blog":
        return apiBlog;
      case "event":
        return apiEvent;

      default:
        return undefined;
    }
  }
  const [pubData] = useOutletContext();
  const [tags, setTags] = useState([]);

  // useEffect(() => {
  //   fetchTags();
  // }, []);
  async function fetchTags() {
    const api = apiRouter();
    if (api === undefined) return;
    const response = await api?.getTags({
      filters: tools.lcsn(window.location.pathname),
    });
    // console.log('response main',response);
    if (response?.error) return;
    // console.log(response.data);
    setTags(response.data);
  }

  return (
    <div className="  align-items-center">
      {section === "blog" ? (
        <div>
          <div className=" mt-0 mb-5 display-none-md">
            <div className="accordion " id="accordionBlogCategory">
              {pubData?.categories?.blog?.map((mainCat, i) => (
                <div
                  key={i}
                  className={`accordion-item m-4 login-btn border-0 card-background${
                    i + 2
                  }`}
                >
                  <h2 className="accordion-header " id="heading1">
                    <button
                      className="accordion-button py-2  accordion-button-blog collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${i}`}
                      aria-expanded="false"
                      // aria-controls="collapse1"
                      // onClick={e=>navigate(`${window.location.pathname}?mainCay=${mainCat.mainCategory}`)}
                    >
                      <span>
                        <img
                          src={mainCat.mainLogo}
                          alt={"main Category" + mainCat.mainCat?.[window.lang]}
                          className="megamenu-icon"
                          loading="lazy"
                        />
                      </span>
                      {mainCat.mainCat?.[window.lang]}
                    </button>
                  </h2>
                  <div
                    id={`collapse${i}`}
                    className="accordion-collapse collapse"
                    aria-labelledby="heading1"
                    data-bs-parent="#accordionBlogCategory"
                  >
                    <div className="accordion-body text-start ms-5">
                      <ul className="list-unstyled  accordion-categories">
                        {mainCat.subCat.map((subCat, i) => (
                          <li key={i}>
                            <span>
                              <i className="bi bi-chevron-left small-gray-font"></i>
                            </span>
                            <Link
                              to={`/${window.lang}/blog/filters?subCat=${
                                subCat?.[window.lang]
                              }&subCatId=${subCat?._id}&mainCat=${
                                mainCat.mainCat?.[window.lang]
                              }&mainCatId=${mainCat?._id}`}
                              className="accordion-categories "
                            >
                              {subCat?.[window.lang]}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        ""
        // <div className="listing-sidebar-section mt-0 mb-5">
        //   <div className="divider2 divider-small">
        //
        //     <span>
        //       <img
        //         src="/img/news.png"
        //         className="arrow2 pe-2 py-1"
        //         alt="more info"
        //         loading="lazy"
        //       />
        //     </span>
        //     <span className="p-0 mb-0 "> تازه‌های مجله</span>
        //   </div>
        //   {pubData?.newPosts?.slice(0, 3).map((post, i) => (
        //     <SmallPostSummaryCard key={i} post={post} />
        //   ))}
        // </div>
      )}

      {/* 
        <div className=" solid-card mt-0 mb-5 d-flex align-items-center justify-content-center">
          <div className=" solid-card2 mt-0 mb-0">
            <a href="#">راهنمای تازه‌واردان</a>
          </div>
        </div> */}
      {section !== "business" && (
        <div className="listing-sidebar-section mt-0 mb-5">
          <div className="divider2 divider-small">
             
            <span>
              <img
                src="/img/news.png"
                className="arrow2 pe-2 py-1"
                alt="more info"
                loading="lazy"
              />
            </span>
            <span className="p-0 mb-0 ">تازه‌های کسب و کار</span>
          </div>

          {pubData?.newBus?.slice(0, 3).map((bus, i) => (
            <NewBusSmallCard key={i} data={bus} />
          ))}
        </div>
      )}
      {section !== "tutor" && (
        <div className="listing-sidebar-section mt-0 mb-5">
          <div className="divider2 divider-small">
             
            <span>
              <img
                src="/img/news.png"
                className="arrow2 pe-2 py-1"
                alt="more info"
                loading="lazy"
              />
            </span>
            <span className="p-0 mb-0 ">تازه‌های متادمی</span>
          </div>

          {pubData?.newTutors?.slice(0, 3).map((da, i) => (
            <NewTutorSmallCard key={i} data={da} />
          ))}
        </div>
      )}
      {section !== "media" && (
        <div className="listing-sidebar-section mt-0 mb-5">
          <div className="divider2 divider-small">
             
            <span>
              <img
                src="/img/news.png"
                className="arrow2 pe-2 py-1"
                alt="more info"
                loading="lazy"
              />
            </span>
            <span className="p-0 mb-0 ">تازه‌های شبکه اجتماعی</span>
          </div>

          {pubData?.newMedias?.slice(0, 3).map((data, i) => (
            <NewMediasSmallCard key={i} data={data} />
          ))}
        </div>
      )}
      {/* {section !== "jobs" && (
        <div className="listing-sidebar-section mt-0 mb-5">
          <div className="divider2 divider-small">
             
            <span>
              <img
                src="/img/news.png"
                className="arrow2 pe-2 py-1"
                alt="more info"
                loading="lazy"
              />
            </span>
            <span className="p-0 mb-0 ">تازه‌های کاریابی</span>
          </div>

          {pubData?.newJobs?.slice(0, 3).map((data, i) => (
            <NewJobsSmallCard key={i} data={data} />
          ))}
        </div>
      )} */}

      {/* this is tags section */}
      {/* {section!=="center"&&section!=='tutor'&& <div className="listing-sidebar-section mt-0 mb-5">
        <div className="divider2 divider-small mb-3">
           
          <span>
            <img src="/img/tag.png" className="arrow2 pe-2 py-1" alt="tags" loading="lazy"/>
          </span>
          <span className="p-0 mb-0 ">برچسب‌های پرطرفدار</span>
        </div>
        <ul className=" mega-menu-subtitle d-flex flex-wrap ">
          {tags?.map((tag, i) => (
            <li key={i}>
              <Link
                onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
                className="mx-1"
                title={`${tag.count} یافته`}
                to={`/${window.lang}/${section}/filters?tag=${tag.tag}`}
              >
                {tag.tag} |
              </Link>
            </li>
          ))}
        </ul>
      </div>} */}

      {/* YOUTUBE *****888 KEEP*****
        <div className="ratio ratio-16x9 mb-5 ">
          <iframe
            className="video-frame-sidebar"
            src="https://www.youtube.com/embed/zqQGkco4VkM?mute=1&autoplay=1"
            allowFullScreen="allowFullScreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
          ></iframe>
        </div>
        <div className="ratio ratio-16x9 mb-5 ">
          <iframe
            className="video-frame-sidebar"
            src="https://www.youtube.com/embed/t3drr-tJvfU"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div> */}
      {/* <!-- End sidebar search formn--> */}
    </div>
  );
}
