import React, { useState } from 'react'
import tools from '../../../../../../../utils/tools'
import apiChain from '../../../../../../../utils/apiChain';

export default function KnownAddresses() {
    const [knownAddresses,setKnownAddresses] =useState([]) 
    async function getKnownAddresses(){
        const response = await apiChain.getKnownAddresses();
        if (response.error)return window.alert(response.error)           
        console.log("response", response);
        setKnownAddresses(response.data);          
        

    }

  return (
    <div>
        <h3 className="btn btn-primary"onClick={e=>getKnownAddresses()}>آدرس های موجود </h3>
        
        <div>
          {knownAddresses.map((address,i)=><div key={i}>
           <div> {address}</div> 
           <br />          
            </div>)}
        </div>
    </div>
  )
}
