import "./saved.css";
import UserDashSavedNav from "../../../components/dashNavbar/userDashSavedNav";
import { Outlet } from "react-router-dom";

import MetaDecorator from "../../../../../../utils/metaDecorator";
// import UserDashNavbar from "../../../components/dashNavbar/userDashNavbar"
// import { useContext } from "react"
// import { CartContext } from "../../../../../contexts/cartContext"

export default function userSavedOut() {
  const metaTagsData = {
    title: "متامارس | داشبورد | آیتم های ذخیره ",
    description: "مشاهده موارد ذخیره شده در پروفایل کاربری",
    section: "local business page",
    url: `${window.location.origin}/${window.lang}/dashboard/user/saved`,
    img: "https://www.metamarce.com/img/illustration/buy-photography-gear-online.svg",
    type: "webpage",
    index: false,
  };
  return (
    <div className="savedItems">
      {/* <UserDashNavbar/> */}
      <MetaDecorator data={{ ...metaTagsData }} />

      <UserDashSavedNav />
      <Outlet />
    </div>
  );
}
