import tools from "../../../utils/tools";

export default function Pagination({
  data,
  setPageNumber,
  pageNumber,
  setItemsToShow,
  position,
}) {
  // console.log(props);
  // const [pageNumber, setPageNumber] = useState(1);
  const totalPages = Math.ceil(data.resultsTotal / data.itemsToShow);

  // function pageNumberHandle(e) {
  //   e.preventDefault();
  //   setPageNumber(e.currentTarget.id);
  // }
  const trans = {
    page:{en:"Page",fa:"صفحه "},
    from:{en:"From",fa:"از"},
    display:{en:"Display",fa:"تعداد نمایش"},
  }

  return (
    <div
      className="mt-0 d-flex justify-content-md-start justify-content-between"
      id={"Page-navigation-example " + position}
    >
      <span className="display-none-md">
        <select
          className="btn-search shadow-none m-2"
          style={{ width: 90 }}
          type="select"
          onChange={(e) => setItemsToShow(e.target.value)}
          name={"itemsToShowSel" + position}
          aria-labelledby={"Page-navigation-example " + position}
          value={data.itemsToShow}
        >
          <option value={15}>{trans.display[window.lang]}</option>
          <option value={15}>15</option>
          <option value={30}>30</option>
          <option value={45}>45</option>
        </select>
      </span>
      {/* <span className="box4 py-1 px-2 mx-2 ">{tools.dynamicNumber({number:data.resultsTotal,lang:window.lang}) } مورد</span> */}
      <span>
        
        <span>
          <i
            className="bi bi-caret-right-fill direction-pagination mx-0 my-2"
            onClick={(e) =>{
              // pageNumber < totalPages &&
              // (function () {
              //   setPageNumber(pageNumber + 1);
              //   window.scroll(0, 0);
              // })()

              pageNumber >= 2 && setPageNumber(pageNumber - 1)
              window.scroll(0, 0);}
            }
          ></i>
        </span>
        <span
          className="btn box5 mx-0 my-2 "
          onClick={(e) => {
            const el = document.getElementById(`paginationInp${position}`);
            el.style.display = "inline-block";
            el.value = "";
            el.focus();
          }}
        >
          {trans.page[window.lang]}
          
          <label
            htmlFor={`paginationInp${position}`}
            className=" px-1"
            onClick={(e) => {
              const el = document.getElementById(`paginationInp${position}`);
              el.style.display = "inline-block";
              el.value = "";
              el.focus();
            }}
          >
            {totalPages >= 1 ? tools.dynamicNumber({number:pageNumber,lang:window.lang})  : ""}
          </label>
          <input
            id={`paginationInp${position}`}
            className="input-pagination px-1"
            autoComplete="off"
            style={{ display: "none" }}
            type="text"
            // defaultValue={totalPages >=1 ? pageNumber : ""}
            onFocus={(e) => {
              e.target.previousElementSibling.style.display = "none";
              e.target.style.display = "inline-block";
              e.target.placeholder = pageNumber;
            }}
            onBlur={(e) => {
              e.target.previousElementSibling.innerText =
                totalPages >= 1 ? (e.target.value = pageNumber) : "";
              e.target.style.display = "none";
              e.target.previousElementSibling.style.display = "inline-block";
            }}
            onKeyUp={(e) => {
              e.preventDefault();
              if (e.key !== "Enter") return;
              if (e.target.value > totalPages || e.target.value <= 0) return;
              console.log("passed");
              setPageNumber(Number(e.target.value));
              e.target.style.display = "none";
              e.target.previousElementSibling.style.display = "inline-block";
              window.scroll(0, 0);
            }}
          />
          <span className="pagination-from">{trans.from[window.lang]} {tools.dynamicNumber({number:totalPages,lang:window.lang})}</span>
        </span>
        <span>
          <i
            className="bi bi-caret-left-fill direction-pagination px-0 "
            onClick={(e) => {
              // pageNumber >= 2 && setPageNumber(pageNumber - 1);
              // window.scroll(0, 0);

              pageNumber < totalPages &&
              (function () {
                setPageNumber(pageNumber + 1);
                window.scroll(0, 0);
              })()
            }}
          ></i>
        </span>
      </span>

      {/* <ul className="pagination pagination-business justify-content-center">
      {pageNumber>= 2 &&<li className="page-item page-item1" onClick={e => setPageNumber(pageNumber-1)}>
          <button className="page-link page-link1" >
            <i className="bi bi-chevron-right"></i>
          </button>
        </li>}

        <li className="page-item page-item1" onClick={e => setPageNumber(1)}>
          <button className="page-link page-link1" >
           اول
          </button>
        </li>

        {btnRender()}

        <li className="page-item page-item1" onClick={e => setPageNumber(totalPages)}>
          <button className="page-link page-link1" >
           آخر
          </button>
        </li>
        
        {pageNumber< totalPages &&<li  className="page-item page-item1" onClick={e =>setPageNumber(pageNumber+1)}>
          <button className="page-link page-link1" >
            <i className="bi bi-chevron-left"></i>
          </button>
        </li>
        }
      </ul> */}
    </div>
  );
}
