import { NavLink } from "react-router-dom";

export default function UserDashMediaNav() {
  const showEdit = window.location.pathname.split("/").slice(7, 8).toString();
  const trans={   
    add:{fa:"افزودن ",en:"Add"},
    myList:{fa:"صفحه های من",en:"My List"},
    edit:{fa:"ویرایش شبکه اجتماعی",en:"Edit"},
   
  }
  
  return (
    <div className="dashboardNavigator d-flex">
      <NavLink
        to={`/${window.lang}/dashboard/user/media/register`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >{trans.add[window.lang]}
      
      </NavLink>
      <NavLink
        to={`/${window.lang}/dashboard/user/media/list`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >{trans.myList[window.lang]}
    
      </NavLink>
      {showEdit === "edit" && (
        <NavLink
          to={`/${window.lang}/dashboard/user/media/edit`}
          className={`sidebarListItem m-1 link  ${({ isActive }) => {
            return isActive && "active";
          }}`}
        >{trans.edit[window.lang]}         
        </NavLink>
      )}
    </div>
  );
}
