import * as React from 'react';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { createTheme, } from '@mui/material/styles';
import countryStates from '../../../utils/countryStates';
import api from '../../../utils/api';
import { Checkbox, CircularProgress } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useState } from 'react';

// Theme.ts
const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiAutocomplete: {
        defaultProps: {
          renderOption: (props, option, state, ownerState) => (
            <Box
              sx={{
                borderRadius: '8px',
                margin: '5px',
                [`&.${autocompleteClasses.option}`]: {
                  padding: '8px',
                },
              }}
              component="li"
              {...props}
            >
              {ownerState.getOptionLabel(option)}
            </Box>
          ),
        },
      },
    },
  });
  export default function FiltersCountryStateCity1({ mode,setBackFilters,backFilters,filters }) {
  
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState({ spinner: false, cities: false });
  
    React.useEffect(() => {
      if (backFilters.state){ getCitiesHandle()}else
      if (backFilters.country)initialStateHandle(backFilters.country);
    }, [backFilters.state]);
  
    const handleFilterUpdate = (updatedFields) => {
      const updatedFilters = { ...backFilters, ...updatedFields };
      console.log('state',updatedFilters);
      setBackFilters(updatedFilters);      
    };
  
    async function getCitiesHandle() {
      const { countryShort, stateShort } = backFilters;
      if (!countryShort || !stateShort) return console.log('Country and state must be filled');
      setLoading((prev) => ({ ...prev, cities: true }));
  
      const response = await api.getCities(countryShort.toUpperCase(), stateShort.toUpperCase());
      setLoading((prev) => ({ ...prev, cities: false }));
      if (response.error) return alert(response.error);
      setCities(response.data);
    }
  
    function initialStateHandle(country) {
      if (!country?.length) return;
      
      const countryInfo = countryStates.find((count) => count.country.toLowerCase() === country.toLowerCase());
      console.log('initialStateHandle',countryInfo);
      setStates(countryInfo.states);
      handleFilterUpdate({
        country,
        countryShort: countryInfo.countryShort.toLowerCase(),
        state: "",
        stateShort: "",
        city: "",
        cities: [],
      });
    }
  
    const handleCountryChange = (event, country) => {
      console.log('country',country);
      
      if (!country) {
        setStates([]);
        setCities([]);
        handleFilterUpdate({ country: "", countryShort: "", state: "", stateShort: "", city: "" });
      } else {
        initialStateHandle(country);
      }
    };
  
    const handleStateChange = (event, stateName) => {
      if (!stateName) {
        setCities([]);
        handleFilterUpdate({ state: "", stateShort: "", city: "" });
      } else {
        const state = states.find((st) => st.state.toLowerCase() === stateName.toLowerCase());
     
        handleFilterUpdate({
          state: state.state.toLowerCase(),
          stateShort: state.stateShort.toLowerCase(),
          city: "",
          cities: [],
        });
      }
    };
  
    const handleCityChange = (event, city) => {
      handleFilterUpdate({ city: city || "" });
    };
  
    const handleMultiCityChange = (event, selectedCities) => {
      handleFilterUpdate({ cities: selectedCities });
    };
  
    return (
      <Stack spacing={5} sx={{ width: 300 }}>
        <CountrySelect />
        {states.length > 0 && <StateSelect />}
        {mode !== 'multi' && (loading.cities ? <CircularProgress /> : cities.length > 0) && <CitySelect />}
        {mode === 'multi' && (loading.cities ? <CircularProgress /> : cities.length > 0) && <CityMultiSelect />}
      </Stack>
    );
  
    function CountrySelect() {
      return (
        <Autocomplete
          id="country-select"
          options={countryStates.map((co) => co.country)}
          getOptionLabel={(option) => option}
          renderInput={(params) => <TextField {...params} label="Choose a country" />}
          onChange={handleCountryChange}
          value={backFilters.country}
        />
      );
    }
  
    function StateSelect() {
      return (
        <Autocomplete
          id="state-select"
          options={states.map((st) => st.state)}
          getOptionLabel={(option) => option}
          renderInput={(params) => <TextField {...params} label="Choose a state" />}
          onChange={handleStateChange}
          value={backFilters.state}
        />
      );
    }
  
    function CitySelect() {
      return (
        <Autocomplete
          id="city-select"
          options={cities.map((city) => city.name)}
          getOptionLabel={(option) => option}
          renderInput={(params) => <TextField {...params} label="Choose a city" />}
          onChange={handleCityChange}
          value={backFilters.city}
        />
      );
    }
  
    function CityMultiSelect() {
      const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
      const checkedIcon = <CheckBoxIcon fontSize="small" />;
      return (
        <Autocomplete
          multiple
          id="city-multi-select"
          options={cities.map((city) => city.name)}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label="Choose Cities" placeholder="Select cities" />}
          onChange={handleMultiCityChange}
          value={backFilters.cities}
        />
      );
    }
  }

