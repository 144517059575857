export default function JobsNav() {
  // const showEdit = window.location.pathname.split("/").slice(5, 6).toString();
  const jobsUrl =
    window.location.origin +
    "/" +
    window.location.pathname.split("/").slice(1, 3).join("/");
  // console.log("jobsUrl",jobsUrl);
  const matchUrlMain =
    window.location.origin +
    "/" +
    window.location.pathname.split("/").slice(1, 4).join("/");
  // console.log("matchUrlMain",matchUrlMain);
  // const matchUrlSub =
  //   window.location.origin +
  //   "/" +
  //   window.location.pathname.split("/").slice(1, 7).join("/");
  // console.log("matchUrlSub",matchUrlSub);
  function resetFilters(section) {
    const storedFilters = JSON.parse(localStorage.getItem("storedFilters"));
    if (storedFilters?.[section]) {
      delete storedFilters[section];
      localStorage.setItem("storedFilters", JSON.stringify(storedFilters));
    }
  }
  return (
    <div className="dashboardNavigator d-flex justify-content-center">
      <ul className="d-flex justify-content-center mt-3 px-0 font-12">
        <li
          onClick={(e) => {
            window.navigateHandle(`/${window.lang}/jobs/filters`);
            resetFilters("jobs");
          }}
          className={`sidebarListItem sidebarListItem2 mx-2 link ${
            (jobsUrl + "/filters" === matchUrlMain ||
              jobsUrl + "/page" === matchUrlMain) &&
            "active"
          }`}
        >
          آگهی استخدام
        </li>
        <li
          onClick={(e) => {
            window.navigateHandle(`/${window.lang}/jobs/ready/filters`);
            resetFilters("ready");
          }}
          className={`sidebarListItem sidebarListItem2 mx-2 link ${
            jobsUrl + "/ready" === matchUrlMain && "active"
          }`}
        >
          آماده به کار
        </li>
        <li
          onClick={(e) => {
            window.navigateHandle(`/${window.lang}/jobs/hide/filters`);
            resetFilters("hide");
          }}
          className={`sidebarListItem sidebarListItem2 mx-2 link ${
            jobsUrl + "/hide" === matchUrlMain && "active"
          }`}
        >
          گوشه و کنار
        </li>
      </ul>
    </div>
  );
}
