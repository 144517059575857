import { NavLink } from "react-router-dom";

export default function UserDashTransNav() {
  const trans={
    metaPoint:{fa:"متا پوینت ها",en:"Meta Points"},
    wallet:{fa:"کیف پول",en:"Wallet"},
    invoices:{fa:"صورتحساب‌ها",en:"Invoices"},
    incompleteOrders:{fa:"سفارش‌های ناتمام",en:"Incomplete Orders"},
    marketingReports:{fa:"گزارش بازاریابی",en:"Marketing reports"},
    refferal:{fa:"افیلیشن ",en:"Refferal"},
  }
  const sec = window.location.pathname.split("/")[5]
  // console.log('isAff',isAff);
  
  return (
    <div className="dashboardNavigator d-flex flex-wrap justify-content-md-start justify-content-around p-3">
      {/* <NavLink to={`/${window.lang}/dashboard/user/trans/overview`} className={`sidebarListItem m-1 link  ${({isActive})=>{return isActive && "active" }}`}>دیدگاه کلی</NavLink> */}
      <NavLink
        to={`/${window.lang}/dashboard/user/trans/points`}
        className={`sidebarListItem m-md-3  link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {trans.metaPoint[window.lang]}
      </NavLink>
      <NavLink
        to={`/${window.lang}/dashboard/user/trans/wallet`}
        className={`sidebarListItem m-md-3  link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
 {trans.wallet[window.lang]}    
   </NavLink>
      <NavLink
        to={`/${window.lang}/dashboard/user/trans/invlist`}
        className={`sidebarListItem m-md-3 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
         {trans.invoices[window.lang]}    

      </NavLink>
      <NavLink
        to={`/${window.lang}/dashboard/user/trans/orderlist`}
        className={`sidebarListItem m-md-3 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {trans.incompleteOrders[window.lang]}
      </NavLink>
      {/* <NavLink
        to={`/${window.lang}/dashboard/user/trans/refcodelist`}
        className={`sidebarListItem m-md-3 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
         {trans.marketingReports[window.lang]}
      </NavLink> */}
      <NavLink
        to={`/${window.lang}/dashboard/user/trans/affilate/ana`}
        className={`sidebarListItem m-md-3 link ${sec==='affilate'?"active":""}`}
        // className={`sidebarListItem m-md-3 link  ${({ isActive }) => {
        //   return isActive && "active";
        // }}`}
      >
 {trans.refferal[window.lang]}      </NavLink>
    </div>
  );
}
