import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";

import { CartContext } from "../../../../contexts/cartContext";
import api from "../../../../../utils/apiMP";
import ToastNote from "../../../../golbal/notifications/toastNote";
// import Tooltip1 from "../../../../golbal/tooltip/tooltip";
import ColoredSpinner from "../../../../alret/spiners/coloredSpiner/coloredSpinner";
// import SearchBarUser from "../profile/transactions/myTrans/searchBarUser";
import MyIcons from "../../../../golbal/icons/myIcons";

export default function UsedList({ access }) {
  const { user } = useContext(CartContext);
  const effectRan = useRef(false);
  const [loading, setLoading] = useState(false);
  const [user2, setUser2] = useState({ _id: user._id });
  const [data, setData] = useState({});
  const messageRef = useRef(null);

  // const access = window.location.pathname.split('/').slice(5,6).join('')!=='user'?'admin':'user'
  const deleteHandle = async (id) => {
    if (window.confirm("آیا با حذف این نیازمندی موافق هستید؟") !== true) return;
    const response = await api.hideChangeStatus({
      token: user.token,
      id,
      action: "delete",
    });
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({
      message: "Delete action is completed successfully.",
    });
    if (access === "admin") {
      console.log("admin is triggred");
      const newData = data.map((da) => (da._id === id ? response.data : da));
      setData(newData);
    } else {
      console.log("user is triggred");
      const newData = data.filter((da) => da._id !== id);
      setData(newData);
    }
  };
  const inactiveHandle = async (id) => {
    if (window.confirm("I am agree to inactive this job posting") !== true)
      return;
    const response = await api.changeStatus({
      token: user.token,
      id,
      action: "inactive",
    });
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({
      message: "Inactive action is completed successfully.",
    });

    const newData = data.map((da) => (da._id === id ? response.data : da));
    setData(newData);
  };
  const activationHandle = async (id) => {
    if (window.confirm("I am agree to activate this job posting") !== true)
      return;
    const response = await api.changeStatus({
      token: user.token,
      id,
      action: "active",
    });
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({
      message: "Inactive action is completed successfully.",
    });

    const newData = data.map((da) => (da._id === id ? response.data : da));
    setData(newData);
  };
  const completeHandle = async (id) => {
    if (window.confirm("I am agree to complete this job posting") !== true)
      return;
    const response = await api.changeStatus({
      token: user.token,
      id,
      action: "complete",
    });
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({
      message: "complete action is successfull.",
    });

    const newData = data.map((da) => (da._id === id ? response.data : da));
    setData(newData);
  };

  useEffect(() => {
    fetchListData();
  }, [window.location.pathname]);

  async function fetchListData() {
    setLoading(true);
    const response = await api.fetchUsedList({
      token: user.token,
      access: access,
      id: user2._id,
    });
    setLoading(false);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    setData(response.data);
  }

  const columns = [
    {
      field: "action",
      headerName: "عملیات",
      width: 170,
      renderCell: (params) => {
        return (
          <div className="d-flex">
            <Link to={`/${window.lang}/mp/used/page/${params.row._id}`}>
              <MyIcons icon="display" />
            </Link>
            <Link
              to={`/${window.lang}/dashboard/user/mp/usededit/${params.row._id}`}
              state={{ post: params.row }}
            >
              <MyIcons icon="edit" />
            </Link>
            {user?.roles?.includes(1000) && (
              <div
                title="پاک کردن"
                onClick={() => deleteHandle(params.row._id)}
              >
                <MyIcons icon="delete2" />
              </div>
            )}
          </div>
        );
      },
    },

    // {
    //   field: "moreInfo.status",
    //   headerName: "وضعیت",
    //   width: 100,
    //   renderCell: (params) => <div>{params.row.moreInfo?.status}</div>,
    // },
    {
      field: "img",
      headerName: "عکس",
      width: 100,
      renderCell: (params) => {
        return (
          <img
            src={params.row.moreInfo.photos[0]}
            alt={params.row.name}
            className="userListImg"
            loading="lazy"
          />
        );
      },
    },

    {
      field: "title",
      headerName: "عنوان آگهی",
      width: 220,
      renderCell: (params) => {
        return (
          // <Link
          //   to={`/${window.lang}/demy//tutor/page/${params.row._id}`}
          //   state={params.row}
          // >
          <div title={params.row?.title}>{params.row?.title}</div>
          // </Link>
        );
      },
    },
    {
      field: "moreInfo.address",
      headerName: "آدرس",
      width: 200,
      renderCell: (params) => (
        <div dir="ltr">
          <span title={params.row.moreInfo?.address?.city}>
            {params.row.moreInfo?.address?.city},
          </span>
          <span title={params.row.moreInfo?.address?.state}>
            {params.row.moreInfo?.address?.state},
          </span>
          <span title={params.row.moreInfo?.address?.countryShort}>
            {params.row.moreInfo?.address?.countryShort}
          </span>
        </div>
      ),
    },

    {
      field: "status",
      headerName: " وضعیت آگهی",
      width: 250,
      renderCell: (params) => <div>{params.row.moreInfo.status}</div>,
    },
  ];

  return (
    <div className="userList" style={{ height: "100vh", width: "100%" }}>
      <ToastNote messageRef={messageRef} />
      {loading && <ColoredSpinner />}
      {!data.length ? (
        <div>
          داده ای پیدا نشد. پس از اطمینان از وجود داده، دوباره تلاش کنید.
        </div>
      ) : (
        <DataGrid
          getRowId={(row) => row._id}
          rows={data}
          components={{
            Toolbar: GridToolbar,
          }}
          disableSelectionOnClick
          columns={columns}
          pageSize={[12]}
          rowsPerPageOptions={[1]}
          checkboxSelection
        />
      )}
    </div>
  );
}
