import CartItems from "./cartItems";
import { useContext, useEffect, useRef, useState } from "react";
import { CartContext } from "../contexts/cartContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import ToastNote from "../golbal/notifications/toastNote";
import Paypal from "./paypal";
import apiStore from "../../utils/apiStore";
import ColoredSpiner from "../alret/spiners/coloredSpiner/coloredSpinner";
import { CircularProgress } from "@mui/material";
import renderData from "../../utils/renderData";
import api from "../../utils/api";
import tools from "../../utils/tools";
import MyIcons from "../golbal/icons/myIcons";
import CountdownBar from "../golbal/countDown/countdownBar";

export default function Cart(props) {
  const {
    productItems,
    serviceItems,
    countItems,
    user,
    cartEmptier,
    serviceDecrement,
    signedUser,
  } = useContext(CartContext);
  const { id } = useParams();
  // const [pubData] = useOutletContext();
  
  const { lang, state:{state,stateShort}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
  const [currencies, setCurrency] = useState([]);
  const countryDetails =
    JSON.parse(localStorage?.getItem("countryDetails")) ?? {};

  const [loading, setLoading] = useState({ createOrder: false });
  const messageRef = useRef(null);
  const [order, setOrder] = useState({
    productItems: [],
    serviceItems: [],
    itemsPrice: 0.0,
    shippingCost: 0.0,
    lang,
    currency: "",
    currency_symbol: "",
    exRate: 0,
    convertRate: 0,
    accepted: false,

    address: {
      country,
      countryShort,
      state:state.state,
      stateShort,
    },
    taxes: {
      GST: 0.0,
      QST: 0.0,
      HST: 0.0,
    },
    totalPrice: 0.0,
    walletInfo: {
      isActive: false,
      amount: 0,
      currency: "",
      exRate: 0,
      convertRate: 0,
    },
    promCode: "",
    refCode: "",
    discPercent: 0,
    discAmount: 0,
    prom: "",
    cycle: "",
    affiliateInfo:{affiliatedby: user?.affiliateInfo?.affiliatedby||undefined,},
  });
  console.log(order);
  const navigate = useNavigate();
  const currencyDoc = currencies.find(
    (cur) => cur.currency === order?.newPrice?.currency
  );

  function cartValue() {
    let price = 0.0;
    productItems.forEach((x) => {
      price += Math.floor(x.price * x.orderCount * 100) / 100;
    });
    serviceItems?.forEach(
      (data) => (price += Math.floor(data?.price * 100) / 100)
    );
    return Math.round(price * 100) / 100;
  }

  async function fetchOrder() {
    console.log(id);
    if (!id?.length) return console.log("id :", id);
    setLoading((pre) => ({ ...pre, fetchData: true }));
    const response = await apiStore.getOrder({ id, token: user.token });
    setLoading((pre) => ({ ...pre, fetchData: false }));
    if (response.error)
      return messageRef.current.showError({
        message: `خطا در انجام عملیات : ${response.error}`,
      });
    console.log(response);
    setOrder(response.data);
    // setProgressBar
  }

  async function fetchAcceptedCurHandle() {
    const response = await api.getAcceptedCur({ token: user.token });
    if (response.error)
      return messageRef.current.showError({
        message: `مشکل در انجام عملیات : ${response.error}`,
      });
    setCurrency(response.data);
  }

  useEffect(() => {
    if (id?.length) {
      fetchOrder();
    } else {
      console.log("useEffect Ran", order);
      !serviceItems?.accepted && !currencies.length && fetchAcceptedCurHandle();
      const itemsPrice = cartValue();
      let discPercent = 0;
      if (order?.marketerInfo?.discPercent) {
        discPercent += order?.marketerInfo?.discPercent;
      }
      if (order?.discPercent) {
        discPercent += order?.discPercent;
      }
      const discCalcu =
        order.discAmount + Math.round(itemsPrice * discPercent * 100) / 100;
      const discAmount = discCalcu * order.exRate;
      console.log(
        "order.exRate :",
        order.exRate,
        "discAmount:",
        discAmount,
        "discCalcu:",
        discCalcu
      );

      let taxes = { GST: 0, QST: 0 };

      order?.serviceItems.forEach((item) =>
        countryShort === "ca"
          ? (() => {
              // console.log('item',item,(1-(discAmount/itemsPrice)));
              const itemPrice = item.price * (1 - discAmount / itemsPrice);
              const GST =
                Math.round(
                  itemPrice *
                    (item.type !== "TutorSession" && countryShort === "ca"
                      ? 0.05
                      : 0) *
                    100
                ) / 100;
              const QST =
                Math.round(
                  itemPrice *
                    (item.type !== "TutorSession" &&
                    countryShort === "ca" &&
                    stateShort === "qc"
                      ? 0.0975
                      : 0) *
                    100
                ) / 100;
              taxes["GST"] += GST;
              taxes["QST"] += QST;
            })()
          : ""
      );
      // console.log('taxes',taxes);
      // const GST =
      //   Math.round(
      //     (itemsPrice - discAmount) * (countryShort === "ca" ? 0.05 : 0) * 100
      //   ) / 100;

      // const QST =
      //   Math.round(
      //     (itemsPrice - discAmount) *
      //       (countryShort === "ca" && stateShort === "qc" ? 0.0975 : 0) *
      //       100
      //   ) / 100;

      const totalPrice =
        Math.round(
          (parseFloat(itemsPrice) +
            parseFloat(order.shippingCost) +
            parseFloat(taxes.GST) +
            parseFloat(taxes.QST) -
            parseFloat(discAmount)) *
            100
        ) / 100;
      const updatedNewPrice = order.newPrice
        ? (() => {
            const cadValue = totalPrice / order.exRate;
            // const currencyDoc = currencies.find(
            //   (cur) => cur.currency === order.newPrice.currency
            // );
            const newTotalPrice =
              Math.round(
                cadValue * currencyDoc?.exRate * currencyDoc?.convertRate * 100
              ) / 100;
            const newVal = {
              totalPrice: newTotalPrice,
              currency: order.newPrice.currency,
            };
            console.log("newVal", newVal);
            return newVal;
          })()
        : undefined;

      setOrder((pre) => ({
        ...pre,
        _id: undefined,
        isPaid: false,
        lcs: serviceItems[0]?.lcs,
        accepted: serviceItems[0]?.accepted,
        currency: serviceItems[0]?.currency,
        currency_symbol: serviceItems[0]?.currency_symbol,
        exRate: serviceItems[0]?.exRate,
        convertRate: serviceItems[0]?.convertRate,
        paymentResult: [],
        productItems: productItems,
        serviceItems,
        itemsPrice,
        taxes,
        discAmount,
        totalPrice: totalPrice,
        newPrice: updatedNewPrice,
      }));
    }
  }, [cartValue(), order.discountType, order?.marketerInfo?.discPercent, id]);
  // console.log(serviceItems);
  // console.log(currencies);
  const applyPromHandle = async (e) => {
    // if (!user.roles?.includes(9999))
    //   return document.getElementById("loginTrigger").click();
    if (!window.logined(9999)) return;

    if (order.promCode.length < 6)
      return messageRef.current.showError({
        message: "کد تخفیف حداقل باید 6 حرف باشد.",
      });
    if (order.itemsPrice <= 0)
      return messageRef.current.showError({ message: "سبد خرید شما خالیه!" });
    e.target.setAttribute("disabled", true);
    setLoading((pre) => ({ ...pre, prom: true }));
    const response = await apiStore.getPromDetails({
      token: user.token,
      promCode: order.promCode,
    });
    setLoading((pre) => ({ ...pre, prom: false }));
    setTimeout(() => e.target.removeAttribute("disabled"), 10000);
    if (response.error)
      return messageRef.current.showError({
        message: "کد تخفیف درست نمیباشد.",
      });
    messageRef.current.showSuccess({
      message: "کد تخفیف با موفقیت اعمال شد.",
    });
    console.log("discount response", response);
    if (response.data.discountType === "percent")
      return setOrder((pre) => ({
        ...pre,
        discPercent: response.data.discPercent,
        promCode: response.data.promCode,
        discountType: "percent",
        prom: response.data._id,
        cycle: response.data.cycle,
        newPrice: undefined,
        walletInfo: undefined,
      }));
    if (response.data.discountType === "fix")
      return setOrder((pre) => ({
        ...pre,
        discAmount: response.data.discAmount,
        promCode: response.data.promCode,
        discountType: "fix",
        prom: response.data._id,
        cycle: response.data.cycle,
        newPrice: undefined,
        walletInfo: undefined,
      }));
  };
  const applyReferalHandle = async (e) => {
    if (!window.logined(9999)) return;

    if (order.refCode.length < 8)
      return messageRef.current.showError({
        message: "کد معرف حداقل باید 8 حرف باشد.",
      });
    if (order.itemsPrice <= 0)
      return messageRef.current.showError({ message: "سبد خرید شما خالیه!" });
    e.target.setAttribute("disabled", true);
    setLoading((pre) => ({ ...pre, refer: true }));
    const response = await apiStore.fetchRefCodeDetails({
      token: user.token,
      refCode: order.refCode,
    });
    setLoading((pre) => ({ ...pre, refer: false }));
    setTimeout(() => e.target.removeAttribute("disabled"), 1000);
    if (response.error)
      return messageRef.current.showError({
        message: "کد معرفی درست نمیباشد.",
      });
    messageRef.current.showSuccess({
      message: "کد معرفی با موفقیت اعمال شد.",
    });
    console.log(response.data);
    setOrder((pre) => ({
      ...pre,
      // discPercent: pre.discPercent+response?.data?.marketerInfo.discPercent,
      marketerInfo: {
        ...response?.data?.marketerInfo,
        marketerId: response.data._id,
      },
      newPrice: undefined,
      walletInfo: undefined,
    }));
  };

  // console.log("order", order);

  return (
    // <div className="Checkout_body">
    <div className="row  mx-md-5 mx-0 ">
      {(loading.createOrder || loading.fetchData) && <ColoredSpiner />}
      <ToastNote messageRef={messageRef} />
      {order?.expiresAt && order?._id && !order?.isPaid && (
        <CountdownBar expirationTime={order.expiresAt} />
      )}
      <div className="col-lg-4 checkout-col p-3">
        {order?._id && order?.isPaid && (
          <div>
            <div title={tools.toSolar(order?.createdAt)}>
              Order Date : {tools.toGreg(order?.createdAt)}
            </div>
            {order.invNumber > 0 ? (
              <div>Invoice Number : {order?.invNumber}</div>
            ) : (
              <div>Order Number : {order?._id}</div>
            )}
          </div>
        )}

        <PriceRender
          order={order}
          countItems={countItems}
          countryDetails={countryDetails}
        />
        {/* ********** promo code ********* */}
        {!order._id && !order.isPaid && (
          <div>
            <div className=" mb-3">
              <div>
                <div className=" divider-small border-0 mb-1 small-gray-font mt-4 ">
                  <span>
                    <img
                      src="/img/bussiness-page/tag.png"
                      className="arrow2 pe-2"
                      alt="facilities"
                      loading="lazy"
                    />
                  </span>
                  <span className="p-0 mb-1 ">کد تخفیف </span>
                </div>
                <div className="d-flex promo-code justify-content-between">
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control text-center"
                    id="promocode"
                    placeholder=" کد تخفیف را این جا وارد کنید "
                    style={{ textTransform: "uppercase" }}
                    onBlur={(e) =>
                      setOrder((pre) => ({
                        ...pre,
                        promCode: e.target.value.toUpperCase(),
                      }))
                    }
                  />
                  <span>
                    <button
                      className="btn login-btn ms-3"
                      onClick={(e) => applyPromHandle(e)}
                    >
                      {loading.prom ? (
                        <CircularProgress color="inherit" size="20px" />
                      ) : (
                        "Apply"
                      )}
                    </button>
                  </span>
                </div>
              </div>
              {/* Referral Code start*/}
              {/* <div>
                <div className=" divider-small border-0 mb-1 small-gray-font mt-4 ">
                  <span>
                    <img
                      src="/img/bussiness-page/tag.png"
                      className="arrow2 pe-2"
                      alt="facilities"
                      loading="lazy"
                    />
                  </span>
                  <span className="p-0 mb-1 ">کد معرف </span>
                </div>
                <div className="d-flex promo-code justify-content-between">
                  <input
                    type="email"
                    autoComplete="off"
                    className="form-control text-center"
                    id="refCode"
                    placeholder=" کد معرف را این جا وارد کنید "
                    // style={{ textTransform: "uppercase" }}
                    onBlur={(e) => {
                      // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      // if (
                      //   e.target.value.length &&
                      //   !emailRegex.test(e.target.value)
                      // )
                      // return messageRef.current.showError({
                      //   message:
                      //     "لطفا در قسمت کد معرفی، ایمیل را به صورت درست وارد نمائید.",
                      // });
                      setOrder((pre) => ({
                        ...pre,
                        refCode: e.target.value,
                      }));
                    }}
                  />
                  <span>
                    <button
                      className="btn login-btn ms-3"
                      onClick={(e) => applyReferalHandle(e)}
                    >
                      {loading.referral ? (
                        <CircularProgress color="inherit" size="20px" />
                      ) : (
                        "Apply"
                      )}
                    </button>
                  </span>
                </div>
              </div> */}
            </div>
            {/* Use Wallet Start*/}
            {user?.wallet > 0 && (
              <div className="userShow form-check form-switch py-3 px-2  mx-0 mx-md-5 text-red-dark bg-beigeGray">
                <input
                  className="form-check-input form-check-input-filter1  m-0 p-0 cursor"
                  type="checkbox"
                  role="switch"
                  id="useWalletSwitch"
                  checked={order?.walletInfo?.isActive}
                  onChange={(e) =>
                    setOrder((pre) => {
                      const newOrder = { ...pre };
                      const cadValue = pre.totalPrice / pre.exRate;
                      const walletAmount =
                        cadValue <= user.wallet ? cadValue : user.wallet;
                      if (newOrder?.walletInfo?.isActive) {
                        newOrder.walletInfo = {
                          ...pre.walletInfo,
                          isActive: false,
                          amount: 0,
                          currency: "",
                          exRate: 1,
                          convertRate: 1,
                        };
                        newOrder.newPrice = undefined;
                      } else {
                        newOrder.walletInfo = {
                          ...pre.walletInfo,
                          isActive: true,
                          amount: walletAmount,
                          currency: "CAD",
                          exRate: 1,
                          convertRate: 1,
                        };
                        if (cadValue > user.wallet) {
                          newOrder.newPrice = {
                            totalPrice: cadValue - user.wallet,
                            currency: "CAD",
                          };
                        }
                      }
                      return newOrder;
                    })
                  }
                />
                <label
                  className="form-check-label ps-2 pe-0 mt-0 d-flex justify-content-between flex-wrap"
                  htmlFor="useWalletSwitch"
                >
                  <span>استفاده از کیف پول</span>
                  <span className="small-gray-font">
                    {tools.showPrice(
                      order?.walletInfo?.amount * countryDetails.exRate,
                      countryDetails?.currency
                    )}
                    <img
                      width="25px"
                      className="ms-2"
                      src={`/img/flags/${countryShort?.toLowerCase()}.svg`}
                      alt={countryShort}
                      title={window.capitalize(country)}
                    />
                  </span>
                </label>
              </div>
            )}
            {/* Use Wallet End*/}

            {order?.totalPrice > 0 &&
              !order?.accepted &&
              (!order?.walletInfo?.isActive ||
                (order?.walletInfo?.isActive &&
                  order?.totalPrice / order.exRate / order.convertRate >
                    user?.wallet)) && (
                <ChangeCurrencyRender
                  currencies={currencies}
                  setOrder={setOrder}
                  order={order}
                  user={user}
                />
              )}

            {order?.newPrice && (
              <div className="p-3 bold-dark-font">
                <span>مبلغ به ارز جایگزین({order?.newPrice?.currency}) :</span>
                <strong>
                  {tools.showPrice(
                    order?.newPrice?.totalPrice,
                    order?.newPrice?.currency
                  )}
                </strong>
              </div>
            )}

            <div className="select-button my-5">
              {countItems() > 0 && (
                <>
                  <button
                    className="btn primary-btn view-cart cart-footer w-100 mx-auto"
                    onClick={emptyCartHandle}
                  >
                    خالی کردن سبد خرید
                  </button>
                  {(!order?.walletInfo?.isActive &&
                    order?.accepted &&
                    order?.totalPrice > 0) ||
                  (order?.walletInfo?.isActive &&
                    order?.totalPrice / order.exRate / order.convertRate >
                      user?.wallet) ||
                  order?.newPrice?.totalPrice > 0 ? (
                    <div
                      className="btn primary-btn checkout-btn cart-footer w-100 mx-auto"
                      onClick={continueHandle}
                    >
                      {loading.createOrder ? (
                        <CircularProgress color="inherit" size="20px" />
                      ) : (
                        " تائید و تکمیل پرداخت"
                      )}
                    </div>
                  ) : (
                    order?.walletInfo?.isActive && (
                      <div
                        className="btn primary-btn checkout-btn cart-footer w-100 mx-auto"
                        onClick={continueHandle}
                      >
                        {loading.createOrder ? (
                          <CircularProgress color="inherit" size="20px" />
                        ) : (
                          "پرداخت از کیف پول"
                        )}
                      </div>
                    )
                  )}
                </>
              )}
            </div>
          </div>
        )}

        {order?._id && !order?.isPaid && order?.totalPrice > 0 && (
          <Paypal order={order} setOrder={setOrder} />
        )}
      </div>
      <div className="col-lg-8 checkout_container p-3">
        <div className="checkout_container">
          <div>
            {/* **********Empty bag************ */}
            {order.productItems.length + order.serviceItems.length <= 0 ? (
              <div className="row m-5 px-0 empty-cart d-flex justify-content-center ">
                <div className="col-md-6 d-flex flex-column  ps-md-5 align-items-center  justify-content-center px-0">
                  <p>سبد خرید شما خالیه!</p>
                  <div
                    className="btn primary-btn btn-404 checkout-btn cart-footer mb-5 mt-2"
                    // to={`/${window.lang}/store/plans`}
                    onClick={(e) =>
                      window.navigateHandle(`/${window.lang}/store/plans`)
                    }
                  >
                    افزودن به سبد خرید
                  </div>
                  <div
                    className="btn primary-btn btn-404 checkout-btn cart-footer bg-pinkOrange cart-footer mb-5 mt-2 "
                    // to={`/${window.lang}/store/plans`}
                    onClick={(e) =>
                      window.navigateHandle(`/${window.lang}/demy/tutor/filters`)
                    }
                  >
                    برو به لیست اساتید
                  </div>
                </div>
                <div className="col-md-6 d-flex align-items-center pe-md-5 ps-md-0 justify-content-center">
                  <img
                    src="/img/empty-cart-2.svg"
                    alt="empty-cart"
                    loading="lazy"
                  />
                </div>
              </div>
            ) : (
              <div>
                {order.productItems?.map((value, i) => {
                  return <CartItems key={i} data={value} />;
                })}
                {order.serviceItems.length > 0 && " سرویس های ویژه :"}
                {order.serviceItems?.map((item, i) => (
                  <ServicesRender
                    key={i}
                    data={item}
                    serviceDecrement={serviceDecrement}
                    lang={lang}
                    order={order}
                    setOrder={setOrder}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  function emptyCartHandle() {
    if (window.confirm("با خالی کردن سبد خرید موافقم.") != true) return;
    cartEmptier();
    messageRef.current.showSuccess({ message: "سبد خرید با موفقیت خالی شد." });
  }
  async function continueHandle(e) {
    e.preventDefault();
    if (!window.logined(9999)) return;
    setLoading((pre) => ({ ...pre, createOrder: true }));
    const response = await apiStore.createOrder({ order, token: user.token });
    setLoading((pre) => ({ ...pre, createOrder: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div> <div>{response.error}</div>
          </div>
        ),
      });

    // const plansX4= order.serviceItems.find(service=> service.type==='plansX4')
    const plans = new Set(["plansX4", "plansX5", "plansS1"]);
    const ads = order.serviceItems.filter((service) => plans.has(service.type));
    console.log("ads", ads.length, ads);
    if (ads.length > 0) {
      console.log("plans", ads);
      const data = ads;
      data.order = response.data._id;
      setLoading((pre) => ({ ...pre, createOrder: true }));
      const responsePlansX4 = await apiStore.createAds({
        token: user.token,
        data,
        orderId: data.order,
        lang,
      });
      setLoading((pre) => ({ ...pre, createOrder: false }));
      if (responsePlansX4.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      console.log("responsePlansX4", responsePlansX4);
    }

    // messageRef.current.showSuccess({message:" با استفاده از منوی زیر پرداخت را کامل کنید."})
    // console.log(response.data);
    cartEmptier();
    if (
      order?.walletInfo?.isActive &&
      order.totalPrice / order.exRate / order.convertRate <=
        order?.walletInfo?.amount
    ) {
      console.log("payWallet Activated");
      const responsePay = await apiStore.payOrder({
        id: response.data._id,
        token: user.token,
        paymentResult: {
          paymentMethod: "wallet",
          orderID: "",
          payerID: "",
          paymentID: "",
          paymentSource: "",
          payerName: "",
          payerFamily: "",
          payerEmail: "",
          status: "",
          amount: {value:order?.walletInfo?.amount ,currency_code:order?.walletInfo?.currency},
          shipping: "",
          paidAT: new Date(),
          descripion: "Paid in full by wallet",
        },
      });
      const response2 = await api.getUser();
      if (response2?.error) return;
      signedUser(response2.data);
      window.navigateHandle(`/${window.lang}/store/order/thanks`);
    }
    // setOrder(response.data)
    navigate(`/${window.lang}/store/order/${response.data._id}`, {
      state: { order: response.data },
    });
  }
}

function ServicesRender({ data, serviceDecrement, lang, order, setOrder }) {
  const planName = tools.renderTrans({ section: "plansName", key: data.type });
  return (
    <div
      dir="ltr"
      className="d-flex"
      style={{
        border: "1px solid #e7e7e7",
        // width: "95%",
        // height: "220px",
        // margin: "25px",
        // display: "flex",
      }}
    >
      {/* *******image****** */}

      <div>
        {/* <Link to={`/${window.lang}/business/page/${data._id}`}> */}
        <img
          className="checkout-cart-img"
          src={data.img}
          alt={data.title}
          loading="lazy"
        />
        {/* </Link> */}
      </div>

      <div className="w-100 d-flex">
        <div className="row w-100 pe-3 pt-2">
          <div className="  col-md-3 col-6">
            <div className=" d-flex flex-column h-100">
              <h6 className="textgap ">{data?.name}</h6>
              <p className=" m-0 mt-2 bold-dark-font">{planName}</p>
              <p className="small-gray-font m-0">{data.title}</p>
            </div>
          </div>
          <div className="col-md-5  display-none-md">
            <p className="text-end pe-3 my-2" dir="ltr">
              <RenderServiceSubItems data={data} />
              {/* <div className="small-gray-font" style={{ fontWeight: "bold" }}>
                1 X ${data.price}
              </div> */}
            </p>
          </div>
          <div className="col-md-3 col-4">
            <div style={{ fontWeight: "bold" }} className="textgap text-center">
              {/* Subtotal:  */}
              {tools.showPrice(data.price, data.currency)}
            </div>
            <div className="px-3"></div>
          </div>
          <div className=" col-md-1 col-2">
            {!order._id && (
              <div className="d-flex flex-column justify-content-between align-items-center h-100 py-3">
                <div
                  // className="bi bi-x-lg btn-cart"
                  className="btn-cart"
                  alt="delete"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        `شما در حال حذف ${
                          planName + " ، " + data.title
                        } از سبد خرید می باشید.`
                      ) !== true
                    )
                      return;
                    serviceDecrement({ _id: data._id, type: data.type });
                    setOrder((pre) => ({
                      ...pre,
                      discAmount: 0,
                      discPercent: 0,
                      promCode: "",
                      discountType: "",
                      refCode: "",
                      prom: undefined,
                      cycle: undefined,
                      newPrice: undefined,
                      walletInfo: undefined,
                    }));

                    document.getElementById("promocode").value = "";
                    // document.getElementById('refCode').value=""
                  }}
                >
                  <MyIcons icon="delete2" />
                </div>
                {/* <i className="ti-close bi bi-x"></i> */}

                {/* <i className="bi bi-heart btn-cart " alt="add to wishlist"></i> */}

                {/* <i className="bi bi-share btn-cart" alt="share "></i> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function RenderServiceSubItems({ data, currency_symbol }) {
  // console.log(data);
  if (data.type === "facilities")
    return data.items.map((x) => (
      <span className="small-gray-font fac"> {x} | </span>
    ));
  if (data.type === "targetAddress")
    return data.items.map((x) => (
      <div className="targetAddress ">
        <div style={{ color: "gray" }}>{x.country} : </div>
        <div>
          {x.states.map((state) => (
            <span className="states small-gray-font">{state} | </span>
          ))}
        </div>
      </div>
    ));
  if (
    data.type === "plansX4" ||
    data.type === "plansX5" ||
    data.type === "plansS1"
  )
    return data.items.map((item) => (
      <PlansX45Prices data={item} currency_symbol={currency_symbol} />
    ));
  if (data.type === "eventTicket")
    return (
      <div className="d-flex flex-column">
        <div className="states small-gray-font">
          {tools.toGreg(data.items[0]?.moreInfo?.dates?.eventDate)}
        </div>
        <div className="states small-gray-font">
          {data.items[0]?.moreInfo?.complex[window.lang]}
        </div>
        <div className="states small-gray-font">{`${data.items[0]?.moreInfo?.address?.country}, ${data.items[0]?.moreInfo?.address?.stateShort}, ${data.items[0]?.moreInfo?.address?.city}`}</div>
        <div className="states small-gray-font">
          {data.items[0]?.moreInfo?.complex[window.lang]}
        </div>
        <div className="states small-gray-font">
          {" row " + data.items[0]?.number}
        </div>
      </div>
    );
  if (data.type === "TutorSession")
    return (
      <div className="d-flex flex-column">
        <span className="small-gray-font">
          {tools.dynamicNumber({ number: data.count, lang: window.lang }) +
            " x " +
            tools.dynamicNumber({
              number: data.price / data.count,
              lang: window.lang,
            })}
        </span>
      </div>
    );
}

function PlansX45Prices({ data, currency_symbol }) {
  const targetCount = data.targetAddress?.reduce(
    (a, c) => a + 1,
    0
  );
  const pages = data.pages?.length || 0;
  const months = data.yearMonth?.length || 0;
  const unitP = data?.price / pages / months / targetCount;
  return (
    <div
      key={`plansX4Render`}
      dir="ltr"
      className="d-flex flex-column justify-content-center align-items-center "
    >
      <div className="d-flex">
        <p className="ad-box-title">اندازه</p>
        <p className="ad-box-title"> صفحه </p>
        <p className="ad-box-title"> استان</p>
        <p className="ad-box-title">ماه</p>
      </div>
      <div className="d-flex">
        <p className="ad-no-box"> {(unitP ?? 0).toLocaleString()}</p>
        <p className="m-0">X</p>
        <p className="ad-no-box">{pages} </p> <p>X</p>
        <p className="ad-no-box"> {targetCount}</p> <p>X</p>
        <p className="ad-no-box">{months}</p>
      </div>
    </div>
  );
}
function PriceRender({ order, countItems, countryDetails }) {
  return (
    <div className="select-total">
      <p className="px-3" dir="ltr">
        جمع ({tools.dynamicNumber({ number: countItems(), lang: window.lang })}{" "}
        آیتم):
        <strong>
          {" "}
          {tools.showPrice(order?.itemsPrice || 0, order?.currency)}
        </strong>
      </p>
      <p
        className="px-3"
        dir="ltr"
        style={{
          color: "gray",
        }}
      >
        هزینه حمل :
        <strong>{tools.showPrice(order?.shippingCost, order?.currency)}</strong>
      </p>
      {order?.taxes.GST > 0 && (
        <p
          className="px-3"
          dir="ltr"
          style={{
            color: "gray",
          }}
        >
          GST Tax :
          <strong> {tools.showPrice(order?.taxes.GST, order?.currency)}</strong>
        </p>
      )}
      {order?.taxes.QST > 0 && (
        <p
          className="px-3"
          dir="ltr"
          style={{
            color: "gray",
          }}
        >
          QST Tax :
          <strong> {tools.showPrice(order?.taxes.QST, order?.currency)}</strong>
        </p>
      )}
      {order?.taxes.HST > 0 && (
        <p
          className="px-3"
          dir="ltr"
          style={{
            color: "gray",
          }}
        >
          HST Tax :
          <strong> {tools.showPrice(order?.taxes.HST, order?.currency)}</strong>
        </p>
      )}
      <p
        className="px-3"
        dir="ltr"
        style={{
          color: "orange",
        }}
      >
        تخفیف{" "}
        {(order.discPercent > 0 || order?.marketerInfo?.discPercent > 0) && (
          <div className="">
            <div>`(%${(order.discPercent ?? 0) * 100})` کد تخفیف</div>
            <div>
              `(%${(order?.marketerInfo?.discPercent ?? 0) * 100})` تخفیف معرفی
            </div>
          </div>
        )}{" "}
        :
        <strong> ({tools.showPrice(order.discAmount, order?.currency)})</strong>
      </p>
      <p className="px-3 bold-dark-font" dir="ltr">
        مبلغ کل :
        <strong className="mx-1">
          {tools.showPrice(order?.totalPrice, order?.currency)}
        </strong>
      </p>
      {order?.walletInfo?.amount > 0 && order?.walletInfo?.isActive && (
        <p className="px-3" dir="ltr" style={{ color: "orange" }}>
          برداشت از کیف پول
          <strong>
            {" "}
            (
            {tools.showPrice(
              order?.walletInfo?.amount *
                countryDetails.exRate *
                countryDetails.convertRate,
              countryDetails?.currency
            )}{" "}
            {countryDetails?.currency !== order?.currency &&
              ` ~ ${tools.showPrice(
                order?.walletInfo?.amount * order?.exRate * order?.convertRate,
                order?.currency
              )}`}{" "}
            )
          </strong>
          <p>
            باقیمانده :{" "}
            {tools.showPrice(
              order?.totalPrice -
                order?.walletInfo?.amount * order?.exRate * order?.convertRate,
              order?.currency
            )}
          </p>
        </p>
      )}
      {order?.newPrice?.totalPrice > 0 && (
        <div>
          <p className="px-3 bold-dark-font green" dir="ltr">
            مبلغ قابل پرداخت ({order?.newPrice?.currency}) :
            <strong className="mx-1">
              {tools.showPrice(
                order?.newPrice?.totalPrice,
                order?.newPrice?.currency
              )}
            </strong>
          </p>
        </div>
      )}

      {order._id && order.isPaid && (
        <button
          title={tools.toSolar(order?.paymentResult[0]?.paidAT)}
          className={`widgetLgButton ${order?.paymentResult[0]?.status}`}
        >
          {order?.paymentResult[0]?.status} :
          {tools.toGreg(order?.paymentResult[0]?.paidAT)}
        </button>
      )}
    </div>
  );
}
// export default Cart;
function ChangeCurrencyRender({ setOrder, order, currencies, user }) {
  function changeCurrencyHandle(e) {
    // console.log(e.target.value);
    const cadValue = Math.ceil(order.totalPrice / order.exRate);
    // console.log(cadValue);
    const currencyDoc = currencies.find(
      (cur) => cur.currency === e.target.value
    );
    setOrder((pre) => {
      const newOrder = { ...pre };
      if (newOrder?.walletInfo?.isActive) {
        const walletAmount = cadValue <= user.wallet ? cadValue : user.wallet;
        console.log("walletAmount00", walletAmount);
        newOrder.newPrice = {
          totalPrice: Math.ceil(
            (cadValue - walletAmount) *
              currencyDoc.exRate *
              currencyDoc.convertRate
          ),
          currency: e.target.value,
        };
        newOrder.walletInfo = {
          ...pre.walletInfo,
          amount: walletAmount,
          currency: "CAD",
          exRate: 1,
          convertRate: 1,
        };
      } else {
        newOrder.newPrice = {
          totalPrice: Math.ceil(
            cadValue * currencyDoc.exRate * currencyDoc.convertRate
          ),
          currency: e.target.value,
        };
      }

      return newOrder;
    });
  }
  return (
    <div className="border p-3 mt-2" style={{ backgroundColor: "white" }}>
      <div className="med-gray-font text-center">
        ارز کشور شما توسط پی پال(Paypal) پشتیبانی نمیشود!
      </div>
      <label
        className="med-gray-font text-center mt-2"
        htmlFor="changeCurrency"
      >
        پرداخت خودرا توسط یکی از ارزهای زیر انجام دهید.
      </label>
      <select
        className="login-btn selectpicker shadow-none w-100"
        id="changeCurrency"
        onChange={changeCurrencyHandle}
        value={order?.newPrice?.currency}
      >
        <option value="">انتخاب ارز جایگزین </option>
        <option value="USD">دلار آمریکا </option>
        <option value="EUR">یورو</option>
        <option value="CAD">دلار کانادا</option>
        {currencies.map(
          (cur, i) =>
            cur.currency !== "USD" &&
            cur.currency !== "EUR" &&
            cur.currency !== "CAD" && (
              <option key={i} value={cur.currency}>
                {cur.currency_name}{" "}
              </option>
            )
        )}
      </select>
    </div>
  );
}
