import React, { Component } from "react";
import Slider from "react-slick";
// https://react-slick.neostack.com/docs/api/#draggable
import 'slick-carousel/slick/slick.css';
import "slick-carousel/slick/slick-theme.css";
import BusinessCard from "../../screens/business/components/businessCard";
export default class BusCardSlider extends Component {
  
  constructor(props) {
    super(props);   
  }


  render() {
    console.log('this.props',this.props);
    
    const PrevButton = ({ onClick }) => (
      <button className="slick-prev" onClick={onClick}>Previous</button>
    );
    
    const NextButton = ({ onClick }) => (
      <button className="slick-next" onClick={onClick}>Next</button>
    );
    var settings = {
      dots: true,
      infinite: true,
      // autoplay:true,
      // fade:true,
      rtl:true,
      swipe:true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      vertical: false,
      prevArrow: <PrevButton />,
      nextArrow: <NextButton />,
      
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    

    
    return (
      <div  >       
        <Slider {...settings}>
        {this.props.data.map((bus, i) => (  <BusinessCard key={i} data={bus} location={'slider'}  />))}
        </Slider>
      </div>
    );
  }
}