import { Link } from "react-router-dom";
import renderData from "../../../../utils/renderData";
import MetaDecorator from "../../../../utils/metaDecorator";

export default function Disclaimer() {
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "درباره متامارس",
      description:
        "یک پلتفرم آنلاین پویا که برای جامعه پارسی‌زبان در سراسر جهان طراحی شده ...",

      url: "https://www.metamarce.com/fa/prompt/disclaimer",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/prompt/disclaimer`,
              name: "متامارس | رفع مسئولیت",
              description:
                "بیانیه رفع مسئولیت | شرایط استفاده توضیح میدهد که چگونه از داد های وبسایت استفاده کنید.",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: "متامارس | رفع مسئولیت",
    description:
      "بیانیه رفع مسئولیت | شرایط استفاده توضیح میدهد که چگونه از داد های وبسایت استفاده کنید.",
    section: "رفع مسئولیت",
    url: `https://www.metamarce.com/${window.lang}/prompt/disclaimer`,
    canonical: `https://www.metamarce.com/${window.lang}/prompt/disclaimer`,

    img: "https://www.metamarce.com/img/illustration/pair-programming-animate.svg",
    type: "webpage",
  };

  return (
    <div className="mx-md-5 mx-2">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h1 className="titr1 font-40 my-5">بیانیه رفع مسئولیت</h1>
        <div>
          <p>
            متامارس، یک بستر جهت انتشار محتوای اشتراکی است .
            <br />
            به این نکته توجه داشته باشید که تمام داده‌ها و محتوایی که توسط
            کاربران ارائه می‌شود، توسط متامارس ارزیابی، بررسی یا تأیید نمی‌شود.
            کاربران به تنهایی مسئول اطلاعات یا محتوایی هستند که منتشر میکنند .
            ما درستی یا کامل بودن هر گونه محتوای موجود در متامارس، از جمله
            اطلاعات و فعالیت کسب و کارها، فرصت های شغلی و مقالات مجله را تضمین و
            تائید نمی‌کنیم. هریک از کاربران در زمان استفاده از آنها باید درستی
            آنها را به صورت مستقل مورد بررسی و ارزیابی قرار دهد و هنگام اعتماد
            به محتوای تولیدی دیگران، نیازها، شرایط و ترجیحات خود را مد نظر داشته
            باشد.
          </p>

          <p>
            متامارس مسئول هیچ گونه از دست رفتگی، ناراحتی، صدمه یا آسیبی که از
            استفاده از اطلاعات یا محتوای ارائه شده توسط کاربران ناشی می‌شود
            نیست. شما می توانید با استفاده از به اشتراک گذاری نتایح ارزیابی‌های
            خود، دیگر کاربران را نسبت به تجربه خود آگاه کنید و در جهت ایجاد یک
            فضای ایمن کمک کنید. ما به شدت کاربران را تشویق می کنیم که هر گونه
            نقص یا مشکل را گزارش دهند و ما بر اساس گزارش‌های ارائه شده اقدامات
            مناسب را انجام خواهیم داد.
          </p>

          <p>
            با استفاده از متامارس، شما توافق خود را برای شرایط استفاده از
            متامارس اعلام می‌کنید.
          </p>
          <p>
            متامارس هیچ گونه مسئولیتی در مورد تبعات ناشی از هر گونه استفاده
            نادرست و غیرحرفه‌ای از مضامین مطالب را بر عهده نمی گیرد.
          </p>
        </div>

        <Link
          className="btn primary-btn btn-404 checkout-btn my-5"
          onClick={(e) => window.scroll(0, 0)}
          to={`/${window.lang}/prompt/privacy`}
        >
          سیاست حفظ حریم خصوصی
        </Link>

        {/* <div
          className="btn primary-btn btn-404 checkout-btn cart-footer mb-5 mt-2 text-center"
          onClick={(e) => window.close()}
        >
          بستن
        </div> */}
      </div>
    </div>
  );
}
