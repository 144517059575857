import { Route } from "react-router-dom";
import DemyHome from "../components/screens/demy/demyHome";
// import DemyPage from "../components/screens/demy/CenterPage";
import ProgramFiltersEn from "../components/screens/demy/filters/programFiltersEn";
import CenterFilters from "../components/screens/demy/filters/centerFilters";
import CenterPage from "../components/screens/demy/CenterPage";
import TutorFilters from "../components/screens/demy/filters/tutorFilters";
import TutorPage from "../components/screens/demy/tutorPage";
import DemyTutorHome from "../components/screens/demy/demyTutorHome";
import TutorRegister from "../components/screens/demy/register/TutorRegister";
import ProgramPageEn from "../components/screens/demy/programPageEn";
// import TutorPageEn from "../components/screens/demy/tutorPageEn";
// import DemyTutorHomeEn from "../components/screens/demy/demyTutorHomeEn";
// import TutorFiltersEn from "../components/screens/demy/filters/tutorFiltersEn";
// import DemyHomeEn from "../components/screens/demy/demyHomeEn";
export default function demyRoutes() {
  return (
    <>
      <Route path="" element={<DemyHome />} />
      <Route path="program/page/:id" element={<ProgramPageEn />} />
      <Route path="program/filters" element={<ProgramFiltersEn />} />
      <Route path="center/page/:id" element={<CenterPage />} />      
      <Route path="center/filters" element={<CenterFilters />} />
      <Route path="tutor/page/:id" element={<TutorPage />} />      
      <Route path="tutor/filters" element={<TutorFilters />} />
      <Route path="tutor/register" element={<TutorRegister />} />
      <Route path="tutor/home" element={<DemyTutorHome />} />
    </>
  );
}
