import "./user.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useRef, useState } from "react";
import { CartContext } from "../../../../../contexts/cartContext";
import ToastNote from "../../../../../golbal/notifications/toastNote";
import ColoredSpiner from "../../../../../alret/spiners/coloredSpiner/coloredSpinner";
import { CheckCircle, Error } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import Tooltip1 from "../../../../../golbal/tooltip/tooltip";
import FormikErrRender from "../../../../../persian/common/alerts/formikErrRender";
import addErrClass from "../../../../../persian/common/alerts/addErrClass";
import { useNavigate } from "react-router-dom";
import apiUsers from "../../../../../../utils/apiUsers";
import tools from "../../../../../../utils/tools";
import api from "../../../../../../utils/api";
import MetaDecorator from "../../../../../../utils/metaDecorator";

export default function MarketerInfo({ user2 }) {
  const { countryShort } = tools.getItemFromLocalStorage("initSetup", {});

  const navigate = useNavigate();
  const messageRef = useRef(null);
  const { user, signedUser } = useContext(CartContext);
  const [loading, setLoading] = useState({ spinner: false });

  useEffect(() => {
    if (!user2?.marketerInfo) fetch();
  }, []);

  async function fetch() {
    console.log("fetch");
    setLoading({ ...loading, spinner: true });
    const response = await apiUsers.getMarketerInfo({
      id: user._id,
      token: user.token,
    });
    setLoading({ ...loading, spinner: false });
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    formik.setValues((pre) => ({
      ...pre,
      roles: response.data?.roles,
      terms: response.data?.marketerInfo?.terms,
      marType: response.data?.marketerInfo?.marType,
      refCode: response.data?.marketerInfo?.refCode,
      dates: response.data?.marketerInfo?.dates,
      marType: response.data?.marketerInfo?.marType,
      discPercent: response.data?.marketerInfo?.discPercent,
      refPercent: response.data?.marketerInfo?.refPercent,
      _id: user?._id,
    }));

    if (document.querySelector("#termsContent"))
      document.querySelector("#termsContent").style.display = response.data
        ?.terms
        ? "none"
        : "block";
  }

  const formik = useFormik({
    initialValues: {
      terms: user2?.marketerInfo?.terms || false,
      marType: user2?.user?.marType || "visitor",
      discPercent: 0,
      refPercent: 0.2,
      refCode: user2?.user?.refCode || "",
      dates: {
        start: user2?.user?.dates.start || "",
        end: user2?.user?.dates.end || "",
      },
      _id: user2?._id || "",
    },
    onSubmit: async (values) => {
      console.log(values._id);
      console.log(user._id);
      setLoading({ ...loading, spinner: true, submitBtn: true });
      const response = await apiUsers.setMarketerInfo({
        id: values._id,
        token: user.token,
        marketerInfo: values,
      });
      setLoading({ ...loading, spinner: false, submitBtn: false });
      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      messageRef.current.showSuccess({
        message: "تبریک، بخش بازاریابی با موفقیت به روز رسانی شد.",
      });
      formik.setValues({
        roles: response.data?.roles,
        terms: response?.data?.marketerInfo?.terms,
        marType: response?.data?.marketerInfo?.marType,
        refCode: response?.data?.marketerInfo?.refCode,
        dates: response?.data?.marketerInfo?.dates,
        discPercent: response?.data?.marketerInfo?.discPercent,
        refPercent: response?.data?.marketerInfo?.refPercent,
        _id: response?.data?._id,
      });
      setLoading({ ...loading, spinner: true });
      const response2 = await api.getUser();
      setLoading({ ...loading, spinner: false });
      if (response2?.error) return;
      signedUser(response2.data);
    },
    validationSchema: Yup.object({
      terms: Yup.boolean().oneOf(
        [true],
        " قوانین بازاریابی را مطالعه و دکمه قبول را فشار دهید."
      ),
      marType: Yup.string()
        .oneOf(["visitor", "influ"])
        .required("لطفا نوع کد معرفی خود را انتخاب نمائید."),
      dates: Yup.object().shape({
        // start: Yup.date().required("تاریخ شروع را وارد کنید."),
        // end: Yup.date()
        //   .test(
        //     "is-after-today",
        //     "لطفا پس از اتمام اعتبار کد معرفی، دوباره اقدام نمائید.",
        //     function (value) {
        //       const today = new Date();
        //       return value && value <= today;
        //     }
        //   ),
      }),
    }),
  });
  console.log("formik", formik.values);
  const metaTagsData = {
    title: "متامارس | داشبورد | پروفایل بازاریابی ",
    description: "پروفایل بازاریابی | به روز رسانی اطلاعات بازاریابی ",
    section: "MarketerInfo",
    url: `${window.location.origin}/${window.lang}/dashboard/user/profile/marketer`,
    img: "https://www.metamarce.com/img/illustration/buy-photography-gear-online.svg",
    type: "webpage",
    index: false,
  };
  return (
    <div className="user mx-md-5 mt-3">
      <MetaDecorator data={{ ...metaTagsData }} />
      {loading.spinner && <ColoredSpiner />}
      <ToastNote messageRef={messageRef} />

      <div className="row ">
        <div className="col">
          <p className="divider2 w-75 mt-2 ">
             
            <svg
              className="text-dark-550 dark:text-white ml-2"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
              <circle
                cx="35"
                cy="16"
                r="8"
                fill="currentColor"
                fillOpacity="0.4"
              ></circle>
              <circle
                cx="20"
                cy="8"
                r="5"
                fill="currentColor"
                fillOpacity="0.7"
              ></circle>
            </svg>
            <span className="p-0 m-z ">
              پروفایل من به عنوان همکار بازاریابی در متامارس
            </span>
          </p>
        </div>
      </div>

      <form
        onSubmit={formik.handleSubmit}
        className="row userShow"
        name="authorNameForm"
        autoComplete="true"
      >
        {/* Right Section */}
        <div className="col-md-6 px-3">
          <div>
            کد معرفی شما :<strong> {formik.values.refCode} </strong>
          </div>
          <div>
            شروع اعتبار :
            <strong>
              {tools.dynamicDate({
                date: formik.values.dates.start,
                countryShort,
                lang: window.lang,
                showYear: true,
                short: true,
              })}
            </strong>
            <FormikErrRender formik={formik} field="dates.start" />
          </div>
          <div>
            پایان اعتبار :
            <strong>
              {tools.dynamicDate({
                date: formik.values.dates.end,
                countryShort,
                lang: window.lang,
                showYear: true,
                short: true,
              })}
            </strong>
            <FormikErrRender formik={formik} field="dates.end" />
          </div>

          <div className="d-flex">
            <div>
              <div>
                <div>
                  {" "}
                  سود بازاریابی : {"%" + formik.values.refPercent * 100}
                </div>
                <div>
                  {" "}
                  تخفیف به کاربر : {"%" + formik.values.discPercent * 100}
                </div>
              </div>
              <div>نوع کد معرفی :</div>
              <ul className="flex-column">
                <li>
                  <input
                    className="form-check-input"
                    type="radio"
                    id="visitorBtn"
                    value="visitor"
                    name="marType"
                    checked={formik.values.marType === "visitor"}
                    onChange={(e) =>
                      formik.setValues((pre) => ({
                        ...pre,
                        marType: e.target.value,
                        discPercent: 0,
                        refPercent: 0.2,
                      }))
                    }
                    required
                  />
                  <label htmlFor="visitorBtn">بازاریابی</label>
                </li>
                <li>
                  <input
                    className="form-check-input"
                    type="radio"
                    id="influBtn"
                    value="influ"
                    name="marType"
                    checked={formik.values.marType === "influ"}
                    onChange={(e) =>
                      formik.setValues((pre) => ({
                        ...pre,
                        marType: e.target.value,
                        discPercent: 0.1,
                        refPercent: 0.1,
                      }))
                    }
                  />
                  <label htmlFor="influBtn">اینفلوئنسری</label>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Left Section */}

        <div className="userUpdateItem col-md-6 px-3">
          <input
            style={{ display: "none" }}
            className="form-check-input"
            id="termsConditions"
            type="checkbox"
            onChange={(e) =>
              (document.querySelector("#termsContent").style.display = e.target
                .checked
                ? "block"
                : "none")
            }
          />
          <label
            className=" terms-con ps-3 mb-2 mt-5"
            htmlFor="termsConditions"
          >
            قوانین متامارس برای همکاری بازاریابی
          </label>

          <div
            id="termsContent"
            className="userUpdateInput form-control userUpdateInputTextarea"
            dir="rtl"
          >
            قوانین و شرایط خدمات برای ایجاد کد بازار یابی
            <br />
            <p>
              <strong>1. مقدمه:</strong>
              <br />
              1.1 با شرکت در برنامه بازاریابی ما، شما موافقت می‌کنید که با این
              شرایط و مقررات رعایت کنید.
            </p>
            <p>
              <strong>2. اهلیت:</strong>
              2.1 بازاریابان باید به سن قانونی در حوزه خود رسیده باشند.
            </p>
            <p>
              <strong>3. فعالیت‌های بازاریابی:</strong>
              <br />
              3.1 بازاریابان مسئول معرفی متامارس و خدمات آن به صورت حرفه‌ای و
              اخلاقی هستند و بایستی از وعده های غیر واقعی و خارج از چهارچوب
              متامارس پرهیز نمایند.
              <br />
              3.2 هر گونه محتوای بازاریابی باید با قوانین و مقررات کانادا مطابقت
              داشته باشد.
              <br />
              3.3 از فعالیت‌های ممنوع شامل فرستادن اسپم، تبلیغات گمراه‌کننده و
              شرکت در فعالیت‌های غیرقانونی خودداری شود.
              <br />
              3.4 بازاریابان مکلف به رعایت حقوق نسبت یکدیگر هستند و بایستی از
              هرگونه تداخل دوری کنند، اصل مرجعیت با بازاریابی است که زودتر
              پرداخت آن تکمیل شده باشد .
              <br />
              3.5 در صورت عدم حل فصل تداخل بین بازاریان متامارس حق دارد براساس
              قوانین این آئین نامه عمل و حساب یک و یا تمامی طرفین تداخل را حذف
              نماید
            </p>
            {/* ... (continue for the remaining points) */}
            <p>
              <strong>4. قانون حاکم:</strong>
              <br />
              4.1 این شرایط و مقررات توسط قوانین کانادا و استان کبک حاکم
              می‌شوند.
              <br />
              4.2 پیش از صدور کد بازاریابی در انتخاب نوع کد معرفی خود دقت لازم
              را بعمل آورید، تغییر یا دریافت کد جدید تنها پس از پایان اعتبار کد
              فعلی امکان پذیر میباشد.
              <br />
              4.3 کد معرفی یکتا و تنها یکبار صادر خواهد شد
            </p>
            <p>
              <strong>5. محاسبه حق الزحمه و پرداخت:</strong>
              <br />
              5.1 محاسبه حق الزحمه بازاریابان تاپایان (ژوئن30) شش ماهه اول سال
              2024 بیست درصد از مبلغ فروش خالص قبل از مالیات میباشد
              <br />
              5.2 پرداخت های متامارس از طریق سیستم بانکی کانادا انجام خواهد شد و
              بازاریابان مکلف به ارائه اطلاعات و مستندات مورد نیاز جهت واریز حق
              الزحمه خود میباشند.
            </p>
            <p>
              <strong>6. پذیرش توافق:</strong>
              <br />
              6.1بازاریابان بایستی اطلاعات ضروری را با دقت تکمیل و تمامی مدارک
              مورد نیاز جهت احراز هویت و پرداخت مطابق قوانین کشور کانادا را
              ارائه نمائید.
              <br />
              6.2 پذیرش توافق یکی از شرایط همکاری با ما است.
            </p>
          </div>
          <label
            htmlFor="termsBtn"
            className={`required my-3 mb-5 ${addErrClass({
              formik,
              field: "terms",
            })} `}
          >
            قوانین را مطالعه کردم و می‌پذیرم
            <input
              id="termsBtn"
              type="checkbox"
              className="form-check-input mx-2"
              onChange={(e) => {
                if (formik.values.terms) return;
                formik.setValues((pre) => ({ ...pre, terms: true }));
              }}
              checked={formik.values.terms}
            />
            <FormikErrRender formik={formik} field="terms" />
          </label>
        </div>
        <SubmitBtn formik={formik} text="بفرست!" />
      </form>
    </div>
  );

  function SubmitBtn({ text, formik }) {
    let textBtn = "بفرست";
    // if (formik.values?.roles?.includes(1106)) textBtn = "ویرایش";
    return (
      <button
        disabled={formik.isSubmitting}
        className="my-1 login-btn login-btn2 "
        style={{
          backgroundColor: Object.keys(formik.errors)?.length > 0 && "grey",
        }}
        type="submit"
        id="createBusBtn"
      >
        {textBtn}
        {loading.spinner ? (
          <CircularProgress color="inherit" size="20px" />
        ) : (
          Object.keys(formik.errors)?.length === 0 && <CheckCircle />
        )}
        <span>
          {Object.keys(formik.errors)?.length > 0 && (
            <Tooltip1
              message=" موارد ستاره دار را کامل کنید."
              icon={<Error sx={{ fontSize: 18, color: "red" }} />}
              position="left"
            />
          )}
        </span>
      </button>
    );
  }
}
