import renderData from "../../../../utils/renderData";
import MetaDecorator from "../../../../utils/metaDecorator";
import { useContext } from "react";
import { CartContext } from "../../../contexts/cartContext";

export default function StudentsGuide() {
  const { user } = useContext(CartContext);

  return (
    <div className="d-flex align-items-start">
      <div className="tab-content" id="v-pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="v-pills-registerUser"
          role="tabpanel"
          aria-labelledby="v-pills-registerUser-tab"
        >
          <div className="content__container">
            <h1 className="titr7 text-start text-gray-green">
              مراحل ثبت‌نام و چگونگی استفاده از کلاس‌های متادمی{" "}
            </h1>

            <div className=" ">
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                  ۱
                </div>
                <span>ورود یا ساخت حساب کاربری در متامارس </span>
                {!user?.roles?.includes(9999) && (
                  <strong
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalToggle"
                    role="button"
                  >
                    (اینجا)
                  </strong>
                )}
              </div>

              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow mx-2 mt-1">
                  ۲
                </div>
                <span> جستجو بین دروس و اساتید مختلف و یافتن کلاس مناسب </span>
                <a
                  href={`/${window.lang}/demy/tutor/filters`}
                  className="content__link bold-700 ms-2"
                >
                  <strong>(اینجا)</strong>
                </a>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow mx-2 mt-1 mt-1 mt-1">
                  ۳
                </div>
                <span></span>
                انتخاب زمان و ساعات جلسات از بین لیست جلسات خالی در تقویم تدریس
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow mx-2 mt-1 mt-1">
                  ۴
                </div>
                <span>تائید و انتقال جلسات به سبد خرید</span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow mx-2 mt-1">
                  ۵
                </div>
                <span>
                  پرداخت از درگاه امن پی پال و از طریق کارت های اعتباری(مستر ،
                  ویزا و ... ) ویا مستقیم از حساب خود
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow mx-2 mt-1">
                  ۶
                </div>
                <span>دریافت صورتحساب</span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow mx-2 mt-1">
                  ۷
                </div>
                <span>دریافت ایمیل تائید استاد</span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow mx-2 mt-1">
                  ۸
                </div>
                <span>دریافت ایمیل یاددآوری یکساعت مانده به کلاس</span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-pinkOrange text-beigeYellow mx-2 mt-1">
                  ۹
                </div>
                <span>
                  دریافت لینک ورود به کلاس نیم ساعت مانده به کلاس ایمیل
                </span>
              </div>
            </div>

            <div>
              <div>
                <div className="">
                  <p className=" ">
                    همچنین پس از درخواست جلسه آزمایشی و یا جلسه آموزشی استاد
                    مدنظر به لیست کاربران در چت متامارس اضافه خواهد شد و به صورت
                    مستقیم میتوانید با استاد خود در ارتباط باشید.
                  </p>
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
