import { useState, useRef, useEffect, useContext } from "react";
import {
 
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import api from "../../../../utils/apiJobs";
import Pagination from "../../../golbal/pagination/pagination";
// import Search from "../../../persian/common/pagination/searchPagination";
import renderData from "../../../../utils/renderData";
import SearchMultiSelect from "../../../golbal/dropdown/searchMultiSelectFilters";
import { CartContext } from "../../../contexts/cartContext";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import PlansX4 from "../../../golbal/sliders/plansX4";
import BusSmallCardSlider from "../../../golbal/sliders/busCardSliderS1";
import MainSidebar from "../../media/components/mainSidebar";
import tools from "../../../../utils/tools";
import FiltersCatsSelector from "../../../golbal/filtersCatsSelector";
import MetaDecorator from "../../../../utils/metaDecorator";
// import TutorCard from "../components/tutorCard";
import DropdownSearch from "../../../golbal/dropdown/DropdownSearch";
import countryStates from "../../../../utils/countryStates";
import demyData from "../../../../utils/demyData";

import ReadyCard from "../components/readyCard";
import NewPosts2x2 from "../../../golbal/sliders/components/newPosts2x2";
import BlogSearch from "../../../persian/common/pagination/searchPagination";

export default function ReadyFilters() {
  const navigate = useNavigate();
  const section = "ready";
  const page = "readyFilters";
  const { lang, state:{state,stateShort,lat,lng}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
  const { user } = useContext(CartContext);
  const [pubData] = useOutletContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const [resultsTotal, setResultsTotal] = useState(0);
  const [loading, setLoading] = useState({ fetchBus: false });
  const [states, setStates] = useState([]);
  const [data, setData] = useState([]);

  const initialFilters = {
    lang,
    country: "",
    countryShort: "",
    states: [],
    state: "",
    stateShort: "",
    cities: [],
    mainCatId: "",
    subCat: [],
    subCatId: [],
    img: "",
    linkedin: "",
    searchKey: "",
    tag: "",
    education: [],
    languages: [],
    emType: [],
    // serviceTypes: [],
    pageNumber: 1,
    itemsToShow: 12,
    sort: "default",
  };
  const storedFilters = JSON.parse(localStorage.getItem("storedFilters"))?.[
    section
  ];
  const [filters, setFilters] = useState(tools.filtersInitiate(initialFilters,storedFilters));

  const [backFilters, setBackFilters] = useState({ ...filters });

  console.log("backFilters", backFilters);

  const effectRan = useRef(true);

  useEffect(() => {
    if (effectRan.current) return;
    filtersFunction()
  }, [filters, window.lcs]);
  function storeFilters({ section, filters }) {
    const storedFilters =
      JSON.parse(localStorage.getItem("storedFilters")) ?? {};
    storedFilters[section] = filters;
    localStorage.setItem("storedFilters", JSON.stringify(storedFilters));
    return;
  }

  useEffect(() => {
    // const keys = Array.from(searchParams.keys())
    const params = {};
    for (let [key, value] of searchParams) {
      params[key] = value;
    }
    countrySet(countryShort, "first");
    if (Object.keys(params).length > 0) {
      setFilters((pre) => ({
        ...pre,
        lang,
        country: "",
        mainCat: params?.mainCat ?? pre.mainCat,
        mainCatId: params?.mainCatId ?? pre.mainCatId,
        subCat: params?.subCat ? [params?.subCat] : pre.subCat,
        subCatId: params?.subCatId ? [params?.subCatId] : pre.subCatId,
        cities: params?.cities ? [params?.cities] : pre.cities,
        searchKey: params?.searchKey ?? pre.searchKey,
        tag: params?.tag ?? pre.tag,

        // serviceTypes: params?.serviceTypes ? [params?.serviceTypes] : pre.serviceTypes,
        // tutorLevel: params?.tutorLevel ? [params?.tutorLevel] : pre.tutorLevel,
        // availableTime: params?.availableTime ? [params?.availableTime] : pre.availableTime,
        // availableDay: params?.availableDay ? [params?.availableDay] : pre.availableDay,
        // busId: params?.busId ? params?.busId : pre.busId,
        // busName: params?.busName ? params?.busName : pre.busName,
        searchKey: pre.searchKey,
        tag: params?.tag ? params?.tag : pre.tag,
      }));
    }    
    effectRan.current = false;
  }, [searchParams]);
  function filtersFunction(){
    console.log('useEffectRun01','filterFunction');    
    fetchData();
    setBackFilters({ ...filters });
    storeFilters({ section, filters });
  }

  function queryRemover(field) {
    console.log(field);
    searchParams?.delete(field);
    // searchParams.set(field,"")
    const searchString = searchParams?.toString();
    setFilters((pre) => {
      const newFilters = { ...pre };
      delete newFilters[field];
      return newFilters;
    });
    navigate(`?${searchString}`);
  }

  async function fetchData() {
    setLoading((pre) => ({
      ...pre,
      fetchBus: true,
    }));
    const response = await api.filtersReady({ filters });
    setLoading((pre) => ({
      ...pre,
      fetchBus: false,
    }));
    if (response?.error) return alert(response?.error);
    setResultsTotal(response.data.resultsTotal);
    setData(response.data.data);
  }

  async function searchHandle(e) {
    e.preventDefault();
    const value = e.target.parentElement.querySelector("input").value;

    filters.searchKey = value;
    if (e.key === "Enter" || e.type === "click") {
      setFilters((prev) => ({
        ...prev,
        searchKey: value,
        pageNumber: 1,
      }));
    }
  }
  const itemsToShowHandle = async (itemsToS) => {
    setFilters((prev) => ({
      ...prev,
      itemsToShow: itemsToS,
      pageNumber: 1,
    }));
  };
  const pageNumberHandle = (PageN) => {
    setFilters((prev) => ({
      ...prev,
      pageNumber: PageN,
    }));
  };

  function deleteFilter(e) {
    const field = e.currentTarget.id;
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key] = "";
              if (key === "mainCat") {
                newFilters.mainCatId = "";
                newFilters.subCat = [];
                newFilters.subCatId = [];
                queryRemover("mainCatId");
              }
              if (key === "country") {
                newFilters.countryShort = "";
              }
              newFilters.pageNumber = 1;
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }

    // effectRan.current = false;
    setFilters(update());
  }

  function deleteArrayFilter(e) {
    const field = e.currentTarget.id.split(",")[0];
    const index = e.currentTarget.id.split(",")[1];
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key].splice(index, 1);
              newFilters.pageNumber = 1;
              if (key === "subCat") {
                newFilters.subCatId.splice(index, 1);
                queryRemover("subCatId");
              }
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }
    setFilters(update());
  }

  const selectedFiltersRender = (() => {
    let rows = [];
    for (let key in filters) {
      if (Array.isArray(filters[key]))
        filters[key].map((option, i) => {
          // console.log(key, renderData[key], option);
          if (
            key !== "states" &&
            key !== "cities" &&
            key !== "subCat" &&
            key !== "subCatId"
          ) {
            const translate = (renderData[key] || demyData[key]).find(
              (option1) => option1.name == option
            );
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                <span>{translate?.[window.lang]} </span>
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }

          if (key === "states" || key === "cities" || key === "subCat") {
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                <span>{option} </span>
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }
        });

      !Array.isArray(filters[key]) &&
        key !== "lang" &&
        key !== "countryShort" &&
        key !== "stateShort" &&
        key !== "state" &&
        key !== "section" &&
        key !== "sort" &&
        key !== "pageNumber:" &&
        key !== "itemsToShow" &&
        key !== "mainCatId" &&
        filters[key]?.length &&
        rows.push(
          <div className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center">
            <span>
              {key === "tag" && "# "}
              {filters[key]}
            </span>
            <span className="ms-3 me-2" id={key} onClick={deleteFilter}>
              <i className="bi bi-x-lg"></i>
            </span>
          </div>
        );
    }
    return rows;
  })();

  function setCitiesHandle(value) {
    setFilters((prev) => ({ ...prev, cities: value, pageNumber: 1 }));
    // effectRan.current = false;
  }
  // const { translations, states: states0 } =
  //   JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = `لیست فریلنسر ها و افراد آماده همکاری`;
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "نیروی کار",
      url: `https://www.metamarce.com/${window.lang}/jobs/ready/filters`,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `خانه`,
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/mp`,
              name: ` نیازمندیها`,
              description: `نیازمندیهای ایرانیان در سراسر دنیا`,
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/jobs/ready/filters`,
              name: title,
            },
          },
        ],
      },
      // about: {
      //   "@type": "Place",
      //   name: state?.toUpperCase(),
      //   address: {
      //     "@type": "PostalAddress",
      //     // "addressLocality": "City",
      //     addressRegion: state?.toUpperCase(),
      //     // "postalCode": "Postal Code",
      //     addressCountry: country?.toUpperCase(),
      //   },
      //   geo: {
      //     "@type": "GeoCoordinates",
      //     latitude: lat, // Replace with the actual latitude
      //     longitude: lng, // Replace with the actual longitude
      //   },
      // },
    },
  ];
  const metaTagsData = {
    title,
    description: `معرفی نیروی کار و جستجو در لیست آماده به کار`,
    section: "فیلتر کارجویان",
    url: `https://www.metamarce.com/${window.lang}/jobs/ready/filters`,
    img: "https://www.metamarce.com/img/illustration/man-with-join-us-sign-for-open-recruitment.svg",
    type: "website",
  };
  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      {loading.fetchBus && <ColoredSpiner />}
      {/* <!-- *********************************** main ******************************************************************** --> */}

      <section className="mx-auto ads-all py-0">
       
        <div className="row blog px-md-4 p-0 py-1">
          {/* <!-- **************Right side ************** --> */}
          <div className="col-lg-8 entries  ">
            {/* <!-- ************** MAP ************** --> */}
            <a id="top"> </a>

            {/* <!-- **************Filters  ************** --> */}
            <div className=" d-flex flex-wrap x-3 justify-content-between my-2 border-0 ">
              <div className="d-flex">
                <span className="pt-2" title="نمایش ذخیره ها">
                  <i
                    className={`bi ${
                      filters.saved ? "bi-bookmark-fill" : "bi-bookmark"
                    } icon-stack-1x cursor`}
                    onClick={(e) => {
                      if (!window.logined(9999)) return;
                      if (user?.saved?.[section]?.length <= 0)
                        return alert("شما تاکنون آیتم ذخیره شده ای ندارین.");
                      setFilters((pre) => ({
                        ...initialFilters,
                        saved: !pre.saved,
                        section,
                      }));
                    }}
                  ></i>
                </span>
                {resultsTotal !== 0 && (
                  <span className="box4 py-1 px-2 mx-md-2 mx-0">
                    {resultsTotal} یافته
                  </span>
                )}
                <div className="blog-search ">
                  <BlogSearch searchHandle={searchHandle} />
                </div>
              </div>
              <div className="d-flex">
                <div
                  className="btn btn-primary addbusiness-btn my-2 me-2 pt-2"
                  onClick={(e) => {
                    if (!window.logined(9999, section)) {
                      return window.navigateHandle(
                        `/${window.lang}/jobs/ready/register`
                      );
                    }
                    window.navigateHandle(
                      `/${window.lang}/dashboard/user/jobs/registerReady`
                    );
                  }}
                >
                  <i className="bi bi-geo me-1"></i>
                  افزودن پروفایل
                   {/* آماده همکاری */}
                </div>
                <button
                  className="btn btn-primary button-filter me-md-3 me-0 ms-auto my-2"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarTogglerDemo02"
                  aria-controls="navbarTogglerDemo02"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="bi bi-filter me-1"> فیلترها</i>
                </button>
              </div>
              <div
                className="collapse navbar-collapse "
                id="navbarTogglerDemo02"
              >
                <div className="d-flex flex-wrap mt-3">
                  {/* <!-- Button filter 1 *******************************--> */}
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn  button-filter dropdown-toggle"
                      data-bs-toggle="dropdown"
                      // id="#filter5"
                      aria-expanded="false"
                      // aria-controls="filter5"
                    >
                      <span>
                        <i className="bi bi-arrow-down-up me-1"></i>
                      </span>
                      مرتب کن
                      {/* {filters.sort !== "default" && <CheckBox />} */}
                      <span className="ps-3">
                        <i className="bi bi-chevron-down"></i>
                      </span>
                    </button>

                    {/* <!-- Modal --> */}

                    <div className="dropdown-menu checkbox-form px-0 ">
                      <div className=" ">
                        <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={filters.sort === "default"}
                            onChange={(e) => {
                              setFilters((prev) => ({
                                ...prev,
                                sort: "default",
                                pageNumber: 1,
                              }));
                              // effectRan.current = false;
                            }}
                          />
                          <label
                            className="form-check-label "
                            htmlFor="flexRadioDefault1"
                          >
                            پیش فرض
                          </label>
                        </div>

                        <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            checked={filters.sort === "dateB"}
                            onChange={(e) => {
                              // effectRan.current = false;
                              setFilters((prev) => ({
                                ...prev,
                                sort: "dateB",
                                pageNumber: 1,
                              }));
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            تازه‌ترین
                          </label>
                        </div>

                        <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault4"
                            checked={filters.sort === "viewsB"}
                            onChange={(e) => {
                              // effectRan.current = false;
                              setFilters((prev) => ({
                                ...prev,
                                sort: "viewsB",
                                pageNumber: 1,
                              }));
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault4"
                          >
                            بازدید
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Button country 2 *******************************--> */}
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn button-filter dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="bi bi-geo-alt me-1"></i>
                      </span>
                      کشور
                      <span className="ps-3">
                        <i className="bi bi-chevron-down"></i>
                      </span>
                    </button>

                    {/* <!-- Modal --> */}

                    <div className="dropdown-menu checkbox-form">
                      <DropdownSearch updateParent={countrySet} />
                    </div>
                  </div>

                  {/* <!-- Button State 2.5 *******************************--> */}
                  {filters.country?.length > 0 && (
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn button-filter dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span>
                          <i className="bi bi-geo-alt me-1"></i>
                        </span>
                        استان
                        <span className="ps-3">
                          <i className="bi bi-chevron-down"></i>
                        </span>
                      </button>

                      {/* <!-- Modal --> */}

                      <div className="dropdown-menu px-0 checkbox-form">
                        <div
                          className="reset-btn w-100"
                          onClick={(e) => {
                            setFilters((prev) => ({
                              ...prev,
                              states: [],
                              state: "",
                              stateShort: "",
                              pageNumber: 1,
                            }));
                          }}
                        >
                          پاک کردن
                        </div>
                        {states?.map((state, i) => (
                          <div
                            key={"states" + i}
                            className="form-check d-flex justify-content-between flex-row-reverse px-3"
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              // value={state.name?.toLowerCase()}
                              onChange={(e) => {
                                const states = filters?.states
                                  ? [...filters.states]
                                  : [];
                                const state1 = { stateShort: "", state: "" };
                                const value = state.state;
                                console.log("value01", value);
                                console.log("states02", states);
                                const index = states.indexOf(value);
                                if (index !== -1) {
                                  states.splice(index, 1);
                                  const contry = countryStates.find(
                                    (count) =>
                                      count.countryShort ===
                                      filters.countryShort.toUpperCase()
                                  );
                                  console.log("contry03", contry);
                                  // const stateShort = contry.states.find(
                                  //   (state) => {
                                  //     console.log('state.state === states[0]',state.state , states[0]);
                                  //     return state.state === states[0]}
                                  // ).stateShort;
                                  const stateShort = countryStates.find(
                                    (count) =>
                                      count.countryShort ===
                                      filters.countryShort
                                  );
                                  if (states.length === 1) {
                                    state1.stateShort = stateShort;
                                    state1.state = states[0];
                                  } else {
                                    state1.stateShort = "";
                                    state1.state = "";
                                  }
                                } else {
                                  states.push(value);
                                  if (states.length === 1) {
                                    state1.stateShort = state.stateShort;
                                    state1.state = value;
                                  } else {
                                    state1.stateShort = "";
                                    state1.state = "";
                                  }
                                }
                                setFilters((pre) => ({
                                  ...pre,
                                  states,
                                  state: state1.state,
                                  stateShort: state1.stateShort,
                                }));
                              }}
                              checked={filters.states?.includes(state.state)}
                              id={`statesCheckBox${i}`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`statesCheckBox${i}`}
                            >
                              {state?.state}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* <!-- Button City 3 *******************************--> */}
                  {filters.states.length === 1 &&
                    filters?.state?.length > 0 && (
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn button-filter dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span>
                            <i className="bi bi-geo-alt me-1"></i>
                          </span>
                          شهر/ محله
                          <span className="ps-3">
                            <i className="bi bi-chevron-down"></i>
                          </span>
                        </button>

                        {/* <!-- Modal --> */}

                        <div className="dropdown-menu checkbox-form">
                          <SearchMultiSelect
                            updateParent={setCitiesHandle}
                            data={{
                              countryShort: filters.countryShort,
                              stateShort: filters.stateShort,
                              cities: filters.cities,
                            }}
                          />
                        </div>
                      </div>
                    )}

                  {/* <!-- Button filter 3 *******************************--> */}
                  <FiltersCatsSelector
                    section={"jobs"}
                    filters={filters}
                    setFilters={setFilters}
                  />

                  {/* <!-- Button filter 5 *******************************--> */}
                  <div className="dropdown">
                    <button
                      className="btn button-filter"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasWithBothOptionsFilters"
                      aria-controls="offcanvasWithBothOptionsFilters"
                    >
                      <span>
                        <i className="bi bi-filter me-1"></i>
                      </span>
                      فیلتر بیشتر
                      <span className="ps-3">
                        <i className="bi bi-chevron-down"></i>
                      </span>
                    </button>

                    <div
                      className="offcanvas offcanvas-end offcanvas-countryLanguage "
                      data-bs-scroll="true"
                      tabIndex="-1"
                      id="offcanvasWithBothOptionsFilters"
                      aria-labelledby="offcanvasWithBothOptionsLabelFilters"
                    >
                      <div className="offcanvas-header offcanvas-countryLanguageTitle ">
                        <h5
                          className="offcanvas-title "
                          id="offcanvasWithBothOptionsLabelFilters"
                        >
                          فیلترهای بیشتر
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        ></button>
                      </div>

                      <div className="offcanvas-footer  text-center px-5 py-4">
                        <button
                          className="btn  checkout-btn  text-center w-100 mb-2 py-2"
                          type="button"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                          onClick={(e) => {
                            navigate('')
                            setFilters(initialFilters)
                            // removing all url queries
                          }}
                        >
                          پاک کردن فیلترها
                        </button>
                        <button
                          className="btn  view-cart text-center w-100 py-2"
                          type="button"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                          onClick={(e) => {
                            // effectRan.current = false;
                            backFilters.pageNumber = 1;
                            setFilters(backFilters);
                          }}
                        >
                          نمایش موارد
                        </button>
                      </div>

                      <div className="offcanvas-body px-0 pb-3 pt-0">
                        <div
                          className="accordion w-100 p-0 m-0"
                          id="accordionPanelsStayOpenBusinessFilters"
                        >
                          {/* <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpenBusinessFilters-headingZero"
                      >
                        <button
                          className="accordion-button collapsed accordion-button-business-filter"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpenBusinessFilters-collapseZero"
                          aria-expanded="false"
                          aria-controls="panelsStayOpenBusinessFilters-collapseZero"
                        >
                          <p className="form-titr2">شیف های کاری </p>
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpenBusinessFilters-collapseZero"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpenBusinessFilters-headingZero"
                      >
                        <div className="accordion-body form-font px-5  row row-cols-2">
                          {renderData.workShifts.map((ws, i) => (
                            <div key={i} className="form-check m-0 py-0 px-1">
                              <input
                                className="form-check-input form-check-input-filter1 m-0 p-0"
                                type="checkbox"
                                // role="switch"
                                name="workShifts"
                                value={ws.name}
                                id={`workShifts,${i}`}
                                checked={backFilters?.workShifts?.includes(
                                  ws.name
                                )}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const workShifts = backFilters.workShifts
                                    ? [...backFilters.workShifts]
                                    : [];
                                  const index = workShifts.indexOf(value);
                                  if (index !== -1) {
                                    workShifts.splice(index, 1);
                                  } else {
                                    workShifts.push(ws.name);
                                  }
                                  setBackFilters((prev) => ({
                                    ...prev,
                                    workShifts,
                                  }));
                                }}
                              />
                              <label
                                className="form-check-label px-2"
                                htmlFor={`workShifts,${i}`}
                              >
                                {ws?.[window.lang]}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div> */}

                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="panelsStayOpenBusinessFilters-headingOne"
                            >
                              <button
                                className="accordion-button collapsed accordion-button-business-filter "
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpenBusinessFilters-collapseOne"
                                aria-expanded="false"
                                aria-controls="panelsStayOpenBusinessFilters-collapseOne"
                              >
                                <p className="form-titr2">
                                  {/* <span>
                              <i className="bi bi-circle-fill "></i>
                            </span> */}
                                  زبان
                                </p>
                              </button>
                            </h2>
                            <div
                              id="panelsStayOpenBusinessFilters-collapseOne"
                              className="accordion-collapse collapse"
                              aria-labelledby="panelsStayOpenBusinessFilters-headingOne"
                            >
                              <div className="accordion-body form-font px-5  row row-cols-3 ">
                                {renderData.languages.map((lang, i) =>
                                  !lang.fa.length ? (
                                    ""
                                  ) : (
                                    <div key={i} className="morelang-line">
                                      <input
                                        className="form-check-input  red moreLang"
                                        type="checkbox"
                                        value={lang.name}
                                        checked={backFilters.languages?.includes(
                                          lang.name
                                        )}
                                        id={`flexLanguages${i}`}
                                        onChange={(e) => {
                                          const languages =
                                            backFilters?.languages
                                              ? [...backFilters.languages]
                                              : [];
                                          const value = e.target.value;
                                          const index =
                                            languages.indexOf(value);
                                          if (index !== -1) {
                                            languages.splice(index, 1);
                                          } else {
                                            languages.push(value);
                                          }
                                          setBackFilters((pre) => ({
                                            ...pre,
                                            languages,
                                          }));
                                        }}
                                      />
                                      <label
                                        className="form-check-label ps-2 red moreLang"
                                        htmlFor={`flexLanguages${i}`}
                                      >
                                        {lang[window.lang]}
                                      </label>
                                    </div>
                                  )
                                )}

                                <button
                                  className="btn button-filter py-0 mt-3"
                                  type="button"
                                >
                                  <label htmlFor="moreLangCheckbox">
                                    {" "}
                                    بیشتر...{" "}
                                  </label>
                                  {/* <span>
                              <i className="bi bi-three-dots ps-2 py-0 my-0"></i>
                            </span> */}
                                  <input
                                    id="moreLangCheckbox"
                                    type="checkbox"
                                    onClick={(e) => {
                                      const elemet =
                                        document.querySelectorAll(".moreLang");
                                      if (e.target.checked) {
                                        document.querySelector(
                                          "label[for=moreLangCheckbox]"
                                        ).innerHTML = "کمتر...";
                                        Array.from(elemet).map(
                                          (el) =>
                                            (el.style.display = "inline-block")
                                        );
                                      } else {
                                        document.querySelector(
                                          "label[for=moreLangCheckbox]"
                                        ).innerHTML = "بیشتر...";
                                        Array.from(elemet).map(
                                          (el) => (el.style.display = "none")
                                        );
                                      }
                                    }}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="panelsStayOpenBusinessFilters-headingTwo"
                            >
                              <button
                                className="accordion-button collapsed accordion-button-business-filter"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpenBusinessFilters-collapseTwo"
                                aria-expanded="false"
                                aria-controls="panelsStayOpenBusinessFilters-collapseTwo"
                              >
                                <p className="form-titr2">
                                  {/* <span>
                              <i className="bi bi-circle-fill "></i>
                            </span> */}
                                  نوع همکاری
                                </p>
                              </button>
                            </h2>
                            <div
                              id="panelsStayOpenBusinessFilters-collapseTwo"
                              className="accordion-collapse collapse"
                              aria-labelledby="panelsStayOpenBusinessFilters-headingTwo"
                            >
                              <div className="accordion-body form-font px-5">
                                {renderData.emType.map((em, i) => (
                                  <div key={i} className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={em.name}
                                      checked={backFilters.emType?.includes(
                                        em.name
                                      )}
                                      id={`checkBoxEmType${i}`}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        const emType = backFilters.emType
                                          ? [...backFilters.emType]
                                          : []; // Create a shallow copy of the array
                                        console.log("emType", emType);
                                        const index =
                                          backFilters.emType.indexOf(value);
                                        if (index !== -1) {
                                          emType.splice(index, 1);
                                        } else {
                                          // Add the value if it's not selected
                                          emType.push(value);
                                        }
                                        // backFilters.emType=emType
                                        setBackFilters((pre) => ({
                                          ...pre,
                                          emType,
                                        }));
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`checkBoxEmType${i}`}
                                    >
                                      {em[lang]}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="panelsStayOpenBusinessFilters-heading-six"
                            >
                              <button
                                className="accordion-button collapsed accordion-button-business-filter"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpenBusinessFilters-collapse-six"
                                aria-expanded="false"
                                aria-controls="panelsStayOpenBusinessFilters-collapse-six"
                              >
                                <p className="form-titr2"> امکانات دیگر</p>
                              </button>
                            </h2>
                            <div
                              id="panelsStayOpenBusinessFilters-collapse-six"
                              className="accordion-collapse collapse"
                              aria-labelledby="panelsStayOpenBusinessFilters-heading-six"
                            >
                              <div className="accordion-body form-font px-5">
                                <div className="form-check form-switch m-0 py-0 px-1">
                                  <input
                                    className="form-check-input form-check-input-filter1 m-0 p-0"
                                    type="checkbox"
                                    role="switch"
                                    name="img"
                                    // value={'tr.name'}
                                    id={`imgBackF`}
                                    checked={backFilters?.img}
                                    onChange={(e) => {
                                      setBackFilters((prev) => ({
                                        ...prev,
                                        img: prev?.img?.length
                                          ? ""
                                          : "دارای عکس",
                                      }));
                                    }}
                                  />
                                  <label
                                    className="form-check-label px-2"
                                    htmlFor={`imgBackF`}
                                  >
                                    دارای عکس
                                  </label>
                                </div>
                                <div className="form-check form-switch m-0 py-2 px-1">
                                  <input
                                    className="form-check-input form-check-input-filter1 m-0 p-0"
                                    type="checkbox"
                                    role="switch"
                                    name="linkedin"
                                    // value={'tr.name'}
                                    id={`linkedinbackF`}
                                    checked={backFilters?.linkedin}
                                    onChange={(e) => {
                                      setBackFilters((prev) => ({
                                        ...prev,
                                        linkedin: prev?.linkedin?.length
                                          ? ""
                                          : "دارای لینکدین",
                                      }));
                                    }}
                                  />
                                  <label
                                    className="form-check-label px-2"
                                    htmlFor={`linkedinbackF`}
                                  >
                                    دارای لینکدین
                                  </label>
                                </div>
                                {/* <div className="form-check form-switch m-0 py-2 px-1">
                            <input
                              className="form-check-input form-check-input-filter1 m-0 p-0"
                              type="checkbox"
                              role="switch"
                              name="freeSession"
                              // value={'tr.name'}
                              id={`freeSessionBackF`}
                              checked={backFilters?.freeSession}
                              onChange={(e) => {
                                setBackFilters((prev) => ({
                                  ...prev,
                                  freeSession: prev.freeSession?.length
                                    ? ""
                                    : "جلسه آزمایشی",
                                }));
                              }}
                            />
                            <label
                              className="form-check-label px-2"
                              htmlFor={`freeSessionBackF`}
                            >
                              جلسه آزمایشی
                            </label>
                          </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* ***************** */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" d-flex flex-wrap my-2 show-filters ">
              {selectedFiltersRender}
              {selectedFiltersRender.length > 0 && (
                <div
                  className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center img-banner-text-7 px-3 filter-del-btn"
                  onClick={(e) => {
                    // removing all url queries
                    navigate('')
                    setFilters(initialFilters)
                  }}
                >
                  <span>پاک کردن فیلترها</span>
                </div>
              )}
            </div>

            {/* <!-- Pagination *******************************--> */}
            <div className="mx-1 p-0 ">
              <div className="g-2 mb-3">
                <div className="text-sm-center text-md-center text-lg-start ">
                  <Pagination
                    data={{ resultsTotal, itemsToShow: filters.itemsToShow }}
                    setPageNumber={pageNumberHandle}
                    pageNumber={filters.pageNumber}
                    setItemsToShow={itemsToShowHandle}
                    position="top"
                  />
                </div>
              </div>

              <div>
                {loading.fetchBus ? (
                  <img src="/img/searching-error.svg" alt="Searching..." />
                ) : (
                  data?.length <= 0 && (
                    <div className="row m-5 px-md-5 px-0">
                      <div className="col-md-6 d-flex align-items-center justify-content-center px-0">
                        داده ای پیدا نشد، لطفا فیلتر های دیگر را امتحان کنید !
                        <div
                          className="btn-search shadow-none m-2 d-flex  align-items-center filter-del-btn img-banner-text-7"
                          onClick={(e) =>
                            document.querySelector("#lcsChangeBtn").click()
                          }
                        >
                          دیگر استانها یا کشورها
                        </div>
                      </div>
                      <div className="col-md-6 d-flex align-items-center justify-content-center">
                        <img
                          src="/img/cart-is-empty.svg"
                          alt="cart-is-empty"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  )
                )}
                {/* <!-- ************** Cards************** --> */}

                <div
                  className="row row-cols-1 
              g-4"
                >
                  {data?.map((job, i) => (
                    <ReadyCard data={job} />
                  ))}
                </div>
              </div>

              <div className="mt-5 ">
                <Pagination
                  data={{ resultsTotal, itemsToShow: filters.itemsToShow }}
                  setPageNumber={pageNumberHandle}
                  pageNumber={filters.pageNumber}
                  position="down"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4  sidebar-all align-items-center text-center  p-md-4 p-1">
            {/* <!-- ********left side *************************************************************** --> */}

            <MainSidebar section={section} />
          </div>
        </div>
        {/* <!-- *********** end of listing ******* --> */}
      </section>

      <section className="mx-md-5 pt-0 mx-0 p-0">
        {/* <!-- تخفیف‌های ویژه --> */}
        <div className="divider2 ">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 ">برگزیدگان</span>
        </div>

        <BusSmallCardSlider plansS1={pubData?.ads?.plansS1} page={page} />

        <div className="divider2 mb-0">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 ">معرفی ویژه</span>
        </div>

        <PlansX4 plansX4={pubData.ads?.plansX4} page={page} />
      </section>

      {/* <!-- تازه‌های بلاگ  --> */}

      <div className=" mx-5 ">
        <div className="divider2 ">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
          </svg>
          <span className="p-0 mb-0 ">تازه‌های بلاگ</span>
        </div>
        <div className="row row-cols-1 row-cols-md-2 g-4 my-5 ads-all mx-auto ">
          {pubData?.newPosts?.length > 0 && (
            <NewPosts2x2 newPosts={pubData.newPosts} user={user} />
          )}
        </div>
      </div>

      {/* <!-- *********************************** end of main ******************************************************************** --> */}

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>

      {/* <!-- **********end of back to top************************* --> */}
    </div>
  );

  // function NewPosts2x2back({ newPosts, user }) {
  //   // console.log(newPosts);
  //   const redirectHandle = (data) => {
  //     window.scrollTo({ top: 0, smooth: true });
  //     navigate(`/${window.lang}/blog/post/${data._id}`, {
  //       state: { data, newPosts },
  //     });
  //   };

  //   return (
  //     <>
  //       {newPosts?.slice(0, 4)?.map((data, i) => (
  //         <div className="col ">
  //           <div
  //             key={i}
  //             className="card mb-3"
  //             onClick={(e) => redirectHandle(data)}
  //           >
  //             <div className="row g-0">
  //               <div className="col-lg-4 ">
  //                 <div className="image-container ">
  //                   <img
  //                     src={data.img}
  //                     className="img-fluid rounded-start w-100  img-card-h "
  //                     alt="..."
  //                     loading="lazy"
  //                   />
  //                 </div>
  //               </div>
  //               <div className={`col-lg-8 small-card${i + 1}`}>
  //                 <div className="card-body card-h">
  //                   <h5 className="card-title">
  //                     {data.title.substring(0, 200)}
  //                   </h5>
  //                   <p className="card-text">
  //                     {/* {data.title.substring(30,200)} */}
  //                   </p>
  //                   <p className="card-text"dir="ltr">
  //                     <small
  //                       className="text-muted"
  //                       title={tools.toSolar(data.createdAt)}
  //                     >
  //                      {tools.timeAgo(data.createdAt)}
  //                     </small>
  //                   </p>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       ))}
  //     </>
  //   );
  // }
  function countrySet(countryShort, first) {
    const coun = countryStates.find(
      (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    );
    setStates(coun.states);
    setFilters((prev) => ({
      ...prev,
      country: coun.country,
      countryShort: countryShort.toLowerCase(),
      state: first ? prev.state : "",
      stateShort: first ? prev.stateShort : "",
      states: first ? prev.states : [],
      cities: first ? prev.cities : [],
    }));
  }
}
