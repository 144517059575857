import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { CartContext } from "../../../../contexts/cartContext";

export default function UserDashDemyNav() {
  const { user } = useContext(CartContext);
  const showEdit = window.location.pathname.split("/").slice(7, 8).toString();
  const trans={
   
    register:{fa:"ثبت‌نام",en:"Register"},
    student:{fa:"دانشجو",en:"Student"},
    teacher:{fa:"استاد",en:"Teacher"},
    teaching:{fa:"استاد",en:"Teaching"},
    sessions:{fa:"کلاس‌ها",en:"Sessions"},
    myProfile:{fa:"پروفایل‌های",en:"My Profiles"},
    report:{fa:"گزارش",en:"Report"},
    financial:{fa:"مالی",en:"Financial"},
    edit:{fa:"مالی",en:"Edit"},
   
  
  }
  return (
    <div className="dashboardNavigator d-flex flex-wrap my-2">
      <NavLink
        to={`/${window.lang}/dashboard/user/demy/registertutor`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
         {trans.register[window.lang]}       
      </NavLink>
      {/* <NavLink to={`/${window.lang}/dashboard/user/demy/listrequests`} className={`sidebarListItem m-1 link  ${({isActive})=>{return isActive && "active" }}`}> لیست ایمیل ها</NavLink> */}
      <NavLink
        to={`/${window.lang}/dashboard/user/demy/listmysessions`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {" "}
        {trans.student[window.lang]}  <span className="small-gray-font font-10 my-2">({trans.sessions[window.lang]})</span>
      </NavLink>
      {user?.roles?.includes(1505) && (
        <>
          <NavLink
            to={`/${window.lang}/dashboard/user/demy/listsessions`}
            className={`sidebarListItem m-1 link  ${({ isActive }) => {
              return isActive && "active";
            }}`}
          >
            {trans.teacher[window.lang]}
            <span className="small-gray-font font-10 my-2">({trans.sessions[window.lang]})</span>
          </NavLink>
          <NavLink
            to={`/${window.lang}/dashboard/user/demy/myTutorlist`}
            className={`sidebarListItem m-1 link  ${({ isActive }) => {
              return isActive && "active";
            }}`}
          >
            {trans.myProfile[window.lang]}
            <span className="small-gray-font font-10 my-2">( {trans.teaching[window.lang]})</span>
          </NavLink>
          <NavLink
            to={`/${window.lang}/dashboard/user/demy/tutortranslist`}
            className={`sidebarListItem m-1 link  ${({ isActive }) => {
              return isActive && "active";
            }}`}
          >
             {trans.report[window.lang]} <span className="small-gray-font font-10 my-2">({trans.financial[window.lang]})</span>
          </NavLink>
        </>
      )}
      {showEdit === "edit" && (
        <NavLink
          to={`/${window.lang}/dashboard/user/demy/edittutor`}
          className={`sidebarListItem m-1 link  ${({ isActive }) => {
            return isActive && "active";
          }}`}
        >
           {trans.edit[window.lang]}
        </NavLink>
      )}
    </div>
  );
}
