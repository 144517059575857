import { Link } from "react-router-dom";
import renderData from "../../../../utils/renderData";
import MetaDecorator from "../../../../utils/metaDecorator";

export default function DisclaimerEn() {
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "Disclaimer",
      description:
        "Disclaimer statement",

      url: "https://www.metamarce.com/fa/prompt/disclaimer",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "landing",
              description: "A bridge between teachers and students around the world",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/prompt/disclaimer`,
              name: "Disclaimer",
              description:
                "Disclaimer",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: "Disclaimer",
    description:
      "Disclaimer statement",
    section: "Disclaimer",
    url: `https://www.metamarce.com/${window.lang}/prompt/disclaimer`,
    canonical: `https://www.metamarce.com/${window.lang}/prompt/disclaimer`,

    img: "https://www.metamarce.com/img/illustration/pair-programming-animate.svg",
    type: "webpage",
  };

  return (
    <div className="mx-md-5 mx-2">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h1 className="titr1 font-40 my-5">Disclaimer statement
        </h1>
        <div>
          <p>
          Metamarce is a platform for sharing content.
            <br />
Note that all data and content provided by users is not evaluated, reviewed or verified by Metamarce. Users are solely responsible for the information or content they publish. We do not guarantee or endorse the accuracy or completeness of any content on Metamarce, including business information and activities, job opportunities, and magazine articles. Each user should check and evaluate their correctness independently when using them and consider their needs, conditions and preferences when trusting the content produced by others.


          </p>

          <p>
Metamarce is not responsible for any loss, inconvenience, injury or damage that results from the use of information or content provided by users. By sharing the results of your evaluations, you can inform other users about your experience and help create a safe environment. We strongly encourage users to report any defects or problems and we will take appropriate action based on the reports provided.


          </p>

          <p>By using Metamarce, you agree to Metamarce Terms of Use.


          </p>
          <p>Metamarce does not assume any responsibility for the consequences of any incorrect and unprofessional use of the contents.


          </p>
        </div>

        <Link
          className="btn primary-btn btn-404 checkout-btn my-5"
          onClick={(e) => window.scroll(0, 0)}
          to={`/${window.lang}/prompt/privacy`}
        >
          Privacy Policy
        </Link>

        {/* <div
          className="btn primary-btn btn-404 checkout-btn cart-footer mb-5 mt-2 text-center"
          onClick={(e) => window.close()}
        >
          بستن
        </div> */}
      </div>
    </div>
  );
}
