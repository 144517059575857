import React from 'react'
import renderData from '../../../utils/renderData';
import FormikErrRender from './alerts/formikErrRender';
import addErrClass from './alerts/addErrClass';

export default function LangsLevel({ formik }) {
  const trans={   
    select:{fa:"انتخاب کنید:",en:"Select:"},
    delete:{fa:"پاک کردن",en:"Delete"},
    add:{fa:"افزودن",en:"Add"},
   
    }
    return (
      <div className="table " id="maintable3">
        {formik.values?.languages.map((distance, i) => (
          <div key={i} className="mb-2 mx-2 ">
            <div className='d-flex justify-content-start' id={i} >
              <select
                // className=" login-btn  selectpicker shadow-none w-30"
                className={`login-btn  selectpicker shadow-none w-30 ${addErrClass({
                  formik,
                  field: `languages.${i}.name`,
                })}`}
                aria-label="Default select example"
                name={distance + i}
                style={{
                  borderTopRightRadius: "20px",
                  bordeBbottomRightRadius: "20px",
                  fontSize: "small",
                }}
                {...formik.getFieldProps(`languages[${i}].name`)}
              >
                <option value="" >{trans.select[window.lang]}</option>
                {renderData.languages?.filter(la=>la.fa.length).map((lang, i) => (
                  <option key={i} value={lang.name}>
                    {lang[window.lang]}
                  </option>
                ))}
              </select>
              <select
                // className=" login-btn  selectpicker shadow-none w-30"
                className={`login-btn  selectpicker shadow-none w-30 ${addErrClass({
                  formik,
                  field: `languages.${i}.level`,
                })}`}
                aria-label="Default select example"
                name={distance + i}
                style={{
                  borderTopRightRadius: "20px",
                  bordeBbottomRightRadius: "20px",
                  fontSize: "small",
                }}
                {...formik.getFieldProps(`languages[${i}].level`)}
              >
                <option value="" >{trans.select[window.lang]}</option>
                {renderData.langsLevel?.map((level, i) => (
                  <option key={i} value={level.name}>
                    {level[window.lang]}
                  </option>
                ))}
              </select>
              <button
                type="button"
                style={{visibility:i > 0?"visible":"hidden"}}
                className="deleteContact btn btn login-trigger3 btn-xs mt-2 mx-2"
                title={trans.delete[window.lang]}

                onClick={() => removeHandle(i)}
              >
                <i
                  className="bi bi-x-circle-fill p-0 "
                  style={{ fontSize: "1.5em" }}
                ></i>
              </button>
            </div>
           
              <FormikErrRender
                formik={formik}
                field={`languages.${i}.name`}
              />
              <FormikErrRender formik={formik} field={`languages.${i}.level`} />
            {/* {i > 0 && ( */}
              {/* <button
                type="button"
                style={{visibility:i > 0?"visible":"hidden"}}
                className="deleteContact btn btn login-trigger3 btn-xs mt-2 mx-2"
                title="پاک کردن"
                onClick={() => removeHandle(i)}
              >
                <i
                  className="bi bi-x-circle-fill p-0 "
                  style={{ fontSize: "1.5em" }}
                ></i>
              </button> */}
            {/* )} */}
          </div>
        ))}
  
        {!formik.errors.languages && (
          <div
          // className='border-top border-secondary'
          >
            <div
              className="btn btn-xs shadow-none login-trigger2  "
              title={trans.add[window.lang]}
            >
              <i
                className="bi bi-plus-circle-fill "
                style={{ fontSize: "1.5em" }}
                onClick={addLevelHandle}
              ></i>
            </div>
          </div>
        )}
      </div>
    );
  
    function addLevelHandle() {
      formik.setValues((prev) => {
        const newF = { ...prev };
        const index = newF.languages.length;
        newF.languages.push({name:"",level:""});
        return newF;
      });
    }
  
    function removeHandle(index) {
      console.log("removeHandle");
      formik.setValues((prev) => {
        const newFormik = { ...prev };
        newFormik.languages.splice(index, 1);
        return newFormik;
      });
    }
    
  }
