import { Component, createContext } from "react";

export const CartContext = createContext({});

class CartContextProvider extends Component {
  constructor(props) {
    super(props);
    // const existingCart =
    //   localStorage.getItem("myProductsCart") != 'undefined'
    //     ? JSON.parse(localStorage.getItem("myProductsCart"))
    //     : [];
    // const currentServiceItems =
    //   localStorage.getItem("myServicesCart") != 'undefined'
    //     ? JSON.parse(localStorage.getItem("myServicesCart"))
    //     : [];
    // const existingUser =
    //   localStorage.getItem("user") != 'undefined'
    //     ? JSON.parse(localStorage.getItem("user"))
    //     : {};
    // const existingShipping =
    //   localStorage.getItem("shippingAddress") != 'undefined'
    //     ? JSON.parse(localStorage.getItem("shippingAddress"))
    //     : [];
    
    this.state = {
      productItems: this.getItemFromLocalStorage("myProductsCart", []),
      // item:[],
      serviceItems:this.getItemFromLocalStorage("myServicesCart", []),
      shipTo: this.getItemFromLocalStorage("shippingAddress", []),
      user: this.getItemFromLocalStorage("user", {}),
      
      shippingAddress: this.shippingHandler,
      paymentType: this.paymentHandler,
      countItems: this.count,
      subtotal: this.subtotalHandler,
      increment: this.addToCart,
      serviceIncrement: this.addServiceToCart,
      decrementItem: this.decrementHandler,
      serviceDecrement: this.serviceDecrementHandle,
      signedUser: this.currentUser,
      cartEmptier: this.EmptierHandler,
      userEmptier: this.userEmptierHandler,
      saveToLocalCash: () => localStorage.setItem("myProductsCart", JSON.stringify(this.state.productItems)),
      // setBusCat:(businessCat)=>this.setState({businessCat}),
      // setBlogCat:(blogCat)=>this.setState({blogCat}),
      // setCategories:(categories)=>this.setState({categories})
    }
  }

  getItemFromLocalStorage(key, defaultValue) {
    const item = localStorage.getItem(key);
    return (item && item !== 'undefined') ? JSON.parse(item) : defaultValue;
}
  

  currentUser = async (user) => {
    console.log("signedUser", user);
    localStorage.setItem("user", JSON.stringify(user));
    this.setState({ user: user });
  };

  userEmptierHandler = async (user) => {
    console.log("userEmptierHandler", user);
    localStorage.removeItem('user')
    this.setState({user:[]})
};

  shippingHandler = ({ fullName, address, city, postalCode, country }) => {
    const shipTo = { fullName, address, city, postalCode, country };
    // console.log(shipTo);
    this.setState({ shipTo: shipTo });
    localStorage.setItem("shippingAddress", JSON.stringify(shipTo));
  };

  // paymentHandler= async(paymentMethod)=>{
  //     console.log(paymentMethod);
  //     this.setState({paymentMethod : paymentMethod})
  //     localStorage.setItem('paymentMethod' , JSON.stringify(paymentMethod))
  // }

  decrementHandler = (_id) => {
    const updatedOrder = this.state.productItems.filter((item) => item._id !== _id);
    localStorage.setItem("myProductsCart", JSON.stringify(updatedOrder));
    this.setState({ productItems: updatedOrder });
  };
  serviceDecrementHandle = ({_id , type}) => {
    // console.log(_id , type);   
    

    const updatedOrder = this.state.serviceItems?.filter((x) => !(x.type === type&& x._id === _id))
 
    localStorage.setItem("myServicesCart", JSON.stringify(updatedOrder));
    this.setState({ serviceItems: updatedOrder })

  };

  EmptierHandler = () => {
    console.log('EmptierHandler');
    localStorage.removeItem("myProductsCart");
    localStorage.removeItem("myServicesCart");
    localStorage.removeItem("paymentMethod");
    this.setState({ productItems: [],serviceItems:[] });
  };


  addToCart = (value) => {
    const itemList = this.state.productItems;
    const existItem = itemList?.length
      ? itemList.find((x) => x._id === value._id)
      : "";
    if (existItem) {
      const cartItems = itemList.map((x) => (x._id === value._id ? value : x));
      localStorage.setItem("myProductsCart", JSON.stringify(cartItems));
      this.setState({ productItems: cartItems });
    } else {
      const cartItems = [...itemList, value];
      localStorage.setItem("myProductsCart", JSON.stringify(cartItems));
      this.setState({ productItems: cartItems });
      //  this.setState({size : this.state.productItems.length})
    }
  };
  addServiceToCart =async (value) => {
    // console.log('value',value._id);
    // const currentCart = this.state.serviceItems;   
    const currentCart = JSON.parse( localStorage.getItem("myServicesCart")) ||[]   
  
    const existItem = currentCart.find((c) => (c._id === value._id && c.type === value.type ))       

    let cartItems = [];
    if (existItem) {
      cartItems = currentCart.map((x) => ((x._id === value._id && x.type === value.type) ? value : x));   
      // localStorage.setItem("myServicesCart", JSON.stringify(cartItems));
      // this.setState({ serviceItems: cartItems });
    } else {
      // const storedItems = JSON.parse( localStorage.getItem("myServicesCart")) ||[]
      cartItems = [...currentCart, value];
      // cartItems.push(value)
      // localStorage.setItem("myServicesCart", JSON.stringify(cartItems));
      // this.setState({ serviceItems: cartItems });
      //  this.setState({size : this.state.productItems.length})
    }
     localStorage.setItem("myServicesCart", JSON.stringify(cartItems));
     this.setState({ serviceItems: cartItems });
   
    
  };

  
  count = () => {
    let items = 0;
    // console.log('this.state.productItems',this.state.productItems);
    this.state.productItems?.forEach((x) => {
      items += x.orderCount;
    });

    // console.log( this.state.serviceItems);


    this.state.serviceItems?.forEach(x=> items+=1)
    return items;
  };

  subtotalHandler = () => {
    let subtotal = 0;
    this.state.productItems.forEach((x) => {
      subtotal += x.orderCount * x.price;
    });
    return subtotal;
  };
    

  render() {
    return (
      <CartContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </CartContext.Provider>
    );
  }
}

export default CartContextProvider;
