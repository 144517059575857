import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import api from "../../utils/api";
import { CartContext } from "../../components/contexts/cartContext";
import ToastNote from "../../components/golbal/notifications/toastNote";
import tools from "../../utils/tools";
// import countryStates from "../../utils/countryStates";

export default function LangFaOut() {
  const { user } = useContext(CartContext);
  const [pubData, setPubData] = useState({
    newPosts: [],
    tags: [],
    viewedPosts: [],
    newBus: [],
    ads: {},
    categories: {},
    countryDetails: {},
    newMedias: [],
    newJobs: [],
    newTutors: [],
  });
  const navigate = useNavigate()
  const { country="ca", countryShort="canada", state } = tools.getItemFromLocalStorage("initSetup",{} );
  const lang = window.location.pathname.split("/")[1]?.toLowerCase();
  const lcs = `${lang}/${countryShort}/${state?.stateShort}`
  // const lcs = window.location.pathname
  //   .split("/")
  //   .slice(1, 4)
  //   .join("/")
  //   .toLowerCase();

  // const effectRan = useRef(false);
  const messageRef = useRef();
  window.capitalize = (s) =>
    s?.charAt(0)?.toUpperCase() + s?.slice(1).toLowerCase();
  window.convert = (string) =>
    string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  window.lcs = lcs;
  window.lang = lang||'fa';
  window.logined = (role,section) => {
    // console.log('allowedRoles',role);
    
    const rolesArray = [9999];
    const rolesSet = new Set(rolesArray);
    const result = user?.roles?.some((role) => rolesSet.has(role));
    let logStatus = true;
    if (!result) {
      logStatus = false;
      document.querySelector("#loginTrigger")?.click();
      console.log('section',section);
      if(section){
        
        let msgTxt = " لطفا ابتدا وارد حساب کاربری خود شوید و "
        switch (section) {
          case 'business':   (msgTxt+="سپس فرم افزودن کسب و کار را تکمیل کنید.");break
          case 'busClaim':   (msgTxt+='سپس دکمه "ادعای مالکیت" را بزنید.');break
          case 'media':  (msgTxt+="سپس فرم افزودن شبکه اجتماعی را تکمیل کنید.");break
          case 'jobs':  (msgTxt+="سپس فرم افزودن فرصت شغلی را تکمیل کنید.");break
          case 'hide':  (msgTxt+="سپس فرم افزودن فرصت شغلی در گوشه و کنار را تکمیل کنید.");break
          case 'ready':  (msgTxt+="سپس فرم آماده به کار را تکمیل کنید.");break
          case 'used':  (msgTxt+="سپس فرم آگهی نیازمندی را تکمیل کنید.");break
          case 'post':  (msgTxt+="سپس فرم افزودن پست در مجله را تکمیل کنید.");break
          case 'tutor':  (msgTxt+="سپس پروفایل تدریس را تکمیل کنید.");break           
       return
        }
      const customMsg = document.querySelector("#customMsg")
      if(customMsg){
        customMsg.innerText=msgTxt
      }else{
        const el = document.querySelector("#loginFormAddTxt")
        const txt = `<div id='customMsg' class="error-message">${msgTxt}</div>`
        el.insertAdjacentHTML('afterbegin',txt)
      }
      setTimeout(() => {
        const customMsg = document.querySelector("#customMsg");
        if (customMsg) {
            customMsg.remove();
        }
    }, 90000);
    }

   
    }
    if (!logStatus)
      messageRef.current.showWarning({
        message: "شما وارد حساب نشده اید، برای ادامه لطفا وارد شوید. ",
      });
    return logStatus;
  };

  useEffect(() => {
    fetchData();
    // getGeoInfo()
    updateBootstrapLink()    
  }, [lcs]);


  const updateBootstrapLink = () => {
    const existingLink = document.querySelector('link[rel="stylesheet"][href*="bootstrap"]');
    const html = document.querySelector('html');
    const enHrefLink = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css"
   
    const faHrefLink = "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.rtl.min.css"
        
    // If no existing Bootstrap link, add a new one
    if (!existingLink) return;    
    // Update the href attribute based on language (RTL or LTR)
    if (lang === 'fa'&&existingLink.href!==faHrefLink) {      
   
      html.lang = "fa"
      html.dir = "rtl"     
      existingLink.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.rtl.min.css";
      existingLink.integrity = "sha384-DOXMLfHhQkvFFp+rWTZwVlPVqdIhpDVYT9csOnHSgWQWPX0v5MCGtjCJbY6ERspU";
      console.log('faPassed 02',existingLink.href);
    } 
    else if(lang === 'en'&&existingLink.href!==enHrefLink) {   
      
      existingLink.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css";
      existingLink.integrity = "sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH";
    }
  };



//  async function getGeoInfo(){
//   const item = localStorage.getItem('initSetup');
//   // console.log(item);  
//   if(item && item !== 'undefined')return
//   const response  =  await api.getGeoInfo()
//   // console.log('response2',response);
//   if(response.error)return console.log(response.error)

//     const currentCount = countryStates.find(co=>co.countryShort===response?.country)
//     const currentState = currentCount.states.find(st=>st.state===response?.region)
//     // console.log("currentCount:",currentCount,"currentState:",currentState);
//     messageRef.current.showWarning({message:`شما از کشور ${currentCount?.country} و استان  ${currentState.stateShort} در صورت تمایل میتوانید شهر یا کشور خود را تغییر دهید`})
//     const[lat,lng]=response.loc.split(",")
//     const initSetup={
//       // lang: initSetup?.lang?.toLowerCase() || "fa",
//       lang,
//       country: currentCount?.country?.toLowerCase(),
//       countryShort: response?.country?.toLowerCase(),
//       city:response?.city,
//       state: {
//         state:  response?.region?.toLowerCase(),
//         stateShort: currentState.stateShort,
//         lat: lat||"52.93991590",
//         lng: lng||"-73.54913610",
//       },
//       firstRender: true,
//     }
//     // console.log('initSetup',initSetup);
//     localStorage.setItem("initSetup",JSON.stringify(initSetup))

//   }
 

  const fetchData = async () => {
    const filters = {
      lang: window.lang,
      country,
      countryShort,
      state:state.state,
      stateShort:state?.stateShort,
      pageNumber: 1,
      itemsToShow: 12,
      sort: "dateB",
    };

    const response = await api.getPupData({ filters });
    if (response.error) return;
    setPubData(response.data);
    if(response?.data?.countryDetails){
    localStorage.setItem(
      "countryDetails",
      JSON.stringify(response?.data?.countryDetails)
    );}
  };
  window.navigateHandle = (url,data)=> {
    // console.log("state data",data,url);
    
    window.scrollTo(0,0);
    setTimeout(()=>navigate(url,{state:data}),200)
  }

  return (
    <div style={{ position: "relative" }}>
      <ToastNote messageRef={messageRef} />
      <Navbar
        categories={pubData.categories}
        countryDetails={pubData.countryDetails}
      />
      <div className="sticky-overflow">
        <Outlet context={[pubData]} />
      </div>
      <div className="sticky-overflow">
        <Footer />
      </div>
    </div>
  );
}
