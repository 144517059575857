import { NavLink } from "react-router-dom";

export default function UserDashBlogNav() {
  const showEdit = window.location.pathname.split("/").slice(7, 8).toString();
  const trans={
    newPost:{fa:"ایجاد پست جدید",en:"New Post"},
    myPost:{fa:"پست های من",en:"My Posts"},
    editPost:{fa:"ویرایش پست",en:"Edit Post"},
   
  }
 
  return (
    <div className="dashboardNavigator d-flex">
      <NavLink
        to={`/${window.lang}/dashboard/user/blog/register`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {trans.newPost[window.lang]}
      </NavLink>
      <NavLink
        to={`/${window.lang}/dashboard/user/blog/myPostsList`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        
        {trans.myPost[window.lang]}
      </NavLink>
      {showEdit === "edit" && (
        <NavLink
          to={`/${window.lang}/dashboard/user/blog/edit`}
          className={`sidebarListItem m-1 link  ${({ isActive }) => {
            return isActive && "active";
          }}`}
        >
          {trans.editPost[window.lang]}
        </NavLink>
      )}
    </div>
  );
}
